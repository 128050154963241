import React, { memo, useEffect, useState } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import { goProGameReport, proGameReportDetail } from '../../../lib/pagePath';
import { GameId } from '../../../services/types/param_types';
import { colors } from '../../../Styles/ui';

import VideoAtom from '../../../recoil/ProGameReport/videoAtom';
import GameAtom from '../../../recoil/ProGameReport/gameAtom';

const TOTAL_SET = [0, 1, 2, 3, 4];

const EachMatch = ({ matchData, team }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [gameFullTime, setGameFullTime] = useState<number>(0);
  const {
    date,
    game,
    gameid,
    vod,
    gamelength,
    platform,
    starttime,
    oppteam,
    uniqueId,
    teamresult,
    oppteamresult,
    oppside,
    pga,
    invade,
  } = matchData;

  const setVodUrl = useSetRecoilState(VideoAtom.vodUrl);
  const setGameTime = useSetRecoilState(GameAtom.gameTime);
  const setStartTime = useSetRecoilState(GameAtom.startTime);
  const setUniqueId = useSetRecoilState(GameAtom.uniqueId);
  const setBlueTeam = useSetRecoilState(GameAtom.blueteam);
  const setRedTeam = useSetRecoilState(GameAtom.redteam);

  return (
    <EachMatchWrapper>
      <MetaData>
        <Round>{uniqueId.replaceAll('_', ' ')}</Round>
        <Date>{date.substring(0, 16)}</Date>
      </MetaData>
      <GameInfoBox>
        <MatchInfo>
          <Team>
            <TeamName>{team}</TeamName>
            <TeamImg src={`/Images/TeamLogo/${team.toUpperCase()}.png`} />
          </Team>
          <ScoreBox>
            <LeftArrow didMyTeamWin={teamresult > oppteamresult} />
            <Score>
              <LeftScore>{teamresult}</LeftScore>&nbsp;:&nbsp;
              <RightScore>{oppteamresult}</RightScore>
            </Score>
            <RightArrow didMyTeamWin={teamresult < oppteamresult} />
          </ScoreBox>
          <OppTeam>
            <OppTeamImg src={`/Images/TeamLogo/${oppteam.toUpperCase()}.png`} />
            <OppTeamName>{oppteam}</OppTeamName>
          </OppTeam>
        </MatchInfo>
        <SetInfo>
          <SetTitle>SET</SetTitle>
          {TOTAL_SET.map((idx) => {
            return game > idx ? (
              pga[idx] === '1' ? (
                <SetWraper>
                  <SetList
                    inactive={gameid[idx] === undefined}
                    noData={pga[idx] !== '1'}
                    onClick={() => {
                      // dispatch(SetGameId(gameid[idx]));
                      setVodUrl(vod[idx]);
                      setGameTime(gamelength[idx] / 2 ?? 0);
                      setStartTime(starttime[idx] ?? 0);
                      setUniqueId(uniqueId.replaceAll('_', ' '));
                      setBlueTeam((oppside[idx] === 'red' ? team : oppteam).toUpperCase());
                      setRedTeam((oppside[idx] === 'red' ? oppteam : team).toUpperCase());
                      setGameFullTime(gamelength[idx] / 2 ?? 0);
                      navigate(`${goProGameReport}/${gameid[idx]}`);
                      // getGameDetailData(gameid[game], gamelength[game] / 2 ?? 0);
                    }}
                  >
                    {idx + 1}
                  </SetList>
                  {invade && invade[idx] && (
                    <img className='invade' src='/Images/tag/tag-invade-ko.svg' alt='invade' />
                  )}
                </SetWraper>
              ) : (
                <SetWraper>
                  <NoDataSetList>{idx + 1}</NoDataSetList>
                  <img className='noData' src='/Images/tag/tag-data-ko.svg' alt='no-data' />
                </SetWraper>
              )
            ) : (
              <NoSetList>{idx + 1}</NoSetList>
            );
          })}
        </SetInfo>
      </GameInfoBox>
    </EachMatchWrapper>
  );
};

export default EachMatch;

const EachMatchWrapper = styled.div``;

const MetaData = styled.section`
  color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-family: 'SpoqaHanSansNeo';
`;

const Round = styled.div`
  font-weight: bold;
  margin-right: 20px;
  font-size: 24px;
`;

const Date = styled.div`
  font-size: 20px;
`;

const GameInfoBox = styled.section`
  background-color: #2f2d38;
  width: 1050px;
  height: 113px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  margin-bottom: 53px;
`;

const MatchInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  width: 380px;
`;

const Team = styled.div`
  display: flex;
  align-items: center;
  width: 155px;
`;

const ScoreBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
`;

const LeftArrow = styled.div<{ didMyTeamWin: boolean }>`
  width: 0px;
  height: 0px;
  border-top: 5px solid transparent;
  border-right: 8px solid #fff;
  border-bottom: 5px solid transparent;
  visibility: ${(props) => (props.didMyTeamWin ? 'visible' : 'hidden')};
`;
const Score = styled.div`
  font-size: 20px;
`;

const LeftScore = styled.span`
  width: 40px;
  margin-left: 10px;
`;
const RightScore = styled.span`
  width: 40px;
  margin-right: 10px;
`;

const RightArrow = styled.div<{ didMyTeamWin: boolean }>`
  width: 0px;
  height: 0px;
  border-top: 5px solid transparent;
  border-left: 8px solid #fff;
  border-bottom: 5px solid transparent;
  visibility: ${(props) => (props.didMyTeamWin ? 'visible' : 'hidden')};
`;

const TeamName = styled.div`
  font-size: 20px;
  margin-right: 10px;
`;
const TeamImg = styled.img`
  width: 73px;
  height: 73px;
`;

const OppTeam = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 155px;
`;
const OppTeamName = styled.div`
  font-size: 20px;
  margin-left: 10px;
`;
const OppTeamImg = styled.img`
  width: 73px;
  height: 73px;
`;

const SetInfo = styled.div`
  width: 386px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;

  .noData {
    min-width: 68px;
    min-height: 21px;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .invade {
    min-width: 57px;
    min-height: 23px;
    object-fit: cover;
    position: absolute;
    top: -25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const SetTitle = styled.ul`
  font-size: 20px;
`;

const SetCss = css`
  list-style: none;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  background-color: #484655;
  font-size: 20px;
  cursor: pointer;
  color: #fff;
`;

const SetWraper = styled.div`
  position: relative;
`;

const SetList = styled.li<{ inactive: boolean; noData: boolean }>`
  ${SetCss}
  border: solid 2px ${({ inactive }) => (inactive ? 'rgba(0,0,0,0)' : colors.point)};
  position: relative;

  ${(props) =>
    props.noData &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}

  &:hover {
    background-color: #484655;
    opacity: 0.7;
    cursor: pointer;
    color: #fff;
  }
`;

const NoDataSetList = styled.li`
  ${SetCss}
  border: solid 2px rgba(0, 0, 0, 0);
  opacity: 0.5;
`;

const NoSetList = styled.li`
  ${SetCss}
  border: solid 2px rgba(0, 0, 0, 0);
  opacity: 0.3;
`;
