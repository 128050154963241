import { AxiosError, AxiosResponse } from 'axios';
import { API } from '../Pages/config';
import { ApiError, axiosPost } from './axios/axios';
import { IAxiosResponse } from './axios/axios.type';
import { IGetChampionSuccess } from './types/filter_services.type';
import { OppPlayersParam, OppTeamsParam, PatchParam } from './types/param_types';

interface ChampionResponse {
  patch: string;
  list: Champion[];
}
type Champion = { ckey: number; cname: string; cid: string };

class FilterServices {
  getFilterCall() {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/filter/filterCall',
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  getChampionList(): Promise<IAxiosResponse<ChampionResponse>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/filter/championCall',
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  public async getPatch(data: PatchParam): Promise<IAxiosResponse<string[]>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/filter/patch',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  public async getChampion(data: any): Promise<IAxiosResponse<IGetChampionSuccess>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/filter/champion',
        data,
      })
        .then((res: AxiosResponse) => {
          console.log('res.data', res.data);
          resolve(res.data);
        })
        .catch((err: AxiosError) =>
          reject(
            new ApiError(
              err.response?.status ? err.response.status.toString() : '500',
              err.response?.statusText,
            ),
          ),
        );
    });
  }

  public async getOppChampion(data: any): Promise<IAxiosResponse<IGetChampionSuccess>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/filter/oppchampion',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) =>
          reject(
            new ApiError(
              err.response?.status ? err.response.status.toString() : '500',
              err.response?.statusText,
            ),
          ),
        );
    });
  }

  public async getOppTeams(data: OppTeamsParam) {
    return axiosPost({
      url: '/filter/oppteam',
      data,
    });
  }

  private async getOppPlayers(data: OppPlayersParam) {
    return axiosPost({
      url: '/filter/oppplayer',
      data: { ...data, position: data.position ?? '' },
    });
  }
}

export default new FilterServices();
