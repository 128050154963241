import { atom } from 'recoil';

interface VideoParams {
  vodUrl: string;
  platform: string;
  playing: boolean;
  controls: boolean;
  light: boolean;
  volume: number;
  muted: boolean;
  played: number;
  loaded: number;
  duration: number;
  playbackRate: number;
  loop: boolean;
  url: string;
  playedSeconds: number;
  teamGold: number;
  pip: boolean;
  seeking: boolean;
}

const VideoAtom = {
  vodUrl: atom<string>({
    key: 'VideoVodUrl',
    default: '',
  }),

  platform: atom<string>({
    key: 'VideoPlatform',
    default: '',
  }),

  playing: atom<boolean>({
    key: 'VideoPlaying',
    default: true,
  }),

  controls: atom<boolean>({
    key: 'VideoControls',
    default: false,
  }),

  light: atom<boolean>({
    key: 'VideoLight',
    default: false,
  }),

  volume: atom<number>({
    key: 'VideoVolume',
    default: 0.8,
  }),

  muted: atom<boolean>({
    key: 'VideoMuted',
    default: false,
  }),

  played: atom<number>({
    key: 'VideoPlayed',
    default: 0,
  }),

  loaded: atom<number>({
    key: 'VideoLoaded',
    default: 0,
  }),

  duration: atom<number>({
    key: 'VideoDuration',
    default: 0,
  }),
  playbackRate: atom<number>({
    key: 'VideoPlaybackRate',
    default: 1.0,
  }),
  loop: atom<boolean>({
    key: 'VideoLoop',
    default: false,
  }),

  url: atom<string>({
    key: 'VideoUrl',
    default: '',
  }),

  playedSeconds: atom<number>({
    key: 'VideoPlayedSeconds',
    default: 0,
  }),

  teamGold: atom<number>({
    key: 'VideoTeamGold',
    default: 0,
  }),

  pip: atom<boolean>({
    key: 'VideoPip',
    default: false,
  }),

  seeking: atom<boolean>({
    key: 'VideoSeeking',
    default: false,
  }),
};

export const VideoResultAtom = atom<VideoParams>({
  key: 'VideoResult',
  default: undefined,
});

export default VideoAtom;
