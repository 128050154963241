import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { useMutation } from 'react-query';
import { t } from 'i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { colors, scrollbarStyle, spacing } from '../../Styles/ui';
import CustomSelect from '../../Components/Ui/Select/CustomSelect';
import { findLegueData, findSeasonData, findTeamData, findYearData } from '../../lib/searchLeagueData';
import { getTrueValueList, getTrueValueSeasonList } from '../../lib/getTureValueList';
import CheckboxList from '../../Components/Ui/CheckList/CheckboxList';
import { initializedObjValue, initializedSeasons } from '../../lib/initializedObjValue';
import FilterService from '../../services/FilterServices';
import { PatchParam } from '../../services/types/param_types';
import PathAnalysisAtom, { PathAnalysisResultAtom } from '../../recoil/Filter/pathAnalysisAtom';
import { useFilterCall } from '../../Hooks/useFilterCall';
import { MAP_FILTER_MEMU } from '../../lib/mapping/map_filter_menu';
import LeagueSelect from '../../Components/Ui/Select/LeagueSelect';
import TeamSelect from '../../Components/Ui/Select/TeamSelect';
import { SideFilterContainer } from '../../Components/Filter/SideFilter/SideFilterStyled';
import SideFilterConfirmButton, {
  SideFilterConfirmErrorType,
} from '../../Components/Filter/SideFilter/SideFilterConfirmButton';

const PathAnalysisFilter = () => {
  //* leaguevalue에서 찾기
  const { data } = useFilterCall();
  const getYear = findYearData(data);
  const getLeague = findLegueData(data);
  const getSeason = findSeasonData(data);
  const getTeam = findTeamData(data);

  //* year
  const [year, setYear] = useRecoilState(PathAnalysisAtom.years);
  const yearRenderList = getYear().map((value) => ({ value }));
  const selectedYear = getTrueValueList(year);

  //* league
  const [league, setLeague] = useRecoilState(PathAnalysisAtom.leagues);
  // 제약조건 수정 22.06.20 - LPL, VCS 제외한 나머지 전체 리그 노출 => 22.06.24 라이브 업데이트는 제외
  const leagueRenderList = getLeague(selectedYear)
    .filter((league) => {
      if (selectedYear?.includes('2021')) {
        return league !== 'LPL' && league !== 'VCS';
      }
      if (selectedYear?.includes('2022')) {
        return league !== 'LPL' && league !== 'VCS';
      }
      return true;
    })
    .map((value) => ({ value }))
    .filter((league) => !['PCS'].includes(league.value));
  const selectedLeague = getTrueValueList(league);

  //* season
  const [season, setSeason] = useRecoilState(PathAnalysisAtom.seasons);
  // 22.05.19 솔로랭크 탭을 제외한 모든 필터에서 Off Season 제외
  const seasonList = getSeason(selectedYear, selectedLeague).filter((season) => season.name !== 'Off Season');
  const selectedSeasonAndPatch = getTrueValueSeasonList(season);
  const selectedSeason = selectedSeasonAndPatch?.map((season) => {
    return Object.keys(season)[0];
  });
  const selectedPatch = selectedSeasonAndPatch
    ?.map((season) => {
      const keys = Object.keys(season);
      return keys
        .map((key) => {
          return season[key];
        })
        .flat();
    })
    .flat();

  //* team
  const [team, setTeam] = useRecoilState(PathAnalysisAtom.teams);
  const teamRenderList = getTeam(selectedYear, selectedLeague, selectedSeason, selectedPatch).map(
    (value) => ({ value }),
  );
  const selectedTeam = getTrueValueList(team);

  //* patch
  // const [patch, setPatch] = useRecoilState(PathAnalysisAtom.patchs);
  // const selectedPatch = getTrueValueList(patch);

  const { mutateAsync, reset } = useMutation(({ year, league, season }: PatchParam) =>
    FilterService.getPatch({ year, league, season }),
  );

  //* error code
  const [errorCode, setErrorCode] = useState<SideFilterConfirmErrorType>();

  const setResult = useSetRecoilState(PathAnalysisResultAtom);

  // effect
  useEffect(() => {
    return () => {
      setYear({});
    };
  }, []);

  useEffect(() => {
    setLeague({});
  }, [year]);

  useEffect(() => {
    setSeason({});

    if (selectedLeague && selectedLeague.length > 0) {
      setSeason(initializedSeasons(seasonList, true));
    }
  }, [league]);

  useEffect(() => {
    reset();
    // setPatch({});
    setTeam({});
    // renderPatch();

    // async function renderPatch() {
    //   const result = await mutateAsync({
    //     year: selectedYear,
    //     league: selectedLeague,
    //     season: selectedSeason,
    //   });
    //   if (!result.response) return;

    //   setPatch(initializedObjValue(result.response, true));
    // }
  }, [season]);

  useEffect(() => {
    if (!selectedTeam || selectedTeam.length === 0) {
      reset();
      // setPatch({});
    } else {
      // renderPatch();
    }

    // async function renderPatch() {
    //   const result = await mutateAsync({
    //     year: selectedYear,
    //     league: selectedLeague,
    //     season: selectedSeason,
    //   });
    //   if (!result.response) return;

    //   setPatch(initializedObjValue(result.response, true));
    // }
  }, [team]);

  // handler
  const handleSubmit = () => {
    if (!selectedYear || selectedYear.length === 0) {
      setErrorCode('연도');
      return;
    }
    if (!selectedLeague || selectedLeague.length === 0) {
      setErrorCode('리그');
      return;
    }
    if (!selectedSeason || selectedSeason.length === 0) {
      setErrorCode('시즌');
      return;
    }
    if (!selectedTeam || selectedTeam.length === 0) {
      setErrorCode('팀');
      return;
    }
    if (!selectedPatch || selectedPatch.length === 0) {
      setErrorCode('패치');
      return;
    }
    if (errorCode) {
      setErrorCode(undefined);
    }

    setResult({
      year: selectedYear,
      league: selectedLeague,
      team: selectedTeam,
      season: selectedSeason,
      patch: selectedPatch,
    });
  };

  return (
    <CONTAINER>
      <SCROLL_CONTAINER>
        <Section>
          <CustomSelect
            step={1}
            placeholder={t(MAP_FILTER_MEMU.연도)}
            renderList={yearRenderList}
            setData={setYear}
            data={year}
          />
        </Section>
        <Section>
          <LeagueSelect
            step={2}
            placeholder={t(MAP_FILTER_MEMU.리그)}
            renderList={leagueRenderList}
            setData={setLeague}
            data={league}
          />
        </Section>

        <LINE />

        <Section>
          <CheckboxList step={3} placeholder={t(MAP_FILTER_MEMU.시즌)} data={season} setData={setSeason} />
        </Section>

        <LINE />

        <Section>
          <TeamSelect
            step={4}
            placeholder={t(MAP_FILTER_MEMU.팀)}
            renderList={teamRenderList}
            setData={setTeam}
            data={team}
          />
        </Section>

        <LINE />

        {/* <Section>
          <CheckboxList
            step={5}
            column={2}
            placeholder={t(MAP_FILTER_MEMU.패치)}
            data={patch}
            setData={setPatch}
            sortType='patch'
          />
        </Section> */}
      </SCROLL_CONTAINER>
      <SideFilterConfirmButton handleSubmit={handleSubmit} errorCode={errorCode} />
    </CONTAINER>
  );
};

const CONTAINER = SideFilterContainer;

const SCROLL_CONTAINER = styled.div`
  ${scrollbarStyle.scroll_4}
  ${spacing.paddingT(10)}
  ${spacing.paddingX(4)}
  overflow-x: hidden;
  height: calc(100vh - 88px);
`;

const Section = styled.div`
  ${spacing.marginB(5)}

  &:last-of-type {
    ${spacing.marginB(0)}
  }
`;

const FOOTER = styled.div`
  ${spacing.paddingX(5)}
  ${spacing.paddingY(6)}
`;

const LINE = styled.div`
  height: 1px;
  background-color: ${colors.배경색[500]};
  ${spacing.marginB(5)}
`;

export default PathAnalysisFilter;
