import React, { useState } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import SetInputBox from './SetInputBox';
import {
  SetConfirmFuncId,
  SetDesc,
  SetIsOpen,
  SetIsSelector,
  SetSemiDesc,
} from '../../../redux/modules/modalvalue';
import { API } from '../../config';
import signAxiosReq from '../../../lib/axios/signAxiosReq';
import { Loading } from '../../../redux/modules/filtervalue';
import { LoadingButton } from '@mui/lab';
import { useMutation } from 'react-query';
import AuthService from '../../../services/AuthServices';
import { colors, typoStyle } from '../../../Styles/ui';
import { Stack } from '@mui/material';

const SetCode = ({ id, authCode, setAuthCode, signType }) => {
  const [alterOpen, setAlertOpen] = useState(false);
  const [doneCheckAuthCode, setDoneCheckAuthCode] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const onChange = (e) => {
    const { value, id } = e.target;
    setAuthCode(value);
  };

  const doneConfirm = () => {
    dispatch(SetIsSelector(true));
    dispatch(SetIsOpen(true));
    dispatch(SetDesc(t(`sign.${id}.authAlert`)));
    id === 'setCode' && dispatch(SetSemiDesc(t(`sign.${id}.authSemiAlert`)));

    switch (signType) {
      case 'EC':
        dispatch(SetConfirmFuncId('signUp'));
        break;
      case 'PC':
        dispatch(SetConfirmFuncId('changePw'));
        break;
      default:
        break;
    }
  };
  const { mutateAsync, isLoading } = useMutation(({ code }) => AuthService.checkCode(code), {
    onError: (res) => {
      const msg = res?.data?.status;
      if (msg === 'NU') {
        dispatch(SetIsSelector(false));
        dispatch(SetIsOpen(true));
        dispatch(SetDesc(t('sign.setCode.expirationKey')));
        dispatch(SetConfirmFuncId('NU'));
        dispatch(SetSemiDesc(''));
      } else {
        setAlertOpen(true);
      }
    },
  });
  const codeAuth = async () => {
    const result = await mutateAsync({ code: authCode });
    console.log(result);

    if (result) {
      dispatch(SetIsSelector(false));
      dispatch(SetIsOpen(true));
      dispatch(SetDesc(t('sign.checkLogin.success')));
      dispatch(SetSemiDesc(''));
      dispatch(SetConfirmFuncId('checkLogin'));
      setDoneCheckAuthCode(true);
      setAlertOpen(false);
    }
  };

  const Confirm = () => {
    if (doneCheckAuthCode) {
      doneConfirm();
    } else {
      dispatch(SetIsSelector(false));
      dispatch(SetIsOpen(true));
      dispatch(SetDesc(t('sign.setCode.authNotDone')));
      dispatch(SetConfirmFuncId('authNotDone'));
      dispatch(SetSemiDesc(''));
    }
  };
  return (
    <MainBox>
      <div className='logo'>
        <img className='logo' src='/Images/logo.png' alt='' />
      </div>
      <div className='subject'>{/* <span>{t(`sign.${id}.subject`)}</span> */}</div>
      <div className='desc'>
        <span>{t(`sign.${id}.desc`)}</span>
      </div>
      <div className='set-code-alert'>
        <span>{id === 'setCode' && t('sign.setCode.alert')}</span>
      </div>
      <Stack className='input-box' direction='row' spacing={2}>
        <STYLED_INPUT
          placeholder={t('sign.setCode.limitChar')}
          id='certification-input'
          value={authCode}
          autoComplete='off'
          onChange={onChange}
          maxLength={16}
        />
        <LoadingButton
          variant='contained'
          onClick={codeAuth}
          disabled={authCode.length < 16}
          sx={MUIButtonStyle}
          loading={isLoading}
        >
          {t('sign.setCode.certification')}
        </LoadingButton>
      </Stack>
      <AlertSetCode isOpen={alterOpen}>{t('sign.setCode.alertCode')}</AlertSetCode>
      <div className='confirm2'>
        <ConfirmButton onClick={Confirm} isActive={doneCheckAuthCode}>
          {t(`sign.${id}.authentication`)}
        </ConfirmButton>
      </div>
    </MainBox>
  );
};

export default SetCode;

const SignUpBox = styled.div`
  width: 50%;
  height: 988px;
  padding: 292px 100px;
`;

const MainBox = styled.div`
  width: 540px;
  height: 356px;
  font-family: SpoqaHanSansNeo;
  text-align: left;
  color: #fff;
  margin: 292px 100px;

  .logo {
    margin: 0 0 23px 0;
    object-fit: contain;
  }

  .subject {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
  }

  .desc {
    margin-top: 30px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
  }

  .set-code-alert {
    margin-top: 10px;
    display: flex;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: left;
    color: #84818e;

    .certification {
      background-color: #484655;
    }
  }

  .input-box {
    margin-top: 40px;
    display: flex;
  }
`;

const ConfirmButton = styled.button`
  width: 100%;
  height: 42px;
  border-radius: 20px;
  background-color: #484655;
  font-family: SpoqaHanSansNeo;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin-top: 31px;
  :hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.isActive &&
    css`
      background-color: #5942ba;
    `}
`;

const AlertSetCode = styled.div`
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  margin-top: 5px;
  margin-left: 20px;
  width: 100%;
  height: 14px;
  font-family: SpoqaHanSansNeo;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: #f04545;
`;

/** 2022 06 30 */

const STYLED_INPUT = styled.input`
  flex: 1;
  padding: 10px 15px 10px 20px;
  font-size: 15px;
  background-color: ${colors.bg_checkbox};
  border-radius: 9999px;

  ${typoStyle.select};
`;

const MUIButtonStyle = {
  width: 120,
  borderRadius: '9999px',
  fontSize: 13,
  color: 'white',
  '&.MuiButton-containedPrimary': {},
  '&.Mui-disabled': {
    opacity: 0.3,
    background: colors.bg_checkbox,
    color: 'white',
  },
  '&.MuiLoadingButton-loading': {
    color: 'transparent',
  },
  '.MuiLoadingButton-loadingIndicator': {
    color: 'white',
  },
};
