export const SOLO_INIT = 'solofiltervalue/SOLO_INIT';

// 정글 오더 API용 챔피언, 상대챔피언
export const SET_SOLO_ORDER_CHAMP = 'solofiltervalue/SET_SOLO_ORDER_CHAMP';
export const SET_SOLO_ORDER_OPPCHAMP = 'solofiltervalue/SET_SOLO_ORDER_OPPCHAMP';

/** 리팩토링 추가 action */
export const SET_SOLO_YEAR = 'solofiltervalue/SET_SOLO_YEAR';
export const SET_SOLO_LEAGUE = 'solofiltervalue/SET_LEAGUE';
export const SET_SOLO_SEASON = 'solofiltervalue/SET_SEASON';
export const SET_SOLO_TEAM = 'solofiltervalue/SET_TEAM';
export const SET_SOLO_TEAMS = 'solofiltervalue/SET_TEAMS';
export const SET_SOLO_PATCH = 'solofiltervalue/SET_PATCH';
export const SET_SOLO_CHAMP = 'solofiltervalue/SET_SOLO_CHAMP';
export const SET_SOLO_OPP_CHAMP = 'solofiltervalue/SET_SOLO_OPP_CHAMP';

export const SET_SOLO_PLAYER = 'solofiltervalue/SET_SOLO_PLAYER';

export const RESET_SOLO_SELECTED_PATCH = 'solofiltervalue/RESET_SOLO_SELECTED_PATCH';

export const RESET_SOLO_YEAR = 'solofiltervalue/RESET_SOLO_YEAR';
export const RESET_SOLO_LEAGUE = 'solofiltervalue/RESET_LEAGUE';
export const RESET_SOLO_SEASON = 'solofiltervalue/RESET_SOLO_SEASON';
export const RESET_SOLO_TEAM = 'solofiltervalue/RESET_SOLO_TEAM';
export const RESET_SOLO_PATCH = 'solofiltervalue/RESET_SOLO_PATCH';
export const RESET_SOLO_PLAYER = 'solofiltervalue/RESET_SOLO_PLAYER';

export const RESET_SOLO_CHAMP = 'solofiltervalue/RESET_SOLO_CHAMP';
export const RESET_SOLO_OPP_CHAMP = 'solofiltervalue/RESET_SOLO_OPP_CHAMP';

export const SET_IS_CHAMP_SELECTED = 'solofiltervalue/SET_IS_CHAMP_SELECTED';
export const SET_IS_BOTH_CHAMPS_SELECTED = 'solofiltervalue/SET_IS_BOTH_CHAMPS_SELECTED';

export const SoloInit = () => {
  return {
    type: SOLO_INIT,
  };
};
// set
export const SetSoloYear = (payload) => ({
  type: SET_SOLO_YEAR,
  payload,
});
export const SetSoloLeague = (payload) => ({
  type: SET_SOLO_LEAGUE,
  payload,
});
export const SetSoloSeason = (payload) => ({
  type: SET_SOLO_SEASON,
  payload,
});
export const SetSoloTeam = (payload) => ({
  type: SET_SOLO_TEAM,
  payload,
});

export const SetSoloPatch = (payload) => ({
  type: SET_SOLO_PATCH,
  payload,
});

// reset
export const ResetSoloSelectedPatch = () => ({
  type: RESET_SOLO_SELECTED_PATCH,
});

export const ResetSoloYear = (payload) => {
  return {
    type: RESET_SOLO_YEAR,
    payload,
  };
};

export const ResetSoloLeague = (payload) => {
  return {
    type: RESET_SOLO_LEAGUE,
    payload,
  };
};

export const ResetSoloSeason = (payload) => {
  return {
    type: RESET_SOLO_SEASON,
    payload,
  };
};

export const ResetSoloTeam = () => {
  return {
    type: RESET_SOLO_TEAM,
  };
};

export const ResetSoloPlayer = () => {
  return {
    type: RESET_SOLO_PLAYER,
  };
};

export const ResetSoloPatch = (payload) => {
  return {
    type: RESET_SOLO_PATCH,
    payload,
  };
};

export const SetSoloPlayer = (payload) => {
  return {
    type: SET_SOLO_PLAYER,
    payload,
  };
};

export const SetSoloChamp = (payload) => {
  return {
    type: SET_SOLO_CHAMP,
    payload,
  };
};

export const SetSoloOppChamp = (payload) => {
  return {
    type: SET_SOLO_OPP_CHAMP,
    payload,
  };
};

export const ResetSoloChamp = () => {
  return {
    type: RESET_SOLO_CHAMP,
  };
};

export const ResetSoloOppChamp = () => {
  return {
    type: RESET_SOLO_OPP_CHAMP,
  };
};

export const SetIsChampSelected = (payload) => {
  return {
    type: SET_IS_CHAMP_SELECTED,
    payload,
  };
};
export const SetIsBothChampSelected = (payload) => {
  return {
    type: SET_IS_BOTH_CHAMPS_SELECTED,
    payload,
  };
};

// year = null | {[key] : boolean}
const initialState = {
  year: null,
  league: null,
  season: null,
  team: null,
  patch: null,
  player: null,
  champion: null,
  oppchampion: null,
  isChampSelected: null,
  isBothChampSelected: null,
};

export default function SoloFilterReducer(state = initialState, action) {
  switch (action.type) {
    case SOLO_INIT:
      return initialState;
    case SET_SOLO_CHAMP:
      return {
        ...state,
        champion: { ...action.payload },
      };
    case SET_SOLO_OPP_CHAMP:
      return {
        ...state,
        oppchampion: { ...action.payload },
      };

    case SET_IS_CHAMP_SELECTED:
      return {
        ...state,
        isChampSelected: { ...action.payload },
      };
    case SET_IS_BOTH_CHAMPS_SELECTED:
      return {
        ...state,
        isBothChampSelected: { ...action.payload },
      };
    case SET_SOLO_YEAR:
      return state;
    case SET_SOLO_LEAGUE:
      return state;
    case SET_SOLO_SEASON:
      return state;
    case SET_SOLO_TEAM:
      return state;
    case SET_SOLO_TEAMS:
      return state;
    case SET_SOLO_PATCH:
      return state;
    case RESET_SOLO_SELECTED_PATCH:
      return state;
    case RESET_SOLO_LEAGUE:
      return state;
    case RESET_SOLO_SEASON:
      return state;
    case RESET_SOLO_TEAM:
      return state;
    case RESET_SOLO_PATCH:
      return state;
    case RESET_SOLO_PLAYER:
      return state;
    case RESET_SOLO_CHAMP:
      return {
        ...state,
        champion: { ...action.payload },
      };
    case RESET_SOLO_OPP_CHAMP:
      return {
        ...state,
        oppchampion: { ...action.payload },
      };

    default:
      return state;
  }
}
