type Postion = 'TOP' | 'JNG' | 'MID' | 'BOT' | 'SUP';

export const convertNumberToPosition = (position: number): Postion | undefined => {
  switch (position) {
    case 1:
      return 'TOP';
    case 2:
      return 'JNG';
    case 3:
      return 'MID';
    case 4:
      return 'BOT';
    case 5:
      return 'SUP';
    default:
      return undefined;
  }
};
