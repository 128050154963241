/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import JungleSideFilter from '../components/JungleSideFilter';
import SequenceDialog from '../components/SequenceDialog';
import Map from '../components/Map';
import * as S from '../components/styled/StyledJungleLayout';
import { useRecoilValue } from 'recoil';
import { PathAnalysisResultAtom } from '../../../../recoil/Filter/pathAnalysisAtom';

const Sequence = () => {
  const result = useRecoilValue(PathAnalysisResultAtom);
  const selectedTeam = result.team[0];
  const selectedPatchs = result.patch;
  const { isMappingClicked } = useSelector((state) => state.JungleMapReducer);

  return (
    <S.SequenceContainer>
      {/* 메인 필터 */}
      {/* 사이드 필터와 맵 비디오  */}
      {selectedTeam && selectedPatchs && selectedTeam.length > 0 && selectedPatchs.length > 0 && (
        <S.FlexContainer>
          {/* 사이드 필터 */}
          <S.Sidebar>
            <JungleSideFilter />
          </S.Sidebar>

          {/*  맵 & 다이얼로그 */}

          <S.Container>
            {/* 맵 비디오 */}
            <S.VideoContainer>
              <Map />
            </S.VideoContainer>
            {/* 이벤트 다이얼로그 */}
            <S.DialogContainer>
              <SequenceDialog />
            </S.DialogContainer>
          </S.Container>
        </S.FlexContainer>
      )}
    </S.SequenceContainer>
  );
};

export default Sequence;
