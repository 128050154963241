import * as React from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

const CustomStyleTooltip = ({ className, ...props }: TooltipProps) => {
  const { maxWidth } = props;
  return (
    <Tooltip
      classes={{ popper: className }}
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            maxWidth: maxWidth ?? 200,
            fontSize: 13,
            padding: 3,
            backgroundColor: '#000000',
          },
        },
      }}
      {...props}
    />
  );
};

export default function CustomTooltip({ children, ...props }) {
  return <CustomStyleTooltip {...props}>{children}</CustomStyleTooltip>;
}
