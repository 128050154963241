/* eslint-disable no-restricted-syntax */
import React, { Key, memo, SetStateAction, useState } from 'react';
import { SetterOrUpdater } from 'recoil';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  styled,
  ButtonProps,
  SvgIcon,
  IconButton,
} from '@mui/material';
import { Autorenew } from '@mui/icons-material';
import { t } from 'i18next';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { colors, spacing } from '../../../Styles/ui';
import { FilterData, IFilterSeason } from '../../../recoil/Filter/filter.type';

// 체크박스 스타일
// 체크해제
const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 18,
  height: 18,
  boxShadow: `inset 0 0 0 1px ${colors.배경색[300]}, inset 0 -1px 0 ${colors.배경색[300]}`,
  backgroundColor: 'transparent',
  transition: `color 0.5s ease`,

  'input:hover ~ &': {
    backgroundColor: colors.보라색[600],
    transition: `color 0.2s ease`,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));
// 체크ed
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: 'rgba(89,66,186,0.2)',
  boxShadow: `inset 0 0 0 1px ${colors.보라색[500]}, inset 0 -1px 0 ${colors.보라색[500]}`,
  '&:before': {
    display: 'block',
    width: 18,
    height: 18,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: `${colors.보라색[700]}`,
  },
});

interface TotalCheckListlPorps {
  placeholder: string;
  total?: boolean;
  data: IFilterSeason;
  setData: SetterOrUpdater<IFilterSeason>;
  sortType?: 'default' | 'patch';
  column?: number;
  step?: number;
}

const SORTTYPE = {
  default: (a: string | number, b: string | number) => a - b,
  patch: (a: string, b: string) => Number(a.split('.')[1]) - Number(b.split('.')[1]),
};

const isTotalChecked = (data: IFilterSeason) => {
  const seasonKeys = Object.keys(data);
  for (const seasonKey of seasonKeys) {
    const season = data[seasonKey];
    const patchKeys = Object.keys(season.patch);
    for (const patchKey of patchKeys) {
      const patch = season.patch[patchKey];
      if (!patch) {
        return false;
      }
    }
  }
  return true;
};

const isSeasonChecked = (data: FilterData) => {
  const keys = Object.keys(data);
  const trueChecked = keys.filter((key) => data[key] === true);
  return keys.length === trueChecked.length;
};

const isIndeterminate = (data: FilterData) => {
  const keys = Object.keys(data);
  const trueChecked = keys.filter((key) => data[key] === true);
  return trueChecked.length > 0 && keys.length !== trueChecked.length;
};

const CheckboxList: React.FC<TotalCheckListlPorps> = ({
  placeholder,
  data,
  setData,
  total = true,
  sortType = 'default',
  column = 1,
  step,
}) => {
  const handleReset = () => {
    setData((prev) => {
      if (!prev) return prev;
      const newObj = { ...prev };
      //? read only property Error로 인해 2중 깊은 복사로 새 데이터 생성
      Object.keys(newObj).forEach((seasonKey) => {
        const season = { ...newObj[seasonKey] };
        Object.keys(newObj[seasonKey].patch).forEach((patchKey) => {
          const patch = { ...season.patch };
          patch[patchKey] = false;
          season.patch = patch;
        });
        newObj[seasonKey] = season;
      });
      console.log('newObj', newObj);
      return newObj;
    });
  };

  const handleTotalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!data) return;
    const { checked } = event.target;
    setData((prev) => {
      if (!prev) return prev;
      const newObj = { ...prev };
      //? read only property Error로 인해 2중 깊은 복사로 새 데이터 생성
      Object.keys(newObj).forEach((seasonKey) => {
        const season = { ...newObj[seasonKey] };
        Object.keys(newObj[seasonKey].patch).forEach((patchKey) => {
          const patch = { ...season.patch };
          patch[patchKey] = checked;
          season.patch = patch;
        });
        newObj[seasonKey] = season;
      });
      console.log('newObj', newObj);
      return newObj;
    });
  };

  return (
    <>
      <StyledFormLabel>
        <TEXT>
          {step && <STEP>STEP{step}.</STEP>}
          <span>{placeholder}</span>
        </TEXT>
        <MUIRESETBUTTON onClick={handleReset} color='primary'>
          <SvgIcon
            sx={{
              fontSize: '12px',
            }}
          >
            <Autorenew />
          </SvgIcon>
          {t('filters.reset')}
        </MUIRESETBUTTON>
      </StyledFormLabel>

      {total && data && Object.keys(data).length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <MUIFORMCONTROLLABEL
            label={t('filters.selectAll')}
            checked={isTotalChecked(data)}
            control={
              <MUICHECKBOX
                name='total'
                icon={<BpIcon />}
                checkedIcon={<BpCheckedIcon />}
                onChange={handleTotalChange}
              />
            }
          />
        </Box>
      )}
      <CHILDLIST column={column}>
        {data &&
          (Object.keys(data) as Array<keyof typeof data>)
            .sort((aKey, bKey) => {
              const aSeason = data[aKey];
              const bSeason = data[bKey];

              const aPatch = Number(Object.keys(aSeason.patch).sort()[0].replace('.', ''));
              const bPatch = Number(Object.keys(bSeason.patch).sort()[0].replace('.', ''));

              if (aPatch < bPatch) {
                return -1;
              }
              return 0;
            })
            .map((season_key) => (
              <Children season_key={season_key} data={data} setData={setData} sortType={sortType} />
            ))}
      </CHILDLIST>
    </>
  );
};

interface ChildrenP {
  data: IFilterSeason;
  setData: SetterOrUpdater<IFilterSeason>;
  sortType: 'default' | 'patch';
  season_key: string | number;
}

const Children: React.FC<ChildrenP> = ({ data, setData, sortType, season_key }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleSeasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!data) return;
    const { checked, name } = event.target;
    console.log('name', name);
    console.log('checked', checked);
    setData((prev) => {
      if (!prev) return prev;
      const newObj = { ...prev };
      //? read only property Error로 인해 2중 깊은 복사로 새 데이터 생성
      Object.keys(newObj).forEach((seasonKey) => {
        if (name === seasonKey) {
          const season = { ...newObj[seasonKey] };
          Object.keys(newObj[seasonKey].patch).forEach((patchKey) => {
            const patch = { ...season.patch };
            patch[patchKey] = checked;
            season.patch = patch;
          });
          newObj[seasonKey] = season;
        }
      });
      console.log('newObj', newObj);
      return newObj;
    });
  };

  const handlePatchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!data) return;
    const { checked, name, className } = event.target;
    console.log('className', className);
    console.log('name', name);
    console.log('checked', checked);
    setData((prev) => {
      if (!prev) return prev;
      const newObj = { ...prev };
      //? read only property Error로 인해 2중 깊은 복사로 새 데이터 생성
      Object.keys(newObj).forEach((seasonKey) => {
        if (className.includes(seasonKey.replace(' ', '_'))) {
          const season = { ...newObj[seasonKey] };
          Object.keys(newObj[seasonKey].patch).forEach((patchKey) => {
            if (patchKey === name) {
              const patch = { ...season.patch };
              patch[patchKey] = checked;
              season.patch = patch;
            }
          });
          newObj[seasonKey] = season;
        }
      });
      return newObj;
    });
  };

  return (
    <>
      <Box display='flex' width='100%' whiteSpace='nowrap' justifyContent='space-between'>
        <MUIFORMCONTROLLABEL
          sx={{ width: '100%' }}
          key={season_key as Key}
          label={season_key}
          checked={isSeasonChecked(data[season_key].patch)}
          control={
            <MUICHECKBOX
              name={season_key.toString()}
              // indeterminate={isIndeterminate(data[season_key].patch)}
              checkedIcon={<BpCheckedIcon />}
              icon={<BpIcon />}
              // indeterminateIcon={<IndeterminateCheckBoxIcon />}
              onChange={handleSeasonChange}
            />
          }
        />
        <IconButton onClick={() => setOpen(!open)}>
          {open ? (
            <img src='/Images/ic-expand-less.png' alt='less' />
          ) : (
            <img src='/Images/ic-expand-more.png' alt='more' />
          )}
        </IconButton>
      </Box>
      <Box sx={{ display: open ? 'flex' : 'none', flexDirection: 'column', ml: 3 }}>
        {(data[season_key].patch && Object.keys(data[season_key].patch))
          .sort(SORTTYPE[sortType])
          .map((patch_key) => (
            <MUIFORMCONTROLLABEL
              sx={{ width: '100%' }}
              key={patch_key}
              label={patch_key}
              checked={data[season_key].patch[patch_key]}
              control={
                <MUICHECKBOX
                  name={patch_key}
                  inputProps={{ className: season_key.toString().replace(' ', '_') }}
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                  onChange={handlePatchChange}
                />
              }
            />
          ))}
      </Box>
    </>
  );
};

const StyledFormLabel = styled(FormLabel)`
  color: white;
  font-size: 14px;

  &.MuiFormLabel-root.Mui-focused {
    color: white;
    stroke: 1px;
  }

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const MUIFORMCONTROLLABEL = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    span {
      font-size: 14px;
    }
  }
`;

const TEXT = styled(Box)`
  display: flex;
`;

const STEP = styled(Box)`
  color: ${colors.배경색[200]};
  font-size: 11px;
  ${spacing.marginR(1)};
`;

const MUICHECKBOX = styled(Checkbox)`
  color: white;
`;
const CHILDLIST = styled(Box)<{ column: number }>`
  display: flex;
  flex-wrap: wrap;

  ${(props) => {
    const number = 100 / Number(props.column) - 3;
    return `
    > label {
      width: ${number}%;
    }
  `;
  }}
`;
const MUIRESETBUTTON = styled(Button)<ButtonProps>(({ theme }) => ({
  minWidth: 0,
  fontSize: '10px',
  padding: '5px 5px',
  color: colors.배경색[100],
}));

export default memo(CheckboxList);
