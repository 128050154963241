export function getKda(total, kill, death, assists) {
  const k = kill / total;
  const d = death / total;
  const a = assists / total;

  return {
    k: k.toFixed(1),
    d: d.toFixed(1),
    a: a.toFixed(1),
    kda: d === 0 ? ((k + a) * 1.2).toFixed(1) : ((k + a) / d).toFixed(1),
  };
}
