/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// components
import { useRecoilValue } from 'recoil';
import Arrow from '../../../../Components/Ui/Arrow';
import Avatar from '../../../../Components/Ui/Avatar';
// style
import { borderRadiusStyle, colors, spacing, typoStyle } from '../../../../Styles/ui';
import { playerRadarOptions } from '../../../../Styles/chart/option';
// lib
import { getKda } from '../../../../lib/getKda';
import { getWinRate } from '../../../../lib/getWinRate';
import {
  commonLabels,
  prLabels,
  commonTooltipContents, // 공통지표 툴팁
  detailTooltipContents, // 세부지표 툴팁
} from '../../../../lib/mapping/map_table_label';
import CustomTooltip from '../../../../Components/Ui/Tooltip/CustomTooltip';
import { ResponseLeaguePlayer } from '../../../../services/types/league_services.type';
import LeagueReportAtom from '../../../../recoil/Filter/leagueReportAtom';
import { getTrueValueList } from '../../../../lib/getTureValueList';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend, ChartDataLabels);

const imgUrl = (imgString: string) =>
  imgString === 'no-image'
    ? 'Images/player_error_image.png'
    : `https://am-a.akamaihd.net/image?resize=90:&f=${imgString}`;

interface P {
  playerData: ResponseLeaguePlayer;
  index: number;
}

const LeaguePlayerInfo: React.FC<P> = ({ playerData, index }) => {
  // hook
  const { t } = useTranslation();
  const league = getTrueValueList(useRecoilValue(LeagueReportAtom.leagues)) ?? [];

  // state
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  // data destructure
  const {
    // 전적
    total,
    wins,
    // 신상
    NativeName,
    name,
    image,
    pr,
    player,
    // 팀
    team,
    teamShort,
    // 커리어
    career,
    // 시즌킬
    kills,
    assists,
    deaths,
    // 표상세정보
    common,
    detail,
  } = playerData;
  console.log('name', name);
  console.log('NativeName', NativeName);

  // 시즌 승률, kda
  const winRate = getWinRate(total, wins);
  const { k, d, a, kda } = getKda(total, kills, deaths, assists);
  // 커리어 승률, kda
  const {
    kills: careerKills,
    assists: careerAssists,
    deaths: careerDeaths,
    total: careerTotal,
    wins: careerWins,
  } = career;
  const careerWinRate = getWinRate(careerTotal, careerWins);
  const { k: ck, d: cd, a: ca, kda: ckda } = getKda(careerTotal, careerKills, careerDeaths, careerAssists);

  // Table
  // data
  const {
    dpm,
    dpm_place,
    dtpm,
    dtpm_place,
    cs10,
    cs10_place,
    solokill,
    solokill_place,
    champion_pool,
    champion_pool_place,
    first_pick_rate,
  } = common;
  const {
    damage_place,
    lanePhase,
    damage,
    survival_place,
    survival,
    pr_place,
    killCatch_place,
    lanePhase_place,
    earlyFight,
    killCatch,
    earlyFight_place,
  } = detail;
  // rendering table
  const commonTable = [
    {
      label: commonLabels[0],
      data: champion_pool,
      place: champion_pool_place,
      tooltip: commonTooltipContents[0],
    },
    {
      label: commonLabels[1],
      data: `${first_pick_rate?.toFixed(1)}%`,
      tooltip: commonTooltipContents[1],
    },
    {
      label: commonLabels[2],
      data: solokill.toFixed(1),
      place: solokill_place,
      tooltip: commonTooltipContents[2],
    },
    {
      label: commonLabels[3],
      data: dpm.toFixed(1),
      place: dpm_place,
      tooltip: commonTooltipContents[3],
    },
    {
      label: commonLabels[4],
      data: dtpm.toFixed(1),
      place: dtpm_place,
      tooltip: commonTooltipContents[4],
    },
    {
      label: commonLabels[5],
      data: cs10.toFixed(1),
      place: cs10_place,
      tooltip: commonTooltipContents[5],
    },
  ];
  const detailTable = [
    {
      label: prLabels[0],
      data: pr.toFixed(1),
      place: pr_place,
      tooltip: detailTooltipContents[0],
    },
    {
      label: prLabels[1],
      data: damage?.toFixed(1),
      place: damage_place,
      tooltip: detailTooltipContents[1],
    },
    {
      label: prLabels[2],
      data: survival?.toFixed(1),
      place: survival_place,
      tooltip: detailTooltipContents[2],
    },
    {
      label: prLabels[3],
      data: killCatch?.toFixed(1),
      place: killCatch_place,
      tooltip: detailTooltipContents[3],
    },
    {
      label: prLabels[4],
      data: lanePhase?.toFixed(1),
      place: lanePhase_place,
      tooltip: detailTooltipContents[4],
    },
    {
      label: prLabels[5],
      data: earlyFight?.toFixed(1),
      place: earlyFight_place,
      tooltip: detailTooltipContents[5],
    },
  ];

  // chart
  const data = {
    labels: prLabels.filter((_, i) => i !== 0).map((row) => t(row)),
    datasets: [
      {
        data: detailTable.map((row) => Number(row.data)).filter((_, i) => i !== 0),
        fill: true,
        backgroundColor: 'rgba(89,66,186,0.2)',
        borderColor: '#8069e3',
        pointStyle: 'dash',
      },
    ],
  };

  return (
    <div>
      <Container>
        {/* 정보 요약 헤더 */}
        <Header>
          {/* 랭킹 */}
          <div className='player__rank'>{index + 1}</div>

          {/* 선수 이미지 */}
          <div className='player__img'>
            <img
              src={imgUrl(image)}
              alt='PlayerImage'
              className='PlayerImage'
              onError={(e) => {
                e.currentTarget.src = '/Images/player_error_image.png';
              }}
            />
          </div>

          {/* 팀, 선수이름 */}
          <div className='player__info'>
            <div className='player__team'>
              <Avatar src={`/Images/TeamLogo/${teamShort}.png`} size={20} circle={false} alt={team} />
              <span>{team}</span>
            </div>
            <span className='player__name'>{`${player} (${NativeName})`}</span>
          </div>

          {/* 경기수 */}
          <div className='player__games'>
            <div>{t('league.playerStat.played')}</div>
            <span>{total}</span>
          </div>

          {/* 승리 횟수 */}
          <div className='player__wins'>
            <div>{t('league.playerStat.winrate')}</div>
            <span>{wins}</span>
          </div>

          {/* KDA */}
          <div className='player__kda'>
            <div>{t('league.playerStat.kda')}</div>
            <div className='kda'>
              <span>{kills}</span>
              <em>/</em>
              <span>{deaths}</span>
              <em>/</em>
              <span>{assists}</span>
              <span className='rate'>{`${kda}:1`}</span>
            </div>
          </div>

          {/* PR - lpl는 일시 숨김  */}
          {league.indexOf('lpl') === -1 ? (
            <div className='player__sbr'>
              <div>{t('league.playerStat.sbr')}</div>
              <span>{pr.toFixed(1)}</span>
            </div>
          ) : (
            <div className='player__sbr' />
          )}
        </Header>

        {/* 정보 상세 페이지 */}
        <AnimatePresence>
          {isOpen && (
            <Contents
              initial={{ scale: 0.4, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ y: -20, opacity: 0 }}
            >
              {/* 상단 데이터 */}
              <DataBoxContainer>
                <DataBox>
                  <span className='data__title'>{t('league.playerStat.record')}</span>

                  {/* 시즌 정보  */}
                  <p className='data__main'>{winRate}</p>
                  <span className='data__info'>
                    {total}
                    {t('league.playerStat.games')}
                    &nbsp;{wins}
                    {t('league.playerStat.win')}
                    &nbsp;{total - wins}
                    {t('league.playerStat.lose')}
                  </span>
                </DataBox>

                <DataBox>
                  <span className='data__title'>{t('league.playerStat.kda')}</span>
                  <p className='data__main'>{kda}:1</p>
                  <span className='data__info'>
                    {k}/{d}/{a}
                    <em>
                      ({kills} / {deaths} / {assists})
                    </em>
                  </span>
                </DataBox>
                <DataBox>
                  <span className='data__title'>{t('league.playerStat.career')}</span>
                  <p className='data__main'>{careerWinRate}</p>
                  <span className='data__info'>
                    {careerTotal}
                    {t('league.playerStat.games')}
                    &nbsp;{careerWins}
                    {t('league.playerStat.win')}
                    &nbsp;{careerTotal - careerWins}
                    {t('league.playerStat.lose')}
                  </span>
                </DataBox>
                <DataBox>
                  <span className='data__title'>{t('league.playerStat.careerKda')}</span>
                  <p className='data__main'>{ckda}:1</p>
                  <span className='data__info'>
                    {ck}/{cd}/{ca}
                    <em>
                      ({careerKills} / {careerDeaths} / {careerAssists})
                    </em>
                  </span>
                </DataBox>
              </DataBoxContainer>

              {/* 하단 테이블 데이터 */}
              <DataBoxContainer>
                {/* common */}
                <DataTable>
                  <thead>
                    <tr>
                      <th colSpan={2}>{t('league.playerStat.commonStat')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {commonTable.map((row) => {
                      console.log(row);
                      return (
                        <tr>
                          <CustomTooltip title={t(row.tooltip)} placement='top'>
                            <td>{t(row.label)}</td>
                          </CustomTooltip>
                          <td>
                            {row.data}
                            {row.place && (
                              <span>
                                {` (${t('label.league')}
                                  ${row.place}
                                  ${t('league.playerStat.place')})
                                `}
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </DataTable>

                {/* detail */}
                <DataTable>
                  <thead>
                    <tr>
                      <th colSpan={2}>{t('league.playerStat.PRStat')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailTable.map((row) => (
                      <tr>
                        <CustomTooltip title={t(row.tooltip)} placement='top'>
                          <td>{t(row.label)}</td>
                        </CustomTooltip>
                        <td>
                          {row.data}
                          {row.place && (
                            <span>
                              {` (${t('label.league')}
                                ${t(row.place.toString())}
                                ${t('league.playerStat.place')})
                              `}
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </DataTable>
                <ChartContainer>
                  <Radar data={data} options={playerRadarOptions} plugins={[ChartDataLabels]} />
                </ChartContainer>
              </DataBoxContainer>
            </Contents>
          )}
        </AnimatePresence>

        <Button onClick={toggleOpen}>
          <Arrow
            css={{
              transform: `translateY(5px) ${isOpen ? 'rotate(180deg)' : ''}`,
            }}
            direction='B'
            size={5}
            color={colors.info}
          />
        </Button>
      </Container>
    </div>
  );
};

const Container = styled.div`
  position: relative;
  margin-top: 10px;
  background-color: ${colors.bg_select};
  border-radius: 15px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 40px);
  height: 80px;
  ${typoStyle.table_head};

  /* 기본스타일 */
  > div {
    > div:first-of-type {
      ${spacing.marginB(1)}
    }
    > span {
      display: block;
      text-align: center;
      ${typoStyle.player_title};
    }
  }

  /* 랭킹 */
  .player__rank {
    width: 70px;
    ${typoStyle.player_id};
    text-align: center;
  }

  /* 이미지 */
  .player__img {
    width: 100px;
    height: 100%;
    ${spacing.marginR(4)};

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  /* 선수정보  */
  .player__info {
    width: 255px;
    /* 팀 */
    .player__team {
      display: flex;

      align-items: center;
      > div {
        ${spacing.marginR(1)};
      }
    }
    /* 선수정보 - 선수이름 */
    .player__name {
      text-align: left;
      ${typoStyle.player_id};
      font-weight: bold;
    }
  }

  .player__wins,
  .player__games {
    width: 105px;
    text-align: center;
  }

  .player__kda {
    width: 250px;
    text-align: center;

    .kda {
      span {
        ${typoStyle.player_title};
      }
      em {
        ${spacing.paddingX(1)};
        color: #4b4758;
      }
      .rate {
        ${spacing.paddingL(2)}
        ${typoStyle.player_title};
        color: ${colors.badge_red};
        font-weight: bold;
      }
    }
  }

  .player__sbr {
    span {
      ${typoStyle.player_id};
      color: ${colors.badge_red};
    }
  }
`;
const Contents = styled(motion.div)`
  width: calc(100% - 40px);
  ${spacing.paddingX(5)};
  ${spacing.paddingY(5)};
`;
const DataBoxContainer = styled.div`
  display: flex;
  align-items: stretch;
  min-height: 120px;

  &:not(:last-of-type) {
    ${spacing.marginB(5)}
  }

  > div:not(:last-of-type) {
    ${spacing.marginR(5)}
  }

  > table {
    ${spacing.marginR(5)}
  }
`;
const DataBox = styled.div`
  ${typoStyle.contents_md};
  ${borderRadiusStyle[20]};
  ${spacing.padding(5)};
  background-color: ${colors.bg_box};
  flex: 1;
  flex-shrink: 1;
  min-width: 240px;

  span:first-of-type {
    display: block;
    ${spacing.marginB(1)};
  }
  p {
    ${typoStyle.percent};
  }
  span:last-of-type {
    em {
      ${spacing.paddingL(1)};
      ${typoStyle.info};
    }
  }
`;
const DataTable = styled.table`
  width: 373px;
  height: 230px;
  background-color: ${colors.bg_box};
  ${borderRadiusStyle[20]}
  overflow: hidden;

  thead {
    ${typoStyle.contents_title};
    th {
      padding: 15px;
    }
  }

  tbody {
    ${typoStyle.contents};
    tr {
      /* row background */
      &:nth-of-type(2n + 1) {
        background-color: ${colors.bg_checkbox};
      }
      /* last row padding */
      &:last-of-type {
        td {
          ${spacing.paddingB(2)};
        }
      }

      /* col style - common, col1, col2 */
      td {
        vertical-align: middle;
        ${spacing.paddingY(1)};
      }
      td:nth-of-type(1) {
        ${spacing.paddingL(5)};
        width: 220px;
      }
      td:nth-of-type(2) {
        ${spacing.paddingR(2)};
        text-align: left;
        width: calc(100% - 220px);
      }
    }
  }
`;
const ChartContainer = styled.div`
  flex: 1;
  align-self: stretch;
`;
const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 40px;
  background-color: ${colors.bg_box};
  border-radius: 0 15px 15px 0;
`;

export default LeaguePlayerInfo;
