import React, { useRef } from 'react';
import Tippy from '@tippy.js/react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Element, animateScroll as scroll, scroller } from 'react-scroll';
import GankingRoamingStatusBox from './StatusOption/GankingRoamingStatusBox';
import GameReportToolTip from '../../Common/GameReportToolTip';
import TeamFightStatusBox from './StatusOption/TeamFightStatusBox';
import TeleportStatusBox from './StatusOption/TeleportStatusBox';
import { ActionLog } from '../../../../../services/GameServices';

import GameDatasetAtom from '../../../../../recoil/ProGameReport/gameDatasetAtom';
import GameAtom from '../../../../../recoil/ProGameReport/gameAtom';

const StatusLogBox = () => {
  const statusLog = useRecoilValue(GameDatasetAtom.statusLogDataset);
  const statusLogActiveIdx = useRecoilValue(GameAtom.statusLogActiveIdx);
  const setSeekTime = useSetRecoilState(GameAtom.seekTime);
  const { t } = useTranslation();

  if (statusLog[0].type !== 'NONE') {
    statusLog.unshift({
      type: 'NONE',
      realCount: 0,
      data: {
        participant: 0,
        dest: 0,
        position: '',
        realCount: 0,
        partis: [0],
      },
    });
  }

  const autoMoveScroll = (idx: number) => {
    if (idx > 1) {
      scroller.scrollTo(`status-log-${idx - 2}`, {
        duration: 500,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'statusLogContentBox',
      });
    } else {
      scroll.scrollToTop({
        duration: 500,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'statusLogContentBox',
      });
    }
  };

  useEffect(() => {
    autoMoveScroll(statusLogActiveIdx);
  }, [statusLogActiveIdx]);

  const typeCase = (type: string, data: ActionLog, isActive: boolean, idx: number) => {
    switch (type) {
      case 'Ganking':
        return (
          <Element name={`status-log-${idx}`} className='element'>
            <button type='button' className='click-div' onClick={() => onClick(data.realCount)}>
              <GankingRoamingStatusBox gankingData={data} isActive={isActive} id={type.toLowerCase()} />
            </button>
          </Element>
        );
      case 'Roaming':
        return (
          <Element name={`status-log-${idx}`} className='element'>
            <button type='button' className='click-div' onClick={() => onClick(data.realCount)}>
              <GankingRoamingStatusBox gankingData={data} isActive={isActive} id={type.toLowerCase()} />
            </button>
          </Element>
        );
      case 'matchLog':
        return (
          <Element name={`status-log-${idx}`} className='element'>
            <button type='button' className='click-div' onClick={() => onClick(data.realCount)}>
              <TeamFightStatusBox TFdata={data} isActive={isActive} />
            </button>
          </Element>
        );
      case 'teleport':
        return (
          <Element name={`status-log-${idx}`} className='element'>
            <button type='button' className='click-div' onClick={() => onClick(data.realCount)}>
              <TeleportStatusBox TPData={data} isActive={isActive} />
            </button>
          </Element>
        );
      default:
        return <></>;
    }
  };

  const onClick = (time: number) => {
    setSeekTime(time / 2);
  };

  return (
    <LogDetailContainer>
      <LogTitle>
        <span>{t('game.log.status.subject')}</span>
        <StyledTippy
          duration={0}
          delay={[100, 0]}
          content={<GameReportToolTip tooltipInfo={t('game.log.status.tooltipInfo')} />}
          placement='top'
        >
          <img src='/Images/ico-question-mark.svg' alt='question' />
        </StyledTippy>
      </LogTitle>
      <LogContentBox id='statusLogContentBox'>
        {statusLog.map((data: ActionLog, idx, arr) => {
          return typeCase(data.type, data, idx === statusLogActiveIdx, idx);
        })}
      </LogContentBox>
    </LogDetailContainer>
  );
};

export default StatusLogBox;

const LogDetailContainer = styled.div`
  width: 200px;
  height: 502px;
  margin: 0px 14px 13px 26px;
  padding: 10px 10px 0;
  border-radius: 20px;
  background-color: #23212a;
  overflow: hidden;
`;

const LogTitle = styled.div`
  width: auto;
  height: 21px;
  span {
    font-family: SpoqaHanSansNeo;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.63;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  img {
    width: 15px;
    height: 15px;
    margin: -5px 0 0px 5px;
    object-fit: contain;
    vertical-align: middle;
  }
`;

const StyledTippy = styled(Tippy)``;

const LogContentBox = styled.div`
  width: 180px;
  height: 91%;
  margin: 10px 0 0;
  overflow-y: scroll;
  overflow-x: hidden;

  .click-div {
    padding: 0px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
