import React, { memo, ReactNode, SyntheticEvent } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Autorenew } from '@mui/icons-material';
import { Box, Button, ButtonProps, FormLabel, InputBase, styled, SvgIcon } from '@mui/material';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { colors, spacing } from '../../../Styles/ui';
import { getTrueValueList } from '../../../lib/getTureValueList';
import { FilterData } from '../../../Pages/Filtertest/filter.type';
import { t } from 'i18next';
import { MAP_FILTER_MEMU } from '../../../lib/mapping/map_filter_menu';

type RenderList = {
  value: string;
  label?: string;
};

interface CustomSelectProps {
  placeholder?: string | ReactJSXElement;
  onLabelClick?: () => void;
  renderList: RenderList[];
  data: FilterData | undefined;
  setData: React.Dispatch<React.SetStateAction<FilterData | undefined>> | SetterOrUpdater<FilterData>;
  step?: number;
}

export const CustomSelect = ({
  placeholder = 'PlaceHolder',
  onLabelClick,
  renderList,
  data,
  setData,
  step,
}: CustomSelectProps) => {
  const handleReset = () => {
    setData({});
  };
  const handleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    setData({ [value]: true });
  };

  const trueData = data && getTrueValueList(data);

  const handleClick = (e: SyntheticEvent<unknown>) => {
    e.preventDefault();
    if (onLabelClick) {
      onLabelClick();
    }
  };

  return (
    <FormControl fullWidth>
      <StyledFormLabel onClick={handleClick}>
        <TEXT>
          {step && <STEP>STEP{step}.</STEP>}
          <span>{placeholder}</span>
        </TEXT>

        <MUIRESETBUTTON size='small' onClick={handleReset} color='primary'>
          <SvgIcon
            sx={{
              fontSize: '12px',
            }}
          >
            <Autorenew />
          </SvgIcon>
          {t(MAP_FILTER_MEMU.리셋)}
        </MUIRESETBUTTON>
      </StyledFormLabel>
      <MUISELECT
        displayEmpty
        input={<StyledInputBase />}
        value={(trueData && trueData[0]) ?? ''}
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: colors.배경색[900],
              color: 'white',
              maxHeight: '180px',
              overflow: 'overlay',
              scrollbarWidth: '3px',
            },
          },
        }}
        onChange={handleChange}
        renderValue={(value: string[]) => {
          if (!value || value?.length === 0) {
            return <em>{placeholder}</em>;
          }
          return <em className='data'>{trueData}</em>;
        }}
      >
        <MUIMENUITEM disabled value=''>
          <em>{placeholder}</em>
        </MUIMENUITEM>
        {renderList &&
          renderList.map((list) => (
            <MUIMENUITEM key={list.value} value={list.value}>
              {list.label ?? list.value}
            </MUIMENUITEM>
          ))}
      </MUISELECT>
    </FormControl>
  );
};

export default memo(CustomSelect);

const MUISELECT = styled(Select)`
  padding: 0;
  .MuiSvgIcon-root {
    color: ${colors.white};
  }
  .data {
    color: white;
  }
`;

const MUIMENUITEM = styled(MenuItem)`
  font-size: 13px;
`;

const StyledFormLabel = styled(FormLabel)`
  color: white;
  font-size: 14px;

  &.MuiFormLabel-root.Mui-focused {
    color: white;
  }

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  &.MuiFormLabel-filled ~ div {
    background: rgba(89, 66, 186, 0.2);
    border: 1px solid ${colors.보라색[500]};
  }
`;

const TEXT = styled(Box)`
  display: flex;
`;

const STEP = styled(Box)`
  color: ${colors.배경색[200]};
  font-size: 11px;
  ${spacing.marginR(1)};
`;

const MUIRESETBUTTON = styled(Button)<ButtonProps>(({ theme }) => ({
  minWidth: 0,
  fontSize: '10px',
  padding: '5px 5px',
  color: colors.배경색[100],
}));

const StyledInputBase = styled(InputBase)`
  border-radius: 10px;
  position: relative;
  font-size: 13px;
  height: 40px;
  color: #79797c;
  background-color: ${colors.배경색[600]};
  transition: background 0.2s ease-in-out;

  & .MuiInputBase-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 16px;
  }

  &:focus {
    border-radius: 10px;
    border-color: ${colors.point};
    box-shadow: 0 0 0 0.1rem inset ${colors.point};
  }
`;
