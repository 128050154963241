import axios from 'axios';
import { API } from '../Pages/config';
import { store } from '../index';
import { ISeason } from '../recoil/Filter/leagueReportAtom';

// 1. () => 리그데이터에서 연도선택값 가져오기
export const findYearData = (leagueData: any) => () => {
  if (typeof leagueData !== 'object') return [];
  let yearList = <string[]>[];
  Object.keys(leagueData).forEach((key: string) => {
    yearList = Object.keys(leagueData[key]).concat(yearList);
  });
  const set = new Set(yearList);
  return Array.from(set).sort();
};
// 2. (연도) => 리그데이터에서 리그선택값 가져오기
export const findLegueData = (leagueData: any) => (years: string[] | undefined) => {
  if (typeof years !== 'object') {
    return [];
  }

  const leagueList = <string[]>[];

  years.forEach((year: string) => {
    Object.keys(leagueData).forEach((key: string) => {
      if (Object.prototype.hasOwnProperty.call(leagueData[key], year)) {
        leagueList.push(key);
      }
    });
  });

  const set = new Set(leagueList);
  return Array.from(set).sort();
};

// 3. (연도,리그) => 리그데이터에서 시즌선택값 가져오기
export const findSeasonData =
  (leagueData: any) => (years: string[] | undefined, leagues: string[] | undefined) => {
    if (typeof years !== 'object' || typeof leagues !== 'object') {
      return [];
    }
    let seasons = <{ name: string | number | symbol; patch: string[] }[]>[];

    years.forEach((year: string) => {
      leagues.forEach((league: string) => {
        const yearsInfo = leagueData[league][year];
        if (yearsInfo) {
          const seasonKeys = (Object.keys(yearsInfo) as Array<keyof typeof yearsInfo>).map((season) => {
            const patch = Object.keys(yearsInfo[season]);
            return {
              name: season,
              patch,
            };
          });
          seasons = seasons.concat(seasonKeys);
        }
      });
    });

    const set = new Set(seasons);
    return Array.from(set).sort() as ISeason[];
  };
// 4. (연도,리그,시즌) 로 리그데이터에서 팀선택값 가져오기
export const findTeamData =
  (leagueData: any) =>
  (
    years: string[] | undefined,
    leagues: string[] | undefined,
    seasons: string[] | undefined,
    patchs: string[] | undefined,
  ) => {
    if (
      typeof years !== 'object' ||
      typeof leagues !== 'object' ||
      typeof seasons !== 'object' ||
      typeof patchs !== 'object'
    ) {
      return [];
    }

    let teams = <string[]>[];

    years.forEach((year: string) => {
      leagues.forEach((league: string) => {
        seasons.forEach((season: string) => {
          const seasonData = leagueData[league][year][season];
          if (seasonData) {
            patchs.forEach((patch: string) => {
              const patchData = seasonData[patch];
              if (patchData) {
                const teamKeys = Object.keys(patchData);
                teams = teams.concat(teamKeys);
              }
            });
          }
        });
      });
    });

    const set = new Set(teams);
    return Array.from(set).sort();
  };

// 5. (연도,리그,시즌) 리그데이터에서 패치선택값 받아오기
// 5. (연도,리그,시즌) 리그데이터에서 패치선택값 받아오기
export const getPatchData = (seasons: ISeason[]) => {
  const patchs: string[] = [];

  seasons.forEach((season: ISeason) => {
    patchs.push(...season.patch);
  });
  const set = new Set(patchs);

  return Array.from(set);
};

// 6. 선수 데이터

type Player = {
  position: 'top' | 'jng' | 'mid' | 'bot' | 'sup';
  name: string;
};

export const findPlayerData =
  (leagueData: any) =>
  (years?: string[], leagues?: string[], seasons?: string[], patchs?: string[], teams?: string[]) => {
    const players = <Player[]>[];
    let playerList = <string[]>[];

    //* parameter가 하나라도 없으면 빈 배열 반환
    if (!years || !leagues || !seasons || !patchs || !teams) {
      return [];
    }

    years.forEach((year: string) => {
      leagues.forEach((league: string) => {
        seasons.forEach((season: string) => {
          const seasonData = leagueData[league][year][season];
          if (seasonData) {
            patchs.forEach((patch: string) => {
              const patchData = seasonData[patch];
              if (patchData) {
                teams.forEach((team) => {
                  if (patchData[team]) {
                    const playerValues = <string[]>Object.values(patchData[team]);
                    playerList = playerList.concat(playerValues);
                  }
                });
              }
            });
          }
        });
      });
    });

    playerList = playerList.filter((item, pos) => playerList.indexOf(item) === pos).sort();

    for (let i = 0; i < playerList.length; i += 1) {
      const name = playerList[i].split('#')[1];
      const position = playerList[i].split('#')[0];
      if (position === '1') {
        players[i] = {
          position: 'top',
          name,
        };
      } else if (position === '2') {
        players[i] = {
          position: 'jng',
          name,
        };
      } else if (position === '3') {
        players[i] = {
          position: 'mid',
          name,
        };
      } else if (position === '4') {
        players[i] = {
          position: 'bot',
          name,
        };
      } else if (position === '5') {
        players[i] = {
          position: 'sup',
          name,
        };
      }
    }

    return players;
  };
