import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export const getLanguage = (navigatorLang: string) => {
  if (navigatorLang.includes('ko')) {
    return 'ko';
  }
  if (navigatorLang.includes('ja')) {
    return 'ja';
  }
  if (navigatorLang.includes('zh')) {
    return 'zh';
  }
  return 'en';
};

export const getLanguageLabel = (navigatorLang: string) => {
  if (navigatorLang.includes('ko')) {
    return '한국어';
  }
  if (navigatorLang.includes('ja')) {
    return '日本語';
  }
  if (navigatorLang.includes('zh')) {
    return '简体中文';
  }
  return 'English';
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: sessionStorage.getItem('i18nextLng') ?? getLanguage(navigator.language),
    debug: false,
    detection: {
      order: ['sessionStorage'],
      caches: ['sessionStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
