/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useMemo } from 'react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { colors, spacing } from '../../../Styles/ui';
import BanBox from '../../TeamReport/GameBanPick/Component/BanBox';
import PickBox from '../../TeamReport/GameBanPick/Component/PickBox';
import { ScrimViewData } from '../../../services/ScrimServices';
import * as S from './ScrimResultItem.style';
import { ITeamDraftPick, ITeamDraftSide } from '../../../services/types/team_services.type';

export interface ITeamDraftPickAddSide extends ITeamDraftPick {
  side: string;
}

interface P {
  data: ITeamDraftSide;
  color: 'red' | 'blue';
  pick: ITeamDraftPickAddSide[];
}

const ScrimDraftBox: React.FC<P> = ({ data, color, pick }) => {
  const { t } = useTranslation();
  const uniqueKey = data.team + [...data.pick.map((item) => item?.champion)].join(' ') + data.result;

  return (
    <SUMMARY isBlue={color === 'blue'}>
      <COL1>
        <S.WinLabel isWin={data.result === 1}>
          {t(`team.draftGame.${data.result === 1 ? 'victory' : 'defeat'}`)}
        </S.WinLabel>
        <div className='team-color txt'>{t(`team.draftGame.${color}Team`)}</div>
      </COL1>
      <COL2>
        <img
          src={data.team === '관리자' ? `/Images/team/ico_team_tsb.svg` : `/Images/TeamLogo/${data.team}.png`}
          alt=''
        />
        <div className='team-name txt'>{data.team === '관리자' ? 'TSB' : data.team}</div>
      </COL2>
      <COL3>
        {data.ban.slice(0, 3).map((champion, index) => {
          return <BanBox key={`${uniqueKey} ban_${champion} ${index}`} champion={champion} />;
        })}
      </COL3>
      <LINE />

      <COL4>
        {pick.slice(0, 6).map((data, index) => {
          return data.side === color ? (
            <PickBox key={`${uniqueKey} pick_${data.champion} ${index}`} color={color} data={data} />
          ) : (
            <BLANK key={`${uniqueKey} pick_${data.champion} ${index}`} className='none-img' />
          );
        })}
      </COL4>

      <LINE />

      <COL5>
        {data.ban.slice(3, 5).map((champion, index) => {
          return <BanBox key={`${uniqueKey} ban_${champion} ${index}`} champion={champion} />;
        })}
      </COL5>
      <LINE />

      <COL4>
        {pick.slice(6, 10).map((data, index) => {
          return data.side === color ? (
            <PickBox key={`${uniqueKey} pick_${data.champion} ${index}`} color={color} data={data} />
          ) : (
            <BLANK key={`${uniqueKey} pick_${data.champion} ${index}`} />
          );
        })}
      </COL4>
    </SUMMARY>
  );
};

export default ScrimDraftBox;

const SUMMARY = styled.div<{ isBlue?: boolean }>`
  position: relative;
  width: 1069px;
  height: 80px;
  padding: 0 0 0 20px;
  border-radius: 10px;
  background-color: ${colors.bg_light};
  display: flex;
  align-items: center;
  color: white;
  justify-content: flex-start;

  &::before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    width: 4px;
    height: 56px;
    border-radius: 4px;
    background-color: ${({ isBlue }) => (isBlue ? colors.blue : colors.badge_red)};
  }
`;

const COL1 = styled.div`
  flex: 0 0 103px;
  display: flex;
  .team-color {
    font-size: 15px;
    line-height: 1;
  }
`;

const COL2 = styled.div`
  display: flex;
  flex: 0 0 130px;
  padding-left: 10px;

  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-right: 8px;
  }

  .team-name {
    display: flex;
    align-items: center;
  }
`;

const COL3 = styled.div`
  flex: 0 0 116px;
  width: max-content;
  display: flex;
  align-items: center;
  img {
    width: 36px;
    height: 36px;
    mix-blend-mode: luminosity;
    border-radius: 50%;
    margin-right: 4px;
  }
`;

const COL4 = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const COL5 = styled(COL3)`
  flex: 0 0 80px;
`;

const LINE = styled.div`
  flex: 0 0 1px;
  height: 40px;
  margin: 0 8px;
  background-color: ${colors.vs};
`;

const BLANK = styled.div`
  flex: 0 0 32.5px;
`;
