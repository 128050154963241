import { atom } from 'recoil';

type JungleSideFilterResult = {
  player: string;
  champion: string[];
  oppchampion: string[];
  type: 0 | 1 | 2 | 3;
  gameid: string[];
};

export const jungleSideFilterResultAtom = atom<JungleSideFilterResult>({
  key: 'JungleSideFilterResult',
  default: undefined,
});
