import React, { useEffect, useReducer, useState, Reducer } from 'react';
import { flushSync } from 'react-dom';
import { Box, Button, Radio, styled as MUIStyled, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useMutation, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { usePrompt } from '../../../Hooks/usePrompt';
import { useModal } from '../../../Hooks';
import { TRANSLATE_SCRIM_TITLE } from '../../../lib/translation/translate_scrim';
import { modalList } from '../../../Components/Modals/Modals';
import { colors, spacing, typoStyle } from '../../../Styles/ui';
import SearchChampion from '../components/SearchChampion';
import ScrimService from '../../../services/ScrimServices';
import { userAtom } from '../../../recoil/Auth/userAtom';
import { MUICalendar } from '../../../Components/Ui/Calendar/MUICalendar';
import ScrimServices, {
  EditParams,
  ScrimAction,
  ScrimData,
  ScrimViewData,
} from '../../../services/ScrimServices';

const findGameData = (teamName: string, state: ScrimViewData) => {
  if (state.blue.team === teamName) {
    return {
      userSide: state.blue.teamid,
      userTeam: state.blue.team,
      oppTeam: state.red.team,
      oppSide: state.red.teamid,
    };
  }
  return {
    userSide: state.red.teamid,
    userTeam: state.red.team,
    oppTeam: state.blue.team,
    oppSide: state.blue.teamid,
  };
};

const ScrimEdit: React.FC = () => {
  const { t } = useTranslation(['translation', 'scrim']);
  const { state: locationState } = useLocation();
  const param = useParams();
  const queryClient = useQueryClient();
  const [calendar, setCalendar] = useState<number>();
  const [state, dispatch] = useReducer(
    ScrimServices.ScrimReducer as Reducer<ScrimData, ScrimAction>,
    ScrimServices.makeNewScrimData(),
  );
  const [gameResult, setGameResult] = useState<number>();
  const user = useRecoilValue(userAtom);
  const gameid = (locationState as { id: string } | undefined)?.id || param?.id;
  const { data, mutate, isError, isLoading } = useMutation(
    ({ gameid, userTeam }: { gameid: string; userTeam: string }) =>
      ScrimService.getScrimId({ gameid, userTeam }),
    {
      onSuccess: (data) => {
        if (data.response[0]) {
          dispatch({ type: 'LOAD', payload: data.response[0] });
          setGameResult(data.response[0].blue.result === 1 ? 100 : 200);
          setCalendar(Number(data.response[0].date) * 1000);
        }
      },
    },
  );

  // 모달 로직
  const [isBlock, setIsBlock] = useState(true);
  const navigate = useNavigate();
  const { openModal } = useModal();

  // 수정
  usePrompt(t('모달.취소', { ns: 'scrim' }), isBlock);

  useEffect(() => {
    if (user && gameid) {
      mutate({ gameid, userTeam: user.teamName });
    }
  }, [gameid, user, mutate]);

  // 밴,픽 챔피언 선택
  const selectChampion = (
    _: any,
    value: { ckey: number; cname: string; cid: string },
    type: ScrimAction['type'],
  ) => {
    const champNo = value?.ckey ?? null;

    dispatch({
      type,
      payload: champNo,
    });
  };
  // 포지션 설정
  const handlePosition = (position: number, positionType: ScrimAction['type']) => {
    dispatch({
      type: positionType,
      payload: position,
    });
  };
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGameResult(Number(event.target.value));
  };
  const handleCalendar = (date: string | undefined) => {
    setCalendar(dayjs(date).unix() * 1000);
  };

  // 양 팀 픽,밴 챔피언 리스트 배열
  const 픽밴중복자리 = ScrimServices.픽밴중복챔피언찾기(state);
  const 블루중복자리 = ScrimServices.중복포지션찾기(state, 'blue');
  const 레드중복자리 = ScrimServices.중복포지션찾기(state, 'red');
  const 픽밴중복메세지 = ScrimServices.픽밴중복메세지변환(픽밴중복자리);
  const 블루포지션중복메세지 = ScrimServices.포지션중복메세지변환(블루중복자리, 'blue');
  const 레드포지션중복메세지 = ScrimServices.포지션중복메세지변환(레드중복자리, 'red');

  // 중복 체크 여부
  const isSelected = () => {
    const { pick: bluePick } = state[100];
    const { pick: redPick } = state[200];

    const blueChamp = bluePick.map((list) => list.champ);
    const redChamp = redPick.map((list) => list.champ);

    // 픽
    const championPickList = [...blueChamp, ...redChamp];
    const championPickSet = new Set(championPickList);

    // 포지션
    const bluePos = bluePick.map((list) => list.pos);
    const bluePosSet = new Set(bluePos);
    const redPos = redPick.map((list) => list.pos);
    const redPosSet = new Set(redPos);

    // 승리, 패배 팀이 선택되지 않은 경우
    if (!gameResult) {
      return TRANSLATE_SCRIM_TITLE.gameResultNotSelected;
    }

    if (championPickSet.has(undefined)) {
      return TRANSLATE_SCRIM_TITLE.champNotSelected;
    }

    if (bluePosSet.has(undefined) || redPosSet.has(undefined)) {
      return TRANSLATE_SCRIM_TITLE.positionNotSelected;
    }

    return false;
  };
  const isDuplicate = () => {
    const { ban: blueBan, pick: bluePick } = state[100];
    const { ban: redBan, pick: redPick } = state[200];
    const blueChamp = bluePick.map((list) => list.champ);
    const bluePos = bluePick.map((list) => list.pos);
    const redChamp = redPick.map((list) => list.champ);
    const redPos = redPick.map((list) => list.pos);

    // 픽
    const championPickList = [...blueChamp, ...redChamp];
    const championPickSet = new Set(championPickList);

    // 밴
    const banList = [
      ...blueBan.map((num) => (num === 0 ? undefined : num)),
      ...redBan.map((num) => (num === 0 ? undefined : num)),
    ];
    const banListWithoutUndefinedList = banList.filter((item) => item !== undefined && item !== null);
    const banSet = new Set(banListWithoutUndefinedList);

    // 포지션
    const bluePosSet = new Set(bluePos);
    const redPosSet = new Set(redPos);

    // 총 챔피언 선택값
    const champList = [...championPickList, ...banListWithoutUndefinedList];
    const champListSet = new Set(champList);

    // 밴 중복
    if (banListWithoutUndefinedList.length !== banSet.size) {
      return TRANSLATE_SCRIM_TITLE.duplicateBan;
    }

    // 픽 중복
    if (championPickSet.size !== championPickList.length) {
      return TRANSLATE_SCRIM_TITLE.duplicatePick;
    }

    // 픽, 밴이 중복일 경우
    if (champList.length !== champListSet.size) {
      return TRANSLATE_SCRIM_TITLE.duplicateBanPickChamp;
    }

    // 블루팀에서 중복된 포지션이 있을 경우
    if (bluePosSet.size !== 5) {
      return TRANSLATE_SCRIM_TITLE.duplicateBluePos;
    }
    // 레드팀에 중복된 포지션이 있을 경우
    if (redPosSet.size !== 5) {
      return TRANSLATE_SCRIM_TITLE.duplicateRedPos;
    }

    return false;
  };
  const MESSAGE = isSelected() || isDuplicate();

  // 버튼 로직
  const handleSubmitScrim = async () => {
    if (data && user && calendar) {
      const { userSide, userTeam, oppTeam, oppSide } = findGameData(
        user?.teamName as string,
        data.response[0],
      );

      const params = {
        gameid,
        date: Number(calendar / 1000).toFixed(0),
        result: Number(gameResult),
        user: { ...state[userSide], team: userTeam, teamid: userSide },
        opp: {
          ...state[oppSide],
          team: oppTeam,
          teamid: oppSide,
        },
      };

      openModal(modalList.confirm, {
        text: t('모달.수정완료', { ns: 'scrim' }),
        onSubmit: () => {
          ScrimService.editScrim(params as EditParams)
            .then(() => {
              flushSync(() => {
                queryClient.invalidateQueries(['@scrim', 'result']);
                setIsBlock(false);
              });
            })
            .then(() => {
              navigate('/scrim');
            })
            .catch(() => console.error('통신에러'));
        },
      });
    }
  };
  const handleCancel = () => {
    navigate('/scrim');
  };

  return (
    <div>
      {/* 페이지가 없을때 */}
      {data && (
        <>
          <Box sx={{ mb: 6 }}>
            <Typography variant='h2' sx={{ mb: 5, fontSize: 24, color: 'white', fontWeight: 700 }}>
              {t('스크림결과경기수정', { ns: 'scrim' })}
            </Typography>
            <STEPCONTAINER style={{ marginBottom: 4 }}>
              <STEP>step 1.</STEP>
              {t(TRANSLATE_SCRIM_TITLE.setDate)}
            </STEPCONTAINER>
            <MUICalendar onChange={handleCalendar} value={calendar ?? null} />
          </Box>

          <GAMEINFO>
            <div>
              {user?.teamName === '관리자' ? 'TSB' : user?.teamName}
              &nbsp;&nbsp;
              <span style={{ color: '#6b6979' }}>VS</span>
              &nbsp;&nbsp;
              {/* 2022.06.14 임시코드 리그가 챌린져스면 렌더링 되었을때 Challengers 스트링이 추가로 붙게 쳐리함 */}
              {user?.teamName !== data.response[0].blue.team
                ? data.response[0].blue.team
                : data.response[0].red.team}
            </div>
            <ERROR_MESSAGE>{MESSAGE === false ? ' ' : t(MESSAGE)}</ERROR_MESSAGE>
            <div>{dayjs(Number(data.response[0].date) * 1000).format('YYYY-MM-DD')}</div>
          </GAMEINFO>

          <INPUTBOX_WRAPPER>
            {/* ######## 블루 ######## */}

            <INPUTBOX>
              <TEAMINFO checked={gameResult === 100}>
                <Radio
                  name='result'
                  value={100}
                  onChange={handleRadioChange}
                  checked={gameResult === 100}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      border: gameResult !== 100 ? '1px solid #383543' : '',
                      borderRadius: gameResult !== 100 ? '50%' : '',
                      fontSize: 18,
                    },
                  }}
                />
                <img
                  src={
                    data.response[0].blue.team === '관리자'
                      ? '/Images/team/ico_team_tsb.svg'
                      : `/Images/TeamLogo/${data.response[0].blue.team}.png`
                  }
                  alt={data.response[0].blue.team}
                />
                <span>{data.response[0].blue.team === '관리자' ? 'TSB' : data.response[0].blue.team}</span>
                {gameResult && (
                  <span style={{ marginLeft: '4px' }}>
                    {gameResult === 100 ? t(TRANSLATE_SCRIM_TITLE.win2) : t(TRANSLATE_SCRIM_TITLE.lose2)}
                  </span>
                )}
              </TEAMINFO>
              <TEAMCOLOR color='blue'>
                {t(TRANSLATE_SCRIM_TITLE.blue)} {t(TRANSLATE_SCRIM_TITLE.team)}
              </TEAMCOLOR>
              {/* 밴픽 입력 */}
              <BANPICKINPUT>
                <BAN>
                  <div className='title'>{t(TRANSLATE_SCRIM_TITLE.FirstPhaseBan)}</div>
                  <div style={{ display: 'flex', width: '100%' }}>
                    {ScrimServices.BlueBan.slice(0, 3).map((ban, idx) => {
                      return (
                        <BAN_CONTAINER>
                          <SearchChampion
                            id={ban}
                            championState={state[100].ban[idx]}
                            handleChange={selectChampion}
                            error={픽밴중복메세지.includes(ban)}
                          />
                        </BAN_CONTAINER>
                      );
                    })}
                  </div>
                </BAN>
                <PICK>
                  <div className='title'>{t(TRANSLATE_SCRIM_TITLE.FirstPhasePick)}</div>
                  {ScrimServices.BluePickPos.slice(0, 6).map((el) => {
                    return (
                      <PICK_CONTAINER>
                        {el ? (
                          <SearchChampion
                            isPick
                            id={el.pick}
                            error={픽밴중복메세지.includes(el.pick)}
                            posError={블루포지션중복메세지.includes(el.pos)}
                            positionState={state[100].pick[el.index].pos}
                            championState={state[100].pick[el.index].champ}
                            handleChange={selectChampion}
                            positionType={el.pos}
                            handlePosChange={handlePosition}
                          />
                        ) : (
                          <DisabledInput>
                            <div className='champImg' />
                            <div className='input' />
                            {Array.from({ length: 5 }).map((_, i) => {
                              return <div className='positions' key={i} />;
                            })}
                          </DisabledInput>
                        )}
                      </PICK_CONTAINER>
                    );
                  })}
                </PICK>
                <BAN>
                  <div className='title'>{t(TRANSLATE_SCRIM_TITLE.SecondPhaseBan)}</div>
                  <div style={{ display: 'flex', width: '100%' }}>
                    {ScrimServices.BlueBan.slice(3).map((ban, idx) => {
                      return (
                        <BAN_CONTAINER>
                          <SearchChampion
                            id={ban}
                            championState={state[100].ban[idx + 3]}
                            handleChange={selectChampion}
                            error={픽밴중복메세지.includes(ban)}
                          />
                        </BAN_CONTAINER>
                      );
                    })}
                  </div>
                </BAN>
                <PICK>
                  <div className='title'>{t(TRANSLATE_SCRIM_TITLE.SecondPhasePick)}</div>
                  {ScrimServices.BluePickPos.slice(6).map((el) => {
                    return (
                      <PICK_CONTAINER>
                        {el ? (
                          <SearchChampion
                            isPick
                            id={el.pick}
                            handleChange={selectChampion}
                            positionType={el.pos}
                            positionState={state[100].pick[el.index].pos}
                            championState={state[100].pick[el.index].champ}
                            error={픽밴중복메세지.includes(el.pick)}
                            posError={블루포지션중복메세지.includes(el.pos)}
                            handlePosChange={handlePosition}
                          />
                        ) : (
                          <DisabledInput>
                            <div className='champImg' />
                            <div className='input' />
                            {Array.from({ length: 5 }).map((_, i) => {
                              return <div className='positions' key={i} />;
                            })}
                          </DisabledInput>
                        )}
                      </PICK_CONTAINER>
                    );
                  })}
                </PICK>
              </BANPICKINPUT>
            </INPUTBOX>

            <div style={{ color: '#fff', marginTop: 24 }}>VS</div>

            {/* ######## 래드 ######## */}
            <INPUTBOX>
              <TEAMINFO checked={gameResult === 200}>
                <Radio
                  name='result'
                  value={200}
                  onChange={handleRadioChange}
                  checked={gameResult === 200}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      border: gameResult !== 200 ? '1px solid #383543' : '',
                      borderRadius: gameResult !== 200 ? '50%' : '',
                      fontSize: 18,
                    },
                  }}
                />
                <img
                  src={
                    data.response[0].red.team === '관리자'
                      ? '/Images/team/ico_team_tsb.svg'
                      : `/Images/TeamLogo/${data.response[0].red.team}.png`
                  }
                  alt={data.response[0].red.team}
                />
                <span>{data.response[0].red.team === '관리자' ? 'TSB' : data.response[0].red.team}</span>
                {gameResult && (
                  <span style={{ marginLeft: '4px' }}>
                    {gameResult === 100 ? t(TRANSLATE_SCRIM_TITLE.win2) : t(TRANSLATE_SCRIM_TITLE.lose2)}
                  </span>
                )}
              </TEAMINFO>
              {/* 팀컬러 */}
              <TEAMCOLOR color='red'>
                {t(TRANSLATE_SCRIM_TITLE.red)} {t(TRANSLATE_SCRIM_TITLE.team)}
              </TEAMCOLOR>
              {/* 밴픽 입력 */}
              <BANPICKINPUT>
                <BAN>
                  <div className='title'>{t(TRANSLATE_SCRIM_TITLE.FirstPhaseBan)}</div>
                  <div style={{ display: 'flex', width: '100%' }}>
                    {ScrimServices.RedBan.slice(0, 3).map((ban, idx) => {
                      return (
                        <BAN_CONTAINER>
                          <SearchChampion
                            id={ban}
                            championState={state[200].ban[idx]}
                            handleChange={selectChampion}
                            error={픽밴중복메세지.includes(ban)}
                          />
                        </BAN_CONTAINER>
                      );
                    })}
                  </div>
                </BAN>
                <PICK>
                  <div className='title'>{t(TRANSLATE_SCRIM_TITLE.FirstPhasePick)}</div>
                  {ScrimServices.RedPickPos.slice(0, 6).map((el) => {
                    return (
                      <PICK_CONTAINER>
                        {el ? (
                          <SearchChampion
                            isPick
                            id={el.pick}
                            handleChange={selectChampion}
                            positionType={el.pos}
                            championState={state[200].pick[el.index].champ}
                            positionState={state[200].pick[el.index].pos}
                            handlePosChange={handlePosition}
                            error={픽밴중복메세지.includes(el.pick)}
                            posError={레드포지션중복메세지.includes(el.pos)}
                          />
                        ) : (
                          <DisabledInput>
                            <div className='champImg' />
                            <div className='input' />
                            {Array.from({ length: 5 }).map((_, i) => {
                              return <div className='positions' key={i} />;
                            })}
                          </DisabledInput>
                        )}
                      </PICK_CONTAINER>
                    );
                  })}
                </PICK>
                <BAN>
                  <div className='title'>{t(TRANSLATE_SCRIM_TITLE.SecondPhaseBan)}</div>
                  <div style={{ display: 'flex', width: '100%' }}>
                    {ScrimServices.RedBan.slice(3).map((ban, idx) => {
                      return (
                        <BAN_CONTAINER>
                          <SearchChampion
                            id={ban}
                            championState={state[200].ban[idx + 3]}
                            handleChange={selectChampion}
                            error={픽밴중복메세지.includes(ban)}
                          />
                        </BAN_CONTAINER>
                      );
                    })}
                  </div>
                </BAN>
                <PICK>
                  <div className='title'>{t(TRANSLATE_SCRIM_TITLE.SecondPhasePick)}</div>
                  {ScrimServices.RedPickPos.slice(6).map((el) => {
                    return (
                      <PICK_CONTAINER>
                        {el ? (
                          <SearchChampion
                            isPick
                            id={el.pick}
                            handleChange={selectChampion}
                            positionType={el.pos}
                            championState={state[200].pick[el.index].champ}
                            positionState={state[200].pick[el.index].pos}
                            handlePosChange={handlePosition}
                            error={픽밴중복메세지.includes(el.pick)}
                            posError={레드포지션중복메세지.includes(el.pos)}
                          />
                        ) : (
                          <DisabledInput>
                            <div className='champImg' />
                            <div className='input' />
                            {Array.from({ length: 5 }).map((_, i) => {
                              return <div className='positions' key={i} />;
                            })}
                          </DisabledInput>
                        )}
                      </PICK_CONTAINER>
                    );
                  })}
                </PICK>
              </BANPICKINPUT>
            </INPUTBOX>
          </INPUTBOX_WRAPPER>

          <Box sx={{ marginTop: 5, display: 'flex', justifyContent: 'center' }}>
            <Button
              sx={{
                width: 200,
                height: 40,
                fontSize: 12,
                height: '45px',
                marginRight: 2.5,
                padding: '6px 16px',
              }}
              onClick={handleCancel}
              color='secondary'
              variant='contained'
            >
              {t('취소', { ns: 'scrim' })}
            </Button>
            <SUBMITBUTTON disabled={!!MESSAGE} onClick={handleSubmitScrim} variant='contained'>
              {t('수정완료', { ns: 'scrim' })}
            </SUBMITBUTTON>
          </Box>
        </>
      )}
      {/* 정보를 잘 불러 왔을때 */}
    </div>
  );
};

const STEP = styled.span`
  ${typoStyle.step};
  text-transform: uppercase;
`;
const STEPCONTAINER = styled.p`
  ${typoStyle.select}
`;
const ERROR_MESSAGE = styled.div`
  ${typoStyle.select_red};
  font-size: 15px;
`;

const GAMEINFO = styled.div`
  display: flex;
  justify-content: space-between;
  ${spacing.paddingX(2)};
  ${spacing.paddingY(2)};
  ${spacing.marginB(5)};
  border-radius: 5px;
  border-bottom: 1px solid #2f2e3b;
  /* background-color: ${colors.bg_box}; */
  ${typoStyle.contents}
`;

const PICK_CONTAINER = styled.div`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const BAN_CONTAINER = styled.div`
  flex: 1;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
`;

const INPUTBOX_WRAPPER = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const INPUTBOX = styled.div`
  width: 535px;
`;

const TEAMCOLOR = styled.div<{ color: string }>`
  width: 535px;
  height: 37px;
  background-color: ${({ color }) => (color === 'blue' ? colors.blue : colors.badge_red)};
  font-size: 13px;
  color: #fff;
  border-radius: 8px;
  margin: 10px 0;
  padding-left: 24px;
  line-height: 37px;
`;

const BANPICKINPUT = styled.div`
  color: #fff;
  font-size: 13px;
`;

const BAN = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0;
`;

const PICK = styled.div`
  > img {
    width: 40px;
    height: 40px;
  }
`;

const DisabledInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* margin: 5px 4px; */
  .champImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${colors.bg_box};
    opacity: 0.3;
    margin-right: 4px;
  }

  .input {
    width: 255px;
    height: 40px;
    border-radius: 8px;
    background-color: ${colors.bg_box};
    opacity: 0.3;
    margin-right: 10px;
  }

  .positions {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: ${colors.bg_box};
    opacity: 0.3;
    margin-right: 3px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const TEAMINFO = styled.label<{ checked: boolean }>`
  display: flex;
  align-items: center;
  min-width: 535px;
  height: 74px;
  ${spacing.marginR(6)}
  border: 1px solid ${({ checked }) => (checked ? colors.point : '#383543')};
  background-color: ${({ checked }) => (checked ? 'rgba(89, 66, 186, 0.2)' : '')};
  border-radius: 12px;
  color: #fff;

  > img {
    width: 50px;
    height: 50px;
  }
  > span {
    font-size: 13px;
  }
`;

const SUBMITBUTTON = MUIStyled(Button)`
  ${typoStyle.button_12};
  width: 200px;

  ${spacing.paddingY(3)};
  
  :disabled {
    background: #545362;
    color: white;
  }
`;

export default ScrimEdit;
