import { AxiosError, AxiosResponse } from 'axios';
import { axiosPost } from './axios/axios';

export interface IResponseSuccess<T> {
  response: T;
  status: string;
  message: string;
}

export interface MyTeamSoloRankInfoResponse {
  playerCount: number;
  players: Players[];
  team: string;
}

export interface BookMarkListInfoResponse {
  active: SoloRankData;
  inactive: SoloRankData;
}

export interface SoloRankData {
  playerCount: number;
  players: Players[];
  team: string;
}

export interface Players {
  bookmark: number;
  playChampion: PlayChampion[];
  player: string;
  position: number;
  soloRankInfo: ISoloRankInfo[];
  team: string;
  allSummonerIds?: string[];
  add?: number;
  cdCount?: number;
  cdName?: string;
  cdTier?: number;
  cdRank?: number;
  cdLeaguePoints?: number;
  cdCalRankPoint?: number;
  cdSeasonTotal?: number;
  cdSeasonWin?: number;
  cdSeasonLose?: number;
  cdSeasonWinrate?: number;
  cdLastDayTotal?: number;
  cdLastDayWin?: number;
  cdLastDayLose?: number;
  cdLastDayWinrate?: number;
}

interface PlayChampion {
  champion: string;
  championEng: string;
  lose: string;
  most: string;
  total: string;
  win: string;
  winrate: string;
}

export interface ISoloRankInfo {
  add: 0 | 1;
  gathering: number;
  lastDay: IMatchInfo;
  lastSeason: IMatchInfo;
  leaguePoints: number;
  rank: number;
  summonerId: string;
  summonerName: string;
  tier: -1 | 0 | 1;
}

interface IMatchInfo {
  lose: string;
  role: string;
  total: string;
  win: string;
  winrate: string;
}

export interface FavoritePlayer {
  player: string;
  team: string;
  role: number;
  summonerId: string[];
  puuId: string[];
}

class SoloRankServices {
  static instance: SoloRankServices;

  getMyTeamSoloRankInfo(data: any): Promise<IResponseSuccess<MyTeamSoloRankInfoResponse>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/solorank/teamlist',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  getBookMarkListInfo(data: any): Promise<IResponseSuccess<BookMarkListInfoResponse>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/solorank/bookmarklist',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  getSeachFilterPlayer(data: any): Promise<IResponseSuccess<SoloRankData>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/solorank/filterlist',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  getSommonerSearch(data: { summonerName: string }): Promise<IResponseSuccess<FavoritePlayer>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/solorank/summoner',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  getSummonerAdd(data: any): Promise<IResponseSuccess<FavoritePlayer>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/solorank/summoneradd',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  getSummonerDrop(data: { summonerId: string[] }): Promise<IResponseSuccess<FavoritePlayer>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/solorank/summonerdrop',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  getBookmarkAdd(data: { player: string }): Promise<IResponseSuccess<any>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/solorank/bookmarkadd',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  getBookmarkDrop(data: { player: string }): Promise<IResponseSuccess<any>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/solorank/bookmarkdrop',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }
}

export default new SoloRankServices();
