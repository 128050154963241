export const MAP_POSITON: {
  1: 'top';
  2: 'jng';
  3: 'mid';
  4: 'bot';
  5: 'sup';
} = {
  1: 'top',
  2: 'jng',
  3: 'mid',
  4: 'bot',
  5: 'sup',
};
