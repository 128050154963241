import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useModal } from '../../Hooks';
import { goProGameReport } from '../../lib/pagePath';
import { userAtom } from '../../recoil/Auth/userAtom';
import { errorAtom } from '../../recoil/errors/errorsAtom';
import { ApiError } from '../../services/axios/axios';
import { modalList } from '../Modals/Modals';

import { TRANSLATE_ERROR_MESSAGE } from '../../lib/translation/translate_ErrorController';

// 번역처리
const ErrrorController: React.FC = ({ children }) => {
  const setUser = useResetRecoilState(userAtom);
  const [error, setError] = useRecoilState(errorAtom);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { openModal } = useModal();

  const convertErrorMessage = useCallback((error: ApiError) => {
    let message;
    let action = () => {};

    switch (error.status) {
      case '401':
        if (error.message === 'IC') {
          message = t(TRANSLATE_ERROR_MESSAGE.anotherApproach);
          action = () => {
            setUser();
            navigate('/login');
          };
        } else {
          message = t(TRANSLATE_ERROR_MESSAGE.authExpired);
          action = () => {
            setUser();
            navigate('/login');
          };
        }

        break;

      case '403':
        message = t(TRANSLATE_ERROR_MESSAGE.duplicatedOrExpiredSession);
        action = () => {
          setUser();
          navigate('/login');
        };
        break;

      case '406':
        message = t(TRANSLATE_ERROR_MESSAGE.sessionExpired);
        action = () => {
          setUser();
          navigate('/login');
        };
        break;

      case '500':
        message = t(TRANSLATE_ERROR_MESSAGE.serverError);
        action = () => {
          if (location.pathname.includes(goProGameReport)) navigate(goProGameReport);
        };
        break;

      default:
        message = t(TRANSLATE_ERROR_MESSAGE.unKnownError);
        break;
    }

    return {
      message,
      action,
    };
  }, []);

  const fireAlert = useCallback(
    (message, action) =>
      openModal(modalList.alert, {
        text: message,
        onCancel: () => {
          action();
          setError(undefined);
        },
      }),
    [setError, openModal],
  );

  useEffect(() => {
    if (!error) return;
    const { message, action } = convertErrorMessage(error);
    fireAlert(message, action);
  }, [error]);

  return <>{children}</>;
};

export default ErrrorController;
