import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
// MUI
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { Language } from '@mui/icons-material';
// custom
import { t } from 'i18next';
import i18n from '../../i18n';
import { userAtom, userLanguageAtom } from '../../recoil/Auth/userAtom';
import { MAP_LANGUAGE_WINDOW } from '../../lib/mapping/map_setting_menu';

const LocaleWindow = () => {
  // language atom
  const [language, setLanguage] = useRecoilState(userLanguageAtom);

  // UI 관련
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lang: string) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <>
      <Tooltip title={t(MAP_LANGUAGE_WINDOW.언어설정)} placement='right'>
        <IconButton
          aria-label='settting'
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          sx={{
            color: 'white',
          }}
        >
          <Language />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            backgroundColor: '#3a3a49',
            color: 'white',
            padding: '8px 0',
            mt: 1.5,
            borderRadius: '8px',
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          disabled
          sx={{
            padding: '6px 24px',
            fontSize: 12,
          }}
        >
          {t(MAP_LANGUAGE_WINDOW.언어설정)}
        </MenuItem>
        <MenuItem
          onClick={() => changeLanguage('en')}
          sx={{
            padding: '6px 24px',
            fontSize: 13,
            ':hover': {
              backgroundColor: '#4e4e5c',
            },
          }}
        >
          English
        </MenuItem>
        <MenuItem
          onClick={() => changeLanguage('ko')}
          sx={{
            padding: '6px 24px',
            fontSize: 13,
            ':hover': {
              backgroundColor: '#4e4e5c',
            },
          }}
        >
          한국어
        </MenuItem>
        <MenuItem
          onClick={() => changeLanguage('zh')}
          sx={{
            padding: '6px 24px',
            fontSize: 13,
            ':hover': {
              backgroundColor: '#4e4e5c',
            },
          }}
        >
          简体中文
        </MenuItem>
        <MenuItem
          onClick={() => changeLanguage('ja')}
          sx={{
            padding: '6px 24px',
            fontSize: 13,
            ':hover': {
              backgroundColor: '#4e4e5c',
            },
          }}
        >
          日本語
        </MenuItem>
      </Menu>
    </>
  );
};

export default LocaleWindow;
