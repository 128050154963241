import { Avatar, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { userLanguageAtom } from '../../recoil/Auth/userAtom';
import { ChampionListAtom, LOLPatchAtom } from '../../recoil/common/championListAtom';

interface ChampionString {
  championId: number | string;
  sx?: SxProps<Theme>;
}
/**
 *
 * @param id: 챔피언 고유번호
 * @param sx: MUI style custom
 * @returns
 */

const ChampionString: React.FC<ChampionString> = ({ championId, sx }) => {
  const championList = useRecoilValue(ChampionListAtom);
  const lang = useRecoilValue(userLanguageAtom);
  const championName =
    championList[championId] && lang === 'ko' ? championList[championId].ko : championList[championId].en;

  return (
    championList && (
      <Typography component='span' sx={sx}>
        {championName}
      </Typography>
    )
  );
};

export default ChampionString;
