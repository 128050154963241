import { useQuery } from 'react-query';
import ScrimService, { ScrimViewData } from '../services/ScrimServices';

export const useGetScrim = (
  params: {
    userTeam: string;
    startDate?: number;
    endDate?: number;
    oppTeam?: string;
  },
  options?: any,
) =>
  useQuery(['@scrim', 'result'], () => ScrimService.getScrim(params), {
    refetchOnWindowFocus: false,
    cacheTime: 60 * 60 * 1000,
    ...options,
  });
