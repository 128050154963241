// 선수 이름
// 캐릭터
// 레벨
// 스펠 체크
// HP/MP

import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { recentVersion } from '../../../../../config';
import GameDatasetAtom from '../../../../../../recoil/ProGameReport/gameDatasetAtom';
import GameAtom from '../../../../../../recoil/ProGameReport/gameAtom';
import VideoAtom from '../../../../../../recoil/ProGameReport/videoAtom';
import useChampionImage from '../../../../../../Hooks/useChampionImage';

/*
when j.spells=1 then 'SummonerBoost'
when j.spells=3 then 'SummonerExhaust'
when j.spells=4 then 'SummonerFlash'
when j.spells=6 then 'SummonerHaste'
when j.spells=7 then 'SummonerHeal'
when j.spells=11 then 'SummonerSmite'
when j.spells=12 then 'SummonerTeleport'
when j.spells=13 then 'SummonerMana'
when j.spells=14 then 'SummonerDot'
when j.spells=21 then 'SummonerBarrier'
 */

const seekSpell = (spellNum: number) => {
  switch (spellNum) {
    case 1:
      return 'SummonerBoost';
    case 3:
      return 'SummonerExhaust';
    case 4:
      return 'SummonerFlash';
    case 6:
      return 'SummonerHaste';
    case 7:
      return 'SummonerHeal';
    case 11:
      return 'SummonerSmite';
    case 12:
      return 'SummonerTeleport';
    case 13:
      return 'SummonerMana';
    case 14:
      return 'SummonerDot';
    case 21:
      return 'SummonerBarrier';
    default:
      console.log('예상치 못한 스펠이 발견되었습니다.');
      return 'SummonerPoroThrow';
  }
};

const StatusBox1 = () => {
  const { getChampionImagePathByName } = useChampionImage();
  const fixedDataset = useRecoilValue(GameDatasetAtom.fixedDataset);
  const mappingDataset = useRecoilValue(GameDatasetAtom.mappingDataset);
  const selectedTeam = useRecoilValue(GameAtom.selectedTeam);
  const selectedPosition = useRecoilValue(GameAtom.selectedPosition);
  const selectedParticipant = useRecoilValue(GameAtom.selectedParticipant);
  const startTime = useRecoilValue(GameAtom.startTime);
  const blueteam = useRecoilValue(GameAtom.blueteam);
  const redteam = useRecoilValue(GameAtom.redteam);
  const playedSeconds = useRecoilValue(VideoAtom.playedSeconds);
  const { info } = fixedDataset[selectedTeam].players[selectedPosition];

  const getIdx = Math.floor(playedSeconds - +startTime - mappingDataset[0].realCount / 2);
  const realCnt =
    getIdx < 0 ? 0 : getIdx * 2 >= mappingDataset.length ? mappingDataset.length - 1 : getIdx * 2;
  const mapping = realCnt === -1 ? null : mappingDataset[realCnt].player[selectedParticipant];
  return (
    <StatusContainer>
      <NameBox>{`${selectedTeam === 0 ? blueteam : redteam} ${info.player}`}</NameBox>
      <FlexBox>
        <ChampImg champImg={getChampionImagePathByName(info.championEng)} />
        <SpellContainer>
          <SpellImg usable={mapping ? mapping.spell1 : 0} spellImg={seekSpell(info.spell1)} />
          <SpellImg usable={mapping ? mapping.spell2 : 0} spellImg={seekSpell(info.spell2)} />
        </SpellContainer>
      </FlexBox>
      <StatusBar>
        <HpBox used={(100 - (mapping ? mapping.hp : 0)) * 0.78}>
          <div className='usable' style={{ backgroundColor: '#37b537' }} />
        </HpBox>
        <MpBox used={(100 - (mapping ? mapping.mp : 0)) * 0.78}>
          <div className='usable' style={{ backgroundColor: '#2b80e0' }} />
        </MpBox>
      </StatusBar>
    </StatusContainer>
  );
};

export default StatusBox1;

const StatusContainer = styled.div`
  width: 78px;
  height: 91px;
  margin: 11px 10px 14.5px 8px;
`;

const NameBox = styled.div`
  font-family: SpoqaHanSansNeo;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.2;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
`;

const FlexBox = styled.div`
  display: flex;
`;

const SpellContainer = styled.div`
  width: 20px;
  height: 42px;
  margin: 5px 8px 7px 0px;
`;

const ChampImg = styled.div<{ champImg: string }>`
  width: 45px;
  height: 45px;
  margin: 2px 5px 7px 0;
  padding: 25px 25px 0 0;
  border: solid 2px #f04545;
  border-radius: 30px;
  background-image: url(${(props) => props.champImg});
  background-size: 45px;
`;

const SpellImg = styled.div<{ usable: number; spellImg: string }>`
  width: 20px;
  height: 20px;
  background-color: #fff;
  margin-bottom: 2px;
  background-image: url('/Images/spell/${(props) => props.spellImg}.png');
  background-size: 20px;
  opacity: ${(props) => (props.usable === 1 ? '1' : '0.5')};
`;

const StatusBar = styled.div`
    width: 78px;
    height: 10px;
    margin: 4px 0 0 2px;
    .usable {
      height: 10px;
      //padding: 0 10px 0 5px;
      border-radius: 10px;
    }
  }
`;

const HpBox = styled.div<{ used: number }>`
  width: 78px;
  height: 10px;
  margin: 0 0 3px;
  padding: 0 ${(props) => props.used}px 0 0;
  border-radius: 10px;
  background-color: #23212a;
  overflow: hidden;
`;

const MpBox = styled.div<{ used: number }>`
  width: 78px;
  height: 10px;
  margin: 3px 0 0;
  padding: 0 ${(props) => props.used}px 0 0;
  border-radius: 10px;
  background-color: #23212a;
  overflow: hidden;
`;
