import { atom } from 'recoil';
import { IFilterSeason } from './filter.type';

type FilterData = {
  [key: string]: boolean;
};

interface ProGameReportParams {
  league: string[];
  year: string[];
  season: string[];
  team: string;
  patch: string[];
}

const ProGameReportAtom = {
  years: atom<FilterData>({
    key: 'ProGameReportYear',
    default: {},
  }),

  leagues: atom<FilterData>({
    key: 'ProGameReportLeague',
    default: {},
  }),

  seasons: atom<IFilterSeason>({
    key: 'ProGameReportSeason',
    default: {},
  }),

  teams: atom<FilterData>({
    key: 'ProGameReportTeam',
    default: {},
  }),

  patchs: atom<FilterData>({
    key: 'ProGameReportPatch',
    default: {},
  }),
};

export const ProGameReportResultAtom = atom<ProGameReportParams>({
  key: 'ProGameReportResult',
  default: undefined,
});

export default ProGameReportAtom;
