/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { getRankLp } from '../../../lib/getRank';
import { playerLabels } from '../../../lib/mapping/map_table_label';
import getSoloRankInfo from '../../../lib/soloRank/getSoloRankInfo';
import { ISoloRankInfo } from '../../../services/SoloRankServices';
import { IPlayerBoardResponse } from '../../../services/types/player_services.type';
import { borderRadiusStyle, colors, spacing, typoStyle } from '../../../Styles/ui';

interface PlayerInfoProps {
  playerInfo: IPlayerBoardResponse;
  soloRankInfo: ISoloRankInfo[];
}

const imgUrl = (imgString: string) =>
  imgString === 'no-image'
    ? '/Images/player_error_image.png'
    : `https://am-a.akamaihd.net/image?f=${imgString}`;

const PlayerInfo: React.FC<PlayerInfoProps> = ({ playerInfo, soloRankInfo }) => {
  const { t } = useTranslation();
  const {
    maxTier,
    maxRank,
    maxLP,
    seasonTotal,
    seasonWin,
    seasonlose,
    lastDayTotal,
    lastDayWin,
    lastDayLose,
  } = getSoloRankInfo(soloRankInfo);

  const InfoRow = playerInfo && [
    {
      label: playerLabels[0],
      data: playerInfo.info.ID,
    },
    {
      label: playerLabels[1],
      data: playerInfo.info.NativeName,
    },
    {
      label: playerLabels[2],
      data: t(`position.${playerInfo.info.Role.toLowerCase()}`),
    },
  ];

  const soloRankRow = soloRankInfo && [
    {
      label: playerLabels[6],
      data: `${getRankLp(maxTier, maxRank, maxLP)}`,
    },
    {
      label: playerLabels[7],
      data: `${seasonTotal}${t('common.label.game')} ${seasonWin}${t('common.label.win')} ${seasonlose}${t(
        'common.label.lose',
      )}`,
    },
    {
      label: playerLabels[8],
      data: `${lastDayTotal}${t('common.label.game')} ${lastDayWin}${t('common.label.win')} ${lastDayLose}${t(
        'common.label.lose',
      )}`,
    },
  ];

  return (
    <PlayerContainer>
      <ImgContainer>
        <img src={imgUrl(playerInfo.info.Image)} alt='' />
      </ImgContainer>
      <ListContainer>
        <List>
          {InfoRow?.map((row) => (
            <ListItem key={row.label}>
              <dt>{t(row.label)}</dt>
              <dd>{row.data}</dd>
            </ListItem>
          ))}
        </List>
      </ListContainer>
      <ListContainer>
        <List>
          {soloRankRow?.map((row) => (
            <ListItem key={row.label}>
              <dt>{t(row.label)}</dt>
              <dd>{row.data}</dd>
            </ListItem>
          ))}
        </List>
      </ListContainer>
    </PlayerContainer>
  );
};

const PlayerContainer = styled.div`
  display: flex;
  ${borderRadiusStyle[20]}
  background-color: ${colors.bg_select};
  height: 160px;
  ${spacing.marginB(5)}
`;

const ImgContainer = styled.div`
  flex: 1.5;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  img {
    height: 100%;
    object-fit: contain;
  }
`;

const ListContainer = styled.div`
  ${typoStyle.contents};
  ${spacing.marginX(5)}
  position: relative;
  flex: 1.5;
  display: flex;
  align-items: center;
  &:last-child {
    flex: 2;
  }

  &::before {
    content: '';
    position: absolute;
    left: -20px;
    width: 1px;
    height: 70px;
    background-color: ${colors.border_light};
  }
`;

const List = styled.dl``;
const ListItem = styled.div`
  display: flex;
  &:not(:last-of-type) {
    ${spacing.marginB(2)}
  }
  dt {
    width: 135px;
    color: ${colors.info};
  }
  dd {
    ${typoStyle.noWrap};
    width: 150px;
  }
`;

export default PlayerInfo;
