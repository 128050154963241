import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from '@emotion/styled/macro';
import { Button, styled as MUIStyled } from '@mui/material';
import dayjs from 'dayjs';
import Progress from '../../../Components/Ui/Loading/Progress';
import NotFound from '../../../Components/Ui/Error/NotFound';
import ScrimBanPickReport from './ScrimBanPickReport';
import { colors } from '../../../Styles/ui';
import Calendar from '../../test/Calendar';
import ScrimService, { ScrimTeamStatParams } from '../../../services/ScrimServices';
import { userAtom } from '../../../recoil/Auth/userAtom';

export type Tabs = 'blue' | 'red';
const TABS: Tabs[] = ['blue', 'red'];

function ScrimBanPickReportIndex() {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);
  const [tab, setTab] = useState(0);
  const [side, setSide] = useState<Tabs>('blue');

  // state: 날짜관련
  const { mutate, data, isLoading, isError } = useMutation((data: ScrimTeamStatParams) =>
    ScrimService.getScrimBanpickAnalysis(data),
  );

  // mutate : 달력 필터에서 날짜 설정 시
  const handleChange = (date: Date[] | undefined[]) => {
    if (!user) return;

    const params = {
      team: user?.teamName,
      startDate: date[0] === undefined ? 0 : dayjs(date[0]).unix(),
      endDate: date[1] === undefined ? 0 : dayjs(date[1]).unix(),
    };
    mutate(params);
  };

  return (
    <WRAPPER>
      {/* 캘린더 주입 */}
      <Calendar handleChange={handleChange} isSelectDateNeeded isOppTeamFilterNeeded={false} />

      {/* 탭 */}
      <TAB_CONATINER>
        {TABS.map((tab) => {
          return (
            <TAB_ITEM
              key={`banreport_${tab}`}
              className='All'
              onClick={() => setSide(tab)}
              changeColor={side === tab}
            >
              <div>
                <span>{tab.toUpperCase()}</span>
              </div>
            </TAB_ITEM>
          );
        })}
      </TAB_CONATINER>

      {/* 컨텐츠 */}
      <TAB_CONTENTS>
        <TAB_CONTENTS_CONTAINER>
          {isLoading ? (
            <ProgressContainer>
              <Progress text={t('solo.playerboard.dataFetching')} />
            </ProgressContainer>
          ) : isError ? (
            <NotFound css={{ height: '100%' }} text={t('solo.playerboard.dataFetchingFailed')} />
          ) : (
            data && <ScrimBanPickReport data={data.response} side={side} />
            // <ScrimBanPickReport data={BANPICK_MOCKDATA} side={side} />
          )}
        </TAB_CONTENTS_CONTAINER>
      </TAB_CONTENTS>
    </WRAPPER>
  );
}

export default ScrimBanPickReportIndex;

const WRAPPER = styled.div`
  max-width: 1200px;
`;

const TAB_CONATINER = styled.div`
  display: flex;
  width: 100%;
  height: 62px;
  border-bottom: solid 1px #433f4e;
`;

const TAB_ITEM = styled.button<{ changeColor: boolean }>`
  display: flex;
  align-items: center;
  width: auto;
  white-space: nowrap;

  div {
    padding: 10px 15px;
  }

  :hover {
    div {
      padding: 10px 15px;
      border-radius: 10px;
      background-color: #26262c;
    }
  }

  span {
    height: 22px;
    font-family: SpoqaHanSansNeo;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 18px;
    border-bottom: solid 1px ${(props) => (props.changeColor ? `#fff` : `none;`)};
    color: ${(props) => (props.changeColor ? `#fff` : `#84818e`)};
  }
`;

const TAB_CONTENTS = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;

const TAB_CONTENTS_CONTAINER = styled.div`
  height: 100%;
  overflow: hidden;
`;

const ProgressContainer = styled.div`
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const WRAPPER_CALENDAR = styled.div`
  display: flex;
  margin: 20px 10px;
`;

const CALENDER_CONTROLLER = styled.div`
  width: 100%;
  display: flex;

  .btn-tab {
    font-size: 14px;
    width: auto;
    height: auto;
    padding: 8px 12px;
    margin-right: 4px;
    border-radius: 10px;
    height: 40px;
  }

  .btn-trigger {
    box-shadow: none;
    height: 40px;
    margin-left: 20px;
    flex: 1;
  }
`;

const MUITab = MUIStyled(Button)(
  ({ active }) => `
background-color: ${active ? `rgba(89, 66, 186, 0.2)` : colors.bg_select};
border: ${active ? ' 1px solid #5442b0' : '1px solid transparent'};
`,
);
