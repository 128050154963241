import getLeafYaer from './getLeafYear';
import getMonthDays from './getMonthDays';
import getMonthDayList from './getMonthDayList';

export default function checkClick(index: number, year: number, seasonStart: string, seasonEnd: string) {
  const leapYear = getLeafYaer(year);
  const seasonStartIdx =
    getMonthDays(+seasonStart.split('-')[1] - 1, getMonthDayList(leapYear)) + +seasonStart.split('-')[2] - 1;
  const seasonEndIdx =
    getMonthDays(+seasonEnd.split('-')[1] - 1, getMonthDayList(leapYear)) + +seasonEnd.split('-')[2] - 1;
  if (seasonStartIdx <= index && index <= seasonEndIdx) {
    return true;
  }
  return false;
}
