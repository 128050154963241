import React, { useState } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import thousand from '../../../../../lib/thousand';
import BlueChampBox from './Component/BlueChampBox';
import RedChampBox from './Component/RedChampBox';

import GameAtom from '../../../../../recoil/ProGameReport/gameAtom';
import GameDatasetAtom from '../../../../../recoil/ProGameReport/gameDatasetAtom';

interface getStandardValueObject {
  blue: number;
  red: number;
}

const Detail = () => {
  const [detailTab, setDetailTab] = useState(1);
  const { t } = useTranslation();

  const teamGoldDataset = useRecoilValue(GameDatasetAtom.teamGoldDataset);
  const liveDataset = useRecoilValue(GameDatasetAtom.liveDataset);
  const goldActiveIdx = useRecoilValue(GameAtom.goldActiveIdx);
  const liveActiveIdx = useRecoilValue(GameAtom.liveActiveIdx);
  const detailSet = getTotalTeamGold(detailTab);

  function getTotalTeamGold(detailTab: number) {
    const obj: getStandardValueObject = {
      blue: 0,
      red: 0,
    };
    const reducer = (accumulator: number, curr: number) => accumulator + curr;

    if (detailTab === 1) {
      const goldSet = teamGoldDataset[goldActiveIdx];
      obj.blue = goldSet.blueGold;
      obj.red = goldSet.redGold;
    } else if (detailTab === 2) {
      const wardSet = liveDataset[liveActiveIdx - 1 < 0 ? 0 : liveActiveIdx].players.map(
        (data) => data.wardsPlaced,
      );
      obj.blue = wardSet.slice(0, 5).reduce(reducer);
      obj.red = wardSet.slice(5, 10).reduce(reducer);
      
    } else if (detailTab === 3) {
      const csSet = liveDataset[liveActiveIdx - 1 < 0 ? 0 : liveActiveIdx].players.map((data) => data.cs);
      obj.blue = csSet.slice(0, 5).reduce(reducer);
      obj.red = csSet.slice(5, 10).reduce(reducer);
      
    }
    return obj;
  }

  return (
    <DetailContainer>
      <DetailTabContainer>
        <TabItem onClick={() => setDetailTab(1)} changeColor={detailTab === 1}>
          <div>
            <span>{t('game.summary.detail.get-gold')}</span>
          </div>
        </TabItem>
        <TabItem onClick={() => setDetailTab(2)} changeColor={detailTab === 2}>
          <div>
            <span>{t('game.summary.detail.placed-ward')}</span>
          </div>
        </TabItem>
        <TabItem onClick={() => setDetailTab(3)} changeColor={detailTab === 3}>
          <div>
            <span>{t('game.summary.detail.cs')}</span>
          </div>
        </TabItem>
      </DetailTabContainer>
      <DetailChampTimeStatus>
        <div className='team-box blue'>
          <BlueChampBox detailTab={detailTab} />
        </div>

        <div className='total'>
          <div className='flex-box'>
            <ArrowBox isShow={detailSet.blue > detailSet.red}>
              <img className='arrow' src='/Images/ic_win-blue.svg' alt='' />
            </ArrowBox>
            <div className='value-box blue-value'>{`${thousand(detailSet.blue)}`}</div>
            <ArrowBox isShow={false} />
          </div>
          <div className='flex-box'>
            <ArrowBox isShow={false} />
            <div className='value-box red-value'>{`${thousand(detailSet.red)}`}</div>
            <ArrowBox isShow={detailSet.blue < detailSet.red}>
              <img className='arrow red-arrow' src='/Images/ic_win-red.svg' alt='' />
            </ArrowBox>
          </div>
        </div>

        <div className='team-box red'>
          <RedChampBox detailTab={detailTab} />
        </div>
      </DetailChampTimeStatus>
    </DetailContainer>
  );
};

export default Detail;

const DetailContainer = styled.div`
  width: 702px;
  height: 225px;
  display: block;
`;

const DetailTabContainer = styled.div`
  width: 100%;
  height: 30px;
  margin: 8px 5px;
  display: flex;
  cursor: pointer;
`;

const TabItem = styled.li<{ changeColor: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: auto;
  white-space: nowrap;

  div {
    padding: 7px 10px 6px;
  }

  :hover {
    div {
      padding: 7px 10px 6px;
      border-radius: 15px;
      background-color: #26262c;
    }
  }

  span {
    height: 22px;
    font-family: SpoqaHanSansNeo;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 19px;
    color: ${(props) => (props.changeColor ? `#fff` : `#84818e`)};
  }
`;

const DetailChampTimeStatus = styled.div`
  width: 694px;
  height: 168px;
  margin: 5px 0px 0 8px;
  display: flex;

  .flex-box {
    display: flex;
  }

  .team-box {
    width: 282px;
    height: 168px;
  }

  .blue {
    margin: 0px 0px 0 0;
  }

  .red {
    margin: 0px 0px 0 0px;
  }

  .total {
    width: 106px;
    height: 168px;
    margin: 66px 12px 48px;

    .value-box {
      width: 58px;
      height: 100%;
      text-align: center;
      white-space: nowrap;
    }

    .blue-value {
      font-family: SpoqaHanSansNeo;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: #0075bf;
    }

    .red-value {
      font-family: SpoqaHanSansNeo;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: #f04545;
    }
  }

  .champ-box {
    display: flex;
    width: 282px;
    height: 25px;
    margin: 0 0 11px;

    img {
      margin-top: 2px;
      width: 24px;
      height: 24px;
      border-radius: 25px;
    }
  }

  .float-right {
    float: right;
  }

  .bar-box {
    width: 253px;
    height: 22px;
    margin: 2px 0 1px 5px;
    .value {
      font-family: SpoqaHanSansNeo;
      font-size: 10px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #fff;
      .max {
        opacity: 0.3;
      }
    }
    .blue-text {
      text-align: left;
    }
    .red-text {
      text-align: right;
    }
  }
`;

const ArrowBox = styled.div<{ isShow: boolean }>`
  width: 24px;
  height: 24px;

  .blue-arrow {
    margin: 1px 3px 11px 0px;
  }

  .red-arrow {
    margin: 2px 0 1px 3px;
  }

  .arrow {
    display: ${(props) => (props.isShow ? `block` : `none`)};
    object-fit: contain;
  }
`;
