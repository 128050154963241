import React, { useState, useEffect } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';

import { Bar } from 'react-chartjs-2';
import { useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';
import { barOptions } from '../../../Styles/chart/option';
import TeamServices from '../../../services/TeamServices';
import { TeamReportResultAtom } from '../../../recoil/Filter/teamReportAtom';
import Progress from '../../../Components/Ui/Loading/Progress';
import NotFound from '../../../Components/Ui/Error/NotFound';
import { userLanguageAtom } from '../../../recoil/Auth/userAtom';
import { spacing } from '../../../Styles/ui';

const CHART_STYLE = {
  barThickness: 28,
  fill: false,
  lineTension: 0,
  backgroundColor: '#f14444',
  borderColor: '#f14444',
  borderWidth: 2,
};

function TeamAnalysis() {
  const lang = useRecoilValue(userLanguageAtom);
  const { t } = useTranslation();
  // 리코일
  const result = useRecoilValue(TeamReportResultAtom);
  const resultTeam = result && result.team.join();
  const resultLeague = result && result.league;

  const { mutate, data, isLoading, isError } = useMutation(() =>
    TeamServices.getTeamAnalysis({
      ...result,
      team: resultTeam,
    }),
  );

  console.log('data', data);

  useEffect(() => {
    mutate();
  }, [result]);

  // 갱킹 그래프 옵션
  const firstGankTime = data?.response.firstGank.firstGankList &&
    data.response.firstGank.firstGankList !== 'NULL' && {
      labels: data?.response.firstGank.firstGankList.map((list) => list.position),
      datasets: [
        {
          ...CHART_STYLE,
          data: data?.response.firstGank.firstGankList.map((list) => list.value),
        },
      ],
    };
  // 서포팅 그래프 옵션
  const averageSupport = data?.response.supportedTime.supportedTimeRow !== 0 && {
    labels: data?.response.supportedTime.supportedTimeList.map((item) => item.position),
    datasets: [
      {
        ...CHART_STYLE,
        data: data?.response.supportedTime.supportedTimeList.map((item) => item.value),
      },
    ],
  };

  return (
    <WRAPPER>
      {/* 컨텐츠 시작 */}
      {/* 2022.05.11 팀 전력보고서는 - LPL 지원하지 않음 데이터 없음 */}
      {resultLeague.includes('LPL') ? (
        <ERROR_CONTAINER>
          <NotFound
            css={{ height: '100%' }}
            text={
              <ERROR_TEXT>
                <p>{t('errorControllerMsg.teamAnalysisLPLError')}</p>
                <p>{t('errorControllerMsg.sorry')}</p>
              </ERROR_TEXT>
            }
          />
        </ERROR_CONTAINER>
      ) : (
        <>
          {isLoading ? (
            <ProgressContainer>
              <Progress text={t('solo.playerboard.dataFetching')} />
            </ProgressContainer>
          ) : isError ? (
            <NotFoundContainer>
              <NotFound css={{ height: '100%' }} text={t('solo.playerboard.dataFetchingFailed')} />
            </NotFoundContainer>
          ) : (
            data?.response && (
              <div>
                {/* 지표 아이콘  */}
                <IndexNav>
                  <div className='UnderAvg'>
                    <img src='/Images/ico-teamreport-num-up.svg' alt='avgIcon' />
                    <span>{t('team.analysis.above')}</span>
                  </div>
                  <div className='AboveAvg'>
                    <img src='/Images/ico-teamreport-num-down.svg' alt='avgIcon' />
                    <span>{t('team.analysis.below')}</span>
                  </div>
                  <div className='LeagueAvg'>
                    <img src='/Images/ico-teamreport-num-equal.svg' alt='avgIcon' />
                    <span>{t('team.analysis.equal')}</span>
                  </div>
                </IndexNav>
                <DisplayWithIcon>
                  {/* 왼쪽 컨텐츠 박스 */}
                  <ContentsBox>
                    <div className='FirstBox'>
                      {/* 평균킬 */}
                      <div className='avgKill'>
                        <img className='MainIcon' src='/Images/ico-team-dash-kill.png' alt='Icon' />
                        <DisplayInfo>
                          <div className='SubTitle'>{t('team.analysis.kill')}</div>
                          <div className='CalcData'>
                            <img
                              src={
                                data.response.teamStat.kill.result === true
                                  ? '/Images/ico-teamreport-num-up.svg'
                                  : '/Images/ico-teamreport-num-down.svg'
                              }
                              alt='arrowIcon'
                              width='13px'
                              height='13px'
                            />
                            {Number.isNaN(data.response.teamStat.kill.value) ? (
                              <TeamValue changeColor={data.response.teamStat.kill.result === false}>
                                0
                              </TeamValue>
                            ) : (
                              <TeamValue changeColor={data.response.teamStat.kill.result === false}>
                                {`${data.response.teamStat.kill.value.toFixed(2)}`}
                              </TeamValue>
                            )}
                          </div>
                          <div className='AvgData'>
                            {`${t('team.analysis.leagueAvg')} ${
                              Number.isNaN(data.response.leagueStat.kill)
                                ? 0
                                : data.response.leagueStat.kill.toFixed(2)
                            }`}
                          </div>
                        </DisplayInfo>
                      </div>

                      {/* 평균시간 */}
                      <div className='avgGameTime'>
                        <img className='MainIcon' src='/Images/ico-team-dash-gametime.png' alt='Icon' />
                        <DisplayInfo>
                          <div className='SubTitle'>{t('team.analysis.playTime')}</div>
                          <div className='CalcData'>
                            <img
                              src={
                                data.response.teamStat.playTime.result === true
                                  ? '/Images/ico-teamreport-num-up.svg'
                                  : '/Images/ico-teamreport-num-down.svg'
                              }
                              alt='arrowIcon'
                              width='13px'
                              height='13px'
                            />
                            <TeamValue changeColor={data.response.teamStat.playTime.result === false}>
                              {`${data.response.teamStat.playTime.minute}${t('team.analysis.min')} ${
                                data.response.teamStat.playTime.second
                              }${t('team.analysis.sec')}`}
                            </TeamValue>
                          </div>
                          <div className='AvgData'>{`${t('team.analysis.leagueAvg')} ${
                            data.response.leagueStat.playTime.minute
                          }${t('team.analysis.min')} ${data.response.leagueStat.playTime.second}${t(
                            'team.analysis.sec',
                          )}`}</div>
                        </DisplayInfo>
                      </div>
                    </div>
                    <div className='SecondBox'>
                      <div className='KillPerMin'>
                        <img className='MainIcon' src='/Images/ico-team-dash-killmin.png' alt='Icon' />
                        <DisplayInfo>
                          <div className='SubTitle'>{t('team.analysis.kpm')}</div>
                          <div className='CalcData'>
                            <img
                              src={
                                data.response.teamStat.km.result === true
                                  ? '/Images/ico-teamreport-num-up.svg'
                                  : '/Images/ico-teamreport-num-down.svg'
                              }
                              alt='arrowIcon'
                              width='13px'
                              height='13px'
                            />
                            {Number.isNaN(data.response.teamStat.kill.value) ? (
                              <TeamValue changeColor={data.response.teamStat.km.result === false}>
                                0
                              </TeamValue>
                            ) : (
                              <TeamValue changeColor={data.response.teamStat.km.result === false}>
                                {`${data.response.teamStat.km.value.toFixed(2)}`}
                              </TeamValue>
                            )}
                          </div>
                          <div className='AvgData'>{`${t('team.analysis.leagueAvg')} ${
                            Number.isNaN(data.response.leagueStat.km)
                              ? 0
                              : data.response.leagueStat.km.toFixed(2)
                          }`}</div>
                        </DisplayInfo>
                      </div>
                      <div className='AvgDragon'>
                        <img className='MainIcon' src='/Images/ico-team-dash-dragon.png' alt='Icon' />
                        <DisplayInfo>
                          <div className='SubTitle'>{t('team.analysis.dragon')}</div>
                          <div className='CalcData'>
                            <img
                              src={
                                data.response.teamStat.firstDragon.result === true
                                  ? '/Images/ico-teamreport-num-up.svg'
                                  : '/Images/ico-teamreport-num-down.svg'
                              }
                              alt='arrowIcon'
                              width='13px'
                              height='13px'
                            />
                            <TeamValue changeColor={data.response.teamStat.firstDragon.result === false}>
                              {`${data.response.teamStat.firstDragon.minute}${t('team.analysis.min')} ${
                                data.response.teamStat.firstDragon.second
                              }${t('team.analysis.sec')}`}
                            </TeamValue>
                          </div>
                          <div className='AvgData'>{`${t('team.analysis.leagueAvg')} ${
                            data.response.leagueStat.firstDragon.minute
                          }${t('team.analysis.min')} ${data.response.leagueStat.firstDragon.second}${t(
                            'team.analysis.sec',
                          )}`}</div>
                        </DisplayInfo>
                      </div>
                    </div>
                  </ContentsBox>

                  {/* 원형 도넛 그래프 */}
                  <TeamWinRateGraph>
                    <TeamTotalStats>
                      <div className='StatLabel'>{t('team.analysis.statLabel')}</div>
                      <div className='StatValue'>{`${data.response.teamStat.total}${t(
                        'team.analysis.total',
                      )} ${data.response.teamStat.win}${t('team.analysis.win')} ${
                        data.response.teamStat.lose
                      }${t('team.analysis.lose')}`}</div>
                    </TeamTotalStats>
                    <CircularProgressbarWithChildren
                      value={
                        Number.isNaN(data.response.teamStat.winRate) ? 0 : data.response.teamStat.winRate
                      }
                      styles={buildStyles({
                        strokeLinecap: 'butt',
                        textSize: '24px',
                        pathTransitionDuration: 0.5,

                        // Colors
                        pathColor: `rgb(240, 69, 69)`,
                        textColor: '#ffffff',
                        trailColor: '#2f2d38',
                        backgroundColor: '#3e98c7',
                      })}
                      strokeWidth={12}
                    >
                      <div
                        style={{
                          fontSize: 12,
                          color: '#84818e',
                          fontFamily: 'SpoqaHanSansNeo',
                        }}
                      >
                        {t('team.analysis.winRate')}
                      </div>
                      <div
                        style={{
                          marginTop: '10px',
                        }}
                      >
                        <strong
                          style={{
                            fontSize: 24,
                            fontFamily: 'Poppins',
                            color: '#ffffff',
                            fontWeight: 'bold',
                            marginRight: ' 5px',
                          }}
                        >
                          {Number.isNaN(data.response.teamStat.winRate)
                            ? 0
                            : data.response.teamStat.winRate.toFixed(1)}
                        </strong>
                        <span
                          style={{
                            fontSize: 15,
                            fontFamily: 'SpoqaHanSansNeo;',
                            color: '#ffffff',
                          }}
                        >
                          %
                        </span>
                      </div>
                    </CircularProgressbarWithChildren>
                  </TeamWinRateGraph>

                  {/* 오른쪽 컨텐츠 박스 */}
                  <ContentsBoxTwo>
                    <div className='FirstBox'>
                      <div className='FirstHerald'>
                        <img className='MainIcon' src='/Images/ico-team-dash-herald.png' alt='Icon' />
                        <DisplayInfo>
                          <div className='SubTitle'>{t('team.analysis.herald')}</div>
                          <div className='CalcData'>
                            <img
                              src={
                                data.response.teamStat.firstHerald.result === true
                                  ? '/Images/ico-teamreport-num-up.svg'
                                  : '/Images/ico-teamreport-num-down.svg'
                              }
                              alt='arrowIcon'
                              width='13px'
                              height='13px'
                            />
                            <TeamValue changeColor={data.response.teamStat.firstHerald.result === false}>
                              {`${data.response.teamStat.firstHerald.minute}${t('team.analysis.min')} ${
                                data.response.teamStat.firstHerald.second
                              }${t('team.analysis.sec')}`}
                            </TeamValue>
                          </div>
                          <div className='AvgData'>{`${t('team.analysis.leagueAvg')} ${
                            data.response.leagueStat.firstHerald.minute
                          }${t('team.analysis.min')} ${data.response.leagueStat.firstHerald.second}${t(
                            'team.analysis.sec',
                          )}`}</div>
                        </DisplayInfo>
                      </div>
                      <div className='AvgFirstGank'>
                        <img className='MainIcon' src='/Images/ico-team-dash-gank.png' alt='Icon' />
                        <DisplayInfoFirstGank>
                          <div className='SubTitle'>{t('team.analysis.gank')}</div>
                          {Number.isNaN(+data.response.teamStat.timeOfFirstGank.minute) &&
                          Number.isNaN(+data.response.teamStat.timeOfFirstGank.second) ? (
                            <NoData2>{t('league.leagueStat.noData2')}</NoData2>
                          ) : (
                            <>
                              <div className='CalcData'>
                                <img
                                  src={
                                    data.response.teamStat.timeOfFirstGank.result === true
                                      ? '/Images/ico-teamreport-num-up.svg'
                                      : '/Images/ico-teamreport-num-down.svg'
                                  }
                                  alt='arrowIcon'
                                  width='13px'
                                  height='13px'
                                />
                                <TeamValue
                                  changeColor={data.response.teamStat.timeOfFirstGank.result === false}
                                >
                                  {`${data.response.teamStat.timeOfFirstGank.minute}${t(
                                    'team.analysis.min',
                                  )} ${data.response.teamStat.timeOfFirstGank.second}${t(
                                    'team.analysis.sec',
                                  )}`}
                                </TeamValue>
                              </div>
                              <div className='AvgData'>{`${t('team.analysis.leagueAvg')} ${
                                data.response.leagueStat.timeOfFirstGank.minute
                              }${t('team.analysis.min')} ${
                                data.response.leagueStat.timeOfFirstGank.second
                              }${t('team.analysis.sec')}`}</div>
                            </>
                          )}
                        </DisplayInfoFirstGank>
                      </div>
                    </div>
                    <div className='SecondBox'>
                      <div className='FirstBaron'>
                        <img className='MainIcon' src='/Images/ico-team-dash-nashor.png' alt='Icon' />
                        <DisplayInfo>
                          <div className='SubTitle'>{t('team.analysis.baron')}</div>
                          <div className='CalcData'>
                            <img
                              src={
                                data.response.teamStat.firstBaron.result === true
                                  ? '/Images/ico-teamreport-num-up.svg'
                                  : '/Images/ico-teamreport-num-down.svg'
                              }
                              alt='arrowIcon'
                              width='13px'
                              height='13px'
                            />
                            <TeamValue changeColor={data.response.teamStat.firstBaron.result === false}>
                              {`${data.response.teamStat.firstBaron.minute}${t('team.analysis.min')} ${
                                data.response.teamStat.firstBaron.second
                              }${t('team.analysis.sec')}`}
                            </TeamValue>
                          </div>
                          <div className='AvgData'>{`${t('team.analysis.leagueAvg')} ${
                            data.response.leagueStat.firstBaron.minute
                          }${t('team.analysis.min')} ${data.response.leagueStat.firstBaron.second}${t(
                            'team.analysis.sec',
                          )}`}</div>
                        </DisplayInfo>
                      </div>
                      <div className='AvgCombat'>
                        <img className='MainIcon' src='/Images/ico-team-dash-fight.png' alt='Icon' />
                        <DisplayInfoNumOfFight>
                          <div className='SubTitle'>{t('team.analysis.teamfight')}</div>
                          {Number.isNaN(+data.response.teamStat.numberOfTeamFight.winRate) ? (
                            <NoData2>{t('league.leagueStat.noData2')}</NoData2>
                          ) : (
                            <>
                              <div className='CalcData'>
                                <img
                                  src={
                                    data.response.teamStat.numberOfTeamFight.result === true
                                      ? '/Images/ico-teamreport-num-up.svg'
                                      : '/Images/ico-teamreport-num-down.svg'
                                  }
                                  alt='arrowIcon'
                                  width='13px'
                                  height='13px'
                                />
                                <TeamValue
                                  changeColor={data.response.teamStat.numberOfTeamFight.result === false}
                                >
                                  {`${data.response.teamStat.numberOfTeamFight.winRate.toFixed(2)}`}
                                </TeamValue>
                              </div>
                              <div className='AvgData'>{`${t(
                                'team.analysis.leagueAvg',
                              )} ${data.response.leagueStat.winRate.toFixed(2)}`}</div>
                            </>
                          )}
                        </DisplayInfoNumOfFight>
                      </div>
                    </div>
                  </ContentsBoxTwo>
                </DisplayWithIcon>

                {/* 팀 PR 지표 */}
                <TeamSBRTable>
                  <TableTitle>
                    <span>{t('team.analysis.sbrLabel')}</span>
                  </TableTitle>
                  <SBRTable>
                    <thead>
                      <tr>
                        <th className='PositionNav'>{t('team.analysis.position')}</th>
                        <th className='SummonerNav'>{t('team.analysis.player')}</th>
                        <th className='PlayerNav'>{t('team.analysis.name')}</th>
                        <th className='AttendNav'>{t('team.analysis.gamePlayed')}</th>
                        <th className='WinCountNav'>{t('team.analysis.winCount')}</th>
                        <th className='KDANAV'>{t('team.analysis.kda')}</th>
                        <th className='SBRNAV'>{t('team.analysis.sbr')}</th>
                        <th className='LeagueAvgNav'>{t('team.analysis.leagueAvg')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.response?.sbrAnalysis.map((sbr) => {
                        return (
                          <TableContents key={sbr.player} changeColor={sbr.focus}>
                            <td>
                              <img
                                className='PositionInfo'
                                src={`/Images/ico-position-${sbr.position}.png`}
                                alt='posIcon'
                              />
                            </td>
                            <td className='SummonerInfo'>{sbr.player}</td>
                            <td className='PlayerInfo'>
                              {lang === 'ko' ? sbr.nativeName.replaceAll(/&quot;|&amp;nbsp;/g, '') : sbr.name}
                            </td>
                            <td className='AttendInfo'>{sbr.gamesPlayed}</td>
                            <td className='WinCountInfo'>{sbr.win}</td>
                            <td className='KDAInfo'>
                              <span className='Kill'>{sbr.kill}</span>
                              <span className='Slash'>/</span>
                              <span className='Kill'>{sbr.death}</span>
                              <span className='Slash'>/</span>
                              <span className='Kill'>{sbr.assist}</span>
                              <span className='Kda'> {`${sbr.kda.toFixed(2)}:1`}</span>
                            </td>
                            <td className='SBR'>
                              <SBR sbr={sbr.sbr > sbr.leagueSbr}>{sbr.sbr.toFixed(1)}</SBR>
                            </td>
                            <td className='LeagueAvgInfo'>{sbr.leagueSbr.toFixed(1)}</td>
                          </TableContents>
                        );
                      })}
                    </tbody>
                  </SBRTable>
                </TeamSBRTable>

                {/* 하단 막대 그래프 */}
                <GraphContainer>
                  <FirstGankLine>
                    <NavBar>
                      <div className='Title'>
                        <span className='AverageTime'>{t('team.analysis.firstGank')}</span>
                      </div>
                      <div className='Legend'>
                        <p className='X'>X {t('team.analysis.lane')}</p>
                        <p className='Y'>Y {t('team.analysis.gankCount')}</p>
                      </div>
                    </NavBar>
                    {data.response.firstGank.firstGankList === 'NULL' ||
                    data.response.firstGank.firstGankList.length === 0 ? (
                      <NoData>{t('league.leagueStat.noData2')}</NoData>
                    ) : (
                      <GameTimeCharts>
                        {firstGankTime && <Bar data={firstGankTime} options={barOptions} />}
                      </GameTimeCharts>
                    )}
                  </FirstGankLine>
                  <AvgSupportTime>
                    <NavBar>
                      <div className='Title'>
                        <span className='AverageTime'>{t('team.analysis.supportTime')}</span>
                      </div>
                      <div className='Legend'>
                        <p className='X'>X {t('team.analysis.lane')}</p>
                        <p className='Y'>Y {t('team.analysis.ySupportTime')}</p>
                      </div>
                    </NavBar>
                    {!data.response.supportedTime.supportedTimeList ||
                    data.response.supportedTime.supportedTimeList.length === 0 ||
                    data.response.supportedTime.supportedTimeRow === 0 ? (
                      <NoData>{t('league.leagueStat.noData2')}</NoData>
                    ) : (
                      <GameTimeCharts>
                        {averageSupport && <Bar data={averageSupport} options={barOptions} />}
                      </GameTimeCharts>
                    )}
                  </AvgSupportTime>
                </GraphContainer>
              </div>
            )
          )}
        </>
      )}
    </WRAPPER>
  );
}

export default TeamAnalysis;

const WRAPPER = styled.div`
  max-width: 1100px;
`;

const TeamTotalStats = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: NotoSansKR;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: -0.6px;
  text-align: left;
  color: rgb(132, 129, 142);
  margin: 7px 0 15px 0;
  > .StatValue {
    font-size: 16px;
    font-weight: bold;
  }
`;

const IndexNav = styled.div`
  margin-top: 27px;
  display: flex;
  img {
    width: 19px;
    height: 19px;
    margin: 0 4px 4px 14px;
    object-fit: contain;
    vertical-align: middle;
  }
  span {
    font-family: SpoqaHanSansNeo;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.87;
    letter-spacing: normal;
    color: #84818e;
  }
`;

const DisplayWithIcon = styled.div`
  display: flex;
  margin-top: 14px;
`;

const TeamSBRTable = styled.div`
  width: auto;
  height: auto;
  border: solid 1px rgb(58, 55, 69);
  background-color: rgb(47, 45, 56);
  margin-top: 22px;
  border-radius: 20px;
`;

const GraphContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 23.5px;
`;

const ContentsBox = styled.div`
  display: flex;
  flex-direction: column;
  /* flex-wrap: nowrap; */
  width: auto;
  height: 198px;
  .FirstBox {
    display: flex;
    margin-bottom: 22px;
  }
  .SecondBox {
    display: flex;
  }
  .avgKill {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 202px;
    height: 88px;
    border: solid 1px #3a3745;
    background-color: #2f2d38;
    margin-right: 22px;
    border-radius: 20px;
    .MainIcon {
      margin-right: 18px;
    }
  }
  .avgGameTime {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 202px;
    height: 88px;
    border: solid 1px #3a3745;
    background-color: #2f2d38;
    margin-right: 59px;
    border-radius: 20px;
    .MainIcon {
      margin-right: 18px;
    }
  }
  .KillPerMin {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 202px;
    height: 88px;
    border: solid 1px #3a3745;
    background-color: #2f2d38;
    margin-right: 22px;
    border-radius: 20px;
    .MainIcon {
      margin-right: 18px;
    }
  }
  .AvgDragon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 202px;
    height: 88px;
    border: solid 1px #3a3745;
    background-color: #2f2d38;
    margin-right: 59px;
    border-radius: 20px;
    .MainIcon {
      margin-right: 18px;
    }
  }
`;

const DisplayInfo = styled.div`
  .SubTitle {
    font-family: SpoqaHanSansNeo;
    font-size: 13px;
    color: #84818e;
    margin-bottom: 8px;
  }
  .CalcData {
    display: flex;
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    color: #f04545;
    margin-bottom: 6px;
    img {
      width: 13px;
      height: 13px;
      object-fit: contain;
      margin-right: 8px;
      margin-top: 3px;
    }
  }
  .AvgData {
    margin-top: 4px;
    font-family: SpoqaHanSansNeo;
    font-size: 13px;
    color: #ffffff;
  }
`;

const DisplayInfoFirstGank = styled.div`
  .SubTitle {
    font-family: SpoqaHanSansNeo;
    font-size: 13px;
    color: #84818e;
    margin-bottom: 8px;
  }
  .CalcData {
    display: flex;
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    color: #f04545;
    margin-bottom: 6px;
    img {
      width: 13px;
      height: 13px;
      object-fit: contain;
      margin-right: 8px;
      margin-top: 3px;
    }
  }
  .AvgData {
    margin-top: 4px;
    font-family: SpoqaHanSansNeo;
    font-size: 13px;
    color: #ffffff;
  }
`;

const DisplayInfoNumOfFight = styled.div`
  .SubTitle {
    font-family: SpoqaHanSansNeo;
    font-size: 13px;
    color: #84818e;
    margin-bottom: 8px;
  }
  .CalcData {
    display: flex;
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    color: #f04545;
    margin-bottom: 6px;
    img {
      width: 13px;
      height: 13px;
      object-fit: contain;
      margin-right: 8px;
      margin-top: 3px;
    }
  }
  .AvgData {
    margin-top: 4px;
    font-family: SpoqaHanSansNeo;
    font-size: 13px;
    color: #ffffff;
  }
`;

const TeamWinRateGraph = styled.div`
  width: 130px;
  height: 130px;
`;

const ContentsBoxTwo = styled.div`
  display: flex;
  flex-direction: column;
  /* flex-wrap: nowrap; */
  width: auto;
  height: 198px;
  .FirstBox {
    display: flex;
    margin-bottom: 22px;
  }
  .SecondBox {
    display: flex;
  }
  .FirstHerald {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 202px;
    height: 88px;
    border: solid 1px #3a3745;
    background-color: #2f2d38;
    margin-right: 22px;
    margin-left: 59px;
    border-radius: 20px;
    .MainIcon {
      margin-right: 18px;
    }
  }
  .AvgFirstGank {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 202px;
    height: 88px;
    border: solid 1px #3a3745;
    background-color: #2f2d38;
    border-radius: 20px;
    padding-top: 15px;

    .MainIcon {
      margin-right: 18px;
    }
  }
  .FirstBaron {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 202px;
    height: 88px;
    border: solid 1px #3a3745;
    background-color: #2f2d38;
    margin-right: 22px;
    margin-left: 59px;
    border-radius: 20px;
    .MainIcon {
      margin-right: 18px;
    }
  }
  .AvgCombat {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20px;
    width: 202px;
    height: 88px;
    border: solid 1px #3a3745;
    background-color: #2f2d38;
    border-radius: 20px;
    position: relative;
    .MainIcon {
      margin-right: 18px;
    }
  }
`;

const TableTitle = styled.div`
  display: flex;
  align-items: center;
  height: 48.5px;
  border-bottom: 1px solid rgb(35, 33, 42);
  font-family: SpoqaHanSansNeo;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  padding: 13px 0 12.5px 15px;
  span {
    margin-right: 11px;
  }
  img {
    width: 15px;
    height: 15px;
    margin: 3px 0 3.3px 3px;
    object-fit: contain;
  }
`;

const SBRTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
  thead > tr {
    width: 100%;
    height: 28px;
    background-color: rgb(58, 55, 69);
    > th {
      font-family: SpoqaHanSansNeo;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      color: rgb(129, 126, 144);
      vertical-align: middle;
      text-align: center;
    }
  }
`;

const TableContents = styled.tr<{ changeColor: boolean }>`
  width: 100%;
  height: 28px;
  border-bottom: 1px solid rgb(58, 55, 69);
  > .SummonerInfo {
    /* width: 300px; */
  }
  > .KDAInfo {
    width: 234px;
  }
  > .SBR {
    width: 85px;
  }
  > td {
    font-family: SpoqaHanSansNeo;
    font-size: 15px;
    font-weight: normal;
    text-align: center;
    color: rgb(255, 255, 255);
    vertical-align: middle;
    text-align: center;
    img {
      width: 13px;
      height: 13px;
    }
    .Kill {
      color: #ffffff;
      width: 30px;
    }
    .Slash {
      color: #817e90;
      margin: 0 4px 0 4px;
    }
    .Kda {
      color: #f04545;
      margin-left: 7px;
    }
  }
  ${(props) =>
    props.changeColor &&
    css`
      background-color: #4b2b2f;
    `}
`;

const SBR = styled.div<{ sbr: boolean }>`
  color: #6b6979;
  ${(props) =>
    props.sbr &&
    css`
      color: #f04545;
    `}
`;

const FirstGankLine = styled.div`
  width: 538px;
  height: 270px;
  border: solid 1px rgb(58, 55, 69);
  background-color: rgb(47, 45, 56);
  border-radius: 20px;
  position: relative;
`;

const AvgSupportTime = styled.div`
  width: 538px;
  height: 270px;
  border: solid 1px rgb(58, 55, 69);
  background-color: rgb(47, 45, 56);
  border-radius: 20px;
  position: relative;
`;

const NavBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48.5px;
  border-bottom: 1px solid rgb(35, 33, 42);
  .AverageTime {
    width: 61px;
    font-family: SpoqaHanSansNeo;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    color: #fff;
    margin-left: 15px;
  }
  .X {
    width: auto;
    /* height: 17px; */
    font-family: SpoqaHanSansNeo;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    color: rgb(132, 129, 142);
    margin-right: 15px;
    ::first-letter {
      color: #f14444;
    }
  }
  .Y {
    width: auto;
    /* height: 17px; */
    font-family: SpoqaHanSansNeo;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    color: rgb(132, 129, 142);
    margin-right: 16px;
    ::first-letter {
      color: #f14444;
    }
  }
  .Legend {
    display: flex;
  }
`;

const GameTimeCharts = styled.div`
  padding: 23px;
  height: 226.5px;
`;

const TeamValue = styled.div<{ changeColor: boolean }>`
  ${(props) =>
    props.changeColor &&
    css`
      color: #0084d8;
    `}
`;

const NoData = styled.div`
  background-color: #2f2d38;
  color: #fff;
  width: auto;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
`;

const NoData2 = styled.div`
  background-color: #2f2d38;
  color: #fff;
  width: 80px;
  font-size: 12px;
  text-align: center;
  position: absolute;
  left: 62%;
  top: 70%;
  transform: translate(-50%, -50%);
`;

const ProgressContainer = styled.div`
  width: 100%;
  height: 800px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const NotFoundContainer = styled.div`
  width: 100%;
  height: 800px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const ERROR_CONTAINER = styled.div`
  background-color: #24222c;
  border-radius: 20px;
  ${spacing.paddingY(20)};
`;

const ERROR_TEXT = styled.div`
  text-align: center;
  font-size: 13px;
  p:first-of-type {
    ${spacing.marginB(2)}
  }
`;
