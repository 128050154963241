import { useEffect } from "react";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import GameAtom from "../recoil/ProGameReport/gameAtom";
import GameDatasetAtom from "../recoil/ProGameReport/gameDatasetAtom";
import VideoAtom from "../recoil/ProGameReport/videoAtom";

export const useResetProGameReport = () => {

  const resetFiexdDataset = useResetRecoilState(GameDatasetAtom.fixedDataset);
  const resetPlayerDataset = useResetRecoilState(GameDatasetAtom.playerDataset);
  const resetLogDataset = useResetRecoilState(GameDatasetAtom.logDataset);
  const resetStatusLogDataset = useResetRecoilState(GameDatasetAtom.statusLogDataset);
  const resetTeamGoldDataset = useResetRecoilState(GameDatasetAtom.teamGoldDataset);
  const resetMappingDataset = useResetRecoilState(GameDatasetAtom.mappingDataset);
  const resetLiveDataset = useResetRecoilState(GameDatasetAtom.liveDataset);
  const resetPlayersStatusDataset = useResetRecoilState(GameDatasetAtom.playersStatusDataset);
  const resetTimeLineDataset = useResetRecoilState(GameDatasetAtom.timeLineDataset);

  const resetTimer = useResetRecoilState(GameAtom.timer);
  const resetDetail = useResetRecoilState(GameAtom.detail);
  const resetPlayer = useResetRecoilState(GameAtom.player);
  const resetUniqueId = useResetRecoilState(GameAtom.uniqueId);
  const resetBlueteam = useResetRecoilState(GameAtom.blueteam);
  const resetRedteam = useResetRecoilState(GameAtom.redteam);
  const resetSelectedTeam = useResetRecoilState(GameAtom.selectedTeam);
  const resetSelectedPosition = useResetRecoilState(GameAtom.selectedPosition);
  const resetSelectedParticipant = useResetRecoilState(GameAtom.selectedParticipant);
  const resetStartTime = useResetRecoilState(GameAtom.startTime);
  const resetGameTime = useResetRecoilState(GameAtom.gameTime);
  const resetChampTab = useResetRecoilState(GameAtom.champTab);
  const resetEventLogActiveIdx = useResetRecoilState(GameAtom.eventLogActiveIdx);
  const resetStatusLogActiveIdx = useResetRecoilState(GameAtom.statusLogActiveIdx);
  const resetItemActiveIdx = useResetRecoilState(GameAtom.itemActiveIdx);
  const resetLiveActiveIdx = useResetRecoilState(GameAtom.liveActiveIdx);
  const resetGoldActiveIdx = useResetRecoilState(GameAtom.goldActiveIdx);
  const resetPlayersActiveIdx = useResetRecoilState(GameAtom.playersActiveIdx);
  const resetMappingActiveIdx = useResetRecoilState(GameAtom.mappingActiveIdx);
  const resetSeekTime = useResetRecoilState(GameAtom.seekTime);
  const resetSelectedStatusTime = useResetRecoilState(GameAtom.selectedStatusTime);
  const resetSelectedStatusType = useResetRecoilState(GameAtom.selectedStatusType);
  const resetSelectedStatusText = useResetRecoilState(GameAtom.selectedStatusText);

  const resetPlaying = useResetRecoilState(VideoAtom.playing);
  const resetUrl = useResetRecoilState(VideoAtom.url);
  const resetControls = useResetRecoilState(VideoAtom.controls);
  const resetLoop = useResetRecoilState(VideoAtom.loop);
  const resetLight = useResetRecoilState(VideoAtom.light);
  const resetVolume = useResetRecoilState(VideoAtom.volume);
  const resetMuted = useResetRecoilState(VideoAtom.muted);
  const resetPlaybackRate = useResetRecoilState(VideoAtom.playbackRate);
  const resetSeeking = useResetRecoilState(VideoAtom.seeking);
  const resetPlayedSeconds = useResetRecoilState(VideoAtom.playedSeconds);
  const resetDuration = useResetRecoilState(VideoAtom.duration);

  useEffect(() => {
    return (() => {
      resetFiexdDataset();
      resetPlayerDataset();
      resetLogDataset();
      resetStatusLogDataset();
      resetTeamGoldDataset();
      resetMappingDataset();
      resetLiveDataset();
      resetPlayersStatusDataset();
      resetTimeLineDataset();
      resetTimer();
      resetDetail();
      resetPlayer();
      resetUniqueId();
      resetBlueteam();
      resetRedteam();
      resetSelectedTeam();
      resetSelectedPosition();
      resetSelectedParticipant();
      resetStartTime();
      resetGameTime();
      resetChampTab();
      resetEventLogActiveIdx();
      resetStatusLogActiveIdx();
      resetItemActiveIdx();
      resetLiveActiveIdx();
      resetGoldActiveIdx();
      resetMappingActiveIdx();
      resetPlayersActiveIdx();
      resetSeekTime();
      resetSelectedStatusTime();
      resetSelectedStatusType();
      resetSelectedStatusText();

      resetPlaying()
      resetUrl()
      resetControls()
      resetLoop()
      resetLight()
      resetVolume()
      resetMuted()
      resetPlaybackRate()
      resetSeeking()
      resetPlayedSeconds()
      resetDuration()
    })
  }, [])
}