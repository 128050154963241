/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippy.js/react';
import { useMutation } from 'react-query';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import BoardToolTip from '../PlayerBoard/BoardToolTip';
import Avatar from '../../../Components/Ui/Avatar';
import Radio from '../../../Components/Ui/Radio';
import {
  tableStyle,
  transitionStyle,
  scrollbarStyle,
  borderRadiusStyle,
  buttonStyle,
  colors,
  spacing,
  typoStyle,
} from '../../../Styles/ui';

import { getTrueValueList } from '../../../lib/getTureValueList';
import Progress from '../../../Components/Ui/Loading/Progress';
import PlayerReportAtom, { PlayerReportResultAtom } from '../../../recoil/Filter/playerReportAtom';
import FilterService from '../../../services/FilterServices';
import { initializedObjValue } from '../../../lib/initializedObjValue';
import NotFound from '../../../Components/Ui/Error/NotFound';
import { userLanguageAtom } from '../../../recoil/Auth/userAtom';
import { PLAYERBOARD_TOOLTIP_CONTENTS } from '../../../lib/mapping/map_playerboard_tooltip';
import { ITeamStats } from '../../../services/types/player_services.type';
import { LOLPatchAtom } from '../../../recoil/common/championListAtom';
import useChampionImage from '../../../Hooks/useChampionImage';

interface DashBoardFilterProps {
  stats: ITeamStats;
  isLoading: boolean;
  onReset: () => void;
  onSubmit: () => void;
  currentChampion: string;
  currentOppChampion: string;
  total: number;
  oppTotal: number;
  [key: string]: any;
}

const DashBoardFilter = ({
  stats,
  isLoading,
  currentChampion,
  currentOppChampion,
  total,
  oppTotal,
  onReset,
  onSubmit,
}: DashBoardFilterProps) => {
  const { t } = useTranslation();
  const { getChampionImagePathByName } = useChampionImage();
  const lang = useRecoilValue(userLanguageAtom);
  const result = useRecoilValue(PlayerReportResultAtom);
  const progressData = t('solo.playerboard.dataFetching');
  const patch = useRecoilValue(LOLPatchAtom);

  // 챔피언
  const [champion, setChampion] = useRecoilState(PlayerReportAtom.champion);
  const selectedChampion = getTrueValueList(champion);

  // 상대챔피언
  const [oppchampion, setOppChampion] = useRecoilState(PlayerReportAtom.oppChampion);
  const setOppTotalGame = useSetRecoilState(PlayerReportAtom.oppTotalGame);
  const setTotalGame = useSetRecoilState(PlayerReportAtom.totalGame);

  // data패칭
  const {
    mutate: championMutate,
    data: championData,
    isLoading: isChampLoading,
    isError: isChampError,
  } = useMutation(
    () =>
      FilterService.getChampion({
        ...result,
        player: result.player[0].split('_')[1],
      }),
    {
      onSuccess: (res) => {
        const champArray = res.response?.champion.map((champ) => champ.eng);
        if (champArray) {
          setChampion(initializedObjValue(champArray, false));
        }
      },
    },
  );

  const {
    mutate: oppChampionMutate,
    data: oppChampionData,
    reset: oppChampionReset,
    isLoading: isOppChampLoading,
    isError: isOppChampError,
  } = useMutation(
    () =>
      FilterService.getOppChampion({
        ...result,
        player: result.player[0].split('_')[1],
        champion: selectedChampion ? selectedChampion[0] : '',
      }),
    {
      onSuccess: (res) => {
        const champArray = res.response?.champion.map((champ) => champ.eng);
        if (champArray) {
          setOppChampion(initializedObjValue(champArray, false));
        }
      },
    },
  );

  useEffect(() => {
    championMutate();
  }, [result]);

  useEffect(() => {
    if (selectedChampion && selectedChampion.length > 0) {
      oppChampionMutate();
    } else {
      oppChampionReset();
    }
  }, [champion]);

  if (!stats) {
    return <div>로딩중</div>;
  }

  // 인터렉션 핸들러
  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === 'champion') {
      setChampion((prev) => {
        const newObj = { ...prev };
        Object.keys(newObj).forEach((key) => {
          newObj[key] = false;
          newObj[value] = checked;
        });
        return newObj;
      });
    } else if (name === 'oppchampion') {
      setOppChampion((prev) => {
        const newObj = { ...prev };
        Object.keys(newObj).forEach((key) => {
          newObj[key] = false;
          newObj[value] = checked;
        });
        return newObj;
      });
    }
  };

  const secToMin = (sec) => {
    const mm = Math.floor(sec / 60);
    const ss = Math.floor(sec % 60);

    return `${mm}${t('solo.playerboard.min')} ${ss}${t('solo.playerboard.sec')}`;
  };

  return (
    <Container>
      {/* 헤더 */}
      <Header>
        <h4>{t('solo.playerboard.detailedStats')}</h4>
        <span>
          <Avatar src='/Images/ico-notice-gy.png' size={14} alt='알림' block={false} />
          {t('solo.playerboard.detailedStatsDesc')}
        </span>
      </Header>

      {/* 컨텐츠시작 */}
      <Main>
        {/* 필터 */}
        <FilterContainer>
          <FlexContainer>
            {/* 챔피언 체크박스 */}
            <div css={{ marginRight: 20, marginBottom: 20 }}>
              <STable1>
                <TableHeader>
                  <h5>{t('solo.playerboard.chooseChamp')}&nbsp;</h5>
                </TableHeader>
                <SHead>
                  <div css={Col1} />
                  <div css={Col2}>{`${t('video.jungle.champTitle')}(${t('solo.playerboard.matches')})`}</div>
                  <div css={[Col3]}>{t('solo.playerboard.redSide')}</div>
                  <div css={[Col3]}>{t('solo.playerboard.blueSide')}</div>
                  <div css={[Col4]}>{t('league.playerStat.firstPickRate')}</div>
                  <div css={[Col3]}>{t('league.playerStat.win')}</div>
                  <div css={[Col3]}>{t('league.playerStat.lose')}</div>
                  <div css={[Col3]}>{t('solo.playerboard.winrate')}</div>
                  <div css={[Col3]}>PR</div>
                </SHead>
                <TABLE_COTENT_SCROLL>
                  {isChampLoading ? (
                    <ProgressWrapper>
                      <Progress text={t('solo.playerboard.dataFetching')} />
                    </ProgressWrapper>
                  ) : isChampError ? (
                    <NotFound css={{ height: '100%' }} text={t('solo.playerboard.dataFetchingFailed')} />
                  ) : (
                    <>
                      {championData &&
                        championData.response?.champion.map((champ) => {
                          return (
                            <SRow isActive={champion[champ.eng]} key={champ.eng}>
                              <div css={Col1}>
                                <Radio
                                  name='champion'
                                  value={champ.eng}
                                  checked={champion[champ.eng]}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setTotalGame(champ.total.toString());
                                  }}
                                />
                              </div>
                              <SChamp css={Col2}>
                                <Avatar
                                  css={{ marginRight: 5 }}
                                  size={20}
                                  src={getChampionImagePathByName(champ.eng)}
                                  alt='champLogo'
                                />
                                <span>{`${champ.eng} (${champ.total})`}</span>
                              </SChamp>
                              <div css={[Col3]}>
                                <SRed>{`${champ.red}`}</SRed>
                              </div>
                              <div css={[Col3]}>
                                <SBlue>{`${champ.blue}`}</SBlue>
                              </div>
                              <div css={[Col4]}>
                                <span>{`${champ.firstPick.toFixed()}%`}</span>
                              </div>
                              <div css={[Col3]}>
                                <span>{`${champ.win}`}</span>
                              </div>
                              <div css={[Col3]}>
                                <span>{`${champ.total - champ.win}`}</span>
                              </div>
                              <div css={[Col3]}>
                                <span>{`${champ.winRate.toFixed()}%`}</span>
                              </div>
                              <div css={[Col3]}>
                                <span>{`${champ.pr.toFixed(1)}`}</span>
                              </div>
                            </SRow>
                          );
                        })}
                    </>
                  )}
                </TABLE_COTENT_SCROLL>
              </STable1>
            </div>

            {/* 상대팀 챔피언 체크박스 */}
            <div css={{ marginBottom: 20 }}>
              <STable2>
                <TableHeader>
                  <h5>{`VS ${t('solo.playerboard.chooseOppChamp')}`}&nbsp;</h5>
                </TableHeader>
                <SHead>
                  <div css={Col2}>{`${t('video.jungle.champTitle')}(${t('solo.playerboard.matches')})`}</div>
                </SHead>

                <TABLE_COTENT_SCROLL>
                  {!selectedChampion || selectedChampion.length === 0 ? (
                    <SSelectOption>{t('solo.playerboard.chooseChampFirst')}</SSelectOption>
                  ) : (
                    <>
                      {isOppChampLoading ? (
                        <ProgressWrapper>
                          <Progress text={t('solo.playerboard.dataFetching')} />
                        </ProgressWrapper>
                      ) : isOppChampError ? (
                        <NotFound css={{ height: '100%' }} text={t('solo.playerboard.dataFetchingFailed')} />
                      ) : (
                        <>
                          {oppChampionData?.response.champion.map((oppchamp) => {
                            return (
                              <SRow isActive={oppchampion[oppchamp.eng]} key={`opp${oppchamp.eng}`}>
                                <div css={Col1}>
                                  <Radio
                                    name='oppchampion'
                                    value={oppchamp.eng}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setOppTotalGame(oppchamp.total);
                                    }}
                                    checked={oppchampion[oppchamp.eng]}
                                  />
                                </div>

                                <SChamp css={Col2}>
                                  <Avatar
                                    css={{ marginRight: 5 }}
                                    size={20}
                                    src={`Images/champion/${oppchamp.eng}.png`}
                                    alt='oppChampLogo'
                                  />
                                  <span>{`${oppchamp.eng} (${oppchamp.total})`}</span>
                                </SChamp>
                              </SRow>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </TABLE_COTENT_SCROLL>
              </STable2>
            </div>
          </FlexContainer>

          {/* 버튼 */}
          <ButtonContainer>
            <button type='button' onClick={onSubmit}>
              {t('solo.playerboard.confirm')}
            </button>
            <button
              type='button'
              onClick={() => {
                setChampion((prev) => {
                  const newObj = { ...prev };
                  Object.keys(newObj).forEach((key) => {
                    newObj[key] = false;
                  });
                  return newObj;
                });

                setOppChampion((prev) => {
                  const newObj = { ...prev };
                  Object.keys(newObj).forEach((key) => {
                    newObj[key] = false;
                  });
                  return newObj;
                });
                setTotalGame('');
                onReset();
              }}
            >
              <Avatar css={{ marginRight: 5 }} src='/Images/icon/ico_reset.svg' size={10} />
              {t('solo.playerboard.reset')}
            </button>
          </ButtonContainer>
        </FilterContainer>

        {/* 표 */}
        <FilterTitleContainer>
          {/* 전체경기 */}
          {!currentChampion && !currentOppChampion && <div>{t('solo.playerboard.wholeGames')}</div>}
          {/* 챔피언 선택 */}
          {currentChampion && !currentOppChampion && (
            <div>{`${currentChampion} 
          (${total} ${t('solo.playerboard.games2')}) 
          ${t('solo.playerboard.stats2')}`}</div>
          )}

          {/* 챔피언 + 상대챔피언 선택 */}
          {currentChampion && currentOppChampion && (
            <div>{`${currentChampion} VS ${currentOppChampion} (${oppTotal} ${t('solo.playerboard.games2')}) 
          ${t('solo.playerboard.stats')}`}</div>
          )}
        </FilterTitleContainer>
        <TableContainer>
          {isLoading ? (
            <ProgressContainer>
              <Progress text={progressData} />
            </ProgressContainer>
          ) : (
            <>
              {/* 라인전 지표 */}
              <StatBox>
                <thead>
                  <StatNav>
                    <th className='StatTitle'>{t('solo.playerboard.laneStat')}</th>
                    <th className='LeagueAvg'>{t('solo.playerboard.avgLeague')}</th>
                    <th className='Icon'> </th>
                    <th className='PlayerScore'>{t('solo.playerboard.playerStat')}</th>
                  </StatNav>
                </thead>
                <tbody>
                  {stats.lineStats.map((title) => {
                    return title.data === 'NULL' ? (
                      <></>
                    ) : (
                      <MapStat key={title.eng}>
                        <Tippy // options
                          duration={0}
                          delay={[100, 0]}
                          content={
                            <BoardToolTip title={title} contents={PLAYERBOARD_TOOLTIP_CONTENTS[title.name]} />
                          }
                          placement='top'
                        >
                          <td className='StatNum'>{lang === 'ko' ? title.name : title.eng}</td>
                        </Tippy>
                        <LeagueValue>
                          {title.name === '솔로킬' || title.name.includes('횟수')
                            ? Number(title.leaguedata).toFixed(2)
                            : Number(title.leaguedata).toFixed(1)}
                        </LeagueValue>
                        <td className='Icon'>
                          <img
                            src={
                              title.leaguedata <= title.data
                                ? '/Images/ico-point-high.png'
                                : '/Images/ico-point-low-blue.png'
                            }
                            width='17px'
                            height='11px'
                            alt='pointIcon'
                          />
                        </td>
                        <PlayerValue className='playerValue' changeColor={title.leaguedata > title.data}>
                          {title.name === '솔로킬' || title.name.includes('횟수')
                            ? Number(title.data).toFixed(2)
                            : Number(title.data).toFixed(1)}
                        </PlayerValue>
                      </MapStat>
                    );
                  })}
                </tbody>
              </StatBox>

              {/* 교전/로밍/갱킹 지표 */}
              <StatBox>
                <thead>
                  <StatNav>
                    <th className='StatTitle'>{t('solo.playerboard.teamFight')}</th>
                    <th className='LeagueAvg'>{t('solo.playerboard.avgLeague')}</th>
                    <th className='Icon'> </th>
                    <th className='PlayerScore'>{t('solo.playerboard.playerStat')}</th>
                  </StatNav>
                </thead>
                <tbody>
                  {stats.engagementStats.map((title) => {
                    return title.data === 'NULL' ? (
                      <></>
                    ) : (
                      <MapStat key={title.eng}>
                        <Tippy // options
                          duration={0}
                          delay={[100, 0]}
                          content={
                            <BoardToolTip title={title} contents={PLAYERBOARD_TOOLTIP_CONTENTS[title.name]} />
                          }
                          placement='top'
                        >
                          <td className='StatNum'>{lang === 'ko' ? title.name : title.eng}</td>
                        </Tippy>
                        <LeagueValue>
                          {title.name.includes('빈도')
                            ? title.leaguedata.toFixed(2)
                            : title.leaguedata.toFixed(1)}
                        </LeagueValue>
                        <td className='Icon'>
                          <img
                            src={
                              title.leaguedata <= title.data
                                ? '/Images/ico-point-high.png'
                                : '/Images/ico-point-low-blue.png'
                            }
                            width='17px'
                            height='11px'
                            alt='pointIcon'
                          />
                        </td>
                        <PlayerValue className='playerValue' changeColor={title.leaguedata > title.data}>
                          {title.name.includes('빈도') ? title.data.toFixed(2) : title.data.toFixed(1)}
                        </PlayerValue>
                      </MapStat>
                    );
                  })}
                </tbody>
              </StatBox>

              {/* 성향 지표 */}
              <StatBox>
                <thead>
                  <StatNav>
                    <th className='StatTitle'>{t('solo.playerboard.tendency')}</th>
                    <th className='LeagueAvg'>{t('solo.playerboard.avgLeague')}</th>
                    <th className='Icon'> </th>
                    <th className='PlayerScore'>{t('solo.playerboard.playerStat')}</th>
                  </StatNav>
                </thead>
                <tbody>
                  {stats.personalityStats.map((title) => {
                    return title.data === 'NULL' ? (
                      <></>
                    ) : (
                      <MapStat key={title.eng}>
                        <Tippy // options
                          duration={0}
                          delay={[100, 0]}
                          content={
                            <BoardToolTip title={title} contents={PLAYERBOARD_TOOLTIP_CONTENTS[title.name]} />
                          }
                          placement='top'
                        >
                          <td className='StatNum'>
                            {lang === 'ko'
                              ? title.name === '첫 텔레포트 사용 시간 (초)'
                                ? '첫 텔레포트 시간'
                                : title.name
                              : title.eng === 'First Teleport Time (sec)'
                              ? 'First Teleport Time'
                              : title.eng}
                          </td>
                        </Tippy>
                        <LeagueValue>
                          {title.name === '첫 텔레포트 사용 시간 (초)'
                            ? secToMin(title.leaguedata.toFixed(1))
                            : title.name === '텔레포트 선제 사용빈도' || title.name === '교전 참여 횟수'
                            ? title.leaguedata.toFixed(2)
                            : title.leaguedata.toFixed(1)}
                        </LeagueValue>
                        <td className='Icon'>
                          <img
                            src={
                              title.leaguedata <= title.data
                                ? '/Images/ico-point-high.png'
                                : '/Images/ico-point-low-blue.png'
                            }
                            width='17px'
                            height='11px'
                            alt='pointIcon'
                          />
                        </td>
                        <PlayerValue className='playerValue' changeColor={title.leaguedata > title.data}>
                          {title.name === '첫 텔레포트 사용 시간 (초)'
                            ? secToMin(title.data.toFixed(1))
                            : title.name === '텔레포트 선제 사용빈도' || title.name === '교전 참여 횟수'
                            ? title.data.toFixed(2)
                            : title.data.toFixed(1)}
                        </PlayerValue>
                      </MapStat>
                    );
                  })}
                </tbody>
              </StatBox>
            </>
          )}
        </TableContainer>
      </Main>
    </Container>
  );
};
const Container = styled.div`
  ${borderRadiusStyle[20]}
  background-color: ${colors.bg_select};
`;
const Header = styled.div`
  ${spacing.paddingX(6)}
  ${spacing.paddingY(5)}
  ${typoStyle.contents_title};
  display: flex;
  border-bottom: 1px solid ${colors.border_light};

  span {
    ${typoStyle.info};
    ${spacing.marginL(3)};
    display: flex;
    align-items: center;

    > div {
      ${spacing.marginR(1)}
    }
  }
`;

const Main = styled.div`
  ${spacing.paddingY(6)}
  ${spacing.paddingX(4)}
`;
const FilterContainer = styled.div`
  ${spacing.marginB(7)}
`;
const FlexContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
const ButtonContainer = styled.div`
  display: flex;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > button:first-of-type {
    flex: 3;
    ${typoStyle.body}
    ${spacing.paddingY(4)}
    ${buttonStyle.color.main}
    ${borderRadiusStyle[20]}
    ${spacing.marginR(2)}
  }
  > button:last-of-type {
    flex: 1;
    ${typoStyle.body}
    ${spacing.paddingY(4)}
    ${buttonStyle.color.normal}
    color: ${colors.text};
    ${borderRadiusStyle[20]}
  }
`;

const TableContainer = styled.div`
  display: flex;
  justify-content: center;
  /* ${spacing.marginT(2)} */
  ${spacing.marginX(-2)}
`;

const ProgressContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  ${spacing.paddingT(35)}
  ${spacing.paddingB(35)}
`;

const StatBox = styled.table`
  flex: 1;
  height: 100%;
  background-color: rgb(47, 45, 56);
  ${spacing.marginX(2)}
  border-radius: 20px;
`;

const StatNav = styled.tr`
  border-bottom: 1px solid rgb(35, 33, 42);

  > th:first-of-type {
    ${spacing.paddingY(2)}
    ${spacing.paddingL(3)}
    text-align: left;
    width: 55%;
  }

  > .Icon {
    width: 17px;
  }

  > th {
    ${typoStyle.label};
    text-align: center;

    vertical-align: middle;
  }

  > th:last-of-type {
    text-align: right;
    ${spacing.paddingR(3)}
  }
`;

const MapStat = styled.tr`
  width: 100%;
  background-color: rgb(58, 55, 69);

  &:nth-of-type(2n) {
    background-color: rgb(47, 45, 56);
  }

  &:last-child {
    /* 마지막 tr에 rounded border 주기 */
    > td {
      ${spacing.paddingB(2)}
    }
    > .StatNum {
      border-bottom-left-radius: 20px;
    }
    > .playerValue {
      border-bottom-right-radius: 20px;
    }
  }

  > .StatNum {
    text-align: left;
    padding-left: 10px;
    ${typoStyle.contents}
  }
  > td {
    vertical-align: middle;
    text-align: center;
    ${spacing.paddingY(1)}
  }
`;

const LeagueValue = styled.td`
  ${typoStyle.contents}
`;

const PlayerValue = styled.td<{ changeColor: boolean }>`
  ${typoStyle.contents}
  color: ${({ changeColor }) => (changeColor ? colors.blue : colors.badge_red)}
`;

// 내팀, 상대팀

// 테이블 관련 코드
const STable1 = styled.div`
  width: 590px;
  border-radius: 20px;
  background-color: #2f2d38;
`;
const STable2 = styled.div`
  position: relative;
  width: 381px;
  height: 100%;
  border-radius: 20px;
  background-color: #2f2d38;
`;

const SHead = styled.div`
  ${tableStyle.table_head}
  ${spacing.paddingL(5)}
  ${spacing.paddingR(5)} /* padding: 0 10px; */
  text-align: center;
`;
const SBody = styled.div`
  ${spacing.paddingL(2)}
  ${spacing.paddingR(2)}
`;

const TABLE_COTENT_SCROLL = styled.div`
  height: 180px;
  ${scrollbarStyle.scroll_4}
`;

const SSelectOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  margin: auto;
  color: ${colors.white};
`;

const SRow = styled.div<{ isActive: boolean }>`
  border-radius: 999px;
  ${tableStyle.table_row}
  ${transitionStyle.background}
  
  background-color: ${({ isActive }) => (isActive ? colors.bg_gnb : '')};
  &:hover {
    background-color: ${colors.bg_gnb};
  }
`;
const SChamp = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  span {
    ${typoStyle.noWrap}
  }
`;

// 테이블
const Col1 = css`
  margin-right: 4px;
`;

const Col2 = css`
  flex: 1;
  max-width: 180px;
`;

const Col3 = css`
  width: 43px;
  text-align: center;
`;

const Col4 = css`
  flex: 1;
  max-width: 180px;
  text-align: center;
`;

const TableHeader = styled.div`
  ${typoStyle.contents_title}
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${spacing.paddingB(4)}

  > div {
    em {
      color: ${colors.info};
    }
    > span:not(:last-of-type) {
      ${spacing.marginR(4)}
    }
  }
  ${spacing.paddingY(4)}
  ${spacing.paddingX(3)}
`;

const SRed = styled.div`
  ${Col3}
  color: ${colors.red};
`;
const SBlue = styled.div`
  ${Col3}
  color: ${colors.blue};
`;

const FilterTitleContainer = styled.div`
  /* border: 1px solid pink; */
  width: 100%;
  height: 36px;
  /* margin-bottom: 12px; */
  padding: 0 8px;
  color: #fff;
  font-weight: 500;
`;

const ProgressWrapper = styled.div`
  width: 100%;
  height: 180px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export default DashBoardFilter;
