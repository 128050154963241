export const TRANSLATE_TOOLTIP = {
  리그보고서: {
    대회주요픽: 'tooltip.리그보고서.대회주요픽',
    주요픽들간의전적: 'tooltip.리그보고서.주요픽들간의전적',
    챔피언티어: 'tooltip.리그보고서.챔피언티어',
    유니크픽: 'tooltip.리그보고서.유니크픽',
    평균게임시간: 'tooltip.리그보고서.평균게임시간',
    라인별평균서포팅시간: 'tooltip.리그보고서.라인별평균서포팅시간',
    평균교전횟수: 'tooltip.리그보고서.평균교전횟수',
    평균첫갱시간: 'tooltip.리그보고서.평균첫갱시간',
  },
  선수보고서: {
    선픽률: 'tooltip.선수보고서.선픽률',
    솔로킬: 'tooltip.선수보고서.솔로킬',
    DPM: 'tooltip.선수보고서.DPM',
    DTPM: 'tooltip.선수보고서.DTPM',
    CS10분: 'tooltip.선수보고서.10분CS',
    PR: 'tooltip.선수보고서.PR',
    데미지: 'tooltip.선수보고서.데미지',
    생존: 'tooltip.선수보고서.생존',
    킬캐치: 'tooltip.선수보고서.킬캐치',
    라인전: 'tooltip.선수보고서.라인전',
    초반교전: 'tooltip.선수보고서.초반교전',
    CSD7: 'tooltip.선수보고서.CSD7',
    CSD10: 'tooltip.선수보고서.CSD10',
    XPD7: 'tooltip.선수보고서.XPD7',
    XPD10: 'tooltip.선수보고서.XPD10',
    GD7: 'tooltip.선수보고서.GD7',
    GD10: 'tooltip.선수보고서.GD10',
    CSD15: 'tooltip.선수보고서.CSD15',
    XPD15: 'tooltip.선수보고서.XPD15',
    GD15: 'tooltip.선수보고서.GD15',
    탑로밍빈도: 'tooltip.선수보고서.탑로밍빈도',
    미드로밍빈도: 'tooltip.선수보고서.미드로밍빈도',
    바텀로밍빈도: 'tooltip.선수보고서.바텀로밍빈도',
    로밍빈도: 'tooltip.선수보고서.로밍빈도',
    첫텔레포트시간: 'tooltip.선수보고서.첫텔레포트시간',
    텔레포트선제사용빈도: 'tooltip.선수보고서.텔레포트선제사용빈도',
    챔피언풀: 'tooltip.선수보고서.챔피언풀',
    상대진영와드확률: 'tooltip.선수보고서.상대진영와드확률',
    타워허깅시간: 'tooltip.선수보고서.타워허깅시간',
    갱회피횟수: 'tooltip.선수보고서.갱회피횟수',
    갱횟수: 'tooltip.선수보고서.갱횟수',
    갱성공률: 'tooltip.선수보고서.갱성공률',
    플레이메이킹횟수: 'tooltip.선수보고서.플레이메이킹횟수',
    교전합류시간: 'tooltip.선수보고서.교전합류시간',
    킬관여율: 'tooltip.선수보고서.킬관여율',
    듀오인접률: 'tooltip.선수보고서.듀오인접률',
    비듀오인접시간: 'tooltip.선수보고서.비듀오인접시간',
    라인서포트횟수: 'tooltip.선수보고서.라인서포트횟수',
    서포트받은시간: 'tooltip.선수보고서.서포트받은시간',
    서포트받은시간2: 'tooltip.선수보고서.서포트받은시간+',
    교전참여횟수: 'tooltip.선수보고서.교전참여횟수',
    상대정글침입시간: 'tooltip.선수보고서.상대정글침입시간',
    합리적위험감수성향: 'tooltip.선수보고서.합리적위험감수성향',
    라인시팅지수: 'tooltip.선수보고서.라인시팅지수',
    탑시팅지수: 'tooltip.선수보고서.탑시팅지수',
    미드시팅지수: 'tooltip.선수보고서.미드시팅지수',
    바텀시팅지수: 'tooltip.선수보고서.바텀시팅지수',
  },
};
