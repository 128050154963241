import styled from '@emotion/styled';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { SCRIM } from '../../lib/pagePath';
import { colors, scrollbarStyle, spacing, transitionStyle } from '../../Styles/ui';

const Tabs = [
  { path: '', title: 'scrim.tab.input' },
  { path: SCRIM.banpick, title: 'scrim.tab.banpick' },
  { path: SCRIM.team, title: 'scrim.tab.team' },
  { path: SCRIM.banpickReport, title: 'scrim.tab.banpickReport' },
];

const Scrim = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <WRAPPER>
      <CONTENT>
        <SCROLLCONTAINER>
          <INNER_CONTAINER>
            <TabHeader>
              {Tabs.map((tab, i) => {
                const active =
                  i === 0 ? pathname.split('/')[2] === undefined : pathname.split('/')[2] === tab.path;
                return (
                  <li key={tab.title}>
                    <TAB active={active} to={tab.path}>
                      {t(tab.title)}
                    </TAB>
                  </li>
                );
              })}
            </TabHeader>
            <Outlet />
          </INNER_CONTAINER>
        </SCROLLCONTAINER>
      </CONTENT>
    </WRAPPER>
  );
};

const WRAPPER = styled.div`
  display: flex;
  min-height: 100%;
  background-color: #16151c;
`;

const CONTENT = styled.div`
  width: 100%;
  height: 100vh;
`;

const TabHeader = styled.ul`
  display: flex;
  ${spacing.marginB(5)};
`;

const SCROLLCONTAINER = styled.div`
  min-height: 100%;
  ${spacing.padding(8)}
  ${scrollbarStyle.scroll_4}
`;

const INNER_CONTAINER = styled.div`
  max-width: 1300px;
  min-width: calc(768px);
`;

const TAB = styled(Link)<{ active: boolean }>`
  font-size: 18px;
  color: ${colors.text};
  ${spacing.paddingX(4)}
  ${spacing.paddingY(3)}
  ${transitionStyle.opacity}
  border-color: ${({ active }) => (active ? colors.text : 'transparent')};
  opacity: ${({ active }) => (active ? 1 : 0.3)};

  &:hover {
    color: ${colors.text};
    opacity: 1;
  }
`;

export default Scrim;
