import React, { useState } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useRecoilValue } from 'recoil';
import GameDatasetAtom from '../../../../../recoil/ProGameReport/gameDatasetAtom';
import GameAtom from '../../../../../recoil/ProGameReport/gameAtom';

function thousandGold(gold: number): string {
  let result = `${gold}`;
  if (gold >= 1000) {
    result = `${Math.floor(gold / 100) / 10}k`;
  }
  return result;
}

const TimeLineTeamGoldLabel = () => {
  const teamGoldDataset = useRecoilValue(GameDatasetAtom.teamGoldDataset);
  const goldActiveIdx = useRecoilValue(GameAtom.goldActiveIdx);
  const goldSet = teamGoldDataset[goldActiveIdx];
  return (
    <TeamGoldBox>
      <div className='blue title'>{thousandGold(goldSet.blueGold)}</div>
      <div className='gap title'>{thousandGold(goldSet.blueGold - goldSet.redGold)}</div>
      <div className='red title'>{thousandGold(goldSet.redGold)}</div>
    </TeamGoldBox>
  );
};

export default TimeLineTeamGoldLabel;

const TeamGoldBox = styled.div`
  .blue {
    color: #0075bf;
  }

  .gap {
    color: #fff;
  }

  .red {
    color: #f04545;
  }
`;
