import React from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import TeamStatusNav from './Component/TeamStatusNav';
import ChampionLiuneBox from './Component/ChampionLineBox';
import { useRecoilValue } from 'recoil';
import VideoAtom from '../../../../recoil/ProGameReport/videoAtom';
import GameDatasetAtom from '../../../../recoil/ProGameReport/gameDatasetAtom';
import GameAtom from '../../../../recoil/ProGameReport/gameAtom';

const TeamStatus = () => {
  const playedSeconds = useRecoilValue(VideoAtom.playedSeconds);
  const fixedDataset = useRecoilValue(GameDatasetAtom.fixedDataset);
  const liveDataset = useRecoilValue(GameDatasetAtom.liveDataset);
  const mappingDataset = useRecoilValue(GameDatasetAtom.mappingDataset);
  const playersStatusDataset = useRecoilValue(GameDatasetAtom.playersStatusDataset);
  const teamGoldDataset = useRecoilValue(GameDatasetAtom.teamGoldDataset);
  const goldActiveIdx = useRecoilValue(GameAtom.goldActiveIdx);
  const mappingActiveIdx = useRecoilValue(GameAtom.mappingActiveIdx);
  const liveActiveIdx = useRecoilValue(GameAtom.liveActiveIdx);
  const playersActiveIdx = useRecoilValue(GameAtom.playersActiveIdx);
  const startTime = useRecoilValue(GameAtom.startTime);
  const blueteam = useRecoilValue(GameAtom.blueteam);
  const redteam = useRecoilValue(GameAtom.redteam);

  return (
    <ChamStatusContainer>
      <TeamStatusNav />
      {[...Array(5)].map((_, idx) => {
        return (
          <ChampionLiuneBox
            position={idx}
            playedSeconds={playedSeconds}
            fixedDataset={fixedDataset}
            liveDataset={liveDataset}
            mappingDataset={mappingDataset}
            playersStatusDataset={playersStatusDataset}
            teamGoldDataset={teamGoldDataset}
            goldActiveIdx={goldActiveIdx}
            mappingActiveIdx={mappingActiveIdx}
            liveActiveIdx={liveActiveIdx}
            playersActiveIdx={playersActiveIdx}
            startTime={startTime}
            blueteam={blueteam}
            redteam={redteam}
          />
        );
      })}
    </ChamStatusContainer>
  );
};

export default TeamStatus;

const ChamStatusContainer = styled.div`
  width: 240px;
  height: 1080px;
  padding: 13px 14px 0 0;
  box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.15);
`;
