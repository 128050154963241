// react query

import { useCallback, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import { LocalizationProvider } from '@mui/lab';
import { CssBaseline, ThemeProvider, createTheme, useTheme } from '@mui/material';
import * as coreLocales from '@mui/material/locale';
import * as gridLocales from '@mui/x-data-grid-pro';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { errorAtom } from './recoil/errors/errorsAtom';
import { nunuVersion } from './Pages/config';
import ModalProvider from './Components/Modals/ModalProvider';
import Modals from './Components/Modals/Modals';
import Routes from './Routes';
import { colors } from './Styles/ui';
import { userLanguageAtom } from './recoil/Auth/userAtom';

type SupportedLocales = keyof typeof coreLocales;

const App = () => {
  console.log(
    `%c 굴려라 데이터 스노우볼! NUNU - ${nunuVersion} `,
    `background-color:${colors.보라색[400]}; color:white;`,
  );
  const theme = useTheme();
  const lang = useRecoilValue(userLanguageAtom);

  const LocalizedTheme = useMemo(() => {
    const locale: SupportedLocales = lang === 'ko' ? 'koKR' : 'enUS';
    return createTheme(theme, gridLocales[locale], coreLocales[locale]);
  }, [lang, theme]);

  const setError = useSetRecoilState(errorAtom);
  const ErrorHandler = useCallback(
    (err: any) => {
      if (err.status) {
        setError(err);
      }
    },
    [setError],
  );
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        onError: ErrorHandler,
      },
      mutations: {
        onError: ErrorHandler,
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={LocalizedTheme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ModalProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Routes />
              <Modals />
            </LocalizationProvider>
          </ModalProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
