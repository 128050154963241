import React, { memo, useState } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import { useRecoilValue } from 'recoil';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import secToMS from '../../../../../../lib/secToMS';
import { recentVersion } from '../../../../../config';
import { EventsLog } from '../../../../../../services/GameServices';

import GameDatasetAtom from '../../../../../../recoil/ProGameReport/gameDatasetAtom';
import useChampionImage from '../../../../../../Hooks/useChampionImage';

interface AssistInfo {
  player: string;
  championEng: string;
}

const KillEventBox = ({ data, isActive }: { data: EventsLog; isActive: boolean }) => {
  const { getChampionImagePathByName } = useChampionImage();
  const fixedDataset = useRecoilValue(GameDatasetAtom.fixedDataset);
  const { t } = useTranslation();

  const time = secToMS(Math.floor(data.realCount / 2));
  const type = `${t('game.log.event.kill')}`;
  const team = data.participantid < 6 ? 0 : 1;
  const { info } = fixedDataset[team].players[team === 0 ? data.participantid - 1 : data.participantid - 6];
  const { player, championEng } = info;
  const oppTeam = team === 0 ? 1 : 0;
  const oppInfo = fixedDataset[oppTeam].players[oppTeam === 0 ? data.victimId - 1 : data.victimId - 6].info;
  const oppPlayer = oppInfo.player;
  const oppChampionEng = oppInfo.championEng;
  const assistList: AssistInfo[] | undefined =
    data.assistingParticipantIds &&
    data.assistingParticipantIds.map((id) => {
      const { info } = fixedDataset[team].players[team === 0 ? id - 1 : id - 6];
      return { player: info.player, championEng: info.championEng };
    });

  return (
    <LogContent isActive={isActive} team={team} isAssist={assistList ? assistList.length > 0 : false}>
      <div className='title'>
        <div className='dot' />
        <span>{`${time} ${type}`}</span>
      </div>
      <div className='body'>
        <img src={getChampionImagePathByName(championEng)} alt={championEng} />
        <span className='player-name'>{player}</span>
        <img src={`/Images/ic_${team === 0 ? 'blue' : 'red'}_kill.svg`} alt='champion' />
        <img src={getChampionImagePathByName(oppChampionEng)} alt={oppChampionEng} />
        <span className='player-name'>{oppPlayer}</span>
      </div>
      <div className='footer'>
        {assistList &&
          assistList.map((data) => {
            return (
              <>
                <img src={`/Images/champion/${data.championEng}.png`} alt='champion' />
                <div className='player-name'>{data.player}</div>
              </>
            );
          })}
      </div>
    </LogContent>
  );
};

export default memo(KillEventBox);

const LogContent = styled.div<{ isActive: boolean; team: number; isAssist: boolean }>`
  width: 180px;
  height: auto;
  margin: 5px 0;
  padding: 8px 8px 8px 8px;
  border-radius: 10px;
  background-color: #000;
  opacity: ${(props) => (props.isActive ? `1` : `0.3`)};
  border: solid 2px ${(props) => props.isActive && (props.team === 1 ? `#f04545` : `#0075bf`)};
  cursor: pointer;

  .title {
    display: flex;
    height: 19px;
    margin: 0 5px 0px;
    //background-color: #f00;
    span {
      font-family: SpoqaHanSansNeo;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.15;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
  }

  .body {
    display: flex;
    ${(props) =>
      props.isAssist
        ? css`
            padding-bottom: 3px;
            border-bottom: solid 1px #23212a;
          `
        : css`
            padding-bottom: 0px;
          `}

    img {
      width: 15px;
      height: 15px;
      //margin: 1px 2px 1px 0;
      object-fit: contain;
      border-radius: 20px;
      vertical-align: middle;
    }

    .player-name {
      font-family: SpoqaHanSansNeo;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
  }
  .footer {
    display: flex;
    margin-top: 3px;
    img {
      width: 15px;
      height: 15px;
      //margin: 1px 2px 1px 0;
      object-fit: contain;
      border-radius: 20px;
    }

    .player-name {
      font-family: SpoqaHanSansNeo;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 18px;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
  }

  .dot {
    width: 6px;
    height: 6px;
    margin: 4px 5px 10px 0px;
    background-color: ${(props) => (props.team === 1 ? `#f04545` : `#0075bf`)};
    border-radius: 10px;
  }
`;
