/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import { useState } from 'react';
import styled from '@emotion/styled';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { prlineOption, totalTrendOption } from '../../../Styles/chart/option';
import ExcelExport from '../../../Components/UtilityComponent/ExcelExport';
import { spacing, typoStyle, colors, borderRadiusStyle, scrollbarStyle } from '../../../Styles/ui';
import { ITeamRecords, ITeamTrends } from '../../../services/types/player_services.type';

const graphOption = {
  pointStyle: 'rectRot',
  pointRadius: 0,
  fill: false,
  lineTension: 0.4,
  borderColor: colors.graph,
  borderWidth: 2,
  scales: {
    x: {
      type: 'category',
    },
  },
};

const tableHeader = [
  'solo.playerboard.team',
  'solo.playerboard.gamePlayed',
  'solo.playerboard.winrate',
  'solo.playerboard.kda',
];

interface DashBoardChart {
  trends?: ITeamTrends;
  records?: ITeamRecords;
  [key: string]: any;
}

const DashBoardChart = ({ trends, records }: DashBoardChart) => {
  const { t } = useTranslation();

  if (!trends || !records) {
    return <div>로딩중</div>;
  }

  const { matchtrends, seasontrends } = trends;

  const 시즌별성적그래프 = {
    labels: seasontrends.map((data) => data.season),
    datasets: [
      {
        ...graphOption,
        data: seasontrends.map((data) => data.sbr),
      },
    ],
  };

  const 최근50경기그래프 = {
    labels: matchtrends.map((data) => data.date),
    datasets: [
      {
        ...graphOption,
        data: matchtrends.map((data) => data.pr),
      },
    ],
  };

  const 최근5경기그래프 = {
    labels: matchtrends.slice(-5, matchtrends.length).map((data) => data.date.slice(0, 10)),
    datasets: [
      {
        ...graphOption,
        data: matchtrends.slice(-5, matchtrends.length).map((data) => data.pr),
      },
    ],
  };

  return (
    <FlexContainer>
      {/* 5경기 */}
      <ChartContainer>
        <ChartHeader>
          <h5>
            {t('solo.playerboard.performanceScore')}&nbsp;(5
            {t('solo.playerboard.games')})
          </h5>
          <div>
            <span>
              <em>X</em> {t('solo.playerboard.games')}
            </span>
            <span>
              <em>Y</em> PR
            </span>
          </div>
        </ChartHeader>
        <ChartContents>
          {최근5경기그래프 && <Line data={최근5경기그래프} options={prlineOption} />}
        </ChartContents>
      </ChartContainer>

      {/* 통산성적 */}
      <TableContainer>
        <TableHeader>
          <h5 style={{ marginRight: 16 }}>{t('solo.playerboard.carrer')}</h5>
          <div style={{ flex: 1 }}>
            <ExcelExport tableid='careerTable' />
          </div>
        </TableHeader>
        <TableConents>
          <Table id='careerTable'>
            <thead>
              <tr>
                {tableHeader.map((col) => (
                  <th key={col}>{t(col)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {records.careerrecords?.map((row, _) => (
                <tr key={row.team + _}>
                  <td>{row.team}</td>
                  <td>{row.total}</td>
                  <td>{`${row.winning.toFixed(1)}%`}</td>
                  <td>
                    {`${row.kill} / ${row.death}  / ${row.assists}`}
                    <em>{`${row.kda.toFixed(1)}:1`}</em>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableConents>
      </TableContainer>

      {/* 50경기 */}
      <ChartContainer>
        <ChartHeader>
          <h5>
            {t('solo.playerboard.performanceScore')}&nbsp;(50
            {t('solo.playerboard.games')})
          </h5>
          <div>
            <span>
              <em>X</em> {t('solo.playerboard.games')}
            </span>
            <span>
              <em>Y</em> PR
            </span>
          </div>
        </ChartHeader>
        <ChartContents>
          {최근50경기그래프 && <Line data={최근50경기그래프} options={totalTrendOption} />}
        </ChartContents>
      </ChartContainer>

      {/* 시즌별 성적 */}
      <ChartContainer>
        <ChartHeader>
          <h5>{t('solo.playerboard.seasonStat')}</h5>
          <div>
            <span>
              <em>X</em> {t('solo.playerboard.games')}
            </span>
            <span>
              <em>Y</em> PR
            </span>
          </div>
        </ChartHeader>
        <ChartContents>
          {시즌별성적그래프 && <Line data={시즌별성적그래프} options={prlineOption} />}
        </ChartContents>
      </ChartContainer>
    </FlexContainer>
  );
};

const FlexContainer = styled.div`
  ${spacing.marginX(-4)}
  display: flex;
  flex-wrap: wrap;
`;
const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 32px);
  min-height: 270px;
  background-color: ${colors.bg_select};
  ${borderRadiusStyle[20]}
  ${spacing.marginX(4)}
  ${spacing.marginB(4)}
  ${spacing.padding(4)}
`;
const ChartHeader = styled.div`
  ${typoStyle.contents_title}
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${spacing.paddingB(4)}

  > div {
    em {
      color: ${colors.info};
    }
    > span:not(:last-of-type) {
      ${spacing.marginR(4)}
    }
  }
`;
const ChartContents = styled.div`
  flex: 1;
`;

const TableContainer = styled(ChartContainer)`
  ${spacing.padding(0)}
  ${spacing.paddingB(2)}
`;
const Table = styled.table`
  ${typoStyle.contents}
  width: 100%;
  table-layout: fixed;

  /* head */
  thead {
    color: ${colors.vs};
    tr {
      background-color: ${colors.bg_checkbox};
      th {
        ${spacing.paddingY(1)}
        text-align: center;
        vertical-align: middle;
      }
      th:nth-of-type(1) {
        width: 40%;
        text-align: left;
        ${spacing.paddingL(3)}
      }
      th:nth-of-type(2) {
        width: 12%;
      }
      th:nth-of-type(3) {
        width: 12%;
      }
      th:nth-of-type(4) {
        width: 36%;
        ${spacing.paddingR(3)}
      }
    }
  }

  tbody {
    tr {
      &:not(:last-of-type) {
        border-bottom: 1px solid ${colors.bg_checkbox};
      }

      td {
        ${spacing.paddingY(1)}
        text-align: center;
        vertical-align: middle;
        word-wrap: break-word;
      }
      td:nth-of-type(1) {
        ${spacing.paddingL(3)}
        text-align: left;
      }
      td:nth-of-type(2) {
      }
      td:nth-of-type(3) {
      }
      td:nth-of-type(4) {
        ${spacing.paddingR(3)}
        em {
          ${spacing.marginL(2)}
          color: ${colors.badge_red}
        }
      }
    }
  }
`;
const TableHeader = styled(ChartHeader)`
  ${spacing.paddingY(4)}
  ${spacing.paddingX(3)}
`;
const TableConents = styled(ChartContents)`
  max-height: 200px;
  ${scrollbarStyle.hidden}
`;

export default DashBoardChart;
