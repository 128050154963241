import {
  ActionLog,
  Infos,
  Live,
  Log,
  Mapping,
  Player,
  Status,
  StatusPlayers,
  TeamGold,
  TimeLineSet,
} from '../../services/GameServices';

interface IGameAction {
  type: GameAction;
  payload?: any;
}

type GameAction =
  | typeof INITIALIZE_GAME_STATE
  | typeof SET_GAME_ID
  | typeof SET_UNIQUE_ID
  | typeof SET_OPPSIDE
  | typeof SET_BLUE_TEAM
  | typeof SET_RED_TEAM
  | typeof SET_START_TIME
  | typeof SET_GAME_TIME
  // 데이터 셋
  | typeof SET_FIXED_DATASET
  | typeof SET_PLAYERS_DATASET
  | typeof SET_LOG_DATASET
  | typeof SET_TEAM_GOLD_DATASET
  | typeof SET_MAPPING_DATASET
  | typeof SET_LIVE_DATASET
  | typeof SET_STATUS_LOG_DATASET
  | typeof SET_PLAYERS_STATUS_DATASET
  | typeof SET_TIMELINE_DATASET
  // 선택
  | typeof SET_SELECTED_PLAYER
  | typeof SET_CHAMP_TAB
  | typeof SET_SELETED_STATUS_TIME
  | typeof SET_SELETED_STATUS_TYPE
  | typeof SET_SELETED_STATUS_TEXT
  // 활성화 인덱스
  | typeof SET_EVENT_LOG_ACTIVE_IDX
  | typeof SET_STATUS_LOG_ACTIVE_IDX
  | typeof SET_CURRENT_ITEM_IDX_ACTIVE_IDX
  | typeof SET_LIVE_ACTIVE_IDX
  | typeof SET_GOLD_ACTIVE_IDX
  | typeof SET_MAPPING_ACTIVE_IDX
  // 이동 시간
  | typeof SET_SEEK_TIME;

interface IGameState {
  gameId: string | undefined;
  timer: number;
  detail: null;
  player: null;
  uniqueId: string;
  fixedDataset: Infos[];
  playerDataset: Player[];
  logDataset: Log;
  statusLogDataset: ActionLog[];
  teamGoldDataset: TeamGold[];
  mappingDataset: Mapping[];
  liveDataset: Live[];
  playersStatusDataset: Status[];
  timeLineDataset: TimeLineSet;
  blueteam: string;
  redteam: string;
  selectedTeam: number;
  selectedPosition: number;
  selectedParticipant: number;
  startTime: string;
  gameTime: string;
  champTab: number;
  eventLogActiveIdx: number;
  statusLogActiveIdx: number;
  itemActiveIdx: number;
  liveActiveIdx: number;
  goldActiveIdx: number;
  mappingActiveIdx: number;
  seekTime: number;
  selectedStatusTime: number;
  selectedStatusType: string;
  selectedStatusText: string;
}

export const INITIALIZE_GAME_STATE = 'gamevalue/INITIALIZE_GAME_STATE';
// 게임 설정
export const SET_GAME_ID = 'gamevalue/SET_GAME_ID';
export const SET_UNIQUE_ID = 'gamevalue/SET_UNIQUE_ID';
export const SET_OPPSIDE = 'gamevalue/SET_OPPSIDE';
export const SET_BLUE_TEAM = 'gamevalue/SET_BLUE_TEAM';
export const SET_RED_TEAM = 'gamevalue/SET_RED_TEAM';
export const SET_START_TIME = 'gamevalue/SET_START_TIME';
export const SET_GAME_TIME = 'gamevalue/SET_GAME_TIME';

// 데이터 셋
export const SET_FIXED_DATASET = 'gamevalue/SET_FIXED_DATASET';
export const SET_PLAYERS_DATASET = 'gamevalue/SET_PLAYERS_DATASET';
export const SET_LOG_DATASET = 'gamevalue/SET_LOG_DATASET';
export const SET_TEAM_GOLD_DATASET = 'gamevalue/SET_TEAM_GOLD_DATASET';
export const SET_MAPPING_DATASET = 'gamevalue/SET_MAPPING_DATASET';
export const SET_LIVE_DATASET = 'gamevalue/SET_LIVE_DATASET';
export const SET_STATUS_LOG_DATASET = 'gamevalue/SET_STATUS_LOG_DATASET';
export const SET_PLAYERS_STATUS_DATASET = 'gamevalue/SET_PLAYERS_STATUS_DATASET';
export const SET_TIMELINE_DATASET = 'gamevalue/SET_TIMELINE_DATASET';

// 선택
export const SET_SELECTED_PLAYER = 'gamevalue/SET_SELECTED_PLAYER';
export const SET_CHAMP_TAB = 'gamevalue/SET_CHAMP_TAB'; // 챔피언 컴포넌트 선택 탭
export const SET_SELETED_STATUS_TIME = 'gamevalue/SET_SELETED_STATUS_TIME';
export const SET_SELETED_STATUS_TYPE = 'gamevalue/SET_SELETED_STATUS_TYPE';
export const SET_SELETED_STATUS_TEXT = 'gamevalue/SET_SELETED_STATUS_TEXT';

// 활성화 인덱스
export const SET_EVENT_LOG_ACTIVE_IDX = 'gamevalue/SET_EVENT_LOG_ACTIVE_IDX';
export const SET_STATUS_LOG_ACTIVE_IDX = 'gamevalue/SET_STATUS_LOG_ACTIVE_IDX';
export const SET_CURRENT_ITEM_IDX_ACTIVE_IDX = 'gamevalue/SET_CURRENT_ITEM_IDX_ACTIVE_IDX';
export const SET_LIVE_ACTIVE_IDX = 'gamevalue/SET_LIVE_ACTIVE_IDX';
export const SET_GOLD_ACTIVE_IDX = 'gamevalue/SET_GOLD_ACTIVE_IDX';
export const SET_MAPPING_ACTIVE_IDX = 'gamevalue/SET_MAPPING_ACTIVE_IDX';

// 이동 시간
export const SET_SEEK_TIME = 'gamevalue/SET_SEEK_TIME';

export const InitializeGameState = () => {
  return {
    type: INITIALIZE_GAME_STATE,
  };
};

export const SetGameId = (payload: string) => {
  return {
    type: SET_GAME_ID,
    payload,
  };
};
export const SetUniqueId = (payload: string) => {
  return {
    type: SET_UNIQUE_ID,
    payload,
  };
};
export const SetFixedDataset = (payload: Infos[]) => {
  return {
    type: SET_FIXED_DATASET,
    payload,
  };
};
export const SetPlayersDataset = (payload: Player[]) => {
  return {
    type: SET_PLAYERS_DATASET,
    payload,
  };
};
export const SetLogDataset = (payload: Log) => {
  return {
    type: SET_LOG_DATASET,
    payload,
  };
};
export const SetTeamGoldDataset = (payload: TeamGold[]) => {
  return {
    type: SET_TEAM_GOLD_DATASET,
    payload,
  };
};
export const SetMappingDataset = (payload: Mapping[]) => {
  return {
    type: SET_MAPPING_DATASET,
    payload,
  };
};
export const SetLiveDataset = (payload: Live[]) => {
  return {
    type: SET_LIVE_DATASET,
    payload,
  };
};
export const SetBlueTeam = (payload: string) => {
  return {
    type: SET_BLUE_TEAM,
    payload,
  };
};
export const SetRedTeam = (payload: string) => {
  return {
    type: SET_RED_TEAM,
    payload,
  };
};

export const SetSelectedPlayer = (payload: StatusPlayers) => {
  return {
    type: SET_SELECTED_PLAYER,
    payload,
  };
};
export const SetStartTime = (payload: number) => {
  return {
    type: SET_START_TIME,
    payload,
  };
};
export const SetGameTime = (payload: number) => {
  return {
    type: SET_GAME_TIME,
    payload,
  };
};
export const SetChampTab = (payload: number) => {
  return {
    type: SET_CHAMP_TAB,
    payload,
  };
};
export const SetEventLogActiveIdx = (payload: number) => {
  return {
    type: SET_EVENT_LOG_ACTIVE_IDX,
    payload,
  };
};
export const SetStatusLogActiveIdx = (payload: number) => {
  return {
    type: SET_STATUS_LOG_ACTIVE_IDX,
    payload,
  };
};
export const SetMappingActiveIdx = (payload: number) => {
  return {
    type: SET_MAPPING_ACTIVE_IDX,
    payload,
  };
};
export const SetCurrentItemIdxActiveIdx = (payload: number) => {
  return {
    type: SET_CURRENT_ITEM_IDX_ACTIVE_IDX,
    payload,
  };
};
export const SetLiveActiveIdx = (payload: number) => {
  return {
    type: SET_LIVE_ACTIVE_IDX,
    payload,
  };
};
export const SetGoldActiveIdx = (payload: number) => {
  return {
    type: SET_GOLD_ACTIVE_IDX,
    payload,
  };
};
export const SetStatusLogDataset = (payload: ActionLog[]) => {
  return {
    type: SET_STATUS_LOG_DATASET,
    payload,
  };
};
export const SetSeekTime = (payload: number) => {
  return {
    type: SET_SEEK_TIME,
    payload,
  };
};
export const SetPlayersStatusDataset = (payload: Status[]) => {
  return {
    type: SET_PLAYERS_STATUS_DATASET,
    payload,
  };
};
export const SetSeletedStatusTime = (payload: number) => {
  return {
    type: SET_SELETED_STATUS_TIME,
    payload,
  };
};
export const SetSeletedStatusType = (payload: string) => {
  return {
    type: SET_SELETED_STATUS_TYPE,
    payload,
  };
};
export const SetSeletedStatusText = (payload: string) => {
  return {
    type: SET_SELETED_STATUS_TEXT,
    payload,
  };
};
export const SetTimeLineDataset = (payload: TimeLineSet) => {
  return {
    type: SET_TIMELINE_DATASET,
    payload,
  };
};

const initialState: IGameState = {
  gameId: '',
  timer: 0,
  detail: null,
  player: null,
  uniqueId: '',
  fixedDataset: [],
  playerDataset: [],
  logDataset: { events: [] },
  statusLogDataset: [],
  teamGoldDataset: [],
  mappingDataset: [],
  liveDataset: [],
  playersStatusDataset: [],
  timeLineDataset: {},
  blueteam: '',
  redteam: '',
  selectedTeam: 0,
  selectedPosition: 0,
  selectedParticipant: 0,
  startTime: '',
  gameTime: '',
  champTab: 0,
  eventLogActiveIdx: 0,
  statusLogActiveIdx: 0,
  itemActiveIdx: 0,
  liveActiveIdx: 0,
  goldActiveIdx: 0,
  mappingActiveIdx: 0,
  seekTime: 0,
  selectedStatusTime: 0,
  selectedStatusType: '',
  selectedStatusText: '',
};

export default function GameReportReducer(state = initialState, action: IGameAction): IGameState {
  switch (action.type) {
    case SET_GAME_ID:
      return {
        ...state,
        gameId: action.payload,
      };

    case SET_UNIQUE_ID:
      return {
        ...state,
        uniqueId: action.payload,
      };
    case SET_FIXED_DATASET:
      return {
        ...state,
        fixedDataset: action.payload,
      };
    case SET_PLAYERS_DATASET:
      return {
        ...state,
        playerDataset: action.payload,
      };
    case SET_LOG_DATASET:
      return {
        ...state,
        logDataset: action.payload,
      };
    case SET_TEAM_GOLD_DATASET:
      return {
        ...state,
        teamGoldDataset: action.payload,
      };
    case SET_MAPPING_DATASET:
      return {
        ...state,
        mappingDataset: action.payload,
      };
    case SET_LIVE_DATASET:
      return {
        ...state,
        liveDataset: action.payload,
      };
    case SET_BLUE_TEAM:
      return {
        ...state,
        blueteam: action.payload,
      };
    case SET_RED_TEAM:
      return {
        ...state,
        redteam: action.payload,
      };
    case SET_SELECTED_PLAYER:
      return {
        ...state,
        selectedTeam: action.payload.teamid,
        selectedPosition: action.payload.position,
        selectedParticipant: action.payload.participantid,
      };
    case SET_START_TIME:
      return {
        ...state,
        startTime: action.payload,
      };
    case SET_GAME_TIME:
      return {
        ...state,
        gameTime: action.payload,
      };
    case SET_CHAMP_TAB:
      return {
        ...state,
        champTab: action.payload,
      };
    case SET_EVENT_LOG_ACTIVE_IDX:
      return {
        ...state,
        eventLogActiveIdx: action.payload,
      };
    case SET_STATUS_LOG_ACTIVE_IDX:
      return {
        ...state,
        statusLogActiveIdx: action.payload,
      };
    case SET_MAPPING_ACTIVE_IDX:
      return {
        ...state,
        mappingActiveIdx: action.payload,
      };
    case SET_CURRENT_ITEM_IDX_ACTIVE_IDX:
      return {
        ...state,
        itemActiveIdx: action.payload,
      };
    case SET_LIVE_ACTIVE_IDX:
      return {
        ...state,
        liveActiveIdx: action.payload,
      };
    case SET_GOLD_ACTIVE_IDX:
      return {
        ...state,
        goldActiveIdx: action.payload,
      };
    case SET_STATUS_LOG_DATASET:
      return {
        ...state,
        statusLogDataset: action.payload,
      };
    case SET_SEEK_TIME:
      return {
        ...state,
        seekTime: action.payload,
      };
    case SET_PLAYERS_STATUS_DATASET:
      return {
        ...state,
        playersStatusDataset: action.payload,
      };
    case SET_SELETED_STATUS_TIME:
      return {
        ...state,
        selectedStatusTime: action.payload,
      };
    case SET_SELETED_STATUS_TYPE:
      return {
        ...state,
        selectedStatusType: action.payload,
      };
    case SET_SELETED_STATUS_TEXT:
      return {
        ...state,
        selectedStatusText: action.payload,
      };
    case SET_TIMELINE_DATASET:
      return {
        ...state,
        timeLineDataset: action.payload,
      };
    case INITIALIZE_GAME_STATE:
      return initialState;
    default:
      return state;
  }
}
