import { atom } from 'recoil';
import {
  ActionLog,
  Infos,
  Live,
  Log,
  Mapping,
  Status,
  TeamGold,
  TimeLineSet,
  Player,
} from '../../services/GameServices';

interface GameDatasetParams {
  fixedDataset: Infos[];
  playerDataset: Player[];
  logDataset: Log;
  statusLogDataset: ActionLog[];
  teamGoldDataset: TeamGold[];
  mappingDataset: Mapping[];
  liveDataset: Live[];
  playersStatusDataset: Status[];
  timeLineDataset: TimeLineSet;
}

const GameDatasetAtom = {
  fixedDataset: atom<Infos[]>({
    key: 'GameDatasetFixedDataset',
    default: [],
  }),

  playerDataset: atom<Player[]>({
    key: 'GameDatasetPlayerDataset',
    default: [],
  }),

  logDataset: atom<Log>({
    key: 'GameDatasetLogDataset',
    default: { events: [] },
  }),

  statusLogDataset: atom<ActionLog[]>({
    key: 'GameDatasetStatusLogDataset',
    default: [],
  }),

  teamGoldDataset: atom<TeamGold[]>({
    key: 'GameDatasetTeamGoldDataset',
    default: [],
  }),

  mappingDataset: atom<Mapping[]>({
    key: 'GameDatasetMappingDataset',
    default: [],
  }),

  liveDataset: atom<Live[]>({
    key: 'GameDatasetLiveDataset',
    default: [],
  }),

  playersStatusDataset: atom<Status[]>({
    key: 'GameDatasetPlayersStatusDataset',
    default: [],
  }),

  timeLineDataset: atom<TimeLineSet>({
    key: 'GameDatasetTimeLineDataset',
    default: {
      roming: [],
      ganking: [],
      timefight: [],
      blueKills: [],
      redKills: [],
      buildDestroy: [],
      objectKill: [],
      dragonKill: [],
      teamGoldX: [],
      teamGoldY: [],
      teamGoldMax: 0,
    },
  }),
};

export const GameDatasetResultAtom = atom<GameDatasetParams>({
  key: 'GameDatasetResult',
  default: undefined,
});

export default GameDatasetAtom;
