import React from 'react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { userLanguageAtom } from '../../../recoil/Auth/userAtom';

interface P {
  title: {
    data: number | 'NULL';
    name: string;
    leaguedata: number;
    eng: string;
  };
  contents: string;
}

const BoardToolTip: React.FC<P> = ({ title, contents }) => {
  const lang = useRecoilValue(userLanguageAtom);
  const { t } = useTranslation();

  return (
    <ArrowWrapper>
      <BoardToolTipWrapper>
        <Title>{lang === 'ko' ? title.name : title.eng}</Title>
        <Content>{t(contents)}</Content>
      </BoardToolTipWrapper>
    </ArrowWrapper>
  );
};

export default BoardToolTip;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const BoardToolTipWrapper = styled.div`
  max-width: 200px;
  background-color: #000000;
  border-radius: 4px;
  padding: 12px;
`;

const Title = styled.div`
  font-family: SpoqaHanSansNeo;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  color: #fff;
  margin-bottom: 5px;
`;

const Content = styled.div`
  font-family: SpoqaHanSansNeo;
  font-size: 13px;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  line-height: 1.3;
`;

const Arrow = styled.div`
  border-top: 10px solid #1d1d1d;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top-color: #1d1d1d;
  width: 15px;
`;
