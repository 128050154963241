/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { UseMutateFunction, useMutation } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import IconStar from '../../../Components/Ui/Icons/IconStar';
import { colors } from '../../../Styles/ui';
import SRContent from './Common/SRContent';

import Avatar from '../../../Components/Ui/Avatar';
import NoneDatabox from '../../../Components/Ui/NoneDatabox';
import * as table from './styled/MTStyledTable';
import * as layout from './styled/MTStyledLayout';
import { modalList } from '../../../Components/Modals/Modals';
import { useModal } from '../../../Hooks';
import { API } from '../../config';
import transPositionToNum from '../lib/transPositionToNum';
import { getTrueValueList } from '../../../lib/getTureValueList';
import { Loading } from '../../../redux/modules/filtervalue';
import axiosRequest from '../../../lib/axios/axiosRequest';
import { SetModalInfo } from '../../../redux/modules/modalvalue';
import SoloRankServices, {
  BookMarkListInfoResponse,
  FavoritePlayer,
  IResponseSuccess,
  Players,
} from '../../../services/SoloRankServices';

const S = { table, layout };
interface Position {
  [key: string]: boolean;
}

const IPInactive = ({
  selectedDay,
  setSelectedDay,
  playerInfo,
  getInfoFunc,
}: {
  selectedDay: string;
  setSelectedDay: React.Dispatch<React.SetStateAction<string>>;
  playerInfo: Players[] | [];
  getInfoFunc: UseMutateFunction<IResponseSuccess<BookMarkListInfoResponse>, unknown, void, unknown>;
}) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const dispatch = useDispatch();

  // getSummonerAdd
  const { mutate: getMyTeamSoloRankInfo } = useMutation(
    (data: FavoritePlayer) => SoloRankServices.getSummonerAdd(data),
    {
      onSuccess: (data) => {
        const e = data.response;
        console.log(e);
        getInfoFunc();
        dispatch(Loading(false));
      },
    },
  );

  const addFavoritePlayer = () => {
    openModal(modalList.addTeamPlayer, {
      onSubmit: (e) => {
        console.log('submit시 action을 등록');
        const position = getTrueValueList(e.position) || [];
        const params = {
          player: e.nickname,
          team: '',
          role: transPositionToNum(position[0]),
          summonerId: e.selectedSoloRankIds,
          puuId: e.selectedSoloRankPuuids,
        };
        getMyTeamSoloRankInfo(params);
        console.log(params);
        dispatch(Loading(true));
        getInfoFunc();
      },
    });
  };
  return (
    <SWrapper>
      <SAlter>
        <Avatar
          css={css`
            margin-right: 5px;
            margin-top: 2px;
          `}
          src='/Images/ico-notice-gy.png'
          size={14}
        />
        {t('soloRank.InterestedPlayer.desc.inactiveGuide')}
      </SAlter>
      <S.table.AddPlayerPopupButton onClick={() => addFavoritePlayer()}>
        <span>+</span>
        <div>
          <h5>{t('soloRank.InterestedPlayer.label.addPlayer')}</h5>
          <span>{t('soloRank.InterestedPlayer.desc.addPlayer')}</span>
        </div>
      </S.table.AddPlayerPopupButton>
      {playerInfo.length > 0 ? (
        <SRContent
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          playerInfo={playerInfo}
          usingBookmark={false}
          usingDeletePlayer
          usingDeletePlayerGuide={false}
          usingAddPlayer={false}
          usingDeleteSoloId
          usingAddSoloId
          getInfoFunc={getInfoFunc}
          myTeamName={undefined}
        />
      ) : (
        <NoneDatabox
          headerText={t('soloRank.InterestedPlayer.desc.noDataHeader')}
          subText={t('soloRank.InterestedPlayer.desc.noDataSub')}
        />
      )}
    </SWrapper>
  );
};

export default IPInactive;

const SWrapper = styled.div``;

const SAlter = styled.div`
  display: flex;
  margin-top: 1px;
  margin-bottom: 20px;
  //border-bottom: 1px solid ${colors.border_light};

  font-family: SpoqaHanSansNeo;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.info};
`;
