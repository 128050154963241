export default function ObjCompare(obj1, obj2) {
  const Obj1Keys = Object.keys(obj1);
  const Obj2Keys = Object.keys(obj2);
  if (Obj1Keys.length !== Obj2Keys.length) {
    return false;
  }

  Obj1Keys.map(k => {
    if (obj1[k] !== obj2[k]) {
      return false;
    }
  })
  return true;
}
