import React, { useState } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import secToMS from '../../../../../../lib/secToMS';
import { recentVersion } from '../../../../../config';
import { ActionLog } from '../../../../../../services/GameServices';

import GameDatasetAtom from '../../../../../../recoil/ProGameReport/gameDatasetAtom';
import useChampionImage from '../../../../../../Hooks/useChampionImage';

const TeamFightStatusBox = ({ TFdata, isActive }: { TFdata: ActionLog; isActive: boolean }) => {
  const fixedDataset = useRecoilValue(GameDatasetAtom.fixedDataset);
  const { getChampionImagePathByName } = useChampionImage();
  const { t } = useTranslation();
  const time = secToMS(Math.floor(TFdata.realCount / 2));
  const { data } = TFdata;

  const blueTeam = data.partis.filter((e) => e <= 5).map((e) => fixedDataset[0].players[+e - 1].info);
  const redTeam = data.partis.filter((e) => e > 5).map((e) => fixedDataset[0].players[+e - 6].info);
  const title = t(`game.log.status.teamfight.title`);

  return (
    <LogContent isActive={isActive}>
      <div className='title'>
        <div className='dot' />
        <span>{`${time} ${title}`}</span>
      </div>
      <div className='body'>
        {blueTeam.map((e) => {
          return <img src={getChampionImagePathByName(e.championEng)} alt={e.championEng} />;
        })}

        {/* <span className="player-name">{player}</span> */}
        <img src='/Images/ic_engagement.svg' alt='champion' />
        {redTeam.map((e) => {
          return <img src={getChampionImagePathByName(e.championEng)} alt={e.championEng} />;
        })}
        {/* <span className="player-name">{`${t(
          "game.log.status.roming.desc"
        )}`}</span> */}
      </div>
    </LogContent>
  );
};

export default TeamFightStatusBox;

const LogContent = styled.div<{ isActive: boolean }>`
  width: 180px;
  height: auto;
  margin: 5px 0;
  padding: 8px 8px 8px 8px;
  border-radius: 10px;
  background-color: #000;
  opacity: ${(props) => (props.isActive ? `1` : `0.3`)};
  border: solid 2px ${(props) => props.isActive && '#5942ba'};
  cursor: pointer;

  .title {
    display: flex;
    height: 19px;
    margin: 0 5px 0px;
    //background-color: #f00;
    span {
      font-family: SpoqaHanSansNeo;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.15;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
  }

  .body {
    text-align: left;
    padding-bottom: 0px;
    //border-bottom: solid 1px #23212a;
    img {
      width: 15px;
      height: 15px;
      //margin: 1px 2px 1px 0;
      object-fit: contain;
      border-radius: 20px;
      vertical-align: middle;
    }

    .player-name {
      font-family: SpoqaHanSansNeo;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
  }
  .footer {
    display: flex;
    margin-top: 3px;
    img {
      width: 15px;
      height: 15px;
      //margin: 1px 2px 1px 0;
      object-fit: contain;
      border-radius: 20px;
    }

    .player-name {
      font-family: SpoqaHanSansNeo;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
  }

  .dot {
    width: 6px;
    height: 6px;
    margin: 4px 5px 10px 0px;
    background-color: #5942ba;
    border-radius: 10px;
  }
`;
