import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MAP_FILTER_ERROR, MAP_FILTER_MEMU } from '../../../lib/mapping/map_filter_menu';
import { spacing } from '../../../Styles/ui';

export type SideFilterConfirmErrorType = '연도' | '리그' | '시즌' | '패치' | '팀' | '선수';

interface P {
  handleSubmit: () => void;
  errorCode?: SideFilterConfirmErrorType;
}

const SideFilterConfirmButton: React.FC<P> = ({ handleSubmit, errorCode }) => {
  const { t } = useTranslation(['filter', 'translation']);

  return (
    <Container>
      <Typography textAlign='center' color='red' fontSize={14} pb={1.5}>
        {errorCode && t(MAP_FILTER_ERROR[errorCode], { ns: 'filter' })}
      </Typography>
      <Button
        sx={{
          width: '100%',
          borderRadius: '10px',
          py: 1,
          fontSize: 15,
          height: 40,
        }}
        onClick={handleSubmit}
        variant='contained'
      >
        {t(MAP_FILTER_MEMU.요청, { ns: 'translation' })}
      </Button>
    </Container>
  );
};

export default SideFilterConfirmButton;

const Container = styled.div`
  ${spacing.paddingX(5)}
  ${spacing.paddingY(6)}
`;
