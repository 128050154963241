/** @jsxImportSource @emotion/react */

// import { jsx, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import timeFormat from '../../../lib/timeFormat';
import PathSlider from '../../../Components/Ui/MUI/PathSlider';
import { spacing } from '../../../Styles/ui';

interface P {
  minFrom: number[];
  setMinFrom: React.Dispatch<React.SetStateAction<number[]>>;
}

const WardTeamFilter: React.FC<P> = ({ minFrom, setMinFrom }) => {
  const { t } = useTranslation();
  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue !== 'number') setMinFrom(newValue);
  };

  return (
    <WardTeamFilterContainer>
      <NoneVisible>
        <div className='title'>
          <span className='step'>STEP 01</span>
          <span className='subtitle'>{t('video.vision.label3')}</span>
        </div>
      </NoneVisible>
      <Steps>
        <div className='title2'>
          {/* <span className="step">STEP 02 </span> */}
          <span className='subtitle'>{t('video.vision.label2')}</span>
        </div>
        <SliderContainer className='slider-container'>
          <PathSlider
            min={0}
            max={100}
            value={minFrom}
            onChange={handleChange}
            valueLabelDisplay='on'
            aria-labelledby='range-slider'
            valueLabelFormat={timeFormat.ward}
          />
        </SliderContainer>

        <DefaultTime>
          <DisplayTime>00:00</DisplayTime>
          <DisplayTime>08:30</DisplayTime>
        </DefaultTime>
      </Steps>
    </WardTeamFilterContainer>
  );
};

export default WardTeamFilter;

const SliderContainer = styled.div``;

const WardTeamFilterContainer = styled.div``;

const NoneVisible = styled.div`
  display: none;
`;

const Steps = styled.div`
  min-height: 111px;
  padding: 0px 23px 30px;
  :nth-child(2) {
    border-bottom: none;
  }
  > .title {
    display: flex;
    font-family: SpoqaHanSansNeo;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;

    margin-bottom: 12px;
    > .step {
      font-weight: normal;
      color: #84818e;
      margin-right: 5px;
    }
    > .subtitle {
      color: rgb(255, 255, 255);
    }
  }
  > .title2 {
    display: flex;
    font-family: SpoqaHanSansNeo;
    font-family: SpoqaHanSansNeo;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 47px;
    > .step {
      font-weight: normal;
      color: #84818e;
      margin-right: 5px;
    }
    > .subtitle {
      color: rgb(255, 255, 255);
    }
  }
`;

const DefaultTime = styled.div`
  ${spacing.marginT(-3)}
  ${spacing.marginX(-1)}
  display: flex;
  justify-content: space-between;
`;

const DisplayTime = styled.div`
  font-family: SpoqaHanSansNeo;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.87;
  letter-spacing: normal;
  text-align: center;
  color: #6b6979;
`;
