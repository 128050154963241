
export const getWinRate = (total: number, win: number, fixed = 1): string => {
  if (typeof total !== 'number' && typeof win !== 'number') {
    console.error(`
    Custom Error Info
    function: "getWinRate"
    param: {total: ${total}, win: ${win}, fixed: ${fixed}}
    `);
  }
  if (total === 0) {
    return '0.0%';
  }
  return `${((win / total) * 100).toFixed(fixed).toString()}%`;
};
