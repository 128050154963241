import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled/macro';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import SelectFilter from '../../Components/SelectFilter/SelectFilter';
import VideoTabs from './VideoTabs';
import NewFiltersPathAnalysis from './PathAnalysisFilter';
import { PathAnalysisResultAtom } from '../../recoil/Filter/pathAnalysisAtom';
import { scrollbarStyle, spacing } from '../../Styles/ui';

export const ScrollContext = React.createContext({
  onScrollTop: () => {},
});

function Video() {
  const result = useRecoilValue(PathAnalysisResultAtom);
  const resetResult = useResetRecoilState(PathAnalysisResultAtom);
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null); // [MutableRefObject와 LegacyRef](https://zerodice0.tistory.com/244)

  const onScrollTop = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [containerRef]);

  const value = useMemo(
    () => ({
      onScrollTop,
    }),
    [onScrollTop],
  );

  if (document.title !== `${t('sidebar.part6')} - NUNU.GG`) {
    document.title = `${t('sidebar.part6')} - NUNU.GG`;
  }

  useEffect(() => {
    return () => {
      resetResult();
    };
  }, []);

  return (
    <WRAPPER>
      <NewFiltersPathAnalysis />
      <CONTENT ref={containerRef}>
        <ScrollContext.Provider value={value}>
          <DETAILS>
            {result ? (
              <VideoTabs />
            ) : (
              <SELECTFILTER_WRAPPER>
                <SelectFilter />
              </SELECTFILTER_WRAPPER>
            )}
          </DETAILS>
        </ScrollContext.Provider>
      </CONTENT>
    </WRAPPER>
  );
}

export default Video;

const WRAPPER = styled.div`
  display: flex;
  background-color: #16151c;
`;

const DETAILS = styled.div`
  min-width: 1096px;
`;

const CONTENT = styled.div`
  ${spacing.padding(8)}
  flex: 1;
  height: 100vh;
  ${scrollbarStyle.scroll_4}
`;

const SELECTFILTER_WRAPPER = styled.div``;
