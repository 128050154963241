export const TRANSLATE_SCRIM_TITLE = {
  champion: 'scrim.banPick.champion',
  pick: 'scrim.banPick.picks',
  ban: 'scrim.banPick.ban',
  pickBanRate: 'scrim.banPick.banRate',
  win: 'scrim.banPick.w',
  lose: 'scrim.banPick.l',
  winRate: 'scrim.banPick.winRate',
  score: 'scrim.banPick.score',
  tierRank: 'scrim.banPick.tier',
  gameInfo: 'scrim.output.gameInfo',
  FirstPhaseBan: 'scrim.output.1phaseBan',
  FirstPhasePick: 'scrim.output.1phasePick',
  SecondPhaseBan: 'scrim.output.2phaseBan',
  SecondPhasePick: 'scrim.output.2phasePick',
  noResult: 'scrim.output.noResult',
  GenNewGame: 'scrim.output.GenNewGame',
  scrimRecord: 'scrim.team.scrimRecord',
  redSide: 'scrim.team.red',
  blueSide: 'scrim.team.blue',
  oppRecord: 'scrim.team.oppRecord',
  league: 'scrim.team.league',
  teamName: 'scrim.team.teamName',
  games: 'scrim.team.games',
  game: 'scrim.team.game',
  champListFetching: 'scrim.input.fetchingChampList',
  FilterFetching: 'scrim.input.fetchingFilterData',
  setDate: 'scrim.filter.setDate',
  setOppteam: 'scrim.filter.setOppteam',
  genScrimGame: 'scrim.filter.genScrimGame',
  team: 'scrim.filter.team',
  side: 'scrim.filter.side',
  blue: 'scrim.filter.blue',
  red: 'scrim.filter.red',
  saveGameResult: 'scrim.input.saveGameResult',
  champNotSelected: 'scrim.input.champNotSelected',
  positionNotSelected: 'scrim.input.positionNotSelected',
  duplicateBan: 'scrim.input.duplicateBan',
  duplicatePick: 'scrim.input.duplicatePick',
  duplicateBanPickChamp: 'scrim.input.duplicateBanPickChamp',
  duplicateBluePos: 'scrim.input.duplicateBluePos',
  duplicateRedPos: 'scrim.input.duplicateRedPos',
  gameResultNotSelected: 'scrim.input.gameResultNotSelected',
  winner: 'scrim.input.winner',
  teamInfo: 'scrim.input.teamInfo',
  position: 'scrim.input.position',
  saveBtn: 'scrim.input.saveBtn',
  genNewGame: 'scrim.input.genNewGame',
  selectChamp: 'scrim.modal.selectChamp',
  noChamp: 'scrim.modal.noChamp',
  searchChamp: 'scrim.modal.searchChamp',
  cancel: 'scrim.modal.cancel',
  confirm: 'scrim.modal.confirm',
  noRestoration: 'scrim.errorMsg.noRestoration',
  noFilteredData: 'scrim.errorMsg.noFilteredData',
  noData: 'scrim.errorMsg.noData',
  errorGenerated: 'scrim.errorMsg.errorGenerated',
  경기수: 'scrim.team.경기수',
  우리팀경기수: 'scrim.team.우리팀경기수',
  우리팀승: 'scrim.team.우리팀승',
  우리팀패: 'scrim.team.우리팀패',
  우리팀승률: 'scrim.team.우리팀승률',
  inputChamp: 'scrim.input.inputChamp',
  win2: 'scrim.input.win',
  lose2: 'scrim.input.lose',
  수정완료: 'scrim.input.수정완료',
  취소: 'scrim.input.취소',
};
