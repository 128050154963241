import React, { useEffect } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { Link, Outlet, useLocation } from 'react-router-dom';
import TeamFilter from './TeamFilter';
import { colors, spacing, transitionStyle, scrollbarStyle } from '../../Styles/ui';
import { TeamReportResultAtom } from '../../recoil/Filter/teamReportAtom';
import SelectFilter from '../../Components/SelectFilter/SelectFilter';
import { TRANSLATE_TEAMREPORT } from '../../lib/translation/translate_teamReport';

const Tabs = [
  // {path: 'schedule', title: TRANSLATE_TEAMREPORT.calendar},
  { path: '', title: TRANSLATE_TEAMREPORT.draft },
  { path: 'gameBanPick', title: TRANSLATE_TEAMREPORT.gameBanPick },
  { path: 'analysis', title: TRANSLATE_TEAMREPORT.analysis },
  // { path: 'teamComparison', title: TRANSLATE_TEAMREPORT.teamComparison },
];

function Team() {
  const { t } = useTranslation();
  const [result, setResult] = useRecoilState(TeamReportResultAtom);
  const resetResult = useResetRecoilState(TeamReportResultAtom);
  const { pathname } = useLocation();

  if (document.title !== `${t('sidebar.part3')} - NUNU.GG`) {
    document.title = `${t('sidebar.part3')} - NUNU.GG`;
  }

  useEffect(() => {
    return () => {
      resetResult();
    };
  }, []);

  return (
    <>
      <WRAPPER>
        <TeamFilter />
        <CONTENT>
          {result ? (
            <DETAILS>
              <TabHeader>
                {Tabs.map((tab, i) => {
                  const active =
                    i === 0 ? pathname.split('/')[2] === undefined : pathname.split('/')[2] === tab.path;
                  return (
                    <Tab key={tab.title} active={active}>
                      <SLink to={tab.path}>{t(tab.title)}</SLink>
                    </Tab>
                  );
                })}
              </TabHeader>
              <Outlet />
            </DETAILS>
          ) : (
            <SELECTFILTER_WRAPPER>
              <SelectFilter />
            </SELECTFILTER_WRAPPER>
          )}
        </CONTENT>
      </WRAPPER>
    </>
  );
}

export default Team;

const WRAPPER = styled.div`
  display: flex;
  background-color: #16151c;
`;

const CONTENT = styled.div`
  width: 100%;
  height: 100vh;
  ${spacing.padding(8)}
  ${scrollbarStyle.scroll_4}
`;

const DETAILS = styled.div`
  min-width: 1060px;
`;
const TabHeader = styled.ul`
  display: flex;
  ${spacing.marginB(5)};
`;

const Tab = styled.li<{ active: boolean }>`
  ${spacing.paddingX(4)}
  ${spacing.paddingY(3)}
  ${transitionStyle.opacity}
  color: ${colors.text};
  opacity: ${({ active }) => (active ? 1 : 0.3)};
  border-color: ${({ active }) => (active ? colors.text : 'transparent')};

  &:hover {
    opacity: 1;
  }
`;

const SCROLLCONTAINER = styled.div`
  ${scrollbarStyle.scroll_4}
  overflow-x: hidden;
`;

const SELECTFILTER_WRAPPER = styled.div``;

const SLink = styled(Link)`
  font-size: 18px;
`;
