export default function transPositionToNum(position: string) {
  switch (position) {
    case 'top':
      return 1;
    case 'jng':
      return 2;
    case 'mid':
      return 3;
    case 'bot':
      return 4;
    case 'sup':
      return 5;
    default:
      return 0;
  }
}
