// 마지막 글자에 받침이 있는 경우 true, 없는 경우 false를 리턴하는 함수

export function checkFinalConsonants(word) {
  if (typeof word !== 'string') return null;

  const lastLetter = word[word.length - 1];
  const uni = lastLetter.charCodeAt(0);

  // 한글의 제일 처음과 끝의 범위 밖일 경우
  if (uni < 44032 || uni > 55203) return null;

  // 종성 인덱스가 0 이상인 경우는 받침 o / 0인 경우 받침 x
  return (uni - 44032) % 28 > 0;
}
