import { Avatar, AvatarGroup, Box } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import useChampionImage from '../../../../Hooks/useChampionImage';

interface ScrimPlayerdChampionProps {
  params: GridRenderCellParams<any, any, any>;
}

const ScrimPlayerdChampion: React.FC<ScrimPlayerdChampionProps> = ({ params }) => {
  const { getChampionImagePathByIndex } = useChampionImage();
  return (
    <AvatarGroup
      max={13}
      sx={{
        pl: 3,
        '& .MuiAvatar-root': {
          border: 'none',
        },
      }}
    >
      {params.row?.champions?.map((champ: any) => (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar
            alt='champImg'
            src={getChampionImagePathByIndex(champ)}
            sx={{
              width: 40,
              height: 40,
              img: {
                transform: 'scale(1.1)',
              },
            }}
          />
          <span style={{ fontSize: 12 }}>
            {params.row?.championDetails
              ?.filter((el: any) => el.champ === champ)
              .map((el: any) => el.opp.length)}
          </span>
        </Box>
      ))}
    </AvatarGroup>
  );
};

export default ScrimPlayerdChampion;
