import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from '@emotion/styled';
import DatePicker from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import { useTranslation } from 'react-i18next';
import en from 'date-fns/locale/en-GB';
import { colors } from '../../../Styles/ui';
import InputAdornments from '../Input/CalendarInput';
import { userLanguageAtom } from '../../../recoil/Auth/userAtom';
import DateRangeService from '../../../lib/Calendar/dateRangelib';
import './calender.css';

const MONTHS = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

interface CalendarProps {
  startDate: Date;
  endDate: Date;
  onChange: (data: Date, type: string) => void;
}

const dateRangeService = new DateRangeService();

const CalendarRange: React.FC<CalendarProps> = ({ startDate, endDate, onChange }) => {
  const lang = useRecoilValue(userLanguageAtom);
  const { t } = useTranslation();
  const handleChange = (date: Date, type: string) => {
    onChange(date, type);
  };

  return (
    <Container>
      {/* 날짜필터 */}
      <DatePickerWrapper>
        {/* 시작 날짜 설정 */}
        <DatePicker
          showPopperArrow={false}
          selected={startDate}
          onChange={(date: Date) => {
            handleChange(date, 'start');
          }}
          locale={lang === 'ko' ? ko : en}
          dateFormat='yyyy-MM-dd'
          selectsStart
          className={startDate ? 'input-border' : ''}
          startDate={startDate}
          maxDate={endDate}
          endDate={endDate}
          useWeekdaysShort
          customInput={
            <InputAdornments startDate={startDate} placeHolder={t('utility.calendarFilter.inputStart')} />
          }
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className='date-customheader'>
              <button type='button' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <img src='/Images/ic-calendar-pre.svg' alt='prevBtn' />
              </button>

              {/* 연, 월만 표시 */}
              <div className='custom-month'>{`${date.getFullYear()}${t('common.date.yearWithoutLetter')} 
              ${lang === 'ko' ? MONTHS.indexOf(MONTHS[date.getMonth()]) + 1 : MONTHS[date.getMonth()]}${t(
                'common.date.monthWithoutLetter',
              )}`}</div>

              <button type='button' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <img src='/Images/ic-calendar-next.svg' alt='nextBtn' />
              </button>
            </div>
          )}
        />

        <div className='hyphen'> - </div>

        {/* 종료 날짜 설정 */}
        <DatePicker
          showPopperArrow={false}
          selected={endDate}
          onChange={(date: Date) => {
            handleChange(date, 'end');
          }}
          dateFormat='yyyy-MM-dd'
          selectsEnd
          locale={lang === 'ko' ? ko : en}
          className={endDate ? 'input-border' : ''}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          maxDate={dateRangeService.NOW}
          useWeekdaysShort
          customInput={
            <InputAdornments endDate={endDate} placeHolder={t('utility.calendarFilter.inputEnd')} />
          }
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className='date-customheader'>
              <button type='button' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <img src='/Images/ic-pre.png' alt='prevBtn' />
              </button>

              {/* 연, 월만 표시 */}
              <div className='custom-month'>{`${date.getFullYear()}${t('common.date.yearWithoutLetter')} 
              ${lang === 'ko' ? MONTHS.indexOf(MONTHS[date.getMonth()]) + 1 : MONTHS[date.getMonth()]}${t(
                'common.date.monthWithoutLetter',
              )}`}</div>
              <button type='button' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <img src='/Images/ic-next.png' alt='nextBtn' />
              </button>
            </div>
          )}
        />
      </DatePickerWrapper>
    </Container>
  );
};

const Container = styled.div`
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;

  .date-customheader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
  }

  .custom-month {
    font-size: 15px;
  }

  .hyphen {
    margin: 0 10px;
  }
`;

const SelectBtnWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SelectDateBtn = styled.button<{ isActive: boolean; isOppTeamFilterNeeded: boolean }>`
  width: ${({ isOppTeamFilterNeeded }) => (isOppTeamFilterNeeded ? '76px' : '87px')};
  height: 40px;
  margin-left: 5px;
  padding: 9px 12px 7px;
  border-radius: 10px;
  background-color: #2f2d38;
  background-color: ${({ isActive }) => (isActive ? colors.point : colors.bg_box)};
  color: #fff;
  font-size: 12px;

  :nth-child(n + 5) {
    width: 87px;
  }
`;

export default CalendarRange;
