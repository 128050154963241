/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import addZero from '../../../lib/addZero';
import getMonthDays from '../../../lib/Calendar/getMonthDays';
import getMonthDayList from '../../../lib/Calendar/getMonthDayList';
import theme from '../../../Styles/Theme';
import getLeafYaer from '../../../lib/Calendar/getLeafYear';
import { SetDesc, SetIsOpen } from '../../../redux/modules/modalvalue';
import CalendarServices from '../../../services/CalendarService';
import { LeagueReportParams } from '../../../recoil/Filter/leagueReportAtom';
import { ICalendar } from './calendar.type';

const date = new Date();

interface P {
  darkMode?: boolean;
  result: LeagueReportParams;
}

interface IgetCalcuDateProps {
  now: string;
  day?: number;
  mon?: number;
}

const CalendarFilterNav: React.FC<P> = ({ darkMode = false, result }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const now = `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}`;
  const { year } = result;
  const leapYear = getLeafYaer(year);

  const { data: calendar } = useQuery<ICalendar>('calendar');

  const getCalcuDate: (props: IgetCalcuDateProps) => string = ({ now, day, mon }) => {
    const time = new Date(now);
    const oneDay = 24 * 3600 * 1000;
    if (day) {
      const calcuDate = new Date(time.getTime() - oneDay * day);

      return `${calcuDate.getFullYear()}-${addZero(calcuDate.getMonth() + 1)}-${addZero(
        calcuDate.getDate(),
      )}`;
    }
    if (mon) {
      const calcuDate = new Date(time.getFullYear(), time.getMonth() - mon, time.getDate());
      return `${calcuDate.getFullYear()}-${addZero(calcuDate.getMonth() + 1)}-${addZero(
        calcuDate.getDate(),
      )}`;
    }
    return now;
  };

  const { mutate: scheduleMutate } = useMutation(() => CalendarServices.getCalendarFilteData(result), {
    onSuccess: (data) => {
      const res = data.response;
      console.log('res', res);
      const start = res[0].date;
      const end = res[res.length - 1].date;
      const leapYear = getLeafYaer(result.year[0]);
      const startIdx =
        getMonthDays(+start.split('-')[1] - 1, getMonthDayList(leapYear)) + +start.split('-')[2] - 1;
      const endIdx = getMonthDays(+end.split('-')[1] - 1, getMonthDayList(leapYear)) + +end.split('-')[2] - 1;

      for (let i = 0; i < res.length; i += 1) {
        const { date } = res[i];
        const index =
          getMonthDays(+date.split('-')[1] - 1, getMonthDayList(leapYear)) + +date.split('-')[2] - 1;
        res[i].index = index;
      }

      const calendar = {
        info: res,
        startDate: start,
        endDate: end,
        startDayIdx: startIdx,
        endDayIdx: endIdx,
        seasonStartDate: start,
        seasonEndDate: end,
      };

      queryClient.setQueryData(['calendar'], calendar);
    },
  });

  const setRecentDayFilter = (day?: number, mon?: number) => {
    if (!calendar) return;
    const calcuDate = getCalcuDate({ now, day, mon });
    const seasonStartDateNum = new Date(calendar.seasonStartDate).getTime();
    const calcuDateNum = new Date(calcuDate!).getTime();
    const changeToStartSeason = calcuDateNum < seasonStartDateNum;
    const startDate = changeToStartSeason ? calendar.seasonStartDate : calcuDate;
    const startIdx =
      getMonthDays(+startDate.split('-')[1] - 1, getMonthDayList(leapYear)) + +startDate.split('-')[2] - 1;
    const endIdx = getMonthDays(+now.split('-')[1] - 1, getMonthDayList(leapYear)) + +now.split('-')[2] - 1;

    queryClient.setQueryData(['calendar'], {
      ...calendar,
      startDate,
      endDate: now,
      startDayIdx: startIdx,
      endDayIdx: endIdx,
      isOpen: false,
    });
    batch(() => {
      if (changeToStartSeason) {
        dispatch(SetDesc(t('utility.calendarFilter.desc.changeToStartSeason')));
        dispatch(SetIsOpen(true));
      }
    });
  };

  useEffect(() => {
    scheduleMutate();
  }, [result]);

  if (!calendar) return <></>;

  return (
    <SCFContainer>
      {/* 달력 필터 */}
      <SCFDaysInput
        darkMode={darkMode}
        onClick={() => {
          batch(() => {
            queryClient.setQueryData(['calendar'], {
              ...calendar,
              isOpen: true,
            });
          });
        }}
      >
        <input
          className='calendar-input'
          type='text'
          placeholder={t('utility.calendarFilter.inputStart')}
          value={calendar.startDate}
          readOnly
        />
        <img className='calendar-icon' src='/Images/ic-calendar.svg' alt='' />
      </SCFDaysInput>
      <div className='hyphen'>-</div>
      <SCFDaysInput
        darkMode={darkMode}
        onClick={() => {
          batch(() => {
            queryClient.setQueryData(['calendar'], {
              ...calendar,
              isOpen: true,
            });
          });
        }}
      >
        <input
          className='calendar-input'
          type='text'
          placeholder={t('utility.calendarFilter.inputEnd')}
          value={calendar.endDate}
          readOnly
        />
        <img className='calendar-icon' src='/Images/ic-calendar.svg' alt='' />
      </SCFDaysInput>

      {/* 시즌 전체 */}
      <SCFButton
        darkMode={darkMode}
        isActive={
          calendar.seasonStartDate === calendar.startDate && calendar.seasonEndDate === calendar.endDate
        }
        isRecentYear
        onClick={() => {
          queryClient.setQueryData(['calendar'], {
            ...calendar,
            startDate: calendar.seasonStartDate,
            endDate: calendar.seasonEndDate,
            startDayIdx: calendar.info[0].index,
            endDayIdx: calendar.info[calendar.info.length - 1].index,
          });
        }}
      >
        {t('utility.calendarFilter.date.allSeason')}
      </SCFButton>

      {/* 최근 3일 */}
      <SCFButton
        darkMode={darkMode}
        isActive={calendar.startDate === getCalcuDate({ now, day: 3 }) && now === calendar.endDate}
        isRecentYear={+year === date.getFullYear()}
        onClick={() => {
          if (+year === date.getFullYear()) {
            setRecentDayFilter(3, undefined);
          }
        }}
      >
        {t('utility.calendarFilter.date.recent3days')}
      </SCFButton>

      {/* 최근 5일 */}
      <SCFButton
        darkMode={darkMode}
        isActive={calendar.startDate === getCalcuDate({ now, day: 5 }) && now === calendar.endDate}
        isRecentYear={+year === date.getFullYear()}
        onClick={() => {
          if (+year === date.getFullYear()) {
            setRecentDayFilter(5, undefined);
          }
        }}
      >
        {t('utility.calendarFilter.date.recent5days')}
      </SCFButton>

      {/* 최근 7일 */}
      <SCFButton
        darkMode={darkMode}
        isActive={calendar.startDate === getCalcuDate({ now, day: 7 }) && now === calendar.endDate}
        isRecentYear={+year === date.getFullYear()}
        onClick={() => {
          if (+year === date.getFullYear()) {
            setRecentDayFilter(7, undefined);
          }
        }}
      >
        {t('utility.calendarFilter.date.recent7days')}
      </SCFButton>

      {/* 최근 15일 */}
      <SCFButton
        darkMode={darkMode}
        isActive={calendar.startDate === getCalcuDate({ now, day: 15 }) && now === calendar.endDate}
        isRecentYear={+year === date.getFullYear()}
        onClick={() => {
          if (+year === date.getFullYear()) {
            setRecentDayFilter(15, undefined);
          }
        }}
      >
        {t('utility.calendarFilter.date.recent15days')}
      </SCFButton>

      {/* 최근 1개월 */}
      <SCFButton
        darkMode={darkMode}
        isActive={calendar.startDate === getCalcuDate({ now, mon: 1 }) && now === calendar.endDate}
        isRecentYear={+year === date.getFullYear()}
        onClick={() => {
          if (+year === date.getFullYear()) {
            setRecentDayFilter(undefined, 1);
          }
        }}
      >
        {t('utility.calendarFilter.date.recent1months')}
      </SCFButton>

      {/* 최근 3개월 */}
      <SCFButton
        darkMode={darkMode}
        isActive={calendar.startDate === getCalcuDate({ now, mon: 3 }) && now === calendar.endDate}
        isRecentYear={+year === date.getFullYear()}
        onClick={() => {
          if (+year === date.getFullYear()) {
            setRecentDayFilter(undefined, 3);
          }
        }}
      >
        {t('utility.calendarFilter.date.recent3months')}
      </SCFButton>
    </SCFContainer>
  );
};

export default CalendarFilterNav;

const SCFContainer = styled.div`
  height: 34px;
  margin: 0 0 20px 0;
  display: flex;

  .calendar-filter-label {
    height: 19px;
    margin: 8px 20px 8px 0;
    font-family: SpoqaHanSansNeo;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${theme.colors.text};
  }

  .hyphen {
    width: 16px;
    height: 34px;
    padding: 12px 0px 8px 0px;

    font-family: SpoqaHanSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${theme.colors.text};
  }

  .calendar-input {
    width: 78px;
    cursor: pointer;
    color: ${theme.colors.text};
  }

  .calendar-icon {
    width: 34px;
    height: 34px;
    margin-top: -3px;
  }
`;

const SCFDaysInput = styled.div<{ darkMode: boolean }>`
  display: flex;
  width: 122px;
  height: 34px;
  padding: 3px 0 0 10px;
  border-radius: 10px;
  margin: 0 2px;
  background-color: ${({ darkMode }) => (darkMode ? theme.colors.bg_select : theme.colors.bg_box)};
  font-family: SpoqaHanSansNeo;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: ${theme.colors.text};
  cursor: pointer;
`;

const SCFButton = styled.button<{ isActive: boolean; darkMode: boolean; isRecentYear: boolean }>`
  cursor: default;
  height: 34px;
  margin-left: 5px;
  padding: 9px 12px 7px;
  border-radius: 10px;
  background-color: ${({ isActive, darkMode }) =>
    isActive ? theme.colors.point : darkMode ? theme.colors.bg_select : theme.colors.bg_box};
  opacity: ${(props) => (props.isRecentYear ? '1' : '0.3')};

  font-family: SpoqaHanSansNeo;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${theme.colors.text};
`;
