/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, batch } from 'react-redux';
import DropdownContainer from '../../../../Components/Ui/DropDown/DropdownContainer';
import DropdownItem from '../../../../Components/Ui/DropDown/DropdownItem';
import DropdownLabel from '../../../../Components/Ui/DropDown/DropdownLabel';
import DropdownList from '../../../../Components/Ui/DropDown/DropdownList';
import Sortingimage from '../../../../Components/Ui/Sortingimage';
import { dropdownStyle } from '../../../../Styles/ui';
import ItemBox from './SRItemBox';
import * as S from '../styled/MTStyledTable';
import { API } from '../../../config';

import { Loading } from '../../../../redux/modules/filtervalue';
import { SetModalInfo } from '../../../../redux/modules/modalvalue';
import ObjCompare from '../../../../lib/compareObj';
import { Players } from '../../../../services/SoloRankServices';
import { useRecoilValue } from 'recoil';
import { userLanguageAtom } from '../../../../recoil/Auth/userAtom';

const inquireDayList = ['1', '3', '5', '7', '15', '30'];
// 챔피언 티어 데이터 sorting Hooks
const useSortableData2 = (tiers: Players[], config = null) => {
  const [sortConfig, setSortConfig] = React.useState<{ key: string; direction: string }>(config);

  const sortedItems = React.useMemo(() => {
    const sortableItems = [...tiers];
    if (sortConfig !== null) {
      console.log('sortableItems', sortableItems);
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    console.log('sortableItems', sortableItems);
    return sortableItems;
  }, [tiers, sortConfig]);

  const requestSorts = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { playerInfos: sortedItems, requestSorts, sortConfig };
};

const SRCategory = ({
  selectedDay,
  setSelectedDay,
  playerInfoSet,
  setPlayerInfoSet,
}: {
  selectedDay: string;
  setSelectedDay: React.Dispatch<React.SetStateAction<string>>;
  playerInfoSet: Players[];
  setPlayerInfoSet: React.Dispatch<React.SetStateAction<Players[]>>;
}) => {
  // 챔피언 티어 오름차 내림차  정렬 상태값
  const { playerInfos, requestSorts } = useSortableData2(playerInfoSet || []);
  const lang = useRecoilValue(userLanguageAtom);

  console.log('playerInfos', playerInfos);

  useEffect(() => {
    if (ObjCompare(playerInfos, playerInfoSet) === false) {
      setPlayerInfoSet(playerInfos);
    }
  }, [playerInfos]);

  const { t } = useTranslation();

  return (
    // 테이블 헤더 카테고리
    <S.TableHead>
      <S.TableHeaderRow>
        {/* col1. 관심선수 */}
        <div className='table-col1'>
          <span>{t('soloRank.myTeam.label.addInterestedPlayer')}</span>
        </div>
        {/* col2. 솔로랭크 id */}
        <div className='table-col2'>
          <span>{t('soloRank.myTeam.label.soloRankID')}</span>
        </div>
        {/* col3. 현재 티어 LP */}
        <div className='table-col3'>
          <span>
            {t('soloRank.myTeam.label.curTier8prevTier')
              .split('\n')
              .map((line) => {
                return (
                  <>
                    {line}
                    <br />
                  </>
                );
              })}
            <Sortingimage
              onClick={() => {
                console.log('cdCalRankPoint');
                requestSorts('cdCalRankPoint');
              }}
              key='cdCalRankPoint'
            />
          </span>
        </div>
        {/* col4. 이번시즌 */}
        <div className='table-col4'>
          <span>
            {t('soloRank.myTeam.label.curSeason')}
            {/* <Sortingimage
              onClick={() => {
                requestSorts("cdSeasonTotal");
              }}
            >
              <img src="/Images/ico-sorting-up.png" alt="up" />
              <img src="/Images/ico-sorting-down.png" alt="down" />
            </Sortingimage> */}
            <Sortingimage
              onClick={() => {
                requestSorts('cdSeasonTotal');
              }}
              key='cdSeasonTotal'
            />
          </span>
        </div>
        {/* col5. 이번시즌 승률 */}
        <div className='table-col5'>
          <span>
            {t('common.label.winrate')}
            <Sortingimage onClick={() => requestSorts('cdSeasonWinrate')} key='cdSeasonWinrate' />
          </span>
        </div>
        {/* col6. 최근 30일 */}
        <div className='table-col6'>
          <span>
            {t('soloRank.myTeam.label.recentDays').replace('##', selectedDay)}
            <Sortingimage onClick={() => requestSorts('cdLastDayTotal')} key='cdLastDayTotal' />
          </span>
        </div>
        {/* col7. 최근 30일 승률 */}
        <div className='table-col7'>
          <span>
            {t('common.label.winrate')}
            <Sortingimage onClick={() => requestSorts('cdLastDayWinrate')} key='cdLastDayWinrate' />
          </span>
        </div>
        {/* col8. 최근 30일간 플레이한 챔피언 */}
        <div className='table-col8'>
          <span>
            {t('soloRank.myTeam.label.daysPlayedCham')
              .split('\n')
              .map((line) => {
                return (
                  <>
                    {line.replace('##', selectedDay)}
                    <br />
                  </>
                );
              })}
            <DropdownContainer
              onChange={(e) => {
                if (e.currentValue !== '') {
                  setSelectedDay(e.currentValue ?? 30);
                }
              }}
            >
              <DropdownLabel css={[dropdownStyle.select_head]}>
                {`${t('common.label.recent')} ${selectedDay} ${t('common.date.day')}${
                  +selectedDay > 1 && lang !== 'ko' ? 's' : ''
                }`}
              </DropdownLabel>
              <DropdownList label='recentDays'>
                {inquireDayList.map((day) => {
                  return (
                    <DropdownItem
                      key={day + t('common.date.day')}
                      css={[dropdownStyle.select_item]}
                      value={day}
                      label={`${t('common.label.recent')} ${day} ${t('common.date.day')}${
                        +day > 1 && lang !== 'ko' ? 's' : ''
                      }`}
                    >
                      {`${t('common.label.recent')} ${day} ${t('common.date.day')}${
                        +day > 1 && lang !== 'ko' ? 's' : ''
                      }`}
                    </DropdownItem>
                  );
                })}
              </DropdownList>
            </DropdownContainer>
          </span>
        </div>
      </S.TableHeaderRow>
    </S.TableHead>
  );
};

export default SRCategory;
