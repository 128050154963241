import React, { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled/macro';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, Typography, Stack, styled as MUIStyled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ScrimDraftBox from './ScrimDraftBox';
import { colors, fontsStyle } from '../../../Styles/ui';
import { ScrimViewData } from '../../../services/ScrimServices';
import * as S from './ScrimResultItem.style';
import { ChampionListAtom, LOLPatchAtom } from '../../../recoil/common/championListAtom';
import { userLanguageAtom } from '../../../recoil/Auth/userAtom';
import Pick from './Pick';
import useParseChampionImagePath from '../../../Hooks/useParseChampionImagePath';

function convertPosition(pos: number) {
  switch (pos) {
    case 1:
      return 'top';
    case 2:
      return 'jng';
    case 3:
      return 'mid';
    case 4:
      return 'bot';
    case 5:
      return 'sup';
    default:
      return '';
  }
}

function convertTeamLogo(teamShort: string) {
  const teamName = teamShort.toUpperCase();
  if (teamName === '관리자' || teamName === 'TBD') {
    return `Images/TeamLogo/TBD.png`;
  }
  return `Images/TeamLogo/${teamName}.png`;
}

interface P {
  data: ScrimViewData;
  handleModal: () => void;
}
const NOIMG = '/Images/ico-ban-nopick.svg';
const ScrimResultItem: React.FC<P> = ({ data, handleModal }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(['translation', 'scrim']);
  const navigation = useNavigate();

  // recoil
  const championList = useRecoilValue(ChampionListAtom);
  const patch = useRecoilValue(LOLPatchAtom);
  const lang = useRecoilValue(userLanguageAtom);
  const parseChampionImagePath = useParseChampionImagePath(patch, championList);

  // utill function
  const parseImgPath = useCallback(
    (champion: number | string) => parseChampionImagePath(champion),
    [parseChampionImagePath],
  );

  // computed data
  const pick = [
    { ...data.blue.pick[0], side: 'blue' },
    { ...data.red.pick[0], side: 'red' },
    { ...data.red.pick[1], side: 'red' },
    { ...data.blue.pick[1], side: 'blue' },
    { ...data.blue.pick[2], side: 'blue' },
    { ...data.red.pick[2], side: 'red' },
    { ...data.red.pick[3], side: 'red' },
    { ...data.blue.pick[3], side: 'blue' },
    { ...data.blue.pick[4], side: 'blue' },
    { ...data.red.pick[4], side: 'red' },
  ];

  // handler
  const handleClickEdit = (id: string) => {
    navigation(id, {
      state: { id },
    });
  };

  const handleClickExpand = () => setIsOpen(!isOpen);

  return (
    <>
      <CONTAINER>
        <HEADER>
          <span>{dayjs.unix(Number(data.date)).format('YYYY-MM-DD')}</span>
          <Stack direction='row' spacing={2}>
            <BUTTON
              variant='contained'
              color='secondary'
              onClick={() => handleClickEdit(data.gameid)}
              startIcon={<EditIcon />}
            >
              {t('수정', { ns: 'scrim' })}
            </BUTTON>
            <BUTTON variant='contained' startIcon={<DeleteIcon />} onClick={handleModal}>
              {t('삭제', { ns: 'scrim' })}
            </BUTTON>
          </Stack>
        </HEADER>

        <WRAPPER>
          <div>
            <div style={{ marginBottom: 4 }}>
              <ScrimDraftBox color='blue' data={data.blue} pick={pick} />
            </div>

            <div>
              <ScrimDraftBox color='red' data={data.red} pick={pick} />
            </div>
          </div>
          <S.BTN_EXPAND variant='contained' color='secondary' onClick={handleClickExpand} isOpen={isOpen}>
            <ArrowDropDownIcon />
          </S.BTN_EXPAND>
        </WRAPPER>

        {/* 확장 */}
        {isOpen && (
          <Stack direction='row' spacing={1.5} sx={{ color: 'white', padding: '10px 0 52px 0' }}>
            {/* 블루 */}
            <Box sx={{ flex: 1 }}>
              {/* 인포 */}
              <S.Info isBlue>
                <S.WinLabel isWin={data.blue.result === 1}>
                  {t(`team.draftGame.${data.blue.result === 1 ? 'victory' : 'defeat'}`)}
                </S.WinLabel>
                <Typography sx={{ mr: 5 }} fontSize={15}>
                  {lang === 'ko' ? '블루팀' : 'Blue Team'}
                </Typography>

                <Avatar
                  sx={{ width: 40, height: 40, mr: 1 }}
                  src={convertTeamLogo(data.blue.team)}
                  alt={data.blue.team}
                />

                <Typography fontSize={15}>{data.blue.team}</Typography>
              </S.Info>

              {/* 밴 순서 */}
              <Stack direction='row' sx={{ mt: 5, mb: 3 }}>
                {data.blue.ban.map((banChamp, index) => (
                  <S.Ban key={banChamp + index} isLine={banChamp !== 0}>
                    <img src={parseImgPath(banChamp)} alt={banChamp + index} />
                  </S.Ban>
                ))}
              </Stack>

              {/* 픽 순서 */}
              <Stack direction='row' spacing={2.5} sx={{ mb: 3 }}>
                <Stack alignItems='center' width='100%'>
                  <S.PickBanInfo>1 Pick</S.PickBanInfo>
                  <Pick pickObj={data.blue.pick[0]} />
                </Stack>
                <Stack alignItems='center' width='100%'>
                  <S.PickBanInfo>2/3 Pick</S.PickBanInfo>
                  <Stack direction='row' spacing={2.5}>
                    <Box>
                      <Pick showName pickObj={data.blue.pick[1]} />
                    </Box>
                    <Box>
                      <Pick showName pickObj={data.blue.pick[2]} />
                    </Box>
                  </Stack>
                </Stack>

                <Stack alignItems='center' width='100%'>
                  <S.PickBanInfo>4/5 Pick</S.PickBanInfo>
                  <Stack direction='row' spacing={2.5}>
                    <Box>
                      <Pick showName pickObj={data.blue.pick[3]} />
                    </Box>
                    <Box>
                      <Pick showName pickObj={data.blue.pick[4]} />
                    </Box>
                  </Stack>
                </Stack>
              </Stack>

              {/* 포지션 순서 */}
              <Stack direction='row' spacing={2.5}>
                {[1, 2, 3, 4, 5].map((num) => (
                  <Box key={`${data.gameid} position champion ${num}`} sx={{ flex: 1 }}>
                    <S.PickBanInfo>
                      <img
                        src={`/Images/position/ico-position-${convertPosition(Number(num))}.svg`}
                        alt={`${convertPosition(Number(num))}`}
                      />
                    </S.PickBanInfo>
                    <Pick showName pickObj={data.blue.pick.find((champ) => Number(champ.pos) === num)!} />
                  </Box>
                ))}
              </Stack>
            </Box>

            {/* VS */}
            <Box sx={{ flex: '0 0 36px', textAlign: 'center', fontSize: '13px', paddingTop: 2.5 }}>VS</Box>

            {/* 레드 */}
            <Box sx={{ flex: 1 }}>
              {/* 인포 */}
              <S.Info>
                <S.WinLabel isWin={data.red.result === 1}>
                  {t(`team.draftGame.${data.red.result === 1 ? 'victory' : 'defeat'}`)}
                </S.WinLabel>

                <Typography sx={{ mr: 5 }} fontSize={15}>
                  {lang === 'ko' ? '레드팀' : 'Red Team'}
                </Typography>

                <Avatar
                  sx={{ width: 40, height: 40, mr: 1 }}
                  src={convertTeamLogo(data.red.team)}
                  alt={data.red.team}
                />

                <Typography fontSize={15}>{data.red.team}</Typography>
              </S.Info>

              {/* 밴 순서 */}
              <Stack direction='row' sx={{ mt: 5, mb: 3 }}>
                {data.red.ban.map((banChamp, index) => (
                  <S.Ban key={banChamp + index} isLine={banChamp !== 0}>
                    <img src={parseImgPath(banChamp)} alt={banChamp + index} />
                  </S.Ban>
                ))}
              </Stack>

              {/* 픽 순서 */}
              <Stack direction='row' spacing={2.5} sx={{ mb: 3 }}>
                <Stack alignItems='center' width='100%'>
                  <S.PickBanInfo>1/2 Pick</S.PickBanInfo>
                  <Stack direction='row' spacing={2.5}>
                    <Box>
                      <Pick showName pickObj={data.red.pick[0]} />
                    </Box>
                    <Box>
                      <Pick showName pickObj={data.red.pick[1]} />
                    </Box>
                  </Stack>
                </Stack>
                <Stack alignItems='center' width='100%'>
                  <S.PickBanInfo>3 Pick</S.PickBanInfo>
                  <Stack direction='row' spacing={2.5}>
                    <Box>
                      <Pick showName pickObj={data.red.pick[2]} />
                    </Box>
                  </Stack>
                </Stack>

                <Stack alignItems='center' width='100%'>
                  <S.PickBanInfo>4 Pick</S.PickBanInfo>
                  <Stack direction='row' spacing={2.5}>
                    <Box>
                      <Pick showName pickObj={data.red.pick[3]} />
                    </Box>
                  </Stack>
                </Stack>

                <Stack alignItems='center' width='100%'>
                  <S.PickBanInfo>5 Pick</S.PickBanInfo>
                  <Stack direction='row' spacing={2.5}>
                    <Box>
                      <Pick showName pickObj={data.red.pick[4]} />
                    </Box>
                  </Stack>
                </Stack>
              </Stack>

              {/* 포지션 순서 */}
              <Stack direction='row' spacing={2.5}>
                {[1, 2, 3, 4, 5].map((num) => (
                  <Box key={`${data.gameid} position champion ${num}`} sx={{ flex: 1 }}>
                    <S.PickBanInfo>
                      <img
                        src={`/Images/position/ico-position-${convertPosition(Number(num))}.svg`}
                        alt={`${convertPosition(Number(num))}`}
                      />
                    </S.PickBanInfo>
                    <Pick showName pickObj={data.red.pick.find((champ) => Number(champ.pos) === num)!} />
                  </Box>
                ))}
              </Stack>
            </Box>
          </Stack>
        )}
      </CONTAINER>
    </>
  );
};

export default ScrimResultItem;

const CONTAINER = styled.div`
  margin: 16px 0;
`;

const HEADER = styled.div`
  ${fontsStyle.size[15]};
  color: ${colors.text};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  .title {
    font-weight: bold;
    margin-right: 20px;
  }
`;

const BUTTON = MUIStyled(Button)`
padding: 4px 12px 4px 12px;
font-size: 12px;

.MuiButton-startIcon {
  margin-right: 0px; 

  .MuiSvgIcon-root {
    font-size: 16px;   
  }
}
`;

const WRAPPER = MUIStyled(Box)`
  display: flex;
`;
