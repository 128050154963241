import React from 'react';
import styled from '@emotion/styled';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import Sidebar from './Components/SideBar/SideBar';
import { goProGameReport } from './lib/pagePath';
import { colors, scrollbarStyle } from './Styles/ui';

const Layout = () => {
  const param = useParams();
  const isGRDetail = useLocation().pathname.includes(goProGameReport) && param.id !== undefined;

  return (
    <CONTAINER>
      <WRAPPER>
        {!isGRDetail && <Sidebar />}
        <CONTENTS isGRDetail={isGRDetail}>
          <Outlet />
        </CONTENTS>
      </WRAPPER>
    </CONTAINER>
  );
};

const CONTAINER = styled.main`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const WRAPPER = styled.article`
  display: flex;
  flex: 1;
  height: 100vh;
  overflow: hidden;
`;

const CONTENTS = styled.section<{ isGRDetail: boolean }>`
  width: calc(100% - ${({ isGRDetail }) => (isGRDetail ? '0' : '180')}px);
  height: 100vh;
  ${scrollbarStyle.scroll_4};
  background-color: ${colors.bg_gnb};
`;

export default Layout;
