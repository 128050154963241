import * as React from 'react';
import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import InputUnstyled, { InputUnstyledProps, inputUnstyledClasses } from '@mui/base/InputUnstyled';
import { styled } from '@mui/system';

const StyledInputRoot = styled('div')(
  ({ theme }) => `
  display: flex;
  font-weight: 500;
  border-radius: 8px;
  background: #2f2d38;
  align-items: center;
  justify-content: center;

  &.${inputUnstyledClasses.focused} {
    border: 2px solid #5a41b7;
  }
`,
);

const StyledInputElement = styled('input')(
  ({ theme }) => `
  font-size: 0.825rem;
  width: 148px;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  flex-grow: 1;
  color: white;
  background: none;
  border: none;
  border-radius: inherit;
  padding: 0 12px;
  outline: 0;
`,
);

const IconButton = styled(ButtonUnstyled)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: inherit;
  cursor: pointer;
`;

const InputAdornment = styled('div')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const CustomInput = React.forwardRef(function CustomInput(
  props: InputUnstyledProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { components, ...other } = props;
  return (
    <InputUnstyled
      components={{
        Root: StyledInputRoot,
        Input: StyledInputElement,
        ...components,
      }}
      {...other}
      ref={ref}
    />
  );
});

export default function InputAdornments({ ...props }) {
  const { startDate, endDate, placeHolder } = props;

  return (
    <CustomInput
      {...props}
      id='outlined-start-adornment'
      autoComplete='off'
      endAdornment={
        <InputAdornment>
          <img
            src={`${startDate || endDate ? `/Images/ic-cancle.svg` : '/Images/ic_calendar.png'}`}
            alt='calendar'
          />
        </InputAdornment>
      }
      placeholder={placeHolder}
    />
  );
}
