import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from '@emotion/styled';
import DatePicker from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import { useTranslation } from 'react-i18next';
import en from 'date-fns/locale/en-GB';
import dayjs from 'dayjs';
import { userLanguageAtom } from '../../recoil/Auth/userAtom';
import InputAdornments from '../../Components/Ui/Input/CalendarInput';
import { TRANSLATE_CALENDAR_TITLE } from '../../lib/translation/translate_Calendar';
import './calender.css';
import theme from '../../Styles/Theme';
import { ScrimViewAtom } from '../../recoil/Scrim/ScrimAtom';

const MONTHS = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

interface CalendarProps {
  handleChange: (date: Date[] | undefined[]) => void;
  isSelectDateNeeded: boolean;
  isOppTeamFilterNeeded: boolean;
}

const DateWithOutTime = (year: number, month: number, date: number) => new Date(year, month, date);

const clacDate = (date: number, startDate?: Date) => {
  const DATE = startDate ?? new Date();
  return DateWithOutTime(DATE.getFullYear(), DATE.getMonth(), DATE.getDate() + date);
};

// 당해년도 첫째날 구하기
const getFirstDayofCurrentYear = () => {
  const result = new Date(new Date().getFullYear(), 0, 1);
  return result;
};

// 당해년도 첫째날부터 오늘까지 경과한 일자 구하기
const getPassedDaysFromNewyear = () => {
  const today = new Date();
  const firstDayOfCurrentYear = getFirstDayofCurrentYear();
  const gap = today.getTime() - firstDayOfCurrentYear.getTime();
  const passedDays = Math.ceil(gap / (1000 * 60 * 60 * 24));
  return passedDays;
};

const SELECT_DATE_BTN_LIST = [
  { title: TRANSLATE_CALENDAR_TITLE.all, calc: Math.abs(getPassedDaysFromNewyear() - 1) * -1 }, // 경과한 날짜 -1일 후 음수 처리
  { title: TRANSLATE_CALENDAR_TITLE.recent3days, calc: -3 },
  { title: TRANSLATE_CALENDAR_TITLE.recent5days, calc: -5 },
  { title: TRANSLATE_CALENDAR_TITLE.recent7days, calc: -7 },
  { title: TRANSLATE_CALENDAR_TITLE.recent15days, calc: -15 },
  { title: TRANSLATE_CALENDAR_TITLE.recent1months, calc: -30 },
  { title: TRANSLATE_CALENDAR_TITLE.recent3months, calc: -90 },
];

const Calendar: React.FC<CalendarProps> = ({
  handleChange,
  isSelectDateNeeded = false,
  isOppTeamFilterNeeded = false,
}) => {
  const NOW = (() => {
    const now = new Date();
    return DateWithOutTime(now.getFullYear(), now.getMonth(), now.getDate());
  })();

  const [startDate, setStartDate] = useState<Date>(getFirstDayofCurrentYear());
  const [endDate, setEndDate] = useState<Date>(NOW);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const lang = useRecoilValue(userLanguageAtom);
  const setOppTeam = useSetRecoilState(ScrimViewAtom.oppTeam);
  const { t } = useTranslation();

  useEffect(() => {
    if (!handleChange) return;
    handleChange([startDate, endDate]);
  }, [startDate, endDate]);

  const handleClick = (Date: number, index: number) => {
    const targetDate = clacDate(Date);
    setCurrentTab(index);
    setStartDate(targetDate);
    setEndDate(NOW);
  };

  return (
    <Container>
      {/* 날짜필터 */}
      <DatePickerWrapper>
        {/* 시작 날짜 설정 */}
        <DatePicker
          showPopperArrow={false}
          selected={startDate}
          onChange={(date: Date) => {
            // 시작날짜 재설정 시 종료날짜 초기화
            if (endDate) {
              setEndDate();
            }
            return setStartDate(date);
          }}
          locale={lang === 'ko' ? ko : en}
          dateFormat='yyyy-MM-dd'
          selectsStart
          className={startDate ? 'input-border' : ''}
          startDate={startDate}
          endDate={endDate}
          useWeekdaysShort
          customInput={
            <InputAdornments startDate={startDate} placeHolder={t('utility.calendarFilter.inputStart')} />
          }
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className='date-customheader'>
              <button type='button' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <img src='/Images/ic-calendar-pre.svg' alt='prevBtn' />
              </button>

              {/* 연, 월만 표시 */}
              <div className='custom-month'>{`${date.getFullYear()}${t('common.date.yearWithoutLetter')} 
              ${lang === 'ko' ? MONTHS.indexOf(MONTHS[date.getMonth()]) + 1 : MONTHS[date.getMonth()]}${t(
                'common.date.monthWithoutLetter',
              )}`}</div>

              <button type='button' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <img src='/Images/ic-calendar-next.svg' alt='nextBtn' />
              </button>
            </div>
          )}
        />

        <div className='hyphen'> - </div>

        {/* 종료 날짜 설정 */}
        <DatePicker
          showPopperArrow={false}
          selected={endDate}
          onChange={(date: Date) => setEndDate(date)}
          dateFormat='yyyy-MM-dd'
          selectsEnd
          locale={lang === 'ko' ? ko : en}
          className={endDate ? 'input-border' : ''}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          useWeekdaysShort
          customInput={
            <InputAdornments endDate={endDate} placeHolder={t('utility.calendarFilter.inputEnd')} />
          }
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className='date-customheader'>
              <button type='button' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <img src='/Images/ic-pre.png' alt='prevBtn' />
              </button>

              {/* 연, 월만 표시 */}
              <div className='custom-month'>{`${date.getFullYear()}${t('common.date.yearWithoutLetter')} 
              ${lang === 'ko' ? MONTHS.indexOf(MONTHS[date.getMonth()]) + 1 : MONTHS[date.getMonth()]}${t(
                'common.date.monthWithoutLetter',
              )}`}</div>
              <button type='button' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <img src='/Images/ic-next.png' alt='nextBtn' />
              </button>
            </div>
          )}
        />
      </DatePickerWrapper>

      {/* 전체 기간 및 지난 날짜 조회 */}
      {isSelectDateNeeded && (
        <SelectBtnWrapper>
          {SELECT_DATE_BTN_LIST.map((item, index) => {
            return (
              <SelectDateBtn
                isActive={index === currentTab}
                isOppTeamFilterNeeded={isOppTeamFilterNeeded}
                type='button'
                onClick={() => {
                  setOppTeam('');
                  handleClick(item.calc, index);
                }}
              >
                {t(item.title)}
              </SelectDateBtn>
            );
          })}
        </SelectBtnWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 1110px;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;

  .date-customheader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
  }

  .custom-month {
    font-size: 15px;
  }

  .hyphen {
    margin: 0 10px;
  }
`;

const SelectBtnWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SelectDateBtn = styled.button<{ isActive: boolean; isOppTeamFilterNeeded: boolean }>`
  width: ${({ isOppTeamFilterNeeded }) => (isOppTeamFilterNeeded ? '76px' : '87px')};
  height: 40px;
  margin-left: 5px;
  padding: 9px 12px 7px;
  border-radius: 10px;
  background-color: #2f2d38;
  background-color: ${({ isActive }) => (isActive ? theme.colors.point : theme.colors.bg_box)};
  color: #fff;
  font-size: 12px;

  :nth-child(n + 5) {
    width: 87px;
  }
`;

export default Calendar;
