/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { Box, FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useRecoilState, useRecoilValue } from 'recoil';
import { styled as MuiStyled } from '@mui/system';
import { colors, spacing } from '../../Styles/ui';
import AuthServices from '../../services/AuthServices';
import checkEmail from './lib/checkEmail';
import { goChangePw, goSignUp } from '../../lib/pagePath';
import { userAtom, userLanguageAtom } from '../../recoil/Auth/userAtom';
import { getLanguage, getLanguageLabel } from '../../i18n';
import { ILoginParam } from '../../services/types/auth_services.type';

function Login() {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();
  const [isCheck, setIsCheck] = useState(true);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(['login', 'translation']);
  const [user, setUser] = useRecoilState(userAtom);

  const showTotalErrorMessage = () => {
    let result = '';
    if (errors.total) {
      setTimeout(() => {
        clearErrors('total');
      }, 1500);

      result = errors.total.message as string;
    }
    return result;
  };

  const getErrorMsg = (error: { [x: string]: any }) => {
    const { id, password } = error;
    if (id) {
      return t(`error.id-${id.type}`, { ns: 'login' });
    }
    if (password) {
      return t(`error.password-${password.type}`);
    }
    return '';
  };

  useEffect(() => {
    console.log('errors', errors);
  }, [errors]);

  // 언어설정
  useEffect(() => {
    document.title = `NUNU.GG`;
  }, []);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  useEffect(() => {
    const lang = getLanguage(isCheck ? navigator.language : 'en');
    i18n.changeLanguage(lang);
  }, [isCheck]);

  const { mutate, error, isLoading } = useMutation(
    ({ id, password }: ILoginParam) => AuthServices.login({ id, password }),
    {
      onSuccess: (data) => {
        const { Authority, lang, name, teamName, token, userId, charge_time } = data.response!;

        setUser({
          Authority,
          charge_time,
          lang,
          name,
          teamName,
          token,
          userId,
        });
      },
      onError: (err: AxiosResponse<any>) => {
        if (err.status.toString() === '403') {
          setError('total', { type: 'custom', message: '아이디와 비밀번호를 확인해주세요' });
        }
      },
    },
  );

  const onValid = (data: { [x: string]: any }) => {
    const { id, password } = data as ILoginParam;
    mutate({ id, password });
  };

  return (
    <WRAPPER>
      <div>
        <Stack sx={{ width: '100%' }}>
          {getLanguage(navigator.language) === 'en' && (
            <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
              <Typography variant='caption' sx={{ color: 'white' }}>
                English
              </Typography>
              <Switch onChange={() => setIsCheck(!isCheck)} checked={isCheck} />
              <Typography variant='caption' sx={{ color: 'white' }}>
                {getLanguageLabel(navigator.language)}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack
          direction='row'
          sx={{
            width: '810px',
            height: '380px',
            borderRadius: '20px',
            border: 'solid 1px rgb(58, 55, 69)',
            backgroundColor: 'rgb(47, 45, 56)',
          }}
        >
          <LEFT />
          <RIGHT>
            <LOGO>
              <img src='/Images/logo2.png' alt='' />
            </LOGO>

            {/* 로그인폼 */}
            <form onSubmit={handleSubmit(onValid)}>
              <INPUT
                type='text'
                className='id'
                id='id'
                placeholder='ID'
                autoComplete='off'
                {...register('id', {
                  required: true,
                  validate: (value) => {
                    return checkEmail(value);
                  },
                })}
              />
              <INPUT
                type='password'
                className='password'
                id='password'
                autoComplete='off'
                placeholder='PASSWORD'
                {...register('password', {
                  required: true,
                })}
              />
              <LOADINGBUTTON type='submit' loading={isLoading} variant='contained'>
                LOGIN
              </LOADINGBUTTON>

              <ERRORTEXT>{getErrorMsg(errors) || showTotalErrorMessage()}</ERRORTEXT>
            </form>

            <FLEXBOX>
              <button
                type='button'
                onClick={() => {
                  navigate(goSignUp);
                }}
              >
                {t('sign.signUp', { ns: 'translation' })}
              </button>

              <span>|</span>

              <button
                type='button'
                onClick={() => {
                  navigate(goChangePw);
                }}
              >
                {t('sign.changePW', { ns: 'translation' })}
              </button>
            </FLEXBOX>
          </RIGHT>
        </Stack>
      </div>
      <CopyRight>Copyright Team Snowball All Rights Reserved.</CopyRight>
    </WRAPPER>
  );
}

export default Login;

const LOADINGBUTTON = MuiStyled(LoadingButton)`
width: 227px;
font-size: 14px;
border-radius: 999px;

&.MuiLoadingButton-loading {
  background-color: ${colors.배경색[700]};
}

& .MuiLoadingButton-loadingIndicator {
  color: ${colors.보라색[50]} !important;
}

`;

const WRAPPER = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(35, 33, 42);
`;

const LEFT = styled.div`
  background-image: url('/Images/index-login-img.png');
  background-repeat: no-repeat;
  width: 436px;
  height: 100%;
  border-radius: 20px 0 0 20px;
  mix-blend-mode: luminosity;
`;

const RIGHT = styled.div`
  text-align: center;
  width: calc(810px - 436px);
  height: 100%;
  padding-top: 90px;
  ${spacing.paddingX(18)}
`;

const LOGO = styled.div`
  width: 122px;
  height: 20px;
  margin: 0 auto;
  ${spacing.marginB(3)}

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const INPUT = styled.input`
  width: 100%;
  ${spacing.paddingY(2.5)};
  ${spacing.paddingX(5)};
  ${spacing.marginB(3)};
  color: ${colors.text};
  background-color: ${colors.bg_checkbox};
  border-radius: 20px;
  font-size: 13px;
  text-align: left;
`;

const ERRORTEXT = styled.p`
  ${spacing.paddingY(2)}
  color: ${colors.badge_red};
  font-size: 11px;
  height: 20px;
`;

const CopyRight = styled.p`
  color: white;
  font-size: 13px;
  font-weight: 300;
  text-align: center;
  opacity: 0.2;
  margin-top: 25px;
`;

const FLEXBOX = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: ${colors.text};
  ${spacing.marginT(8)};

  > button {
    color: ${colors.text};
  }
`;
