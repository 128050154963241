import React from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';

interface P {
  PRInfo: string;
}

const PRInfoTooltip: React.FC<P> = ({ PRInfo }) => {
  return (
    <ArrowWrapper>
      <ToolTipWrapper>
        <Contents>{PRInfo}</Contents>
      </ToolTipWrapper>
    </ArrowWrapper>
  );
};

export default PRInfoTooltip;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ToolTipWrapper = styled.div`
  max-width: 295px;
  background-color: #433f4e;
  padding: 10px;
  border-radius: 10px;
  /* text-align: left; */
  font-size: 13px;
`;

const Contents = styled.div`
  color: #ffffff;
  line-height: 1.38;
`;
