/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import SRContent from '../Component/Common/SRContent';
import FSHeader from '../Component/FSHeader';
import NoneDatabox from '../../../Components/Ui/NoneDatabox';
import SoloRankServices, { Players } from '../../../services/SoloRankServices';
import { SoloRankSearchFilterParam } from '../../../services/types/param_types';
import { TeamReportResultAtom } from '../../../recoil/Filter/teamReportAtom';
import Progress from '../../../Components/Ui/Loading/Progress';
import { colors } from '../../../Styles/ui';

const SearchFilter = () => {
  const [selectedDay, setSelectedDay] = useState('30');
  const [playerInfo, setPlayerInfo] = useState<Players[]>([]);
  const [myTeamName, setMyTeamName] = useState('');
  const { t } = useTranslation();
  const result = useRecoilValue(TeamReportResultAtom);
  const resetResult = useResetRecoilState(TeamReportResultAtom); // reset recoil atom

  const { mutate: getSeachFilterPlayer, isLoading } = useMutation(
    (data: SoloRankSearchFilterParam) =>
      SoloRankServices.getSeachFilterPlayer({
        ...data,
        days: selectedDay,
      }),
    {
      onSuccess: (data) => {
        const e = data.response;
        if (Object.keys(e).length !== 0) {
          setPlayerInfo(e.players);
          setMyTeamName(e.team);
        } else {
          setPlayerInfo([]);
          setMyTeamName('');
        }
      },
    },
  );

  useEffect(() => {
    resetResult();
  });

  useEffect(() => {
    if (result) {
      getSeachFilterPlayer({
        team: result.team,
        patch: result.patch,
      });
    }
  }, [result]);

  return (
    <>
      <FSHeader />
      {/* 팀 선수 테이블 */}
      {isLoading ? (
        <ProgressContainer>
          <Progress text={t('solo.playerboard.dataFetching')} />
        </ProgressContainer>
      ) : playerInfo.length > 0 ? (
        <SRContent
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          playerInfo={playerInfo}
          usingBookmark
          usingDeletePlayer={false}
          usingDeletePlayerGuide={false}
          usingAddPlayer={false}
          usingDeleteSoloId={false}
          usingAddSoloId={false}
          myTeamName={myTeamName}
          getInfoFunc={() => {}}
        />
      ) : (
        <NoneDatabox headerText={t('soloRank.searchFilter.desc.noDataHeader')} subText={undefined} />
      )}
    </>
  );
};

export default SearchFilter;

const ProgressContainer = styled.div`
  width: ${1120}px;
  height: 160px;
  margin: 20px 0 0;
  padding: 0 30px;
  border-radius: 20px;
  background-color: ${colors.bg_select};
  display: flex;
  align-items: center;
  justify-content: center;
`;
