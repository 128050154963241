/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import { Stack, Typography } from '@mui/material';
import { spacing, typoStyle } from '../../../Styles/ui';

interface P {
  text?: React.ReactNode;
  children?: React.ReactNode;
  css?: { [x: string]: any };
}

const NotFound: React.FC<P> = ({ text, children = <></>, css }) => {
  return (
    <Stack alignItems='center' py={10}>
      <ImgContainer>
        <img src='/Images/icon/icon_error_page.svg' alt='NotFound' />
      </ImgContainer>
      <TextContainer>{text}</TextContainer>
      {children}
    </Stack>
  );
};

const ImgContainer = styled.div`
  ${spacing.marginB(4)}
`;
const TextContainer = styled.div`
  ${typoStyle.contents_title}
`;

export default NotFound;
