import React, { useState } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { useRunesCall } from '../../../../../../Hooks/useRunesCall';
import { RootState } from '../../../../../../redux/modules';
import { RuneInfo } from '../../../../../../services/ClientServices';
import { useRecoilValue } from 'recoil';
import GameDatasetAtom from '../../../../../../recoil/ProGameReport/gameDatasetAtom';
import GameAtom from '../../../../../../recoil/ProGameReport/gameAtom';

const StatusBox4 = () => {
  const fixedDataset = useRecoilValue(GameDatasetAtom.fixedDataset);
  const selectedTeam = useRecoilValue(GameAtom.selectedTeam);
  const selectedPosition = useRecoilValue(GameAtom.selectedPosition);

  const runesJoson: RuneInfo[] = useRunesCall().data;

  const runesData = fixedDataset[selectedTeam].players[selectedPosition].rune;

  const mainRune = getRunesAddr(runesData.slice(0, 5));
  const subRune = getRunesAddr(runesData.slice(5, 8));
  const statRune = runesData.slice(8, 11);

  function getRunesAddr(runeList: number[]) {
    const arrImgAddr: number[] = [];
    let slots = null;
    runesJoson
      .filter((runeObject) => runeObject.id === runeList[0])
      .forEach((runeObject) => {
        slots = runeObject.slots;
        arrImgAddr.push(runeObject.icon);
        return false;
      });
    runeList.slice(1).forEach((id: number) => {
      slots.forEach((slot) => {
        slot.runes
          .filter((rune) => id === rune.id)
          .forEach((rune) => {
            arrImgAddr.push(rune.icon);
          });
      });
    });

    return arrImgAddr;
  }

  return (
    <RunesContainer>
      <MainRuneBox>
        <img src={`http://ddragon.leagueoflegends.com/cdn/img/${mainRune[1]}`} alt='' />
      </MainRuneBox>
      <SubRuneBox>
        <RunesBox>
          <div className='sub-rune'>
            <img src={`http://ddragon.leagueoflegends.com/cdn/img/${mainRune[2]}`} alt='' />
          </div>
          <div className='sub-rune'>
            <img src={`http://ddragon.leagueoflegends.com/cdn/img/${mainRune[3]}`} alt='' />
          </div>
          <div className='sub-rune'>
            <img src={`http://ddragon.leagueoflegends.com/cdn/img/${mainRune[4]}`} alt='' />
          </div>
        </RunesBox>
        <RunesBox>
          <div className='sub-rune'>
            <img src={`http://ddragon.leagueoflegends.com/cdn/img/${subRune[1]}`} alt='' />
          </div>
          <div className='sub-rune'>
            <img src={`http://ddragon.leagueoflegends.com/cdn/img/${subRune[2]}`} alt='' />
          </div>
        </RunesBox>
        <RuneStatsBox>
          <div className='rune-stat'>
            <img src={`/Images/icon/${statRune[0]}.png`} alt='' />
          </div>
          <div className='rune-stat'>
            <img src={`/Images/icon/${statRune[1]}.png`} alt='' />
          </div>
          <div className='rune-stat'>
            <img src={`/Images/icon/${statRune[2]}.png`} alt='' />
          </div>
        </RuneStatsBox>
      </SubRuneBox>
    </RunesContainer>
  );
};

export default StatusBox4;

const RunesContainer = styled.div`
  width: auto;
  height: 76px;
  margin: 14px 10px 19.5px 5px;
  display: flex;
`;

const MainRuneBox = styled.div`
  width: 76px;
  height: 76px;
  margin: 0 3px 0 0;
  object-fit: contain;

  img {
  }
`;

const SubRuneBox = styled.div``;

const RunesBox = styled.div`
  width: 126px;
  height: 26px;
  margin: 0 0 0px;
  display: flex;

  .sub-rune {
    width: 21px;
    height: 21px;
    margin: 0px 3px 0px 0px;
    object-fit: contain;

    img {
      width: 21px;
      height: 21px;
    }
  }
`;

const RuneStatsBox = styled.div`
  height: 16px;
  margin: 6px 0px 0 0;
  display: flex;

  .rune-stat {
    width: 16px;
    height: 16px;
    margin: 0 6px 0 0;
    object-fit: contain;
    border-radius: 10px;

    img {
      width: 16px;
      height: 16px;
    }
  }
`;
