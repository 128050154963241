import { Avatar, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { ChampionListAtom, LOLPatchAtom } from '../../recoil/common/championListAtom';
import useChampionImage from '../../Hooks/useChampionImage';

interface ChampionImg {
  championId: number | string;
  sx?: SxProps<Theme>;
}

/**
 *
 * @param id: 챔피언 고유번호
 * @param sx: MUI style custom
 * @returns jsx mui Avatar
 */
const ChampionImg: React.FC<ChampionImg> = ({ championId, sx }) => {
  const { getChampionImagePathByName } = useChampionImage();

  const championList = useRecoilValue(ChampionListAtom);
  const patch = useRecoilValue(LOLPatchAtom);
  const championEn = championList[championId] && championList[championId].en;
  const imgSrc = championEn ? getChampionImagePathByName(championEn) : '/Images/icon/icon_error_page.svg';

  return championList && <Avatar sx={sx} src={imgSrc} alt={championEn ?? 'error'} />;
};

export default ChampionImg;
