/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
// recoil
import { useRecoilValue } from 'recoil';
import { TeamReportResultAtom } from '../../../recoil/Filter/teamReportAtom';
// components
import DraftContant from './Component/DraftContent';
import TeamServices from '../../../services/TeamServices';
import Progress from '../../../Components/Ui/Loading/Progress';
import NotFound from '../../../Components/Ui/Error/NotFound';

type Tabs = 'all' | 'red' | 'blue';
const TABS: Tabs[] = ['all', 'red', 'blue'];

// 최신 데이터가 가장 상단에 올라오도록 렌더링 => 백엔드에서 정렬해서 보내줌
const BanPickIndex = () => {
  const { t } = useTranslation();
  const [side, setSide] = useState<Tabs>('all');
  const result = useRecoilValue(TeamReportResultAtom);
  const { mutate, data, isLoading, isError } = useMutation((result: object) =>
    TeamServices.getTeamDraft({
      ...result,
    }),
  );

  const filteredData = data?.response.filter((draft) => {
    switch (side) {
      case 'red':
        if (draft.red.team === result.team[0]) return draft;
        break;
      case 'blue':
        if (draft.blue.team === result.team[0]) return draft;
        break;
      case 'all':
        return draft;
      default:
        return false;
    }
    return false;
  });

  useEffect(() => {
    mutate(result);
  }, [result]);

  return (
    <WRAPPER>
      <TAB_CONATINER>
        {TABS.map((tab) => (
          <TAB_ITEM
            key={`tab_${tab}`}
            className='All'
            onClick={() => setSide(tab)}
            changeColor={side === tab}
          >
            <div>
              <span>{tab.toUpperCase()}</span>
            </div>
          </TAB_ITEM>
        ))}
      </TAB_CONATINER>
      <TAB_CONTENTS>
        <TAB_CONTENTS_CONTAINER>
          {isLoading ? (
            <ProgressContainer>
              <Progress text={t('solo.playerboard.dataFetching')} />/
            </ProgressContainer>
          ) : isError ? (
            <NotFound css={{ height: '100%' }} text={t('solo.playerboard.dataFetchingFailed')} />
          ) : (
            <div>
              {filteredData &&
                filteredData.map((filteredDraft, index) => (
                  <DraftContant key={`${side}_${filteredDraft.date}_${index}`} data={filteredDraft} />
                ))}
            </div>
          )}
        </TAB_CONTENTS_CONTAINER>
      </TAB_CONTENTS>
    </WRAPPER>
  );
};

export default BanPickIndex;

const WRAPPER = styled.div`
  max-width: 1200px;
`;

const TAB_CONATINER = styled.div`
  display: flex;
  width: 100%;
  height: 62px;
  border-bottom: solid 1px #433f4e;
`;

const TAB_ITEM = styled.button<{ changeColor: boolean }>`
  display: flex;
  align-items: center;
  width: auto;
  white-space: nowrap;

  div {
    padding: 10px 15px;
  }

  :hover {
    div {
      padding: 10px 15px;
      border-radius: 10px;
      background-color: #26262c;
    }
  }

  span {
    height: 22px;
    font-family: SpoqaHanSansNeo;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 18px;
    border-bottom: solid 1px ${(props) => (props.changeColor ? `#fff` : `none;`)};
    color: ${(props) => (props.changeColor ? `#fff` : `#84818e`)};
  }
`;

const TAB_CONTENTS = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TAB_CONTENTS_CONTAINER = styled.div`
  height: 100%;
  overflow-x: hidden;
`;

const ProgressContainer = styled.div`
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
`;
