import React, { useState, useRef, useEffect } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import { useRecoilValue } from 'recoil';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import h337 from 'heatmap.js';
import { useTranslation } from 'react-i18next';
import SetByChampion from './SetByChampion';
import SetByPlayer from './SetByPlayer';
import { Reset_MapTab, ResetChampion } from '../../../redux/modules/filtervalue';
import { goPlayerReport } from '../../../lib/pagePath';
import { PathAnalysisResultAtom } from '../../../recoil/Filter/pathAnalysisAtom';
import JunglePathingService, { HitMapParams } from '../../../services/JunglePathingServices';
import NotFound from '../../../Components/Ui/Error/NotFound';
import { RootState } from '../../../redux/modules';
import { MappingPosition } from '../../../services/WardPathingService';

const OLD_X_MIN = -120;
const OLD_X_MAX = 14870;

const OLD_Y_MIN = -120;
const OLD_Y_MAX = 14980;

const NEW_X_MAX = 295;
const NEX_X_MIN = 0;
const NEW_Y_MAX = 0;
const NEW_Y_MIN = 295;

const convertX = (OLD_X: number) => {
  return ((OLD_X - OLD_X_MIN) * (NEW_X_MAX - NEX_X_MIN)) / (OLD_X_MAX - OLD_X_MIN) + NEX_X_MIN;
};
const convertY = (OLD_Y: number) => {
  return ((OLD_Y - OLD_Y_MIN) * (NEW_Y_MAX - NEW_Y_MIN)) / (OLD_Y_MAX - OLD_Y_MIN) + NEW_Y_MIN;
};

interface P {}

const HitMap: React.FC = () => {
  const result = useRecoilValue(PathAnalysisResultAtom)!;
  const selectedTeam = result.team[0];

  // 시간 설정 상태값
  const [minFrom, setMinFrom] = useState([0, 100]);
  const firstTime = minFrom[0] * 18;
  const secondTime = minFrom[1] * 18;
  const isPageSolo = document.location.pathname === goPlayerReport;

  const filters = useSelector((state: RootState) => state.FilterReducer);
  console.log('filters', filters);
  const resetHeatMap1 = useRef(null);
  const resetHeatMap2 = useRef(null);
  const [tab, setTab] = useState<'player' | 'champion'>('player');
  const [selectedPlayer, setSelectedPlayer] = useState<string>('');
  const [selectedPlayerPosition, setSelectedPlayerPosition] = useState<string>('');
  const [selectedChampArray, setSelectedChampArray] = useState<string[]>([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  // 히트맵 데이터 fetch 함수
  const {
    mutate: getHitmap,
    data,
    isLoading,
    isError,
  } = useMutation((data: HitMapParams) => JunglePathingService.getHitmap(data));

  const showAlertMesage = () => {
    const { oppteam, oppplayer, oppchampion_eng } = filters;

    const unselectedItem = [];

    if (tab === 'player') {
      if (selectedTeam.length === 0) unselectedItem.push(t('video.vision.team'));
      if (selectedPlayer.length === 0) unselectedItem.push(t('video.vision.player'));
      if (selectedChampArray.length === 0) unselectedItem.push(t('video.vision.champ'));
    } else if (tab === 'champion') {
      if (selectedTeam.length === 0) unselectedItem.push(t('video.vision.team'));
      if (selectedPlayer.length === 0) unselectedItem.push(t('video.vision.player'));
      if (selectedChampArray.length === 0) unselectedItem.push(t('video.vision.champ'));
      if (oppteam.length === 0) unselectedItem.push(t('video.vision.team2'));
      if (oppplayer.length === 0) unselectedItem.push(t('video.vision.player2'));
      if (oppchampion_eng.length === 0) unselectedItem.push(t('video.vision.champ2'));
    }

    if (unselectedItem.length > 0) {
      let unselectedSentence = '';
      for (let i = 0; i < unselectedItem.length; i += 1) {
        unselectedSentence += unselectedItem[i];
        if (i !== unselectedItem.length - 1) {
          unselectedSentence += ', ';
        }
      }
      const alertMessage = t('video.vision.selectAlert').replace('###', unselectedSentence);
      alert(alertMessage);
    }
  };

  const refineHitmapData = (position: MappingPosition) => {
    showAlertMesage();
    const heatData = position;
    const blueData = [];
    const redData = [];
    let blueMax = 0;
    let redMax = 0;

    if (heatData) {
      for (let j = 0; j < heatData.length; j += 1) {
        const dto = heatData[j];
        const player = dto.player!;

        if (!(Object.keys(dto).length === 0)) {
          for (let i = 0; i < player.length; i += 1) {
            if (player[i].team === 'blue') {
              blueMax += 1;
              const x = (convertX(Number(player[i].x)) * 538) / 295;
              const y = (convertY(Number(player[i].y)) * 538) / 295;
              blueData.push({ x: x.toFixed(0), y: y.toFixed(0), value: 0.5 });
            } else {
              redMax += 1;
              const x = (convertX(Number(player[i].x)) * 538) / 295;
              const y = (convertY(Number(player[i].y)) * 538) / 295;
              redData.push({
                x: x.toFixed(0),
                y: y.toFixed(0),
                value: 0.5,
              });
            }
          }
        }
      }
    }

    let blueBlur = 0.9;
    let redBlur = 0.9;

    if (blueMax > redMax) {
      redBlur *= redMax / blueMax;
    } else {
      blueBlur *= blueMax / redMax;
    }

    getHitmapBlue(blueData, blueBlur);
    getHitmapRed(redData, redBlur);
  };

  useEffect(() => {
    if (!data) return;
    refineHitmapData(data.response.position);
  }, [data]);

  // 히트맵 블루팀 데이터 가공 함수
  const getHitmapBlue = (data, blur) => {
    resetHeatMap1.current.innerHTML = '';

    const data2 = {
      max: 30,
      min: 0,
      data,
    };

    const heatmap = h337.create({
      container: resetHeatMap1.current!,
      maxOpacity: 0.6,
      radius: 10,
      blur: blur === 0 ? 0.9 : blur,
    });

    heatmap.setData(data2);
  };
  // 히트맵 레드팀 데이터 가공 함수
  const getHitmapRed = (data, blur) => {
    resetHeatMap2.current.innerHTML = '';

    const data2 = {
      max: 30,
      min: 0,
      data,
    };

    const heatmap = h337.create({
      container: resetHeatMap2.current!,
      maxOpacity: 0.6,
      radius: 10,
      blur: blur === 0 ? 0.9 : blur,
    });

    console.log(data2);

    heatmap.setData(data2);
  };

  const confirmHitmapData = () => {
    getHitmap({
      ...result,
      team: selectedTeam,
      player: selectedPlayer,
      champion: selectedChampArray,
      compare: 'off',
      oppteam: filters.oppteam,
      oppplayer: filters.oppplayer,
      oppchampion: filters.oppchampion_eng,
      side: 'all',
      firsttime: firstTime,
      secondtime: secondTime,
    });
  };

  const contents = {
    player: (
      <SetByPlayer
        setMinFrom={setMinFrom}
        minFrom={minFrom}
        selectedPlayer={selectedPlayer}
        setSelectedPlayer={setSelectedPlayer}
        selectedChampArray={selectedChampArray}
        setSelectedChampArray={setSelectedChampArray}
        selectedPlayerPosition={selectedPlayerPosition}
        setSelectedPlayerPosition={setSelectedPlayerPosition}
      />
    ),
    champion: <SetByChampion setMinFrom={setMinFrom} minFrom={minFrom} />,
  };

  return (
    <HitMapContainer>
      <TabBox>
        <FilterTab
          className='player'
          onClick={() => {
            if (tab !== 'player') {
              setTab('player');
              if (isPageSolo) {
                dispatch(ResetChampion());
              } else {
                dispatch(Reset_MapTab());
              }
              resetHeatMap1.current.innerHTML = '';
              resetHeatMap2.current.innerHTML = '';
            }
          }}
          changeColor={tab === 'player'}
        >
          <div>
            <span>{t('video.heatmap.all')}</span>
          </div>
        </FilterTab>

        <FilterTab
          className='opp-champ'
          onClick={() => {
            setTab('champion');
            if (isPageSolo) {
              dispatch(ResetChampion());
            } else {
              dispatch(Reset_MapTab());
            }
            resetHeatMap1.current.innerHTML = '';
            resetHeatMap2.current.innerHTML = '';
          }}
          changeColor={tab === 'champion'}
        >
          <div>
            <span>{t('video.heatmap.opp')}</span>
          </div>
        </FilterTab>

        <LastMargin />
      </TabBox>
      <TopSection>
        <FilterContents>{contents[tab]}</FilterContents>
        <ButtonSection>
          <ConfirmButton
            onClick={confirmHitmapData}
            isActive={
              tab === 'player'
                ? selectedChampArray.length > 0
                : tab === 'champion'
                ? selectedChampArray.length > 0 && filters.oppchampion_eng
                : false
            }
          >
            {t('video.heatmap.apply')}
          </ConfirmButton>
        </ButtonSection>
      </TopSection>

      <BottomSection>
        <HitMapSide>
          <SideNav>BLUE {t('video.heatmap.side')}</SideNav>
          <HitMapWrapper>
            {isLoading ? (
              <LoadingImage>
                <img src='/Images/loadingSpinner_purple.gif' alt='Loading' />
              </LoadingImage>
            ) : isError ? (
              <LoadingImage>
                <NotFound css={{ height: '100%' }} text={t('solo.playerboard.dataFetchingFailed')} />
              </LoadingImage>
            ) : (
              <MapContainer>
                <Map ref={resetHeatMap1} />
              </MapContainer>
            )}
          </HitMapWrapper>
        </HitMapSide>
        <HitMapSide>
          <SideNav2>RED {t('video.heatmap.side')}</SideNav2>
          <HitMapWrapper>
            {isLoading ? (
              <LoadingImage>
                <img src='/Images/loadingSpinner_purple.gif' alt='Loading' />
              </LoadingImage>
            ) : isError ? (
              <LoadingImage>
                <NotFound css={{ height: '100%' }} text={t('solo.playerboard.dataFetchingFailed')} />
              </LoadingImage>
            ) : (
              <MapContainer>
                <Map ref={resetHeatMap2} />
              </MapContainer>
            )}
          </HitMapWrapper>
        </HitMapSide>
      </BottomSection>
    </HitMapContainer>
  );
};

export default HitMap;

const HitMapContainer = styled.div`
  margin-top: 0px;
  width: 1098px;
  height: calc(100vh);
`;

const TopSection = styled.section`
  min-height: 123px;
  border: solid 1px rgb(67, 63, 78);
  background-color: rgb(47, 45, 56);
  border-radius: 20px;
`;

const BottomSection = styled.section`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #433f4e;
`;

const ConfirmButton = styled.button<{ isActive: boolean }>`
  width: 97%;
  height: 60px;
  border-radius: 20px;
  background-color: #484655;
  font-family: SpoqaHanSansNeo;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin: 20px 0;
  color: rgb(255, 255, 255);
  :hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.isActive &&
    css`
      background-color: #5942ba;
    `}
`;

const TabBox = styled.div`
  display: flex;
  height: 62px;
  margin-bottom: 20px;
`;

const FilterTab = styled.button<{ changeColor: boolean }>`
  display: flex;
  align-items: center;
  width: auto;
  border-bottom: solid 1px #433f4e;
  white-space: nowrap;

  div {
    padding: 10px 15px;
  }

  :hover {
    div {
      padding: 10px 15px;
      border-radius: 10px;
      background-color: #26262c;
    }
  }
  span {
    height: 22px;
    font-family: SpoqaHanSansNeo;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 18px;
    border-bottom: solid 1px ${({ changeColor }) => (changeColor ? `#fff` : `none`)};
    color: ${({ changeColor }) => (changeColor ? `#fff` : `#84818e`)};
  }
`;

const FilterContents = styled.div``;

const HitMapSide = styled.div``;

const SideNav = styled.div`
  display: flex;
  align-items: center;
  padding-left: 22px;
  width: 538px;
  height: 52px;
  border: solid 1px #3a3745;
  background-color: #2f2d38;
  font-family: SpoqaHanSansNeo;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  border-radius: 20px 20px 0px 0px;

  background-image: url('/Images/right-blue-gradient.png');
  background-repeat: no-repeat;
  background-position: right;
`;
const SideNav2 = styled(SideNav)`
  background-image: url('/Images/right-win-gradient.png');
`;

const HitMapWrapper = styled.div`
  display: flex;
  width: 538px;
  height: 538px;
  justify-content: space-between;
`;

const MapContainer = styled.div``;

const Map = styled.div`
  width: 538px;
  height: 538px;
  background-image: url('/Images/obj_map_summer.png');
  background-size: 538px 538px;
`;

const LineMargin = styled.div`
  width: 10px;
  border-bottom: solid 1px #433f4e;
`;

const LastMargin = styled.div`
  width: 73%;
  border-bottom: solid 1px #433f4e;
`;

const LoadingImage = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #2f2d38;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  img {
    width: 30px;
    height: 30px;
  }
`;
