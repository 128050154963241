import React, { useState } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import secToMS from '../../../../../../lib/secToMS';
import { recentVersion } from '../../../../../config';
import { EventsLog } from '../../../../../../services/GameServices';

import GameDatasetAtom from '../../../../../../recoil/ProGameReport/gameDatasetAtom';
import useChampionImage from '../../../../../../Hooks/useChampionImage';

function drakeType(apiDragonType: string) {
  switch (apiDragonType) {
    case 'AIR':
      return 'Cloud';
    case 'FIRE':
      return 'Infernal';
    case 'EARTH':
      return 'Mountain';
    case 'WATER':
      return 'Ocean';
    case 'ELDER':
      return 'Elder';
    case 'CHEMTECH':
      return 'Chemtech';
    case 'HEXTECH':
      return 'Hextech';
    default:
      return '';
  }
}

const MonsterEventBox = ({ data, isActive }: { data: EventsLog; isActive: boolean }) => {
  const fixedDataset = useRecoilValue(GameDatasetAtom.fixedDataset);
  const { getChampionImagePathByName } = useChampionImage();

  const { t } = useTranslation();
  const time = secToMS(Math.floor(data.realCount / 2));
  const type = getMosterType(data.subType);
  const team = data.participantid < 6 ? 0 : 1;
  const { info } = fixedDataset[team].players[team === 0 ? data.participantid - 1 : data.participantid - 6];
  const { player, championEng } = info;

  function getMosterType(type: string) {
    const result = {
      type: '',
      subType: '',
      name: '',
      img: '',
    };
    if (type.includes('DRAGON')) {
      result.type = t('game.log.event.dragon');
      const subType = type.split('_')[0];
      result.subType = drakeType(subType);
      result.name = `${t(`game.log.event.dragonType.${subType.toLowerCase()}`)} ${t(
        'game.log.event.dragon',
      )}`;
      result.img = `ico-drake-${result.subType.toLowerCase()}.svg`;
    } else if (type === 'RIFTHERALD') {
      result.type = t('game.log.event.herald');
      result.subType = t('game.log.event.herald');
      result.name = t('game.log.event.herald');
      result.img = 'ico-heralds.svg';
    } else if (type === 'BARON_NASHOR') {
      result.type = t('game.log.event.baron');
      result.subType = t('game.log.event.baron');
      result.name = t('game.log.event.baron');
      result.img = 'ico-baron.svg';
    }
    return result;
  }

  return (
    <LogContent isActive={isActive} team={team}>
      <div className='title'>
        <div className='dot' />
        <span>{`${time} ${type.type}`}</span>
      </div>
      <div className='body'>
        <img src={getChampionImagePathByName(championEng)} alt={championEng} />
        <span className='player-name'>{player}</span>
        <img src={`/Images/ic_${team === 0 ? 'blue' : 'red'}_kill.svg`} alt='champion' />
        <img src={`/Images/${type.img}`} alt='monster' />
        <span className='player-name'>{type.name}</span>
      </div>
    </LogContent>
  );
};

export default MonsterEventBox;

const LogContent = styled.div<{ isActive: boolean; team: number }>`
  width: 180px;
  height: auto;
  margin: 5px 0;
  padding: 8px 8px 8px 8px;
  border-radius: 10px;
  background-color: #000;
  opacity: ${(props) => (props.isActive ? `1` : `0.3`)};
  border: solid 2px ${(props) => props.isActive && (props.team === 1 ? `#f04545` : `#0075bf`)};
  cursor: pointer;

  .title {
    display: flex;
    height: 19px;
    margin: 0 5px 0px;
    //background-color: #f00;
    span {
      font-family: SpoqaHanSansNeo;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.15;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
  }

  .body {
    //display: flex;
    text-align: left;
    padding-bottom: 0px;
    //border-bottom: solid 1px #23212a;

    img {
      width: 15px;
      height: 15px;
      //margin: 1px 2px 1px 0;
      object-fit: contain;
      border-radius: 20px;
      vertical-align: middle;
    }

    .player-name {
      font-family: SpoqaHanSansNeo;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
  }
  .footer {
    display: flex;
    margin-top: 3px;
    img {
      width: 15px;
      height: 15px;
      //margin: 1px 2px 1px 0;
      object-fit: contain;
      border-radius: 20px;
    }

    .player-name {
      font-family: SpoqaHanSansNeo;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
  }

  .dot {
    width: 6px;
    height: 6px;
    margin: 7px 5px 10px 2px;
    background-color: ${(props) => (props.team === 1 ? `#f04545` : `#0075bf`)};
    border-radius: 10px;
  }
`;
