import { colors } from '../ui';

export const tooltip = {
  intersect: false,
  enabled: true,
  backgroundColor: colors.border_light,
  titleFont: { size: 12 },
  body: { size: 10 },
  displayColors: true,
  boxPadding: 8,
  boxWidth: 4,
  boxHeight: 4,
  usePointStyle: true,
  cornerRadius: 10,
};

export const lineOptions = {
  responsive: true,
  maintainAspectRatio: false,
  //! Chart.js Line Options 에서 사용하지 않는 Property
  // hover: {
  //   animationDuration: 100,
  // },
  scales: {
    x: {
      offset: true,
      ticks: {
        // labelOffset: true,
        borderColor: colors.info,
        color: colors.info,
        font: {
          size: 15,
        },
        padding: 0,
      },
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    y: {
      suggestedMin: 0,
      suggestedMax: 10,
      offset: true,
      ticks: {
        // labelOffset: true,
        color: colors.info,
        stepSize: 2,
        font: {
          size: 15,
        },
        padding: 10,
      },

      grid: {
        drawBorder: false,
        color: colors.bg_checkbox,
      },
    },
  },
  plugins: {
    tooltip: {
      interaction: {
        mode: 'index',
        axis: 'y',
      },
      enabled: true,
      backgroundColor: colors.border_light,
      titleFont: { size: 12 },
      body: { size: 10 },
      displayColors: true,
      boxPadding: 8,
      boxWidth: 4,
      boxHeight: 4,
      usePointStyle: true,
      cornerRadius: 10,
    },
    legend: {
      display: false,
      position: 'top' as const, //? https://stackoverflow.com/questions/61208792/chart-js-the-types-of-options-legend-position-are-incompatible-between-these,
    },
    title: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
};

export const barOptions = {
  maintainAspectRatio: false,
  //! Chart.js Bar Options 에서 사용하지 않는 Property
  // hover: {
  //   animationDuration: 100,
  // },
  scales: {
    x: {
      offset: true,
      ticks: {
        // labelOffset: true,
        borderColor: colors.info,
        color: colors.info,
        font: {
          size: 15,
        },
        padding: 0,
      },
      grid: {
        display: false,
        drawBorder: false,
        color: colors.bg_checkbox,
      },
      datalabels: {
        display: false,
      },
    },

    y: {
      ticks: {
        // labelOffset: true,
        borderColor: colors.info,
        color: colors.info,
        font: {
          size: 15,
        },
        padding: 10,
      },
      grid: {
        drawBorder: false,
        color: colors.bg_checkbox,
      },
    },
  },
  plugins: {
    tooltip: {
      ...tooltip,
      usePointStyle: false,
    },
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
};

export const radarOptions = {
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    mode: 'index',
  },
  // hover: {
  //   animationDuration: 100,
  // },
  scales: {
    r: {
      min: 0,
      max: 100,
      pointLabels: {
        padding: 15,
        color: colors.info,
        font: {
          family: "'SpoqaHanSansNeo'",
          size: 15,
        },
      },
      ticks: {
        display: false,

        stepSize: 20,
        showLabelBackdrop: false,
        backdropColor: 'rgba(203, 197, 11, 1)',
      },
      angleLines: {
        color: '#7f7c89',
        lineWidth: 1,
      },
      grid: {
        lineWidth: 1,
        color: colors.border_light,
      },
    },
  },
  plugins: {
    tooltip,
    legend: {
      display: true,
      position: 'top' as const,
      align: 'start',
      labels: {
        // usePointStyle: true,
        boxWidth: 28,
        boxHeight: 3,

        font: {
          size: 15,
        },
        color: colors.text,
      },
    },
    datalabels: {
      display: false,
    },
  },
};

export const playerRadarOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index' as const,
  },
  // hover: {
  //   animationDuration: 100,
  // },
  scales: {
    r: {
      min: 0,
      max: 10,
      pointLabels: {
        padding: 18,
        color: colors.text,
        font: {
          family: "'Spoqa Han Sans'",
          size: 15,
        },
      },
      ticks: {
        display: false,
        // callback: function (value, index, ticks) {
        //   if (index === 0 || index === 2 || index === 7 || index === 10) {
        //     return value;
        //   }
        // },
      },
      angleLines: {
        color: colors.border_light,
        lineWidth: 1,
      },
      grid: {
        lineWidth: 1,
        color: colors.border_light,
      },
    },
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
    datalabels: {
      backgroundColor: 'rgba(20,10,57,0.6)',
      borderColor: colors.point,
      borderWidth: 2,
      borderRadius: 10,
      padding: 5,
      color: colors.text,
      anchor: 'end' as const,
      align: 'end' as const,
      offset: -2,
      // formatter: function (value, context) {
      //   if (isNaN(value)) return;
      //   return value.toFixed(1);
      // },
    },
  },
};

export const prlineOption = {
  responsive: true,
  maintainAspectRatio: false,
  // hover: {
  //   animationDuration: 100,
  // },
  scales: {
    x: {
      offset: true,

      ticks: {
        color: colors.info,
        font: {
          size: 15,
        },
      },
      grid: {
        color: colors.bg_checkbox,
      },
    },
    y: {
      beginAtZero: true,
      suggestedMin: 0,
      suggestedMax: 10,
      ticks: {
        // labelOffset: true,
        color: colors.info,
        stepSize: 2,
        font: {
          size: 15,
        },
      },
      grid: {
        drawBorder: false,
        color: colors.bg_checkbox,
      },
    },
  },
  plugins: {
    tooltip,
    legend: {
      display: false,
      position: 'top' as const,
    },
    title: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
};

export const totalTrendOption = {
  responsive: true,
  maintainAspectRatio: false,
  // hover: {
  //   animationDuration: 100,
  // },
  scales: {
    x: {
      offset: true,
      ticks: {
        stepSize: 5,
        beginAtZero: true,
        color: colors.info,
        font: {
          size: 15,
        },
        callback: (value: any, index: number, values: any) => {
          return index + 1;
        },
      },
      grid: {
        color: colors.bg_checkbox,
      },
    },
    y: {
      beginAtZero: true,
      suggestedMin: 0,
      suggestedMax: 10,
      ticks: {
        // labelOffset: true,
        color: colors.info,
        stepSize: 2,
        font: {
          size: 15,
        },
      },
      grid: {
        drawBorder: false,
        color: colors.bg_checkbox,
      },
    },
  },
  plugins: {
    tooltip,
    legend: {
      display: false,
      position: 'top' as const,
    },
    title: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
};
