/** @jsxImportSource @emotion/react */
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useRecoilValue } from 'recoil';
import { useMutation } from 'react-query';
import timeFormat from '../../../lib/timeFormat';
import PathSlider from '../../../Components/Ui/MUI/PathSlider';
import { spacing } from '../../../Styles/ui';
import { useDetectOutside } from '../../../Hooks/useDetectedOutside';
import { PathAnalysisResultAtom } from '../../../recoil/Filter/pathAnalysisAtom';
import WardPathingService, { IGetChampionParam, IPlayer } from '../../../services/WardPathingService';

interface P {
  compareOpen: boolean;
  setCompareOpen: React.Dispatch<React.SetStateAction<boolean>>;
  minFrom: number[];
  setMinFrom: React.Dispatch<React.SetStateAction<number[]>>;
  selectedTeam: string;
  selectedPlayer: string;
  setSelectedPlayer: React.Dispatch<React.SetStateAction<string>>;
  selectedChampArray: string[];
  setSelectedChampArray: React.Dispatch<React.SetStateAction<string[]>>;
}

const WardPlayerFilter: React.FC<P> = ({
  compareOpen,
  setCompareOpen,
  minFrom,
  setMinFrom,
  selectedTeam,
  selectedPlayer,
  setSelectedPlayer,
  selectedChampArray,
  setSelectedChampArray,
}) => {
  const result = useRecoilValue(PathAnalysisResultAtom)!;
  const dropdownRef = useRef(null);
  const { t } = useTranslation();

  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [isActive5, setIsActive5] = useState(false);
  const [filterData, setFilterData] = useState({});
  const ref1 = useDetectOutside(setIsActive1);
  const ref2 = useDetectOutside(setIsActive2);
  const ref3 = useDetectOutside(setIsActive3);
  const ref4 = useDetectOutside(setIsActive4);
  const ref5 = useDetectOutside(setIsActive5);

  const [playerArray, setPlayerArray] = useState<string[]>([]);
  const [championArray, setChampionArray] = useState<string[]>([]);

  // 챔피언 선택
  const clickChampionConfirm = () => {
    setIsActive2(false);
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setMinFrom(newValue as number[]);
  };

  // mappingfilter/player response 가공
  const refinePlayerData = (data: IPlayer[]) => {
    const refined = [];
    for (let i = 0; i < data.length; i += 1) {
      refined.push(data[i].name);
    }
    return refined;
  };

  // player
  const { mutate: getPlayer } = useMutation(
    () =>
      WardPathingService.getPlayerInWardMapping({
        ...result,
        team: selectedTeam,
      }),
    {
      onSuccess: (data) => {
        const refinedData = refinePlayerData(data.response);
        setPlayerArray(refinedData);
      },
    },
  );

  // champion
  const { mutate: getChampion } = useMutation(
    (data: IGetChampionParam) => WardPathingService.getChampion(data),
    {
      onSuccess: (data) => {
        setChampionArray(data.response.championEng);
      },
    },
  );

  //? oppteam
  // const {
  //   mutate: getOppTeam,
  //   data,
  //   isLoading,
  //   isError,
  // } = useMutation(({ team, player, champion }: IOppTeamParam) =>
  //   JunglePathingServices.getOppTeam({
  //     ...result,
  //     team,
  //     player,
  //     champion,
  //   }),
  // );

  // const getOppTeam = () => {
  //   try {
  //     const url = `${API}/lolapi/mappingfilter/oppteam`;
  //     const params = {
  //       league: filters.league,
  //       year: filters.year,
  //       season: filters.season,
  //       patch: filters.patch,
  //       team: filters.team,
  //       player: selectedPlayer,
  //       champion: selectedChampArray,
  //       token: user.token,
  //       id: user.id,
  //     };
  //     axiosRequest(
  //       undefined,
  //       url,
  //       params,
  //       function (e) {
  //         console.log('oppteam', e);
  //         setFilterData({ ...filterData, oppteam: e });
  //       },
  //       function (objStore) {
  //         dispatch(SetModalInfo(objStore)); // 오류 발생 시, Alert 창을 띄우기 위해 사용
  //       },
  //     );
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  //? oppplayer
  // const refineOppPlayerData = (data) => {
  //   const refined = [];
  //   for (let i = 0; i < data.length; i += 1) {
  //     refined.push(data[i].oppplayer);
  //   }
  //   return refined;
  // };

  // const getOppPlayer = () => {
  //   try {
  //     const url = `${API}/lolapi/mappingfilter/oppplayer2`;
  //     const params = {
  //       league: filters.league,
  //       year: filters.year,
  //       season: filters.season,
  //       patch: filters.patch,
  //       team: filters.team,
  //       player: selectedPlayer,
  //       champion: selectedChampArray,
  //       oppteam: filters.oppteam,
  //       token: user.token,
  //       id: user.id,
  //     };
  //     axiosRequest(
  //       undefined,
  //       url,
  //       params,
  //       function (e) {
  //         const refinedData = refineOppPlayerData(e);
  //         setFilterData({ ...filterData, oppplayer: refinedData });
  //       },
  //       function (objStore) {
  //         dispatch(SetModalInfo(objStore)); // 오류 발생 시, Alert 창을 띄우기 위해 사용
  //       },
  //     );
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  //? oppchampion
  // const getOppChampion = () => {
  //   try {
  //     const url = `${API}/lolapi/mappingfilter/oppchampion`;
  //     const params = {
  //       league: filters.league,
  //       year: filters.year,
  //       season: filters.season,
  //       patch: filters.patch,
  //       team: filters.team,
  //       player: selectedPlayer,
  //       champion: selectedChampArray,
  //       oppteam: filters.oppteam,
  //       oppplayer: filters.oppplayer,
  //       token: user.token,
  //       id: user.id,
  //     };
  //     axiosRequest(
  //       undefined,
  //       url,
  //       params,
  //       function (e) {
  //         const data = e.championEng;
  //         setFilterData({ ...filterData, oppchampion: data });
  //       },
  //       function (objStore) {
  //         dispatch(SetModalInfo(objStore)); // 오류 발생 시, Alert 창을 띄우기 위해 사용
  //       },
  //     );
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const pushChampion = (champion: string) => {
    if (selectedChampArray.find((e) => e === champion) && champion !== 'all' && champion !== 'none') {
      setSelectedChampArray(selectedChampArray.filter((e) => e !== champion));
    } else if (selectedChampArray.length >= 0 && champion !== 'all' && champion !== 'none') {
      setSelectedChampArray([...selectedChampArray.filter((e) => e !== ''), champion]);
    } else if (champion === 'all') {
      setSelectedChampArray(championArray);
    } else if (champion === 'none') {
      setSelectedChampArray([]);
    }
  };

  return (
    <WardPlayerFilterContainer>
      <Steps>
        <div className='title'>
          <span className='step'>STEP 01</span>
          <span className='subtitle'>{t('video.vision.label1')}</span>
        </div>
        <FilterBox>
          {/* step1 - 기준 데이터 선택 */}
          <DropDownBox>
            {/* 팀 (고정) */}
            <DropDownToggle className='container' changeColor={selectedTeam.length > 0}>
              <div className='menu-container'>
                <div className='menu-trigger' style={{ background: 'transparent' }}>
                  <span className='Label'>{selectedTeam !== '' ? selectedTeam : t('video.vision.team')}</span>
                </div>
              </div>
            </DropDownToggle>

            {/* 선수 선택 */}
            <DropDownToggle className='container' changeColor={selectedPlayer.length > 0} ref={ref1}>
              <div className='menu-container2'>
                <button
                  type='button'
                  onClick={() => {
                    setIsActive1(true);
                    getPlayer();
                  }}
                  className='menu-trigger2'
                >
                  <span className='Label2'>
                    {selectedPlayer !== '' ? selectedPlayer : t('video.vision.player')}
                  </span>
                  <img className='ArrowIcon' src='/Images/select-arrow.png' alt='arrowIcon' />
                </button>

                {/* 선수리스트 */}
                {isActive1 && (
                  <nav ref={dropdownRef} className={`menu2 ${isActive1 ? 'active' : 'inactive'}`}>
                    <ul>
                      {playerArray?.map((playerName: string) => {
                        return (
                          <li key={`player_${playerName}`}>
                            <button
                              onClick={() => {
                                setSelectedPlayer(playerName);
                                setSelectedChampArray([]);
                                setIsActive1(!isActive1);
                              }}
                              type='button'
                            >
                              {playerName}
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </nav>
                )}
              </div>
            </DropDownToggle>

            {/* 챔피언 선택 */}
            <DropDownToggle className='container' changeColor={selectedChampArray.length > 0} ref={ref2}>
              <div className='menu-container2'>
                <button
                  type='button'
                  onClick={() => {
                    setIsActive2(!isActive2);
                    getChampion({
                      ...result,
                      team: selectedTeam,
                      player: selectedPlayer,
                    });
                  }}
                  className='menu-trigger2'
                >
                  {selectedChampArray && selectedChampArray.length !== 0 ? (
                    <span className='Label3'>
                      <span className='champLength'>{`${selectedChampArray.length} `}</span>
                      {` ${t('video.object.champ')}`}
                    </span>
                  ) : (
                    <span className='Label3'>{t('video.object.selectChamp')}</span>
                  )}
                  <img className='ArrowIcon' src='/Images/select-arrow.png' alt='arrowIcon' />
                </button>

                {/* 챔피언 중복선택 */}
                {championArray ? (
                  <nav ref={dropdownRef} className={`menu3 ${isActive2 ? 'active' : 'inactive'}`}>
                    <ul>
                      <Menu3li
                        onClick={() =>
                          pushChampion(championArray?.length === selectedChampArray.length ? 'none' : 'all')
                        }
                        isChecked={championArray?.length === selectedChampArray.length}
                      >
                        <input
                          checked={championArray?.length === selectedChampArray.length}
                          type='checkbox'
                          readOnly
                        />
                        Select All
                      </Menu3li>

                      <div className='hr-line' />
                      {championArray?.map((championName, idx) => {
                        return (
                          <Menu3li
                            key={`champion_${championName}`}
                            onClick={() => pushChampion(championName)}
                            isChecked={selectedChampArray.includes(championName)}
                          >
                            <input
                              checked={selectedChampArray.includes(championName)}
                              type='checkbox'
                              readOnly
                            />
                            {championName}
                          </Menu3li>
                        );
                      })}
                      <button
                        type='button'
                        onClick={() => {
                          clickChampionConfirm();
                        }}
                      >
                        {t('video.object.confirm')}
                      </button>
                    </ul>
                  </nav>
                ) : (
                  ''
                )}
              </div>
            </DropDownToggle>
          </DropDownBox>

          {/* step2 - 상대 데이터 선택 */}
          <DropDownBox2 isActive={compareOpen === true}>
            {/* 상태팀 선택 */}
            {/* <DropDownToggle className='container' changeColor={filters.oppteam.length > 0} ref={ref3}> */}
            <div className='menu-container'>
              {/* <button
                  type='button'
                  onClick={() => {
                    setIsActive3(!isActive3);
                    getOppTeam();
                  }}
                  className='menu-trigger'
                >
                  <span className='Label'>
                    {filters.oppteam !== '' ? filters.oppteam : t('video.vision.team2')}
                  </span>
                  <img className='ArrowIcon' src='/Images/select-arrow.png' alt='arrowIcon' />
                </button> */}

              {/* 상대팀 리스트 */}
              {/* <nav ref={dropdownRef} className={`menu ${isActive3 ? 'active' : 'inactive'}`}>
                  <ul>
                    {filterData?.oppteam?.map((oppteamName) => {
                      return (
                        <li key={`oppteam_${oppteamName}`}>
                          <button
                            type='button'
                            onClick={() => {
                              dispatch(
                                Reset_Map({
                                  league: filters.league,
                                  year: filters.year,
                                  season: filters.season,
                                  patch: filters.patch,
                                  team: filters.team,
                                  player: selectedPlayer,
                                  champion_eng: selectedChampArray,
                                  oppteam: oppteamName,
                                  oppplayer: '',
                                  oppchampion_eng: '',
                                }),
                              );
                            }}
                          >
                            {oppteamName}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </nav> */}
            </div>
            {/* </DropDownToggle> */}

            {/* 상대팀 선수 선택  */}
            {/* <DropDownToggle className='container' changeColor={filters.oppplayer.length > 0} ref={ref4}>
              <div className='menu-container2'>
                <button
                  type='button'
                  onClick={() => {
                    setIsActive4(!isActive4);
                    getOppPlayer();
                  }}
                  className='menu-trigger2'
                >
                  <span className='Label2'>
                    {filters.oppplayer !== '' ? filters.oppplayer : t('video.vision.player2')}
                  </span>
                  <img className='ArrowIcon' src='/Images/select-arrow.png' alt='arrowIcon' />
                </button>
                <nav ref={dropdownRef} className={`menu2 ${isActive4 ? 'active' : 'inactive'}`}>
                  <ul>
                    {filterData?.oppplayer?.map((oppplayer, idx) => {
                      return (
                        <li key={idx}>
                          <button
                            type='button'
                            onClick={() => {
                              dispatch(
                                Reset_Map({
                                  league: filters.league,
                                  year: filters.year,
                                  season: filters.season,
                                  patch: filters.patch,
                                  team: filters.team,
                                  player: selectedPlayer,
                                  champion_eng: selectedChampArray,
                                  oppteam: filters.oppteam,
                                  oppplayer,
                                  oppchampion_eng: '',
                                }),
                              );
                            }}
                          >
                            {oppplayer}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </nav>
              </div>
            </DropDownToggle> */}

            {/* 상대팀 챔피언 선택 */}
            {/* <DropDownToggle className='container' changeColor={filters.oppchampion_eng.length > 0} ref={ref5}> */}
            <div className='menu-container2'>
              {/* <button
                  type='button'
                  onClick={() => {
                    setIsActive5(!isActive5);
                    getOppChampion();
                  }}
                  className='menu-trigger2'
                >
                  <span className='Label2'>
                    {filters.oppchampion_eng !== '' ? filters.oppchampion_eng : t('video.vision.champ2')}
                  </span>
                  <img className='ArrowIcon' src='/Images/select-arrow.png' alt='arrowIcon' />
                </button> */}

              {/* 상대팀 챔피언 리스트 */}
              {/* <nav ref={dropdownRef} className={`menu2 ${isActive5 ? 'active' : 'inactive'}`}>
                  <ul>
                    {filterData?.oppchampion?.map((oppchampionName, idx) => {
                      return (
                        <li key={`oppchampion_${oppchampionName}`}>
                          <button
                            type='button'
                            onClick={() => {
                              dispatch(
                                Reset_Map({
                                  league: filters.league,
                                  year: filters.year,
                                  season: filters.season,
                                  patch: filters.patch,

                                  team: filters.team,
                                  player: selectedPlayer,
                                  champion_eng: selectedChampArray,
                                  oppteam: filters.oppteam,
                                  oppplayer: filters.oppplayer,
                                  oppchampion_eng: filters.oppchampion,
                                }),
                              );
                              setIsActive5(!isActive5);
                            }}
                          >
                            {oppchampionName}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </nav> */}
            </div>
            {/* </DropDownToggle> */}
          </DropDownBox2>
        </FilterBox>
      </Steps>

      {/* step 3 - 구간 슬라이드 */}
      <Steps>
        <div className='title2'>
          <span className='step'>STEP 02</span>
          <span className='subtitle'>{t('video.vision.label2')}</span>
        </div>
        <SliderContainer className='slider-container'>
          <PathSlider
            min={0}
            max={100}
            value={minFrom}
            onChange={handleChange}
            valueLabelDisplay='on'
            aria-labelledby='range-slider'
            getAriaValueText={timeFormat.ward}
            valueLabelFormat={timeFormat.ward}
          />
        </SliderContainer>
        <DefaultTime>
          <DisplayTime>00:00</DisplayTime>
          <DisplayTime>08:30</DisplayTime>
        </DefaultTime>
      </Steps>
    </WardPlayerFilterContainer>
  );
};

export default WardPlayerFilter;

const SliderContainer = styled.div``;

const WardPlayerFilterContainer = styled.div``;

const DefaultTime = styled.div`
  ${spacing.marginT(-3)}
  ${spacing.marginX(-1)}
  display: flex;
  justify-content: space-between;
`;

const DisplayTime = styled.div`
  font-family: SpoqaHanSansNeo;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.87;
  letter-spacing: normal;
  text-align: center;
  color: #6b6979;
`;

const Steps = styled.div`
  min-height: 111px;
  padding: 0px 23px 20px;
  :nth-of-type(2) {
    border-bottom: none;
  }

  > .title {
    display: flex;
    font-family: SpoqaHanSansNeo;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 12px;

    > .step {
      font-weight: normal;
      color: #84818e;
      margin-right: 5px;
    }
    > .subtitle {
      color: rgb(255, 255, 255);
    }
  }

  > .title2 {
    display: flex;
    font-family: SpoqaHanSansNeo;
    font-family: SpoqaHanSansNeo;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 40px;

    > .step {
      font-weight: normal;
      color: #84818e;
      margin-right: 5px;
    }
    > .subtitle {
      color: rgb(255, 255, 255);
    }
  }
`;

const FilterBox = styled.div``;

const DropDownBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 4px;
`;

const DropDownBox2 = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 4px;
  opacity: 0;
  max-height: 0px;
  overflow: hidden;
  width: 100%;
  transition: all 0.2s ease;

  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
      max-height: 1000px;
    `}
`;

// const CompareButton = styled.button`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 517px;
//   height: 34px;
//   border: solid 1px rgb(67, 63, 78);
//   background-color: rgb(67, 63, 78);
//   border-radius: 10px;
//   > span {
//     font-family: SpoqaHanSansNeo;
//     font-size: 13px;
//     font-weight: normal;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: normal;
//     letter-spacing: normal;
//     text-align: center;
//     color: #fff;
//   }
//   img {
//     margin-left: 10px;
//   }
// `;

const DropDownToggle = styled.div<{ changeColor: boolean }>`
  margin-right: 4px;
  :nth-of-type(3) {
    margin-right: 0px;
  }

  padding: 0;
  * {
    box-sizing: border-box;
  }

  body {
    font-family: Arial, Helvetica, sans-serif;
  }

  .menu-container {
  }

  .menu-container2 {
    position: relative;
  }
  .menu-trigger {
    display: flex;
    align-items: center;
    width: 142px;
    height: 34px;
    background-color: rgb(35, 33, 42);
    font-family: SpoqaHanSansNeo;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    outline: none;
    border: none;
  }

  .menu-trigger2 {
    display: flex;
    align-items: center;
    width: 183px;
    height: 34px;
    background-color: rgb(35, 33, 42);
    font-family: SpoqaHanSansNeo;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.3);
    outline: none;
    border: none;
    border-radius: 10px;
  }

  .SelectedLabel {
    font-family: SpoqaHanSansNeo;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    width: 142px;
    margin-left: 20px;
  }

  .SelectedLabel2 {
    font-family: SpoqaHanSansNeo;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    width: 183px;
    margin-left: 20px;
  }

  .Label {
    font-family: SpoqaHanSansNeo;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => (props.changeColor ? `rgb(255, 255, 255)` : `rgba(255, 255, 255, 0.3)`)};
    width: 142px;
  }

  .Label2 {
    font-family: SpoqaHanSansNeo;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => (props.changeColor ? `rgb(255, 255, 255)` : `rgba(255, 255, 255, 0.3)`)};
    width: 183px;
  }

  .Label3 {
    font-family: SpoqaHanSansNeo;
    font-size: 12px;
    letter-spacing: -0.6px;
    text-align: left;
    color: ${(props) => (props.changeColor ? `rgb(255, 255, 255)` : `#84818e`)};
    width: 183px;
  }

  .champLength {
    color: #f04545;
  }

  .Wrapper {
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    :hover {
      background-color: rgb(60, 58, 72);
    }
    img {
      margin: 0 5px 0 15px;
    }
  }

  .ChampIconImg {
    margin: 0 8px 0 4px;
  }

  .menu {
    background: #23212a;
    border-radius: 10px;
    position: absolute;
    top: 10;
    right: 1;
    width: 142px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
    z-index: 10;
  }

  .menu2 {
    background: #23212a;
    border-radius: 10px;
    position: absolute;
    top: 10;
    right: 1;
    width: 183px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
    z-index: 10;
  }

  .menu3 {
    background: #23212a;
    border-radius: 10px;
    position: absolute;
    top: 10;
    right: 1;
    width: 183px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
    z-index: 10;
  }

  .menu.active {
    opacity: 5;
    visibility: visible;
    transform: translateY(0);
    z-index: 10111;
  }

  .menu2.active {
    opacity: 5;
    visibility: visible;
    transform: translateY(0);
    z-index: 10111;
  }

  .menu3.active {
    opacity: 5;
    visibility: visible;
    transform: translateY(0);
    z-index: 10111;
  }

  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 99999;
  }

  .menu2 ul {
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 99990;
  }

  .menu3 ul {
    list-style: none;
    padding: 0;
    margin: 0px 8px;
    z-index: 99990;
  }

  .menu li {
    text-decoration: none;
    padding: 15px 20px;
    display: block;
    width: 142px;
    height: 40px;

    font-family: SpoqaHanSansNeo;
    font-size: 13px;
    letter-spacing: -0.55px;
    text-align: left;
    color: rgb(255, 255, 255);
    cursor: pointer;
    z-index: 9999;
    :hover {
      background-color: rgb(60, 58, 72);
      border-radius: 20px;
    }
  }

  .menu2 li {
    text-decoration: none;
s   padding: 4px;
    display: block;
    width: 183px;
    height: 40px;
    font-family: SpoqaHanSansNeo;
    font-size: 13px;
    letter-spacing: -0.55px;
    text-align: left;
    color: rgb(255, 255, 255);
    cursor: pointer;
    z-index: 9999;
    :hover {
      background-color: rgb(60, 58, 72);
      color: rgb(255, 255, 255);
      border-radius: 20px;
    }
  }

  .menu2 button {
    text-decoration: none;
    display: block;
    height: 30px;
    text-align: left;
    width:184px;
    border-radius: 10px;
    font-family: SpoqaHanSansNeo;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    cursor: pointer;
    z-index: 9999;
  }

  .menu3 button {
    text-decoration: none;
    display: block;
    margin: 10px auto;
    width: 164px;
    height: 30px;
    border-radius: 10px;
    background-color: #5942ba;
    font-family: SpoqaHanSansNeo;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    cursor: pointer;
    z-index: 9999;
  }

  .hr-line {
    width: 100%;
    border-bottom: solid 1px rgb(67, 63, 78);
    margin: 5px 0 7px;
  }
`;

const Menu3li = styled.li<{ isChecked: boolean }>`
  margin-top: 4px;
  text-decoration: none;
  padding: 3px 5px;
  display: flex;
  width: 100%;
  font-family: SpoqaHanSansNeo;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  cursor: pointer;
  z-index: 9999;

  ${(props) =>
    props.isChecked &&
    css`
      color: rgb(255, 255, 255);
      background-color: rgba(22, 21, 28, 0.5);
      border-radius: 10px;
    `}
  >  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    display: inline-block;
    width: 20px;
    height: 20px;

    background-clip: content-box;
    background: url('/Images/btn_check_off.svg') no-repeat;
    margin-right: 8px;

    &:checked {
      background-color: #5942ba;
      border: #5942ba;
      border-radius: 2px;
      background: url('/Images/btn_check_on.svg') no-repeat;
      float: right;
    }

    &:focus {
      outline: none !important;
    }
  }
  :hover {
    border-radius: 10px;
    background-color: #3a3745;
  }
`;
