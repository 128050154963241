/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../Styles/ui';
import Avatar from '../../../Components/Ui/Avatar';
import IconStar from '../../../Components/Ui/Icons/IconStar';

const FSHeader = () => {
  const { t } = useTranslation();

  return (
    <SWrapper>
      <SAlter>
        <Avatar
          css={{
            marginRight: '5px',
            marginTop: '3px',
          }}
          src='/Images/ico-notice-gy.png'
          size={14}
        />
        <IconStar inactiveColor={colors.info} />
        {t('soloRank.searchFilter.desc.guideBookmark')}
      </SAlter>
    </SWrapper>
  );
};

export default FSHeader;

const SWrapper = styled.div`
  width: 1120px;
  height: auto;
  margin: 20px 0 20px;
  //padding: 22px 100px 21px;
  border-radius: 20px;
`;

const SAlter = styled.div`
  display: flex;
  margin-top: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.border_light};

  font-family: SpoqaHanSansNeo;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.info};
`;
