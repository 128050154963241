import { atom } from 'recoil';

export type OriginalChampionObject = { ckey: number; cname: string; cid: string };

type ChampionList = {
  patch: string;
  list: OriginalChampionObject[];
};

export const ChampionListAtom = atom<{ [key: string]: { ko: string; en: string } }>({
  key: '@chmpionlist',
  default: undefined,
});

export const LOLPatchAtom = atom<string>({
  key: '@lolpatch',
  default: undefined,
});

export const OriginalChampData = atom<ChampionList>({
  key: '@originalChampData',
  default: undefined,
});
