/* eslint-disable no-restricted-syntax */

import { FilterData, IFilterSeason } from '../recoil/Filter/leagueReportAtom';

/**
 * @desc boolean 값을 가지는 객체를 돌면서 true인 key값을 배열로 리턴하는 함수
 * @param {{[key:string] : boolean}} obj [key: string] value: boolean 인 객체
 * @returns {string[]} 객체의 value가 true인 key값의 배열
 */
export const getTrueValueList = (obj: FilterData | undefined) => {
  if (typeof obj !== 'object') return undefined;
  const trueArray = [];
  for (const key in obj) {
    if (obj[key] === true) {
      trueArray.push(key);
    }
  }
  return trueArray;
};

export const getTrueValueSeasonList = (seasonArray: IFilterSeason | undefined) => {
  if (typeof seasonArray !== 'object') return undefined;
  const trueSeasonArray: { [key: string]: string[] }[] = [];

  const SeasonKeys: Array<keyof typeof seasonArray> = Object.keys(seasonArray);
  SeasonKeys.forEach((seasonKey) => {
    const season = { ...seasonArray[seasonKey] };
    const patchKeys = Object.keys(season.patch);
    const trueSeason: { [key: string]: string[] } = {};
    const truePatch: string[] = [];
    patchKeys.forEach((patchKey) => {
      // console.log('seasonKey', seasonKey);
      const patch = season.patch[patchKey];
      if (patch) {
        truePatch.push(patchKey);
      }
    });
    if (truePatch.length > 0) {
      trueSeason[`${seasonKey}`] = truePatch;
      trueSeasonArray.push(trueSeason);
    }
  });
  console.log('trueSeasonArray', trueSeasonArray);
  return trueSeasonArray;
};
