/**
 *
 * @param total 전체경기수
 * @param pick 전체 픽 횟수
 * @param win 전체 승리 횟수
 * @desc 전체게임중에 해당 챔피언이 픽 + 밴 된 확률을 구합니다.
 */
export function getChampionTier(score: number) {
  if (Number.isNaN(Number(score))) {
    console.warn('score 타입 확인');
    return 'No Tier';
  }

  if (Number(score) >= 29.5) {
    return 0;
  }
  if (Number(score) >= 19.5) {
    return 1;
  }
  if (Number(score) >= 9.5) {
    return 2;
  }
  return 3;
}
