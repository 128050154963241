import React, { useState } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled/macro';
import { useRecoilValue } from 'recoil';
import transferValuetoWidth from '../../../../lib/transferValuetoWidth';
import thousand from '../../../../../../lib/thousand';
import { RootState } from '../../../../../../redux/modules';

import GameDatasetAtom from '../../../../../../recoil/ProGameReport/gameDatasetAtom';
import GameAtom from '../../../../../../recoil/ProGameReport/gameAtom';

const detailBoxWidth = 253;

interface getStandardValueObject {
  max: number;
  curMax: number;
  cur: number;
}

const BlueChampBox = ({ detailTab }: { detailTab: number }) => {
  const fixedDataset = useRecoilValue(GameDatasetAtom.fixedDataset);
  const liveDataset = useRecoilValue(GameDatasetAtom.liveDataset);
  const teamGoldDataset = useRecoilValue(GameDatasetAtom.teamGoldDataset);
  const goldActiveIdx = useRecoilValue(GameAtom.goldActiveIdx);
  const liveActiveIdx = useRecoilValue(GameAtom.liveActiveIdx);

  function getStandardValue(detailTab: number, positionIdx: number) {
    const { detail } = fixedDataset[0].players[positionIdx];
    const obj: getStandardValueObject = {
      max: 0,
      curMax: 0,
      cur: 0,
    };

    if (detailTab === 1) {
      const { golds } = teamGoldDataset[goldActiveIdx];
      const maxGolds = teamGoldDataset[teamGoldDataset.length - 1].golds;
      const gold = golds[positionIdx];
      obj.max = Math.max.apply(null, maxGolds);
      obj.curMax = maxGolds[positionIdx];
      obj.cur = gold;
    } else if (detailTab === 2) {
      const wards = liveDataset[liveActiveIdx - 1 < 0 ? 0 : liveActiveIdx].players;
      const ward = wards.map((data) => data.wardsPlaced);
      const maxWard = fixedDataset[0].players
        .map((data) => data.detail.wardsplaced)
        .concat(fixedDataset[1].players.map((data) => data.detail.wardsplaced));
      obj.max = Math.max.apply(null, maxWard);
      obj.curMax = maxWard[positionIdx];
      obj.cur = ward[positionIdx];
    } else if (detailTab === 3) {
      const csSet = liveDataset[liveActiveIdx - 1 < 0 ? 0 : liveActiveIdx].players;
      const cs = csSet.map((data) => data.cs);
      const maxCs = fixedDataset[0].players
        .map((data) => data.detail.cs)
        .concat(fixedDataset[1].players.map((data) => data.detail.cs));
      obj.max = Math.max.apply(null, maxCs);
      obj.curMax = maxCs[positionIdx];
      obj.cur = cs[positionIdx];
    }
    return obj;
  }

  return (
    <>
      {fixedDataset[0].players.map((data, idx) => {
        const detailObj = getStandardValue(detailTab, idx);
        return (
          <div className='champ-box'>
            <img src={`/Images/champion/${data.info.championEng}.png`} alt='champion' />
            <div className='bar-box'>
              <div className='value blue-text'>
                {`${thousand(detailObj.cur)}`}
                <span className='max'>/{`${thousand(detailObj.max)}`}</span>
              </div>
              <Bar
                curMax={transferValuetoWidth(detailObj.max, detailBoxWidth, detailObj.curMax)}
                cur={transferValuetoWidth(detailObj.max, detailBoxWidth, detailObj.cur)}
              >
                <div className='bar common-max' />
                <div className='bar blue-player-max' />
                <div className='bar blue-player-cur' />
              </Bar>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default BlueChampBox;

const Bar = styled.div<{ curMax: number; cur: number }>`
  position: relative;

  .bar {
    position: absolute;
    height: 6px;
    margin: 3px 0 0;
    padding: 0 0px 0 0;
    border-radius: 10px;
  }

  .common-max {
    width: 253px;
    background-color: #3a3745;
  }

  .blue-player-max {
    width: ${(props) => props.curMax}px;
    background-color: #234e69;
  }

  .blue-player-cur {
    width: ${(props) => props.cur}px;
    background-color: #0075bf;
  }
`;
