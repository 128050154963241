import { MAP_POSITON } from './mapping/map_position';

interface IGetPositonParam {
  role: 1 | 2 | 3 | 4 | 5;
  lang?: string;
}

type IGetPositionResult = 'top' | 'jng' | 'mid' | 'bot' | 'sup';

export const getPositon: (param: IGetPositonParam) => IGetPositionResult = ({ role, lang }) =>
  MAP_POSITON[role];
