import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from '@emotion/styled/macro';
import { t } from 'i18next';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { TRANSLATE_HOME_TITLE } from '../../../../lib/translation/Home/translate_home';
import { useTab } from '../../../../Hooks';
import { useSchedule } from './userSchedule';
import { scrollbarStyle } from '../../../../Styles/ui';
import Progress from '../../../../Components/Ui/Loading/Progress';
import NotFound from '../../../../Components/Ui/Error/NotFound';
import DateListRenewal from './DateListRenewal';
import { SELECTED_YEAR } from '../../../config';

const LEAGUES: string[] = [
  'LCK',
  'LCS',
  'LEC',
  'LPL',
  'LCK CL',
  'LCL',
  'LCO',
  'LJL',
  'LLA',
  'CBLOL',
  'MSI',
  'PCS',
  'TCL',
  'LFL',
];

interface HomeScheduleData {
  winner: string;
  awayTeam: {
    teamName: string;
    score: number;
    teamShort: string;
    winRate: number;
  };
  homeTeam: {
    teamName: string;
    score: number;
    teamShort: string;
    winRate: number;
  };
  time: string;
  title: string;
}

// 인덱스 찾기 => 현재 날짜의 unix time과 주어진 데이터 값의 키와 비교
const getRecentDateIndex = (data) => {
  let index;
  const now = dayjs().format('YYYY-MM-DD');
  data.some((daylist, i) => {
    index = i;
    return Number(daylist[0].time) - dayjs(now).unix() >= 0;
  });
  return index;
};

//* dayjs에서 utc 와 timezone 확장
dayjs.extend(timezone);
dayjs.extend(utc);

const HomeAIRenewal = () => {
  const [todayIndex, setTodayIndex] = useState(0);
  const { currentIndex, setIndex, currentTab } = useTab(0, LEAGUES);
  const scrollContainerRef = useRef<HTMLUListElement>(null);
  const dayListRef = useRef<HTMLLIElement[] | []>([]);

  const currentTabName = currentTab.toLowerCase();
  const handleTab = (index: number) => setIndex(index);

  //* fetch schedule data
  const { data: HomeSchedule, isLoading, isError } = useSchedule(currentTabName, SELECTED_YEAR);

  const refinedSchedule = HomeSchedule?.map((scheduleItem) => {
    const newScheduleItem = { ...scheduleItem };
    const utcTime = dayjs.unix(Number(scheduleItem.time)).utc(true).tz(dayjs.tz.guess()).format('YYYY-MM-DD');
    const localeUnix = dayjs(utcTime).unix();

    newScheduleItem.time = localeUnix.toString();
    newScheduleItem.league = currentTab;
    return newScheduleItem;
  });

  //! 데이터 타입 설정: HomeSchedule의 API 데이터부터 다시 설정 필요해보임.
  const dateObj = refinedSchedule?.reduce((a, b) => {
    if (Object.prototype.hasOwnProperty.call(a, b.time)) {
      a[b.time].push(b);
    } else {
      a[b.time] = [b];
    }
    return a;
  }, {});

  useEffect(() => {
    if (!dateObj || Object.keys(dateObj).length === 0) return;
    const index = getRecentDateIndex(Object.values(dateObj));
    setTodayIndex(index ?? 0);
  }, [dateObj]);

  // 오늘 or 다음경기 찾아서 스크롤
  useLayoutEffect(() => {
    if (!scrollContainerRef.current || !dayListRef.current[todayIndex]) return;
    const { top: containerTop } = scrollContainerRef.current.getBoundingClientRect();
    const { top: childTop } = dayListRef.current[todayIndex].getBoundingClientRect();
    scrollContainerRef.current.scrollTo({ top: childTop - containerTop });

    // ? 동작하지 않는 부분
    // () => {
    //   if (!scrollContainerRef.current) return;
    //   scrollContainerRef.current.scrollTo({ top: 0 });
    // };
  }, [todayIndex]);

  return (
    <HomeAIWrapper>
      <div
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          marginBottom: 20,

          display: 'flex',
        }}
      >
        <span style={{ marginRight: 5 }}>{t(TRANSLATE_HOME_TITLE.리그일정)}</span>

        <div
          style={{
            fontSize: 13,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#84818e',
          }}
        >
          <img src='/Images/ico-notice-gy.png' alt='notice' />
          <span style={{ marginLeft: 5 }}>{t(TRANSLATE_HOME_TITLE.알림)}</span>
        </div>
      </div>

      {/* 리그 탭 */}
      <Stack spacing={1} direction='row' justifyContent='space-around'>
        {LEAGUES.map((league, idx) => {
          return (
            <Button
              key={`${league}_rank`}
              variant='contained'
              color={currentIndex === idx ? 'primary' : 'secondary'}
              onClick={() => {
                handleTab(idx);
              }}
            >
              <img
                src={`/Images/league/ico_league_${league.toLowerCase()}.png`}
                alt='leagueLogo'
                width='20px'
              />
              <span style={{ marginLeft: 2, whiteSpace: 'nowrap' }}>{league}</span>
            </Button>
          );
        })}
      </Stack>

      {/* AI 일정 */}
      <Contents>
        {/* converted UI */}
        <ScrollContainer ref={scrollContainerRef}>
          {isLoading ? (
            <ProgressContainer>
              <Progress text={t('solo.playerboard.dataFetching')} />
            </ProgressContainer>
          ) : isError ? (
            <NotFound css={{ height: '100%' }} text={t('solo.playerboard.dataFetchingFailed')} />
          ) : (
            dateObj &&
            Object.values(dateObj)?.map((item, idx) => {
              return (
                <DateListRenewal
                  key={`${currentTabName}_${idx}`}
                  ref={(el: HTMLLIElement) => {
                    dayListRef.current[idx] = el;
                  }}
                  list={item}
                />
              );
            })
          )}
        </ScrollContainer>
      </Contents>
    </HomeAIWrapper>
  );
};

export default HomeAIRenewal;

const HomeAIWrapper = styled.section`
  width: 1137px;
  color: white;
  margin: 30px 0;
`;

const Contents = styled.div`
  margin-top: 24px;
`;

const ScrollContainer = styled.ul`
  height: 534px;
  ${scrollbarStyle.scroll_4}
`;

const ProgressContainer = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;
