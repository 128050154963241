/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutateFunction, useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';
import * as S from '../styled/MTStyledTable';
import IconStar from '../../../../Components/Ui/Icons/IconStar';
import Avatar from '../../../../Components/Ui/Avatar';
import Arrow from '../../../../Components/Ui/Arrow';
import IconDel from '../../../../Components/Ui/Icons/IconDel';
import { typoStyle } from '../../../../Styles/ui';
import { useModal } from '../../../../Hooks';
import { modalList } from '../../../../Components/Modals/Modals';
import { getRank, getRankLp, getTier } from '../../../../lib/getRank';
import { API, recentVersion } from '../../../config';
import axiosRequest from '../../../../lib/axios/axiosRequest';
import { SetModalInfo } from '../../../../redux/modules/modalvalue';
import { Loading } from '../../../../redux/modules/filtervalue';
import { getPositon } from '../../../../lib/getPosition';
import Progress from '../../../../Components/Ui/Loading/Progress';
import limitCharNum from '../../lib/limitCharNum';
import SolorankServices, {
  FavoritePlayer,
  IResponseSuccess,
  Players,
} from '../../../../services/SoloRankServices';
import { LOLPatchAtom } from '../../../../recoil/common/championListAtom';
import useChampionImage from '../../../../Hooks/useChampionImage';

const SRPlayerList = ({
  getInfoFunc,
  info,
  usingBookmark,
  usingDeletePlayer,
  usingDeletePlayerGuide,
  usingDeleteSoloId,
  usingAddSoloId,
}: {
  getInfoFunc: UseMutateFunction<IResponseSuccess<any>, unknown, void, unknown>;
  info: Players;
  usingBookmark: boolean;
  usingDeletePlayer: boolean;
  usingDeletePlayerGuide: boolean;
  usingDeleteSoloId: boolean;
  usingAddSoloId: boolean;
}) => {
  const { getChampionImagePathByName } = useChampionImage();

  const {
    add,
    player,
    bookmark,
    position: role,
    player: nickName,
    playChampion,
    soloRankInfo,
    cdCount,
    cdName,
    cdTier,
    cdRank,
    cdLeaguePoints,
    cdSeasonTotal,
    cdSeasonWin,
    cdSeasonLose,
    cdSeasonWinrate,
    cdLastDayTotal,
    cdLastDayWin,
    cdLastDayLose,
    cdLastDayWinrate,
    allSummonerIds,
    team,
  } = info;
  const { t } = useTranslation();
  const { openModal } = useModal();
  const patch = useRecoilValue(LOLPatchAtom);

  console.log('role', role);

  // 선수id로 솔로랭크 조회
  // ui 열고 닫기 상태
  const [isOpen, setIsOpen] = useState(true);

  // 즐겨찾기 기능 관련
  const [isLike, setIsLike] = useState(bookmark !== 0);

  // close 시 데이터 셋

  const { mutate: getBookmarkAdd, isAddLoading } = useMutation(
    (name: string) =>
      SolorankServices.getBookmarkAdd({
        player: name,
      }),
    {
      onSuccess: (data) => {
        const e = data.response;
        console.log(e);
      },
    },
  );

  const { mutate: getBookmarkDrop, isDropLoading } = useMutation(
    (name: string) =>
      SolorankServices.getBookmarkDrop({
        player: name,
      }),
    {
      onSuccess: (data) => {
        const e = data.response;
        console.log(e);
        getInfoFunc();
      },
    },
  );

  // getSummonerAdd
  const { mutate: getSummonerAdd } = useMutation(
    (data: FavoritePlayer) => SolorankServices.getSummonerAdd(data),
    {
      onSuccess: (data) => {
        const e = data.response;
        console.log(e);
        getInfoFunc();
      },
    },
  );

  const { mutate: getSummonerDrop } = useMutation(
    (data: { summonerId: string[] }) => SolorankServices.getSummonerDrop(data),
    {
      onSuccess: (data) => {
        const e = data.response;
        console.log(e);
        getInfoFunc();
      },
    },
  );

  const handleFavorite = (e) => {
    const { name, checked, value } = e.target;
    console.log(name, checked, value);

    if (checked) {
      getBookmarkAdd(name);
    } else {
      getBookmarkDrop(name);
    }
    setIsLike(checked);
  };

  // 선수삭제
  const handleDelete = (summonerId: string) => {
    const title = t('soloRank.myTeam.label.deleteSoloRankId');
    const text = t('soloRank.myTeam.desc.deleteSoloRankId');
    const subtext = '';
    const onCancel = () => {
      return '';
    };
    const onSubmit = () => {
      const params = {
        summonerId: [summonerId],
      };
      getSummonerDrop(params);
    };
    openModal(modalList.confirm, { title, text, subtext, onCancel, onSubmit });
  };
  // 선수등록
  const handleClickModalOpen = (e) => {
    openModal(modalList.addSolorankID, {
      onSubmit: (e) => {
        const params = {
          player,
          team,
          role,
          summonerId: e.summonerId,
          puuId: e.puuId,
        };
        getSummonerAdd(params);
      },
    });
  };

  const handleDeletePlayer = (allSummonerIds: string[]) => {
    const title = t('soloRank.myTeam.label.deletePlayer');
    const text = t('soloRank.myTeam.desc.deletePlayer');
    const subtext = '';
    const onCancel = () => {
      return '';
    };
    const onSubmit = () => {
      const params = {
        summonerId: allSummonerIds,
      };
      console.log('allSummonerIds', allSummonerIds);
      getSummonerDrop(params);
    };
    openModal(modalList.confirm, { title, text, subtext, onCancel, onSubmit });
  };

  useEffect(() => {}, []);

  return (
    // 관심선수
    <S.TableItemRow>
      {/* 선수정보 & 등록 */}
      <div className='table-item-col1'>
        <S.InfoId>
          {usingBookmark && (
            <S.Star name={player} checked={isLike} onChange={handleFavorite}>
              <IconStar isActive={isLike} />
            </S.Star>
          )}
          <span>{`${team} ${t(`position.${getPositon({ role })}`)}`}</span>
          <h4 css={typoStyle.noWrap}>{nickName}</h4>
          {usingDeletePlayer && (
            <div
              css={css`
                margin: 8px 4px 31px 0px;
                display: flex;
              `}
            >
              <S.DeletePlayerButton
                disabled={add === 0}
                added={add === 1}
                onClick={() => {
                  if (allSummonerIds) handleDeletePlayer(allSummonerIds);
                }}
              >
                {t('soloRank.myTeam.label.deletePlayer')}
              </S.DeletePlayerButton>
              {usingDeletePlayerGuide && (
                <Avatar
                  css={css`
                    margin-left: 5px;
                    margin-top: 5px;
                  `}
                  src='/Images/ico-notice-gy.png'
                  size={14}
                />
              )}
            </div>
          )}

          {/* <h5>{name}</h5> */}
        </S.InfoId>
      </div>

      {/* col-2 솔로랭크 정보 */}
      <ul className='table-item-col2'>
        {isOpen ? (
          // 오픈 ui
          <>
            {soloRankInfo.length > 0 &&
              soloRankInfo.map((data, i) => {
                return Math.abs(data.gathering) === 1 ? (
                  <S.OpenList key={`header${i}`}>
                    {/* 아이디 */}
                    <div className='table-col2'>
                      <span css={typoStyle.noWrap}>{data.summonerName ?? ''}</span>
                    </div>
                    {/* 티어 */}
                    <div className='table-col3'>
                      <p>{`${getRankLp(data.rank, data.tier, data.leaguePoints)}`}</p>
                      {/* <span>{`S11 challenger / S10 Challenger`}</span> */}
                    </div>
                    {/* 이번시즌 */}
                    <div className='table-col4'>
                      <span>{`${data.lastSeason ? data.lastSeason.total : 0}${t('common.label.game')}`}</span>
                      <span>{`${data.lastSeason ? data.lastSeason.win : 0}${t('common.label.win')} ${
                        data.lastSeason ? data.lastSeason.lose : 0
                      }${t('common.label.lose')}`}</span>
                    </div>
                    {/* 시즌 승률 */}
                    <div className='table-col5'>{`${Math.round(+data.lastSeason.winrate ?? 0)}%`}</div>
                    {/* 최근 */}
                    <div className='table-col6'>
                      <span>{`${data.lastDay ? +data.lastDay.total : 0}${t('common.label.game')}`}</span>
                      <span>{`${data.lastDay ? +data.lastDay.win : 0}${t('common.label.win')} ${
                        data.lastDay ? +data.lastDay.lose : 0
                      }${t('common.label.lose')}`}</span>
                    </div>
                    {/* 최근 승률 */}
                    <div className='table-col7'>
                      {`${Math.round(data.lastDay ? +data.lastDay.winrate : 0)}%`}
                    </div>

                    {/* 선수삭제 버튼 */}
                    {usingDeleteSoloId && data.add === 1 && (
                      <button
                        type='button'
                        onClick={() => {
                          handleDelete(data.summonerId);
                        }}
                      >
                        <IconDel />
                      </button>
                    )}
                  </S.OpenList>
                ) : (
                  <S.OpenList2 key={`header${i}`}>
                    {/* 아이디 */}
                    <div className='table-col2 _div'>
                      <span css={typoStyle.noWrap}>{data.summonerName ?? ''}</span>
                    </div>
                    <div
                      css={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Progress text={t('soloRank.myTeam.label.progress')} />
                    </div>
                  </S.OpenList2>
                );
              })}

            {/* 버튼 - 선수 추가 modal */}
            {usingAddSoloId && soloRankInfo.length < 6 && (
              <S.AddPlayer onClick={handleClickModalOpen}>
                <button type='button'>+</button>
                <div>
                  <p>{t('soloRank.myTeam.label.addSoloRankID')}</p>
                  <span>{t('soloRank.myTeam.desc.addSoloRankID')}</span>
                </div>
              </S.AddPlayer>
            )}
          </>
        ) : (
          // 클로즈 ui
          <S.CloseList>
            <div className='table-col2'>
              <span css={[typoStyle.ellipsis_two, { width: 100 }]}>
                {cdName ? `${limitCharNum(cdName)}(${cdCount})` : ''}
              </span>
            </div>
            {/* 티어 */}
            <div className='table-col3'>
              <p>{`${getRankLp(cdRank, cdTier, cdLeaguePoints)}`}</p>
              {/* <span>{`S11 challenger / S10 Challenger`}</span> */}
            </div>
            {/* 이번시즌 */}
            <div className='table-col4'>
              <span>{`${cdSeasonTotal}${t('common.label.game')}`}</span>
              <span>{`${cdSeasonWin}${t('common.label.win')} ${cdSeasonLose}${t('common.label.lose')}`}</span>
            </div>
            {/* 시즌 승률 */}
            <div className='table-col5'>{`${cdSeasonWinrate}%`}</div>
            {/* 최근 */}
            <div className='table-col6'>
              <span>{`${cdLastDayTotal}${t('common.label.game')}`}</span>
              <span>{`${cdLastDayWin}${t('common.label.win')} ${cdLastDayLose}${t(
                'common.label.lose',
              )}`}</span>
            </div>
            {/* 최근 승률 */}
            <div className='table-col7'>{`${cdLastDayWinrate}%`}</div>
          </S.CloseList>
        )}
      </ul>

      {/* col3 - 최근 30일간 플레이한 챔피언 */}
      <S.ChampList className='table-item-col3' isOpen={isOpen}>
        {/* #반복 - 챔피언 리스트 */}
        {playChampion.length > 0 &&
          playChampion.map((data, idx) => {
            return (
              <S.ChampListItem>
                <S.ChampInfo>
                  {isOpen && <S.ChampLabel>Most {idx + 1}</S.ChampLabel>}
                  <S.ChampInfoText>
                    <Avatar
                      size={isOpen ? 34 : 24}
                      src={getChampionImagePathByName(data.championEng)}
                      alt='data.champlion'
                    />
                    <div>
                      {isOpen && <h6>{data.champion}</h6>}
                      {isOpen ? (
                        <p css={typoStyle.noWrap}>
                          {`${data.total}${t('common.label.game')} ${data.win}${t('common.label.win')} ${
                            data.lose
                          }${t('common.label.lose')}`}
                          <em>{`${Math.round(data.winrate)}%`}</em>
                        </p>
                      ) : (
                        <p>{`${data.total}${t('common.label.game')}`}</p>
                      )}
                    </div>
                  </S.ChampInfoText>
                </S.ChampInfo>
              </S.ChampListItem>
            );
          })}

        {/* <S.ChampListItem>
          <S.ChampInfo>
            {isOpen && <S.ChampLabel>Most 1</S.ChampLabel>}
            <S.ChampInfoText>
              <Avatar
                size={isOpen ? 34 : 24}
                src="/Images/champion/nunu.png"
                alt="누누"
              />
              <div>
                {isOpen && <h6>블라디미르</h6>}
                {isOpen ? (
                  <p css={typoStyle.noWrap}>
                    {`${100}경기 ${50}승 ${50}패`}
                    <em>{` ${(5 / 10) * 100}%`}</em>
                  </p>
                ) : (
                  <p>{`${100}경기`}</p>
                )}
              </div>
            </S.ChampInfoText>
          </S.ChampInfo>
        </S.ChampListItem> */}
      </S.ChampList>

      {/* 우측버튼 - close <-> open */}
      <S.ToggleMenuButton onClick={() => setIsOpen(!isOpen)}>
        <Arrow direction={isOpen ? 'T' : 'B'} size={5} />
      </S.ToggleMenuButton>
    </S.TableItemRow>
  );
};

export default SRPlayerList;
