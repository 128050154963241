import dayjs from 'dayjs';
import { atom } from 'recoil';

interface ScrimData {
  date: number;
  oppleague: string;
  oppteam: string;
  oppteamid: 100 | 200;
}

export const ScrimAtom = {
  oppleague: atom<string>({
    key: '@scrim/oppleague',
    default: undefined,
  }),

  oppTeam: atom<string>({
    key: '@scrim/oppTeam',
    default: undefined,
  }),

  oppSide: atom<100 | 200 | undefined>({
    key: '@scrim/side',
    default: 100,
  }),
};

export const ScrimViewAtom = {
  isRefetch: atom<boolean>({
    key: '@scrim/view/refetch',
    default: false,
  }),
  startDate: atom<Date>({
    key: '@scrim/view/startdate',
    default: undefined,
  }),
  endDate: atom<Date>({
    key: '@scrim/view/endDate',
    default: new Date(),
  }),
  oppTeam: atom<string>({
    key: '@scrim/view/oppTeam',
    default: '',
  }),
  date: atom<number[]>({
    key: '@scrim/view/date',
    default: undefined,
  }),
};

export const ScrimCalendarAtom = {
  startDate: atom<Date>({
    key: '@scrim/calendar/startdate',
    default: undefined,
  }),
  endDate: atom<Date>({
    key: '@scrim/calendar/endDate',
    default: undefined,
  }),
};

export const ScrimFilterResult = atom<ScrimData>({
  key: '@scrim/result/filter',
  default: undefined,
});

export const ScrimGameResult = atom<ScrimData>({
  key: '@scrim/result/game',
  default: undefined,
});

export default ScrimAtom;
