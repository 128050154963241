import React, { useEffect, useState } from 'react';
import { Button, styled as MUIstyled } from '@mui/material';
import styled from '@emotion/styled';
import { transitionStyle, typoStyle } from '../../../Styles/ui';

// 포지션 정보
const POSITION = {
  1: {
    text: 'top',
    value: 1,
    img: <img src='/Images/position/ico-position-top.svg' alt='top' />,
  },
  2: {
    text: 'jng',
    value: 2,
    img: <img src='/Images/position/ico-position-jng.svg' alt='jng' />,
  },
  3: {
    text: 'mid',
    value: 3,
    img: <img src='/Images/position/ico-position-mid.svg' alt='mid' />,
  },
  4: {
    text: 'bot',
    value: 4,
    img: <img src='/Images/position/ico-position-bot.svg' alt='bot' />,
  },
  5: {
    text: 'sup',
    value: 5,
    img: <img src='/Images/position/ico-position-sup.svg' alt='sup' />,
  },
};

interface PositionCheckBoxProps {
  error?: boolean;
  iconStyle?: { [key: string]: any };
  boxStyle?: { [key: string]: any };
  position: number;
  onChange: (pos: number) => void;
}

function PositionCheckBox({ position, error, boxStyle, iconStyle, onChange }: PositionCheckBoxProps) {
  const handleClick = (pos: number) => {
    if (onChange) {
      onChange(pos);
    }
  };

  return (
    <div>
      <SList>
        {Object.values(POSITION).map((pos) => {
          return (
            <SListItem key={`position_${pos.text}`}>
              <BUTTON_POSITION
                style={boxStyle}
                error={pos.value === position && error}
                isActive={pos.value === position}
                variant='contained'
                color='secondary'
                onClick={() => handleClick(pos.value)}
              >
                <SIcon style={iconStyle}>{pos.img}</SIcon>
              </BUTTON_POSITION>
            </SListItem>
          );
        })}
      </SList>
    </div>
  );
}

const SList = styled.ul`
  display: flex;
`;

const BUTTON_POSITION = MUIstyled(Button)(
  ({ error, isActive }: { error: boolean | undefined; isActive: boolean }) => `
  opacity: 0.3;
  padding: 0;
  min-width: 0;

  ${
    isActive &&
    `opacity: 1;
    background-color: rgba(89,66,186,0.2);
    border: 1px solid #5942ba;`
  }

  ${
    error &&
    `
    opacity: 1;
    background-color: rgba(240,69,69,0.2);
    border: 1px solid #f04545;
    `
  }
  
    

  `,
);

const SIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${typoStyle.button_popin};

  span {
    line-height: 0;
    opacity: 0.3;
    ${transitionStyle.opacity}
    img {
      width: 21px;
      height: 21px;
    }
  }

  &:hover {
    span {
      opacity: 1;
    }
  }
`;

const SListItem = styled.li`
  :not(:last-of-type) {
    margin-right: 3px;
  }
`;

export default PositionCheckBox;
