import { AxiosError, AxiosResponse } from 'axios';
import { recentVersion } from '../Pages/config';
import { axiosGet } from './axios/axios';

export interface RuneInfo {
  icon: string;
  id: number;
  key: string;
  name: string;
  slots: Slots[];
}

interface Slots {
  runes: Rune[];
}

interface Rune {
  icon: string;
  id: number;
  key: string;
  lonDesc: string;
  name: string;
  shortDesc: string;
}

class ClientServices {
  static instance: ClientServices;

  fetchFilterData(): Promise<RuneInfo[]> {
    return new Promise((resolve, reject) => {
      fetch(`http://ddragon.leagueoflegends.com/cdn/${recentVersion}/data/ko_KR/runesReforged.json`)
        .then((res) => res.json())
        .then((out) => resolve(out))
        .catch((err) => reject(err));
    });
  }
}

export default new ClientServices();
