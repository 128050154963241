import { useQuery } from 'react-query';
import ClientService, { RuneInfo } from '../services/ClientServices';

export const useRunesCall = () =>
  useQuery(['runes'], () => ClientService.fetchFilterData(), {
    select: (data: RuneInfo[]) => data,
    staleTime: 1000 * 60 * 60 * 3,
    cacheTime: 1000 * 60 * 60 * 3,
    refetchOnWindowFocus: false,
  });
