/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutateFunction, useMutation } from 'react-query';
import SRCategory from './SRCategory';
import SRPlayerList from './SRPlayerList';

// styled components
import * as table from '../styled/MTStyledTable';
import * as layout from '../styled/MTStyledLayout';
import { useModal } from '../../../../Hooks';
import Modals, { modalList } from '../../../../Components/Modals/Modals';
import { API } from '../../../config';

import { Loading } from '../../../../redux/modules/filtervalue';
import { getTrueValueList } from '../../../../lib/getTureValueList';
import getSoloRankInfo from '../../../../lib/soloRank/getSoloRankInfo';
import SoloRankServices, {
  FavoritePlayer,
  IResponseSuccess,
  MyTeamSoloRankInfoResponse,
  Players,
} from '../../../../services/SoloRankServices';

const S = { table, layout };

const transPositionToNum = (position: string) => {
  switch (position) {
    case 'top':
      return 1;
    case 'jng':
      return 2;
    case 'mid':
      return 3;
    case 'bot':
      return 4;
    case 'sup':
      return 5;
    default:
      return 0;
  }
};

const SRContent = ({
  selectedDay,
  setSelectedDay,
  playerInfo,
  myTeamName,
  getInfoFunc,
  usingDeletePlayer = false,
  usingDeletePlayerGuide = false,
  usingBookmark = false,
  usingAddPlayer = false,
  usingAddSoloId = false,
  usingDeleteSoloId = false,
}: {
  selectedDay: string;
  setSelectedDay: React.Dispatch<React.SetStateAction<string>>;
  playerInfo: Players[];
  myTeamName: string;
  getInfoFunc: UseMutateFunction<IResponseSuccess<T>, unknown, void, unknown>;
  usingDeletePlayer: boolean;
  usingDeletePlayerGuide: boolean;
  usingBookmark: boolean;
  usingAddPlayer: boolean;
  usingAddSoloId: boolean;
  usingDeleteSoloId: boolean;
}) => {
  const { openModal } = useModal();
  const { t } = useTranslation();
  const [playerInfoSet, setPlayerInfoSet] = useState(playerInfo);

  // 선수 등록
  const { mutate: addFavoritePlayer } = useMutation(
    (data: FavoritePlayer) => SoloRankServices.getSummonerAdd(data),
    {
      onSuccess: (data) => {
        const e = data.response;
        console.log(e);
        getInfoFunc();
      },
    },
  );

  const handleClick = () => {
    openModal(modalList.addTeamPlayer, {
      onSubmit: (e) => {
        console.log('submit시 action을 등록');
        const position = getTrueValueList(e.position) || [];
        const params = {
          player: e.nickname,
          team: myTeamName,
          role: transPositionToNum(position[0]),
          summonerId: e.selectedSoloRankIds,
          puuId: e.selectedSoloRankPuuids,
        };
        console.log(params);
        addFavoritePlayer(params);
      },
    });
  };

  useEffect(() => {
    const playerInfoCopy = playerInfo;
    playerInfo.map((info, piIdx) => {
      const {
        allName,
        maxTier,
        maxRank,
        maxLP,
        seasonTotal,
        seasonWin,
        seasonlose,
        lastDayTotal,
        lastDayWin,
        lastDayLose,
        allSummonerIds,
        addedCount,
      } = getSoloRankInfo(info.soloRankInfo);

      playerInfoCopy[piIdx] = {
        ...playerInfo[piIdx],
        allSummonerIds,
        add: addedCount === info.soloRankInfo.length ? 1 : 0,
        cdCount: info.soloRankInfo.length,
        cdName: allName.substring(0, allName.length - 1),
        cdTier: maxTier,
        cdRank: maxRank,
        cdLeaguePoints: maxLP,
        cdCalRankPoint: (10 - +maxTier) * 1000 + +maxRank * 100 + +maxLP,
        cdSeasonTotal: seasonTotal,
        cdSeasonWin: seasonWin,
        cdSeasonLose: seasonlose,
        cdSeasonWinrate: seasonTotal !== 0 ? Math.round((seasonWin / seasonTotal) * 100) : 0,
        cdLastDayTotal: lastDayTotal,
        cdLastDayWin: lastDayWin,
        cdLastDayLose: lastDayLose,
        cdLastDayWinrate: lastDayTotal !== 0 ? Math.round((lastDayWin / lastDayTotal) * 100) : 0,
      };

      return '';
    });
    setPlayerInfoSet([...playerInfo]);
  }, [playerInfo]);

  return (
    <S.layout.Container>
      {/* 테이블 */}
      <S.table.Table>
        {/* 테이블 헤더 */}
        <SRCategory
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          playerInfoSet={playerInfoSet}
          setPlayerInfoSet={setPlayerInfoSet}
        />
        <S.table.TableBody>
          {/* 반복 */}
          {playerInfoSet.length > 0 &&
            playerInfoSet.map((info) => {
              return (
                <SRPlayerList
                  usingDeletePlayer={usingDeletePlayer}
                  usingDeletePlayerGuide={usingDeletePlayerGuide}
                  usingBookmark={usingBookmark}
                  usingAddSoloId={usingAddSoloId}
                  usingDeleteSoloId={usingDeleteSoloId}
                  getInfoFunc={getInfoFunc}
                  info={info}
                />
              );
            })}
          {usingAddPlayer && (
            <S.table.AddPlayerPopupButton onClick={handleClick}>
              <span>+</span>
              <div>
                <h5>{t('soloRank.myTeam.label.addPlayer')}</h5>
                <span>{t('soloRank.myTeam.desc.addPlayer')}</span>
              </div>
            </S.table.AddPlayerPopupButton>
          )}
        </S.table.TableBody>
      </S.table.Table>
    </S.layout.Container>
  );
};

export default SRContent;
