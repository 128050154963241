import { styled } from '@mui/system';
import { Slider } from '@mui/material';
import { colors } from '../../../Styles/ui';

const PathSlider = styled(Slider)({
  color: colors.point,
  height: 6,
  fontFamily: 'SpoqaHanSansNeo',

  // 뒷배경
  '& .MuiSlider-rail': {
    color: colors.bg_checkbox,
  },

  // 스위치
  '& .MuiSlider-thumb': {
    height: 11,
    width: 11,
    backgroundColor: '#fff',

    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
    },
  },

  // 툴팁 라벨
  '& .MuiSlider-valueLabel': {
    position: 'relative',
    top: -5,
    fontSize: 12,
    fontWeight: 'bold',
    fontFamily: 'SpoqaHanSansNeo',
    padding: '4px 6px',
    background: colors.point,
    letterSpacing: '0.05em',
  },
});

export default PathSlider;
