import { AxiosError, AxiosResponse } from 'axios';
import { axiosPost } from './axios/axios';
import { GameId } from './types/param_types';

interface GameDetailData {
  gameid?: GameId;
}

export interface GameIndexDataParam {
  league: string[];
  year: string[];
  season: string[];
  patch: string[];
  team: string;
}

interface IResponseSuccess {
  response: any;
  status: string;
  message: string;
}

export interface Mapping {
  realCount: number;
  player: MappingPlayer[];
}

export interface MappingPlayer {
  dead: number;
  hp: number;
  mp: number;
  participantid: number;
  replay: number;
  spell1: number;
  spell2: number;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

export interface Log {
  events: EventsLog[];
}

export interface EventsLog {
  assistingParticipantIds?: number[] | undefined;
  participantid: number;
  realCount: number;
  subType: string;
  type: string;
  victimId: number;
}

export interface Player {
  currentItem: PlayerCurrentItem[];
  event: PlayerEvent[];
  participantId: number;
  position: number;
  purchasedItem: PlayerPurchasedItem[];
  skill: PlayerSkill[];
}

interface PlayerCurrentItem {
  items: number[];
  realCount: number;
  trinket?: number;
  usedGold?: number;
}

interface PlayerEvent {
  assistingParticipantIds: number[];
  participantid: number;
  realCount: number;
  subType: string;
  type: string;
  victimId: number;
}

interface PlayerPurchasedItem {
  realCount: number;
  items: PlayerPurchasedItems[];
}

interface PlayerPurchasedItems {
  count: number;
  itemId: number;
  itemName: string;
  realCount: number;
}

export interface PlayerSkill {
  realCount: number;
  skillId: number;
  timestamp: number;
}

export interface TeamGold {
  blueGold: number;
  blueKills: number;
  realCount: number;
  redGold: number;
  redKills: number;
  golds: number[];
}

export interface Dict {
  bush: string[];
  teleport: string[];
  dive: string[];
  position: string[];
  event: string[];
  fight: string[];
}

export interface Infos {
  BanPick: InfosBanPick;
  players: InfosPlayers[];
  team: string;
  teamid: string;
  teamshort: string;
}

interface InfosBanPick {
  bans: InfosBanPickBans[];
  picks: InfosBanPickPicks[];
}

interface InfosBanPickBans {
  team: number;
  champion: string;
}

export interface InfosBanPickPicks {
  champion: string;
  championKor: string;
  position: number;
  team: number;
  fpick?: number;
}
export interface InfosPlayers {
  detail: InfosPlayersDetail;
  info: InfosPlayersInfo;
  rune: number[];
}

interface InfosPlayersDetail {
  assists: number;
  cs: number;
  damage: number;
  deaths: number;
  kills: number;
  wardskilled: number;
  wardsplaced: number;
  gold: number;
}

interface InfosPlayersInfo {
  champion: string;
  championEng: string;
  participantId: number;
  player: string;
  position: number;
  skills: string[];
  spell1: number;
  spell2: number;
}

export interface Live {
  players: LivePlayers[];
  realCount: number;
  timestamp: number;
}

interface LivePlayers {
  assists: number;
  cs: number;
  CurrentGold: number;
  deaths: number;
  items: string;
  kills: number;
  level: number;
  participantId: number;
  totalGold: number;
  totalGoldEarned: number;
  usedGold: number;
  wardsDestroyed: number;
  wardsPlaced: number;
}

export interface Status {
  players: StatusPlayers[];
  realCount: number;
}

export interface StatusPlayers {
  participantid: number;
  position: number;
  teamid: number;
  type: string;
  team?: number;
}

export interface ActionLog {
  data: ActionLogData;
  realCount: number;
  type: string;
}

export interface ActionLogData {
  participant: number;
  dest: number;
  position: string;
  realCount: number;
  partis: number[];
}

export interface GameDetailDataResponse {
  mapping: Mapping[];
  log: Log;
  players: Player[];
  teamGold: TeamGold[];
  dict: Dict;
  infos: Infos[];
  live: Live[];
  status: Status[];
  actionLog: ActionLog[];
}

export interface GameReportIndexResponse {
  game: GameInfo[];
}

export interface GameInfo {
  date: string;
  gameid: string[];
  game: number;
  gamelength: number[];
  vod: string[];
  starttime: string[];
  oppteamresult: number;
  platform: string[];
  teamresult: number;
  oppside: string[];
  season: string;
  uniqueId: string;
  oppteam: string;
}

export interface TimeLineSet {
  roming?: ActionLog[];
  ganking?: ActionLog[];
  timefight?: ActionLog[];
  blueKills?: EventsLog[];
  redKills?: EventsLog[];
  buildDestroy?: EventsLog[];
  objectKill?: EventsLog[];
  dragonKill?: EventsLog[];
  teamGoldX?: string[];
  teamGoldY?: (number | undefined)[];
  teamGoldMax?: number;
}

interface IGameDetailDataSuccess extends IResponseSuccess {
  response: GameDetailDataResponse;
}

interface IGameIndexDataSuccess extends IResponseSuccess {
  response: GameReportIndexResponse;
}

class GameServices {
  static instance: GameServices;

  getGameDetailData(data: GameDetailData): Promise<IGameDetailDataSuccess> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/match/pga',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  getGameIndexData(data: GameIndexDataParam): Promise<IGameIndexDataSuccess> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/mappingfilter/game',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }
}

export default new GameServices();
