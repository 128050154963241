export const TRANSLATE_BANPICK_REPORT = {
  포지션별분석: 'team.밴픽보고서.포지션별분석',
  서브라인업분석: 'team.밴픽보고서.서브라인업분석',
  포지션픽: 'team.밴픽보고서.포지션픽',
  밴한챔피언: 'team.밴픽보고서.밴한챔피언',
  밴당한챔피언: 'team.밴픽보고서.밴당한챔피언',
  포지션: 'team.밴픽보고서.포지션',
  선수이름: 'team.밴픽보고서.선수이름',
  경기수: 'team.밴픽보고서.경기수',
  승리: 'team.밴픽보고서.승리',
  선픽: 'team.밴픽보고서.선픽',
  후픽: 'team.밴픽보고서.후픽',
  픽: 'team.밴픽보고서.픽',
  PR: 'team.밴픽보고서.PR',
  플레이한챔피언: 'team.밴픽보고서.플레이한챔피언',
  // 포지션
  1: 'team.밴픽보고서.탑',
  2: 'team.밴픽보고서.정글',
  3: 'team.밴픽보고서.미드',
  4: 'team.밴픽보고서.원딜',
  5: 'team.밴픽보고서.서포터',
  경기: 'team.밴픽보고서.경기',
  승: 'team.밴픽보고서.승',
  패: 'team.밴픽보고서.패',
  패배: 'team.밴픽보고서.패배',
  전체경기: 'team.밴픽보고서.전체경기',
  레드: 'team.밴픽보고서.레드',
  블루: 'team.밴픽보고서.블루',
  사이드: 'team.밴픽보고서.사이드',
  페이즈1: 'team.밴픽보고서.1페이즈',
  페이즈2: 'team.밴픽보고서.2페이즈',
  밴: 'team.밴픽보고서.밴',
};
