import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import SelectFilter from '../../Components/SelectFilter/SelectFilter';
import GameReportTab from './GameReportTab';

import ProGameFilter from './ProGameFilter';
import { ProGameReportResultAtom } from '../../recoil/Filter/proGameReportAtom';
import { scrollbarStyle, spacing } from '../../Styles/ui';
import { GameDatasetResultAtom } from '../../recoil/ProGameReport/gameDatasetAtom';
import { GameResultAtom } from '../../recoil/ProGameReport/gameAtom';

function GameReport() {
  const result = useRecoilValue(ProGameReportResultAtom);
  const { t } = useTranslation();
  const resetGameDataset = useResetRecoilState(GameResultAtom);
  const resetGame = useResetRecoilState(GameDatasetResultAtom);

  useEffect(() => {
    resetGameDataset();
    resetGame();
  }, []);

  if (document.title !== `${t('sidebar.part12')} - NUNU.GG`) {
    document.title = `${t('sidebar.part12')} - NUNU.GG`;
  }

  return (
    <>
      <WRAPPER>
        <ProGameFilter />
        <CONTENT>
          <DETAILS>
            {result ? (
              <GameReportTab />
            ) : (
              <SELECTFILTER_WRAPPER>
                <SelectFilter />
              </SELECTFILTER_WRAPPER>
            )}
          </DETAILS>
        </CONTENT>
      </WRAPPER>
    </>
  );
}
export default GameReport;

const WRAPPER = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  background-color: #16151c;
  overflow: hidden;
`;

const CONTENT = styled.div`
  ${spacing.padding(8)}
  flex: 1;
  height: 100vh;
  ${scrollbarStyle.scroll_4}
`;

const DETAILS = styled.div`
  min-width: 1096px;
`;

const SELECTFILTER_WRAPPER = styled.div``;
