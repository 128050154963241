import react, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import ReactPlayer from 'react-player';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import secToMS from '../../../../lib/secToMS';

import GameDatasetAtom from '../../../../recoil/ProGameReport/gameDatasetAtom';
import GameAtom from '../../../../recoil/ProGameReport/gameAtom';
import VideoAtom from '../../../../recoil/ProGameReport/videoAtom';

const VideoPlayer = () => {
  const player = useRef(null);
  const vodUrl = useRecoilValue(VideoAtom.vodUrl);
  const setPlayed = useSetRecoilState(VideoAtom.played);
  const setLoaded = useSetRecoilState(VideoAtom.loaded);
  const setPip = useSetRecoilState(VideoAtom.pip);

  const [playing, setPlaying] = useRecoilState(VideoAtom.playing);
  const [url, setUrl] = useRecoilState(VideoAtom.url);
  const [controls, setControls] = useRecoilState(VideoAtom.controls);
  const [loop, setLoop] = useRecoilState(VideoAtom.loop);
  const [light, setLight] = useRecoilState(VideoAtom.light);
  const [volume, setVolume] = useRecoilState(VideoAtom.volume);
  const [muted, setMuted] = useRecoilState(VideoAtom.muted);
  const [playbackRate, setPlaybackRate] = useRecoilState(VideoAtom.playbackRate);
  const [seeking, setSeeking] = useRecoilState(VideoAtom.seeking);
  const [playedSeconds, setPlayedSeconds] = useRecoilState(VideoAtom.playedSeconds);
  const [duration, setDuration] = useRecoilState(VideoAtom.duration);

  const logDataset = useRecoilValue(GameDatasetAtom.logDataset);
  const liveDataset = useRecoilValue(GameDatasetAtom.liveDataset);
  const playerDataset = useRecoilValue(GameDatasetAtom.playerDataset);
  const teamGoldDataset = useRecoilValue(GameDatasetAtom.teamGoldDataset);
  const statusLogDataset = useRecoilValue(GameDatasetAtom.statusLogDataset);
  const mappingDataset = useRecoilValue(GameDatasetAtom.mappingDataset);
  const playersStatusDataset = useRecoilValue(GameDatasetAtom.playersStatusDataset);
  const startTime = useRecoilValue(GameAtom.startTime);
  const selectedParticipant = useRecoilValue(GameAtom.selectedParticipant);
  const gameTime = useRecoilValue(GameAtom.gameTime);

  const [logIdx, setEventLogActiveIdx] = useRecoilState(GameAtom.eventLogActiveIdx);
  const [statusLogIdx, setStatusLogActiveIdx] = useRecoilState(GameAtom.statusLogActiveIdx);
  const [itemIdx, setItemActiveIdx] = useRecoilState(GameAtom.itemActiveIdx);
  const [liveIdx, setLiveActiveIdx] = useRecoilState(GameAtom.liveActiveIdx);
  const [goldIdx, setGoldActiveIdx] = useRecoilState(GameAtom.goldActiveIdx);
  const [mappingActiveIdx, setMappingActiveIdx] = useRecoilState(GameAtom.mappingActiveIdx);
  const [playersActiveIdx, setPlayersActiveIdx] = useRecoilState(GameAtom.playersActiveIdx);
  const [seekTime, setSeekTime] = useRecoilState(GameAtom.seekTime);

  // video option

  const endTime = +startTime + +gameTime;
  const startPlayed = +startTime / +duration;
  const sec5 = 5;
  const sec30 = 30;
  const currentTime = Math.floor(playedSeconds - +startTime);
  const curTime = currentTime < 0 ? 0 : currentTime;
  const moveMin = parseFloat(+startTime) / duration;
  const moveMax = parseFloat(+endTime) / duration;
  // log option
  const eventLogDataset = logDataset.events;
  const currentItemDataset = playerDataset[selectedParticipant].currentItem;

  const datasets = [
    eventLogDataset,
    currentItemDataset,
    liveDataset,
    teamGoldDataset,
    statusLogDataset,
    mappingDataset,
    playersStatusDataset,
  ];

  useEffect(() => {
    if (seekTime > 0) {
      handleSeekTime();
    }
  }, [seekTime]);

  const load = (url) => {
    setUrl(url);
    setPlayed(0);
    setLoaded(0);
    setPip(false);
  };

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleStop = () => {
    setUrl('');
    setPlaying(false);
  };

  const handleToggleControls = () => {
    setControls(!controls).then(() => load(url));
  };

  const handleToggleLight = () => {
    setLight(!light);
  };

  const handleToggleLoop = () => {
    setLoop(!loop);
  };

  const handleVolumeChange = (e) => {
    setVolume(parseFloat(e.target.value));
  };

  const handleToggleMuted = () => {
    setMuted(!muted);
  };

  const handleSetPlaybackRate = (e) => {
    setPlaybackRate(parseFloat(e.target.value));
  };

  const handleOnPlaybackRateChange = (speed) => {
    setPlaybackRate(parseFloat(speed));
  };

  // const handleTogglePIP = () => {
  //   dispatch(HandleTogglePip(!pip));
  // };

  const handlePlay = () => {
    console.log('onPlay');
    setPlaying(true);
  };

  const handleEnablePIP = () => {
    console.log('onEnablePIP');
    setPip(true);
  };

  const handleDisablePIP = () => {
    console.log('onDisablePIP');
    setPip(false);
  };

  const handlePause = () => {
    console.log('onPause');
    setPlaying(false);
  };

  const handleSeekMouseDown = (e) => {
    setSeeking(false);
  };

  const handleSeekChange = (e) => {
    if (e.target.value !== 0) {
      player.current.seekTo(parseFloat(e.target.value));
      // setSeekTime(parseFloat(e.target.value));
      setPlayed(parseFloat(e.target.value));
    }
  };

  const handleSeekMove = (move) => {
    handleSeekMouseDown();
    const movePlayed = (parseFloat(+playedSeconds) + move) / duration;
    if (moveMin > movePlayed) {
      setPlayed(moveMin);
      setSeeking(false);
      player.current.seekTo(moveMin);
    } else if (moveMax < movePlayed) {
      setPlayed(moveMax);
      setSeeking(false);
      player.current.seekTo(moveMax);
    } else {
      setPlayed(movePlayed);
      setSeeking(false);
      player.current.seekTo(movePlayed);
    }
  };

  const handleSeekTime = () => {
    handleSeekMouseDown();
    const movePlayed = (parseFloat(+startTime) + +seekTime) / duration;
    setPlayed(movePlayed);
    setSeeking(false);
    player.current.seekTo(parseFloat(movePlayed));
    setSeekTime(0);
  };

  const handleSeekMouseUp = (e) => {
    setSeeking(false);
    if (seekTime !== 0) {
      player.current.seekTo(parseFloat(seekTime));
    }
  };

  const handleProgress = (state) => {
    const Idxs = [logIdx, itemIdx, liveIdx, goldIdx, statusLogIdx, mappingActiveIdx, playersActiveIdx];
    const NotHalfSec = [2, 3];

    let checkDatasetIdx = 0;
    let checkIdx = 0;
    // 총 게임 시간 넘어가면 자동으로 멈춤
    if (state.playedSeconds > endTime) {
      setPlaying(false);
    }
    // 이벤트 로그 Idx
    try {
      Idxs.map((value, datasetIdx) => {
        checkDatasetIdx = datasetIdx;
        checkIdx = value;
        const timer = Math.floor(NotHalfSec.includes(datasetIdx) ? curTime / 2 : curTime);
        if (
          Math.floor(
            datasets[datasetIdx][value === datasets[datasetIdx].length ? value - 1 : value].realCount / 2,
          ) < timer
        ) {
          // 현재 인덱스 보다 큰 것들 중에서 찾기
          const eventIdx = value;
          let result = 0;
          for (let idx of datasets[datasetIdx].slice(value).keys()) {
            idx += checkIdx;
            if (
              Math.floor(
                datasets[datasetIdx].length !== idx + 1 && datasets[datasetIdx][idx].realCount / 2,
              ) <= timer
            ) {
              result = idx + 1;
              if (
                Math.floor(
                  datasets[datasetIdx][result === datasets[datasetIdx].length ? result - 1 : result]
                    .realCount / 2,
                ) < timer
              ) {
                continue;
              }
              if (Math.floor(datasets[datasetIdx][result].realCount / 2) > timer) {
                result = result - 1;
              }
            } else if (
              datasets[datasetIdx].length !== idx + 1 &&
              datasets[datasetIdx][idx].realCount / 2 > timer
            ) {
              result = idx - 1;
              if (Math.floor(datasets[datasetIdx][idx + 1].realCount / 2) > timer) {
                // 더할 필요 없는 경우 브레이크
                break;
              }
            } else if (
              Math.floor(datasets[datasetIdx][datasets[datasetIdx].length - 1].realCount / 2) < timer
            ) {
              result = datasets[datasetIdx].length - 1;
            }
            // 이전 idx랑 변환할 idx가 다른지 확인
            if (result !== eventIdx) {
              //console.log("Event Idx:", idx);
              if (result > eventIdx) {
                switch (datasetIdx) {
                  case 0:
                    setEventLogActiveIdx(result);
                    break;
                  case 1:
                    setItemActiveIdx(result);
                    break;
                  case 2:
                    setLiveActiveIdx(result);
                    break;
                  case 3:
                    setGoldActiveIdx(result);
                    break;
                  case 4:
                    setStatusLogActiveIdx(result);
                    break;
                  case 5:
                    setMappingActiveIdx(result);
                    break;
                  case 6:
                    setPlayersActiveIdx(result);
                    break;
                  default:
                    break;
                }
                //console.log("result1 : ", result);
                break;
              }
            }
          }
        } else if (datasets[datasetIdx][value - 1 < 0 ? 0 : value].realCount / 2 >= timer) {
          let eventIdx = value;
          let result = 0;
          for (let idx of datasets[datasetIdx].slice(0, value).keys()) {
            if (eventIdx - 1 >= 0 && Math.floor(datasets[datasetIdx][eventIdx].realCount / 2) > timer) {
              eventIdx = eventIdx - 1;
              if (Math.floor(datasets[datasetIdx][eventIdx === 0 ? 0 : eventIdx].realCount / 2) > timer) {
                continue;
              }
            } else if (
              eventIdx - 1 >= 0 &&
              Math.floor(datasets[datasetIdx][eventIdx].realCount / 2) > timer
            ) {
              eventIdx = eventIdx === 0 ? 0 : eventIdx - 1;
              if (Math.floor(datasets[datasetIdx][eventIdx].realCount / 2) < timer) {
                // 더할 필요 없는 경우 브레이크
                break;
              }
            }
            if (value !== eventIdx) {
              //console.log("Event Idx:", idx);
              result = eventIdx;
              if (value > eventIdx) {
                switch (datasetIdx) {
                  case 0:
                    setEventLogActiveIdx(result);
                    break;
                  case 1:
                    setItemActiveIdx(result);
                    break;
                  case 2:
                    setLiveActiveIdx(result);
                    break;
                  case 3:
                    setGoldActiveIdx(result);
                    break;
                  case 4:
                    setStatusLogActiveIdx(result);
                    break;
                  case 5:
                    setMappingActiveIdx(result);
                    break;
                  case 6:
                    setPlayersActiveIdx(result);
                    break;
                  default:
                    break;
                }
                //console.log("result1 : ", result);
                break;
              }
              break;
            }
          }
        } else if (Math.floor(datasets[datasetIdx][1].realCount / 2) > timer) {
          const result = 0;
          switch (datasetIdx) {
            case 0:
              setEventLogActiveIdx(result);
              break;
            case 1:
              setItemActiveIdx(result);
              break;
            case 2:
              setLiveActiveIdx(result);
              break;
            case 3:
              setGoldActiveIdx(result);
              break;
            case 4:
              setStatusLogActiveIdx(result);
              break;
            case 5:
              setMappingActiveIdx(result);
              break;
            case 6:
              setPlayersActiveIdx(result);
              break;
            default:
              break;
          }
        }
      });
    } catch (e) {
      console.log('checkDatasetIdx: ', checkDatasetIdx);
      console.log('checkIdx: ', checkIdx);
      console.log('ERROR : ', e);
    }
    if (!seeking) {
      setPlayedSeconds(state.playedSeconds);
      setPlayed(state.played);
    }
  };

  const handleEnded = () => {
    console.log('onEnded');
    setPlaying(loop);
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handleOnSeek = (e) => {
    console.log('onSeek', e);
  };

  const ref = (newPlayer) => {
    player.current = newPlayer;
  };

  return (
    <TwtichVideoContainer>
      <ReactPlayer
        url={vodUrl}
        width='1440px'
        height='800px'
        ref={ref}
        className='react-player'
        playing={playing}
        controls={controls}
        light={light}
        loop={loop}
        playbackRate={playbackRate}
        volume={volume}
        muted={muted}
        progressInterval={500}
        onReady={() => console.log('onReady')}
        onStart={() => console.log('onStart')}
        onPlay={handlePlay}
        onEnablePIP={handleEnablePIP}
        onDisablePIP={handleDisablePIP}
        onPause={handlePause}
        onBuffer={() => console.log('onBuffer')}
        onPlaybackRateChange={handleOnPlaybackRateChange}
        onSeek={handleOnSeek}
        onEnded={handleEnded}
        onError={(e) => console.log('onError', e)}
        onProgress={handleProgress}
        onDuration={handleDuration}
      />
      <div className='time-bar'>
        <img
          className='icon'
          onClick={() => {
            handleSeekMove(-sec30);
          }}
          onKeyUp={() => {
            handleSeekMove(-sec30);
          }}
          src='/Images/back_30.svg'
          alt=''
        />
        <img
          className='icon'
          onClick={() => {
            handleSeekMove(-sec5);
          }}
          onKeyUp={() => {
            handleSeekMove(-sec5);
          }}
          src='/Images/back_5.svg'
          alt=''
        />
        <img
          className='icon'
          onClick={handlePlayPause}
          onKeyUp={handlePlayPause}
          src={playing ? '/Images/btn_stop.svg' : '/Images/btn_play.svg'}
          alt=''
        />
        <img
          className='icon'
          onClick={() => {
            handleSeekMove(+sec5);
          }}
          onKeyUp={() => {
            handleSeekMove(+sec5);
          }}
          src='/Images/pre_5.svg'
          alt=''
        />
        <img
          className='icon'
          onClick={() => {
            handleSeekMove(+sec30);
          }}
          onKeyUp={() => {
            handleSeekMove(+sec30);
          }}
          src='/Images/pre_30.svg'
          alt=''
        />
        <div className='volume-box'>
          <img
            className='icon'
            onClick={handleToggleMuted}
            onKeyUp={handleToggleMuted}
            src={volume === 0 || muted ? '/Images/btn_vol-x.svg' : '/Images/btn_vol-1.svg'}
            alt=''
          />
          <input
            className='volume'
            type='range'
            min={0}
            max={1}
            step='any'
            value={volume}
            onChange={handleVolumeChange}
          />
        </div>
        <div className='line-bar'>
          <div className='time-text-box'>
            <span>
              {secToMS(Math.floor(+playedSeconds) - +startTime)} / {secToMS(gameTime)}
            </span>
            {/* * 지울거야
            <div><span>{(Math.floor(+playedSeconds) - +startTime) * 2}</span></div> */}
          </div>
          <input
            className='game-time-bar'
            type='range'
            min={startPlayed}
            max={endTime / +duration}
            step='any'
            value={(+playedSeconds - +startTime) / duration + startPlayed}
            onMouseDown={handleSeekMouseDown}
            onChange={handleSeekChange}
            onMouseUp={handleSeekMouseUp}
          />
        </div>
      </div>
    </TwtichVideoContainer>
  );
};

export default VideoPlayer;

const TwtichVideoContainer = styled.div`
  width: 1440px;
  height: 800px;
  position: relative;

  .time-bar {
    position: absolute;
    width: 1440px;
    height: 67px;
    padding-bottom: 17px;
    padding-left: 20px;
    bottom: 0;
    display: flex;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.74));

    .icon {
      width: 50px;
      height: 50px;
      object-fit: contain;
      cursor: pointer;
    }

    .volume-box {
      position: relative;
      .volume {
        display: none;
        height: 60px;
      }

      :hover {
        .volume {
          display: block;
          position: absolute;
          top: -55px;
          left: -40px;
          writing-mode: bt-lr; /* IE */
          -webkit-appearance: slider-vertical; /* WebKit */
        }
      }
    }

    .line-bar {
      width: 1069px;
      height: 17px;
      margin: 20px 0 13px 20px;
      display: flex;

      .time-text-box {
        width: 79px;
        height: 17px;
        margin: 0 10px 0 0;
        white-space: nowrap;

        span {
          font-family: SpoqaHanSansNeo;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.3;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
        }
      }
      .game-time-bar {
        -webkit-appearance: none; /* Override default CSS styles */
        appearance: none;
        margin-top: 6px;
        width: 90%; /* Full-width */
        height: 6px; /* Specified height */
        background: #d3d3d3; /* Grey background */
        outline: none; /* Remove outline */
        opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
        -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
        transition: opacity 0.2s;
        border-radius: 10px;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        ::-webkit-slider-thumb {
          -webkit-appearance: none; /* Override default look */
          appearance: none;
          width: 25px; /* Set a specific slider handle width */
          height: 6px; /* Slider handle height */
          background: #5942ba; /* Green background */
          box-shadow: -100vw 0 0 100vw #5942ba;
          cursor: pointer; /* Cursor on hover */
        }

        ::-webkit-slider-runnable-track {
          width: 100%;
          height: 6px;
          cursor: pointer;
          animate: 0.2s;
          background: #3a3745;
        }

        ::-moz-range-thumb {
          width: 25px; /* Set a specific slider handle width */
          height: 6px; /* Slider handle height */
          background: #5942ba; /* Green background */
          cursor: pointer; /* Cursor on hover */
        }
      }
    }
  }
`;
