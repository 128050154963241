import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { userLanguageAtom } from '../../../recoil/Auth/userAtom';
import { ChampionListAtom, LOLPatchAtom } from '../../../recoil/common/championListAtom';
import { ITeamDraftPick } from '../../../services/types/team_services.type';
import * as S from './ScrimResultItem.style';
import useParseChampionImagePath from '../../../Hooks/useParseChampionImagePath';

const NOIMG = '/Images/ico-ban-nopick.svg';

interface P {
  pickObj: ITeamDraftPick;
  showName?: boolean;
}

const Pick: React.FC<P> = ({ pickObj, showName }) => {
  console.log(pickObj);
  const lang = useRecoilValue(userLanguageAtom);
  const championList = useRecoilValue(ChampionListAtom);
  const patch = useRecoilValue(LOLPatchAtom);
  const parseChampionImagePath = useParseChampionImagePath(patch, championList);

  const getChampionObj = useCallback(
    (id: number) => {
      return championList[id];
    },
    [championList],
  );
  const parseImgPath = useCallback(
    (champion: string) => parseChampionImagePath(champion),
    [parseChampionImagePath],
  );

  return (
    pickObj && (
      <S.PickWrapper>
        <S.Pick>
          <img src={parseImgPath(pickObj.champion)} alt='' />
        </S.Pick>
        {showName && (
          <S.Name>
            {typeof pickObj.champion === 'string' || typeof pickObj.champion === 'string'
              ? pickObj.champion
              : lang === 'ko'
              ? getChampionObj(pickObj.champion)?.ko
              : getChampionObj(pickObj.champion)?.en}
          </S.Name>
        )}
        {Number(pickObj.fpick) ? (
          <S.FirstPick variant='body1'>{lang === 'ko' ? '선픽' : 'Blind Pick'}</S.FirstPick>
        ) : null}
      </S.PickWrapper>
    )
  );
};

export default Pick;
