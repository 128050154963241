// 이미지 url용
import { MAP_MONSTER_NAME } from './map_monster_name';

export type JungleMonsterNumber = keyof typeof COORDINATES;
export const COORDINATES = {
  NOTFOUND: {
    name: '에러',
    coordinate: { x: -9999, y: -9999 },
  },
  '-1': { name: 'video.jungle.positionTop' },
  '-2': { name: 'video.jungle.positionMid' },
  '-3': { name: 'video.jungle.positionBottom' },
  '-4': { name: 'video.jungle.firstReturn' },
  0: {
    name: MAP_MONSTER_NAME.블루,
    coordinate: { x: 169, y: 314 },
  },
  1: { name: MAP_MONSTER_NAME.심술두꺼비, coordinate: { x: 99, y: 290 } },
  2: {
    name: MAP_MONSTER_NAME.어스름늑대,
    coordinate: { x: 174, y: 380 },
  },
  3: { name: MAP_MONSTER_NAME.칼날부리, coordinate: { x: 330, y: 446 } },
  4: {
    name: MAP_MONSTER_NAME.레드,
    coordinate: { x: 360, y: 510 },
  },
  5: { name: MAP_MONSTER_NAME.돌거북, coordinate: { x: 395, y: 574 } },
  6: { name: MAP_MONSTER_NAME.바위게, coordinate: { x: 153, y: 169 } },
  7: {
    name: MAP_MONSTER_NAME.블루,
    coordinate: { x: 524, y: 364 },
  },
  8: { name: MAP_MONSTER_NAME.심술두꺼비, coordinate: { x: 598, y: 388 } },
  9: {
    name: MAP_MONSTER_NAME.어스름늑대,
    coordinate: { x: 518, y: 300 },
  },
  10: { name: MAP_MONSTER_NAME.칼날부리, coordinate: { x: 364, y: 238 } },
  11: {
    name: MAP_MONSTER_NAME.레드,
    coordinate: { x: 330, y: 171 },
  },
  12: { name: MAP_MONSTER_NAME.돌거북, coordinate: { x: 300, y: 105 } },
  13: {
    name: MAP_MONSTER_NAME.바위게,
    coordinate: { x: 449, y: 422 },
  },

  20: {
    name: MAP_MONSTER_NAME.바다드래곤,
    coordinate: { x: 468, y: 485 },
  },
  30: {
    name: MAP_MONSTER_NAME.협곡의전령,
    coordinate: { x: 226, y: 192 },
  },
  40: {
    name: MAP_MONSTER_NAME.화염드래곤,
    coordinate: { x: 468, y: 485 },
  },
  50: {
    name: MAP_MONSTER_NAME.대지드래곤,
    coordinate: { x: 468, y: 485 },
  },
  60: {
    name: MAP_MONSTER_NAME.내셔남작,
    coordinate: { x: 226, y: 192 },
  },
  70: {
    name: MAP_MONSTER_NAME.바람드래곤,
    coordinate: { x: 468, y: 485 },
  },
  80: {
    name: MAP_MONSTER_NAME.마법공학드래곤,
    coordinate: { x: 468, y: 485 },
  },
  90: {
    name: MAP_MONSTER_NAME.화학공학드래곤,
    coordinate: { x: 468, y: 485 },
  },
};
