import { TRANSLATE_CALENDAR_TITLE } from '../translation/translate_Calendar';

class DateRangeService {
  public NOW;

  public SELECT_DATE_BTN_LIST = [
    { title: TRANSLATE_CALENDAR_TITLE.all, calc: Math.abs(this.getPassedDaysFromNewyear() - 1) * -1 },
    { title: TRANSLATE_CALENDAR_TITLE.recent3days, calc: -3 },
    { title: TRANSLATE_CALENDAR_TITLE.recent5days, calc: -5 },
    { title: TRANSLATE_CALENDAR_TITLE.recent7days, calc: -7 },
    { title: TRANSLATE_CALENDAR_TITLE.recent15days, calc: -15 },
    { title: TRANSLATE_CALENDAR_TITLE.recent1months, calc: -30 },
    { title: TRANSLATE_CALENDAR_TITLE.recent3months, calc: -90 },
  ];

  constructor() {
    this.NOW = new Date();
  }

  dateWithOutTime(date?: Date) {
    const DATE = date ?? new Date();
    return new Date(DATE.getFullYear(), DATE.getMonth(), DATE.getDate());
  }

  clacDate(date: number, startDate?: Date) {
    const DATE = startDate ?? new Date();
    return new Date(DATE.getFullYear(), DATE.getMonth(), DATE.getDate() + date);
  }

  // 당해년도 첫째날 구하기
  getFirstDayofCurrentYear(date?: Date) {
    const DATE = date ?? new Date();
    const result = new Date(DATE.getFullYear(), 0, 1);
    return result;
  }

  // 당해년도 첫째날부터 오늘까지 경과한 일자 구하기
  getPassedDaysFromNewyear(date?: Date) {
    const today = date ?? new Date();
    const firstDayOfCurrentYear = this.getFirstDayofCurrentYear(today);
    const gap = today.getTime() - firstDayOfCurrentYear.getTime();
    const passedDays = Math.ceil(gap / (1000 * 60 * 60 * 24));
    return passedDays;
  }
}

export default DateRangeService;
