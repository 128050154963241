import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import {
  GridColDef,
  GridValidRowModel,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueGetterParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import { userAtom, userLanguageAtom } from '../../../recoil/Auth/userAtom';
import { spacing, typoStyle, colors } from '../../../Styles/ui';
import ScrimService, { ScrimBanpickParams } from '../../../services/ScrimServices';
import { useChampionCall } from '../../../Hooks/useChampionCall';
import { TRANSLATE_SCRIM_TITLE } from '../../../lib/translation/translate_scrim';
import { ChampionListAtom } from '../../../recoil/common/championListAtom';
import LOLfunction from '../../../lib/lolfunction';
import Calendar from '../../test/Calendar';
import CustomDataGrid from '../components/CustomDataGrid';
import ChampionImg from '../../../Components/Champion/ChampionImg';
import ChampionString from '../../../Components/Champion/ChampionString';
import { getWinRate } from '../../../lib/getWinRate';
import { ScrimCalendarAtom } from '../../../recoil/Scrim/ScrimAtom';
import { useUpdateEffect } from '../../../Hooks';
import ScrimMatchUp from '../components/ScrimPrimaryPickTable';

type Position = 'top' | 'jng' | 'mid' | 'bot' | 'sup';
type RowDataset = undefined | GridValidRowModel[];
type WidthOptions = {
  width?: number;
  flex?: number;
};

const POSITON_TAB: { title: Position }[] = [
  { title: 'top' },
  { title: 'jng' },
  { title: 'mid' },
  { title: 'bot' },
  { title: 'sup' },
];

function CustomToolbar(title: string) {
  return (
    <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', padding: '12px 16px' }}>
      <TITLE>{title}</TITLE>

      <GridToolbarExport
        csvOptions={{
          fileName: 'scrim_ban_pick',
          utf8WithBom: true,
        }}
        sx={{
          padding: '4px 12px',
          background: '#484655',
          color: 'white',
        }}
      />
    </GridToolbarContainer>
  );
}

// 스크림밴픽 - 데이터가 없는 경우, 에러 발생, 필터링 결과 없을 경우 띄워주는 에러메세지
const CustomOverlay = (msg: string) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src='/Images/icon/ic_error_page.png' alt='errorImg' />
      <span style={{ marginTop: '10px' }}>{msg}</span>
    </div>
  );
};

const makeCellData = {
  챔피언: (championList: any, lang: string, widthOptions?: WidthOptions): GridColDef => ({
    field: 'champion',
    headerName: t(TRANSLATE_SCRIM_TITLE.champion),
    disableColumnMenu: true,
    filterable: false,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) =>
      lang === 'ko' ? championList[params.id].ko : championList[params.id].en,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ChampionImg
            championId={params.id}
            sx={{
              width: 26,
              height: 26,
              marginRight: 2,
            }}
          />
          <ChampionString championId={params.id} sx={{ fontSize: 15 }} />
        </Box>
      );
    },
    ...widthOptions,
  }),

  픽: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'pick',
    headerName: t(TRANSLATE_SCRIM_TITLE.pick),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    ...widthOptions,
  }),

  밴: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'ban',
    headerName: t(TRANSLATE_SCRIM_TITLE.ban),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    ...widthOptions,
  }),

  픽밴률: (totalGames: number, widthOptions?: WidthOptions): GridColDef => ({
    field: 'pickbanRate',
    headerName: t(TRANSLATE_SCRIM_TITLE.pickBanRate),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    ...widthOptions,
    valueGetter: (params: GridValueGetterParams) =>
      LOLfunction.getBanpickRate(totalGames, params.row.pick, params.row.ban).toFixed(0),
    valueFormatter: (params: GridValueFormatterParams) => `${params.value}%`,
  }),

  승리: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'win',
    headerName: t(TRANSLATE_SCRIM_TITLE.win),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    ...widthOptions,
  }),

  패배: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'lose',
    headerName: t(TRANSLATE_SCRIM_TITLE.lose),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    ...widthOptions,
  }),

  승률: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'winLoseRate',
    headerName: t(TRANSLATE_SCRIM_TITLE.winRate),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    ...widthOptions,
    valueGetter: (params: GridValueGetterParams) =>
      (Number(params.row.win / params.row.pick) * 100).toFixed(0),
    valueFormatter: (params) => `${params.value}%`,
  }),

  스코어: (totalGames: number, widthOptions?: WidthOptions): GridColDef => ({
    field: 'score',
    headerName: t(TRANSLATE_SCRIM_TITLE.score),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    ...widthOptions,
    valueGetter: (params: GridValueGetterParams) =>
      LOLfunction.getChampionScore(totalGames, params.row.pick, params.row.ban, params.row.win),
  }),

  티어: (totalGames: number, widthOptions?: WidthOptions): GridColDef => ({
    field: 'tier',
    headerName: t(TRANSLATE_SCRIM_TITLE.tierRank),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    ...widthOptions,
    valueGetter: (params: GridValueGetterParams) => {
      const score = LOLfunction.getChampionScore(totalGames, params.row.pick, params.row.ban, params.row.win);
      const tier = LOLfunction.getChampionTier(score);
      return tier;
    },
  }),
};

const ScrimBanPick = () => {
  const { isLoading: championLoading } = useChampionCall();
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);
  const championList = useRecoilValue(ChampionListAtom);
  const [queryPosition, setQueryPosition] = useState<Position>('top');
  const [positionNo, setPositionNo] = useState(1);
  const lang = useRecoilValue(userLanguageAtom);

  const [startDate, setStartDate] = useRecoilState(ScrimCalendarAtom.startDate);
  const [endDate, setEndDate] = useRecoilState(ScrimCalendarAtom.endDate);

  const {
    mutate,
    data: banpickData,
    isLoading: banpickLoading,
    isError,
  } = useMutation((data: ScrimBanpickParams) => ScrimService.getScrimBanpick(data), {
    mutationKey: `@scrim/${queryPosition}`,
  });

  console.log(banpickData);

  const totalPick =
    banpickData?.response?.ABP?.length !== 0 &&
    banpickData?.response?.ABP.map((item) => item.picks).reduce((a, b) => a + b);
  const totalGames = totalPick / 2;
  const isLoading = banpickLoading || championLoading;

  const rows전체: RowDataset =
    banpickData?.response &&
    banpickData?.response?.ABP.map((item: any) => {
      return {
        id: item.champion,
        pick: item.picks,
        ban: item.bans,
        win: item.result,
        lose: item.picks - item.result,
      };
    });

  const rows우리팀: RowDataset =
    banpickData?.response &&
    banpickData?.response?.TBP.map((item: any) => {
      return {
        id: item.champion,
        pick: item.picks,
        ban: item.bans,
        win: item.result,
        lose: item.picks - item.result,
      };
    });

  const col전체픽밴: GridColDef[] = championList && [
    makeCellData.챔피언(championList, lang, {
      flex: 100,
    }),
    makeCellData.픽(),
    makeCellData.밴(),
    makeCellData.픽밴률(totalGames),
    makeCellData.승리(),
    makeCellData.패배(),
    makeCellData.승률(),
  ];

  const col우리팀선수픽: GridColDef[] = [
    makeCellData.챔피언(championList, lang, {
      flex: 100,
    }),
    makeCellData.픽(),
    makeCellData.승리(),
    makeCellData.패배(),
    makeCellData.승률(),
  ];

  const col챔피언티어: GridColDef[] = [
    makeCellData.챔피언(championList, lang, {
      flex: 100,
    }),
    makeCellData.픽(),
    makeCellData.밴(),
    makeCellData.승리(),
    makeCellData.픽밴률(totalGames),
    makeCellData.승률(),
    makeCellData.스코어(totalGames),
    makeCellData.티어(totalGames),
  ];

  useUpdateEffect(() => {
    if (!user) return;
    const params = {
      team: user?.teamName,
      startDate,
      endDate,
      position: positionNo,
    };
    mutate(params);
  }, [queryPosition]);

  // mutate : 달력 필터에서 날짜 설정 시
  const handleChange = (date) => {
    if (!user) return;

    setStartDate(dayjs(date[0]).unix());
    setEndDate(dayjs(date[1]).unix());

    const params = {
      team: user?.teamName,
      startDate: date[0] === 0 ? 0 : dayjs(date[0]).unix(),
      endDate: date[1] === 0 ? 0 : dayjs(date[1]).unix(),
      position: positionNo,
    };
    mutate(params);
  };

  return (
    <>
      <Calendar handleChange={handleChange} isSelectDateNeeded />
      {championList && !isLoading && (
        <>
          <ScrimBankPickTabs>
            {POSITON_TAB.map((item, idx) => {
              return (
                <TabItem
                  key={`pick${item.title}`}
                  onClick={() => {
                    setQueryPosition(item.title);
                    setPositionNo(idx + 1);
                  }}
                  changeColor={queryPosition === item.title}
                >
                  <div>
                    <span>{t(`position.${item.title}`)}</span>
                  </div>
                </TabItem>
              );
            })}
          </ScrimBankPickTabs>

          <TableWrapper>
            <TABLE_CONTAINER>
              <Group>
                <HomeTable>
                  {rows전체 && (
                    <CustomDataGrid
                      initialState={{
                        sorting: {
                          sortModel: [{ field: 'pick', sort: 'desc' }],
                        },
                      }}
                      rows={rows전체}
                      columns={col전체픽밴}
                      components={{
                        Toolbar: () => CustomToolbar(t('scrim.banPick.entirePickBan')),
                        // 필터링 결과가 없는 경우
                        NoResultsOverlay: () => CustomOverlay(t(TRANSLATE_SCRIM_TITLE.noFilteredData)),
                        // 데이터가 빈배열인 경우
                        NoRowsOverlay: () => CustomOverlay(t(TRANSLATE_SCRIM_TITLE.noData)),
                        // 데이터 fetching에 에러 발생 시
                        ErrorOverlay: () => CustomOverlay(t(TRANSLATE_SCRIM_TITLE.errorGenerated)),
                      }}
                    />
                  )}
                </HomeTable>
              </Group>
              <Group>
                <HomeTable>
                  {rows우리팀 && (
                    <CustomDataGrid
                      initialState={{
                        sorting: {
                          sortModel: [{ field: 'pick', sort: 'desc' }],
                        },
                      }}
                      rows={rows우리팀}
                      columns={col우리팀선수픽}
                      components={{
                        Toolbar: () => CustomToolbar(t('scrim.banPick.homeTeamPick')),
                        NoResultsOverlay: () => CustomOverlay(t(TRANSLATE_SCRIM_TITLE.noFilteredData)),
                        NoRowsOverlay: () => CustomOverlay(t(TRANSLATE_SCRIM_TITLE.noData)),
                        ErrorOverlay: () => CustomOverlay(t(TRANSLATE_SCRIM_TITLE.errorGenerated)),
                      }}
                    />
                  )}
                </HomeTable>
              </Group>
            </TABLE_CONTAINER>
            <TABLE_CONTAINER>
              <Group>
                <HomeTable>
                  {rows전체 && (
                    <CustomDataGrid
                      initialState={{
                        sorting: {
                          sortModel: [{ field: 'pick', sort: 'desc' }],
                        },
                      }}
                      rows={rows전체}
                      columns={col챔피언티어}
                      components={{
                        Toolbar: () => CustomToolbar(t('scrim.banPick.championTier')),
                        NoResultsOverlay: () => CustomOverlay(t(TRANSLATE_SCRIM_TITLE.noFilteredData)),
                        NoRowsOverlay: () => CustomOverlay(t(TRANSLATE_SCRIM_TITLE.noData)),
                        ErrorOverlay: () => CustomOverlay(t(TRANSLATE_SCRIM_TITLE.errorGenerated)),
                      }}
                    />
                  )}
                </HomeTable>
              </Group>
              {/* 주요픽간의 전적 업데이트 -  22.06.07 dev update 예정 */}
              {banpickData && <ScrimMatchUp data={banpickData.response.matchUp} />}
            </TABLE_CONTAINER>
          </TableWrapper>
        </>
      )}
    </>
  );
};

export default ScrimBanPick;

const TITLE = styled.div`
  ${typoStyle.body_title}
`;

const ScrimBankPickTabs = styled.div`
  display: flex;
  height: 62px;
  border-bottom: 1px solid #433f4e;
`;

const TabItem = styled.button<{ changeColor: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  white-space: nowrap;

  div {
    padding: 10px 15px;
  }

  :hover {
    div {
      padding: 10px 15px;
      border-radius: 10px;
      background-color: #26262c;
    }
  }

  span {
    height: 22px;
    font-family: SpoqaHanSansNeo;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 18px;
    border-bottom: solid 1px ${(props) => (props.changeColor ? `#fff` : `none`)};
    color: ${(props) => (props.changeColor ? `#fff` : `#84818e`)};
  }
`;

const TABLE_CONTAINER = styled.div`
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  padding: 30px 0;
  color: #ffffff;
  margin-bottom: 20px;
`;

const Group = styled.div`
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
  background-color: #2f2d38;
  border-radius: 12px;
`;

const HomeTable = styled.div`
  height: 100%;
  border-radius: 12px;
  width: 100%;
`;
