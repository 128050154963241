import { AxiosError, AxiosResponse } from 'axios';
import { axiosPost } from './axios/axios';
import { IResponseSuccess } from './axios/axios.type';
import {
  PlayersParam,
  TeamPickParam,
  TeamPICompareParam,
  TeamPositionCompareParam,
} from './types/param_types';
import { TeamAnalysisResponse, ITeamDraftResponse, TeamPickResponse } from './types/team_services.type';

interface Side {
  ban: string[];
  pick: { fpick: string; position: string; champion: string }[];
  result: number;
  team: string;
  teamid: number;
}

class TeamServices {
  static instance: TeamServices;

  // 밴픽보고서 개편 전
  // getTeamPick(data: TeamPickParam): Promise<IResponseSuccess<TeamPickResponse>> {
  //   return new Promise((resolve, reject) => {
  //     axiosPost({
  //       url: '/team/pick',
  //       data,
  //     })
  //       .then((res: AxiosResponse) => resolve(res.data))
  //       .catch((err: AxiosError) => reject(err));
  //   });
  // }

  // 밴픽보고서 개편 후
  getTeamPickRenewal(data: TeamPickParam): Promise<IResponseSuccess<TeamPickResponse>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/team/teamData',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  getTeamDraft(data: any): Promise<IResponseSuccess<ITeamDraftResponse[]>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/team/draft',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  // team 배열로 안들어가면 에러가 뜸
  getTeamAnalysis(data: any): Promise<IResponseSuccess<TeamAnalysisResponse>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/team/analysis',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  getCompareTeamPI(data: TeamPICompareParam) {
    return axiosPost({
      url: '/team/comparisonPi',
      data,
    });
  }

  getCompareTeamPosition(data: TeamPositionCompareParam) {
    return axiosPost({
      url: '/team/comparison',
      data,
    });
  }

  getReportTemBanPick(data: any): Promise<IResponseSuccess<TeamAnalysisResponse>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/report/team/banpick',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }
}

export default new TeamServices();
