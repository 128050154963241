/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import secToMS from '../../../../lib/secToMS';
import GameAtom from '../../../../recoil/ProGameReport/gameAtom';
import VideoAtom from '../../../../recoil/ProGameReport/videoAtom';
import { RootState } from '../../../../redux/modules';

const TimeBarTitle = ({ textAligh = 'right' }) => {
  const startTime = useRecoilValue(GameAtom.startTime);
  const playedSeconds = useRecoilValue(VideoAtom.playedSeconds);

  return <Title textAligh={textAligh}>{secToMS(Math.floor(+playedSeconds) - +startTime)}</Title>;
};

export default TimeBarTitle;

const Title = styled.div<{ textAligh: string }>`
  width: 58px;
  font-family: SpoqaHanSansNeo;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: ${(props) => props.textAligh};
  color: #fff;
`;
