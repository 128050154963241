/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useLocation } from 'react-router-dom';
import SoloRankTab from './Component/Common/SoloRankTab';
import { colors, scrollbarStyle, spacing } from '../../Styles/ui';
import SoloRankFilter from './SoloRankFilter';
import TeamFilter from '../TeamReport/TeamFilter';

const SoloRank = () => {
  const { t } = useTranslation();
  const isFSPage = useLocation().pathname.includes('soloRank/searchFilter');

  if (document.title !== `${t('sidebar.part13')} - NUNU.GG`) {
    document.title = `${t('sidebar.part13')} - NUNU.GG`;
  }

  return (
    <WRAPPER>
      {isFSPage && <TeamFilter />}
      <CONTENT>
        <DETAILS>
          <SoloRankTab />
        </DETAILS>
      </CONTENT>
    </WRAPPER>
  );
};

export default SoloRank;

const WRAPPER = styled.div`
  display: flex;
  height: 100vh;
  background-color: #16151c;
`;

const CONTENT = styled.div`
  ${spacing.padding(8)}
  flex: 1;
  height: 100vh;
  ${scrollbarStyle.scroll_4}
`;

const DETAILS = styled.div`
  min-width: 1096px;
`;
