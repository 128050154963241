/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { colors, scrollbarStyle, spacing, transitionStyle, typoStyle } from '../../Styles/ui';
import NotFound from '../../Components/Ui/Error/NotFound';
import PlayerFilter from './PlayerFilter';
import { PlayerReportResultAtom } from '../../recoil/Filter/playerReportAtom';

const Tabs = [
  { path: '', title: 'solo.tabs.board' },
  { path: 'solorank', title: 'solo.tabs.soloRank' },
];

function Solo() {
  const result = useRecoilValue(PlayerReportResultAtom);
  const resetResult = useResetRecoilState(PlayerReportResultAtom);
  const { t } = useTranslation();
  const { pathname } = useLocation();

  if (document.title !== `${t('sidebar.part5')} - NUNU.GG`) {
    document.title = `${t('sidebar.part5')} - NUNU.GG`;
  }

  useEffect(() => {
    return () => {
      resetResult();
    };
  }, []);

  return (
    <WRAPPER>
      <PlayerFilter />

      <CONTENT>
        <DETAILS>
          <Container>
            <TabContainer>
              <TabHeader>
                {Tabs.map((tab, i) => {
                  const active =
                    i === 0 ? pathname.split('/')[2] === undefined : pathname.split('/')[2] === tab.path;
                  return (
                    <Tab key={tab.title} active={active}>
                      <SLink to={tab.path}>{t(tab.title)}</SLink>
                    </Tab>
                  );
                })}
              </TabHeader>
              <TabContents>
                {result ? (
                  <Outlet />
                ) : (
                  <NotFound
                    css={spacing.paddingY(12.5)}
                    text={
                      <Text>
                        <h3>{t('solo.notFound.chooseOptions')}</h3>
                        <p>{t('solo.notFound.chooseOptionsDesc')}</p>
                      </Text>
                    }
                  />
                )}
              </TabContents>
            </TabContainer>
          </Container>
        </DETAILS>
      </CONTENT>
    </WRAPPER>
  );
}

export default Solo;

const WRAPPER = styled.div`
  display: flex;
  min-height: 100%;
  background-color: #16151c;
`;

const CONTENT = styled.div`
  ${spacing.padding(8)}
  flex: 1;
  height: 100vh;
  ${scrollbarStyle.scroll_4}
`;
const Container = styled.div`
  ${spacing.padding(7)};
`;
const DETAILS = styled.div`
  min-width: 1096px;
`;
const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1096px;
  border-radius: 10px;
  min-height: 320px;
`;
const TabHeader = styled.ul`
  display: flex;
  height: 60px;
  ${spacing.paddingX(6)}
  border-radius: 10px 10px 0 0;
  background-color: ${colors.bg_light};
`;

const Text = styled.div`
  text-align: center;
  h3 {
    ${spacing.marginB(5)}
    ${typoStyle.body}
  }
`;
const TabContents = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  background-color: ${colors.bg_light};
  ${spacing.paddingY(1)};
  border-radius: 0px 0px 10px 10px;
`;
const Tab = styled.li<{ active: boolean; disabled?: boolean }>`
  ${spacing.paddingX(4)}
  ${spacing.paddingY(3)}
  ${transitionStyle.opacity}
  color: ${colors.text};
  opacity: ${({ active }) => (active ? 1 : 0.3)};
  border-bottom: 1px solid transparent;
  border-color: ${({ active }) => (active ? colors.text : 'transparent')};

  &:hover {
    opacity: 1;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
      user-select: none;
      border-bottom: none;
    `}
`;

const SLink = styled(Link)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
