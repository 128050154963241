/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import { t } from 'i18next';
import { useRef, useState } from 'react';
import Checkbox from '../../../Components/Ui/Checkbox';
import getMatchHistory from '../../../lib/getMatchHistory';
import { getRank } from '../../../lib/getRank';
import { getWinRate } from '../../../lib/getWinRate';
import { soloRankLabels } from '../../../lib/mapping/map_table_label';
import { ISoloRankInfo } from '../../../services/SoloRankServices';
import { colors, tableStyle, transitionStyle } from '../../../Styles/ui';

interface SolorankIDListProps {
  soloRankInfo: ISoloRankInfo[];
  summonerNameState: any;
  handleChange: any;
}

const SolorankIDList: React.FC<SolorankIDListProps> = ({ soloRankInfo, summonerNameState, handleChange }) => {
  return (
    <>
      <SHead>
        <div css={Col}>
          <Checkbox
            name='summonerNameState'
            value='all'
            onChange={handleChange}
            checked={
              Object.keys(summonerNameState).length > 0 && !Object.values(summonerNameState).includes(false)
            }
          />
        </div>
        <div css={Col1}>{t(soloRankLabels[0])}</div>
        <div css={[Col2]}>{t(soloRankLabels[1])}</div>
        <div css={[Col3]}>{t(soloRankLabels[2])}</div>
        <div css={[Col4]}>{t(soloRankLabels[3])}</div>
        <div css={[Col5]}>{t(soloRankLabels[4])}</div>
        <div css={[Col4]}>{t(soloRankLabels[5])}</div>
        <div css={[Col5]}>{t(soloRankLabels[4])}</div>
      </SHead>
      {soloRankInfo.map((info) => {
        const { lastDay, lastSeason: season } = info;

        return (
          <ROW
            key={info.summonerName}
            isActive={summonerNameState[info.summonerName]}
            htmlFor={info.summonerName}
          >
            <div css={Col}>
              <Checkbox
                id={info.summonerName}
                name='summonerNameState'
                value={info.summonerName}
                onChange={handleChange}
                checked={summonerNameState[info.summonerName]}
              />
            </div>
            <div css={Col1}>{info.summonerName}</div>
            <div css={[Col2]}>{getRank(info.tier, info.rank)}</div>
            <div css={[Col3]}>{`${+info.leaguePoints > -1 ? `${info.leaguePoints} LP` : '-'}`}</div>
            <div css={[Col4]}>{`${
              season ? getMatchHistory(season.total, season.win, season.lose) : '-'
            }`}</div>
            <div css={[Col5, { color: colors.badge_red }]}>
              {season ? getWinRate(+season.total, +season.win, 0) : '-'}
            </div>
            <div css={[Col4]}>{`${
              lastDay ? getMatchHistory(lastDay.total, lastDay.win, lastDay.lose) : '-'
            }`}</div>
            <div css={[Col5, { color: colors.badge_red }]}>
              {lastDay ? `${getWinRate(+lastDay.total, +lastDay.win, 0)}` : '-'}
            </div>
          </ROW>
        );
      })}
    </>
  );
};

const ROW = styled.label<{ isActive: boolean }>`
  border-radius: 20px;
  cursor: pointer;
  ${tableStyle.table_row_none_after};
  ${transitionStyle.background};
  background-color: ${({ isActive }) => (isActive ? colors.bg_gnb : '')};

  &:hover {
    background-color: ${colors.bg_gnb};
  }

  height: 60px;
  display: flex;
`;

const SHead = styled.div`
  ${tableStyle.table_head}
  text-align: left;
`;

// 테이블
const Col = css`
  margin-left: 20px;
  margin-right: 9px;
`;

const Col1 = css`
  width: 160px;
  text-align: left;
  //margin-right: 20px;
`;

const Col2 = css`
  width: 120px;
  margin-right: 10px;
  text-align: left;
`;

const Col3 = css`
  width: 100px;
  margin-right: 10px;
  text-align: left;
`;
const Col4 = css`
  width: 180px;
  margin-right: 30px;
  text-align: left;
`;
const Col5 = css`
  width: 50px;
  margin-right: 30px;
  text-align: left;
`;
export default SolorankIDList;
