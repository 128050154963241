export const INIT = 'playerReportValue/INIT';
export const SET_PLAYER_DATA = 'playerReportValue/SET_PLAYER_DATA';
export const SET_CHAMP_DATA = 'playerReportValue/SET_CHAMP_DATA';
export const SET_OPPCHAMP_DATA = 'playerReportValue/SET_OPPCHAMP_DATA';
export const SET_PLAYER_DATA_LOADING = 'playerReportValue/SET_PLAYER_DATA_LOADING';
export const SET_TOTAL_DATA_LOADING = 'playerReportValue/SET_TOTAL_DATA_LOADING';
export const SET_PLAYER_DATA_RESET = 'playerReportValue/SET_PLAYER_DATA_RESET';

export const Init = () => {
  return {
    type: INIT,
  };
};

export const SetPlayerData = (payload) => {
  return {
    type: SET_PLAYER_DATA,
    payload,
  };
};

export const SetChampData = (payload) => {
  return {
    type: SET_CHAMP_DATA,
    payload,
  };
};

export const SetOppChampData = (payload) => {
  return {
    type: SET_OPPCHAMP_DATA,
    payload,
  };
};

export const SetPlayerDataLoading = (payload) => {
  return {
    type: SET_PLAYER_DATA_LOADING,
    payload,
  };
};

export const SetTotalDataLoading = (payload) => {
  return {
    type: SET_TOTAL_DATA_LOADING,
    payload,
  };
};

export const SetPlayerDataReset = (payload) => {
  return {
    type: SET_PLAYER_DATA_RESET,
    payload,
  };
};

const initialState = {
  info: {},
  records: {},
  stats: {},
  trends: {},
  champion: {},
  oppchampion: {},
  totalDataLoading: false,
  playerDataLoading: false,
  playerDataReset: false,
};

export default function PlayerReportReducer(state = initialState, action) {
  switch (action.type) {
    case INIT:
      return initialState;
    case SET_PLAYER_DATA:
      return {
        ...state,
        info: { ...action.payload.info },
        records: { ...action.payload.records },
        stats: { ...action.payload.stats },
        trends: { ...action.payload.trends },
      };

    case SET_CHAMP_DATA:
      return {
        ...state,
        champion: { ...action.payload },
      };

    case SET_OPPCHAMP_DATA:
      return {
        ...state,
        oppchampion: { ...action.payload },
      };

    case SET_PLAYER_DATA_LOADING:
      return {
        ...state,
        playerDataLoading: action.payload,
      };

    case SET_TOTAL_DATA_LOADING:
      return {
        ...state,
        totalDataLoading: action.payload,
      };
    case SET_PLAYER_DATA_RESET:
      return {
        ...state,
        playerDataReset: action.payload,
      };
    default:
      return state;
  }
}
