import React, { useState, useEffect } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { convertNumberToPosition } from '../../../lib/convertNumberToPosition';
import { MAP_WARD, WardType } from '../../../lib/mapping/map_ward';
import { IWarding } from '../../../services/JunglePathingServices';

interface WardTooltipProps {
  wardType?: string;
  time: number;
  data: IWarding;
}

function WardTooltip({ data, time, wardType }: WardTooltipProps) {
  const { champion, player, team, side, date, position, oppteam, uniqueid } = data;

  return (
    <WardTooltipWrapper>
      <TopSection>
        <ChampionImage
          src={`/Images/champion/${champion}.png`}
          color={side === 'red' ? '#f04545' : '#0075bf'}
        />
        <ChampionContents>
          <PlayerInfo>{`${team.toUpperCase()} ${
            convertNumberToPosition(Number(position)) ?? 'No-Data'
          } ${player}`}</PlayerInfo>
          <OtherInfo>
            <GameInfo>{`${uniqueid.replaceAll('_', ' ')}`}</GameInfo>
            {`${date} VS ${oppteam}`}
          </OtherInfo>
        </ChampionContents>
      </TopSection>
      <BottomSection>
        {wardType && <WardImage src={MAP_WARD[wardType as WardType]?.img} />}
        <WardDetail>{`${Math.floor(time / 1000 / 60)}m ${Math.floor((time / 1000) % 60)}s`}</WardDetail>
        <WardName>{wardType}</WardName>
      </BottomSection>
    </WardTooltipWrapper>
  );
}

export default WardTooltip;

const WardTooltipWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px 10px;
  align-items: center;
  min-width: 100px;
  min-height: 55px;
  border-radius: 20px;
  background-color: #23212a;
`;

const TopSection = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const ChampionImage = styled.img`
  width: 26px;
  height: 26px;
  border: solid 1px ${(props) => props.color};
  border-radius: 30px;
  margin-right: 5px;
`;

const ChampionContents = styled.div`
  display: flex;
  flex-direction: column;
  font-family: NotoSansKR;
  font-size: 13px;
  letter-spacing: -0.06px;
  text-align: left;
  color: #fff;
  padding-bottom: 5px;
  border-bottom: 1px solid #3a3745;
`;

const PlayerInfo = styled.div`
  width: auto;
  font-weight: bold;
  margin-bottom: 5px;
`;

const OtherInfo = styled.div``;

const GameInfo = styled.div`
  margin-bottom: 5px;
`;

const WardImage = styled.img`
  width: 26px;
  height: 26px;
  margin-right: 5px;
`;

const BottomSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: NotoSansKR;
  font-size: 13px;
  text-align: left;
  color: #fff;
`;

const WardDetail = styled.div`
  margin-right: 5px;
  font-size: 13px;
`;

const WardName = styled.div``;
