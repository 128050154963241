import { atom } from 'recoil';
import { FilterData, IFilterSeason } from './filter.type';

interface PlayerReportParam {
  league: string[];
  year: string[];
  season: string[];
  patch: string[];
  team: string[];
  player: string[];
}

const PlayerReportAtom = {
  years: atom<FilterData>({
    key: 'PlayerReportYear',
    default: {},
  }),

  leagues: atom<FilterData>({
    key: 'PlayerReportLeague',
    default: {},
  }),

  seasons: atom<IFilterSeason>({
    key: 'PlayerReportSeason',
    default: {},
  }),

  // patchs: atom<FilterData>({
  //   key: 'PlayerReportPatch',
  //   default: {},
  // }),

  teams: atom<FilterData>({
    key: 'PlayerReportTeam',
    default: {},
  }),

  players: atom<FilterData>({
    key: 'PlayerReportPlayer',
    default: {},
  }),

  champion: atom<FilterData>({
    key: 'PlayerReportChampion',
    default: {},
  }),

  oppChampion: atom<FilterData>({
    key: 'PlayerReportOppChampion',
    default: {},
  }),
  totalGame: atom({
    key: 'PlayerReportTotalGame',
    default: '',
  }),

  oppTotalGame: atom({
    key: 'PlayerReportOppTotalGame',
    default: '',
  }),
};

export const PlayerReportResultAtom = atom<PlayerReportParam>({
  key: 'PlayerReportResult',
  default: undefined,
});

export default PlayerReportAtom;
