import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { ChampionListAtom, LOLPatchAtom } from '../recoil/common/championListAtom';
import FilterService from '../services/FilterServices';

export const useChampionCall = () => {
  const setChampionList = useSetRecoilState(ChampionListAtom);
  const setLolPatch = useSetRecoilState(LOLPatchAtom);

  return useQuery(['filter', 'champion'], () => FilterService.getChampionList(), {
    onSuccess: (data) => {
      const newObj = data.list.reduce((acc, crr) => {
        acc[crr.ckey] = { ko: crr.cname, en: crr.cid };
        return acc;
      }, {});
      console.log('data', data);
      setLolPatch(data.patch);
      setChampionList(newObj);
    },
    select: (data) => data.response,
    staleTime: 1000 * 60 * 60 * 3,
    cacheTime: 1000 * 60 * 60 * 3,
    refetchOnWindowFocus: false,
  });
};
