import { createTheme } from '@mui/material';
import Bold from '../fonts/SpoqaHanSansNeo-Bold.woff2';
import Light from '../fonts/SpoqaHanSansNeo-Light.woff2';
import Medium from '../fonts/SpoqaHanSansNeo-Medium.woff2';
import Regular from '../fonts/SpoqaHanSansNeo-Regular.woff2';
import Thin from '../fonts/SpoqaHanSansNeo-Thin.woff2';

const theme = createTheme({
  palette: {
    primary: {
      light: '#9575cd',
      main: '#5442b0',
      dark: '#4135a1',
      contrastText: '#fff',
    },
    secondary: {
      main: '#403f50',
      contrastText: '#fff',
    },
    background: {},
  },
  typography: {
    htmlFontSize: 15,
    fontFamily: ['SpoqaHanSansNeo', '-apple-system'].join(','),

    subtitle1: {
      fontSize: 15,
    },
    subtitle2: {
      fontSize: 13,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family:'SpoqaHanSansNeo';
        src: local('SpoqaHanSansNeo'), url(${Bold}) format('woff2');
        font-display: swap;
        font-weight: 700; 	
        font-style: normal;
      }
    @font-face {
        font-family:'SpoqaHanSansNeo';
        src: local('SpoqaHanSansNeo'), url(${Light}) format('woff2');
        font-display: swap;
        font-weight: 300; 	
        font-style: normal;
      }
    @font-face {
        font-family:'SpoqaHanSansNeo';
        src: local('SpoqaHanSansNeo'), url(${Regular}) format('woff2');  
        font-display: swap;
        font-weight: 400; 		
        font-style: normal;
    }
    @font-face {
        font-family:'SpoqaHanSansNeo';
        src: local('SpoqaHanSansNeo'), url(${Medium}) format('woff2');
        font-display: swap;
        font-weight: 500; 	
        font-style: normal;
    }
    @font-face {
        font-family:'SpoqaHanSansNeo';
        src: local('SpoqaHanSansNeo'), url(${Thin}) format('woff2');
        font-display: swap;
        font-weight: 100; 		
        font-style: normal;
    }
      `,
    },
  },
  spacing: 4,
});

export default theme;
