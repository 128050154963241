import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled/macro';
import { useQuery } from 'react-query';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import CalendarFilter from '../../Components/Filter/Calendar/CalendarFilter';
import LeagueFilter from './LeagueFilter';
import { colors, scrollbarStyle, spacing, transitionStyle } from '../../Styles/ui';
import { LeagueReportResultAtom } from '../../recoil/Filter/leagueReportAtom';
import SelectFilter from '../../Components/SelectFilter/SelectFilter';

const Tabs = [
  { path: '', title: 'league.tab.draft' },
  { path: 'statistics', title: 'league.tab.leagueStat' },
  { path: 'player', title: 'league.tab.playerStat' },
];

const League = () => {
  const { t } = useTranslation();
  const result = useRecoilValue(LeagueReportResultAtom);
  const { pathname } = useLocation();
  const resetResult = useResetRecoilState(LeagueReportResultAtom);

  if (document.title !== `${t('sidebar.part2')} - NUNU.GG`) {
    document.title = `${t('sidebar.part2')} - NUNU.GG`;
  }

  useEffect(() => {
    return () => {
      resetResult();
    };
  }, []);

  return (
    <>
      <WRAPPER>
        <LeagueFilter />
        <CONTENT>
          {result ? (
            <DETAILS>
              {/* 탭 헤더 */}
              <TabHeader>
                {Tabs.map((tab, i) => {
                  const active =
                    i === 0 ? pathname.split('/')[2] === undefined : pathname.split('/')[2] === tab.path;
                  return (
                    <Tab key={tab.title} active={active}>
                      <SLink to={tab.path}>{t(tab.title)}</SLink>
                    </Tab>
                  );
                })}
              </TabHeader>
              {/* 탭 컨텐츠 */}
              <Outlet />
            </DETAILS>
          ) : (
            <SELECTFILTER_WRAPPER>
              <SelectFilter />
            </SELECTFILTER_WRAPPER>
          )}
        </CONTENT>
        <CalendarFilter />
      </WRAPPER>
    </>
  );
};

export default League;

const WRAPPER = styled.div`
  display: flex;
  background-color: #16151c;
`;

const DETAILS = styled.div`
  min-width: 1096px;
`;

const CONTENT = styled.div`
  ${spacing.padding(8)}
  flex: 1;
  height: 100vh;
  ${scrollbarStyle.scroll_4}
`;
const TabHeader = styled.ul`
  display: flex;
  ${spacing.marginB(5)};
`;

const Tab = styled.li<{ active: boolean }>`
  ${spacing.paddingX(4)}
  ${spacing.paddingY(3)}
  ${transitionStyle.opacity}
  color: ${colors.text};
  opacity: ${({ active }) => (active ? 1 : 0.3)};
  border-color: ${({ active }) => (active ? colors.text : 'transparent')};

  &:hover {
    opacity: 1;
  }
`;

const SLink = styled(Link)`
  font-size: 18px;
`;

const SELECTFILTER_WRAPPER = styled.div``;
