import React from 'react';
import styled from '@emotion/styled';
import { cx } from '@emotion/css';
import { colors } from '../../Styles/ui';

const CustomCheckbox = ({
  checked,
  children,
  className,
  style,
  posError,
  defaultColor,
  hoverColor,
  ...props
}) => {
  return (
    <SContainer
      style={style}
      tabIndex={-1}
      hoverColor={hoverColor}
      className={cx(className, checked ? 'is-active' : '')}
    >
      <input type='checkbox' checked={checked} {...props} />
      {children}
    </SContainer>
  );
};

const SContainer = styled.label`
  display: block;
  cursor: pointer;
  border: 1px solid transparent;

  > input {
    display: none;
  }
`;

export default CustomCheckbox;
