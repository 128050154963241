import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import Progress from '../../../Components/Ui/Loading/Progress';
import { ChampionListAtom } from '../../../recoil/common/championListAtom';
import { FilterCallAtom } from '../../../recoil/Filter/staticAtom';
import ScrimFilter from '../components/ScrimFilter';

import ScrimInputRenewal from '../components/ScrimInputRenewal';
import ScrimOutput from '../components/ScrimOutput';

const ScrimIndex = () => {
  const filterData = useRecoilValue(FilterCallAtom);
  const championList = useRecoilValue(ChampionListAtom);
  const { t } = useTranslation();

  return (
    <>
      {!filterData || !championList ? (
        <ProgressContainer>
          <Progress text={t('solo.playerboard.dataFetching')} />
        </ProgressContainer>
      ) : (
        <>
          {/* 입력창 */}
          <ScrimFilter />
          <ScrimInputRenewal />
          {/* 필터 */}
          {/* 날짜 */}
          {/* 상대팀 */}
          {/* 밴픽 및 게임 결과 등록 */}
          <ScrimOutput />
          {/* 결과창 */}
          {/* 날짜 레인지 */}
        </>
      )}
    </>
  );
};
export default ScrimIndex;

const ProgressContainer = styled.div`
  width: 1110px;
  height: 253px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #24222c;
  border-radius: 10px;
  margin-top: 30px;
`;
