import useChampionImage from './useChampionImage';

const NOIMG = '/Images/ico-ban-nopick.svg';

export default function useParseChampionImagePath(
  patch: string,
  championList: {
    [key: string]: {
      ko: string;
      en: string;
    };
  },
) {
  const { getChampionImagePathByName, getChampionImagePathByIndex } = useChampionImage();

  return (champion: number | string) => {
    const championNumber = Number(champion);

    if (champion === 'no-image') {
      return NOIMG;
    }

    if (typeof champion === 'string') {
      return getChampionImagePathByName(champion.replace(' ', ''));
    }

    if (!championList[championNumber]) {
      return NOIMG;
    }
    return getChampionImagePathByIndex(championNumber);
  };
}
