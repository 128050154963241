import { atom } from 'recoil';
import { FilterData, IFilterSeason } from './filter.type';

export interface LeagueReportParams {
  league: string[];
  year: string[];
  season: string[];
  patch: string[];
}

export interface ISeason {
  name: string | number | symbol;
  patch: string[];
}

const LeagueReportAtom = {
  years: atom<FilterData>({
    key: 'LeagueReportYear',
    default: {},
  }),

  leagues: atom<FilterData>({
    key: 'LeagueReportLeague',
    default: {},
  }),

  seasons: atom<IFilterSeason>({
    key: 'LeagueReportSeason',
    default: {},
  }),

  // patchs: atom<FilterData>({
  //   key: 'LeagueReportPatch',
  //   default: {},
  // }),
};

export const LeagueReportResultAtom = atom<LeagueReportParams>({
  key: 'LeagueReportResult',
  default: undefined,
});

export default LeagueReportAtom;
