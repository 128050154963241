/**
 * @desc 첫번째 인자는 배열, 두번째 인자는 value의 값을받아, 배열의 아이템을 key, value의 값을 value로 하는 오브젝트를 리턴한다.
 * @param {string[]} array
 * @param {string | boolean} value
 * @returns {[key:string]: boolean}
 */

import { ISeason } from '../recoil/Filter/leagueReportAtom';

export const initializedObjValue = <T>(
  array: string[],
  value: T | boolean = false,
): { [key: string]: typeof value } => {
  if (!Array.isArray(array)) {
    console.error('initializedObjValue에 제공된 인자는 배열이여야 합니다');
    return {};
  }

  const newArray = [...array];
  const result = newArray.sort().reduce<{ [key: string]: typeof value }>((newObj, key) => {
    // eslint-disable-next-line no-param-reassign
    newObj[key] = value;
    return newObj;
  }, {});

  return result;
};

export const initializedSeasons = <T>(
  array: ISeason[],
  value: T | boolean = false,
): { [key: string]: { check: T | boolean; patch: { [key: string]: boolean } } } => {
  if (!Array.isArray(array)) {
    console.error('initializedObjValue에 제공된 인자는 배열이여야 합니다');
    return {};
  }

  const newArray = [...array];
  const result = newArray
    .sort()
    .reduce<{ [key: string]: { check: T | boolean; patch: { [key: string]: boolean } } }>((newObj, key) => {
      // eslint-disable-next-line no-param-reassign
      newObj[key.name] = {
        check: value,
        patch: initializedObjValue(key.patch),
      };
      return newObj;
    }, {});

  return result;
};
