import React from 'react';
import styled from '@emotion/styled';
import { useRecoilValue } from 'recoil';
import { Box } from '@mui/system';
import { List, ListItem, Avatar, styled as MUIstyled } from '@mui/material';
import { t } from 'i18next';

import { ChampionListAtom, OriginalChampData } from '../../../recoil/common/championListAtom';
import { scrollbarStyle } from '../../../Styles/ui';
import { TRANSLATE_BANPICK_REPORT } from '../../../lib/translation/TeamReport/translate_BanpickReport';
import { userLanguageAtom } from '../../../recoil/Auth/userAtom';
import useChampionImage from '../../../Hooks/useChampionImage';

interface P {
  title: string;
  data: any;
  side?: 'blue' | 'red';
  games: number;
}

// 데이터 내림차순 정렬
function sortByTotalGames(a: any, b: any) {
  return b.count - a.count;
}

const ChampList: React.FC<P> = ({ title, data, games, side }) => {
  const originalChamp = useRecoilValue(OriginalChampData);
  const refinedChamp = useRecoilValue(ChampionListAtom);
  const lang = useRecoilValue(userLanguageAtom);
  const { getChampionImagePathByIndex } = useChampionImage();

  console.log(title, data);

  return (
    <Wrapper>
      <Title>{title}</Title>
      <StyledList disablePadding sx={{ overflowY: 'scroll', maxHeight: 828 }}>
        {data?.length !== 0 &&
          data?.sort(sortByTotalGames).map((item: any, idx: number) => (
            <ListItem
              disablePadding
              sx={{ display: 'flex', justifyContent: 'flex-start', py: 1.5, px: 3 }}
              key={side + title + idx + item.champ}
            >
              <Avatar
                alt='champImg'
                src={getChampionImagePathByIndex(item.champ)}
                sx={{
                  width: 40,
                  heigth: 40,
                }}
                variant='square'
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  color: '#fff',
                  ml: 2,
                }}
              >
                <div style={{ fontSize: 13 }}>
                  {lang === 'ko' ? refinedChamp[item.champ]?.ko : refinedChamp[item.champ]?.en}
                </div>
                <div style={{ fontSize: 13 }}>
                  <span style={{ color: '#e2ff7a' }}>{`${item.count}${t(TRANSLATE_BANPICK_REPORT.밴)}`}</span>
                  {` / ${games}${t(TRANSLATE_BANPICK_REPORT.경기)} (${((item.count / games) * 100).toFixed(
                    0,
                  )}%)`}
                </div>
              </Box>
            </ListItem>
          ))}
      </StyledList>
    </Wrapper>
  );
};

export default ChampList;

const Wrapper = styled.div`
  width: 281px;
`;

const Title = styled.div`
  height: 28px;
  color: #fff;
  background-color: rgba(128, 128, 128, 0.2);
  border-radius: 4px;
  text-align: center;
  font-size: 13px;
  line-height: 28px;
`;

const StyledList = MUIstyled(List)` 
    ${scrollbarStyle.scroll_4}
`;
