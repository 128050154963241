/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import { borderRadiusStyle, typoStyle } from '../../Styles/ui';

const Button = ({ children, ...props }) => {
  return (
    <SButton css={typoStyle.button} {...props}>
      {children}
    </SButton>
  );
};

const SButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  ${borderRadiusStyle[20]}
  width: 100%;
`;

export default Button;
