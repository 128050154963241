export const patchsort = (a: any, b: any) => {
  const patch1 = a.split('.');
  const patch2 = b.split('.');
  const error =
    typeof Number(patch1[0]) !== 'number' ||
    typeof Number(patch1[1]) !== 'number' ||
    typeof Number(patch2[0]) !== 'number' ||
    typeof Number(patch2[1]) !== 'number';

  if (error) {
    console.warn('patch 형태의 숫자가 아닙니다. number.number를 확인하세요. 삽입 순서 그대로 리턴합니다.');
    return 0;
  }

  if (Number(patch1[0]) - Number(patch2[0])) return Number(patch1[0]) - Number(patch2[0]);
  if (Number(patch1[0]) - Number(patch2[0]) === 0) return Number(patch1[1]) - Number(patch2[1]);
  return 0;
};
