import React, { ErrorInfo, ReactNode, useState } from 'react';
import { Navigate } from 'react-router-dom';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error;
  info: { componentStack: string };
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: { name: '', message: '', stack: '' },
      info: { componentStack: '' },
    };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // You can also log the error to an error reporting service
    this.setState({ error, info });
  }

  render() {
    const { hasError, error, info } = this.state;
    if (hasError) {
      console.error('에러바운더리:');
      sessionStorage.clear();
      // return <h1>Something went wrong.</h1>;
      return <Navigate to='/' />;
    }
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

export default ErrorBoundary;
