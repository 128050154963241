import React from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
// MUI
import { IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import LogoutIcon from '@mui/icons-material/Logout';
// custom
import { userAtom } from '../../recoil/Auth/userAtom';
import AuthServices from '../../services/AuthServices';
import { t } from 'i18next';
import { MAP_USER_WINDOW } from '../../lib/mapping/map_setting_menu';

const UserWindow = () => {
  // 로그아웃 함수, 로그아웃 이벤트가 발생되면 session 값을 clear하고 로그인 페이지로 이동시킴\
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);

  const { mutate } = useMutation(({ user, locale }) => AuthServices.logout(user, locale), {
    onSuccess: () => {
      setUser(undefined);
      sessionStorage.removeItem('user');
      navigate('/');
    },
  });

  const handleLogOut = async () => {
    mutate({ user, locale: 'ko' });
  };

  return (
    <>
      <Tooltip title={t(MAP_USER_WINDOW.계정관리)} placement='right'>
        <IconButton
          aria-label='settting'
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          sx={{
            color: 'white',
          }}
        >
          <PowerSettingsNewRoundedIcon />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            width: '160px',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            backgroundColor: '#3a3a49',
            color: 'white',
            padding: '8px 0',
            mt: 1.5,
            borderRadius: '8px',
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          disabled
          sx={{
            padding: '6px 24px',
            fontSize: 12,
          }}
        >
          {t(MAP_USER_WINDOW.계정관리)}
        </MenuItem>
        {/* 2022.05.25 추후 프로필 관리 기능 구현시 활성화 */}
        {/* <MenuItem
          sx={{
            padding: '6px 24px',
            fontSize: 13,
            ':hover': {
              backgroundColor: '#4e4e5c',
            },
          }}
        >
          <ListItemIcon sx={{ color: 'white' }}>
            <PersonAddAltIcon fontSize='small' />
          </ListItemIcon>
          {t(MAP_USER_WINDOW.사용자관리)}
        </MenuItem> */}
        <MenuItem
          onClick={handleLogOut}
          sx={{
            padding: '6px 24px',
            fontSize: 13,
            ':hover': {
              backgroundColor: '#4e4e5c',
            },
          }}
        >
          <ListItemIcon sx={{ color: 'white' }}>
            <LogoutIcon fontSize='small' />
          </ListItemIcon>
          {t(MAP_USER_WINDOW.로그아웃)}
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserWindow;
