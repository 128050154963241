/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { spacing } from '../../Styles/ui';

function SelectFilter(headText) {
  const { t } = useTranslation();
  // 필터 미선택시 띄어주는 화면
  return (
    <WRAPPER>
      <InnerWrapper>
        <div className='FirstLabel'>{t('filters.selectOption')}</div>
        <div className='SecondLabel'>
          {t('filters.selectOption2')} {t('filters.selectOption3')}
        </div>
      </InnerWrapper>
    </WRAPPER>
  );
}

export default SelectFilter;

const WRAPPER = styled.div`
  ${spacing.marginL(8)}
  ${spacing.marginT(8)}
`;

const InnerWrapper = styled.div`
  background-color: #23212a;
  padding: 50px 100px;
  border-radius: 30px;
  width: 1050px;

  .FirstLabel {
    font-family: SpoqaHanSansNeo;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.75px;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
  }
  .SecondLabel {
    font-family: SpoqaHanSansNeo;
    font-size: 15px;
    letter-spacing: -0.6px;
    text-align: center;
    color: #fff;
    line-height: 1.25;
  }
`;
