import React, { useState, useEffect } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import { useRecoilValue } from 'recoil';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import noneItem from '../../../../lib/noneItem';

import GameAtom from '../../../../../../recoil/ProGameReport/gameAtom';
import GameDatasetAtom from '../../../../../../recoil/ProGameReport/gameDatasetAtom';

const ItemTimeBox = () => {
  const { t } = useTranslation();
  const playerDataset = useRecoilValue(GameDatasetAtom.playerDataset);
  const selectedParticipant = useRecoilValue(GameAtom.selectedParticipant);
  const itemActiveIdx = useRecoilValue(GameAtom.itemActiveIdx);
  const { currentItem } = playerDataset[selectedParticipant];
  const [trinket, setTrinket] = useState<number>(3340);
  const curTrinket = currentItem[itemActiveIdx].trinket ?? 0;

  if (currentItem[0].realCount !== 0) {
    currentItem.unshift({
      realCount: 0,
      items: [],
      trinket: 3340,
    });
  }

  useEffect(() => {
    if (curTrinket !== 0 && curTrinket !== currentItem[itemActiveIdx].trinket) {
      setTrinket(curTrinket);
    }
  }, [selectedParticipant]);

  return (
    <ItemTimeContainer>
      <div className='item-status-box current'>
        <div className='nav'>{t('game.summary.champion.item-current')}</div>
        <div className='img-box'>
          <div className='img-col'>
            {currentItem[itemActiveIdx].items.map((item) => {
              return <ItemStatusImg className='item-img' src={`/Images/item/${item}.png`} alt='itemImg' />;
            })}
            {noneItem(currentItem[itemActiveIdx].items.length).map(() => {
              return <ItemStatusNotImg />;
            })}
          </div>
          <div className='img-col-trick'>
            <ItemStatusImg className='item-img' src={`/Images/item/${trinket}.png`} alt='itemImg' />
          </div>
        </div>
      </div>
    </ItemTimeContainer>
  );
};

export default ItemTimeBox;

const ItemTimeContainer = styled.div`
  display: flex;
`;

const ItemStatusImg = styled.img`
  width: 30px;
  height: 30px;
  margin: 0 3px 3px 0;
  object-fit: contain;
  border-radius: 3px;
`;

const ItemStatusNotImg = styled.img`
  width: 31px;
  height: 31px;
  margin: 0 3px 3px 0;
  object-fit: contain;
  border-radius: 3px;
  background-color: #3a3745;
  border: solid 15px #3a3745;
  display: inline-block;
  content: '';
`;
