import React from 'react';
import styled from '@emotion/styled';
import { colors, typoStyle } from '../../Styles/ui';

const NoneDatabox = ({ width = 1110, headerText, subText }) => {
  return (
    <SContainer width={width} aria-label='none-data'>
      <div>
        {headerText && <HeaderText>{headerText}</HeaderText>}
        {subText && <SubText>{subText}</SubText>}
      </div>
    </SContainer>
  );
};

export default NoneDatabox;

const SContainer = styled.div`
  width: ${({ width }) => width}px;
  height: 160px;
  margin: 20px 0 0;
  padding: 0 30px;
  border-radius: 20px;
  background-color: ${colors.bg_select};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderText = styled.div`
  width: 100%
  font-family: SpoqaHanSansNeo;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.text};
`;
const SubText = styled.div`
  margin-top: 20px;
  width: 100%;
  font-family: SpoqaHanSansNeo;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.text};
`;
