import React, { ChangeEvent } from 'react';
import SelectUnstyled, { SelectUnstyledProps, selectUnstyledClasses } from '@mui/base/SelectUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { styled } from '@mui/system';
import { colors } from '../../Styles/ui';

const StyledButton = styled('button')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-width: 188px;
  background: ${colors.bg_box};
  border: transparent;
  border-radius: 0.75em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: #aaa9b1;
  margin-left: 5px;


  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }

  `,
);

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-width: 188px;
  background: #3a3a49 !important;
  border: transparent;
  border-radius: 0.75em;
  color: #aaa9b1;
  overflow: auto;
  outline: 0px;
  `,
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: #4e4b58;
    color: #aaa9b1;
  }


  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: #444552;    
    color: white;
    cursor: pointer;
  }

  `,
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect<TValue>(
  props: SelectUnstyledProps<TValue>,
  ref: React.ForwardedRef<HTMLUListElement>,
) {
  const components: SelectUnstyledProps<TValue>['components'] = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
}) as <TValue>(props: SelectUnstyledProps<TValue> & React.RefAttributes<HTMLUListElement>) => JSX.Element;

export const SelectBox = ({ placeholder = 'placeHolder', renderList, data, setData }) => {
  const handleChange = (value: ChangeEvent<HTMLInputElement> | null) => {
    setData(value);
  };

  return (
    <CustomSelect
      value={data}
      renderValue={(selected) => {
        if (selected?.value) {
          return selected.value;
        }
        return placeholder;
      }}
      sx={{
        border: data ? `1px solid ${colors.보라색[500]}` : '1px solid transparent',
        backgroundColor: data ? `rgba(89, 66, 186, 0.2)` : `${colors.bg_box}`,
        color: data ? '#fff' : `#aaa9b1`,
      }}
      onChange={handleChange}
    >
      {renderList &&
        renderList.map((list: string, idx: number) => {
          return (
            <StyledOption key={list + idx} value={list}>
              {list}
            </StyledOption>
          );
        })}
    </CustomSelect>
  );
};

export default SelectBox;
