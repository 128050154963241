import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled/macro';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Divider from '@mui/material/Divider';
import SideBarItem from './SideBarItem';
import {
  goHome,
  goLeagueReport,
  goPathAnalysis,
  goPlayerCompare,
  goPlayerReport,
  goProGameReport,
  goSoloRank,
  goTeamCompare,
  goTeamReport,
} from '../../lib/pagePath';
import { colors, scrollbarStyle, spacing } from '../../Styles/ui';
import UserWindow from '../Setting/UserWindow';
import LanguageWindow from '../Setting/LanguageWindow';
import { userLanguageAtom } from '../../recoil/Auth/userAtom';
import EtipsterItem from './EtipsterItem';

function Sidebar() {
  //  팀 비교 모달창 상태 값
  const lang = useRecoilValue(userLanguageAtom);
  const { i18n, t } = useTranslation();
  const pathName = useLocation().pathname;

  //* 언어 설정 값이 변경되면
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  //  onClick 이벤트가 발생할때마다 해당 path로 이동
  const menus = [
    {
      // menus[0] Home
      idx: 0,
      name: t('sidebar.part1'),
      path: goHome,
      image: '/Images/sidebar_newLogo/ico-home.png',
      changeColor: pathName === goHome,
    },
    {
      // menus[1] 리그 보고서
      idx: 1,
      name: t('sidebar.part2'),
      path: goLeagueReport,
      image: '/Images/sidebar_newLogo/ico-league.png',
      changeColor: pathName.includes(goLeagueReport),
    },
    {
      // menus[2] 팀 보고서
      idx: 2,
      name: t('sidebar.part3'),
      path: goTeamReport,
      image: '/Images/sidebar_newLogo/ico-team.png',
      changeColor: pathName.includes(goTeamReport),
    },
    {
      // menus[3] 메타 분석
      idx: 3,
      name: t('sidebar.part4'),
      path: '/metaAnalysis',
      image: '/Images/sidebar_newLogo/ico-meta.png',
      changeColor: pathName.includes('/metaAnalysis'),
    },
    {
      // menus[4] 선수 보고서
      idx: 4,
      name: t('sidebar.part5'),
      path: goPlayerReport,
      image: '/Images/sidebar_newLogo/ico-player.png',
      changeColor: pathName.includes(goPlayerReport),
    },
    {
      // menus[5] 영상 보고서
      idx: 5,
      name: t('sidebar.part6'),
      path: goPathAnalysis,
      image: '/Images/sidebar_newLogo/ico-movie.png',
      changeColor: pathName.includes(goPathAnalysis),
    },
    {
      // menus[6] 매치 분석
      idx: 6,
      name: t('sidebar.part7'),
      path: '/matchAnalysis',
      image: '/Images/sidebar_newLogo/ico-match.png',
      changeColor: pathName.includes('/matchAnalysis'),
    },
    {
      // menus[7] 팀 비교
      idx: 7,
      name: t('sidebar.part8'),
      path: goTeamCompare,
      image: '/Images/sidebar_newLogo/ico-teamcom.png',
      changeColor: pathName.includes(goTeamCompare),
      modal: true,
    },
    {
      // menus[8] 선수 비교
      idx: 8,
      name: t('sidebar.part9'),
      path: goPlayerCompare,
      image: '/Images/sidebar_newLogo/ico-playercom.png',
      changeColor: pathName.includes(goPlayerCompare),
      modal: true,
    },
    {
      // menus[9] 아이템 시뮬레이터
      idx: 9,
      name: t('sidebar.part10'),
      path: '/simulator',
      image: '/Images/ico-itemsimulator.png',
      changeColor: pathName.includes('/simulator'),
    },
    {
      // menus[10] 픽 계산기
      idx: 10,
      name: t('sidebar.part11'),
      path: '/calculator',
      image: '/Images/ico-pick-calculator.png',
      changeColor: pathName.includes('/calculator'),
    },
    {
      // menus[11] 게임 보고서
      idx: 11,
      name: t('sidebar.part12'),
      path: goProGameReport,
      image: '/Images/sidebar_newLogo/ico_game.png',
      changeColor: pathName.includes(goProGameReport),
      version: 'beta',
    },
    {
      // menus[12] 솔로 랭크
      idx: 12,
      name: t('sidebar.part13'),
      path: goSoloRank,
      image: '/Images/sidebar_newLogo/ico_solorank.svg',
      changeColor: pathName.includes(goSoloRank),
      opt: 'LCK Only',
    },
    {
      // menus[13] 아이템 계산기
      idx: 13,
      name: t('sidebar.part14'),
      path: '/itemCalculator',
      image: '/Images/sidebar_newLogo/ico_calculate.svg',
      changeColor: pathName.includes('/itemCalculator'),
      version: 'beta',
    },
    {
      // menus[14] 스크림
      idx: 14,
      name: t('sidebar.part15'),
      path: '/scrim',
      image: '/Images/sidebar_newLogo/ico_calculate.svg',
      changeColor: pathName.includes('/scrim'),
    },
  ];

  // sidebar 에서 사용되는 menus를 순차적으로 추가.
  const usingValue = [
    menus[0],
    // menus[1],
    // menus[2],
    // menus[4],
    // menus[5],
    // menus[11],
    // menus[7],
    // menus[8],
    // menus[12],
    menus[14],
  ];

  return (
    <WRAPPER>
      <SCROLL_CONTAINER>
        <LOGO>
          <Link to='/'>
            <img src='/Images/tsb/logo_lg.png' alt='tsb' />
          </Link>
        </LOGO>
        <LIST>
          {usingValue.length > 0 &&
            usingValue.map((menu, idx) => <SideBarItem key={menu.path} menu={menu} idx={idx} />)}
          {/* 
          //? 이팁스터 버튼 안보이도록 요청
          <Divider variant="middle" sx={{
            bgcolor: colors.btn_nor,
            margin: "12px 12px 8px 12px"
          }} />
          <EtipsterItem /> */}
        </LIST>
      </SCROLL_CONTAINER>
      <FOOTER>
        <LanguageWindow />
        <UserWindow />
      </FOOTER>
    </WRAPPER>
  );
}

export default Sidebar;

const WRAPPER = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  background-color: ${colors.배경색[900]};
`;

const SCROLL_CONTAINER = styled.div`
  flex: 1;
  ${scrollbarStyle.scroll_4}
`;

const LOGO = styled.div`
  ${spacing.paddingL(4)};
  ${spacing.marginT(7)}
  ${spacing.marginB(12)};
  height: 20px;

  img {
    display: block;
    height: 100%;
    object-fit: cover;
  }
`;

const LIST = styled.div``;

const FOOTER = styled.div`
  display: flex;
  flex-direction: column;
  ${spacing.padding(4)}
  margin-right: auto;
  color: white;
`;
