import React, { useState } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { StatusPlayers } from '../../../../../services/GameServices';

import GameAtom from '../../../../../recoil/ProGameReport/gameAtom';

const ChampionEventBox = ({
  isDeath,
  isOpp,
  status,
  time,
  isMax,
  participant,
}: {
  isDeath: number;
  isOpp: boolean;
  status: StatusPlayers;
  time: number;
  isMax: boolean;
  participant: number;
}) => {
  const { t } = useTranslation();
  const [event, setEvent] = useState({
    type: '',
    time: 0,
    text: '',
  });
  const selectedStatusType = useRecoilValue(GameAtom.selectedStatusType);
  const selectedParticipant = useRecoilValue(GameAtom.selectedParticipant);
  const setSeletedStatusTime = useSetRecoilState(GameAtom.selectedStatusTime);
  const setSeletedStatusType = useSetRecoilState(GameAtom.selectedStatusType);
  const setSeletedStatusText = useSetRecoilState(GameAtom.selectedStatusText);

  useEffect(() => {
    setEvent({
      type: '',
      time,
      text: '',
    });
  }, []);

  const setSelectedStatus = (time: number, type: string, text: string) => {
    if (selectedStatusType !== type) {
      setSeletedStatusTime(time);
      setSeletedStatusType(type);
      setSeletedStatusText(text);
    }
  };

  const getStatue = () => {
    const type = {
      type: status.type,
      time,
      text: t(`game.teamStatus.player.${status.type}`),
    };

    // text에 .teamStatus.가 있으면 변환이 안되는 것으로 ""(공백)처리를 하도록 임의로 지정
    if (isMax) {
      if (status.type !== event.type) {
        if (type.text === '' || type.text.includes('.teamStatus.')) {
          setEvent({
            type: status.type,
            time: 0,
            text: '',
          });
        } else {
          setEvent(type);
        }
      }
    } else if (status.type !== event.type) {
      if (type.text === '' || type.text.includes('.teamStatus.')) {
        setEvent({
          type: status.type,
          time: 0,
          text: '',
        });
      } else {
        setEvent(type);
      }
    }
    if (selectedParticipant === participant) {
      setSelectedStatus(event.time, event.type, event.text);
    }
  };

  useEffect(() => {
    getStatue();
  }, [time]);

  return (
    <EventBox isDeath={isDeath || isMax} isOpp={isOpp}>
      {/* {isOpp && <div className="event-img"></div>} */}
      <div className='desc'>
        {/* <div className="time">{event.time > 0 && secToMS(event.time)}</div> */}
        <div className='status'>{event.text}</div>
      </div>
      {/* {!isOpp && <div className="event-img"></div>} */}
    </EventBox>
  );
};

export default ChampionEventBox;

const EventBox = styled.div<{ isDeath: boolean | number; isOpp: boolean }>`
  ${(props) => props.isDeath && `opacity: 0.3;`};
  margin: 4px 0 4px 2px;
  width: 100%;
  height: 21px;
  display: flex;
  .desc {
    width: 88px;
    // height: 28px;
    .time {
      height: 13px;
      font-family: SpoqaHanSansNeo;
      font-size: 10px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: ${(props) => (props.isOpp ? 'right' : 'left')};
      color: #fff;
    }
    .status {
      font-family: SpoqaHanSansNeo;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: ${(props) => (props.isOpp ? 'right' : 'left')};
      color: #fff;
    }
  }
  .event-img {
    visibility: hidden;
    margin-left: 2px;
    width: 28px;
    height: 28px;
    object-fit: contain;
    background-color: #fff;
  }
`;
