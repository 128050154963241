import React, { useState } from 'react';
import { DesktopDatePicker } from '@mui/lab';
import { TextField } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from '../../../Styles/ui';

interface MUICalendarProps {
  onChange: (date: string | undefined) => void;
  value: number | null;
}

export const MUICalendar: React.FC<MUICalendarProps> = ({ value, onChange }) => {
  const handleChange = (newValue: number | null) => {
    onChange(newValue?.toString());
  };

  return (
    <DesktopDatePicker
      value={value}
      inputFormat='YYYY-MM-DD'
      mask='____-__-__'
      onChange={handleChange}
      disableFuture
      PaperProps={{
        sx: {
          backgroundColor: '#3a3a49',
          color: 'white',

          '& .PrivatePickersSlideTransition-root': {
            marginTop: '20px',
            minHeight: '244px',
          },
          '.MuiButtonBase-root.Mui-disabled.MuiPickersDay-root ': {
            color: 'white',
            opacity: 0.3,
          },

          '& .MuiSvgIcon-root': {
            color: 'white',
          },
          '& .MuiTypography-root': {
            color: 'white',
          },
          '& .MuiPickersDay-root': {
            backgroundColor: '#3a3a49',
            color: 'white',
            '&:hover': {
              backgroundColor: colors.보라색[700],
            },
          },

          '& .Mui-selected': {
            backgroundColor: colors.보라색[700],
          },

          '& .MuiPickersDay-today': {
            '&:not(.Mui-selected)': {
              border: `1px solid ${colors.배경색[300]}`,
            },
          },
        },
      }}
      renderInput={(params) => <INPUTFIELD {...params} />}
    />
  );
};

const INPUTFIELD = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'rgba(89, 66, 186, 0.2)',
    color: 'white',
    padding: '12px',
    borderRadius: '8px',
    fontSize: '13px',
    border: `1px solid ${colors.보라색[500]}`,
    width: `188px`,
    height: `40px`,

    '& fieldset': {
      display: 'none',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: 0,
  },
  '& label.Mui-focused': {},
  '& .MuiInput-underline:after': {},
  '& .MuiSvgIcon-root': {
    color: 'white',
  },
});
