import React, { forwardRef } from 'react';
import styled from '@emotion/styled/macro';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { borderRadiusStyle, colors, spacing, typoStyle } from '../../../../Styles/ui';
import Avatar from '../../../../Components/Ui/Avatar';
import { SELECTED_YEAR } from '../../../config';

function MSI텍스트변환(string: any) {
  let resultText = '';

  const splitLabel = string.split(SELECTED_YEAR.toString());
  const firstLabel = splitLabel[1].split('_')[0].trim(); //'Mid-Season Invitational'
  const secondLabel = splitLabel[1].split('_')[1];
  const sanitizedFirstLabel = firstLabel.includes('Mid-Season') && 'MSI';
  resultText = sanitizedFirstLabel + ' ' + secondLabel;

  return resultText;
}

function 텍스트변환(string: any) {
  let resultText = '';

  const [league, ...rest] = string.split('Season/');
  const splitText = rest
    .join(' ')
    .replace(/2_W/g, ' W')
    .replace(/\s{2}|Season/gi, '')
    .split('_');
  splitText.pop();
  resultText = splitText.join(' ');

  return resultText;
}

// 테스트코드
// somting string =>  [summer1, summer1] => [summer1]
// somting string => [summer1, summer2] => [summer1,summer2]

const DateListRenewal = forwardRef(({ list }, ref) => {
  const labelSet = [...new Set(list.map((item) => 텍스트변환(item.title)))];
  const MSIlabelSet = [...new Set(list.map((item) => MSI텍스트변환(item.title)))];
  const firstGameTime = list[0]?.time; // 1652580000 unix
  // 경기 날짜 관련
  const locale = useSelector((state) => state.LocaleReducer);
  const gameDate = dayjs(firstGameTime * 1000); // sec to milisec
  const localeGameDate = locale === 'ko' ? gameDate.locale('ko') : gameDate;

  const now = dayjs().format('YYYY-MM-DD hh:mm:ss').toString().slice(0, 10);

  // 오늘 , 경기가 끝났는지 판별
  const isToday =
    dayjs(firstGameTime * 1000)
      .format('YYYY-MM-DD hh:mm:ss')
      .toString()
      .slice(0, 10) === now;
  const isComplete = gameDate.unix() < dayjs(now).unix();

  return (
    <ListItem ref={ref}>
      <Date>
        <span>{localeGameDate.format(`M.D`)}</span>
        <span>
          {localeGameDate.format('dddd')}
          {isToday ? `(${t('league.schedule.today')})` : ''}
        </span>
      </Date>

      <GameInfo>
        {list.map((games, _) => {
          const {
            winner,
            homeTeam: { score: homeScore, teamShort: homeShort, teamName: homeName, winRate: homeWinRate },
            awayTeam: { score: awayScore, teamShort: awayShort, teamName: awayName, winRate: awayWinRate },
            title,
          } = games;

          let labeltext;

          // MSI
          if (list[0].league === 'MSI') {
            if (MSIlabelSet.length > 0 && MSIlabelSet[0] === MSI텍스트변환(title)) {
              labeltext = MSIlabelSet.shift();
            }
          } else {
            // MSI 외
            if (labelSet.length > 0 && labelSet[0] === 텍스트변환(title)) {
              labeltext = labelSet.shift();
            }
          }

          return (
            <GameWrapper>
              {labeltext && <Label>{labeltext as string}</Label>}
              <GameList key={title + _}>
                <TeamWrapper className='left'>
                  <TeamLogo>
                    <Avatar
                      src={`/Images/TeamLogo/${homeShort.toUpperCase()}.png`}
                      alt={homeShort ?? 'TBD'}
                      size={40}
                      circle={false}
                    />
                  </TeamLogo>
                  <Team className='left'>
                    {homeShort === 'Saigon Buffalo' ? (
                      <span>SGB</span>
                    ) : (
                      <span>{homeName ? `${homeName}` : 'TBD'}</span>
                    )}
                  </Team>
                </TeamWrapper>

                {isComplete ? (
                  // 경기 종료시 보여주는 UI
                  <>
                    <Percent className='left' winner={winner === 'home'}>
                      {homeShort !== 'TBD' && homeWinRate ? `${homeWinRate.toFixed(1)}%` : ''}
                    </Percent>
                    <Score>
                      <Detail winner={winner === 'home'}>{homeScore < 0 ? '' : homeScore}</Detail> :
                      <Detail winner={winner === 'away'}>{awayScore < 0 ? '' : awayScore}</Detail>
                    </Score>
                    <Percent className='right' winner={winner === 'away'}>
                      {homeShort !== 'TBD' && awayWinRate ? `${awayWinRate.toFixed(1)}%` : ''}
                    </Percent>
                  </>
                ) : (
                  // 경기 시작전 보여주는 UI
                  <VSContainer>
                    <Prediction className='left' winner={homeWinRate > awayWinRate}>
                      {homeShort !== 'TBD' && homeWinRate ? `${homeWinRate.toFixed(1)}` : ''}
                      <span style={{ fontSize: 15, marginLeft: 4 }}>%</span>
                    </Prediction>
                    :
                    <Prediction className='right' winner={homeWinRate < awayWinRate}>
                      {homeShort !== 'TBD' && awayWinRate ? `${awayWinRate.toFixed(1)}` : ''}
                      <span style={{ fontSize: 15, marginLeft: 4 }}>%</span>
                    </Prediction>
                  </VSContainer>
                )}

                <TeamWrapper className='right'>
                  <Team className='right'>
                    {awayShort === 'Saigon Buffalo' ? (
                      <span>SGB</span>
                    ) : (
                      <span>{awayName ? `${awayName}` : 'TBD'}</span>
                    )}
                  </Team>
                  <TeamLogo>
                    <Avatar
                      src={`/Images/TeamLogo/${awayShort.toUpperCase()}.png`}
                      size={40}
                      alt={awayShort ?? 'TBD'}
                      circle={false}
                    />
                  </TeamLogo>
                </TeamWrapper>
              </GameList>
            </GameWrapper>
          );
        })}
      </GameInfo>
    </ListItem>
  );
});

export default DateListRenewal;

const ListItem = styled.li`
  margin-bottom: 32px;
`;

const Date = styled.div`
  font-size: 18px;
  font-weight: bold;

  span {
    &:first-child {
      margin-right: 4px;
    }
  }
`;
const Contents = styled.div``;

const GameWrapper = styled.div`
  position: relative;
`;

const GameInfo = styled.ul`
  flex: 1 0;
  margin-top: 8px;

  &.is-complete {
    > li {
      background-color: ${colors.bg_box};
    }
  }
`;
const GameList = styled.li`
  &:not(:last-of-type) {
    ${spacing.marginB(2)};
  }
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  align-items: center;
  padding: 4px 0;
  border-bottom: 1px solid #3a3745;
`;

const Label = styled.div`
  background-color: #2d2c36;
  height: 32px;
  ${spacing.paddingY(1.5)}
  ${spacing.paddingX(3)}
  ${typoStyle.contents_md}
  text-align: center;
`;

const VSContainer = styled.div``;

const TeamWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 565px;

  &.left {
    justify-content: flex-start;
  }
  &.right {
    justify-content: flex-end;
  }
`;
const Team = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  height: 100%;

  &.right {
    align-items: flex-end;
  }
  &.left {
    align-items: flex-start;
  }

  span {
    &:first-of-type {
      ${typoStyle.contents_md};
    }
  }
`;
const TeamLogo = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  text-align: left;
`;
const Score = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 110px;
  height: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
`;

const Detail = styled.div<{ winner: boolean }>`
  font-weight: ${({ winner }) => (winner ? '700' : '300')};
  opacity: ${({ winner }) => (winner ? '1' : '0.3')};
`;

const Side = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 100%;
  text-align: center;
`;

const Percent = styled.span<{ winner: boolean }>`
  font-size: 15px;
  color: ${({ winner }) => (winner ? '#a9a1d6' : '#615c7c')};

  &.left {
    margin-right: 40px;
  }
  &.right {
    margin-left: 40px;
  }
`;

const Prediction = styled.span<{ winner: boolean }>`
  font-size: 24px;
  font-weight: ${({ winner }) => (winner ? '700' : '300')};
  opacity: ${({ winner }) => (winner ? '1' : '0.3')};
  color: #fff;

  &.left {
    margin-right: 40px;
  }
  &.right {
    margin-left: 40px;
  }
`;
