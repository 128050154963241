import { AxiosError, AxiosResponse } from 'axios';
import { ScrimViewData } from '../Hooks/useGetScim';
import { axiosPost } from './axios/axios';

export interface ScrimViewData {
  gameid: string;
  date: string;
  red: {
    result: number;
    pick: {
      fpick: string;
      pos: string;
      champ: string;
    }[];
    teamid: 200 | 100;
    team: string;
    ban: (number | string)[];
  };
  blue: {
    result: number;
    pick: {
      fpick: string;
      pos: string;
      champ: string;
    }[];
    teamid: 200 | 100;
    team: string;
    ban: (number | string)[];
  };
}

export type ScrimAction =
  | { type: 'RESET'; payload: ScrimData }
  | { type: 'LOAD'; payload: ViewData }
  | { type: 'BLUE_BAN_1'; payload: string }
  | { type: 'BLUE_BAN_2'; payload: string }
  | { type: 'BLUE_BAN_3'; payload: string }
  | { type: 'BLUE_BAN_4'; payload: string }
  | { type: 'BLUE_BAN_5'; payload: string }
  | { type: 'BLUE_RESET_BAN_1'; payload: string }
  | { type: 'BLUE_RESET_BAN_2'; payload: string }
  | { type: 'BLUE_RESET_BAN_3'; payload: string }
  | { type: 'BLUE_RESET_BAN_4'; payload: string }
  | { type: 'BLUE_RESET_BAN_5'; payload: string }
  | { type: 'BLUE_PICK_POS_1'; payload: Position }
  | { type: 'BLUE_PICK_POS_2'; payload: Position }
  | { type: 'BLUE_PICK_POS_3'; payload: Position }
  | { type: 'BLUE_PICK_POS_4'; payload: Position }
  | { type: 'BLUE_PICK_POS_5'; payload: Position }
  | { type: 'BLUE_PICK_CHAMP_1'; payload: string }
  | { type: 'BLUE_PICK_CHAMP_2'; payload: string }
  | { type: 'BLUE_PICK_CHAMP_3'; payload: string }
  | { type: 'BLUE_PICK_CHAMP_4'; payload: string }
  | { type: 'BLUE_PICK_CHAMP_5'; payload: string }
  | { type: 'RED_BAN_1'; payload: string }
  | { type: 'RED_BAN_2'; payload: string }
  | { type: 'RED_BAN_3'; payload: string }
  | { type: 'RED_BAN_4'; payload: string }
  | { type: 'RED_BAN_5'; payload: string }
  | { type: 'RED_RESET_BAN_1'; payload: string }
  | { type: 'RED_RESET_BAN_2'; payload: string }
  | { type: 'RED_RESET_BAN_3'; payload: string }
  | { type: 'RED_RESET_BAN_4'; payload: string }
  | { type: 'RED_RESET_BAN_5'; payload: string }
  | { type: 'RED_PICK_POS_1'; payload: Position }
  | { type: 'RED_PICK_POS_2'; payload: Position }
  | { type: 'RED_PICK_POS_3'; payload: Position }
  | { type: 'RED_PICK_POS_4'; payload: Position }
  | { type: 'RED_PICK_POS_5'; payload: Position }
  | { type: 'RED_PICK_CHAMP_1'; payload: string }
  | { type: 'RED_PICK_CHAMP_2'; payload: string }
  | { type: 'RED_PICK_CHAMP_3'; payload: string }
  | { type: 'RED_PICK_CHAMP_4'; payload: string }
  | { type: 'RED_PICK_CHAMP_5'; payload: string };

interface IResponseSuccess<T> {
  response: T;
  status: string;
  message: string;
}

export interface GetScrimParams {
  team: string;
  startDate?: number;
  endDate?: number;
  oppLeague?: string;
  oppTeam?: string;
}

type PickState = {
  pos: number;
  champ: number;
};

export interface AddScrimParams {
  date: string;
  result: number;
  user: {
    team: string;
    teamid: number;
    ban: number[];
    pick: PickState[];
  };
  opp: {
    oppleague: string;
    team: string;
    teamid: number;
    ban: number[];
    pick: PickState[];
  };
}

type Position = 1 | 2 | 3 | 4 | 5;

export interface ScrimBanpickParams {
  team: string;
  startDate: number;
  enddate: number;
  position: Position;
}

export interface ScrimDeleteParams {
  gameid: string;
}
export interface ScrimTeamStatParams {
  team: string;
  startDate: number;
  endDate: number;
}
export type Champion = undefined | number;

export interface PickBanData {
  ban: (undefined | number)[];
  pick: {
    pos: undefined | Position;
    champ: Champion;
  }[];
}
export interface ScrimData {
  100: PickBanData;
  200: PickBanData;
}

export interface ViewData {
  red: PickBanData & {
    result: 0 | 1;
    team: string;
    teamid: 100 | 200;
  };
  blue: PickBanData & {
    result: 0 | 1;
    team: string;
    teamid: 100 | 200;
  };
  gameid: string;
  date: string;
}

export type EditParams = AddScrimParams & { gameid: string; opp: Omit<AddScrimParams['opp'], 'oppleague'> };

class ScrimServices {
  BlueBan: ScrimAction['type'][] = ['BLUE_BAN_1', 'BLUE_BAN_2', 'BLUE_BAN_3', 'BLUE_BAN_4', 'BLUE_BAN_5'];
  RedBan: ScrimAction['type'][] = ['RED_BAN_1', 'RED_BAN_2', 'RED_BAN_3', 'RED_BAN_4', 'RED_BAN_5'];
  BluePickPos: ({ pick: ScrimAction['type']; pos: ScrimAction['type']; index: number } | undefined)[] = [
    { pick: 'BLUE_PICK_CHAMP_1', pos: 'BLUE_PICK_POS_1', index: 0 },
    undefined,
    undefined,
    { pick: 'BLUE_PICK_CHAMP_2', pos: 'BLUE_PICK_POS_2', index: 1 },
    { pick: 'BLUE_PICK_CHAMP_3', pos: 'BLUE_PICK_POS_3', index: 2 },
    undefined,
    undefined,
    { pick: 'BLUE_PICK_CHAMP_4', pos: 'BLUE_PICK_POS_4', index: 3 },
    { pick: 'BLUE_PICK_CHAMP_5', pos: 'BLUE_PICK_POS_5', index: 4 },
    undefined,
  ];
  RedPickPos: ({ pick: ScrimAction['type']; pos: ScrimAction['type']; index: number } | undefined)[] = [
    undefined,
    { pick: 'RED_PICK_CHAMP_1', pos: 'RED_PICK_POS_1', index: 0 },
    { pick: 'RED_PICK_CHAMP_2', pos: 'RED_PICK_POS_2', index: 1 },
    undefined,
    undefined,
    { pick: 'RED_PICK_CHAMP_3', pos: 'RED_PICK_POS_3', index: 2 },
    { pick: 'RED_PICK_CHAMP_4', pos: 'RED_PICK_POS_4', index: 3 },
    undefined,
    undefined,
    { pick: 'RED_PICK_CHAMP_5', pos: 'RED_PICK_POS_5', index: 4 },
  ];

  private 자리수찾기 = (num: number) => (num + 1).toString();
  private 몫변환 = (num: number) => {
    switch (num) {
      case 0:
        return 'BLUE_BAN_';

      case 1:
        return 'BLUE_PICK_CHAMP_';

      case 2:
        return 'RED_BAN_';

      case 3:
        return 'RED_PICK_CHAMP_';

      default:
        return '';
    }
  };

  // state 만들기
  makeNewScrimData(): ScrimData {
    return {
      100: {
        ban: [undefined, undefined, undefined, undefined, undefined],
        pick: [
          {
            pos: undefined,
            champ: undefined,
          },
          {
            pos: undefined,
            champ: undefined,
          },
          {
            pos: undefined,
            champ: undefined,
          },
          {
            pos: undefined,
            champ: undefined,
          },
          {
            pos: undefined,
            champ: undefined,
          },
        ],
      },
      200: {
        ban: [undefined, undefined, undefined, undefined, undefined],
        pick: [
          {
            pos: undefined,
            champ: undefined,
          },
          {
            pos: undefined,
            champ: undefined,
          },
          {
            pos: undefined,
            champ: undefined,
          },
          {
            pos: undefined,
            champ: undefined,
          },
          {
            pos: undefined,
            champ: undefined,
          },
        ],
      },
    };
  }
  ScrimReducer(state: ScrimData, action: ScrimAction) {
    const result: ScrimData = { ...state };
    switch (action.type) {
      case 'RESET':
        return action.payload;
      case 'LOAD':
        result[100].ban = [...action.payload.blue.ban];
        result[100].pick = [
          ...action.payload.blue.pick.map((p) => {
            delete p.fpick;
            return p;
          }),
        ];
        result[200].ban = [...action.payload.red.ban];
        result[200].pick = [
          ...action.payload.red.pick.map((p) => {
            delete p.fpick;
            return p;
          }),
        ];

        return {
          ...result,
        };
      case 'BLUE_BAN_1':
        result[100].ban[0] = Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;
      case 'BLUE_BAN_2':
        result[100].ban[1] = Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;
      case 'BLUE_BAN_3':
        result[100].ban[2] = Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;
      case 'BLUE_BAN_4':
        result[100].ban[3] = Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;
      case 'BLUE_BAN_5':
        result[100].ban[4] = Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;

      case 'BLUE_RESET_BAN_1':
        result[100].ban[0] = undefined;
        return result;
      case 'BLUE_RESET_BAN_2':
        result[100].ban[1] = undefined;
        return result;
      case 'BLUE_RESET_BAN_3':
        result[100].ban[2] = undefined;
        return result;
      case 'BLUE_RESET_BAN_4':
        result[100].ban[3] = undefined;
        return result;
      case 'BLUE_RESET_BAN_5':
        result[100].ban[4] = undefined;
        return result;

      case 'BLUE_PICK_POS_1':
        result[100].pick[0].pos =
          Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;
      case 'BLUE_PICK_POS_2':
        result[100].pick[1].pos =
          Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;
      case 'BLUE_PICK_POS_3':
        result[100].pick[2].pos =
          Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;
      case 'BLUE_PICK_POS_4':
        result[100].pick[3].pos =
          Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;
      case 'BLUE_PICK_POS_5':
        result[100].pick[4].pos =
          Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;

      case 'BLUE_PICK_CHAMP_1':
        result[100].pick[0].champ = Number(action.payload) === 0 ? undefined : Number(action.payload);
        return result;
      case 'BLUE_PICK_CHAMP_2':
        result[100].pick[1].champ = Number(action.payload) === 0 ? undefined : Number(action.payload);
        return result;
      case 'BLUE_PICK_CHAMP_3':
        result[100].pick[2].champ = Number(action.payload) === 0 ? undefined : Number(action.payload);
        return result;
      case 'BLUE_PICK_CHAMP_4':
        result[100].pick[3].champ = Number(action.payload) === 0 ? undefined : Number(action.payload);
        return result;
      case 'BLUE_PICK_CHAMP_5':
        result[100].pick[4].champ = Number(action.payload) === 0 ? undefined : Number(action.payload);
        return result;

      case 'RED_BAN_1':
        result[200].ban[0] = Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;
      case 'RED_BAN_2':
        result[200].ban[1] = Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;
      case 'RED_BAN_3':
        result[200].ban[2] = Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;
      case 'RED_BAN_4':
        result[200].ban[3] = Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;
      case 'RED_BAN_5':
        result[200].ban[4] = Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;

      case 'RED_RESET_BAN_1':
        result[200].ban[0] = undefined;
        return result;
      case 'RED_RESET_BAN_2':
        result[200].ban[1] = undefined;
        return result;
      case 'RED_RESET_BAN_3':
        result[200].ban[2] = undefined;
        return result;
      case 'RED_RESET_BAN_4':
        result[200].ban[3] = undefined;
        return result;
      case 'RED_RESET_BAN_5':
        result[200].ban[4] = undefined;
        return result;

      case 'RED_PICK_POS_1':
        result[200].pick[0].pos =
          Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;
      case 'RED_PICK_POS_2':
        result[200].pick[1].pos =
          Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;
      case 'RED_PICK_POS_3':
        result[200].pick[2].pos =
          Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;
      case 'RED_PICK_POS_4':
        result[200].pick[3].pos =
          Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;
      case 'RED_PICK_POS_5':
        result[200].pick[4].pos =
          Number(action.payload) === 0 ? undefined : (Number(action.payload) as Position);
        return result;

      case 'RED_PICK_CHAMP_1':
        result[200].pick[0].champ = Number(action.payload) === 0 ? undefined : Number(action.payload);
        return result;
      case 'RED_PICK_CHAMP_2':
        result[200].pick[1].champ = Number(action.payload) === 0 ? undefined : Number(action.payload);
        return result;
      case 'RED_PICK_CHAMP_3':
        result[200].pick[2].champ = Number(action.payload) === 0 ? undefined : Number(action.payload);
        return result;
      case 'RED_PICK_CHAMP_4':
        result[200].pick[3].champ = Number(action.payload) === 0 ? undefined : Number(action.payload);
        return result;
      case 'RED_PICK_CHAMP_5':
        result[200].pick[4].champ = Number(action.payload) === 0 ? undefined : Number(action.payload);
        return result;

      default:
        return state;
    }
  }

  // 중복체크
  픽밴중복챔피언찾기 = (state: ScrimData) => {
    const { ban: blueBan, pick: bluePick } = state[100];
    const { ban: redBan, pick: redPick } = state[200];

    const PickBanArr = [
      ...blueBan.map((num) => (num === 0 ? undefined : num)),
      ...bluePick.map((_) => _.champ),
      ...redBan.map((num) => (num === 0 ? undefined : num)),
      ...redPick.map((_) => _.champ),
    ];

    const pickObject = PickBanArr.reduce((a: { [key: number]: number }, b: number | undefined) => {
      if (typeof b !== 'number') return a;

      if (Object.prototype.hasOwnProperty.call(a, b)) {
        a[b] += 1;
      } else {
        a[b] = 1;
      }
      return a;
    }, {});

    const 중복챔피언 = Object.entries<number>(pickObject)
      .filter((arr) => arr[1] > 1)
      .map((arr) => Number(arr[0]));

    if (중복챔피언.length === 0) return 중복챔피언;

    const index: number[] = [];

    PickBanArr.forEach((champ, i) => {
      if (typeof champ !== 'number') return;
      if (중복챔피언.includes(champ)) {
        index.push(i);
      }
    });

    return index;
  };

  중복포지션찾기 = (state: ScrimData, pos: 'red' | 'blue') => {
    const side = pos === 'blue' ? 100 : 200;
    const { pick } = state[side];
    const positionArray = pick.map((list) => list.pos);
    // 각 팀 포지션 배열

    const resultArray: number[] = [];
    const positionObject = positionArray.reduce((a: { [key: number]: number }, b: number | undefined) => {
      if (typeof b !== 'number') return a;
      if (Object.prototype.hasOwnProperty.call(a, b)) {
        a[b] += 1;
      } else {
        a[b] = 1;
      }
      return a;
    }, {});
    const 중복포지션 = Object.entries(positionObject)
      .filter((arr) => arr[1] > 1)
      .map((arr) => Number(arr[0]));

    if (중복포지션.length === 0) return 중복포지션;

    positionArray.forEach((pos, i) => {
      if (typeof pos !== 'number') return;
      if (중복포지션.includes(pos)) {
        resultArray.push(i);
      }
    });
    return resultArray;
  };

  픽밴중복메세지변환 = (arr: number[]) =>
    arr.map((number) => {
      const 나머지 = number % 5;
      const 몫 = parseInt(number / 5);

      return `${this.몫변환(몫)}${this.자리수찾기(나머지)}`;
    });

  포지션중복메세지변환 = (arr: number[], team: 'blue' | 'red') =>
    arr.map((number) => {
      if (team === 'blue') {
        return `BLUE_PICK_POS_${number + 1}`;
      }
      return `RED_PICK_POS_${number + 1}`;
    });

  // async fetch
  // team 배열로 안들어가면 에러가 뜸
  async addScrim(data: AddScrimParams): Promise<IResponseSuccess<any>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/scrim/add',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async getScrim(data: GetScrimParams): Promise<IResponseSuccess<ScrimViewData>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/scrim/view',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async getScrimBanpick(data: ScrimBanpickParams): Promise<IResponseSuccess<any>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/scrim/banPick',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async deleteScrim(data: ScrimDeleteParams): Promise<IResponseSuccess<any>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/scrim/delete',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async getScrimTeamStat(data: ScrimTeamStatParams): Promise<IResponseSuccess<any>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/scrim/team',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async getScrimId({
    gameid,
    userTeam,
  }: {
    gameid: string;
    userTeam: string;
  }): Promise<IResponseSuccess<ScrimViewData[]>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/scrim/view',
        data: {
          gameid,
          userTeam,
        },
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async editScrim({ gameid, date, result, user, opp }: EditParams): Promise<IResponseSuccess<any>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/scrim/edit',
        data: {
          gameid,
          date,
          result,
          user,
          opp,
        },
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async getScrimBanpickAnalysis(data: GetScrimParams): Promise<IResponseSuccess<ScrimViewData>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/scrim/analysis',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }
}

export default new ScrimServices();
