import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import CustomCheckbox from './CustomCheckbox';
import { isObjEqual } from '../../lib/isObjEqual';
import { borderRadiusStyle, colors, transitionStyle, typoStyle } from '../../Styles/ui';

// 포지션 정보
const POSITION_IMG = {
  all: 'ALL',
  top: <img src='/Images/position/ico-position-top.svg' alt='top' />,
  jng: <img src='/Images/position/ico-position-jng.svg' alt='jng' />,
  mid: <img src='/Images/position/ico-position-mid.svg' alt='mid' />,
  bot: <img src='/Images/position/ico-position-bot.svg' alt='bot' />,
  sup: <img src='/Images/position/ico-position-sup.svg' alt='sup' />,
};

const PositionCheckList = ({
  all = true,
  multi = true,
  defaultColor = 'rgba(255,255,255,0.3)',
  iconBgColor = colors.배경색[600],
  hoverColor = colors.bg_checkbox,
  position = {
    all: false,
    top: false,
    jng: false,
    mid: false,
    bot: false,
    sup: false,
  },
  width = '50px',
  height = '50px',
  borderRadius = '10px',
  marginRight = '6px',
  setPosition = () => {},
  error = false,
  isScrimPage = false,
  posError,
  ...props
}) => {
  const handleChangeMulti = (e) => {
    const { name, value, checked } = e.target;
    if (value === 'all') {
      setPosition((prev) => {
        const newPosition = { ...prev };
        Object.keys(newPosition).forEach((key) => {
          newPosition[key] = checked;
        });
        return newPosition;
      });
    } else {
      setPosition((prev) => {
        const newPosition = { ...prev };
        newPosition[value] = checked;

        if (isObjEqual(newPosition)) {
          newPosition.all = checked;
        } else {
          newPosition.all = false;
        }

        return newPosition;
      });
    }
  };
  const handleChangeSingle = (e) => {
    const { name, value, checked } = e.target;
    if (position[value]) return setPosition({ ...position, [value]: checked });
    setPosition((prev) => {
      const newPosition = { ...prev };
      Object.keys(newPosition).forEach((key) => {
        newPosition[key] = !checked;
      });
      newPosition[value] = checked;
      return newPosition;
    });
  };

  return (
    <div {...props}>
      <SList>
        {Object.keys(position)
          .filter((pos) => {
            if (!all || !multi) return pos !== 'all';
            return true;
          })
          .map((key, i) => {
            return (
              <SListItem key={key} marginRight={marginRight}>
                <SCustomCheckbox
                  name='position'
                  value={key}
                  onChange={multi ? handleChangeMulti : handleChangeSingle}
                  checked={position[key]}
                  posError={position[key] && posError}
                  defaultcolor={defaultColor}
                  hovercolor={hoverColor}
                >
                  <SIcon width={width} height={height} borderRadius={borderRadius}>
                    <span>{POSITION_IMG[key]}</span>
                  </SIcon>
                </SCustomCheckbox>
              </SListItem>
            );
          })}
      </SList>
    </div>
  );
};

const SList = styled.ul`
  display: flex;
`;

const SCustomCheckbox = styled(CustomCheckbox)`
  overflow: hidden;
  ${borderRadiusStyle[10]}
  ${transitionStyle.background}
  background-color: ${({ defaultcolor }) => defaultcolor};

  &.is-active {
    border: ${({ posError }) => (posError ? `1px solid ${colors.badge_red}` : `1px solid ${colors.point}`)};
    background-color: ${({ hovercolor }) => hovercolor};

    > div {
      background-color: ${({ posError }) => (posError ? 'rgba(240, 69, 69, 0.2)' : '')};
      span {
        opacity: 1;
      }
    }
  }
`;

const SIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: ${({ borderRadius }) => borderRadius};
  ${typoStyle.button_popin}

  span {
    line-height: 0;
    opacity: 0.3;
    ${transitionStyle.opacity}
    img {
      width: 21px;
      height: 21px;
    }
  }

  &:hover {
    span {
      opacity: 1;
    }
  }
`;

const SListItem = styled.li`
  &:not(:last-child) {
    margin-right: ${({ marginRight }) => marginRight};
  }
`;

export default PositionCheckList;
