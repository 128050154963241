import { resolve } from 'path';
import { AxiosError, AxiosResponse } from 'axios';
import { axiosPost } from './axios/axios';

interface FilterParam {
  league: string[];
  year: string[];
  season: string[];
}

interface IResponseSuccess {
  response: any;
  status: string;
  message: string;
}

interface IResponseSchedule extends IResponseSuccess {
  response: {
    date: string;
    matchs: string[];
    index: number;
  }[];
}

class CalendarService {
  async getCalendarFilteData(data: FilterParam): Promise<IResponseSchedule> {
    const gml = new Date().getTimezoneOffset();
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/filter/schedule',
        data: { ...data, gml },
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }
}

export default new CalendarService();
