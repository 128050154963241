import React, { useCallback, useState } from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled/macro';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { colors, fontsStyle } from '../../../../Styles/ui';
import ScrimDraftBox from '../../../Scrim/components/ScrimDraftBox';
import * as S from '../../../Scrim/components/ScrimResultItem.style';
import { ChampionListAtom, LOLPatchAtom } from '../../../../recoil/common/championListAtom';
import { userLanguageAtom } from '../../../../recoil/Auth/userAtom';
import Pick from '../../../Scrim/components/Pick';
import { ITeamDraftResponse } from '../../../../services/types/team_services.type';
import useParseChampionImagePath from '../../../../Hooks/useParseChampionImagePath';

function convertPosition(pos: number) {
  switch (pos) {
    case 1:
      return 'top';
    case 2:
      return 'jng';
    case 3:
      return 'mid';
    case 4:
      return 'bot';
    case 5:
      return 'sup';
    default:
      return '';
  }
}

function convertTeamLogo(teamShort: string) {
  const teamName = teamShort.toUpperCase();
  if (teamName === '관리자' || teamName === 'TBD') {
    return `/Images/TeamLogo/TBD.png`;
  }
  return `/Images/TeamLogo/${teamName}.png`;
}

interface P {
  data: ITeamDraftResponse;
}

const DraftContant: React.FC<P> = ({ data }) => {
  console.log('DraftContant', data);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(['translation', 'scrim']);

  const pick = [
    { ...data.blue.pick[0], side: 'blue' },
    { ...data.red.pick[0], side: 'red' },
    { ...data.red.pick[1], side: 'red' },
    { ...data.blue.pick[1], side: 'blue' },
    { ...data.blue.pick[2], side: 'blue' },
    { ...data.red.pick[2], side: 'red' },
    { ...data.red.pick[3], side: 'red' },
    { ...data.blue.pick[3], side: 'blue' },
    { ...data.blue.pick[4], side: 'blue' },
    { ...data.red.pick[4], side: 'red' },
  ];

  const championList = useRecoilValue(ChampionListAtom);
  const patch = useRecoilValue(LOLPatchAtom);
  const lang = useRecoilValue(userLanguageAtom);

  const parseChampionImagePath = useParseChampionImagePath(patch, championList);

  // utill function
  const parseImgPath = useCallback((champion) => parseChampionImagePath(champion), [parseChampionImagePath]);

  const handleClickExpand = () => setIsOpen(!isOpen);

  return (
    <SDraftContainer>
      <SDraftHeader>
        <span className='title'>{`${data.title.replaceAll('_', ' ')} ${data.set}set`}</span>
        <span>{data.date}</span>
      </SDraftHeader>

      <Stack direction='row'>
        <Box>
          <Box sx={{ mb: 1 }}>
            <ScrimDraftBox color='blue' data={data.blue} pick={pick} />
          </Box>

          <Box>
            <ScrimDraftBox color='red' data={data.red} pick={pick} />
          </Box>
        </Box>
        <S.BTN_EXPAND variant='contained' color='secondary' onClick={handleClickExpand} isOpen={isOpen}>
          <ArrowDropDownIcon />
        </S.BTN_EXPAND>
      </Stack>

      {/* 확장 */}
      {isOpen && (
        <Stack direction='row' spacing={1.5} sx={{ color: 'white', padding: '10px 0 52px 0' }}>
          {/* 블루 */}
          <Box sx={{ flex: 1 }}>
            {/* 인포 */}
            <S.Info isBlue>
              <S.WinLabel isWin={data.blue.result === 1}>
                {t(`team.draftGame.${data.blue.result === 1 ? 'victory' : 'defeat'}`)}
              </S.WinLabel>
              <Typography sx={{ mr: 5 }} fontSize={15}>
                {lang === 'ko' ? '블루팀' : 'Blue Team'}
              </Typography>

              <Avatar
                sx={{ width: 40, height: 40, mr: 1 }}
                src={convertTeamLogo(data.blue.team)}
                alt={data.blue.team}
              />

              <Typography fontSize={15}>{data.blue.team}</Typography>
            </S.Info>

            {/* 밴 순서 */}
            <Stack direction='row' sx={{ mt: 5, mb: 3 }}>
              {data.blue.ban.map((banChamp, index) => (
                <S.Ban key={banChamp + index} isLine={banChamp !== '0'}>
                  <img src={parseImgPath(banChamp)} alt={banChamp + index} />
                </S.Ban>
              ))}
            </Stack>

            {/* 픽 순서 */}
            <Stack direction='row' spacing={2.5} sx={{ mb: 3 }}>
              <Stack alignItems='center' width='100%'>
                <S.PickBanInfo>1 Pick</S.PickBanInfo>
                <Pick showName pickObj={data.blue.pick[0]} />
              </Stack>
              <Stack alignItems='center' width='100%'>
                <S.PickBanInfo>2/3 Pick</S.PickBanInfo>
                <Stack direction='row' spacing={2.5}>
                  <Box>
                    <Pick showName pickObj={data.blue.pick[1]} />
                  </Box>
                  <Box>
                    <Pick showName pickObj={data.blue.pick[2]} />
                  </Box>
                </Stack>
              </Stack>

              <Stack alignItems='center' width='100%'>
                <S.PickBanInfo>4/5 Pick</S.PickBanInfo>
                <Stack direction='row' spacing={2.5}>
                  <Box>
                    <Pick showName pickObj={data.blue.pick[3]} />
                  </Box>
                  <Box>
                    <Pick showName pickObj={data.blue.pick[4]} />
                  </Box>
                </Stack>
              </Stack>
            </Stack>

            {/* 포지션 순서 */}
            <Stack direction='row' spacing={2.5}>
              {[1, 2, 3, 4, 5].map((num: number) => (
                <Box key={`${num} position champion ${num}`} sx={{ flex: 1 }}>
                  <S.PickBanInfo>
                    <img
                      src={`/Images/position/ico-position-${convertPosition(num)}.svg`}
                      alt={`${convertPosition(num)}`}
                    />
                  </S.PickBanInfo>
                  <Pick showName pickObj={data.blue.pick.find((champ) => Number(champ.position) === num)!} />
                </Box>
              ))}
            </Stack>
          </Box>

          {/* VS */}
          <Box sx={{ flex: '0 0 36px', textAlign: 'center', fontSize: '13px', paddingTop: 2.5 }}>VS</Box>

          {/* 레드 */}
          <Box sx={{ flex: 1 }}>
            {/* 인포 */}
            <S.Info>
              <S.WinLabel isWin={data.red.result === 1}>
                {t(`team.draftGame.${data.red.result === 1 ? 'victory' : 'defeat'}`)}
              </S.WinLabel>

              <Typography sx={{ mr: 5 }} fontSize={15}>
                {lang === 'ko' ? '레드팀' : 'Red Team'}
              </Typography>

              <Avatar
                sx={{ width: 40, height: 40, mr: 1 }}
                src={convertTeamLogo(data.red.team)}
                alt={data.red.team}
              />

              <Typography fontSize={15}>{data.red.team}</Typography>
            </S.Info>

            {/* 밴 순서 */}
            <Stack direction='row' sx={{ mt: 5, mb: 3 }}>
              {data.red.ban.map((banChamp, index) => (
                <S.Ban key={banChamp + index} isLine={banChamp !== '0'}>
                  <img src={parseImgPath(banChamp)} alt={banChamp + index} />
                </S.Ban>
              ))}
            </Stack>

            {/* 픽 순서 */}
            <Stack direction='row' spacing={2.5} sx={{ mb: 3 }}>
              <Stack alignItems='center' width='100%'>
                <S.PickBanInfo>1/2 Pick</S.PickBanInfo>
                <Stack direction='row' spacing={2.5}>
                  <Box>
                    <Pick showName pickObj={data.red.pick[0]} />
                  </Box>
                  <Box>
                    <Pick showName pickObj={data.red.pick[1]} />
                  </Box>
                </Stack>
              </Stack>
              <Stack alignItems='center' width='100%'>
                <S.PickBanInfo>3 Pick</S.PickBanInfo>
                <Stack direction='row' spacing={2.5}>
                  <Box>
                    <Pick showName pickObj={data.red.pick[2]} />
                  </Box>
                </Stack>
              </Stack>

              <Stack alignItems='center' width='100%'>
                <S.PickBanInfo>4 Pick</S.PickBanInfo>
                <Stack direction='row' spacing={2.5}>
                  <Box>
                    <Pick showName pickObj={data.red.pick[3]} />
                  </Box>
                </Stack>
              </Stack>

              <Stack alignItems='center' width='100%'>
                <S.PickBanInfo>5 Pick</S.PickBanInfo>
                <Stack direction='row'>
                  <Box>
                    <Pick showName pickObj={data.red.pick[4]} />
                  </Box>
                </Stack>
              </Stack>
            </Stack>

            {/* 포지션 순서 */}
            <Stack direction='row' spacing={2.5}>
              {[1, 2, 3, 4, 5].map((num) => (
                <Box key={`${num} position champion ${num}`} sx={{ flex: 1 }}>
                  <S.PickBanInfo>
                    <img
                      src={`/Images/position/ico-position-${convertPosition(Number(num))}.svg`}
                      alt={`${convertPosition(Number(num))}`}
                    />
                  </S.PickBanInfo>
                  <Pick showName pickObj={data.red.pick.find((champ) => Number(champ.position) === num)!} />
                </Box>
              ))}
            </Stack>
          </Box>
        </Stack>
      )}
    </SDraftContainer>
  );
};

export default DraftContant;

const SDraftContainer = styled.div`
  width: 1110px;
  margin: 20px 32px 30px 0;
`;

const SDraftHeader = styled.div`
  font-family: SpoqaHanSansNeo;
  line-height: 1.87;
  letter-spacing: normal;
  text-align: left;
  ${fontsStyle.size[15]}
  color: ${colors.text};

  .title {
    font-weight: bold;
    margin-right: 20px;
  }
  margin-bottom: 12px;
`;
