import { ISoloRankInfo } from '../../services/SoloRankServices';

const getSoloRankInfo = (soloRankInfo: ISoloRankInfo[]) => {
  let allName = '';
  let maxTier = 9;
  let maxRank = 0;
  let maxLP = 0;
  let seasonTotal = 0;
  let seasonWin = 0;
  let seasonlose = 0;
  let lastDayTotal = 0;
  let lastDayWin = 0;
  let lastDayLose = 0;
  const allSummonerIds: string[] = [];
  let addedCount = 0;
  const allSummonerNames: string[] = [];

  soloRankInfo.forEach((data) => {
    if (data.add === 1) {
      addedCount += 1;
    }
    if (Math.abs(data.gathering) === 1) {
      allName += `${data.summonerName ?? ''},`;
      allSummonerIds.push(data.summonerId);
      allSummonerNames.push(data.summonerName);
      if (data.lastSeason) {
        seasonTotal += +data.lastSeason.total ?? 0;
        seasonWin += +data.lastSeason.win ?? 0;
        seasonlose += +data.lastSeason.lose ?? 0;
      }
      if (data.lastDay) {
        lastDayTotal += +data.lastDay.total ?? 0;
        lastDayWin += +data.lastDay.win ?? 0;
        lastDayLose += +data.lastDay.lose ?? 0;
      }

      if (data.tier > 0 && data.tier <= maxTier) {
        if (maxTier !== data.tier) {
          maxRank = 0;
        }
        maxTier = data.tier;

        if (maxRank < data.rank) {
          maxRank = data.rank;
          maxLP = 0;
        }

        if (maxLP < data.leaguePoints) {
          maxLP = data.leaguePoints;
        }
      }
    }
  });

  return {
    allName,
    maxTier,
    maxRank,
    maxLP,
    seasonTotal,
    seasonWin,
    seasonlose,
    lastDayTotal,
    lastDayWin,
    lastDayLose,
    allSummonerIds,
    addedCount,
    allSummonerNames,
  };
};

export default getSoloRankInfo;
