// 현재버전
export const nunuVersion = '1.4.2';
export const API = process.env.REACT_APP_CONFIG;
// API2: 영상보고서
export const API2 = process.env.REACT_APP_CONFIG;

// API3: PI DATA
export const API3 = 'https://nunu.gg:8443/LeagueCollectionPi';
export const API4 = 'http://192.168.0.36:8081/LeagueCollectionPi2';

// 라이엇 최신 버전(json)
export const recentVersion = '12.11.1';
export const SELECTED_YEAR = Number(process.env.REACT_APP_SELECTED_YEAR)