import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import filtervalue from './filtervalue';
import teambanpick from './teambanpick';

import user from './user';
import staticvalue from './staticvalue';
import tablevalue from './tablevalue';
import pivalue from './pivalue';
import selectorvalue from './selectorvalue';
import modalvalue from './modalvalue';
import gamevalue from './gamevalue';
import signvalue from './signvalue';
import junglevalue from './junglevalue';
import playerReportValue from './playerReportValue';
import SoloFilterValue from './solofiltervalue';

// 세션 스토리지에 whitelist에 있는 Redux를 저장.
const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['FilterReducer', 'UserReducer', 'StaticValueReducer', 'SelectorReducer', 'ModalReducer'],
};

const Logout = 'Logout';
export const UserLogout = () => {
  return {
    type: Logout,
  };
};

const appReducer = combineReducers({
  FilterReducer: filtervalue,
  GameReportReducer: gamevalue,
  JungleMapReducer: junglevalue,
  ModalReducer: modalvalue,
  PiAreaReducer: pivalue,
  PlayerReportReducer: playerReportValue,
  SelectorReducer: selectorvalue,
  SignReducer: signvalue,
  SoloFilterReducer: SoloFilterValue,
  StaticValueReducer: staticvalue,
  TableReducer: tablevalue,
  BanPickReducer: teambanpick,
  UserReducer: user,
});

const rootReducer = (state, action) => {
  if (action.type === Logout) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default persistReducer(persistConfig, rootReducer);
