export const MAP_WARD = {
  yellowTrinket: {
    icon: '/Images/ward/yellowtrinket_',
    img: '/Images/ward/img_yellow_ward.png',
  },
  blueTrinket: {
    icon: '/Images/ward/yellowtrinket_',
    img: '/Images/ward/img_blue_ward.png',
  },
  sight: {
    icon: '/Images/ward/yellowtrinket_',
    img: '/Images/ward/img_sight_ward.png',
  },
  control: {
    icon: '/Images/ward/control_',
    img: '/Images/ward/img_control_ward.png',
  },

  // 노답 레거시 와드타입 삼형제 데이터 베이스 변환 후 삭제- 2022.05.11
  'Control Ward': {
    icon: '/Images/ward/control_',
    img: '/Images/ward/img_control_ward.png',
  },

  'Stealth Ward': {
    icon: '/Images/ward/yellowtrinket_',
    img: '/Images/ward/img_yellow_ward.png',
  },
  'Trinket Ward': {
    icon: '/Images/ward/yellowtrinket_',
    img: '/Images/ward/img_yellow_ward.png',
  },
};

export type WardType = keyof typeof MAP_WARD;
