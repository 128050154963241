export const MAP_MONSTER_NAME = {
  블루: 'video.jungle.monsters.blueSentinel',
  심술두꺼비: 'video.jungle.monsters.gromp',
  어스름늑대: 'video.jungle.monsters.murkWolves',
  칼날부리: 'video.jungle.monsters.raptors',
  레드: 'video.jungle.monsters.redBrambleback',
  돌거북: 'video.jungle.monsters.krugs',
  바위게: 'video.jungle.monsters.scuttler',
  화염드래곤: 'video.jungle.monsters.InfernalDragon',
  대지드래곤: 'video.jungle.monsters.MountainDragon',
  바다드래곤: 'video.jungle.monsters.OceanDragon',
  바람드래곤: 'video.jungle.monsters.CloudDragon',
  마법공학드래곤: 'video.jungle.monsters.HextechDragon',
  화학공학드래곤: 'video.jungle.monsters.ChemtechDragon',
  장로드래곤: 'video.jungle.monsters.ElderDragon',
  협곡의전령: 'video.jungle.monsters.RiftHerald',
  내셔남작: 'video.jungle.monsters.BaronNashor',
};
