import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { spacing } from '../../Styles/ui';
import ErrorBoundary from '../../Components/ErrorBoundary';
import LeagueRankRenewal from './components/LeagueRank/LeagueRankRenewal';
import HomeAIRenewal from './components/AI/HomeAIRenewal';

const Home = () => {
  // 홈 화면 컨테이너
  const { t } = useTranslation();
  if (document.title !== `${t('sidebar.part1')} - NUNU.GG`) {
    document.title = `${t('sidebar.part1')} - NUNU.GG`;
  }

  return (
    <ErrorBoundary>
      <HomeWrapper>
        <ContentWrapper>
          {/* GAME SCHEDULE */}
          <HomeAIRenewal />
          {/* MSI Rumble statge */}
          {/* <MSIRumbleStage /> */}
          {/* MSI 2022 GROUP STAGE */}
          {/* <MsiGroupStage /> */}
          {/* LEAGUE RANK */}
          <LeagueRankRenewal />
        </ContentWrapper>
      </HomeWrapper>
    </ErrorBoundary>
  );
};

export default Home;

const HomeWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  background-color: #23212a;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  ${spacing.paddingT(8)};
  ${spacing.paddingL(8)};
  flex-direction: column;
  /* overflow-y: auto; */
`;
