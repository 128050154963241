import { getBanpickRate } from './getBanpickRate';
import { getChampionScore } from './getChampionScore';
import { getChampionTier } from './getChampionTier';

const LOLfunction = {
  getBanpickRate,
  getChampionScore,
  getChampionTier,
};

export default LOLfunction;
