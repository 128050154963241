import { AxiosError, AxiosResponse } from 'axios';
import { axiosPost } from './axios/axios';
import { IResponseSuccess } from './axios/axios.type';
import {
  PlayerCompareParam,
  PlayerCompareStatParam,
  PlayerInfoParam,
  PlayerSoloRankInfoParam,
  SelectedSoloRankInfoParam,
} from './types/param_types';
import { IPlayerDetailResponse, IPlayerListResponse, ITemaAnalysis } from './types/player_services.type';

interface IGetPlayerDashboardParam {
  champion: string;
  league: string[];
  oppchampion: string;
  patch: string[];
  player: string;
  season: string[];
  team: string;
  year: string[];
}

class PlayerServices {
  static instance: PlayerServices;

  getPlayerDashboard(data: IGetPlayerDashboardParam): Promise<IResponseSuccess<ITemaAnalysis>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/player/playerboard',
        data,
      })
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => reject(err));
    });
  }
  getPlayerInfo(data: PlayerInfoParam) {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/player/playerboard',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  // oppplayer 추가해서 요청
  getCompareData(data: PlayerCompareParam) {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/player/comparison',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  getCompareStat(data: PlayerCompareStatParam) {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/player/comparisonStat',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  getCompareRecord(data: PlayerCompareStatParam) {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/player/comparisonRecord',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  getPlayerSoloRankInfo(data: PlayerSoloRankInfoParam): Promise<IResponseSuccess<IPlayerListResponse>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/solorank/playerlist',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  getSelectedSoloRankInfo(data: SelectedSoloRankInfoParam): Promise<IResponseSuccess<IPlayerDetailResponse>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/solorank/playerdetail',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }
}

export default new PlayerServices();
