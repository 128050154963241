import React from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useRecoilValue } from 'recoil';
import { useSelector, useDispatch, batch } from 'react-redux';
import ChampionContainer from './ChampionContainer';
import ChampionOppContainer from './ChampionOppContainer';
import GoldGap from './GoldGap';
import { colors } from '../../../../../Styles/ui';
import { RootState } from '../../../../../redux/modules';

import GameDatasetAtom from '../../../../../recoil/ProGameReport/gameDatasetAtom';
import GameAtom from '../../../../../recoil/ProGameReport/gameAtom';
import VideoAtom from '../../../../../recoil/ProGameReport/videoAtom';
import { Infos, Live, Mapping, Status, TeamGold } from '../../../../../services/GameServices';

const ChampionLiuneBox = ({
  position,
  playedSeconds,
  fixedDataset,
  liveDataset,
  mappingDataset,
  playersStatusDataset,
  teamGoldDataset,
  goldActiveIdx,
  mappingActiveIdx,
  liveActiveIdx,
  playersActiveIdx,
  startTime,
  blueteam,
  redteam,
}: {
  position: number;
  playedSeconds: number;
  fixedDataset: Infos[];
  liveDataset: Live[];
  mappingDataset: Mapping[];
  playersStatusDataset: Status[];
  teamGoldDataset: TeamGold[];
  goldActiveIdx: number;
  mappingActiveIdx: number;
  liveActiveIdx: number;
  playersActiveIdx: number;
  startTime: string;
  blueteam: string;
  redteam: string;
}) => {
  // const playedSeconds = useRecoilValue(VideoAtom.playedSeconds);
  // const fixedDataset = useRecoilValue(GameDatasetAtom.fixedDataset);
  // const liveDataset = useRecoilValue(GameDatasetAtom.liveDataset);
  // const mappingDataset = useRecoilValue(GameDatasetAtom.mappingDataset);
  // const playersStatusDataset = useRecoilValue(GameDatasetAtom.playersStatusDataset);
  // const teamGoldDataset = useRecoilValue(GameDatasetAtom.teamGoldDataset);
  // const goldActiveIdx = useRecoilValue(GameAtom.goldActiveIdx);
  // const mappingActiveIdx = useRecoilValue(GameAtom.mappingActiveIdx);
  // const liveActiveIdx = useRecoilValue(GameAtom.liveActiveIdx);
  // const playersActiveIdx = useRecoilValue(GameAtom.playersActiveIdx);
  // const startTime = useRecoilValue(GameAtom.startTime);
  // const blueteam = useRecoilValue(GameAtom.blueteam);
  // const redteam = useRecoilValue(GameAtom.redteam);

  // mapping 데이터 관련
  // const getIdx = Math.floor(
  //   playedSeconds - +startTime - mappingDataset[0].realCount / 2,
  // );
  // const realCnt =
  //   getIdx < 0
  //     ? 0
  //     : getIdx * 2 >= mappingDataset.length
  //     ? mappingDataset.length - 1
  //     : getIdx * 2;

  // 골드 데이터 관련
  const goldDataset = teamGoldDataset;
  const goldData = goldDataset[goldActiveIdx];

  if (goldDataset[0].realCount !== 0) {
    goldDataset.unshift({
      realCount: 0,
      blueGold: 0,
      redGold: 0,
      blueKills: 0,
      redKills: 0,
      golds: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    });
  }

  const goldgap = goldData.golds[position] - goldData.golds[position + 5];
  const arrPosition = ['top', 'jng', 'mid', 'ad', 'sup'];
  const transPosition = ['TOP', 'JUG', 'MID', 'BOT', 'SUP'];
  let timer = Math.floor(playedSeconds - +startTime);
  const playerStatusMax = playersStatusDataset[playersStatusDataset.length - 1].realCount;
  timer = timer > 0 ? (timer < playerStatusMax ? timer * 2 : playerStatusMax) : 0;
  const isMax = timer > playerStatusMax;
  const liveIdx = liveActiveIdx - 1 < 0 ? 0 : liveActiveIdx;

  return (
    <SChampLineContainer>
      <SLineContainer>
        <img src={`/Images/ic_line_${arrPosition[position]}.svg`} alt='' />
        &nbsp;{transPosition[position]?.toUpperCase()}
      </SLineContainer>
      <ChampLineContainer>
        <ChampionContainer
          player={fixedDataset[0].players[position]}
          participant={position}
          teamName={blueteam}
          mapping={mappingDataset[mappingActiveIdx].player[position]}
          winner={Math.abs(goldgap) >= 300 && goldgap > 0}
          status={playersStatusDataset[playersActiveIdx].players[position]}
          time={timer ? timer / 2 : 0}
          isMax={isMax}
          level={liveDataset[liveIdx].players[position].level}
        />
        <ChampionOppContainer
          player={fixedDataset[1].players[position]}
          participant={position + 5}
          teamName={redteam}
          mapping={mappingDataset[mappingActiveIdx].player[position + 5]}
          winner={Math.abs(goldgap) >= 300 && goldgap < 0}
          status={playersStatusDataset[playersActiveIdx].players[position]}
          time={timer ? timer / 2 : 0}
          isMax={isMax}
          level={liveDataset[liveIdx].players[position + 5].level}
        />

        {Math.abs(goldgap) >= 300 && <GoldGap gold={Math.abs(goldgap)} win={goldgap > 0 ? 'blue' : 'red'} />}
      </ChampLineContainer>
    </SChampLineContainer>
  );
};

export default ChampionLiuneBox;

const ChampLineContainer = styled.div`
  display: flex;
  width: 226px;
  height: 155px;
  margin: 6px 14px 10px 0;
  position: relative;

  .gold {
    position: absolute;
    top: 35%;
    left: 39%;
    width: 48px;
    height: 20px;
    padding: 3px 6px 2px;
    border-radius: 10px;
    background-color: #0075bf;
  }
`;

const SChampLineContainer = styled.div`
  width: 226px;
  height: 187px;
  background-color: ${colors.bg_select};
  border-radius: 20px;
  margin-bottom: 10px;
  padding-top: 5px;
`;

const SLineContainer = styled.div`
  display: flex;
  width: 100%;
  height: 21px;

  font-family: SpoqaHanSansNeo;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  justify-content: center;
  color: ${colors.text};
`;
