import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { styled as MUIstyled } from '@mui/material';
import { Button } from '@mui/material';
import { MUICalendar } from '../../../Components/Ui/Calendar/MUICalendar';
import SimpleSelect from '../../../Components/Ui/Select/SimpleSelect';
import { colors, spacing, typoStyle } from '../../../Styles/ui';
import ScrimAtom, { ScrimFilterResult } from '../../../recoil/Scrim/ScrimAtom';
import { findLegueData, findSeasonData, findTeamData, getPatchData } from '../../../lib/searchLeagueData';
import { TRANSLATE_SCRIM_TITLE } from '../../../lib/translation/translate_scrim';
import { userAtom } from '../../../recoil/Auth/userAtom';
import { FilterCallAtom } from '../../../recoil/Filter/staticAtom';
import { ISeason } from '../../../recoil/Filter/leagueReportAtom';

const ScrimFilter = () => {
  const filterData = useRecoilValue(FilterCallAtom);
  const user = useRecoilValue(userAtom);
  const [calendar, setCalendar] = useState<number>(Date.now());
  const [league, setLeague] = useRecoilState<string>(ScrimAtom.oppleague);
  const [team, setTeam] = useRecoilState<string>(ScrimAtom.oppTeam);
  const [side, setSide] = useRecoilState<100 | 200 | undefined>(ScrimAtom.oppSide);
  const [result, setResult] = useRecoilState(ScrimFilterResult);
  const { t } = useTranslation();

  const THIS_YEAR = dayjs().get('year') - 1;
  const getLeague = filterData && findLegueData(filterData);
  const getSeason = filterData && findSeasonData(filterData);
  const fullSeason = league && getSeason([THIS_YEAR], [league]);
  const fullPatch = fullSeason && getPatchData(fullSeason);
  const getTeam = filterData && findTeamData(filterData);

  const onChange = (date: string | undefined) => {
    setCalendar(dayjs(date).unix() * 1000);
  };

  const handleClickMakeGame = () => {
    setResult({
      date: calendar,
      oppleague: league,
      oppteam: team,
      oppteamid: side,
    });
  };

  useEffect(() => {
    setTeam('');
    setSide('');
  }, [league]);

  return (
    <TOP>
      {
        <FILTER_WRAPPER>
          {/* 날짜설정 */}
          <DATECONTAINER>
            <STEPCONTAINER style={{ marginBottom: 4 }}>
              <STEP>step 1.</STEP>
              {t(TRANSLATE_SCRIM_TITLE.setDate)}
            </STEPCONTAINER>
            <MUICalendar onChange={onChange} value={calendar} />
          </DATECONTAINER>

          <UILINE />

          {/* 상대팀 정보입력 */}
          <TEAMCONTAINER>
            <STEPCONTAINER style={{ marginBottom: 4 }}>
              <STEP>step 2.</STEP>
              {t(TRANSLATE_SCRIM_TITLE.setOppteam)}
            </STEPCONTAINER>

            <SELECTCONTAINER>
              <div style={{ width: '188px', marginRight: '10px' }}>
                <SimpleSelect
                  placeholder={t(TRANSLATE_SCRIM_TITLE.league)}
                  data={league}
                  setData={setLeague}
                  renderList={filterData && getLeague([THIS_YEAR]).map((league) => ({ value: league }))}
                />
              </div>

              <div style={{ width: '188px', marginRight: '10px' }}>
                <SimpleSelect
                  data={team}
                  setData={setTeam}
                  placeholder={t(TRANSLATE_SCRIM_TITLE.team)}
                  renderList={
                    fullSeason &&
                    getTeam(
                      [THIS_YEAR],
                      [league],
                      fullSeason.map((season: ISeason) => season.name),
                      fullPatch,
                    )
                      .map((team) => ({ value: team }))
                      // 2022.06.14 => NS Challengers 팀이 teamshort가 같아서 나오지 않는 경우 임시코드 => LCK 일때만 NS을 보여주지 않음
                      .filter((team) => {
                        if (league === 'LCK') {
                          return team.value !== user?.teamName;
                        }
                        return true;
                      })
                  }
                />
              </div>
              <div style={{ width: '188px' }}>
                <SimpleSelect
                  placeholder={t(TRANSLATE_SCRIM_TITLE.side)}
                  data={side}
                  setData={setSide}
                  renderList={[
                    { label: t(TRANSLATE_SCRIM_TITLE.blue), value: 100 },
                    { label: t(TRANSLATE_SCRIM_TITLE.red), value: 200 },
                  ]}
                />
              </div>
            </SELECTCONTAINER>
          </TEAMCONTAINER>

          <UILINE />

          {/* 액션 버튼 */}
          <BUTTONCONATINER>
            <STYLED_BUTTON
              disabled={!calendar || !league || !team || !side}
              onClick={handleClickMakeGame}
              variant='contained'
              style={{ height: 40 }}
            >
              {t(TRANSLATE_SCRIM_TITLE.genScrimGame)}
            </STYLED_BUTTON>
          </BUTTONCONATINER>
        </FILTER_WRAPPER>
      }
    </TOP>
  );
};

const TOP = styled.section`
  width: 1200px;
  display: flex;
  align-items: baseline;
  ${spacing.marginB(10)};
`;

const FILTER_WRAPPER = styled.div`
  display: flex;
  height: 60px;
`;

const SELECTCONTAINER = styled.div`
  display: flex;
`;

const STEP = styled.span`
  ${typoStyle.step};
  text-transform: uppercase;
`;

const UILINE = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${colors.border_light};
  margin-top: auto;
  ${spacing.marginX(8)}
`;
const STEPCONTAINER = styled.p`
  ${typoStyle.select}
`;
const DATECONTAINER = styled.div``;
const TEAMCONTAINER = styled.div``;
const BUTTONCONATINER = styled.div`
  margin-top: auto;
`;

const STYLED_BUTTON = MUIstyled(Button)`
width: 188px;
${typoStyle.button_12};

&:disabled {
  background-color: #545362;
  color: white;
}
`;

export default ScrimFilter;
