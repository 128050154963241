import { useEffect } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { SetIsOpen, SetSelectedResult, SetSemiDesc } from '../../redux/modules/modalvalue';
import { API } from '../../Pages/config';
import axiosRequest from '../../lib/axios/axiosRequest';
import { Loading } from '../../redux/modules/filtervalue';
import { RootState, UserLogout } from '../../redux/modules';
import setCookie from '../../lib/Cookie/setCookie';
import { goHome, goLogin } from '../../lib/pagePath';
import { userLanguageAtom } from '../../recoil/Auth/userAtom';

const customStyles: Modal.Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 1,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(0, 0, 0, 0.75)',
    border: '1px solid rgba(0, 0, 0, 0.75)',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '0px',
  },
};

const AlertModal: React.FC = () => {
  const user = useSelector((state: RootState) => state.UserReducer);
  const lang = useRecoilValue(userLanguageAtom);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen, desc, semiDesc, isSelector, confirmFuncId, cancelFuncId } = useSelector(
    (state: RootState) => state.ModalReducer,
  );

  useEffect(() => {
    console.log('Open');
    if (isOpen) {
      console.log('Close');
    }
  }, [isOpen]);

  const activeConfirmFunc = () => {
    dispatch(SetIsOpen(false));
    dispatch(SetSemiDesc(''));
    const url = `${API}}/lolapi/logout`;
    const info = `id=${user.id}&charge_time=${user.charge_time}&lang=${lang}`;
    switch (confirmFuncId) {
      case '/login':
        axiosRequest('POST', url, info, function () {
          return '';
        });
        dispatch(Loading(false));
        sessionStorage.clear();
        dispatch(UserLogout());
        navigate(goLogin);
        setCookie('user-token', user.token, -1);
        return;
      case '/home':
        navigate(goHome);
        return;
      default:
        break;
    }

    if (desc === t('alert.logout.sessionExpires')) {
      navigate(goLogin);
    }

    if (confirmFuncId) {
      dispatch(SetSelectedResult(confirmFuncId));
    }
  };

  const activeCancelFunc = () => {
    dispatch(SetIsOpen(false));
    dispatch(SetSemiDesc(''));
    if (cancelFuncId) {
      dispatch(SetSelectedResult(''));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(SetIsOpen(false))}
      style={customStyles}
      contentLabel='Example Modal'
      key='alter-react-modal'
    >
      <ModalWrapper>
        <ModalDetail>
          {desc?.split('\n').map((line: string, idx: number) => {
            return (
              <p key={`alert-react-modal-desc-${idx}`}>
                {line}
                <br />
              </p>
            );
          })}
        </ModalDetail>
        {semiDesc.length > 0 && (
          <ModalSemiDetail>
            {semiDesc?.split('\n').map((line: string, idx: number) => {
              return (
                <p key={`alert-react-modal-semiDesc-${idx}`}>
                  {line}
                  <br />
                </p>
              );
            })}
          </ModalSemiDetail>
        )}

        <ModalClose>
          {isSelector ? (
            <>
              <button
                key='alert-react-modal-cancel'
                className='cancel'
                type='button'
                onClick={() => activeCancelFunc()}
              >
                {t('alert.label.cancel')}
              </button>
              <button
                type='button'
                className='confirm'
                onClick={() => {
                  activeConfirmFunc();
                }}
                key='alert-react-modal-confirm'
              >
                {t('alert.label.confirm')}
              </button>
            </>
          ) : (
            <button
              type='button'
              className='confirm'
              onClick={() => {
                activeConfirmFunc();
              }}
              key='alert-react-modal-confirm'
            >
              {t('alert.label.confirm')}
            </button>
          )}
        </ModalClose>
      </ModalWrapper>
    </Modal>
  );
};

export default AlertModal;

const ModalWrapper = styled.div`
  position: relative;
  width: 500px;
  //height: 151px;
  /* margin: 10px 0 0; */
  padding: 30px 0 20px;
  border-radius: 20px;
  box-shadow: 0px 8px 16px 0 rgba(4, 0, 0, 0.4);
  background-color: #2f2d38;
  overflow-y: hidden;
  z-index: 1;
`;

const ModalDetail = styled.div`
  width: 420px;
  margin: 0 40px 13px;
  font-family: SpoqaHanSansNeo;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
`;

const ModalSemiDetail = styled.div`
  width: 100%;
  margin: 0 0 0px 0;
  font-family: SpoqaHanSansNeo;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: #84818e;
`;

const ModalClose = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px;
  margin-top: 30px;

  button {
    width: 225px;
    height: 60px;
    margin: 0 0px 0 0;
    padding: 0px 0px;
    border-radius: 16px;
    font-family: SpoqaHanSansNeo;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .confirm {
    background-color: #5942ba;
    margin: 0 5px;
  }
  .cancel {
    background-color: #484655;
    margin: 0 5px;
  }
`;
