/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import Tippy from '@tippy.js/react';
import { useRecoilValue } from 'recoil';
import { useMutation } from 'react-query';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { API, recentVersion } from '../../../config';
import axiosRequest from '../../../../lib/axios/axiosRequest';
import ObjectTooltip from '../../ObjectMapping/ObjectTooltip';
import { SetModalInfo } from '../../../../redux/modules/modalvalue';
import { SetJunglingCampData } from '../../../../redux/modules/junglevalue';
import addZero from '../../../../lib/addZero';
import PositionCheckList from '../../../../Components/Ui/PositionCheckList';
import Badge from '../../../../Components/Ui/Dialog/Badge';
import { COORDINATES } from '../../../../lib/mapping/map_jungle_mosters';
import { PathAnalysisResultAtom } from '../../../../recoil/Filter/pathAnalysisAtom';
import { userAtom, userLanguageAtom } from '../../../../recoil/Auth/userAtom';
import JunglePathingService, {
  JungleMappingInfo,
  JungleMappingPosition,
} from '../../../../services/JunglePathingServices';
import { jungleSideFilterResultAtom } from '../../../../recoil/JunglePathing/junglePathingAtom';
import useChampionImage from '../../../../Hooks/useChampionImage';

const x_old_min = -120;
const x_old_max = 14870;

const y_old_min = -120;
const y_old_max = 14980;

const x_new_max = 295;
const x_new_min = 0;
const y_new_max = 0;
const y_new_min = 295;

const convertX = (old_x: number) => {
  return parseInt(((old_x - x_old_min) * (x_new_max - x_new_min)) / (x_old_max - x_old_min) + x_new_min) - 5;
};

const convertY = (old_y: number) => {
  return parseInt(((old_y - y_old_min) * (y_new_max - y_new_min)) / (y_old_max - y_old_min) + y_new_min) - 5;
};

function useIntervalFast(callback) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    const id = setInterval(tick, 100);
    return () => clearInterval(id);
  }, []);
}

const convertMonsterId = (id) => {
  if (Number(id) < 20) {
    return id;
  }
  return parseInt(Number(id) / 10) * 10;
};

function useIntervalNormal(callback) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    const id = setInterval(tick, 300);
    return () => clearInterval(id);
  }, []);
}

const Map = () => {
  const { getChampionImagePathByName } = useChampionImage();
  const result = useRecoilValue(PathAnalysisResultAtom);
  const sideFilterResult = useRecoilValue(jungleSideFilterResultAtom);
  const { t } = useTranslation();

  const selectedLeagues = result.league;
  const selectedYears = result.year;
  const selectedSeasons = result.season;
  const selectedTeam = result.team[0];
  const selectedPatchs = result.patch;

  const junglevalue = useSelector((state) => state.JungleMapReducer);

  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const [position, setPosition] = useState({
    all: true,
    top: true,
    jng: true,
    mid: true,
    bot: true,
    sup: true,
  });
  // mapping data
  const [mappingPosition, setMappingPosition] = useState<JungleMappingPosition[]>();
  const [mappingInfo, setMappingInfo] = useState<JungleMappingInfo[]>();

  // range handling
  const [range, setRange] = useState(0);
  const [minTime, setMinTime] = useState<number>();
  const [maxTime, setMaxTime] = useState<number>();
  const [play, setPlay] = useState(false);
  const [playFast, setPlayFast] = useState(false);
  const [pause, setPause] = useState(false);

  const {
    mutate: orderMutate,
    isLoading: orderLoading,
    isError: orderError,
  } = useMutation((params) => JunglePathingService.getJungleOrder(params));
  const {
    mutate: mappingMutate,
    isLoading: mappingLoading,
    isError: mappingError,
  } = useMutation((params) => JunglePathingService.getJungleMapping(params));

  const isLoading = orderLoading || mappingLoading;
  const isError = orderError || mappingError;

  const filteredPosition = (info) => {
    return (
      position &&
      Object.keys(position)
        .filter((key) => position[key] === true)
        .includes(info.position)
    );
  };

  // mapping API
  const GetMappingInfo = async () => {
    const params = {
      league: selectedLeagues,
      year: selectedYears,
      season: selectedSeasons,
      patch: selectedPatchs,
      team: selectedTeam,
      player: sideFilterResult.player,
      champion: sideFilterResult.champion,
      oppchampion: sideFilterResult.oppchampion,
      side: 'all',
      time: 'all',
      position: ['top', 'jng', 'mid', 'bot', 'sup'],
      gameid: sideFilterResult.gameid[0],
    };

    mappingMutate(params, {
      onSuccess: (data) => {
        onReset();
        const result = data.response;
        if (result?.position.length === 0) return;
        if (result?.position.length !== 0) {
          setMinTime(result.position[0].realCount ? result.position[0].realCount : 0);
          setMaxTime(result.position.length - 1 > 900 ? 900 : result.position.length - 1);
          setMappingPosition(result.position.slice(0, 900));
          setMappingInfo(result.info);
        }
      },
    });
  };

  // jungle camp order API
  const GetJungleCampOrder = async () => {
    const params = {
      league: selectedLeagues,
      year: selectedYears,
      season: selectedSeasons,
      patch: selectedPatchs,
      team: selectedTeam,

      player: sideFilterResult.player,
      // 챔피언, 상대챔피언 배열에서 정글러 추출
      champion: sideFilterResult.champion[1],
      oppchampion: sideFilterResult.oppchampion[1],
      gameid: sideFilterResult.gameid[0],
    };

    orderMutate(params, {
      onSuccess: (data) => {
        const result = data.response;
        if (!result || result.length === 0 || result === 'NODATA') {
          alert(t('video.wardPathing.noData'));
        } else {
          dispatch(SetJunglingCampData(result));
        }
      },
    });
  };

  useIntervalNormal(() => {
    if (play === true && range < maxTime) {
      setRange(parseInt(range) + 1);
    }
  });

  useIntervalFast(() => {
    if (playFast === true && range < maxTime) {
      setRange(parseInt(range) + 1);
    }
  });

  // mapping / jungle api 동시 호출
  useEffect(() => {
    if (!result || !sideFilterResult) return;
    GetMappingInfo();
    GetJungleCampOrder();
  }, [sideFilterResult]);

  // 정글 캠프 추가
  const LOCATION = {
    NOTFOUND: [],
    '-1': [],
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [], // 바론 앞
    6: [],
    7: [],
    8: [],
    9: [],
    10: [],
    11: [],
    12: [],
    13: [], // 용 앞
    20: [], // 드래곤
    30: [], // 전령
  };

  const onPlay = () => {
    setPlay(true);
    setPause(false);
  };

  const onFast = () => {
    setPlayFast(true);
    setPause(false);
  };

  const onPause = () => {
    setPlayFast(false);
    setPlay(false);
    setPause(true);
  };

  const onReset = () => {
    onPause();
    setRange(0);
  };

  return (
    <>
      {sideFilterResult && (
        <>
          <PositionCheckList position={position} setPosition={(position) => setPosition(position)} />

          <SMapContainer>
            <SWardPathingMap ref={mapRef}>
              {isLoading ? (
                <OVERLAY>
                  <CircularProgress />
                </OVERLAY>
              ) : isError ? (
                <OVERLAY>
                  <NoData>
                    <ErrorImg src='/Images/icon/ic_error_page.png' alt='errorIcon' />
                    <NoDataDetail>{t('video.jungle.noData')}</NoDataDetail>
                  </NoData>
                </OVERLAY>
              ) : (
                <>
                  {/* 정글 동선 */}
                  {mappingInfo?.filter(filteredPosition).map((info, idx) => {
                    let x;
                    let y;
                    if (range === 0) {
                      if (info.side === 'red') {
                        x = 640;
                        y = 45;
                      } else {
                        x = 45;
                        y = 640;
                      }
                    } else {
                      for (let i = 0; i < mappingPosition[range]?.player?.length; i += 1) {
                        if (
                          `mapping ${info.gameid} ${info.side} ${info.position}` ===
                          `mapping ${mappingPosition[range]?.player[i].gameid} ${mappingPosition[range]?.player[i].team} ${mappingPosition[range]?.player[i].position}`
                        ) {
                          x = convertX(Number(mappingPosition[range]?.player[i].x)) * (720 / 295);
                          y = convertY(Number(mappingPosition[range]?.player[i].y)) * (720 / 295);
                        }
                      }
                    }

                    return (
                      <StyledTippy
                        // options
                        arrow
                        duration={0}
                        delay={[0, 0]}
                        trigger='click'
                        content={
                          <ObjectTooltip
                            champion={info.champions}
                            side={info.side}
                            gameid={info.gameid}
                            position={info.position}
                            player={info.player}
                            result={info.win}
                            oppteam={info.opp_team}
                            oppchampion={info.opp_champions}
                          />
                        }
                        placement='top'
                        key={info.date + info.gameid + info.player}
                      >
                        <PlayerInfo
                          className={`mapping ${info.gameid} ${info.side} ${info.position}`}
                          style={{
                            top: `${y}px`,
                            left: `${x}px`,
                            height: '30px',
                            width: '30px',
                            transition: 'all 0.25s ease-out 0s',
                            backgroundImage: `url(${getChampionImagePathByName(info.champions)})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            borderRadius: '50%',
                            border: `1px solid ${info.side}`,
                          }}
                        />
                      </StyledTippy>
                    );
                  })}

                  {/* 정글 캠프 */}
                  {junglevalue.junglingCampData
                    ?.filter((item) => Math.floor(item.realCount) <= range + minTime)
                    .map((camp, idx: number) => {
                      const { monsterid } = camp;
                      const convertedMonsterId = convertMonsterId(monsterid);
                      const Coordinates = COORDINATES[convertedMonsterId] ?? COORDINATES.NOTFOUND;

                      let location;
                      // 테스트 에러코드
                      console.assert(
                        COORDINATES[convertedMonsterId] !== undefined,
                        `${convertedMonsterId}를 찾을 수 없습니다`,
                      );

                      if (convertedMonsterId < 0) {
                        LOCATION['-1'].push(idx + 1);
                        location = LOCATION['-1'];
                      } else if (convertedMonsterId === 30) {
                        LOCATION[30].push(idx + 1);
                        location = LOCATION[30];
                      } else if (convertedMonsterId > 20) {
                        LOCATION[20].push(idx + 1);
                        location = LOCATION[20];
                      } else {
                        LOCATION[convertedMonsterId].push(idx + 1);
                        location = LOCATION[convertedMonsterId];
                      }

                      return (
                        <Badge
                          color={camp.side}
                          index={idx + 1}
                          styling={{
                            left: `${
                              location.length === 1
                                ? Coordinates.coordinate.x
                                : Coordinates.coordinate.x + 24 * (location.length - 1)
                            }px`,
                            top: `${Coordinates.coordinate.y}px`,
                          }}
                        />
                      );
                    })}
                </>
              )}
            </SWardPathingMap>
            <RangeWrapper>
              {(play || playFast) && !pause ? (
                <img src='/Images/btn_stop.svg' alt='stopBtn' onClick={onPause} onKeyUp={onPause} />
              ) : (
                <>
                  <img src='/Images/btn_play.svg' alt='playBtn' onClick={onPlay} onKeyUp={onPlay} />
                  <img src='/Images/pre_2.svg' alt='playFastBtn' onClick={onFast} onKeyUp={onFast} />
                </>
              )}
              <RangeInput
                min={0}
                value={range}
                max={maxTime}
                id='rangeSlider'
                type='range'
                onChange={(e) => {
                  setRange(Number(e.target.value));
                }}
                onKeyPress={(e) => {
                  setRange(Number(e.target.value));
                }}
                step='1'
              />
              <TimeStamp>
                <span className='current'>
                  {range
                    ? `${addZero(Math.floor((range + minTime) / 60))} : ${addZero(
                        Math.floor((range + minTime) % 60),
                      )}`
                    : '00 : 00'}
                </span>
                <p>/</p>
                {maxTime ? <span className='max'>15 : 00</span> : <span className='max'>00 : 00</span>}
              </TimeStamp>
            </RangeWrapper>
          </SMapContainer>
        </>
      )}
    </>
  );
};

export default Map;

const SMapContainer = styled.section`
  width: 700px;
`;

const SWardPathingMap = styled.div`
  background-image: url('/Images/obj_map_summer.png');
  position: relative;
  width: 700px;
  height: 700px;
`;

const StyledTippy = styled(Tippy)``;

const PlayerInfo = styled.div`
  position: absolute;
`;

const RangeWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 14px 0 26px 0;
`;

const RangeInput = styled.input`
  width: 85%;
  height: 6px;
  border-radius: 3px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background-color: rgb(58, 55, 69);
  border-radius: 6px;
  border: 0.2px solid rgb(58, 55, 69);
  -webkit-appearance: none;
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid #817e90;
    height: 11px;
    width: 11px;
    border-radius: 50%;
    background: #817e90;
    cursor: pointer;
  }
`;

const TimeStamp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 17px;
  font-family: SpoqaHanSansNeo;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.87;
  letter-spacing: normal;
  color: #6b6979;
  p {
    margin: 0 4px 0 4px;
  }
  > .current {
    width: 45%;
    color: rgb(240, 69, 69);
    text-align: right;
  }
  > .max {
    width: 55px;
    color: #6b6979;
  }
`;

const OVERLAY = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoData = styled.div`
  width: 500px;
  height: 169px;
  text-align: center;
`;

const ErrorImg = styled.img``;

const NoDataDetail = styled.div`
  padding-top: 20px;
  color: #ffffff;
`;

const JunglingCampWrapper = styled.div``;
