import { resolve } from 'path';
import { rejects } from 'assert';
import { AxiosError, AxiosResponse } from 'axios';
import { axiosPost } from './axios/axios';
import {
  GameListParam,
  OppPlayerChampionsParam,
  PlayerChampionParam,
  PlayersParam,
} from './types/param_types';
import { WardType } from '../lib/mapping/map_ward';

interface IResponseSuccess<T> {
  response: T;
  status: string;
  message: string;
}

type Postion = 'top' | 'jug' | 'mid' | 'bot' | 'sup';

export type MappingPosition = {
  realCount?: number;
  player?: {
    y: number;
    x: number;
    team: 'blue' | 'red';
    position: 'top' | 'jng' | 'mid' | 'bot' | 'sup';
    gameid: string;
  }[];
}[];

export type MappingWard = {
  date: string;
  side: string;
  team: string;
  firstwardPosition: string;
  firstjunglewardplaced: number;
  firstward: WardType;
  firstwardDestroy: number;
  position: string;
  firstwardTime: number;
  firstwardPlaced: number;
  uniqueid: string;
  champion: string;
  oppteam: string;
  player: string;
}[];

export type MappingInfo = {
  date: string;
  opp_team: string;
  mapping: number;
  side: string;
  champions: string;
  opp_champions: string;
  position: string;
  team: string;
  idx: string;
  win: string;
  player: string;
  gameid: string;
}[];

interface MappingParm {
  league: string[];
  year: string[];
  season: string[];
  patch: string[];
  team: string;
  player: string;
  champion: string[];
  oppchampion: string[];
  side: 'all' | 'red' | 'blue';
  position: Postion[];
  type: 0 | 1 | 2 | 3;
  idx: string[];
}

interface MappingResponse {
  position: MappingPosition;
  ward: {
    warding: MappingWard;
  };
  info: MappingInfo;
}

export interface IGetPlayerInWardMappingParam {
  year: string[];
  league: string[];
  season: string[];
  team: string;
  patch: string[];
}

export interface IPlayer {
  name: string;
  position: string;
}

export interface IGetChampionParam {
  year: string[];
  league: string[];
  season: string[];
  team: string;
  player: string;
  patch: string[];
}

export interface IGetChampionResponse {
  champion: string[];
  championEng: string[];
}

class WardPathingService {
  async getPlayerList(data: any) {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/ward-passing/userinfo',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async getPlayerInWardMapping(data: IGetPlayerInWardMappingParam): Promise<IResponseSuccess<IPlayer[]>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/mappingfilter/player',
        data,
      })
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  }

  async getChampion(data: IGetChampionParam): Promise<IResponseSuccess<IGetChampionResponse>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/mappingfilter/champion',
        data,
      })
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  }

  async getChampionList(data: any): Promise<IResponseSuccess<any>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/ward-passing/player-champions',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async getOppChampionList(data: any) {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/ward-passing/opp-champions',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async getGameList(data: any) {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/ward-passing/object-game',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async getMappingData(data: MappingParm): Promise<IResponseSuccess<MappingResponse>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/ward-passing/mapping',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }
}

export default new WardPathingService();
