import { useCallback, useEffect, useRef } from 'react';

export function useDetectOutside(onTrigger) {
  const ref = useRef(null);
  const onClick = useCallback(
    (e) => {
      if (ref && ref.current) {
        if (!ref.current.contains(e.target)) {
          onTrigger();
        }
      }
    },
    [ref.current, onTrigger],
  );

  useEffect(() => {
    window.addEventListener('click', onClick);
    return () => {
      window.removeEventListener('click', onClick);
    };
  }, []);

  return ref;
}
