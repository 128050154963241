import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { API } from '../../Pages/config';
import { store } from '../../index';
import { IAxiosResponse } from './axios.type';

export class ApiError implements IAxiosResponse<undefined> {
  status: string;
  message: string;

  constructor(status: string, message = 'SomeThing Wrong') {
    this.status = status;
    this.message = message;
  }
}

export const axiosPost = axios.create({
  baseURL: `${API}/lolapi`,
  method: 'POST',
  headers: { 'content-type': 'application/x-www-form-urlencoded' },
  timeout: 20000,
});

export const axiosGet = axios.create({
  baseURL: `${API}/lolapi`,
  method: 'GET',
  paramsSerializer: (params: any) => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  },
  timeout: 5000,
});

const handlePostRequest = (config: AxiosRequestConfig) => {
  const _config = addUserData(config);
  return _config;
};

const handleGetReqeust = (config: AxiosRequestConfig) => {
  const _config = addHeader(config);
  return _config;
};

const addUserData = (config: AxiosRequestConfig) => {
  const user = sessionStorage.getItem('user');

  if (user) {
    const { userId, token } = JSON.parse(user);
    const _config = {
      ...config,
      data: { ...config.data, id: userId, token },
    };
    return _config;
  }

  return config;
};

const addHeader = (config: AxiosRequestConfig) => {
  const user = store.getState().UserReducer;
  const _config = {
    ...config,
    headers: { Accept: `Bearer  ${user.token}` },
  };
  return _config;
};

const handleError = (error: any) => {
  return Promise.reject(error);
};

// 요청 인터셉터
axiosPost.interceptors.request.use(handlePostRequest);
axiosGet.interceptors.request.use(handleGetReqeust);

// 응답 인터셉터
axiosPost.interceptors.response.use((res: AxiosResponse<IAxiosResponse<string[]>>) => {
  // http 기본응답
  if (res.status > 299 || res.status < 200) {
    console.error('서버에 호출이 들어가지 않았습니다. url을 확인하세요');
  }

  // 서버에서 호출하는 응답 schema
  if (Number(res.data.status) > 299 || Number(res.data.status) < 200) {
    const { status, message } = res.data;
    const error = new ApiError(status, message);

    return Promise.reject(error);
  }

  return res;
}, handleError);
