import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { t } from 'i18next';
import Avatar from '@mui/material/Avatar';

import { DataGridProProps, GridColDef } from '@mui/x-data-grid-pro';
import { Box } from '@mui/system';
import { Chip, List, ListItem, Stack, Typography } from '@mui/material';
import { ChampionListAtom, OriginalChampData } from '../../../recoil/common/championListAtom';
import DataGridWithPanel from '../../test/DataGridWithPanel';
import CustomDataGrid from '../../Scrim/components/CustomDataGrid';
import { TRANSLATE_BANPICK_REPORT } from '../../../lib/translation/TeamReport/translate_BanpickReport';
import { TRANSLATE_HOME_TITLE } from '../../../lib/translation/Home/translate_home';
import ChampList from './ChampList';
import { getKda } from '../../../lib/getKda';
import { userLanguageAtom } from '../../../recoil/Auth/userAtom';
import { TeamReportResultAtom } from '../../../recoil/Filter/teamReportAtom';
import { IPositionPick, ITeamAnalysis, TeamPickResponse } from '../../../services/types/team_services.type';
import ScrimPlayerdChampion from '../../Scrim/components/ScrimBanPickReport/ScrimPlayerdChampion';
import useChampionImage from '../../../Hooks/useChampionImage';

// 포지션 NO로 포지션명 맵핑
enum POSITION {
  TOP = 1,
  JNG = 2,
  MID = 3,
  BOT = 4,
  SUP = 5,
}

type WidthOptions = {
  width?: number;
  flex?: number;
  minWidth?: number;
};

type champInfo = {
  cid: string;
  ckey: number;
  cname: string;
};

type OriginalChamp = {
  patch: string;
  list: champInfo[];
};

type refinedChampInfo = {
  ko: string;
  en: string;
};
type RefinedChamp = {
  [key: number]: refinedChampInfo;
};

// 에러, 로딩 처리
const CustomOverlay = (msg: string) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src='/Images/icon/ic_error_page.png' alt='errorImg' />
      <span style={{ marginTop: '10px', color: '#fff' }}>{msg}</span>
    </div>
  );
};
const 예외처리components = {
  // 데이터가 빈배열인 경우
  NoRowsOverlay: () => CustomOverlay(t(TRANSLATE_HOME_TITLE.데이터없음)),
  // 데이터 fetching에 에러 발생 시
  ErrorOverlay: () => CustomOverlay(t(TRANSLATE_HOME_TITLE.데이터에러발생)),
  // loading property값이 true일 경우
  LoadingOverlay: () => CustomOverlay(t(TRANSLATE_HOME_TITLE.로딩중)),
};

// COL : 포지션별분석, 서브라인업분석, 포지션픽 테이블
const makeColumnData = {
  포지션: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'position',
    headerName: t(TRANSLATE_BANPICK_REPORT.포지션),
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    headerClassName: 'columnHeader',
    renderCell: (params) => {
      if (params.row.position === undefined) return <></>;
      return (
        <Box sx={{ width: 40, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar
            src={`/Images/position/banpickTable/ico-position-${
              params.row?.position && POSITION[params.row?.position as POSITION].toLowerCase()
            }.png`}
            alt='position'
          />
          <span style={{ fontSize: 12 }}>
            {t(TRANSLATE_BANPICK_REPORT[params.row?.position as POSITION])}
          </span>
        </Box>
      );
    },
    ...widthOptions,
  }),
  선수이름: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'playerName',
    headerName: t(TRANSLATE_BANPICK_REPORT.선수이름),
    flex: 10,
    headerAlign: 'left',
    align: 'left',
    sortable: false,
    ...widthOptions,
  }),
  경기수: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'games',
    headerName: t(TRANSLATE_BANPICK_REPORT.경기수),
    flex: 5,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    ...widthOptions,
  }),
  승리: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'win',
    headerName: t(TRANSLATE_BANPICK_REPORT.승리),
    flex: 4.5,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    ...widthOptions,
  }),
  선픽: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'firstPick',
    headerName: t(TRANSLATE_BANPICK_REPORT.선픽),
    flex: 4.5,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    ...widthOptions,
  }),
  후픽: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'lastPick',
    headerName: t(TRANSLATE_BANPICK_REPORT.후픽),
    flex: 4.5,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    ...widthOptions,
  }),
  // blue side
  '1픽': (widthOptions?: WidthOptions): GridColDef => ({
    field: 'pick1',
    headerName: `1${t(TRANSLATE_BANPICK_REPORT.픽)}`,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    renderCell: (params) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span>{params && params.row.pick1}</span>
          <div>
            {`(${(
              params &&
              (params.row.pick1 / (params.row.pick1 + params.row['pick2-3'] + params.row['pick4-5'])) * 100
            ).toFixed(0)}%)`}
          </div>
        </div>
      );
    },
    ...widthOptions,
  }),
  '2-3픽': (widthOptions?: WidthOptions): GridColDef => ({
    field: 'pick2-3',
    headerName: `2-3${t(TRANSLATE_BANPICK_REPORT.픽)}`,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    renderCell: (params) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span>{params && params.row['pick2-3']}</span>
          <div>
            {`(${(
              (params.row['pick2-3'] / (params.row.pick1 + params.row['pick2-3'] + params.row['pick4-5'])) *
              100
            ).toFixed(0)}%)`}
          </div>
        </div>
      );
    },
    ...widthOptions,
  }),
  '4-5픽': (widthOptions?: WidthOptions): GridColDef => ({
    field: 'pick4-5',
    headerName: `4-5${t(TRANSLATE_BANPICK_REPORT.픽)}`,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    renderCell: (params) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span>{params.row['pick4-5']}</span>
          <div>
            {`(${(
              (params.row['pick4-5'] / (params.row.pick1 + params.row['pick2-3'] + params.row['pick4-5'])) *
              100
            ).toFixed(0)}%)`}
          </div>
        </div>
      );
    },
    ...widthOptions,
  }),
  // red side
  '1-2픽': (widthOptions?: WidthOptions): GridColDef => ({
    field: 'pick1-2',
    headerName: `1-2${t(TRANSLATE_BANPICK_REPORT.픽)}`,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    renderCell: (params) => {
      if (params.row['pick1-2'] === undefined) return <></>;
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span>{params.row['pick1-2']}</span>
          <div>
            {`(${(
              (params.row['pick1-2'] /
                (params.row['pick1-2'] + params.row.pick3 + params.row.pick4 + params.row.pick5)) *
              100
            ).toFixed(0)}%)`}
          </div>
        </div>
      );
    },
    ...widthOptions,
  }),
  '3픽': (widthOptions?: WidthOptions): GridColDef => ({
    field: 'pick3',
    headerName: `3${t(TRANSLATE_BANPICK_REPORT.픽)}`,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    renderCell: (params) => {
      if (params.row.pick3 === undefined) return <></>;
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span>{params.row.pick3}</span>
          <div>
            {`(${(
              (params.row.pick3 /
                (params.row['pick1-2'] + params.row.pick3 + params.row.pick4 + params.row.pick5)) *
              100
            ).toFixed(0)}%)`}
          </div>
        </div>
      );
    },
    ...widthOptions,
  }),
  '4픽': (widthOptions?: WidthOptions): GridColDef => ({
    field: 'pick4',
    headerName: `4${t(TRANSLATE_BANPICK_REPORT.픽)}`,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    renderCell: (params) => {
      if (params.row.pick4 === undefined) return <></>;
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span>{params.row.pick4}</span>
          <div>
            {`(${(
              (params.row.pick4 /
                (params.row['pick1-2'] + params.row.pick3 + params.row.pick4 + params.row.pick5)) *
              100
            ).toFixed(0)}%)`}
          </div>
        </div>
      );
    },
    ...widthOptions,
  }),
  '5픽': (widthOptions?: WidthOptions): GridColDef => ({
    field: 'pick5',
    headerName: `5${t(TRANSLATE_BANPICK_REPORT.픽)}`,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    renderCell: (params) => {
      if (params.row.pick5 === undefined) return <></>;
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span>{params.row.pick5}</span>
          <div>
            {`(${(
              (params.row.pick5 /
                (params.row['pick1-2'] + params.row.pick3 + params.row.pick4 + params.row.pick5)) *
              100
            ).toFixed(0)}%)`}
          </div>
        </div>
      );
    },
    ...widthOptions,
  }),
  PR: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'pr',
    headerName: t(TRANSLATE_BANPICK_REPORT.PR),
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    ...widthOptions,
  }),
  플레이한챔피언: (
    originalChamp: OriginalChamp,
    refinedChamp: RefinedChamp,
    widthOptions?: WidthOptions,
  ): GridColDef => ({
    field: 'champions',
    headerName: t(TRANSLATE_BANPICK_REPORT.플레이한챔피언),
    headerAlign: 'left',
    align: 'left',
    sortable: false,
    ...widthOptions,
    renderCell: (params) => {
      return <ScrimPlayerdChampion params={params} />;
    },
  }),
};

// ROW : 포지션별분석, 서브라인업분석
const 분석Rows = (data: any[], side: string) => {
  let result = [];
  if (side === 'blue') {
    result = data?.map((row, idx) => ({
      id: idx,
      position: row && row.pos,
      playerName: row && row.player,
      games: row && row.games,
      win: row && row.win,
      firstPick: row && row.firstPick,
      lastPick: row && row.games - row.firstPick,
      pick1: row && row.picks[0],
      'pick2-3': row && row.picks[1] + row.picks[2],
      'pick4-5': row && row.picks[3] + row.picks[4],
      pr: row && row.pr.toFixed(1),
      champions: row && [
        ...new Set(
          Object.values(row.champs)
            .sort((a, b) => b.games - a.games)
            .map((el) => el.champ),
        ),
      ],
      championDetails: row && Object.values(row.champs).sort((a, b) => b.games - a.games), // 드롭다운 컨텐츠
    }));
  } else {
    result = data?.map((row, idx) => {
      return {
        id: idx,
        position: row && row.pos,
        playerName: row && row.player,
        games: row && row.games,
        win: row && row.win,
        firstPick: row && row.firstPick,
        lastPick: row && row.games - row.firstPick,
        'pick1-2': row && row.picks[0] + row.picks[1],
        pick3: row && row.picks[2],
        pick4: row && row.picks[3],
        pick5: row && row.picks[4],
        pr: row && row.pr.toFixed(1),
        champions: row && [
          ...new Set(
            Object.values(row.champs)
              .sort((a, b) => b.games - a.games)
              .map((el) => el.champ),
          ),
        ],
        championDetails: row && Object.values(row.champs).sort((a, b) => b.games - a.games), // 드롭다운 컨텐츠
      };
    });
  }
  return result;
};

// ROW : 포지션 픽
const 픽Rows = (data: IPositionPick[], side: 'blue' | 'red') => {
  let result = [];
  if (side === 'blue') {
    result = data?.map((row, idx) => ({
      id: idx,
      position: row && row.pos,
      pick1: row && row.pick[0],
      'pick2-3': row && row.pick[1] + row.pick[2],
      'pick4-5': row && row.pick[3] + row.pick[4],
    }));
  } else {
    result = data?.map((row, idx) => ({
      id: idx,
      position: row && row.pos,
      'pick1-2': row && row.pick[0] + row.pick[1],
      pick3: row && row.pick[2],
      pick4: row && row.pick[3],
      pick5: row && row.pick[4],
    }));
  }
  return result;
};

// 드롭다운 내부 컨텐츠
function DetailPanelContent({ row: rowProp }: { row: Detail }) {
  const originalChamp = useRecoilValue(OriginalChampData);
  const { getChampionImagePathByIndex } = useChampionImage();
  const refinedChamp = useRecoilValue(ChampionListAtom);
  const lang = useRecoilValue(userLanguageAtom);
  const result = useRecoilValue(TeamReportResultAtom);

  if (rowProp.championDetails === undefined) return <></>;
  return (
    <Box
      sx={{
        width: 1075,
        display: 'flex',
        flexDirection: 'column',
        mt: 2,
        ml: 14,
      }}
    >
      <div style={{ color: '#fff', margin: '10px 0 20px 0', fontSize: 13 }}>
        {t(TRANSLATE_BANPICK_REPORT.플레이한챔피언)}
      </div>
      <Stack
        direction='row'
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {rowProp?.championDetails.map((champEl, idx) => {
          return (
            <Box sx={{ width: 345, marginRight: 3, marginBottom: 5 }} key={idx + champEl.champ}>
              <Box
                sx={{
                  color: '#fff',
                  height: 40,
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 2.5,
                }}
              >
                <div style={{ display: 'flex' }}>
                  <Avatar
                    src={getChampionImagePathByIndex(champEl.champ)}
                    alt='champImg'
                    sx={{
                      width: 40,
                      marginRight: 2,
                      img: {
                        transform: 'scale(1.2)',
                      },
                    }}
                    variant='square'
                  />
                  <Box>
                    <div>
                      {lang === 'ko' ? refinedChamp[champEl.champ].ko : refinedChamp[champEl.champ].en}
                    </div>
                    <div>
                      {`${champEl.games}${t(TRANSLATE_BANPICK_REPORT.경기)}
                     ${champEl.wins}${t(TRANSLATE_BANPICK_REPORT.승)} 
                     ${champEl.games - champEl.wins}${t(TRANSLATE_BANPICK_REPORT.패)} ${(
                        (champEl.wins / champEl.games) *
                        100
                      ).toFixed(0)}%`}
                    </div>
                  </Box>
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', flex: 0.7 }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ fontSize: 13, color: '#6f6f6f' }}>PR</div>
                    <div style={{ fontSize: 15 }}>{(champEl.pr / champEl.games).toFixed(1)}</div>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ fontSize: 13, color: '#6f6f6f' }}>KDA</div>
                    <div style={{ fontSize: 15 }}>
                      {getKda(champEl.games, champEl.kills, champEl.deaths, champEl.assists).kda}
                    </div>
                  </Box>
                </div>
              </Box>
              <List sx={{ overflowY: 'scroll', maxHeight: 222 }} disablePadding>
                {champEl.opp.map((oppEl, idx) => {
                  return (
                    <ListItem
                      key={idx + oppEl.oppChamp}
                      sx={{
                        border: '1px solid transparent',
                        height: 60,
                        color: '#fff',
                        bgcolor: '#302e39',
                        marginBottom: 1,
                        borderRadius: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        py: 2.5,
                        px: 4,
                      }}
                    >
                      {oppEl.win === 1 ? (
                        <Chip
                          label='WIN'
                          sx={{
                            bgcolor: '#e2ff7a',
                            color: '#1f1d29',
                            borderRadius: 1,
                            width: 32,
                            height: 20,
                            fontWeight: 'bold',
                            fontSize: 13,
                            '& .MuiChip-label': {
                              padding: '2px 0',
                            },
                          }}
                        />
                      ) : (
                        <Chip
                          label={t(TRANSLATE_BANPICK_REPORT.패배)}
                          sx={{
                            color: '#fff',
                            borderRadius: 1,
                            width: 36,
                            height: 18,
                            fontSize: 13,
                            '& .MuiChip-label': {
                              padding: '2px 0',
                            },
                          }}
                        />
                      )}

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          minWidth: 27,
                          marginLeft: 5,
                        }}
                      >
                        {/* firstpick , win => 1인경우 Champ, 0인경우 OppChamp가 선픽, 승 */}
                        <Box sx={{ display: 'flex', minWidth: 118, justifyContent: 'flex-end' }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              textAlign: 'right',
                              marginRight: 8,
                              minWidth: 70,
                            }}
                          >
                            <span>{result.team}</span>
                            <span style={{ fontWeight: 'bold', fontSize: 13 }}>
                              {oppEl.firstPick === 1 ? t(TRANSLATE_BANPICK_REPORT.선픽) : ''}
                            </span>
                          </div>
                          <Avatar
                            src={getChampionImagePathByIndex(champEl.champ)}
                            alt='oppChampImg'
                            sx={{
                              width: 40,
                              img: {
                                transform: 'scale(1.1)',
                              },
                            }}
                            variant='square'
                          />
                        </Box>
                        <span style={{ margin: '0 10px' }}>VS</span>
                        <Box sx={{ display: 'flex', minWidth: 118, justifyContent: 'flex-start' }}>
                          <Avatar
                            src={getChampionImagePathByIndex(oppEl.oppChamp)}
                            alt='oppChampImg'
                            sx={{
                              width: 40,
                              img: {
                                transform: 'scale(1.1)',
                              },
                            }}
                            variant='square'
                          />
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              textAlign: 'left',
                              marginLeft: 8,
                            }}
                          >
                            <span>{oppEl.oppTeam}</span>
                            <span style={{ fontWeight: 'bold', minWidth: 70, fontSize: 13 }}>
                              {oppEl.firstPick === 1 ? '' : t(TRANSLATE_BANPICK_REPORT.선픽)}
                            </span>
                          </div>
                        </Box>
                      </Box>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
}

// 분석데이터 가공
function divide(data: ITeamAnalysis[]) {
  const result: {
    1: ITeamAnalysis[];
    2: ITeamAnalysis[];
    3: ITeamAnalysis[];
    4: ITeamAnalysis[];
    5: ITeamAnalysis[];
  } = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
  };

  if (data.length === 0) return result;

  data?.forEach((item: any) => {
    result[item.pos as keyof typeof result].push(item);
  });

  return result;
}

function gameDateCompare(a: any, b: any) {
  const dateA = new Date(a.gameDate).getTime();
  const dateB = new Date(b.gameDate).getTime();
  return dateB - dateA;
}

interface P {
  data: TeamPickResponse;
  side: 'blue' | 'red';
}

const BanPickRenewal: React.FC<P> = ({ data, side }) => {
  const originalChamp = useRecoilValue(OriginalChampData);
  const refinedChamp = useRecoilValue(ChampionListAtom);

  const blueAnalysis = divide(data.blue);
  const redAnalysis = divide(data.red);

  // 블루 포지션별 분석, 서브라인업 분석
  const [bluePriorTop, ...blueSublineTop] = blueAnalysis && blueAnalysis[1].sort(gameDateCompare);
  const [bluePriorMid, ...blueSublineMid] = blueAnalysis && blueAnalysis[2].sort(gameDateCompare);
  const [bluePriorJng, ...blueSublineJng] = blueAnalysis && blueAnalysis[3].sort(gameDateCompare);
  const [bluePriorBot, ...blueSublineBot] = blueAnalysis && blueAnalysis[4].sort(gameDateCompare);
  const [bluePriorSup, ...blueSublineSup] = blueAnalysis && blueAnalysis[5].sort(gameDateCompare);

  const bluePosArr = [bluePriorTop, bluePriorMid, bluePriorJng, bluePriorBot, bluePriorSup]
    // .concat(bluePriorTop, bluePriorMid, bluePriorJng, bluePriorBot, bluePriorSup)
    .filter((item) => item !== undefined);
  const blueSublineArr: ITeamAnalysis[] = [
    ...blueSublineTop,
    ...blueSublineMid,
    ...blueSublineJng,
    ...blueSublineBot,
    ...blueSublineSup,
  ];

  // 레드 포지션별 분석, 서브라인업 분석
  const [redPriorTop, ...redSublineTop] = redAnalysis && redAnalysis[1].sort(gameDateCompare);
  const [redPriorMid, ...redSublineMid] = redAnalysis && redAnalysis[2].sort(gameDateCompare);
  const [redPriorJng, ...redSublineJng] = redAnalysis && redAnalysis[3].sort(gameDateCompare);
  const [redPriorBot, ...redSublineBot] = redAnalysis && redAnalysis[4].sort(gameDateCompare);
  const [redPriorSup, ...redSublineSup] = redAnalysis && redAnalysis[5].sort(gameDateCompare);

  const redPosArr = [redPriorTop, redPriorMid, redPriorJng, redPriorBot, redPriorSup].filter(
    (item) => item !== undefined,
  );
  const redSublineArr = [
    ...redSublineTop,
    ...redSublineMid,
    ...redSublineJng,
    ...redSublineBot,
    ...redSublineSup,
  ];

  const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] = useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    [],
  );
  const getDetailPanelHeight = useCallback(({ row }) => {
    return (Math.floor(row.championDetails.length / 3) + 1) * 180 + 19;
  }, []);

  // COL : 포지션별 분석, 서브라인업 분석
  const ColBlue분석: GridColDef[] = originalChamp && [
    makeColumnData.포지션({ flex: 3.5 }),
    makeColumnData.선수이름({ flex: 10 }),
    makeColumnData.경기수({ flex: 5 }),
    makeColumnData.승리({ flex: 4.5 }),
    makeColumnData.선픽({ flex: 4.5 }),
    makeColumnData.후픽({ flex: 4.5 }),
    makeColumnData['1픽']({ flex: 7 }),
    makeColumnData['2-3픽']({ flex: 8.5 }),
    makeColumnData['4-5픽']({ flex: 8.5 }),
    makeColumnData.PR({ flex: 5 }),
    makeColumnData.플레이한챔피언(originalChamp, refinedChamp, { flex: 36, minWidth: 430 }),
  ];
  const ColRed분석: GridColDef[] = originalChamp && [
    makeColumnData.포지션({ flex: 3.5 }),
    makeColumnData.선수이름({ flex: 10 }),
    makeColumnData.경기수({ flex: 5 }),
    makeColumnData.승리({ flex: 4.5 }),
    makeColumnData.선픽({ flex: 4.5 }),
    makeColumnData.후픽({ flex: 4.5 }),
    makeColumnData['1-2픽']({ flex: 7 }),
    makeColumnData['3픽']({ flex: 5.5 }),
    makeColumnData['4픽']({ flex: 5.5 }),
    makeColumnData['5픽']({ flex: 5.5 }),
    makeColumnData.PR({ flex: 5.5 }),
    makeColumnData.플레이한챔피언(originalChamp, refinedChamp, { flex: 36, minWidth: 430 }),
  ];

  // COL : 포지션 픽
  const ColBlue픽: GridColDef[] = [
    makeColumnData.포지션({ flex: 6 }),
    makeColumnData['1픽']({ flex: 31.5 }),
    makeColumnData['2-3픽']({ flex: 31.5 }),
    makeColumnData['4-5픽']({ flex: 31.5 }),
  ];
  const ColRed픽: GridColDef[] = [
    makeColumnData.포지션({ flex: 6 }),
    makeColumnData['1-2픽']({ flex: 23.5 }),
    makeColumnData['3픽']({ flex: 23.5 }),
    makeColumnData['4픽']({ flex: 23.5 }),
    makeColumnData['5픽']({ flex: 23.5 }),
  ];

  return (
    <>
      {/*  포지션별 분석 */}
      <Box sx={{ color: '#fff', width: 1136, borderBottom: '1px solid rgba(87, 52, 52, 0.1)' }} mt={10}>
        <Typography sx={{ fontSize: 24, color: '#fff', fontWeight: 'bold', marginBottom: 4 }}>
          {t(TRANSLATE_BANPICK_REPORT.포지션별분석)}
        </Typography>
        <DataGridWithPanel
          columns={side === 'blue' ? ColBlue분석 : ColRed분석}
          rows={
            side === 'blue'
              ? bluePosArr.length > 0 && 분석Rows(bluePosArr, 'blue')
              : redPosArr.length > 0 && 분석Rows(redPosArr, 'red')
          }
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          autoheight
          headerHeight={28}
          rowHeight={80}
          sx={{
            border: 'none',
            '& .MuiDataGrid-cell': {
              border: 'none',
              padding: 0,
              color: '#fff',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: 'rgba(128, 128, 128, 0.2)',
              border: 'none',
              color: '#fff',
              fontSize: 13,
            },
            '& .MuiDataGrid-columnHeader': {
              padding: 0,
            },
            '& .MuiDataGrid-detailPanel': {
              backgroundColor: '#16151c',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .MuiSvgIcon-root': {
              fill: '#fff',
            },
            '& .MuiAvatar-colorDefault': {
              backgroundColor: '#2f2d38',
              fontSize: 14,
            },
          }}
          components={예외처리components}
        />
      </Box>
      {/* 서브라인업 분석 */}
      <Box sx={{ color: '#fff', width: 1136, borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }} mt={10}>
        <Typography sx={{ fontSize: 24, color: '#fff', fontWeight: 'bold', marginBottom: 4 }}>
          {t(TRANSLATE_BANPICK_REPORT.서브라인업분석)}
        </Typography>
        <DataGridWithPanel
          columns={side === 'blue' ? ColBlue분석 : ColRed분석}
          rows={
            side === 'blue'
              ? blueSublineArr.length > 0 && 분석Rows(blueSublineArr, 'blue')
              : redSublineArr.length > 0 && 분석Rows(redSublineArr, 'red')
          }
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          autoheight
          headerHeight={28}
          rowHeight={80}
          sx={{
            border: 'none',
            '& .MuiDataGrid-cell': {
              border: 'none',
              padding: 0,
              color: '#fff',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: 'rgba(128, 128, 128, 0.2)',
              border: 'none',
              color: '#fff',
              fontSize: 13,
            },
            '& .MuiDataGrid-columnHeader': {
              padding: 0,
            },
            '& .MuiDataGrid-detailPanel': {
              backgroundColor: '#16151c',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .MuiSvgIcon-root': {
              fill: '#fff',
            },
            '& .MuiAvatar-colorDefault': {
              backgroundColor: '#2f2d38',
              fontSize: 14,
            },
          }}
          components={예외처리components}
        />
      </Box>
      {/* 포지션 픽 */}
      <Box sx={{ height: 410, borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }} mt={10}>
        <Typography sx={{ fontSize: 24, color: '#fff', fontWeight: 'bold', marginBottom: 4 }}>
          {t(TRANSLATE_BANPICK_REPORT.포지션픽)}
        </Typography>
        <CustomDataGrid
          columns={side === 'blue' ? ColBlue픽 : ColRed픽}
          rows={
            side === 'blue' ? 픽Rows(data?.bluePositionPick, 'blue') : 픽Rows(data?.redPositionPick, 'red')
          }
          headerHeight={28}
          rowHeight={64}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: 'rgba(128, 128, 128, 0.2)',
              color: '#fff',
              fontSize: 13,
            },
            '& .MuiDataGrid-columnHeader': {
              '&:first-of-type': {
                padding: 0,
              },
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
              padding: 4,
              '&:last-of-type': {
                color: '#fff',
              },
            },
          }}
          components={예외처리components}
          disableColumnMenu
        />
      </Box>
      {/* 밴한 챔피언  */}
      <Box sx={{ width: 1136, borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }} mt={10}>
        <Typography sx={{ fontSize: 24, color: '#fff', fontWeight: 'bold', marginBottom: 4 }}>
          {t(TRANSLATE_BANPICK_REPORT.밴한챔피언)}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ChampList
            title={t(TRANSLATE_BANPICK_REPORT.전체경기)}
            data={data.banInfo2.All?.ban}
            games={data.banInfo2.All?.games}
          />
          <ChampList
            title={`${side === 'blue' ? t(TRANSLATE_BANPICK_REPORT.블루) : t(TRANSLATE_BANPICK_REPORT.레드)} 
            ${t(TRANSLATE_BANPICK_REPORT.사이드)} ${t(TRANSLATE_BANPICK_REPORT.전체경기)}`}
            data={side === 'blue' ? data.banInfo2.Blue?.ban : data.banInfo2.Red?.ban}
            games={side === 'blue' ? data.banInfo2.Blue?.games : data.banInfo2.Red?.games}
          />
          <ChampList
            title={t(TRANSLATE_BANPICK_REPORT.페이즈1)}
            data={side === 'blue' ? data.banInfo2.Blue?.phase1 : data.banInfo2.Red?.phase1}
            games={side === 'blue' ? data.banInfo2.Blue?.games : data.banInfo2.Red?.games}
          />
          <ChampList
            title={t(TRANSLATE_BANPICK_REPORT.페이즈2)}
            data={side === 'blue' ? data.banInfo2.Blue?.phase2 : data.banInfo2.Red?.phase2}
            games={side === 'blue' ? data.banInfo2.Blue?.games : data.banInfo2.Red?.games}
          />
        </Box>
      </Box>
      {/* 밴당한 챔피언 */}
      <Box sx={{ width: 1136, borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }} mt={10}>
        <Typography sx={{ fontSize: 24, color: '#fff', fontWeight: 'bold', marginBottom: 4 }}>
          {t(TRANSLATE_BANPICK_REPORT.밴당한챔피언)}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ChampList
            title={t(TRANSLATE_BANPICK_REPORT.전체경기)}
            data={data.banedInfo.All?.ban}
            games={data.banedInfo.All?.games}
          />
          <ChampList
            title={`${side === 'blue' ? t(TRANSLATE_BANPICK_REPORT.블루) : t(TRANSLATE_BANPICK_REPORT.레드)} 
            ${t(TRANSLATE_BANPICK_REPORT.사이드)} ${t(TRANSLATE_BANPICK_REPORT.전체경기)}`}
            data={side === 'blue' ? data.banedInfo.Blue?.ban : data.banedInfo.Red?.ban}
            games={side === 'blue' ? data.banedInfo.Blue?.games : data.banedInfo.Red?.games}
          />
          <ChampList
            title={t(TRANSLATE_BANPICK_REPORT.페이즈1)}
            data={side === 'blue' ? data.banedInfo.Blue?.phase1 : data.banedInfo.Red?.phase1}
            games={side === 'blue' ? data.banedInfo.Blue?.games : data.banedInfo.Red?.games}
          />
          <ChampList
            title={t(TRANSLATE_BANPICK_REPORT.페이즈2)}
            data={side === 'blue' ? data.banedInfo.Blue?.phase2 : data.banedInfo.Red?.phase2}
            games={side === 'blue' ? data.banedInfo.Blue?.games : data.banedInfo.Red?.games}
          />
        </Box>
      </Box>
    </>
  );
};

export default BanPickRenewal;
