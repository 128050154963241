import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { colors, spacing, typoStyle } from '../../../Styles/ui';

export const Info = styled(Box)<{ isBlue?: boolean }>(
  ({ theme, isBlue }) => `
  display: flex;
  align-items: center;
  font-size: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 40px;xw
flex: 1;
color: white;
height: 48px;
border-radius: 8px;
background: ${isBlue ? 'rgba(0,117,191,0.3)' : 'rgba(240,69,69,0.3)'}

`,
);
export const Ban = styled(Box)<{ isLine: boolean }>(
  ({ isLine }) =>
    `
    position: relative;
  margin-right: 8px;
  border-radius: 50%;
  overflow: hidden;
  width: 60px;
  height: 60px;

  &:nth-of-type(3) {
    margin-right: 40px;
  }

  img {
    display: block;
   width: 100%;
   height: 100%;
    border-radius: 50%;
    opacity: 0.8;
    mix-blend-mode: luminosity;
  }

  ${
    isLine &&
    `
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
content: '';
width: 60px;
height: 1px;
background: ${colors.badge_red};
transform: translate(-50%,-50%) rotate(45deg);
  }
  `
  }

  
`,
);

export const PickBanInfo = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  padding: 6px 0;
  border-radius: 4px;
  background-color: rgba(128, 128, 128, 0.2);
`;

export const PickWrapper = styled(Box)`
  background-color: #1f1d29;
  padding: 12px 0;
  min-height: 128px;
`;

export const Pick = styled(Box)`
  width: 100%;
  min-width: 97px;
  text-align: center;

  img {
    display: block;
    margin: 0 auto;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
`;

export const FirstPick = styled(Typography)`
  text-align: center;
  font-size: 15px;
  font-weight: bold;
`;

export const Name = styled(FirstPick)`
  width: 97px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
`;

export const WinLabel = styled(Box)<{ isWin: boolean }>(
  ({ isWin }) =>
    `
  color: white;
  font-size: 15px;
  margin-right: 20px;
  width: 32px;
  height: 20px;
  border-radius: 4px;
  text-align: center;

  /* 승리시 */
  ${
    isWin &&
    `
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: #e2ff7a;
  color: #1f1d29;
  font-size: 13px;
  `
  }
`,
);

export const BTN_EXPAND = styled(Button)<{ isOpen?: boolean }>(
  ({ isOpen }) => `
background: #302e39;
margin-left: 8px;
min-width: 0px;
width: 32px;

${
  isOpen &&
  `
  svg {
    transform: rotate(180deg);
  }


`
}
`,
);
