import React, { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import ScrimResultItem from './ScrimResultItem';
import { useModal, useUpdateEffect } from '../../../Hooks';
import { modalList } from '../../../Components/Modals/Modals';
import { useGetScrim } from '../../../Hooks/useGetScim';
import { userAtom } from '../../../recoil/Auth/userAtom';
import Progress from '../../../Components/Ui/Loading/Progress';
import { colors, spacing, typoStyle } from '../../../Styles/ui';
import ScrimService, { ScrimViewData } from '../../../services/ScrimServices';
import { ScrimViewAtom } from '../../../recoil/Scrim/ScrimAtom';
import { TRANSLATE_SCRIM_TITLE } from '../../../lib/translation/translate_scrim';
import { TRANSLATE_ERROR_MESSAGE } from '../../../lib/translation/translate_ErrorController';
import Calendar from '../../test/Calendar';
import SelectBox from '../../test/SelectBox';
import { useQueryClient } from 'react-query';

const HEADER = [
  TRANSLATE_SCRIM_TITLE.gameInfo,
  TRANSLATE_SCRIM_TITLE.FirstPhaseBan,
  TRANSLATE_SCRIM_TITLE.FirstPhasePick,
  TRANSLATE_SCRIM_TITLE.SecondPhaseBan,
  TRANSLATE_SCRIM_TITLE.SecondPhasePick,
];

// 상대팀 필터 팀 불러오기
const filterOppTeam = (data: ScrimViewData[], user: string) => {
  const filteredOppteams: string[] = [];
  data.forEach((item) => {
    if (item.red.team === user) {
      filteredOppteams.push(item.blue.team);
    } else {
      filteredOppteams.push(item.red.team);
    }
  });
  const removeDuplicateTeam = new Set(filteredOppteams);
  return [...removeDuplicateTeam];
};

const ScrimOutput = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const user = useRecoilValue(userAtom);
  const queryClient = useQueryClient();

  //  ------- 추후 date range 추가 + 팀필터 추가
  // const [date, setDate] = useState<number[]>();
  const [date, setDate] = useRecoilState<number[]>(ScrimViewAtom.date);
  const [oppTeam, setOppTeam] = useRecoilState(ScrimViewAtom.oppTeam);

  const {
    data: scrimData,
    isLoading: scrimDataLoading,
    isError: scrimDataError,
    refetch,
    isFetching,
  } = useGetScrim(
    {
      userTeam: user!.teamName,
      startDate: date && date[0],
      endDate: date && date[1],
    },
    { enabled: !!user && !!date },
  );
  const isLoading = scrimDataLoading;

  // modal
  const handleModal = (gameid: string) => {
    const text = t(TRANSLATE_SCRIM_TITLE.noRestoration);
    openModal(modalList.confirm, {
      text,
      onSubmit: () => {
        ScrimService.deleteScrim({
          gameid,
        }).then(() => refetch());
      },
    });
  };

  const resetTeam = () => setOppTeam('');

  const handleChange = useCallback(
    (date: Date[] | undefined[]) => {
      resetTeam();

      const [startDate, endDate] = date;
      if (!endDate) return;
      const newDate = [dayjs(startDate).unix(), dayjs(endDate).unix()];
      setDate(newDate);
    },
    [setDate, setOppTeam],
  );

  const filteredData = (item: ScrimViewData) => {
    if (oppTeam === '') return item;
    return item.blue.team === oppTeam || item.red.team === oppTeam;
  };

  useUpdateEffect(() => {
    if (!user || !date) return;
    refetch();
  }, [user, date]);

  return (
    <Wrapper>
      <CONTAINER>
        {isLoading ? (
          <FLEX_CONTAINER>
            <Progress text={t('solo.playerboard.dataFetching')} />
          </FLEX_CONTAINER>
        ) : scrimDataError ? (
          <FLEX_CONTAINER>
            <ERRORIMG src='/Images/icon/ic_error_page.png' alt='errorIcon' />
            <p>{t(TRANSLATE_ERROR_MESSAGE.noData)}</p>
            <p>{t(TRANSLATE_ERROR_MESSAGE.sorry)}</p>
          </FLEX_CONTAINER>
        ) : (
          <>
            <CalendarWrapper>
              {/* 날짜 필터 */}
              <Calendar handleChange={handleChange} isSelectDateNeeded isOppTeamFilterNeeded />
              <div className='line' />
              {/* 상대팀 필터 */}
              {scrimData && (
                <SelectBox
                  placeholder={t('utility.selectBox.teamFilter')}
                  renderList={filterOppTeam(scrimData.response, user!.teamName)}
                  data={oppTeam}
                  setData={setOppTeam}
                />
              )}
            </CalendarWrapper>
            {/* 스크림 결과 리스트 */}
            <ScrimOutputHeader>
              {HEADER.map((item) => {
                return <HeaderItem key={item}>{t(item)}</HeaderItem>;
              })}
            </ScrimOutputHeader>
            {isFetching ? (
              <FLEX_CONTAINER>
                <Progress text={t('solo.playerboard.dataFetching')} />
              </FLEX_CONTAINER>
            ) : (
              <ScrimResultWrapper>
                {scrimData?.response.length === 0 ? (
                  <NODATA_CONTAINER>
                    <img src='/Images/ic-empty.svg' alt='noData' />
                    <span>
                      {t(TRANSLATE_SCRIM_TITLE.noResult)}
                      <br />
                      {t(TRANSLATE_SCRIM_TITLE.GenNewGame)}
                    </span>
                  </NODATA_CONTAINER>
                ) : (
                  scrimData?.response.filter(filteredData).map((draft: ScrimViewData) => {
                    return (
                      <ScrimResultItem
                        key={draft.gameid}
                        data={draft}
                        handleModal={() => handleModal(draft.gameid)}
                      />
                    );
                  })
                )}
              </ScrimResultWrapper>
            )}
          </>
        )}
      </CONTAINER>
    </Wrapper>
  );
};

export default ScrimOutput;

const Wrapper = styled.div`
  width: 1109px;
  border-top: 1px solid ${colors.border_light};
`;

const CalendarWrapper = styled.div`
  display: flex;
  .line {
    width: 1px;
    height: 40px;
    background-color: ${colors.border_light};
  }
`;

const ScrimOutputHeader = styled.ul`
  display: flex;
  justify-content: space-around;
  width: 1109px;
  padding: 30px 0 15px 0;
  border-bottom: 1px solid #2f2e3b;
  color: #fff;
`;

const HeaderItem = styled.li`
  :first-child {
    text-align: left;
  }
  width: 100%;
  text-align: center;
`;

const CONTAINER = styled.div`
  ${spacing.marginT(10)}
`;

const FLEX_CONTAINER = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 253px;
  flex-direction: column;
  border-radius: 20px;
  background-color: #24222c;
  margin-top: 10px;

  p {
    line-height: 1.54;
  }
`;

const ERRORIMG = styled.img`
  ${spacing.marginB(5)}
`;

const ScrimResultWrapper = styled.div``;

const NODATA_CONTAINER = styled.div`
  margin-top: 32px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #2f2d39;

  ${typoStyle.select}
  > span {
    ${spacing.marginT(4)};
    text-align: center;
  }
`;
