import styled from '@emotion/styled';
import React, { forwardRef } from 'react';
import { colors } from '../../Styles/ui';

interface AvatarProps {
  [key: string]: any;
  src: string;
  alt: string;
  circle?: boolean;
  size?: number;
  color?: string;
  block?: boolean;
}

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ src, alt, circle = true, size = 20, color, block = true, ...props }, ref) => {
    return (
      <SAvarta ref={ref} color={color} size={size} circle={circle} block={block} {...props}>
        <img src={src} alt={alt} />
      </SAvarta>
    );
  },
);

const SAvarta = styled.div<{
  circle: boolean;
  size: number;
  color?: string;
  block: boolean;
}>`
  display: ${({ block }) => (block ? 'block' : 'inline-block')};
  flex: 0 0 ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border: ${({ color }) => (color ? `1px solid ${colors[color]}` : '')};
  overflow: hidden;
  border-radius: ${({ circle }) => (circle ? '50%' : 0)};

  > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export default Avatar;
