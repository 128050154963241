import * as React from 'react';
import {
  DataGridPro,
  GridColumns,
  GridRowParams,
  GridSlotsComponent,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { styled, SxProps } from '@mui/material';
import { scrollbarStyle } from '../../Styles/ui';

const PanelIconStyle: SxProps = {
  fontSize: 24,
  background: '#4E4B5A',
  borderRadius: '50%',
  padding: '3px',
};

function customOpenIcon() {
  return <AddIcon sx={PanelIconStyle} />;
}
function customCloseIcon() {
  return <RemoveIcon sx={PanelIconStyle} />;
}

interface P {
  columns: GridColumns<GridValidRowModel>;
  rows: readonly GridValidRowModel[];
  getDetailPanelContent?: (params: GridRowParams<GridValidRowModel>) => React.ReactNode;
  getDetailPanelHeight?: (params: GridRowParams<any>) => number;
  autoheight?: boolean;
  headerHeight?: number;
  rowHeight?: number;
  components?: GridSlotsComponent;
}

const DataGridWithPanel: React.FC<P> = ({
  columns,
  rows,
  getDetailPanelContent,
  getDetailPanelHeight,
  autoheight,
  headerHeight,
  rowHeight,
  components,
  ...props
}) => {
  return (
    <StyledDataGridWithPanel
      {...props}
      columns={columns}
      rows={rows}
      rowThreshold={0}
      getDetailPanelHeight={getDetailPanelHeight}
      getDetailPanelContent={getDetailPanelContent}
      autoHeight={autoheight}
      headerHeight={headerHeight}
      rowHeight={rowHeight}
      hideFooter
      disableColumnMenu
      components={{
        ...components,
        DetailPanelExpandIcon: customOpenIcon,
        DetailPanelCollapseIcon: customCloseIcon,
      }}
    />
  );
};

export default DataGridWithPanel;

const StyledDataGridWithPanel = styled(DataGridPro)`
  .MuiDataGrid-detailPanel {
    ${scrollbarStyle.hidden}
  }
  .MuiList-root {
    ${scrollbarStyle.scroll_4}
  }

  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    &:first-of-type {
      padding: 0 8px;
    }
  }
`;
