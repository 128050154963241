export const goHome = '/'; // 홈
export const goLeagueReport = '/leagueReport'; // 리그 보고서
export const goTeamReport = '/teamReport'; // 팀 보고서
export const goPlayerReport = '/playerReport'; // 선수 보고서
export const goPathAnalysis = '/pathAnalysis'; // 와드&동선 분석
export const goTeamCompare = '/teamCompare'; // 팀 비교
export const goPlayerCompare = '/playerCompare'; // 선수 비교
export const goProGameReport = '/proGameReport'; // 대회 경기 분석
export const goSoloRank = '/soloRank'; // 솔로 랭크
export const goItemCalculator = '/itemCalculator'; // 아이템 계산기
export const SCRIM = {
  index: 'scrim',
  banpick: 'banpick',
  team: 'team',
  banpickReport: 'banpickReport',
};

// 회원 관련
export const goLogin = '/login'; // 로그인
export const goSignUp = '/signUp'; // 회원 가입
export const goCheckLogin = '/checkLogin'; // 로그인 체크
export const goChangePw = '/changePw'; // 비밀번호 변경

// ETC
export const goPiArea = '/piArea'; // PI 사이트
export const goUiTest = '/uiTest'; // UI Test

export const proGameReportDetail = '/proGameReportDetail'; // 임시..
