/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import addZero from '../../../../lib/addZero';
import { SetDesc, SetIsOpen } from '../../../../redux/modules/modalvalue';
import MatchBox from './MatchBox';
import { colors } from '../../../../Styles/ui';
import checkClick from '../../../../lib/Calendar/checkClick';
import { userLanguageAtom } from '../../../../recoil/Auth/userAtom';
import { ICalendar } from '../calendar.type';

const START_DATE = 'START_DATE';
const END_DATE = 'END_DATE';

interface P {
  info: { year: number; day: number; month: number; index: number; match: string[] };
  activeLabel: string;
  selectStartIdx: string;
  selectEndIdx: string;
  setActiveLabel: React.Dispatch<React.SetStateAction<string>>;
  setSelectStartIdx: React.Dispatch<React.SetStateAction<string>>;
  setSelectEndIdx: React.Dispatch<React.SetStateAction<string>>;
  setSelectStartValue: React.Dispatch<React.SetStateAction<string>>;
  setSelectEndValue: React.Dispatch<React.SetStateAction<string>>;
}

const DayBox: React.FC<P> = ({
  info,
  activeLabel,
  selectStartIdx,
  selectEndIdx,
  setActiveLabel,
  setSelectStartIdx,
  setSelectEndIdx,
  setSelectStartValue,
  setSelectEndValue,
}) => {
  const lang = useRecoilValue(userLanguageAtom);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { year, day, month, index, match } = info;
  const { data: calendar } = useQuery<ICalendar>('calendar');
  const getActiveLabel = (activeLabel: string, index: number) => {
    const exception1 = activeLabel === END_DATE && index < +selectStartIdx;
    const exception2 = activeLabel === START_DATE && index > +selectEndIdx;
    if (activeLabel === START_DATE || exception1) {
      batch(() => {
        if (exception1 || exception2) {
          setSelectEndIdx('');
          setSelectEndValue('');
        }
        setActiveLabel(END_DATE);
      });
      return START_DATE;
    }
    if (selectStartIdx === '') {
      setActiveLabel(START_DATE);
    }
    return END_DATE;
  };

  if (calendar) {
    return (
      <SCalendarDayBox
        isAble={
          +selectStartIdx === index ||
          checkClick(index, year, calendar!.seasonStartDate, calendar!.seasonEndDate)
        }
        isActive={
          +selectStartIdx === index ||
          +selectEndIdx === index ||
          (selectStartIdx !== '' && +selectStartIdx <= index && index <= +selectEndIdx)
        }
        onClick={() => {
          if (checkClick(index, year, calendar!.seasonStartDate, calendar!.seasonEndDate)) {
            if (getActiveLabel(activeLabel, index) === START_DATE) {
              setSelectStartIdx(index.toString());
              setSelectStartValue(`${year}-${addZero(month)}-${addZero(day + 1)}`);
              setActiveLabel(END_DATE);
            } else {
              setSelectEndIdx(index.toString());
              setSelectEndValue(`${year}-${addZero(month)}-${addZero(day + 1)}`);
            }
          } else {
            dispatch(SetDesc(t('utility.calendarFilter.desc.wrongDate')));
            dispatch(SetIsOpen(true));
          }
        }}
      >
        {day >= 0 && month < 13 && (
          <div className='header-day'>
            {lang === 'ko'
              ? `${day === 0 ? `${month}${t('common.date.month')} ` : ''} ${day + 1}${t('common.date.day')}`
              : `${month}/${day + 1}`}
          </div>
        )}
        <div className='match-box-container'>
          {match.map((data) => {
            return <MatchBox text={data} />;
          })}
        </div>
      </SCalendarDayBox>
    );
  }
  return <></>;
};

export default DayBox;

const SCalendarDayBox = styled.div<{ isActive: boolean; isAble: boolean }>`
  width: 145px;
  height: 123px;
  margin: 0 10px 20px;
  //padding: 9px 0 10px;
  border-radius: 20px;
  background-color: ${({ isActive }) => (isActive ? colors.point : colors.bg_button)};
  opacity: ${({ isAble }) => (isAble ? '1' : '0.3')};

  &:hover {
    background-color: ${({ isActive }) => (isActive ? colors.point_hover : colors.bg_hover)};
  }

  .match-box-container {
    height: 74px;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #434050;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      margin: 5px;
    }
  }

  .header-day {
    height: 19px;
    margin: 9px 20px 3px;
    margin-bottom: 3px;
    font-family: SpoqaHanSansNeo;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: ${colors.text};
  }
`;
