import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import styled from '@emotion/styled/macro';
import { Button, styled as MUIStyled } from '@mui/material';
import TeamService from '../../../services/TeamServices';
import { TeamReportResultAtom } from '../../../recoil/Filter/teamReportAtom';
import Progress from '../../../Components/Ui/Loading/Progress';
import NotFound from '../../../Components/Ui/Error/NotFound';
import BanPickRenewal from './BanPickRenewal';
import CalendarRange from '../../../Components/Ui/Calendar/CalendarRange';
import DateRangeService from '../../../lib/Calendar/dateRangelib';
import { colors } from '../../../Styles/ui';

export type Tabs = 'blue' | 'red';
const TABS: Tabs[] = ['blue', 'red'];

const dateRangeService = new DateRangeService();
const now = new Date();

function getStringFormattedDate(date: Date) {
  const offset = date.getTimezoneOffset();
  const newDate = new Date(date.getTime() - offset * 60 * 1000);
  const result = newDate.toISOString().split('T')[0];
  return result;
}

function BanIndex() {
  const { t } = useTranslation();
  const result = useRecoilValue(TeamReportResultAtom);
  const [tab, setTab] = useState(0);
  const [side, setSide] = useState<Tabs>('blue');

  // state: 날짜관련
  const NOW = dateRangeService.dateWithOutTime();
  const LASTYEAR = dateRangeService.dateWithOutTime(new Date(Number(result.year[0]), 0, 1));

  const getStartDateByYear = () => {
    console.log('?', Number(result.year[0]) !== now.getFullYear());
    if (Number(result.year[0]) !== now.getFullYear()) {
      return dateRangeService.clacDate(
        Math.abs(dateRangeService.getPassedDaysFromNewyear(new Date(Number(result.year[0]), 0, 1))) * -1,
        LASTYEAR,
      );
    }

    return dateRangeService.clacDate(Math.abs(dateRangeService.getPassedDaysFromNewyear() - 1) * -1, NOW);
  };
  const getEndDateByYear = () => {
    if (Number(result.year[0]) !== now.getFullYear()) {
      return dateRangeService.clacDate(
        Math.abs(dateRangeService.getPassedDaysFromNewyear(new Date(Number(result.year[0]), 11, 31))),
        LASTYEAR,
      );
    }
    return NOW;
  };
  const [startDate, setStartDate] = useState(getStartDateByYear());
  const [endDate, setEndDate] = useState(getEndDateByYear());

  const handleClickCalendar = (index: number, calc: number) => {
    // react 18 이라서 자동 배칭
    setStartDate(dateRangeService.clacDate(calc));
    setEndDate(dateRangeService.NOW);
    setTab(index);
  };
  const handleChangeCalendar = (date: Date, type: string) => {
    switch (type) {
      case 'start':
        setStartDate(date);
        break;

      case 'end':
        setEndDate(date);
        break;

      default:
        break;
    }
  };

  // data fetching
  const { mutate, data, isLoading, isError } = useMutation(
    ({ startDate, endDate }: { startDate: string; endDate: string }) =>
      TeamService.getTeamPickRenewal({
        ...result,
        startDate,
        endDate,
      }),
  );

  useEffect(() => {
    mutate({
      startDate: getStringFormattedDate(startDate),
      endDate: getStringFormattedDate(endDate),
    });
  }, [startDate, endDate]);

  useEffect(() => {
    setStartDate(getStartDateByYear());
    setEndDate(getEndDateByYear());
  }, [result]);

  return (
    <WRAPPER>
      {/* 캘린더 주입 */}
      <WRAPPER_CALENDAR>
        <CalendarRange startDate={startDate} endDate={endDate} onChange={handleChangeCalendar} />
        {result.year[0] !== '2021' && (
          <CALENDER_CONTROLLER>
            {dateRangeService.SELECT_DATE_BTN_LIST.map((item, index) => (
              <MUITab
                className='btn-tab'
                variant='outlined'
                style={{ color: 'white' }}
                active={tab === index}
                onClick={() => {
                  handleClickCalendar(index, item.calc);
                }}
              >
                {t(item.title)}
              </MUITab>
            ))}
          </CALENDER_CONTROLLER>
        )}
      </WRAPPER_CALENDAR>

      {/* 탭 */}
      <TAB_CONATINER>
        {TABS.map((tab) => (
          <TAB_ITEM
            key={`banreport_${tab}`}
            className='All'
            onClick={() => setSide(tab)}
            changeColor={side === tab}
          >
            <div>
              <span>{tab.toUpperCase()}</span>
            </div>
          </TAB_ITEM>
        ))}
      </TAB_CONATINER>

      {/* 컨텐츠 */}
      <TAB_CONTENTS>
        <TAB_CONTENTS_CONTAINER>
          {isLoading ? (
            <ProgressContainer>
              <Progress text={t('solo.playerboard.dataFetching')} />
            </ProgressContainer>
          ) : isError ? (
            <NotFound css={{ height: '100%' }} text={t('solo.playerboard.dataFetchingFailed')} />
          ) : (
            data && <BanPickRenewal data={data.response} side={side} />
          )}
        </TAB_CONTENTS_CONTAINER>
      </TAB_CONTENTS>
    </WRAPPER>
  );
}

export default BanIndex;

const WRAPPER = styled.div`
  max-width: 1200px;
`;

const TAB_CONATINER = styled.div`
  display: flex;
  width: 100%;
  height: 62px;
  border-bottom: solid 1px #433f4e;
`;

const TAB_ITEM = styled.button<{ changeColor: boolean }>`
  display: flex;
  align-items: center;
  width: auto;
  white-space: nowrap;

  div {
    padding: 10px 15px;
  }

  :hover {
    div {
      padding: 10px 15px;
      border-radius: 10px;
      background-color: #26262c;
    }
  }

  span {
    height: 22px;
    font-family: SpoqaHanSansNeo;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 18px;
    border-bottom: solid 1px ${(props) => (props.changeColor ? `#fff` : `none;`)};
    color: ${(props) => (props.changeColor ? `#fff` : `#84818e`)};
  }
`;

const TAB_CONTENTS = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;

const TAB_CONTENTS_CONTAINER = styled.div`
  height: 100%;
  overflow: hidden;
`;

const ProgressContainer = styled.div`
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const WRAPPER_CALENDAR = styled.div`
  display: flex;
  margin: 20px 24px;
`;

const CALENDER_CONTROLLER = styled.div`
  width: 100%;
  display: flex;

  .btn-tab {
    font-size: 14px;
    width: auto;
    height: auto;
    padding: 8px 12px;
    margin-right: 4px;
    border-radius: 10px;
    height: 40px;
  }

  .btn-trigger {
    box-shadow: none;
    height: 40px;
    margin-left: 20px;
    flex: 1;
  }
`;

const MUITab = MUIStyled(Button)<{ active: boolean }>(
  ({ active }) => `
background-color: ${active ? `rgba(89, 66, 186, 0.2)` : colors.bg_select};
border: ${active ? ' 1px solid #5442b0' : '1px solid transparent'};
`,
);
