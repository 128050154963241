/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { jsx, css } from '@emotion/react';
import Tippy from '@tippy.js/react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useMutation } from 'react-query';
import WardPlayerFilter from './WardPlayerFilter';
import WardTeamFilter from './WardTeamFilter';
import WardTooltip from './WardTooltip';
import PathAnalysisAtom, { PathAnalysisResultAtom } from '../../../recoil/Filter/pathAnalysisAtom';

import NotFound from '../../../Components/Ui/Error/NotFound';
import JunglePathingService, { IWarding, IWardParam } from '../../../services/JunglePathingServices';
import { MAP_WARD, WardType } from '../../../lib/mapping/map_ward';
import { getTrueValueList } from '../../../lib/getTureValueList';

const OLD_X_MIN = -120;
const OLD_X_MAX = 14870;

const OLD_Y_MIN = -120;
const OLD_Y_MAX = 14980;

const NEW_X_MAX = 295;
const NEX_X_MIN = 0;
const NEW_Y_MAX = 0;
const NEW_Y_MIN = 295;

const convertX = (OLD_X: number) => {
  return ((OLD_X - OLD_X_MIN) * (NEW_X_MAX - NEX_X_MIN)) / (OLD_X_MAX - OLD_X_MIN) + NEX_X_MIN - 7;
};
const convertY = (OLD_Y: number) => {
  return ((OLD_Y - OLD_Y_MIN) * (NEW_Y_MAX - NEW_Y_MIN)) / (OLD_Y_MAX - OLD_Y_MIN) + NEW_Y_MIN - 7;
};

const sectorName = {
  0: 1,
  1: 2,
  2: 3,
  3: 4,
  4: 5,
  5: 6,
  6: 7,
  7: 8,
  8: 9,
  9: 10,
  10: 11,
  11: 12,
  12: 13,
  13: 14,
  14: 'Error',
  15: 'None',
};

const makeBackgroundUrl = (index: string) => {
  if (index === '') {
    return '/Images/ward_map.png';
  }
  return `/Images/minimap_new/${index}.png`;
};

function WardMapping() {
  const result = useRecoilValue(PathAnalysisResultAtom)!;
  const selectedTeam = getTrueValueList(useRecoilValue(PathAnalysisAtom.teams))?.[0] ?? '';
  const [selectedPlayer, setSelectedPlayer] = useState<string>('');
  const [selectedChampArray, setSelectedChampArray] = useState<string[]>([]);
  const [warding, setWarding] = useState<IWarding[]>([]);

  //PathAnalysisAtom
  const { t } = useTranslation();
  // 시간 설정 상태값
  const [minFrom, setMinFrom] = useState([0, 100]);

  const [side, setSide] = useState('all');
  const [tab, setTab] = useState<'team' | 'player'>('team');
  const [compareOpen, setCompareOpen] = useState(false);

  // fetch data 상태값
  const [wards, setWards] = useState([]);
  const [sector, setSector] = useState<number[]>([]);
  const [totalWard, setTotalWard] = useState<number>();
  const [mapSector, setMapSector] = useState('');
  const [isClicked, setIsClicked] = useState(false);

  const firstTime = minFrom[0] * 5100;
  const secondTime = minFrom[1] * 5100;

  const contents = {
    team: <WardTeamFilter minFrom={minFrom} setMinFrom={setMinFrom} />,
    player: (
      <WardPlayerFilter
        compareOpen={compareOpen}
        setCompareOpen={setCompareOpen}
        minFrom={minFrom}
        setMinFrom={setMinFrom}
        selectedTeam={selectedTeam}
        selectedPlayer={selectedPlayer}
        setSelectedPlayer={setSelectedPlayer}
        selectedChampArray={selectedChampArray}
        setSelectedChampArray={setSelectedChampArray}
      />
    ),
  };

  // useEffect(() => {
  //   setWards([]);
  //   setSector([]);
  //   setTotalWard(undefined);
  //   setMapSector('');
  //   setIsClicked(false);
  // }, [filters.team]);

  const {
    mutate: getWard,
    isLoading,
    isError,
  } = useMutation(
    ({ team, player, champion, side, firstTime, secondTime }: IWardParam) =>
      JunglePathingService.getWard({
        ...result,
        team,
        player,
        champion,
        side,
        firstTime,
        secondTime,
      }),
    {
      onSuccess: (data) => {
        if (!data) return;
        setWarding(data.response.warding);
        const warding: IWarding[] = data.response.warding.filter((el) => Object.keys(el).length !== 0);
        calcSectorData(warding);
      },
    },
  );

  const calcTotal = (data: IWarding[]) => {
    let result = 0;
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].firstward) {
        result += 1;
      }
      //! what is the secondward?
      // if (data[i].secondward) {
      //   result += 1;
      // }
    }

    return result;
  };

  // ai 데이터 계산
  const calcFirstWardArray = (data: any) => {
    const result = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let placed = 0;
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].firstwardPlaced === 200) {
        placed = 15;
      } else if (data[i].firstwardPlaced === 100) {
        placed = 14;
      } else {
        placed = Number(data[i].firstwardPlaced);
      }

      result[placed] += 1;
    }

    return result;
  };

  // pi ward 데이터 계산
  const calcSecondWardArray = (data: any) => {
    const result = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    let placed = 0;
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].secondwardPlaced === 200) {
        placed = 15;
      } else if (data[i].secondwardPlaced === 100) {
        placed = 14;
      } else {
        placed = Number(data[i].secondwardPlaced);
      }
      result[placed] += 1;
    }

    return result;
  };

  const sumArray = (arr1: number[], arr2: number[]) => arr1.map((item, idx) => item + arr2[idx]);

  const calcSectorData = (dto: IWarding[]) => {
    if (dto.length > 0) {
      const total = calcTotal(dto);
      setTotalWard(total);

      const firstWardDataArray = calcFirstWardArray(dto);
      const secondWardDataArray = calcSecondWardArray(dto);
      const totalSumArray = sumArray(firstWardDataArray, secondWardDataArray);
      setSector(totalSumArray);
    }
  };

  const handleWardClick = (side: string) => {
    getWard({
      ...result!,
      team: selectedTeam,
      player: selectedPlayer,
      champion: selectedChampArray.length === 0 ? null : selectedChampArray,
      side,
      firstTime,
      secondTime,
    });
  };

  // const handleTotalClick = () => {
  //   mapSector === '' || mapSector !== 15 ? setMapSector(15) : setMapSector('');
  // };

  return (
    <WardMappingContainer>
      <LeftSection>
        <WardMappingTabs>
          <TopTabItem
            onClick={() => {
              setTab('team');
              setCompareOpen(false);
              setWards([]);
              setTotalWard(0);
              setMapSector('');
            }}
            changeColor={tab === 'team'}
          >
            <div>
              <span>{t('video.vision.teamview')}</span>
            </div>
          </TopTabItem>
          <TopTabItem
            onClick={() => {
              setTab('player');
              setCompareOpen(false);
              setWards([]);
              setTotalWard(0);
              setMapSector('');
            }}
            changeColor={tab === 'player'}
          >
            <div>
              <span>{t('video.vision.playerview')}</span>
            </div>
          </TopTabItem>
          <LastMargin />
        </WardMappingTabs>
        <FilterContainer>
          <FilterContents>{contents[tab]}</FilterContents>
          <div className='ward-btn-area'>
            <WardButton
              onClick={() => {
                handleWardClick('all');
                setSide('all');
              }}
              isActive={tab === 'player' ? !!selectedChampArray : true}
            >
              {t('video.vision.checkWard')}
            </WardButton>
          </div>
        </FilterContainer>
      </LeftSection>
      <RightSection>
        <ViewContainer>
          <ButtonContainer>
            <TabItem
              onClick={() => {
                setSide('all');
                handleWardClick('all');
              }}
              changeColor={side === 'all'}
            >
              <div>ALL</div>
            </TabItem>

            <TabItem
              onClick={() => {
                setSide('blue');
                handleWardClick('blue');
              }}
              changeColor={side === 'blue'}
            >
              <div>BLUE</div>
            </TabItem>
            <TabItem
              onClick={() => {
                setSide('red');
                handleWardClick('red');
              }}
              changeColor={side === 'red'}
            >
              <div>RED</div>
            </TabItem>
          </ButtonContainer>
          {/* <WardTable>
            {isLoading ? (
              <ProgressContainer>
                <Progress text={t('solo.playerboard.dataFetching')} />
              </ProgressContainer>
            ) : isError ? (
              <NotFound css={{ height: '100%' }} text={t('solo.playerboard.dataFetchingFailed')} />
            ) : (
              <WardTableWrapper>
                <TotalWard>
                  <LabelArea>
                    <span>{t('video.vision.totalWard')}</span>
                    <p>{`${totalWard ? totalWard : 0}`}</p>
                  </LabelArea>
                  <LabelArea>
                    {totalWard ? (
                      <button type='button' onClick={handleTotalClick}>
                        {t('video.vision.allLabel')}
                      </button>
                    ) : (
                      ''
                    )}
                  </LabelArea>
                </TotalWard>
                {!isLoading && sector.length > 0 ? (
                  <DisplayTable>
                    <thead>
                      <tr>
                        <th>{t('video.vision.area')}</th>
                        <th>{t('video.vision.count')}</th>
                        <th>{t('video.vision.probability')}</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {sector.map((sector, idx) => {
                        if (idx === 14) return '';
                        return (
                          <tr key={'sector' + idx}>
                            <td className='Sector'>{sectorName[idx]}</td>
                            <td className='Num'>{sector}</td>
                            <td className='Rate'>{`${((sector / totalWard) * 100).toFixed(1)}%`}</td>
                            <td className='Detail'>
                              <button type='button' onClick={() => setMapSector(idx)}>
                                {t('video.vision.details')}
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </DisplayTable>
                ) : (
                  <NoContentTable className='nocontents' colspan='4'>
                    <img src='/Images/img-no-contents.png' alt='' />
                    <label htmlFor='option'>{t('video.vision.option')}</label>
                  </NoContentTable>
                )}
              </WardTableWrapper>
            )}
          </WardTable> */}
        </ViewContainer>
        <WardMap
          style={{
            backgroundImage: `url(${makeBackgroundUrl(mapSector)})`,
          }}
        >
          {isLoading ? (
            <LoadingImage>
              <img src='/Images/loadingSpinner_purple.gif' alt='Loading' />
            </LoadingImage>
          ) : isError ? (
            <NotFound css={{ height: '100%' }} text={t('solo.playerboard.dataFetchingFailed')} />
          ) : (
            warding.map((ward, idx) => {
              const firstWard = ward?.firstward && ward?.firstwardPosition?.split(',');
              const x = firstWard && convertX(Number(firstWard[0])) * (512 / 295);
              const y = firstWard && convertY(Number(firstWard[1])) * (512 / 295);

              // console.log(ward.firstward);

              return (
                <WardInfoWrapper key={`ward${idx}`}>
                  {firstWard && (
                    <Tippy
                      duration={0}
                      delay={[100, 0]}
                      trigger='mouseenter'
                      content={
                        <WardTooltip data={ward} wardType={ward.firstward} time={ward.firstwardTime} />
                      }
                      placement='top'
                    >
                      <WardInfo
                        className={`ward ${ward.team} ${ward.side} ${ward.player}`}
                        style={{
                          top: `${y}px`,
                          left: `${x}px`,
                          height: '15px',
                          width: '18px',
                          backgroundImage: `url(${MAP_WARD[ward.firstward as WardType]?.icon}${
                            ward.side
                          }.png)`,
                        }}
                      />
                    </Tippy>
                  )}
                </WardInfoWrapper>
              );
            })
          )}
        </WardMap>
      </RightSection>
    </WardMappingContainer>
  );
}

export default WardMapping;

const LabelArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  height: 50.5px;
  > span {
    font-family: SpoqaHanSansNeo;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-right: 5px;
  }
  > p {
    font-family: SpoqaHanSansNeo;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #f04545;
  }
  > button {
    width: 140px;
    height: 34px;
    padding: 9px 18px 8px;
    border-radius: 10px;
    margin-right: 10px;
    font-family: SpoqaHanSansNeo;
    font-size: 13px;
    letter-spacing: -0.6px;
    text-align: center;
    padding: 3px 5px;
    color: #fff;
    background-color: #484655;
  }
`;

const NoContentTable = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  > img {
    width: 31px;
    height: 31px;
  }
  > label {
    margin-top: 10px;
    font-family: SpoqaHanSansNeo;
    font-size: 12px;
    letter-spacing: -0.6px;
    text-align: center;
    color: rgb(107, 105, 121);
  }
`;

const WardInfoWrapper = styled.div``;

const WardInfo = styled.div`
  position: absolute;
`;

const WardMappingContainer = styled.div`
  display: flex;
  min-height: 100vh;
  height: 100%;
  margin-top: 0px;
`;

const LeftSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-right: 22px;
`;

const RightSection = styled.section``;

const FilterContainer = styled.div`
  width: 564px;
  min-height: 264px;
  /* border: solid 1px rgb(67, 63, 78); */
  background-color: #2f2d38;
  border-radius: 20px;
  padding-top: 30px;

  .ward-btn-area {
    border-top: solid 1px #433f4e;
  }
`;

const TabContainer = styled.div`
  display: flex;
  width: 504px;
  height: 60px;
  margin: 0 30px 30px;
`;

const TopTabItem = styled.button<{ changeColor: boolean }>`
  display: flex;
  align-items: center;
  width: auto;
  border-bottom: solid 1px #433f4e;
  white-space: nowrap;
  div {
    border-radius: 10px;
    padding: 10px 15px;
  }

  :hover {
    div {
      padding: 10px 15px;
      background-color: #26262c;
    }
  }
  span {
    height: 22px;
    font-family: SpoqaHanSansNeo;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 18px;

    border-bottom: solid 1px ${(props) => (props.changeColor ? `#fff` : `none`)};
    color: ${(props) => (props.changeColor ? `#fff` : `#84818e`)};
  }
`;

const TabItem = styled.button<{ changeColor: boolean }>`
  width: 72px;
  border-radius: 10px;
  background-color: ${(props) => (props.changeColor ? '#23212a' : ' #3a3745')};
  padding: 9px 15px;
  margin-right: 5px;
  height: 34px;
  font-family: 'SpoqaHanSansNeo';
  font-size: 13px;
  color: ${(props) => (props.changeColor ? `#fff` : `#84818e`)};
`;

const FilterContents = styled.div``;

const WardButton = styled.button<{ isActive: boolean }>`
  width: 524px;
  height: 60px;
  margin: 20px 20px;
  padding: 21px 20px 20px;
  border-radius: 20px;
  background-color: rgb(105, 103, 119);
  color: #fff;
  :hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.isActive &&
    css`
      background-color: #5942ba;
    `}
`;

const ViewContainer = styled.div``;

const WardMap = styled.div`
  width: 512px;
  height: 512px;
  position: relative;
  background-image: url('/Images/ward_map.png');
  z-index: 9999;
`;

const ButtonContainer = styled.div`
  margin: 20px 0 10px 0;
`;

const WardTable = styled.div`
  width: 564px;
  min-height: 330px;
  /* border: solid 1px #3a3745; */
  background-color: #23212a;
  border-radius: 20px;
  position: relative;
`;

const TotalWard = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const DisplayTable = styled.table`
  width: 100%;

  > thead > tr {
    width: 100%;
    height: 28px;
    background-color: #3a3745;
    th {
      padding: 10px;
      font-family: SpoqaHanSansNeo;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: -0.6px;
      text-align: center;
      color: #817e90;
    }
  }

  > tbody > tr {
    border-top: 1px solid #3a3745;
    width: 100%;
    height: 39.5px;
    color: #ffffff;
    font-size: 15px;
    font-family: Poppins;
    :hover {
      background-color: #817e90;
    }
    > .Sector {
      width: 10%;
      vertical-align: middle;
      text-align: center;
    }
    > .Num {
      width: 50%;
      vertical-align: middle;
      text-align: center;
    }
    > .Rate {
      width: 10%;
      vertical-align: middle;
      text-align: center;
    }
    > .Detail {
      vertical-align: middle;
      text-align: right;
      padding-right: 30px;
      width: 30%;
      > button {
        width: 102px;
        height: 34px;
        border-radius: 10px;
        border: solid 1px #474554;
        //background-color: #3a3745;
        font-family: SpoqaHanSansNeo;
        font-size: 13px;
        letter-spacing: -0.6px;
        text-align: center;
        color: #6b6979;
      }
    }
  }
`;

const LineMargin = styled.div`
  width: 30px;
  border-bottom: solid 1px #433f4e;
`;

const LastMargin = styled.div`
  width: 73%;
  border-bottom: solid 1px #433f4e;
`;

const WardMappingTabs = styled.div`
  display: flex;
  height: 62px;
  margin-bottom: 20px;
`;

const LoadingImage = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1;
  img {
    width: 50px;
    height: 50px;
  }
`;

const ProgressContainer = styled.div`
  width: 100%;
  height: 330px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const WardTableWrapper = styled.div``;
