import React, { memo, ReactNode, SyntheticEvent, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { SetterOrUpdater } from 'recoil';
import { InputBase, styled, SelectProps, FormLabel } from '@mui/material';
import { colors } from '../../../Styles/ui';
import { useTranslation } from 'react-i18next';

type RenderList = {
  value: string;
  label?: string;
};

interface CustomSelectProps extends SelectProps<any> {
  renderList: RenderList[];
  placeholder: string;
  data: string | undefined;
  setData: React.Dispatch<React.SetStateAction<string | undefined>> | SetterOrUpdater<string>;
}

const SimpleSelect = ({ renderList, placeholder, data, setData }: CustomSelectProps) => {
  const { t } = useTranslation();
  const handleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;

    setData(value);
  };

  const handleClick = (e: SyntheticEvent<unknown>) => {
    e.preventDefault();
  };

  return (
    <FormControl fullWidth>
      <StyledFormLabel onClick={handleClick} />

      <MUISELECT
        displayEmpty
        input={<StyledInputBase />}
        value={data}
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: colors.배경색[900],
              color: 'white',
              maxHeight: '180px',
              overflow: 'overlay',
              scrollbarWidth: '3px',
            },
          },
        }}
        onChange={handleChange}
        renderValue={(value) => {
          if (!value) {
            return <em>{placeholder}</em>;
          }

          if (value === 100) {
            return t('scrim.filter.blue');
          }
          if (value === 200) {
            return t('scrim.filter.red');
          }

          return value;
        }}
      >
        <MUIMENUITEM disabled value=''>
          {placeholder}
        </MUIMENUITEM>
        {renderList &&
          renderList.map((list) => {
            return (
              <MUIMENUITEM key={list.value} value={list.value}>
                {list.label ?? list.value}
              </MUIMENUITEM>
            );
          })}
      </MUISELECT>
    </FormControl>
  );
};

export default memo(SimpleSelect);

const MUISELECT = styled(Select)<SelectProps<string>>`
  padding: 0;
  .MuiSvgIcon-root {
    color: ${colors.white};
  }
  .data {
    color: white;
  }
`;

const StyledFormLabel = styled(FormLabel)`
  display: none;

  &.MuiFormLabel-root.Mui-focused {
    color: white;
  }

  &.MuiFormLabel-filled ~ div {
    background: rgba(89, 66, 186, 0.2);
    color: white;
    border: 1px solid ${colors.보라색[500]};
  }
`;

const MUIMENUITEM = styled(MenuItem)`
  font-size: 13px;
`;

const StyledInputBase = styled(InputBase)`
  border-radius: 10px;
  position: relative;
  font-size: 13px;
  height: 40px;
  color: #79797c;
  background-color: ${colors.bg_box};
  transition: background 0.2s ease-in-out;

  & .MuiInputBase-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 16px;
  }

  &:focus {
    border-radius: 10px;
    border-color: ${colors.point};
    box-shadow: 0 0 0 0.1rem inset ${colors.point};
  }
`;
