import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import Tippy from '@tippy.js/react';
import { useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { recentVersion } from '../../../config';
import ObjectTooltip from '../../ObjectMapping/ObjectTooltip';
import PositionCheckList from '../../../../Components/Ui/PositionCheckList';
import ToggleSwitch from '../../../../Components/Ui/ToggleSwitch/ToggleSwitch';
import WardTooltip from '../../WardMapping/WardTooltip';
import addZero from '../../../../lib/addZero';
import { PathAnalysisResultAtom } from '../../../../recoil/Filter/pathAnalysisAtom';
import { MAP_WARD, WardType } from '../../../../lib/mapping/map_ward';

import WardPathingServices, {
  MappingInfo,
  MappingPosition,
  MappingWard,
} from '../../../../services/WardPathingService';
import { wardSideFilterResultAtom } from '../../../../recoil/WardPathing/wardPathingAtom';
import useChampionImage from '../../../../Hooks/useChampionImage';

function useIntervalFast(callback: any) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    const id = setInterval(tick, 100);
    return () => clearInterval(id);
  }, []);
}

function useIntervalNormal(callback: any) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    const id = setInterval(tick, 300);
    return () => clearInterval(id);
  }, []);
}

function convertObjectType(keyword: string) {
  switch (keyword) {
    case 'all':
      return 0;
    case 'firstdragon':
      return 1;
    case 'seconddragon':
      return 2;
    case 'firstherald':
      return 3;
    default:
      return 0;
  }
}

const OLD_X_MIN = -120;
const OLD_X_MAX = 14870;

const OLD_Y_MIN = -120;
const OLD_Y_MAX = 14980;

const NEW_X_MAX = 295;
const NEX_X_MIN = 0;
const NEW_Y_MAX = 0;
const NEW_Y_MIN = 295;

const convertX = (OLD_X: number) => {
  return ((OLD_X - OLD_X_MIN) * (NEW_X_MAX - NEX_X_MIN)) / (OLD_X_MAX - OLD_X_MIN) + NEX_X_MIN - 7;
};
const convertY = (OLD_Y: number) => {
  return ((OLD_Y - OLD_Y_MIN) * (NEW_Y_MAX - NEW_Y_MIN)) / (OLD_Y_MAX - OLD_Y_MIN) + NEW_Y_MIN - 7;
};

const WardObjectMap = () => {
  const { getChampionImagePathByName } = useChampionImage();
  const result = useRecoilValue(PathAnalysisResultAtom);
  const sideFilterResult = useRecoilValue(wardSideFilterResultAtom);
  const selectedLeagues = result.league;
  const selectedYears = result.year;
  const selectedSeasons = result.season;
  const selectedTeam = result.team[0];
  const selectedPatchs = result.patch;
  const junglevalue = useSelector((state) => state.JungleMapReducer);
  const { t } = useTranslation();
  const rangeRef = useRef();

  const [position, setPosition] = useState({
    all: true,
    top: true,
    jng: true,
    mid: true,
    bot: true,
    sup: true,
  });
  const [checked, setChecked] = useState(true);

  // mapping data
  const [mappingPosition, setMappingPosition] = useState<MappingPosition>();
  const [mappingInfo, setMappingInfo] = useState<MappingInfo>();
  const [wardData, setWardData] = useState<MappingWard>();

  // range handling
  const [range, setRange] = useState(0);
  const [minTime, setMinTime] = useState<number>();
  const [maxTime, setMaxTime] = useState<number>();
  const [play, setPlay] = useState(false);
  const [playFast, setPlayFast] = useState(false);
  const [pause, setPause] = useState(false);

  // 동선 포지션별 필터링
  const filteredPosition = useCallback(
    (info) => {
      return (
        position &&
        Object.keys(position)
          .filter((key) => position[key] === true)
          .includes(info.position)
      );
    },
    [position],
  );

  // 와드 필터링
  const displayWard = useCallback(
    (ward) => {
      return (
        (range + minTime) * 1000 >= ward.firstwardTime && (range + minTime) * 1000 < ward.firstwardDestroy
      );
    },
    [range, minTime],
  );

  const { mutate, isLoading, isError } = useMutation((params) => WardPathingServices.getMappingData(params));

  useIntervalNormal(() => {
    if (play === true && maxTime && range < maxTime) {
      setRange((prevRange) => Number(prevRange) + 1);
    }
  });

  useIntervalFast(() => {
    if (playFast === true && maxTime && range < maxTime) {
      setRange((prevRange) => Number(prevRange) + 1);
    }
  });

  // ward object mapping api 호출
  useEffect(() => {
    if (!result || !sideFilterResult) return;
    GetMappingInfo();
  }, [sideFilterResult]);

  const onPlay = () => {
    setPlay(true);
    setPause(false);
  };

  const onFast = () => {
    setPlayFast(true);
    setPause(false);
  };

  const onPause = () => {
    setPlayFast(false);
    setPlay(false);
    setPause(true);
  };

  const onReset = () => {
    onPause();
    setRange(0);
  };

  const GetMappingInfo = async () => {
    const type = convertObjectType(junglevalue.object);
    const RENDER_TIME = type === 0 ? 900 : 90; // 전체 타입 조회일때는 60 * 15  / 60 * 1.5;

    const params = {
      league: selectedLeagues,
      year: selectedYears,
      season: selectedSeasons,
      patch: selectedPatchs,
      team: selectedTeam,
      side: 'all',
      type,
      position: ['top', 'jug', 'mid', 'bot', 'sup'],
      ...sideFilterResult,
    };

    mutate(params, {
      onSuccess: (data) => {
        const result = data.response;

        if (!result || result.position.length === 0) {
          alert(t('video.wardPathing.noData'));
        } else {
          onReset();
          setMinTime(result?.position[0].realCount ? result?.position[0].realCount : 0);
          setMaxTime(result.position.length - 1 > RENDER_TIME ? RENDER_TIME : result.position.length - 1);
          setMappingPosition(result?.position.slice(0, RENDER_TIME));
          setMappingInfo(result?.info);
          setWardData(result?.ward.warding.slice(0, RENDER_TIME));
        }
      },
    });
  };

  return (
    <>
      {sideFilterResult && (
        <>
          <SVideoNavContainer>
            <PositionCheckList position={position} setPosition={(position) => setPosition(position)} />
            <SToggleSwitchContainer>
              <SWard>
                <SWardIcon src='/Images/ic_trinketward.svg' />
                <SWardText>{t('video.wardPathing.ward')}</SWardText>
              </SWard>
              <ToggleSwitch checked={checked} onChange={(checked) => setChecked(checked)} />
            </SToggleSwitchContainer>
          </SVideoNavContainer>

          <SMapContainer>
            <SWardPathingMap>
              {isLoading ? (
                <OVERLAY>
                  <CircularProgress />
                </OVERLAY>
              ) : isError ? (
                <OVERLAY>
                  <NoData>
                    <ErrorImg src='/Images/icon/ic_error_page.png' alt='errorIcon' />
                    <NoDataDetail>{t('video.jungle.noData')}</NoDataDetail>
                  </NoData>
                </OVERLAY>
              ) : (
                <>
                  {/* 챔피언 동선 */}
                  {mappingInfo?.filter(filteredPosition).map((info, idx) => {
                    let x;
                    let y;

                    // 사이드에 따른 초기 좌표 설정
                    if (range === 0) {
                      if (info.side === 'red') {
                        x = 630;
                        y = 45;
                      } else {
                        x = 45;
                        y = 640;
                      }
                    } else {
                      for (let i = 0; i < mappingPosition[range]?.player?.length; i++) {
                        if (
                          `${info.gameid} ${info.side} ${info.position}` ===
                          `${mappingPosition[range]?.player[i]?.gameid} ${mappingPosition[range]?.player[i]?.team} ${mappingPosition[range]?.player[i]?.position}`
                        ) {
                          if (
                            Number(mappingPosition[range]?.player[i].x) !== 0 ||
                            Number(mappingPosition[range]?.player[i].y) !== 0
                          ) {
                            x = convertX(Number(mappingPosition[range]?.player[i].x)) * (720 / 295);
                            y = convertY(Number(mappingPosition[range]?.player[i].y)) * (720 / 295);
                          }
                        }
                      }
                    }
                    return (
                      <StyledTippy
                        // options
                        arrow
                        duration={0}
                        delay={[0, 0]}
                        trigger='click'
                        key={info.date + info.gameid + info.position}
                        content={
                          <ObjectTooltip
                            champion={info.champions}
                            side={info.side}
                            gameid={info.idx}
                            position={info.position}
                            player={info.player}
                            result={info.win}
                            oppteam={info.opp_team}
                            oppchampion={info.opp_champions}
                          />
                        }
                        placement='top'
                      >
                        <PlayerInfo
                          className={`mapping ${info.idx} ${info.side} ${info.position}`}
                          style={{
                            top: `${y}px`,
                            left: `${x}px`,
                            height: '30px',
                            width: '30px',
                            transition: 'all 0.25s ease-out 0s',
                            backgroundImage: `url(${getChampionImagePathByName(info.champions)})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            borderRadius: '50%',
                            border: `1px solid ${info.side}`,
                          }}
                        />
                      </StyledTippy>
                    );
                  })}

                  {/* 와드 */}
                  {checked &&
                    wardData?.filter(displayWard).map((ward, idx) => {
                      const firstWard = ward?.firstwardPosition?.split(',');
                      const x = convertX(Number(firstWard[0])) * (720 / 295);
                      const y = convertY(Number(firstWard[1])) * (720 / 295);

                      return (
                        <WardInfoWrapper key={ward.position + ward.firstwardDestroy}>
                          {ward.firstwardPosition ? (
                            <Tippy // optionsx
                              duration={0}
                              delay={[100, 0]}
                              trigger='mouseenter'
                              content={
                                <WardTooltip
                                  data={ward}
                                  wardType={ward.firstward}
                                  time={ward.firstwardTime}
                                />
                              }
                              placement='top'
                            >
                              <WardInfo
                                className={`ward ${ward.team} ${ward.side} ${ward.player}`}
                                style={{
                                  top: `${y ?? null}px`,
                                  left: `${x ?? null}px`,
                                  height: '15px',
                                  width: '18px',
                                  backgroundImage: `url(${MAP_WARD[ward.firstward as WardType].icon}${
                                    ward.side
                                  }.png)`,
                                }}
                              />
                            </Tippy>
                          ) : (
                            ''
                          )}
                        </WardInfoWrapper>
                      );
                    })}
                </>
              )}
            </SWardPathingMap>

            {/* 재생 스크롤 */}
            {!isLoading && !isError && (
              <RangeWrapper>
                {(play || playFast) && !pause ? (
                  <img src='/Images/btn_stop.svg' alt='stopBtn' onClick={onPause} onKeyUp={onPause} />
                ) : (
                  <>
                    <img src='/Images/btn_play.svg' alt='playBtn' onClick={onPlay} onKeyUp={onPlay} />
                    <img src='/Images/pre_2.svg' alt='playFastBtn' onClick={onFast} onKeyUp={onFast} />
                  </>
                )}
                <RangeInput
                  min={0}
                  value={range}
                  max={maxTime}
                  ref={rangeRef}
                  id='rangeSlider'
                  type='range'
                  onChange={(e) => {
                    setRange(Number(e.target.value));
                  }}
                  onKeyPress={(e) => {
                    setRange(Number(e.target.value));
                  }}
                  step='1'
                />
                <TimeStamp>
                  <span className='current'>
                    {range
                      ? `${addZero(Math.floor((range + minTime) / 60))} : ${addZero(
                          Math.floor((range + minTime) % 60),
                        )}`
                      : '00 : 00'}
                  </span>
                  <p>/</p>
                  {maxTime ? (
                    <span className='max'>{`${junglevalue.object === 'all' ? '15 : 00' : '01 : 30'}`}</span>
                  ) : (
                    <span className='max'>00 : 00</span>
                  )}
                </TimeStamp>
              </RangeWrapper>
            )}
          </SMapContainer>
        </>
      )}
    </>
  );
};

export default WardObjectMap;

const SMapContainer = styled.section`
  width: 700px;
`;

const SWardPathingMap = styled.div`
  background-image: url('/Images/obj_map_summer.png');
  position: relative;
  width: 700px;
  height: 700px;
`;

const WardInfoWrapper = styled.div``;

const WardInfo = styled.div`
  position: absolute;
`;

const StyledTippy = styled(Tippy)``;

const PlayerInfo = styled.div`
  position: absolute;
`;

const RangeWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 14px 0 26px 0;
`;

const RangeInput = styled.input`
  width: 85%;
  height: 6px;
  border-radius: 3px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: linear-gradient(90deg, #3a3745 60%, #3a3745 60%);
  border-radius: 6px;
  border: 0.2px solid rgb(58, 55, 69);
  -webkit-appearance: none;
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid #817e90;
    height: 11px;
    width: 11px;
    border-radius: 50%;
    background: #817e90;
    cursor: pointer;
  }
`;

const TimeStamp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 17px;
  font-family: SpoqaHanSansNeo;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.87;
  letter-spacing: normal;
  color: #6b6979;
  p {
    margin: 0 4px 0 4px;
  }
  > .current {
    width: 45%;
    color: rgb(240, 69, 69);
    text-align: right;
  }
  > .max {
    width: 55px;
    color: #6b6979;
  }
`;

export const SVideoNavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SToggleSwitchContainer = styled.div`
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const SWard = styled.div`
  display: flex;
  align-items: center;
`;

export const SWardIcon = styled.img`
  width: 21px;
  height: 21px;
  margin-right: 3px;
`;

export const SWardText = styled.span`
  font-size: 16px;
  color: #ffffff;
`;

const OVERLAY = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoData = styled.div`
  width: 500px;
  height: 169px;
  text-align: center;
`;

const ErrorImg = styled.img``;

const NoDataDetail = styled.div`
  padding-top: 20px;
  color: #ffffff;
`;
