/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { useMutation } from 'react-query';
import MTHeader from '../Component/MTHeader';
import SRContent from '../Component/Common/SRContent';
import { Loading } from '../../../redux/modules/filtervalue';
import NoneDatabox from '../../../Components/Ui/NoneDatabox';
import { RootState } from '../../../redux/modules';
import SoloRankServices, { Players } from '../../../services/SoloRankServices';
import Progress from '../../../Components/Ui/Loading/Progress';
import { colors } from '../../../Styles/ui';
import { userLanguageAtom } from '../../../recoil/Auth/userAtom';

export interface HeaderInfo {
  logo?: string;
  text?: string;
}

const MyTeam = () => {
  const [selectedDay, setSelectedDay] = useState<string>('30');
  const [headerInfo, setheaderInfo] = useState<HeaderInfo[]>([]);
  const [playerInfo, setPlayerInfo] = useState<Players[]>([]);
  const [myTeamName, setMyTeamName] = useState<string>('');
  const lang = useRecoilValue(userLanguageAtom);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mutate: getMyTeamSoloRankInfo, isLoading } = useMutation(
    () =>
      SoloRankServices.getMyTeamSoloRankInfo({
        days: selectedDay,
      }),
    {
      onSuccess: (data) => {
        const e = data.response;
        if (Object.keys(e).length !== 0) {
          setheaderInfo([
            {
              logo: `/Images/ico-league-${'lck'}.png`,
              // text: e.league,
            },
            { logo: `/Images/TeamLogo/${e.team}.png` },
            { text: e.team },
            // { text: "" },
            { text: e.playerCount + (lang === 'ko' ? '명' : '') },
          ]);
          setPlayerInfo(e.players);
          setMyTeamName(e.team);
        }
        dispatch(Loading(false));
      },
    },
  );

  useEffect(() => {
    getMyTeamSoloRankInfo();
  }, [selectedDay]);

  if (isLoading) {
    return (
      <ProgressContainer>
        <Progress text={t('solo.playerboard.dataFetching')} />
      </ProgressContainer>
    );
  }

  return (
    <>
      {/* 팀 정보 헤더 */}
      {playerInfo.length > 0 && <MTHeader headerInfo={headerInfo} />}

      {/* 팀 선수 테이블 */}
      {playerInfo.length > 0 ? (
        <SRContent
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          playerInfo={playerInfo}
          getInfoFunc={getMyTeamSoloRankInfo}
          myTeamName={myTeamName}
          usingBookmark={false}
          usingDeletePlayer
          usingAddPlayer
          usingAddSoloId
          usingDeletePlayerGuide
          usingDeleteSoloId
        />
      ) : (
        <NoneDatabox headerText={t('soloRank.myTeam.desc.noDataHeader')} subText={undefined} />
      )}
    </>
  );
};

export default MyTeam;

const ProgressContainer = styled.div`
  width: ${1120}px;
  height: 160px;
  margin: 20px 0 0;
  padding: 0 30px;
  border-radius: 20px;
  background-color: ${colors.bg_select};
  display: flex;
  align-items: center;
  justify-content: center;
`;
