import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { t } from 'i18next';
import { useMutation } from 'react-query';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { colors, scrollbarStyle, spacing } from '../../Styles/ui';
import CustomSelect from '../../Components/Ui/Select/CustomSelect';
import { findLegueData, findSeasonData, findYearData } from '../../lib/searchLeagueData';
import { getTrueValueList, getTrueValueSeasonList } from '../../lib/getTureValueList';
import CheckboxList from '../../Components/Ui/CheckList/CheckboxList';
import { initializedSeasons } from '../../lib/initializedObjValue';
import FilterService from '../../services/FilterServices';
import { PatchParam } from '../../services/types/param_types';
import LeagueReportAtom, { LeagueReportResultAtom } from '../../recoil/Filter/leagueReportAtom';
import { errorAtom } from '../../recoil/errors/errorsAtom';
import { ApiError } from '../../services/axios/axios';
import { useFilterCall } from '../../Hooks/useFilterCall';
import { MAP_FILTER_MEMU } from '../../lib/mapping/map_filter_menu';
import LeagueSelect from '../../Components/Ui/Select/LeagueSelect';
import SideFilterConfirmButton, {
  SideFilterConfirmErrorType,
} from '../../Components/Filter/SideFilter/SideFilterConfirmButton';
import { IAxiosResponse } from '../../services/axios/axios.type';

const LeagueFilter = () => {
  const { data } = useFilterCall();

  const setGlobalError = useSetRecoilState(errorAtom);

  const getYear = findYearData(data);
  const getLeague = findLegueData(data);
  const getSeason = findSeasonData(data);

  //* year
  const [year, setYear] = useRecoilState(LeagueReportAtom.years);
  const yearRenderList = getYear().map((value) => ({ value }));
  const selectedYear = getTrueValueList(year);

  //* league
  const [league, setLeague] = useRecoilState(LeagueReportAtom.leagues);
  const leagueRenderList = getLeague(selectedYear).map((value) => ({ value }));
  const selectedLeague = getTrueValueList(league);

  //* season
  const [season, setSeason] = useRecoilState(LeagueReportAtom.seasons);
  // 22.05.19 솔로랭크 탭을 제외한 모든 필터에서 Off Season 제외
  const seasonList = getSeason(selectedYear, selectedLeague).filter((season) => season.name !== 'Off Season');
  const selectedSeasonAndPatch = getTrueValueSeasonList(season);
  const selectedSeason = selectedSeasonAndPatch?.map((season) => {
    return Object.keys(season)[0];
  });
  const selectedPatch = selectedSeasonAndPatch
    ?.map((season) => {
      const keys = Object.keys(season);
      return keys
        .map((key) => {
          return season[key];
        })
        .flat();
    })
    .flat();

  //* patch
  // const [patch, setPatch] = useRecoilState(LeagueReportAtom.patchs);
  // const selectedPatch = getTrueValueList(patch);

  //* error code
  const [errorCode, setErrorCode] = useState<SideFilterConfirmErrorType>();

  const { mutateAsync, reset } = useMutation<IAxiosResponse<string[]>, ApiError, PatchParam, unknown>(
    ({ year, league, season }: PatchParam) => FilterService.getPatch({ year, league, season }),
    {
      onError: (err) => {
        setGlobalError(err);
      },
    },
  );

  const setLeagueReportResult = useSetRecoilState(LeagueReportResultAtom);

  // effect
  useEffect(() => {
    return () => {
      setYear({});
    };
  }, []);

  useEffect(() => {
    setLeague({});
  }, [year]);

  useEffect(() => {
    if (selectedLeague && selectedLeague.length > 0) {
      setSeason(initializedSeasons(seasonList, true));
      // setSeason(seasonList);
    } else {
      setSeason({});
    }
  }, [league, year]);

  // handler
  const handleSubmit = () => {
    if (!selectedYear || selectedYear.length === 0) {
      setErrorCode('연도');
      return;
    }
    if (!selectedLeague || selectedLeague.length === 0) {
      setErrorCode('리그');
      return;
    }
    if (!selectedSeason || selectedSeason.length === 0) {
      setErrorCode('시즌');
      return;
    }
    if (!selectedPatch || selectedPatch.length === 0) {
      setErrorCode('패치');
      return;
    }
    if (errorCode) {
      setErrorCode(undefined);
    }

    setLeagueReportResult({
      year: selectedYear,
      league: selectedLeague,
      season: selectedSeason,
      patch: selectedPatch,
    });
  };

  return (
    <CONTAINER>
      <SCROLL_CONTAINER>
        {/* 연도 */}
        <Section>
          <CustomSelect
            step={1}
            placeholder={t(MAP_FILTER_MEMU.연도)}
            renderList={yearRenderList}
            setData={setYear}
            data={year}
          />
        </Section>
        {/* 리그 */}
        <Section>
          <LeagueSelect
            step={2}
            placeholder={t(MAP_FILTER_MEMU.리그)}
            renderList={leagueRenderList}
            setData={setLeague}
            data={league}
          />
        </Section>

        <LINE />

        <Section>
          <CheckboxList placeholder={t(MAP_FILTER_MEMU.시즌)} step={3} data={season} setData={setSeason} />
        </Section>

        {/* <Section>
          <CheckboxList
            step={4}
            column={2}
            placeholder={t(MAP_FILTER_MEMU.패치)}
            data={patch}
            setData={setPatch}
            sortType='patch'
          />
        </Section> */}
      </SCROLL_CONTAINER>
      <SideFilterConfirmButton handleSubmit={handleSubmit} errorCode={errorCode} />
    </CONTAINER>
  );
};

const CONTAINER = styled.div`
  height: 100%;
  background-color: ${colors.배경색[800]};
  width: 220px;
  color: ${colors.white};
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SCROLL_CONTAINER = styled.div`
  ${scrollbarStyle.scroll_4}
  ${spacing.paddingT(10)}
  ${spacing.paddingX(4)}
  overflow-x: hidden;
`;

const Section = styled.div`
  ${spacing.marginB(5)}

  &:last-of-type {
    ${spacing.marginB(0)}
  }
`;

const LINE = styled.div`
  height: 1px;
  background-color: ${colors.배경색[500]};
  ${spacing.marginB(5)}
`;

export default LeagueFilter;
