import React, { memo } from 'react';
import GameReportIndex from './Components/GameReportIndex';

const GameReportTab = () => {
  return (
    <div>
      <GameReportIndex />
    </div>
  );
};

export default memo(GameReportTab);
