/* eslint-disable no-unused-expressions */
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import React, { useRef, useState } from 'react';
// 리덕스 상태관련
import { useSelector, useDispatch } from 'react-redux';
// 클립보드 & 엑셀 데이터 export 관련
import * as clipboard from 'clipboard-polyfill/text';
import XLSX from 'xlsx';
// UI 관련
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { setTableHeaders, TableHeaders } from '../../redux/modules/tablevalue';
import timeFormat from '../../lib/timeFormat';
// 모달관련
import { useModal } from '../../Hooks/index';
import { modalList } from '../Modals/Modals';
import { borderRadiusStyle, buttonStyle, colors, spacing, typoStyle } from '../../Styles/ui';
import Avatar from '../Ui/Avatar';

import { useDetectOutside } from '../../Hooks/useDetectedOutside';

const ExportUtil = ({ filename = 'none', tableid }) => {
  // ref
  const dropdownRef = useRef(null);
  const tblHeaders = useRef([]);
  // hook
  const { t } = useTranslation();
  const { openModal } = useModal();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const ref = useDetectOutside(setIsActive);
  const tblvalue = useSelector((state) => state.TableReducer);

  const getTableHeaders = () => {
    const table = document.getElementById(tableid);
    const rowData = table.rows[0].cells;
    const result = [];

    for (let colCnt = 0; colCnt < rowData.length; colCnt += 1) {
      let columnData = rowData[colCnt].innerText;
      if (columnData !== null && columnData.length !== 0) {
        columnData = columnData.toString().replace(/"/g, '""'); // escape double quotes
        result.push(columnData);
      }
    }

    tblHeaders.current = result;
    dispatch(setTableHeaders(result));
  };
  function tabledata(type) {
    const BOM = '\uFEFF'; // 바이트 순서 표식
    let result = BOM;
    const table = document.getElementById(tableid);
    const regex = /\n/gi; // 줄바꿈 제거 정규식

    for (let rowCnt = 0; rowCnt < table.rows.length; rowCnt += 1) {
      const rowData = table.rows[rowCnt].cells;
      for (let colCnt = 0; colCnt < rowData.length; colCnt += 1) {
        let columnData = rowData[colCnt].innerText;
        if (columnData == null || columnData.length === 0) {
          columnData = ''.replace(/"/g, '""');
        } else {
          columnData = columnData.toString().replace(/"/g, '""').replace(regex, ''); // escape double quotes
        }
        // console.log(result);
        result = type === 'csv' ? `${result}"${columnData}",` : `${result + columnData}\t`;
      }
      result = result.substring(0, result.length - 1);
      result += '\r\n';
    }
    result = result.substring(0, result.length - 1);

    return result;
  }

  function tableData2(tableName) {
    const ws = XLSX.utils.table_to_sheet(document.getElementById(tableid), {
      sheet: tableName,
      raw: true,
    });
    const range = XLSX.utils.decode_range(ws['!ref']);
    const result = [];
    let row;
    let rowNum;
    let colNum;
    const selectedCol = [];

    for (let i = 0; i < tblHeaders.current.length; i += 1) {
      tblvalue.headers.forEach((select) => {
        if (select === tblHeaders.current[i]) {
          selectedCol.push(i);
          return false;
        }
      });
    }

    for (rowNum = range.s.r; rowNum <= range.e.r; rowNum += 1) {
      row = [];
      for (colNum = range.s.c; colNum <= range.e.c; colNum += 1) {
        if (selectedCol.includes(colNum)) {
          const nextCell = ws[XLSX.utils.encode_cell({ r: rowNum, c: colNum })];
          if (typeof nextCell === 'undefined') {
            row.push(void 0);
          } else {
            // row.push(nextCell.w);
            row.push(nextCell.v);
          }
        } else {
          continue;
        }
      }
      result.push(row);
    }

    return result;
  }

  const exportCSV = (tableName, selectedCol) => {
    const resultArray = tableData2(filename, selectedCol);
    const wa = XLSX.utils.aoa_to_sheet(resultArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, wa, tableName);
    XLSX.writeFile(wb, `${timeFormat.nowTime() + tableName}.csv`);
  };

  const exportXlsx = (tableName, selectedCol) => {
    const resultArray = tableData2(filename, selectedCol);
    const wa = XLSX.utils.aoa_to_sheet(resultArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, wa, tableName);
    XLSX.writeFile(wb, `${timeFormat.nowTime() + tableName}.xlsx`);
  };

  const copyClipboard = () => {
    clipboard.writeText(tabledata('clipboard')).then(
      () => openModal(modalList.alert, { text: t('alert.desc.copy_y') }),
      () => openModal(modalList.alert, { text: t('alert.desc.copy_n') }),
    );
  };

  return (
    <Container>
      <CopyButton onClick={copyClipboard}>{t('button.copy')}</CopyButton>
      <DropdownContainer>
        <ExportButton
          onClick={() => {
            setIsActive(!isActive);
            getTableHeaders();
          }}
        >
          <Avatar size={18} src='/Images/ic_download.png' alt='arrowIcon' />
        </ExportButton>

        <AnimatePresence>
          {isActive && (
            <DropdownMenu
              ref={dropdownRef}
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -10 }}
              transition={{
                type: 'tween',
                duration: 0.3,
                ease: 'easeInOut',
              }}
            >
              <ul>
                {tblHeaders.current.map((header, idx) => {
                  return (
                    <Selected
                      // eslint-disable-next-line react/no-array-index-key
                      key={`tableheader${idx}`}
                      isChecked={tblvalue.headers?.includes(header)}
                      onClick={() => {
                        dispatch(TableHeaders(header));
                      }}
                    >
                      <input type='checkbox' checked={tblvalue.headers?.includes(header)} readOnly />
                      &nbsp;{header}
                    </Selected>
                  );
                })}
              </ul>

              <ButtonContainer>
                <Button
                  disabled={tblvalue.headers.length === 0}
                  onClick={() => {
                    tblvalue.headers.length > 0 && exportCSV(filename, tblvalue.headers);
                    tblvalue.headers.length > 0 && setIsActive(false);
                  }}
                >
                  <img className='download-icon' src='/Images/ic_download.png' alt='DownloadIcon' />
                  CSV
                </Button>
                <Button
                  disabled={tblvalue.headers.length === 0}
                  onClick={() => {
                    tblvalue.headers.length > 0 && exportXlsx(filename, tblvalue.headers);
                    tblvalue.headers.length > 0 && setIsActive(false);
                  }}
                >
                  <img className='download-icon' src='/Images/ic_download.png' alt='DownloadIcon' />
                  XLSX
                </Button>
              </ButtonContainer>
            </DropdownMenu>
          )}
        </AnimatePresence>
      </DropdownContainer>
    </Container>
  );
};

export default React.memo(ExportUtil);

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CopyButton = styled.button`
  ${spacing.paddingX(2)}
  ${spacing.paddingY(1)}
  border-radius: 4px;
  ${buttonStyle.color.normal}
  ${typoStyle.button_12}
  height: 100%;
`;

const ExportButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  ${buttonStyle.color.normal}
`;

const Button = styled.button`
  ${typoStyle.button_13}
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  height: 30px;

  cursor: pointer;
  ${buttonStyle.color.main}
  ${borderRadiusStyle[10]}
  ${spacing.paddingX(4)}

  &:hover {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  }
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownMenu = styled(motion.div)`
  background-color: ${colors.bg_select};
  ${spacing.paddingX(3)}
  ${spacing.paddingY(5)}
  ${borderRadiusStyle[20]}
  position: absolute;
  z-index: 9999;
  top: calc(32px + 4px);
  left: 0;
  box-shadow: 3px 9px 6px 0 rgba(0, 0, 0, 0.16);
  width: 210px;

  > ul {
    ${spacing.marginB(3)}
  }
`;

const Selected = styled.li`
  display: flex;
  align-items: center;
  ${typoStyle.contents_md}
  ${borderRadiusStyle[10]}
  ${spacing.paddingY(1)}
  ${spacing.paddingX(2)}
  &:not(:last-of-type) {
    ${spacing.marginB(1)}
  }
  cursor: pointer;
  background-color: ${({ isChecked }) => (isChecked ? colors.bg_gnb : 'transparent')};

  > input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('/Images/btn_check_off.svg') no-repeat;
    transition: background 0.1s linear;

    &:checked {
      background: url('/Images/btn_check_on.svg') no-repeat;
    }
  }

  &:hover {
    background-color: #3a3745;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  > button:first-of-type {
    ${spacing.marginR(2)}
  }
`;
