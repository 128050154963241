import React, { useCallback, useMemo, useState } from 'react';
import { Modal, ModalDispatch, ModalsDispatchCointext, ModalsStateContext } from './ModalsContext';

const ModalProvider: React.FC = ({ children }) => {
  const [openedModals, setOpenModals] = useState<Modal[]>([]);

  const open = useCallback((Component: JSX.Element, props: any) => {
    setOpenModals((modals) => [...modals, { Component, props }]);
  }, []);

  const close = useCallback((Component: JSX.Element) => {
    setOpenModals((modals) => modals.filter((modal) => modal.Component !== Component));
  }, []);

  const dispatch: ModalDispatch = useMemo(() => ({ open, close }), [open, close]);

  return (
    <ModalsStateContext.Provider value={openedModals}>
      <ModalsDispatchCointext.Provider value={dispatch}>{children}</ModalsDispatchCointext.Provider>
    </ModalsStateContext.Provider>
  );
};

export default ModalProvider;
