import React, { useEffect, useState } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import IPConent from '../Component/IPConent';
import SoloRankServices, { BookMarkListInfoResponse } from '../../../services/SoloRankServices';
import { colors } from '../../../Styles/ui';
import Progress from '../../../Components/Ui/Loading/Progress';

const InterestedPlayer = () => {
  const [selectedDay, setSelectedDay] = useState('30');
  const [dataset, setDataset] = useState<BookMarkListInfoResponse>();
  const { t } = useTranslation();
  const { mutate: getBookMarkListInfo, isLoading } = useMutation(
    () =>
      SoloRankServices.getBookMarkListInfo({
        days: selectedDay,
      }),
    {
      onSuccess: (data) => {
        const e = data.response;
        if (Object.keys(e).length !== 0) {
          setDataset(e);
        }
      },
      onError: (data) => {
        console.log(data);
      },
    },
  );

  useEffect(() => {
    getBookMarkListInfo();
  }, [selectedDay]);

  return isLoading || !dataset ? (
    <ProgressContainer>
      <Progress text={t('solo.playerboard.dataFetching')} />
    </ProgressContainer>
  ) : (
    <IPConent
      selectedDay={selectedDay}
      setSelectedDay={setSelectedDay}
      dataset={dataset}
      getInfoFunc={getBookMarkListInfo}
    />
  );
};

export default InterestedPlayer;

const ProgressContainer = styled.div`
  width: ${1120}px;
  height: 160px;
  margin: 20px 0 0;
  padding: 0 30px;
  border-radius: 20px;
  background-color: ${colors.bg_select};
  display: flex;
  align-items: center;
  justify-content: center;
`;
