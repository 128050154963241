import { AxiosError, AxiosResponse } from 'axios';
import { axiosPost } from './axios/axios';
import {
  Pickparams,
  PlayerParams,
  ResponseLeaguePickData,
  ResponseLeaguePlayer,
  ResponseLeagueTotal,
  ResponseSchedule,
  TotalParams,
} from './types/league_services.type';
import { Leagues, PickParam, Years } from './types/param_types';

interface IResponseSuccess {
  response: any;
  status: string;
  message: string;
}
interface IResponseSchedule extends IResponseSuccess {
  response: ResponseSchedule[];
}

interface IResponseLeagueTotal extends IResponseSuccess {
  response: ResponseLeagueTotal;
}

interface IResponseLeaguePlayer extends IResponseSuccess {
  response: ResponseLeaguePlayer[];
}

interface IResponseLeaguePickData extends IResponseSuccess {
  response: ResponseLeaguePickData;
}

class LeagueServices {
  static instance: LeagueServices;

  getRank() {
    return axiosPost({
      url: '/home/home',
    });
  }

  getPickData(data: Pickparams): Promise<IResponseLeaguePickData> {
    return new Promise((resolve, reject) => {
      const gml = new Date().getTimezoneOffset();
      axiosPost({
        url: '/league/pick',
        data: {
          ...data,
          gml,
        },
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  getSchedule(league: Leagues, year: Years): Promise<IResponseSchedule> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/home/schedule',
        data: {
          league,
          year,
        },
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  getMsiPlace() {
    return axiosPost({
      url: '/home/msiPlace',
    });
  }

  getLeagueTotal(data: TotalParams): Promise<IResponseLeagueTotal> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/league/totalinfo',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  getLeaguePlayer(data: PlayerParams): Promise<IResponseLeaguePlayer> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/league/playerinfo',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }
}

export default new LeagueServices();
