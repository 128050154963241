import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import dayjs from 'dayjs';
import Home from './Pages/Home/Home';
import League from './Pages/LeagueReport/League';
import Team from './Pages/TeamReport/Team';
import PlayerReport from './Pages/PlayerReport/PlayerReport';
import Login from './Pages/Sign/Login';
import Video from './Pages/PathingAnalysis/Video';
import SingUp from './Pages/Sign/SignUp';
import ChangePW from './Pages/Sign/ChangePW';
import GameReport from './Pages/ProGameReport/GameReport';
import AlertModal from './Components/UtilityComponent/AlertModal';
import CheckLogin from './Pages/Sign/CheckLogin';
import GameReportDetail from './Pages/ProGameReport/Components/GameReportDetail';
import SoloRank from './Pages/SoloRank/SoloRank';
import NotFound404 from './Pages/NotFound/NotFound404';
import Layout from './Layout';
import {
  goChangePw,
  goCheckLogin,
  goLeagueReport,
  goLogin,
  goPathAnalysis,
  goPlayerReport,
  goProGameReport,
  goSignUp,
  goSoloRank,
  goTeamReport,
  SCRIM,
} from './lib/pagePath';
import { PlayerDashBoard, PlayerSoloRank } from './Pages/PlayerReport/screens';
import LeagueStatistics from './Pages/LeagueReport/LeagueStatistics/LeagueStatistics';
import LeaguePick from './Pages/LeagueReport/LeaguePick/LeaguePick';
import LeaguePlayer from './Pages/LeagueReport/LeaguePlayer/LeaguePlayer';
import BanIndex from './Pages/TeamReport/BanReport/BanIndex';
import BanPickIndex from './Pages/TeamReport/GameBanPick/BanPickIndex';
import WardMapping from './Pages/PathingAnalysis/WardMapping/WardMapping';
import ObjectMapping from './Pages/PathingAnalysis/ObjectMapping/ObjectMapping';
import HitMap from './Pages/PathingAnalysis/HitMap/HitMap';
import JungleMap from './Pages/PathingAnalysis/JungleMap/JungleMap';
import WardObjectPathing from './Pages/PathingAnalysis/WardObjectPathing/WardObjectPathing';
import MyTeam from './Pages/SoloRank/screens/MyTeam';
import SearchFilter from './Pages/SoloRank/screens/SearchFilter';
import InterestedPlayer from './Pages/SoloRank/screens/InterestedPlayer';
import TeamAnalysis from './Pages/TeamReport/TeamAnalysis/TeamAnalysis';
import Sequence from './Pages/PathingAnalysis/JungleMap/screens/Sequence';

import { userAtom, userLanguageAtom } from './recoil/Auth/userAtom';
import ErrrorController from './Components/ErrorController/ErrrorController';
import { useFilterCall } from './Hooks/useFilterCall';
import { useRunesCall } from './Hooks/useRunesCall';
import Scrim from './Pages/Scrim';
import ScrimIndex from './Pages/Scrim/screens/ScrimIndex';
import ScrimBanPick from './Pages/Scrim/screens/ScrimBanPick';
import ScrimTeam from './Pages/Scrim/screens/ScrimTeam';
import { useChampionCall } from './Hooks/useChampionCall';
import { FilterCallAtom } from './recoil/Filter/staticAtom';
import { OriginalChampData } from './recoil/common/championListAtom';
import Test from './Pages/test/Calendar';
import DataGridWithPanel from './Pages/test/DataGridWithPanel';
import ScrimEdit from './Pages/Scrim/screens/ScrimEdit';
import ScrimBanPickReport from './Pages/Scrim/screens/ScrimBanPickReport';
import ScrimBanPickReportIndex from './Pages/Scrim/screens/ScrimBanPIckReportIndex';
import ChannelService from './Components/UtilityComponent/ChannelService';
// import Utility from "./Pages/ETC/Utility/Utility";
// import PickCalculator from "./Pages/ETC/PickCalculator/PickCalculator";

function Routor() {
  const user = useRecoilValue(userAtom);
  const lang = useRecoilValue(userLanguageAtom);

  useEffect(() => {
    if (user) {
      ChannelService.boot({
        pluginKey: '7cc25793-4246-4495-80e9-97a59767ef03', // please fill with your plugin key
        memberId: user.userId, // fill with user id
        profile: {
          name: user.name, // fill with user name
          teamName: user.teamName,
          email: user.userId,
          lastPage: window.location.pathname,
        },
      });
    } else {
      ChannelService.shutdown();
    }
  }, [user]);

  useEffect(() => {
    if (!lang) return;
    ChannelService.updateLanguage(lang);
  }, [lang]);

  return (
    <Router>
      <ErrrorController />
      <AlertModal />

      <Routes>
        {/* private router */}
        <Route path='/' element={<PrivateOutlet />}>
          <Route element={<Layout />}>
            {/* Home */}
            <Route index element={<Home />} />
            {/* League */}
            <Route path={goLeagueReport} element={<League />}>
              <Route index element={<LeaguePick />} />
              <Route path='statistics' element={<LeagueStatistics />} />
              <Route path='player' element={<LeaguePlayer />} />
            </Route>
            {/* Team */}
            <Route path={goTeamReport} element={<Team />}>
              <Route index element={<BanIndex />} />
              <Route path='gameBanPick' element={<BanPickIndex />} />
              <Route path='analysis' element={<TeamAnalysis />} />
            </Route>
            {/* Player Report */}
            <Route path={goPlayerReport} element={<PlayerReport />}>
              <Route index element={<PlayerDashBoard />} />
              <Route path='solorank' element={<PlayerSoloRank />} />
            </Route>
            {/* path Analysis Report */}
            <Route path={goPathAnalysis} element={<Video />}>
              <Route index element={<WardMapping />} />
              <Route path='objectPathing' element={<ObjectMapping />} />
              <Route path='heatmap' element={<HitMap />} />
              <Route path='junglePathing' element={<JungleMap />}>
                <Route index element={<Sequence />} />
              </Route>
              <Route path='wardPathing' element={<WardObjectPathing />} />
            </Route>
            <Route path={goProGameReport} element={<GameReport />} />
            <Route path={`${goProGameReport}/:id`} element={<GameReportDetail />} />
            <Route path={goSoloRank} element={<SoloRank />}>
              <Route index element={<MyTeam />} />
              <Route path='searchFilter' element={<SearchFilter />} />
              <Route path='interestedPlayer' element={<InterestedPlayer />} />
            </Route>

            {/* 스크림 매니지먼트 */}
            <Route path={SCRIM.index} element={<Scrim />}>
              <Route index element={<ScrimIndex />} />
              <Route path={SCRIM.banpick} element={<ScrimBanPick />} />
              <Route path={SCRIM.team} element={<ScrimTeam />} />
              <Route path={SCRIM.banpickReport} element={<ScrimBanPickReportIndex />} />
              <Route path=':id' element={<ScrimEdit />} />
            </Route>
          </Route>
        </Route>

        {/* common router */}
        <Route path={goLogin} element={<Login />} />
        <Route path={goCheckLogin} element={<CheckLogin />} />
        <Route path={goSignUp} element={<SingUp />} />
        <Route path={goChangePw} element={<ChangePW />} />

        {/* test - remove later */}
        {/* <Route path='/test' element={<Test />} /> */}
        {/* <Route path='/test' element={<MuiSelectBox />} /> */}

        {/* error - 404 */}
        <Route path='*' element={<NotFound404 />} />
      </Routes>
    </Router>
  );
}

export default Routor;

function PrivateOutlet() {
  const user = useRecoilValue(userAtom);
  const setFilterData = useSetRecoilState(FilterCallAtom);
  const setOriginalChampData = useSetRecoilState(OriginalChampData);

  const { data: data2 } = useRunesCall();

  // 전역에서 필터콜, 챔피언리스트 미리 로드
  const { data: filterData } = useFilterCall();
  const { data: championData } = useChampionCall();

  setFilterData(filterData);
  setOriginalChampData(championData!);

  return user ? <Outlet /> : <Navigate to='/login' />;
}
