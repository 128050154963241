import { useRecoilValue } from 'recoil';
import { ChampionListAtom, LOLPatchAtom } from '../recoil/common/championListAtom';

const useChampionImage = () => {
  const championList = useRecoilValue(ChampionListAtom);
  const lolPatch = useRecoilValue(LOLPatchAtom);

  const getChampionImagePathByName = (name: string) => {
    return `https://ddragon.leagueoflegends.com/cdn/${lolPatch}/img/champion/${name}.png`;
  };

  const getChampionImagePathByIndex = (index: number) => {
    const name = championList[index].en;
    return getChampionImagePathByName(name);
  };

  return { getChampionImagePathByName, getChampionImagePathByIndex };
};

export default useChampionImage;
