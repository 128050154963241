import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { ChampionListAtom, LOLPatchAtom } from '../../../../recoil/common/championListAtom';
import useParseChampionImagePath from '../../../../Hooks/useParseChampionImagePath';

interface P {
  champion: string;
}

const BanBox: React.FC<P> = ({ champion }) => {
  const championList = useRecoilValue(ChampionListAtom);
  const patch = useRecoilValue(LOLPatchAtom);
  const parseChampionImagePath = useParseChampionImagePath(patch, championList);

  const parseImgPath = useCallback((champion) => parseChampionImagePath(champion), [parseChampionImagePath]);

  return championList && patch ? <img src={parseImgPath(champion)} alt='' /> : <></>;
};

export default BanBox;
