import React from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { getPositon } from '../../../../../lib/getPosition';
import { InfosBanPickPicks } from '../../../../../services/GameServices';
import GameDatasetAtom from '../../../../../recoil/ProGameReport/gameDatasetAtom';
import GameAtom from '../../../../../recoil/ProGameReport/gameAtom';

const BanPick = () => {
  const fixedDataset = useRecoilValue(GameDatasetAtom.fixedDataset);
  const blueteam = useRecoilValue(GameAtom.blueteam);
  const redteam = useRecoilValue(GameAtom.redteam);
  const banObject = fixedDataset[0].BanPick.bans.concat(fixedDataset[1].BanPick.bans);
  const { t } = useTranslation();

  const bluePick = fixedDataset[0].BanPick.picks;
  const redPick = fixedDataset[1].BanPick.picks;
  const pickObject: InfosBanPickPicks[] = [];
  pickObject.push(
    bluePick[0],
    redPick[0],
    redPick[1],
    bluePick[1],
    bluePick[2],
    redPick[2],
    redPick[3],
    bluePick[3],
    bluePick[4],
    redPick[4],
  );

  function getFpickLabel(fpick: number) {
    switch (fpick) {
      case 1:
        return t('game.banPick.firstPick.top');
      case 2:
        return t('game.banPick.firstPick.jng');
      case 3:
        return t('game.banPick.firstPick.mid');
      case 4:
        return t('game.banPick.firstPick.ad');
      case 5:
        return t('game.banPick.firstPick.sup');
      default:
        return '';
    }
  }

  [...Array(5)].forEach((e, i) => {
    for (let j = 0; pickObject.length; j += 1) {
      if (pickObject[j].position === i + 1) {
        pickObject[j] = { ...pickObject[j], fpick: i + 1 };
        break;
      }
    }
  });

  const phase1Champion = pickObject.slice(0, 6);
  const phase2Champion = pickObject.slice(6, 10);
  const phase1Ban = banObject.slice(0, 3).concat(banObject.slice(5, 8));
  const phase2Ban = banObject.slice(3, 5).concat(banObject.slice(8, 10));

  return (
    <BanPickContainer>
      <PhaseBox1>
        <div className='nav'>Phase 1</div>
        <BanBox>
          {phase1Ban.map((data) => {
            return (
              data.team === 100 && (
                <img className='ban-champ' src={`/Images/champion/${data.champion}.png`} alt='ban' />
              )
            );
          })}
        </BanBox>
        <SelectedPick>
          <div className='blue-team'>
            <div className='title'>
              <img className='logo' src={`/Images/TeamLogo/${blueteam}.png`} alt='' />
              <span>{blueteam}</span>
            </div>
            {phase1Champion.map((data) => {
              return (
                <PickChampion>
                  {data.team === 100 && (
                    <>
                      <img src={`/Images/champion/${data.champion}.png`} alt='' />
                      {data.fpick && (
                        <div className='first-pick blue'>
                          <img
                            src={`/Images/position/ico-position-${getPositon(data.fpick)}.svg`}
                            alt='line'
                          />
                          <div>{t('game.banPick.firstPick')}</div>
                          {/* {getFpickLabel(data.fpick)} */}
                        </div>
                      )}
                    </>
                  )}
                </PickChampion>
              );
            })}
          </div>
          <div className='red-team'>
            <div className='title'>
              <img className='logo' src={`/Images/TeamLogo/${redteam}.png`} alt='' />
              <span>{redteam}</span>
            </div>
            {phase1Champion.map((data) => {
              return (
                <PickChampion>
                  {data.team === 200 && (
                    <>
                      <img src={`/Images/champion/${data.champion}.png`} alt='' />
                      {data.fpick && (
                        <div className='first-pick red'>
                          <img
                            src={`/Images/position/ico-position-${getPositon(data.fpick)}.svg`}
                            alt='line'
                          />
                          <div>{t('game.banPick.firstPick')}</div>
                          {/* {getFpickLabel(data.fpick)} */}
                        </div>
                      )}
                    </>
                  )}
                </PickChampion>
              );
            })}
          </div>
        </SelectedPick>
        <BanBox>
          {phase1Ban.map((data) => {
            return (
              data.team === 200 && (
                <img className='ban-champ' src={`/Images/champion/${data.champion}.png`} alt='ban' />
              )
            );
          })}
        </BanBox>
      </PhaseBox1>
      <PhaseBox2>
        <div className='nav'>Phase 2</div>
        <BanBox>
          {phase2Ban.map((data) => {
            return (
              data.team === 100 && (
                <img className='ban-champ' src={`/Images/champion/${data.champion}.png`} alt='ban' />
              )
            );
          })}
        </BanBox>
        <SelectedPick>
          <div className='blue-team'>
            <PickChampion />
            {phase2Champion.map((data) => {
              return (
                <PickChampion>
                  {data.team === 100 && (
                    <>
                      <img src={`/Images/champion/${data.champion}.png`} alt='' />
                      {data.fpick && (
                        <div className='first-pick blue'>
                          <img
                            src={`/Images/position/ico-position-${getPositon(data.fpick)}.svg`}
                            alt='line'
                          />
                          <div>{t('game.banPick.firstPick')}</div>
                          {/* {getFpickLabel(data.fpick)} */}
                        </div>
                      )}
                    </>
                  )}
                </PickChampion>
              );
            })}
          </div>
          <div className='red-team'>
            <PickChampion />
            {phase2Champion.map((data) => {
              return (
                <PickChampion>
                  {data.team === 200 && (
                    <>
                      <img src={`/Images/champion/${data.champion}.png`} alt='' />
                      {data.fpick && (
                        <div className='first-pick red'>
                          <img
                            src={`/Images/position/ico-position-${getPositon(data.fpick)}.svg`}
                            alt='line'
                          />
                          <div>{t('game.banPick.firstPick')}</div>
                          {/* {getFpickLabel(data.fpick)} */}
                        </div>
                      )}
                    </>
                  )}
                </PickChampion>
              );
            })}
          </div>
        </SelectedPick>
        <BanBox>
          {phase2Ban.map((data) => {
            return (
              data.team === 200 && (
                <img className='ban-champ' src={`/Images/champion/${data.champion}.png`} alt='ban' />
              )
            );
          })}
        </BanBox>
      </PhaseBox2>
    </BanPickContainer>
  );
};

export default BanPick;

const BanPickContainer = styled.div`
  width: 676px;
  height: 213px;
  margin: 0px 18px 0 0;
  display: flex;

  .nav {
    font-family: SpoqaHanSansNeo;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.6;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
`;

const PhaseBox1 = styled.div`
  width: 430px;
  height: 15px;
`;

const PhaseBox2 = styled.div`
  width: 246px;
  height: 15px;
`;

const BanBox = styled.div`
  width: 100%;
  height: 40px;
  display: flex;

  .ban-champ {
    width: 40px;
    height: 40px;
    margin: 0 5px 0 0;
    object-fit: contain;
    mix-blend-mode: luminosity;
    border-radius: 50px;
  }
`;

const SelectedPick = styled.div`
  width: 100%;
  height: 90px;
  margin: 10px 0px;

  .blue-team {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
  }

  .red-team {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
  }

  .title {
    width: 100px;
    height: 30px;
    line-height: 30px;
    display: flex;

    .logo {
      width: 30px;
      height: 30px;
      margin: 0 3px 0 0;
      object-fit: contain;
    }

    span {
      font-family: SpoqaHanSansNeo;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.3;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      white-space: nowrap;
    }
  }
`;

const PickChampion = styled.div`
  width: 58px;
  height: 45px;
  margin: 0px 2px 0;
  position: relative;
  display: flex;
  justify-content: center;

  img {
    width: 45px;
    height: 45px;
    border-radius: 50px;
  }
  .first-pick {
    display: flex;
    position: absolute;
    width: auto;
    padding: 0px 5px;
    height: 18px;
    text-align: center;
    border-radius: 10px;
    align-items: center;
    justify-content: center;

    div {
      font-family: SpoqaHanSansNeo;
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #fff;
      margin-top: 1px;
      white-space: nowrap;
    }

    img {
      width: 12px;
      height: 18px;
      object-fit: contain;
    }
  }

  .red {
    background-color: #f04545;
    top: 38px;
  }

  .blue {
    background-color: #0075bf;
    top: -9px;
  }
`;
