export const TRANSLATE_ERROR_MESSAGE = {
  anotherApproach: 'errorControllerMsg.anotherApproach',
  authExpired: 'errorControllerMsg.authExpired',
  duplicatedOrExpiredSession: 'errorControllerMsg.duplicatedOrExpiredSession',
  sessionExpired: 'errorControllerMsg.sessionExpired',
  serverError: 'errorControllerMsg.serverError',
  unKnownError: 'errorControllerMsg.unKnownError',
  noData: 'errorControllerMsg.noData',
  sorry: 'errorControllerMsg.sorry',
};
