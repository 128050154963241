import React, { useEffect, useRef, useState } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import { LoadingButton } from '@mui/lab';

import AuthService from '../../../services/AuthServices';
import SetInputBox from './SetInputBox';
import CheckBox from '../../../Components/UtilityComponent/CheckBox';
import contentTOU from '../../../lib/content/contentTOU';
import contentPICACU from '../../../lib/content/contentPICAUA';
import checkEmail from '../lib/checkEmail';
import {
  SetConfirmFuncId,
  SetDesc,
  SetIsOpen,
  SetIsSelector,
  SetSemiDesc,
} from '../../../redux/modules/modalvalue';
import signAxiosReq from '../../../lib/axios/signAxiosReq';
import { API } from '../../config';
import { Loading } from '../../../redux/modules/filtervalue';
import { goLogin } from '../../../lib/pagePath';

import { colors, typoStyle } from '../../../Styles/ui';

const validationRegex = {
  password: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/g,
};

const checkValidation = (regex) => (input) => !!input.match(regex);

const EnrollAccount = ({ id, authCode, signType }) => {
  // 텍스트
  const [emailText, setEmailText] = useState(''); // 이메일 텍스트
  const [emailAuthText, setEmailAuthText] = useState(''); // 이메일 보안 텍스트
  const [pwText, setPwText] = useState(''); // 패스워드 텍스트
  const [pwValidateText, setPwValidateText] = useState(''); // 패스워드 확인 텍스트

  // 인증 완료
  const [doneCheckEmailAuth, setDoneCheckEmailAuth] = useState(false); // 이메일 보안 완료 여부

  // 경고
  const [authAlertOpen, setAuthAlertOpen] = useState(false); // 이메일 인증 경고 여부
  const [emailAlertOpen, setEmailAlertOpen] = useState(false); // 이메일 경고 여부

  // 체크박스
  const [checkTOU, setCheckTOU] = useState(false);
  const [checkPICACU, setCheckPICACU] = useState(false);

  // 타이머
  const [emailAuthSendTime, setEmailAuthSendTime] = useState();
  const [emailAuthTimeOut, setEmailAuthTimeOut] = useState(false);

  const { t } = useTranslation(['translation', 'login']);
  const dispatch = useDispatch();
  const lang = sessionStorage.getItem('i18nextLng');

  const navigate = useNavigate();

  const onChange = (e) => {
    const { value, id } = e.target;

    switch (id) {
      case 'pw-input':
        setPwText(value);
        break;
      case 'pw-validate-input':
        setPwValidateText(value);
        break;
      case 'email-input':
        setEmailText(value);
        setEmailAlertOpen(!checkEmail(value));
        break;
      case 'email-auth-input':
        setEmailAuthText(value);
        break;
      default:
        break;
    }
  };

  const btnChange = () => {
    if (checkPICACU && checkTOU) {
      setCheckPICACU(false);
      setCheckTOU(false);
    } else {
      setCheckPICACU(true);
      setCheckTOU(true);
    }
  };

  const { mutateAsync, isError, isLoading, data } = useMutation(
    ({ email, authCode }) => AuthService.checkEmail(email, authCode),
    {
      onError: () => {
        dispatch(SetIsSelector(false));
        dispatch(SetIsOpen(true));
        dispatch(SetDesc(t('sign.checkLogin.fail')));
        setEmailAlertOpen(false);
        dispatch(Loading(false));
      },
    },
  );

  const checkMail = async () => {
    const result = await mutateAsync({ email: emailText, authCode });
    if (result) {
      dispatch(SetIsSelector(false));
      dispatch(SetIsOpen(true));
      dispatch(SetDesc(t('sign.signUpContent.sendAuthDesc')));
      setEmailAlertOpen(false);
      const time = new Date().getTime() / 1000 + 180;
      setEmailAuthSendTime(time);
      setEmailAuthTimeOut(false);
    }
  };

  const checkEmailAuth = () => {
    if (emailAuthTimeOut) {
      dispatch(SetIsSelector(false));
      dispatch(SetIsOpen(true));
      dispatch(SetDesc(t('sign.signUpContent.emailAuthTimeOut')));
      return;
    }
    dispatch(Loading(true));
    const url = `${API}/lolapi/authcord`;
    const param = `authcord=${emailAuthText}&key=${authCode}&email=${emailText}&type=${signType}`;
    // console.log("param", param);
    signAxiosReq(
      url,
      param,
      function (success) {
        if (success) {
          dispatch(SetIsSelector(false));
          dispatch(SetIsOpen(true));
          dispatch(SetDesc(t('sign.checkLogin.success')));
          // dispatch(SetConfirmFuncId("checkLogin"));
          setDoneCheckEmailAuth(true);
          setAuthAlertOpen(false);
        }
        dispatch(Loading(false));
      },
      function (data) {
        dispatch(SetIsSelector(false));
        dispatch(SetIsOpen(true));
        dispatch(SetDesc(t('sign.checkLogin.fail')));
        // dispatch(SetConfirmFuncId("checkLogin"));
        setDoneCheckEmailAuth(false);
        setAuthAlertOpen(false);
        dispatch(Loading(false));
      },
    );
  };

  const onConfirm = () => {
    let desc = '';

    // 이메일 / 패스워드 일치 확인
    if (!doneCheckEmailAuth) {
      desc = t('sign.signUpContent.needEmailAuth');
    } else if (isPasswordError || isPasswordCheckError) {
      desc = t('sign.signUpContent.alertPWCheck');
    } else if (id === 'signUpContent') {
      if (!checkTOU) {
        desc = t('sign.signUpContent.needTOU');
      } else if (!checkPICACU) {
        desc = t('sign.signUpContent.needPICACU');
      }
    }
    // 신규 가입 시, 이용 약관 확인

    if (desc.length > 0) {
      dispatch(SetIsSelector(false));
      dispatch(SetIsOpen(true));
      dispatch(SetDesc(desc));
      setDoneCheckEmailAuth(true);
      setAuthAlertOpen(false);
    } else {
      dispatch(Loading(true));
      const url = `${API}/lolapi/userinfo`;
      const param = `email=${emailText}&password=${pwText}&key=${authCode}&type=${signType}`;
      signAxiosReq(url, param, function (success) {
        if (success) {
          dispatch(SetIsSelector(false));
          dispatch(SetIsOpen(true));
          dispatch(SetDesc(t(`sign.${id}.confirmSuccess`)));
          dispatch(SetSemiDesc(''));
          navigate(goLogin);
        }
        dispatch(Loading(false));
      });
    }
  };

  const onCancel = () => {
    dispatch(SetIsSelector(true));
    dispatch(SetIsOpen(true));
    dispatch(SetDesc(t('alert.desc.questionCancel')));
    dispatch(SetSemiDesc(''));
    dispatch(SetConfirmFuncId('/login'));
  };

  const isPasswordError = !checkValidation(validationRegex.password)(pwText);
  const isPasswordCheckError = pwText !== pwValidateText;
  const isConfirm = !isPasswordError && !isPasswordCheckError && authAlertOpen && emailAlertOpen;

  return (
    <MainBox>
      <header className='logo'>
        <img className='logo' src='/Images/logo.png' alt='' />
      </header>
      <section className='hello'>{t(`sign.${id}.hello`)}</section>
      <section className='email'>
        <header>{t('sign.signUpContent.email')}</header>
        <Stack direction='row' spacing={2}>
          <STYLED_INPUT
            type='email'
            id='email-input'
            autoComplete='off'
            placeholder={t('sign.signUpContent.emailPlaceholder')}
            value={emailText}
            onChange={onChange}
          />
        </Stack>
        <AlertBox isOpen={emailAlertOpen}>{t('sign.signUpContent.emailAlert')}</AlertBox>
      </section>
      <section className='auth-num'>
        <header>{t('sign.signUpContent.emailAuthNum')}</header>
        <div className='flex-box'>
          <SetInputBox
            width='55'
            type='text'
            id='email-auth-input'
            placeholder={t('sign.signUpContent.authPlaceholder')}
            onChange={onChange}
            timer={emailAuthSendTime}
            timeOutFunc={setEmailAuthTimeOut}
          />
          <Stack direction='row' spacing={1}>
            <LoadingButton
              sx={MUIButtonStyle}
              color='secondary'
              variant='contained'
              onClick={checkMail}
              loading={isLoading}
              disabled={!emailText}
            >
              {t('sign.signUpContent.sendAuthMail')}
            </LoadingButton>
            <LoadingButton variant='contained' sx={MUIButtonStyle} onClick={checkEmailAuth}>
              {t('sign.signUpContent.sendAuthConfirm')}
            </LoadingButton>
          </Stack>
        </div>
        <Typography variant='caption' sx={{ display: 'block', mt: 2, textAlign: 'center' }}>
          {t('스팸메일함', { ns: 'login' })}
        </Typography>
        <AlertBox isOpen={authAlertOpen}>{t('sign.signUpContent.authAlert')}</AlertBox>
      </section>
      <section className='pw'>
        <header>{t('sign.signUpContent.pw')}</header>
        <div className='flex-box'>
          <TextField
            error={isPasswordError}
            hiddenLabel
            fullWidth
            type='password'
            id='pw-input'
            value={pwText}
            onChange={onChange}
            variant='filled'
            autoComplete='new-password'
            helperText={isPasswordError ? t('sign.signUpContent.pwPlaceholder') : null}
            sx={MuiInputStyle}
          />
        </div>
      </section>
      <section className='validate-pw'>
        <header>{t('sign.signUpContent.validate-pw')}</header>
        <div className='flex-box'>
          <TextField
            error={isPasswordCheckError}
            hiddenLabel
            fullWidth
            type='password'
            id='pw-validate-input'
            placeholder={t('sign.signUpContent.pwPlaceholder')}
            value={pwValidateText}
            onChange={onChange}
            variant='filled'
            helperText={isPasswordCheckError ? t('sign.signUpContent.alertPWCheck') : null}
            sx={MuiInputStyle}
            autoComplete='new-password'
          />
        </div>
      </section>

      <ContentBox isShow={id === 'signUpContent'}>
        <section className='all-agree'>
          <CheckBox
            key='all-agree'
            checked={checkTOU && checkPICACU}
            text={t('sign.signUpContent.allAgree')}
            clickEvent={() => {
              btnChange();
            }}
          />
        </section>

        <div className='line' />

        <section className='agree-TOU'>
          <CheckBox
            key='agree-TOU'
            checked={checkTOU}
            text={t('sign.signUpContent.agreeOfTermsOfUse')}
            clickEvent={() => {
              setCheckTOU(!checkTOU);
            }}
          />
          <ArticleBox readOnly>{`${contentTOU(lang)}`}</ArticleBox>
        </section>

        <section className='agree-PICAUA'>
          <CheckBox
            key='agree-PICAUA'
            checked={checkPICACU}
            text={t('sign.signUpContent.personalInfoCalUsageAgree')}
            clickEvent={() => {
              setCheckPICACU(!checkPICACU);
            }}
          />
          <ArticleBox readOnly>{`${contentPICACU(lang)}`}</ArticleBox>
        </section>
      </ContentBox>
      <ButtonBox>
        <button type='button' className='cancel' onClick={onCancel}>
          {t('alert.label.cancel')}
        </button>
        <button type='button' className='confirm' onClick={onConfirm}>
          {t('alert.label.confirm')}
        </button>
      </ButtonBox>
    </MainBox>
  );
};

export default EnrollAccount;

const MainBox = styled.div`
  min-width: 800px;
  width: 100%;
  height: 100%;
  font-family: SpoqaHanSansNeo;
  text-align: left;
  color: #fff;
  padding: 80px 164px 110px 180px;

  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .hello {
    font-family: SpoqaHanSansNeo;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .logo {
    margin: 0 0 23px 0;
    object-fit: contain;
  }

  .email {
    margin-top: 36px;
  }

  .flex-box {
    margin-top: 7px;
    display: flex;

    > input {
      flex: 1;
    }
  }

  .pw,
  .all-agree,
  .auth-num,
  .validate-pw {
    margin-top: 20px;
  }

  .line {
    margin: 10px 0 1px 0;
    width: 100%;
    height: 1px;
    margin: 10px 0 0;
    background-color: #433f4e;
  }
`;

const ArticleBox = styled.textarea`
  width: 100%;
  height: 180px;
  margin: 10px 0 0;
  padding: 20px;
  border-radius: 30px;
  background-color: #2f2d38;

  font-family: SpoqaHanSansNeo;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: #84818e;
  border: solid 0px #fff;

  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const AlertBox = styled.div`
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  margin: 5px 0px 0 20px;
  font-family: SpoqaHanSansNeo;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.27;
  letter-spacing: normal;
  text-align: left;
  outline: none;
  color: #f04545;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  margin-top: 50px;

  button {
    width: 49%;
    height: 42px;
    margin: 0 0px 0 0;
    padding: 0px 0px;
    border-radius: 16px;
    font-family: SpoqaHanSansNeo;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .confirm {
    background-color: #5942ba;
    margin-left: 5px;
  }
  .cancel {
    background-color: #484655;
    margin-right: 5px;
  }
`;

const ContentBox = styled.div`
  display: ${(props) => (props.isShow ? 'block' : 'none')};
`;

/** 2022 06 30 */
const STYLED_INPUT = styled.input`
  flex: 1;
  padding: 10px 15px 10px 20px;
  font-size: 15px;
  background-color: ${colors.bg_checkbox};
  border-radius: 9999px;

  ${typoStyle.select};
`;

const MUIButtonStyle = {
  width: 120,
  borderRadius: '9999px',
  fontSize: 13,
  color: 'white',
  '&.MuiButton-containedPrimary': {},
  '&.Mui-disabled': {
    opacity: 0.3,
    background: colors.bg_checkbox,
    color: 'white',
  },
  '&.MuiLoadingButton-loading': {
    color: 'transparent',
  },
  '.MuiLoadingButton-loadingIndicator': {
    color: 'white',
  },
};

const MuiInputStyle = {
  '.MuiFilledInput-root': {
    borderRadius: 20,
    backgroundColor: '#3a3745',
    border: '1px solid transparent',
    height: 36,

    '::focus': {
      backgroundColor: '#3a3745',
    },

    '&::after,&::before': {
      display: 'none',
    },

    '&.Mui-error': {
      border: '1px solid red',
    },
  },

  input: {
    width: '100%',
    height: '100%',
    color: 'white',
    fontSize: 15,
    padding: 0,
    pl: 4,

    '::placeholder': {
      fontSize: 12,
    },
  },

  '.MuiFormHelperText-root': {
    fontSize: 11,
  },
};
