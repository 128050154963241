import React, { useState, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useLocation } from 'react-router-dom';
import { Avatar, Box } from '@mui/material';
import { MenuNum, InitailizeState } from '../../redux/modules/filtervalue';
import { SelectorInitailizeState } from '../../redux/modules/selectorvalue';
import { InitializeGameState } from '../../redux/modules/gamevalue';
import { JungleInit } from '../../redux/modules/junglevalue';
import { Init } from '../../redux/modules/playerReportValue';

import { SoloInit } from '../../redux/modules/solofiltervalue';
import { colors, spacing } from '../../Styles/ui';
import { IMenu } from './SideBar.type';

interface P {
  menu: IMenu;
  idx: number;
}

const SideBarItem: React.FC<P> = ({ menu, idx }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = location.pathname.split('/')[1] === menu.path.split('/')[1];

  return (
    <MenuWrapper
      onClick={() => {
        // changeTitle(`${menu.name} - NUNU.GG`);
        dispatch(JungleInit());
        dispatch(InitializeGameState());
        if (location.pathname !== '/playerReport') {
          dispatch(SelectorInitailizeState());
          dispatch(SoloInit());
          dispatch(Init());
        }
        // dispatch(InitailizeState());
        dispatch(Init());
        if (!menu.modal) {
          batch(() => {
            dispatch(InitailizeState());
            dispatch(MenuNum(menu.idx));
          });
          navigate(menu.path);
        }
      }}
      isActive={isActive}
    >
      <MenuImg src={menu.image} alt='menu' />
      <div className='Name'>{menu.name}</div>
      {menu.opt && (
        <Box
          sx={{
            fontSize: 13,
            ml: 2,
          }}
        >
          [ {menu.opt} ]
        </Box>
      )}
      {menu.version && menu.version === 'beta' && (
        <Avatar
          sx={{
            marginLeft: 1,
            width: 16,
            height: 16,
          }}
          src='/Images/icon/ico_beta.svg'
          alt='beta'
        />
      )}
    </MenuWrapper>
  );
};

export default SideBarItem;

const MenuWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  ${spacing.paddingY(2)};
  ${spacing.paddingL(3)};
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? colors.배경색[50] : colors.배경색[100])};

  &:hover {
    background-color: rgba(89, 66, 186, 0.3);
    color: ${colors.배경색[50]};
  }
  background-color: ${({ isActive }) => (isActive ? `rgba(89, 66, 186, 0.3)` : ' ')}};
`;

const MenuImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  object-fit: contain;
  vertical-align: middle;
`;
