import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { t } from 'i18next';
import { useQuery } from 'react-query';
import CustomDataGrid from '../../../Scrim/components/CustomDataGrid';
import LeagueServices from '../../../../services/LeagueServices';
import { TRANSLATE_HOME_TITLE } from '../../../../lib/translation/Home/translate_home';
import { GridColDef } from '@mui/x-data-grid-pro';

const LEAGUES: string[] = [
  'LCK',
  'LCS',
  'LEC',
  'LPL',
  // 'LCK CL', => 정규리그가 아님
  'LCL',
  'LCO',
  'LJL',
  'LLA',
  'CBLOL',
  // 'MSI', => msi, wc는 일반 리그형식과 다름. 타 api에서 받아올 예정
  'PCS',
  'TCL',
];
interface LeagueInfo {
  WinSeries: string;
  WinRate: number;
  Team: string;
  TeamShorts: string;
  Place: string;
  LossSeries: string;
  PointsTiebreaker: number;
  PO: string;
}
interface TotalLeagueInfo {
  [key: string]: LeagueInfo[];
}

// league에 따른 rows data 필터링
const makeRowData = (data: TotalLeagueInfo, i: number) => {
  if (!data) {
    console.warn('makeRowData: data가 없습니다');
    return undefined;
  }

  return data[LEAGUES[i]]?.map((row: LeagueInfo, index: number) => ({
    id: index,
    place: Number(row.Place),
    teamName: row.Team, // 오리지널 팀명
    teamShorts: row.TeamShorts, // 팀쇼츠
    win: Number(row.WinSeries),
    lose: Number(row.LossSeries),
    winRate: `${row.WinRate.toFixed()}%`,
    pointsTiebreaker: row.PointsTiebreaker,
  }));
};

const CustomOverlay = (msg: string) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src='/Images/icon/ic_error_page.png' alt='errorImg' />
      <span style={{ marginTop: '10px' }}>{msg}</span>
    </div>
  );
};

const LeagueRankRenewal = () => {
  const [currentLeague, setCurrentLeague] = useState(0);
  // data fetching
  const { data: LeagueRankData } = useQuery(['home', 'rank'], () => LeagueServices.getRank(), {
    select: (response) => response.data.response,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
  });

  const handleTab = (index: number) => setCurrentLeague(index);

  // column data
  const columns: GridColDef[] = [
    {
      field: 'place',
      headerName: t(TRANSLATE_HOME_TITLE.순위),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
    {
      field: 'teamName',
      headerName: t(TRANSLATE_HOME_TITLE.팀명),
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex' }}>
            <img
              src={`/Images/TeamLogo/${params.row.teamShorts.toUpperCase()}.png`}
              alt='teamLogo'
              width='20px'
              style={{ marginRight: 4 }}
            />
            <span>{params.value}</span>
          </div>
        );
      },
      flex: 100,
      sortable: false,
    },
    {
      field: 'win',
      headerName: t(TRANSLATE_HOME_TITLE.승),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
    {
      field: 'lose',
      headerName: t(TRANSLATE_HOME_TITLE.패),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
    {
      field: 'winRate',
      headerName: t(TRANSLATE_HOME_TITLE.승률),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
    {
      field: 'pointsTiebreaker',
      headerName: t(TRANSLATE_HOME_TITLE.득실),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
  ];

  return (
    <LeagueRankWrapper>
      <div style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: 20 }}>
        {t(TRANSLATE_HOME_TITLE.리그순위표)}
      </div>
      {/* 리그 탭 */}
      <Stack spacing={1} direction='row'>
        {LEAGUES.map((league, idx) => {
          return (
            <Button
              key={`${league}_rank`}
              variant='contained'
              color={currentLeague === idx ? 'primary' : 'secondary'}
              onClick={() => {
                handleTab(idx);
              }}
            >
              <img
                src={`/Images/league/ico_league_${league.toLowerCase()}.png`}
                alt='leagueLogo'
                width='20px'
              />
              <span style={{ marginLeft: 2, whiteSpace: 'nowrap' }}>{league}</span>
            </Button>
          );
        })}
      </Stack>
      {/* 테이블 */}
      <div style={{ display: 'flex', height: 473, marginTop: 24 }}>
        <CustomDataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'place', sort: 'asc' }],
            },
          }}
          columns={columns}
          rows={makeRowData(LeagueRankData, currentLeague) ?? []}
          loading={!LeagueRankData}
          disableColumnMenu
          components={{
            // 데이터가 빈배열인 경우
            NoRowsOverlay: () => CustomOverlay(t(TRANSLATE_HOME_TITLE.데이터없음)),
            // 데이터 fetching에 에러 발생 시
            ErrorOverlay: () => CustomOverlay(t(TRANSLATE_HOME_TITLE.데이터에러발생)),
            // loading property값이 true일 경우
            LoadingOverlay: () => CustomOverlay(t(TRANSLATE_HOME_TITLE.로딩중)),
          }}
          sx={{
            '& .MuiDataGrid-cell': {
              borderColor: 'transparent',
              '&:last-of-type': {
                color: '#fff',
              },
            },
          }}
          headerHeight={28}
          rowHeight={40}
        />
      </div>
    </LeagueRankWrapper>
  );
};

export default LeagueRankRenewal;

const LeagueRankWrapper = styled.section`
  width: 1136px;
  height: auto;
  color: white;
  border-bottom: 1px solid #3a3745;
`;
