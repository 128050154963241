export const SELECTOR_INITIAL_STATE = 'filterSelector/SELECTOR_INITIAL_STATE';
export const SET_LEAGUE_FILTER = 'filterSelector/SET_LEAGUE_FILTER';
export const SET_YEAR_FILTER = 'filterSelector/SET_YEAR_FILTER';
export const SET_SEASON_FILTER = 'filterSelector/SET_SEASON_FILTER';
export const SET_TEAM_FILTER = 'filterSelector/SET_TEAM_FILTER';
export const SET_PLAYER_FILTER = 'filterSelector/SET_PLAYER_FILTER';
export const SET_PATCH_FILTER = 'filterSelector/SET_PATCH_FILTER';

// 정글링비교 상대팀 가능한 필터
export const SELECTOR_INITIAL_OPP_STATE = 'filterSelector/SELECTOR_INITIAL_OPP_STATE';
export const SET_OPPLEAGUE_FILTER = 'filterSelector/SET_OPPLEAGUE_FILTER';
export const SET_OPPYEAR_FILTER = 'filterSelector/SET_OPPYEAR_FILTER';
export const SET_OPP_SEASON_FILTER = 'filterSelector/SET_OPP_SEASON_FILTER';
export const SET_OPPTEAM_FILTER = 'filterSelector/SET_OPPTEAM_FILTER';
export const SET_OPPPLAYER_FILTER = 'filterSelector/SET_OPPPLAYER_FILTER';

export const INITIALIZE_SEASON_SELECTOR = 'filterSelector/INITIALIZE_SEASON_SELECTOR';
export const INITIALIZE_OPPSEASON_SELECTOR = 'filterSelector/INITIALIZE_OPPSEASON_SELECTOR';

export const setLeagueFilter = (payload) => {
  return {
    type: SET_LEAGUE_FILTER,
    payload,
  };
};

export const setYearFilter = (payload) => {
  return {
    type: SET_YEAR_FILTER,
    payload,
  };
};

export const setSeasonFilter = (payload) => {
  return {
    type: SET_SEASON_FILTER,
    payload,
  };
};

export const setTeamFilter = (payload) => {
  return {
    type: SET_TEAM_FILTER,
    payload,
  };
};

export const setPlayerFilter = (payload) => {
  return {
    type: SET_PLAYER_FILTER,
    payload,
  };
};

export const setPatchFilter = (payload) => {
  return {
    type: SET_PATCH_FILTER,
    payload,
  };
};

export const setOppLeagueFilter = (payload) => {
  return {
    type: SET_OPPLEAGUE_FILTER,
    payload,
  };
};

export const setOppYearFilter = (payload) => {
  return {
    type: SET_OPPYEAR_FILTER,
    payload,
  };
};

export const setOppTeamFilter = (payload) => {
  return {
    type: SET_OPPTEAM_FILTER,
    payload,
  };
};

export const setOppSeasonFilter = (payload) => {
  return {
    type: SET_OPP_SEASON_FILTER,
    payload,
  };
};

export const setOppPlayerFilter = (payload) => {
  return {
    type: SET_OPPPLAYER_FILTER,
    payload,
  };
};

export const SelectorInitailizeState = () => {
  return {
    type: SELECTOR_INITIAL_STATE,
  };
};

export const SelectorInitializeOppState = () => {
  return {
    type: SELECTOR_INITIAL_OPP_STATE,
  };
};

export const InitializeSeason = () => {
  return {
    type: INITIALIZE_SEASON_SELECTOR,
  };
};

export const InitializeOppSeason = () => {
  return {
    type: INITIALIZE_OPPSEASON_SELECTOR,
  };
};

const initialState = {
  leagueFilter: [],
  yearFilter: [],
  seasonFilter: [],
  teamFilter: [],
  playerFilter: [],
  patchFilter: [],
  oppyearFilter: [],
  oppleagueFilter: [],
  oppteamFilter: [],
  oppplayerFilter: [],
  oppseasonFilter: [],
};

export default function SelectorReducer(state = initialState, action) {
  switch (action.type) {
    case SELECTOR_INITIAL_STATE:
      return initialState;
    case SET_LEAGUE_FILTER:
      return {
        ...state,
        leagueFilter: action.payload,
      };
    case SET_YEAR_FILTER:
      return {
        ...state,
        yearFilter: action.payload,
      };
    case SET_SEASON_FILTER:
      return {
        ...state,
        seasonFilter: action.payload,
      };
    case SET_TEAM_FILTER:
      return {
        ...state,
        teamFilter: action.payload,
      };
    case SET_PLAYER_FILTER:
      return {
        ...state,
        playerFilter: action.payload,
      };
    case SET_PATCH_FILTER:
      return {
        ...state,
        patchFilter: action.payload,
      };
    case SET_OPPLEAGUE_FILTER:
      return {
        ...state,
        oppleagueFilter: action.payload,
      };
    case SET_OPPYEAR_FILTER:
      return {
        ...state,
        oppyearFilter: action.payload,
      };
    case SET_OPP_SEASON_FILTER:
      return {
        ...state,
        oppseasonFilter: action.payload,
      };
    case SET_OPPTEAM_FILTER:
      return {
        ...state,
        oppteamFilter: action.payload,
      };
    case SET_OPPPLAYER_FILTER:
      return {
        ...state,
        oppplayerFilter: action.payload,
      };

    case SELECTOR_INITIAL_OPP_STATE:
      return {
        ...state,
        oppyearFilter: [],
        oppleagueFilter: [],
        oppseasonFilter: [],
        oppteamFilter: [],
        oppplayerFilter: [],
      };

    case INITIALIZE_SEASON_SELECTOR:
      return {
        ...state,
        seasonFilter: [],
      };

    case INITIALIZE_OPPSEASON_SELECTOR:
      return {
        ...state,
        oppseasonFilter: [],
      };

    default:
      return state;
  }
}
