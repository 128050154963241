/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
// components
import DashBoardPlayerInfo from '../Components/DashBoardPlayerInfo';
import DashBoardFilter from '../Components/DashBoardFilter';
import DashBoardChart from '../Components/DashBoardChart';
import { colors, spacing } from '../../../Styles/ui';
import PlayerReportAtom, { PlayerReportResultAtom } from '../../../recoil/Filter/playerReportAtom';
import PlayerService from '../../../services/PlayerServices';
import { getTrueValueList } from '../../../lib/getTureValueList';
import Progress from '../../../Components/Ui/Loading/Progress';
import NotFound from '../../../Components/Ui/Error/NotFound';
import { ITemaAnalysis } from '../../../services/types/player_services.type';

const PlayerDashBoard = () => {
  const { t } = useTranslation();
  const result = useRecoilValue(PlayerReportResultAtom);
  const champion = useRecoilValue(PlayerReportAtom.champion);
  const oppchampion = useRecoilValue(PlayerReportAtom.oppChampion);
  const totalGame = useRecoilValue(PlayerReportAtom.totalGame);
  const oppTotalGame = useRecoilValue(PlayerReportAtom.oppTotalGame);
  console.log(totalGame, oppTotalGame);

  const queryClient = useQueryClient();
  console.log('queryClient', queryClient);

  // 로딩
  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);

  // 현재 챔피언 상태
  const [currentChampion, setCurrentChampion] = useState('');
  const [currentOppChampion, setCurrentOppChampion] = useState('');
  // 로컬 상태
  const [total, setTotal] = useState('');
  const [oppTotal, setOppTotal] = useState('');
  // const { data, refetch } = useQuery<ITemaAnalysis>(['playerDashboard']);
  const [data, setData] = useState<ITemaAnalysis>();

  // 데이터패치로직
  const {
    mutate: getPlayerDashboard,
    isLoading: isMutationLoading,
    isError,
  } = useMutation(
    ({ champion, oppchampion }: { champion: string; oppchampion: string }) =>
      PlayerService.getPlayerDashboard({
        ...result,
        team: result.team[0],
        player: result.player[0].split('_')[1],
        champion,
        oppchampion,
      }),
    {
      onSuccess: (data) => {
        // console.log('data', data.response.info);
        // queryClient.setQueryData(['playerDashboard'], data.response);
        setData(data.response);
        // refetch();
        setIsLoading(false);
        setIsTableLoading(false);
      },
    },
  );

  useEffect(() => {
    // 22.04.21
    // 향후 변경될 로직 =>
    // 현재는 챔피언: 상대챔피언 매치가 1: 1 이라서 string으로 주고 받고 있음,
    // 1:n 또는 n:n 으로 주고 받을 경우 array로 schema를 변경
    setIsLoading(true);
    getPlayerDashboard({
      champion: '',
      oppchampion: '',
    });
  }, [result]);

  return (
    <>
      {isLoading || isMutationLoading ? (
        <ProgressContainer>
          <Progress text={t('solo.playerboard.dataFetching')} />
        </ProgressContainer>
      ) : isError ? (
        <ProgressContainer>
          <NotFound css={{ height: '100%' }} text={t('solo.playerboard.dataFetchingFailed')} />
        </ProgressContainer>
      ) : (
        <Wrapper>
          {/* 선수 정보 부분 */}
          <div css={{ marginBottom: 20 }}>
            <DashBoardPlayerInfo info={data?.info} />
          </div>
          {/* 상세지표 필터 */}
          <div css={{ marginBottom: 20 }}>
            {!isLoading && isMutationLoading ? (
              <ProgressContainer>
                <Progress text={t('solo.playerboard.dataFetching')} />
              </ProgressContainer>
            ) : isError ? (
              <ProgressContainer>
                <NotFound css={{ height: '100%' }} text={t('solo.playerboard.dataFetchingFailed')} />
              </ProgressContainer>
            ) : (
              <DashBoardFilter
                isLoading={isTableLoading}
                stats={data!.stats}
                currentChampion={currentChampion}
                currentOppChampion={currentOppChampion}
                total={total}
                oppTotal={oppTotal}
                onReset={() => {
                  setIsTableLoading(true);
                  setCurrentChampion('');
                  setCurrentOppChampion('');
                  setTotal('');
                  setOppTotal('');
                  getPlayerDashboard({
                    champion: '',
                    oppchampion: '',
                  });
                }}
                onSubmit={() => {
                  console.log(getTrueValueList(champion));
                  const selectedChampion =
                    getTrueValueList(champion).length === 0 ? '' : getTrueValueList(champion)[0];

                  const selectedOppChampion =
                    getTrueValueList(oppchampion).length === 0 ? '' : getTrueValueList(oppchampion)[0];
                  setCurrentChampion(selectedChampion);
                  setCurrentOppChampion(selectedOppChampion);
                  setTotal(totalGame);
                  setOppTotal(oppTotalGame);

                  setIsTableLoading(true);

                  getPlayerDashboard({
                    champion: selectedChampion,
                    oppchampion: selectedOppChampion,
                  });
                }}
              />
            )}
          </div>
          {/* 차트 */}
          <DashBoardChart records={data?.records} trends={data?.trends} />
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  ${spacing.paddingX(6)}
  ${spacing.paddingT(4)}
  ${spacing.paddingB(10)}
  background-color: ${colors.bg_light};
  border-radius: 0 0 10px 10px;
`;

const ProgressContainer = styled.div`
  width: 100%;
  height: 223px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export default PlayerDashBoard;
