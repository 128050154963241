import { atom } from 'recoil';
import {
  ActionLog,
  Infos,
  Live,
  Log,
  Mapping,
  Status,
  TeamGold,
  TimeLineSet,
} from '../../services/GameServices';
import { Player } from '../../services/types/param_types';

interface GameParams {
  timer: number;
  detail: null;
  player: null;
  uniqueId: string;
  blueteam: string;
  redteam: string;
  selectedTeam: number;
  selectedPosition: number;
  selectedParticipant: number;
  startTime: string;
  gameTime: number;
  champTab: number;
  eventLogActiveIdx: number;
  statusLogActiveIdx: number;
  itemActiveIdx: number;
  liveActiveIdx: number;
  goldActiveIdx: number;
  mappingActiveIdx: number;
  playersActiveIdx: number;
  seekTime: number;
  selectedStatusTime: number;
  selectedStatusType: string;
  selectedStatusText: string;
}

const GameAtom = {
  timer: atom<number>({
    key: 'GameTimer',
    default: 0,
  }),

  detail: atom<null>({
    key: 'GameDetail',
    default: null,
  }),

  player: atom<null>({
    key: 'GamePlayer',
    default: null,
  }),

  uniqueId: atom<string>({
    key: 'GameUniqueId',
    default: '',
  }),

  blueteam: atom<string>({
    key: 'GameBlueTeam',
    default: '',
  }),

  redteam: atom<string>({
    key: 'GameRedTeam',
    default: '',
  }),

  selectedTeam: atom<number>({
    key: 'GameSelectedTeam',
    default: 0,
  }),

  selectedPosition: atom<number>({
    key: 'GameSelectedPosition',
    default: 0,
  }),

  selectedParticipant: atom<number>({
    key: 'GameSelectedParticipant',
    default: 0,
  }),

  startTime: atom<string>({
    key: 'GameStartTime',
    default: '',
  }),

  gameTime: atom<number>({
    key: 'GameGameTime',
    default: 0,
  }),

  champTab: atom<number>({
    key: 'GameChampTab',
    default: 0,
  }),

  eventLogActiveIdx: atom<number>({
    key: 'GameEvnetLogActiveIdx',
    default: 0,
  }),

  statusLogActiveIdx: atom<number>({
    key: 'GameStatusLogActiveIdx',
    default: 0,
  }),

  itemActiveIdx: atom<number>({
    key: 'GameItemActiveIdx',
    default: 0,
  }),

  liveActiveIdx: atom<number>({
    key: 'GameLiveActiveIdx',
    default: 0,
  }),

  goldActiveIdx: atom<number>({
    key: 'GameGoldActiveIdx',
    default: 0,
  }),

  mappingActiveIdx: atom<number>({
    key: 'GameMappingActiveIdx',
    default: 0,
  }),

  playersActiveIdx: atom<number>({
    key: 'GamePlayersActiveIdx',
    default: 0,
  }),

  seekTime: atom<number>({
    key: 'GameSeekTime',
    default: 0,
  }),

  selectedStatusTime: atom<number>({
    key: 'GameSelectedStatusTime',
    default: 0,
  }),
  selectedStatusType: atom<string>({
    key: 'GameSelectedStatusType',
    default: '',
  }),
  selectedStatusText: atom<string>({
    key: 'GameSelectedStatusText',
    default: '',
  }),
};

export const GameResultAtom = atom<GameParams>({
  key: 'GameResult',
  default: undefined,
});

export default GameAtom;
