import { useQuery } from 'react-query';
import FilterService from '../services/FilterServices';

export const useFilterCall = () =>
  useQuery(['filter', 'call'], () => FilterService.getFilterCall(), {
    select: (data) => data.response,
    staleTime: 1000 * 60 * 60 * 3,
    cacheTime: 1000 * 60 * 60 * 3,
    refetchOnWindowFocus: false,
  });
