import { TRANSLATE_TOOLTIP } from '../translation/Tooltip/translate_Tooltip';

// 선수상황판 - 챔피언 필터링 영역 하단 지표 툴팁
export const PLAYERBOARD_TOOLTIP_CONTENTS = {
  // key : title.name
  CSD7: TRANSLATE_TOOLTIP.선수보고서.CSD7,
  CSD10: TRANSLATE_TOOLTIP.선수보고서.CSD10,
  CSD15: TRANSLATE_TOOLTIP.선수보고서.CSD15,
  XPD7: TRANSLATE_TOOLTIP.선수보고서.XPD7,
  XPD10: TRANSLATE_TOOLTIP.선수보고서.XPD10,
  XPD15: TRANSLATE_TOOLTIP.선수보고서.XPD15,
  GD7: TRANSLATE_TOOLTIP.선수보고서.GD7,
  GD10: TRANSLATE_TOOLTIP.선수보고서.GD10,
  GD15: TRANSLATE_TOOLTIP.선수보고서.GD15,
  상대진영와드확률: TRANSLATE_TOOLTIP.선수보고서.상대진영와드확률,
  '타워 허깅시간 (초)': TRANSLATE_TOOLTIP.선수보고서.타워허깅시간,
  솔로킬: TRANSLATE_TOOLTIP.선수보고서.솔로킬,
  '갱 회피 횟수': TRANSLATE_TOOLTIP.선수보고서.갱회피횟수,
  '갱 횟수': TRANSLATE_TOOLTIP.선수보고서.갱횟수,
  '갱 성공률 (%)': TRANSLATE_TOOLTIP.선수보고서.갱성공률,
  '플레이메이킹 횟수': TRANSLATE_TOOLTIP.선수보고서.플레이메이킹횟수,
  '교전 합류 시간 (초)': TRANSLATE_TOOLTIP.선수보고서.교전합류시간,
  '분당 데미지': TRANSLATE_TOOLTIP.선수보고서.DPM,
  '분당 받은 데미지': TRANSLATE_TOOLTIP.선수보고서.DTPM,
  '킬 관여율 (%)': TRANSLATE_TOOLTIP.선수보고서.킬관여율,
  '듀오 인접률 (%)': TRANSLATE_TOOLTIP.선수보고서.듀오인접률,
  '비듀오 인접 시간 (초)': TRANSLATE_TOOLTIP.선수보고서.비듀오인접시간,
  '라인 서포트 횟수': TRANSLATE_TOOLTIP.선수보고서.라인서포트횟수,
  '서포트 받은 시간 (초)': TRANSLATE_TOOLTIP.선수보고서.서포트받은시간,
  '서포트 받은 시간+': TRANSLATE_TOOLTIP.선수보고서.서포트받은시간2,
  '교전 참여 횟수': TRANSLATE_TOOLTIP.선수보고서.교전참여횟수,
  '상대 정글 침입 시간 (초)': TRANSLATE_TOOLTIP.선수보고서.상대정글침입시간,
  '합리적 위험 감수 성향 (%)': TRANSLATE_TOOLTIP.선수보고서.합리적위험감수성향,
  '라인 시팅 지수': TRANSLATE_TOOLTIP.선수보고서.라인시팅지수,
  '탑 시팅 지수+': TRANSLATE_TOOLTIP.선수보고서.탑시팅지수,
  '바텀 시팅 지수+': TRANSLATE_TOOLTIP.선수보고서.바텀시팅지수,
  '미드 시팅 지수+': TRANSLATE_TOOLTIP.선수보고서.미드시팅지수,
  '탑 로밍 빈도': TRANSLATE_TOOLTIP.선수보고서.탑로밍빈도,
  '미드 로밍 빈도': TRANSLATE_TOOLTIP.선수보고서.미드로밍빈도,
  '바텀 로밍 빈도': TRANSLATE_TOOLTIP.선수보고서.바텀로밍빈도,
  '로밍 빈도': TRANSLATE_TOOLTIP.선수보고서.로밍빈도,
  '첫 텔레포트 사용 시간 (초)': TRANSLATE_TOOLTIP.선수보고서.첫텔레포트시간,
  '텔레포트 선제 사용빈도': TRANSLATE_TOOLTIP.선수보고서.텔레포트선제사용빈도,
  '선픽률 (%)': TRANSLATE_TOOLTIP.선수보고서.선픽률,
};
