import * as React from 'react';
import { GridColDef, GridColumnMenu, GridColumnMenuProps, GridValidRowModel } from '@mui/x-data-grid-pro';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material';
import { colors, scrollbarStyle } from '../../../Styles/ui';

interface CustomDataGridProps {
  columns: GridColDef[];
  rows: GridValidRowModel[];
  components?: any;
  [key: string]: any;
}

const StyledGridColumnMenu = styled(GridColumnMenu)(({ theme }) => ({
  background: colors.배경색[500],
  color: colors.text,
}));

export function CustomColumnMenuComponent(props: GridColumnMenuProps) {
  const { hideMenu, currentColumn, ...other } = props;
  return <StyledGridColumnMenu hideMenu={hideMenu} currentColumn={currentColumn} {...other} />;
}

export default function CustomDataGrid({
  columns,
  rows,
  headerHeight,
  rowHeight,
  components,
  ...props
}: CustomDataGridProps) {
  console.log(rows.length);
  return (
    <StyledDataGrid
      {...props}
      rows={rows}
      columns={columns}
      pageSize={rows.length}
      headerHeight={headerHeight ?? 36}
      rowHeight={rowHeight ?? 36}
      disableSelectionOnClick
      hideFooter
      components={{
        ...components,
        ColumnMenu: CustomColumnMenuComponent,
      }}
    />
  );
}

const StyledDataGrid = styled(DataGridPro)`
  color: white;
  border: none;

  .MuiDataGrid-virtualScroller {
    ${scrollbarStyle.scroll_4};
  }

  .MuiIconButton-root {
    color: white;
  }

  .MuiDataGrid-columnHeaders {
    background-color: #3a3745;
    border-bottom: none;
  }

  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    &:first-of-type {
      padding: 0 20px;
    }
  }

  .MuiDataGrid-cell {
    border-bottom: 1px solid #3a3745;
    &:last-of-type {
      color: ${colors.badge_red};
      padding: 0;
    }
  }

  .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-cell:focus {
    outline: none;
  }

  .MuiDataGrid-columnSeparator {
    opacity: 0 !important;
  }
`;
