import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { createContext } from 'react';

export type ModalDispatch = {
  open: (...props: any) => void;
  close: (...props: any) => void;
};

export type Modal = {
  Component: ReactJSXElement;
  props: { [key: string]: any };
};

// 모달 열고 닫기
export const ModalsDispatchCointext = createContext<ModalDispatch>({
  open: () => {},
  close: () => {},
});

// 보여줄 모달 리스트
export const ModalsStateContext = createContext<Modal[]>([]);
