import React, { useCallback } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Avatar } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { ChampionListAtom, LOLPatchAtom } from '../../../../recoil/common/championListAtom';
import { getPositon } from '../../../../lib/getPosition';
import { ITeamDraftPickAddSide } from '../../../Scrim/components/ScrimDraftBox';
import useParseChampionImagePath from '../../../../Hooks/useParseChampionImagePath';

const NOIMG = '/Images/ico-ban-nopick.svg';

interface P {
  data: ITeamDraftPickAddSide;
  color: 'red' | 'blue';
}

const PickBox: React.FC<P> = ({ data, color }) => {
  const { t } = useTranslation();
  const championList = useRecoilValue(ChampionListAtom);
  const patch = useRecoilValue(LOLPatchAtom);

  const parseChampionImagePath = useParseChampionImagePath(patch, championList);

  const parseImgPath = useCallback((champion) => parseChampionImagePath(champion), [parseChampionImagePath]);

  return championList && patch ? (
    <CONTAINER>
      <Avatar
        sx={{
          width: 42,
          height: 42,
          margin: '0 auto',
        }}
        src={parseImgPath(data.champion)}
        alt={data.champion}
      />

      {Number(data.fpick) === 1 && (
        <LABEL>
          <img
            src={`/Images/position/ico-position-${getPositon({
              role: Number(data.pos || data.position) as 1 | 2 | 3 | 4 | 5,
            })}.svg`}
            alt='line'
          />
          <span>{t('game.banPick.firstPick')}</span>
        </LABEL>
      )}
    </CONTAINER>
  ) : (
    <></>
  );
};

export default PickBox;

const CONTAINER = styled.div`
  flex: 0 0 65px;
  text-align: center;
`;

const LABEL = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  padding: 0 4px;
  border-radius: 10px;

  img {
    display: inline-block;
    width: 12px;
    height: 12px;
    object-fit: contain;
    margin-right: 4px;
  }

  span {
    font-size: 10px;
    line-height: 1;
    color: #fff;
    white-space: nowrap;
  }
`;
