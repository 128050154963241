/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Radar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { borderRadiusStyle, colors, spacing, typoStyle } from '../../../Styles/ui';
import { playerRadarOptions } from '../../../Styles/chart/option';
import { getWinRate } from '../../../lib/getWinRate';
import { getKda } from '../../../lib/getKda';
import {
  playerLabels,
  commonLabels,
  prLabels,
  commonTooltipContents, // 공통지표 툴팁
  detailTooltipContents, // 세부지표 툴팁
} from '../../../lib/mapping/map_table_label';
import CustomTooltip from '../../../Components/Ui/Tooltip/CustomTooltip';
import { ITeamAnalysisInfo } from '../../../services/types/player_services.type';

const imgUrl = (imgString: string) =>
  imgString === 'player_error_image.png'
    ? '/Images/player_error_image.png'
    : `https://am-a.akamaihd.net/image?f=${imgString}`;

interface DashBoardPlayerInfoProps {
  info?: ITeamAnalysisInfo;
  [key: string]: any;
}

const DashBoardPlayerInfo = ({ info, ...props }: DashBoardPlayerInfoProps) => {
  const { t } = useTranslation();

  // 로딩중일때
  if (!info) {
    return <div>로딩중</div>;
  }

  console.log('info.NativeName', info.NativeName);

  // data destructure
  const {
    total,
    wins,
    NativeName,
    Name,
    Image,
    pr,
    // player,
    Birthdate,
    Role,
    Age,
    Country,
    Team,
    teamShort,
    career,
    kills,
    assists,
    deaths,
    common,
    detail,
  } = info;

  // 커리어 승률, kda
  const {
    kills: careerKills,
    assists: careerAssists,
    deaths: careerDeaths,
    total: careerTotal,
    wins: careerWins,
  } = career;
  const careerWinRate = getWinRate(careerTotal, careerWins);
  const { k: ck, d: cd, a: ca, kda: ckda } = getKda(careerTotal, careerKills, careerDeaths, careerAssists);

  // 시즌 승률, kda
  const winRate = getWinRate(total, wins);
  const { k, d, a, kda } = getKda(total, kills, deaths, assists);

  // Table
  // data
  const {
    dpm,
    dpm_place,
    dtpm,
    dtpm_place,
    cs10,
    cs10_place,
    solokill,
    solokill_place,
    champion_pool,
    champion_pool_place,
    first_pick_rate,
  } = common;

  const {
    pr_place,
    damage,
    damage_place,
    lanePhase,
    lanePhase_place,
    survival,
    survival_place,
    killCatch,
    killCatch_place,
    earlyFight,
    earlyFight_place,
  } = detail;

  // Header - id, name, positio, 국적, 나이, 평균 pr
  const headerTable = [
    {
      label: playerLabels[0],
      data: Name,
    },
    {
      label: playerLabels[1],
      data: NativeName === '' ? Name : NativeName,
    },
    {
      label: playerLabels[2],
      data: t(`position.${Role.toLowerCase()}`),
    },
    {
      label: playerLabels[3],
      data: Country === '' ? '-' : Country,
    },
    {
      label: playerLabels[4],
      data: Age === 0 ? '-' : Age,
      detail: Birthdate === '' ? '-' : Birthdate,
    },
    {
      label: playerLabels[5],
      data: pr.toFixed(1),
      place: pr_place,
    },
  ];

  // rendering table
  const commonTable = [
    {
      label: commonLabels[0],
      data: champion_pool,
      place: champion_pool_place,
      tooltip: commonTooltipContents[0],
    },
    {
      label: commonLabels[1],
      data: `${first_pick_rate.toFixed(1)}%`,
      tooltip: commonTooltipContents[1],
    },
    {
      label: commonLabels[2],
      data: solokill.toFixed(1),
      place: solokill_place,
      tooltip: commonTooltipContents[2],
    },
    {
      label: commonLabels[3],
      data: dpm.toFixed(1),
      place: dpm_place,
      tooltip: commonTooltipContents[3],
    },
    {
      label: commonLabels[4],
      data: dtpm.toFixed(1),
      place: dtpm_place,
      tooltip: commonTooltipContents[4],
    },
    {
      label: commonLabels[5],
      data: cs10.toFixed(1),
      place: cs10_place,
      tooltip: commonTooltipContents[5],
    },
  ];

  const detailTable = [
    {
      label: prLabels[0],
      data: pr.toFixed(1),
      place: pr_place,
      tooltip: detailTooltipContents[0],
    },
    {
      label: prLabels[1],
      data: damage.toFixed(1),
      place: damage_place,
      tooltip: detailTooltipContents[1],
    },
    {
      label: prLabels[2],
      data: survival.toFixed(1),
      place: survival_place,
      tooltip: detailTooltipContents[2],
    },
    {
      label: prLabels[3],
      data: killCatch.toFixed(1),
      place: killCatch_place,
      tooltip: detailTooltipContents[3],
    },
    {
      label: prLabels[4],
      data: lanePhase.toFixed(1),
      place: lanePhase_place,
      tooltip: detailTooltipContents[4],
    },
    {
      label: prLabels[5],
      data: earlyFight.toFixed(1),
      place: earlyFight_place,
      tooltip: detailTooltipContents[5],
    },
  ];

  // chart
  const chartData = {
    labels: prLabels.filter((_, i) => i !== 0).map((row) => t(row)), // PR 제외 그래프이므로
    datasets: [
      {
        data: detailTable.map((row) => Number(row.data)).filter((_, i) => i !== 0),
        fill: true,
        backgroundColor: 'rgba(89,66,186,0.2)',
        borderColor: '#8069e3',
        pointStyle: 'dash',
      },
    ],
  };

  return (
    <div {...props} style={{ color: 'white' }}>
      {/* 선수 기본 정보  */}
      <PlayerContainer>
        <ImgContainer>
          <img src={imgUrl(Image)} alt='' />
        </ImgContainer>
        <ListContainer>
          <List>
            {headerTable.slice(0, 3).map((row) => (
              <ListItem key={row.label}>
                <dt>{t(row.label)}</dt>
                <dd>{row.data}</dd>
              </ListItem>
            ))}
          </List>
        </ListContainer>
        <ListContainer>
          <List>
            {headerTable.slice(3).map((row) => (
              <ListItem key={row.label}>
                <dt>{t(row.label)}</dt>
                <dd>
                  {row.data}
                  {row.detail && (
                    <span>
                      {` (${t(row.detail)})
                        `}
                    </span>
                  )}
                  {row.place && (
                    <span>
                      {` (${t('label.league')}
                          ${t(row.place.toString())}
                          ${t('league.playerStat.place')})
                        `}
                    </span>
                  )}
                </dd>
              </ListItem>
            ))}
          </List>
        </ListContainer>
      </PlayerContainer>

      {/* 상단 박스 데이터 */}
      <DataBoxContainer>
        <DataBox>
          <span className='data__title'>{t('league.playerStat.record')}</span>

          <p className='data__main'>{winRate}</p>
          <span className='data__info'>
            {total}
            {t('league.playerStat.games')}
            &nbsp;{wins}
            {t('league.playerStat.win')}
            &nbsp;{total - wins}
            {t('league.playerStat.lose')}
          </span>
        </DataBox>
        <DataBox>
          <span className='data__title'>{t('league.playerStat.kda')}</span>
          <p className='data__main'>{kda}:1</p>
          <span className='data__info'>
            {k}/{d}/{a}
            <em>
              ({kills} / {deaths} / {assists})
            </em>
          </span>
        </DataBox>
        <DataBox>
          <span className='data__title'>{t('league.playerStat.career')}</span>
          <p className='data__main'>{careerWinRate}</p>
          <span className='data__info'>
            {careerTotal}
            {t('league.playerStat.games')}
            &nbsp;{careerWins}
            {t('league.playerStat.win')}
            &nbsp;{careerTotal - careerWins}
            {t('league.playerStat.lose')}
          </span>
        </DataBox>
        <DataBox>
          <span className='data__title'>{t('league.playerStat.careerKda')}</span>
          <p className='data__main'>{ckda}:1</p>
          <span className='data__info'>
            {ck}/{cd}/{ca}
            <em>
              ({careerKills} / {careerDeaths} / {careerAssists})
            </em>
          </span>
        </DataBox>
      </DataBoxContainer>

      <DataBoxContainer>
        <DataTable>
          <thead>
            <tr>
              <th colSpan={2}>{t('league.playerStat.commonStat')}</th>
            </tr>
          </thead>
          <tbody>
            {commonTable.map((row) => (
              <tr key={row.label}>
                <CustomTooltip title={t(row.tooltip)} placement='top'>
                  <td>{t(row.label)}</td>
                </CustomTooltip>
                <td>
                  {row.data}
                  {row.place && (
                    <span>
                      {` (${t('label.league')}
                              ${t(row.place.toString())}
                              ${t('league.playerStat.place')})
                            `}
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </DataTable>

        <DataTable>
          <thead>
            <tr>
              <th colSpan={2}>{t('league.playerStat.PRStat')}</th>
            </tr>
          </thead>
          <tbody>
            {detailTable.map((row) => (
              <tr key={row.label}>
                <CustomTooltip title={t(row.tooltip)} placement='top'>
                  <td>{t(row.label)}</td>
                </CustomTooltip>
                <td>
                  {row.data}
                  {row.place && (
                    <span>
                      {` (${t('label.league')}
                              ${t(row.place.toString())}
                              ${t('league.playerStat.place')})
                            `}
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </DataTable>

        <ChartContainer>
          <Radar data={chartData} options={playerRadarOptions} plugins={[ChartDataLabels]} />
        </ChartContainer>
      </DataBoxContainer>
    </div>
  );
};

const PlayerContainer = styled.div`
  display: flex;
  ${borderRadiusStyle[20]}
  background-color: ${colors.bg_select};
  height: 160px;
  ${spacing.marginB(5)}
`;
const ImgContainer = styled.div`
  flex: 1.5;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  img {
    height: 100%;
    object-fit: contain;
  }
`;
const ListContainer = styled.div`
  ${typoStyle.contents};
  ${spacing.marginX(5)}
  position: relative;
  flex: 1.5;
  display: flex;
  align-items: center;
  &:last-child {
    flex: 2;
  }

  &::before {
    content: '';
    position: absolute;
    left: -20px;
    width: 1px;
    height: 70px;
    background-color: ${colors.border_light};
  }
`;

const List = styled.dl``;
const ListItem = styled.div`
  display: flex;
  &:not(:last-of-type) {
    ${spacing.marginB(2)}
  }
  dt {
    width: 110px;
    color: ${colors.info};
  }
  dd {
    ${typoStyle.noWrap};
    width: 150px;
  }
`;

const DataBoxContainer = styled.div`
  display: flex;
  min-height: 120px;

  &:not(:last-of-type) {
    ${spacing.marginB(5)}
  }

  > div:not(:last-of-type) {
    ${spacing.marginR(5)}
  }

  > table {
    ${spacing.marginR(5)}
  }
`;

const DataBox = styled.div`
  ${typoStyle.contents_md};
  ${borderRadiusStyle[20]};
  ${spacing.padding(5)};
  background-color: ${colors.bg_select};
  flex: 1;
  flex-shrink: 1;
  min-width: 120px;

  span:first-of-type {
    display: block;
    ${spacing.marginB(1)};
  }
  p {
    ${typoStyle.percent};
  }
  span:last-of-type {
    em {
      ${spacing.paddingL(1)};
      ${typoStyle.info};
    }
  }
`;

const DataTable = styled.table`
  width: 373px;
  height: 230px;
  background-color: ${colors.bg_select};
  ${borderRadiusStyle[20]}
  overflow: hidden;

  thead {
    ${typoStyle.contents_title};
    th {
      padding: 15px;
    }
  }
  tbody {
    ${typoStyle.contents};
    tr {
      &:nth-of-type(2n + 1) {
        background-color: ${colors.bg_checkbox};
      }
      &:last-of-type {
        td {
          ${spacing.paddingB(2)};
        }
      }
      td {
        vertical-align: middle;
        ${spacing.paddingY(1)};
      }
      td:first-of-type {
        ${spacing.paddingL(5)};
        width: 210px;
      }
      td:nth-of-type(2) {
        ${spacing.paddingR(2)};
        text-align: left;
        width: calc(100% - 220px);
      }
    }
  }
`;

const ChartContainer = styled.div`
  flex: 1;
  max-height: 230px;
`;

export default DashBoardPlayerInfo;
