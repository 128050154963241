import React, { ChangeEvent, SyntheticEvent } from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Autocomplete, Avatar, TextField, styled as MUIStyled, createFilterOptions } from '@mui/material';
import { spacing, colors, scrollbarStyle, typoStyle } from '../../../Styles/ui';
import { TRANSLATE_SCRIM_TITLE } from '../../../lib/translation/translate_scrim';
import { OriginalChampData, OriginalChampionObject } from '../../../recoil/common/championListAtom';
import { userLanguageAtom } from '../../../recoil/Auth/userAtom';
import { recentVersion } from '../../config';
import PositionCheckBox from './PositionCheckBox';
import { ScrimAction } from '../../../services/ScrimServices';
import useChampionImage from '../../../Hooks/useChampionImage';

// 필터링 옵션 : 챔피언넘버, 영문으로도 검색 가능
const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: OriginalChampionObject) => option.ckey + option.cid + option.cname,
});

interface SearchChampProps {
  width: string;
  isPick?: boolean;
  handleChange?: (event: SyntheticEvent<HTMLInputElement>, value: number, id: ScrimAction['type']) => void;
  id: ScrimAction['type'];
  handlePosChange?: (position: number, positionType: ScrimAction['type']) => void;
  error: boolean;
  posError?: boolean;
  positionType?: ScrimAction['type'];
  championState: undefined | number;
  positionState: undefined | number;
}

export default function SearchChampion({
  width,
  isPick = false,
  id,
  championState,
  handleChange,
  error,
  positionType,
  positionState,
  handlePosChange,
  posError,
}: SearchChampProps) {
  const { getChampionImagePathByName } = useChampionImage();
  const champData = useRecoilValue(OriginalChampData);
  const { t } = useTranslation();
  const lang = useRecoilValue(userLanguageAtom);
  const isKo = lang === 'ko';
  const championObj = champData && champData.list.find((item) => item.ckey === Number(championState));
  const handleChangePosition = (pos: number) => {
    if (handlePosChange && positionType) {
      handlePosChange(pos, positionType);
    }
  };

  return (
    <WRAPPER>
      {champData && (
        <>
          <ImgWrapper isPick={isPick} champImg={!!championState} error={error}>
            {!isPick && !!championState && <div className='line' />}
            {isPick && !!championState && error && <div className='errorLayer' />}
            <img
              src={
                championObj?.cid
                  ? getChampionImagePathByName(championObj?.cid)
                  : '/Images/ico-cham-none@2x.png'
              }
              alt='champ'
            />
          </ImgWrapper>

          <AUTO_CONTAINER>
            <StyledAutocomplete
              value={championObj ?? null}
              disablePortal
              error={error}
              id={id}
              filterOptions={filterOptions}
              options={champData?.list}
              getOptionLabel={(opt: OriginalChampionObject) => {
                return isKo ? (opt.cname as string) : (opt.cid as string);
              }}
              classes={{
                root: MUI_AUTOCOMPLETE_CSS,
                paper: MUI_PAPER_CSS,
              }}
              popupIcon={null}
              noOptionsText={t(TRANSLATE_SCRIM_TITLE.noChamp)}
              sx={{ width, backgroundColor: '#2f2d38' }}
              renderOption={(option, value) => {
                return (
                  <li {...option}>
                    <Avatar
                      sx={{
                        width: 20,
                        height: 20,
                        marginRight: 1,
                      }}
                      src={getChampionImagePathByName(value.cid)}
                    />
                    <span
                      style={{
                        width: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {isKo ? value.cname : value.cid}
                    </span>
                  </li>
                );
              }}
              renderInput={(params) => (
                <StyledTextField {...params} placeholder={t(TRANSLATE_SCRIM_TITLE.inputChamp)} />
              )}
              onChange={(event: SyntheticEvent<HTMLInputElement, ChangeEvent>, value: number) => {
                if (handleChange) {
                  handleChange(event, value, id);
                }
              }}
            />
          </AUTO_CONTAINER>

          {isPick && (
            <POSITION_CONTAINER>
              <PositionCheckBox
                position={Number(positionState)}
                error={posError}
                onChange={handleChangePosition}
                boxStyle={{ width: 40, height: 40 }}
              />
            </POSITION_CONTAINER>
          )}
        </>
      )}
    </WRAPPER>
  );
}
const WRAPPER = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
`;

const AUTO_CONTAINER = styled.div`
  width: 100%;
`;

const POSITION_CONTAINER = styled.div`
  margin-left: 10px;
`;

const ImgWrapper = styled.div<{ isPick: boolean; champImg: boolean; error: boolean }>`
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  margin-right: 4px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border: ${({ error }) => (error ? `1px solid ${colors.badge_red}` : `1px solid transparent`)};
  background-color: ${({ error }) => (error ? `rgba(240, 69, 69, 0.2)` : `transparent`)};

  .line {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 50px;
    background-color: ${colors.badge_red};
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .errorLayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(240, 69, 69, 0.2);
  }

  > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    mix-blend-mode: ${({ isPick }) => (!isPick ? 'luminosity' : 'normal')};
    opacity: ${({ isPick, champImg }) => (!isPick && champImg ? '0.5' : '1')};
  }
`;

const StyledAutocomplete = MUIStyled(Autocomplete)<{ error: boolean }>`
  width: 100%;
  border-radius: 8px;
  margin-right: 2px;
  padding-right: 0;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  
  & .MuiOutlinedInput-root {
      padding: 0 !important;
      color: #fff;
      font-size:13px;
      height: 40px;

      &:hover {
        border:none;
      }
  }

  & .MuiAutocomplete-listbox {
    ${scrollbarStyle.scroll_4}
    }

  &.MuiAutocomplete-hasClearIcon {
    border: 1px solid ${({ error }) => (error ? '#f04545 ' : '#5a41b7')}; 
    background-color: ${({ error }) => (error ? 'rgba(240, 69, 69, 0.2); ' : 'rgba(90, 65, 183, 0.2)')}; 
    border-radius: 8px;
  }
  

`;

const StyledTextField = MUIStyled(TextField)`
  & .MuiFilledInput-root {
    height: 40px;
    padding: 12px;
    background: transparent;
    color: white;
    font-size: 13px;
    font-family: SpoqaHanSansNeo;

    &::focus {
      background: red;
    }
  
    &::after {
      display: none;
    }
  }
 
`;

const MUI_PAPER_CSS = css`
  &.MuiPaper-root {
    overflow: hidden;
    background-color: ${colors.bg_box};
    border-radius: 8px;
  }

  & .MuiAutocomplete-listbox {
    ${scrollbarStyle.scroll_4}
    max-height: 160px;

    /* 옵션 */
    & .MuiAutocomplete-option {
      height: 32px;
      ${typoStyle.select};
      padding-left: 4px;

      ${spacing.paddingX(5)};
      &.Mui-focused {
        background-color: rgba(188, 175, 242, 0.1);
      }
    }

    /* 선택되었을때 */
    & .MuiAutocomplete-option[aria-selected='true'] {
      background-color: #5a41b7 !important;
    }
  }

  /* 옵션값이 없을때 */
  & .MuiAutocomplete-noOptions {
    color: white;
    ${typoStyle.select};
  }
`;

const MUI_AUTOCOMPLETE_CSS = css`
  background-color: #4e4e5c;
  border-radius: 8px;
  padding-right: 0 !important;

  &.MuiAutocomplete-hasClearIcon {
    border: 1px solid #5a41b7;
    background-color: rgba(90, 65, 183, 0.2);
    border-radius: 8px;
  }

  .MuiFilledInput-root {
    height: 40px;
    &::before {
      display: none;
    }
  }

  .MuiSvgIcon-root {
    color: white;
  }
`;
