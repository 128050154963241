const noneItem = (len: number) => {
  const empty = 6 - len;
  const result = [];
  for (let i = 0; i < empty; i += 1) {
    result.push(i);
  }
  return result;
};

export default noneItem;
