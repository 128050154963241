import { useState } from 'react';

/**
 * @param  initialTabIndex : number
 * @param allTabs [{[key: string]: string | JSX }]
 * @returns [currentTab : JSX, setIndex: Dispatch<SetStateAction<number>> ]
 */

export const useTab = <T>(initialTabIndex: number, allTabs: T[]) => {
  const [index, setIndex] = useState(initialTabIndex);

  return { currentIndex: index, currentTab: allTabs[index], setIndex };
};
