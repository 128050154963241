/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { testStyle, transitionStyle, typoStyle, colors, spacing } from '../../../Styles/ui';
import AlertModal from '../../../Components/UtilityComponent/AlertModal';

const Tabs = [
  { path: '', title: 'video.jungle.JunglePathing' },
  // { path: 'compare', title: 'video.jungle.CompareJungling' },
];

const JungleMap = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <>
      <AlertModal />
      <SContainer>
        <TabHeader>
          {Tabs.map((tab, i) => {
            const active =
              i === 0 ? pathname.split('/')[3] === undefined : pathname.split('/')[3] === tab.path;
            return (
              <Tab key={tab.title} active={active}>
                <SLink to={tab.path}>{t(tab.title)}</SLink>
              </Tab>
            );
          })}
        </TabHeader>
        <Outlet />
      </SContainer>
    </>
  );
};

const SContainer = styled.section`
  ${typoStyle.contents}
`;

const TabHeader = styled.ul`
  display: flex;
  ${spacing.marginB(5)};
`;

const Tab = styled.li`
  ${spacing.paddingX(4)}
  ${spacing.paddingY(3)}
  ${transitionStyle.opacity}
  color: ${colors.text};
  opacity: ${({ active }) => (active ? 1 : 0.3)};
  /* border-bottom: 1px solid transparent; */
  border-color: ${({ active }) => (active ? colors.text : 'transparent')};

  &:hover {
    opacity: 1;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
      user-select: none;
      border-bottom: none;
    `}
`;

const STab = styled.ul`
  display: flex;
  width: 100%;
  height: 62px;
  border-bottom: 1px solid ${colors.border_light};
  margin-bottom: 10px;
`;

const STabItem = styled.li`
  position: relative;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.3;

  ${typoStyle.body}
  ${transitionStyle.opacity};

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 120px;
    height: 1px;
    background: ${colors.text};
    opacity: 0;
    ${transitionStyle.opacity};
  }

  &.is-active {
    opacity: 1;

    &::after {
      opacity: 1;
    }
  }
`;

const SContents = styled.div`
  display: flex;
  /* ${testStyle.border2} */
`;

const SLink = styled(Link)`
  font-size: 18px;
`;

export default JungleMap;
