import React, { memo, SyntheticEvent } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Autorenew } from '@mui/icons-material';
import { t } from 'i18next';
import { css } from '@emotion/css';
import { Button, ButtonProps, FormLabel, InputBase, styled, Box, SvgIcon } from '@mui/material';
import { colors, scrollbarStyle, spacing } from '../../../Styles/ui';
import { getTrueValueList } from '../../../lib/getTureValueList';
import { MAP_FILTER_MEMU } from '../../../lib/mapping/map_filter_menu';

type RenderList = {
  value: string;
  position: string;
};
interface CustomSelectProps {
  placeholder?: string;
  onLabelClick?: () => void;
  renderList: RenderList[];
  data: FilterData | undefined;
  step: number;
  setData: React.Dispatch<React.SetStateAction<FilterData | undefined>> | SetterOrUpdater<FilterData>;
}

export const CustomSelect = ({
  placeholder = 'PlaceHolder',
  onLabelClick,
  renderList,
  data,
  step,
  setData,
}: CustomSelectProps) => {
  const handleReset = () => {
    setData({});
  };
  const handleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    setData({ [value]: true });
  };

  const trueData = data && getTrueValueList(data);

  const handleClick = (e: SyntheticEvent<unknown>) => {
    e.preventDefault();
    if (onLabelClick) {
      onLabelClick();
    }
  };

  return (
    <FormControl fullWidth>
      <StyledFormLabel onClick={handleClick}>
        <TEXT>
          {step && <STEP>STEP{step}.</STEP>}
          <span>{placeholder}</span>
        </TEXT>

        <MUIRESETBUTTON size='small' onClick={handleReset} color='primary'>
          <SvgIcon
            sx={{
              fontSize: '12px',
            }}
          >
            <Autorenew />
          </SvgIcon>
          {t(MAP_FILTER_MEMU.리셋)}
        </MUIRESETBUTTON>
      </StyledFormLabel>
      <MUISELECT
        displayEmpty
        input={<StyledInputBase />}
        value={(trueData && trueData[0]) ?? ''}
        MenuProps={{
          classes: {
            paper: MUI_PAPER_CSS,
          },
        }}
        onChange={handleChange}
        renderValue={(value: string[]) => {
          if (!trueData || trueData.length === 0 || !value || value?.length === 0) {
            return <em>{placeholder}</em>;
          }
          return (
            <>
              <IMG_POSITION>
                <img
                  src={`/Images/position/ico-position-${trueData[0].split('_')[0]}.svg`}
                  alt={`${trueData[0].split('_')[0]}`}
                />
              </IMG_POSITION>
              {trueData[0].split('_')[1]}
            </>
          );
        }}
      >
        <MenuItem disabled value=''>
          <em>{placeholder}</em>
        </MenuItem>
        {renderList &&
          renderList.map((list) => (
            <MenuItem key={`${list.position}_${list.value}`} value={`${list.position}_${list.value}`}>
              <IMG_POSITION>
                <img src={`/Images/position/ico-position-${list.position}.svg`} alt={`${list.position}`} />
              </IMG_POSITION>
              {list.value}
            </MenuItem>
          ))}
      </MUISELECT>
    </FormControl>
  );
};

export default memo(CustomSelect);

const MUISELECT = styled(Select)`
  padding: 0;

  .MuiSvgIcon-root {
    color: #79797c;
  }
`;

const StyledInputBase = styled(InputBase)`
  border-radius: 10px;
  position: relative;
  font-size: 13px;
  height: 40px;
  color: #79797c;
  background-color: ${colors.배경색[600]};
  transition: background 0.2s ease-in-out;

  & .MuiSelect-select {
    display: flex;
    align-items: center;
    color: white;
  }

  & .MuiInputBase-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 16px;
  }

  &:focus {
    border-radius: 10px;
    border-color: ${colors.point};
    box-shadow: 0 0 0 0.1rem inset ${colors.point};
  }
`;

const IMG_POSITION = styled(Box)`
  margin-right: 5px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TEXT = styled(Box)`
  display: flex;
`;

const STEP = styled(Box)`
  color: ${colors.배경색[200]};
  font-size: 11px;
  ${spacing.marginR(2)};
`;

const MUIRESETBUTTON = styled(Button)<ButtonProps>(({ theme }) => ({
  minWidth: 0,
  fontSize: '10px',
  padding: '5px 5px',
  color: colors.배경색[100],
}));

const StyledFormLabel = styled(FormLabel)`
  color: white;
  font-size: 14px;

  &.MuiFormLabel-root.Mui-focused {
    color: white;
  }

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  &.MuiFormLabel-filled ~ div {
    background: rgba(89, 66, 186, 0.2);
    border: 1px solid ${colors.보라색[500]};
  }
`;

const MUI_PAPER_CSS = css`
  &.MuiPaper-root {
    background-color: ${colors.배경색[900]};
    color: white;
    max-height: 180px;
    ${scrollbarStyle.scroll_4};
  }

  & .MuiMenuItem-root {
    display: flex;
    font-size: 13px;
  }
`;
