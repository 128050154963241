export const MAP_FILTER_MEMU = {
  연도: 'filters.setYear',
  리그: 'filters.setLeague',
  시즌: 'filters.setSeason',
  패치: 'filters.setPatchVersion',
  팀: 'filters.team',
  선수: 'filters.player',
  리셋: 'filters.reset',
  요청: 'filters.clickConfirm',
};

export const MAP_FILTER_ERROR = {
  연도: 'error.year',
  리그: 'error.league',
  시즌: 'error.season',
  패치: 'error.patch',
  팀: 'error.team',
  선수: 'error.player',
};
