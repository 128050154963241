/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Button, styled as MUIStyled } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useMutation } from 'react-query';
import { borderRadiusStyle, colors, spacing, tableStyle, typoStyle } from '../../../Styles/ui';
import { soloRankLabels } from '../../../lib/mapping/map_table_label';
import SoloRankTable from '../Components/SoloRankTable';
import Checkbox from '../../../Components/Ui/Checkbox';
import PlayerInfo from '../Components/PlayerInfo';
import getSoloRankInfo from '../../../lib/soloRank/getSoloRankInfo';
import { getTrueValueList } from '../../../lib/getTureValueList';
import { initializedObjValue } from '../../../lib/initializedObjValue';
import Progress from '../../../Components/Ui/Loading/Progress';
import NotFound from '../../../Components/Ui/Error/NotFound';
import { PlayerReportResultAtom } from '../../../recoil/Filter/playerReportAtom';

import PlayerService from '../../../services/PlayerServices';
import SolorankIDList from '../Components/SolorankIDList';
import CalendarRange from '../../../Components/Ui/Calendar/CalendarRange';
import DateRangeService from '../../../lib/Calendar/dateRangelib';

const dateRangeService = new DateRangeService();

function PlayerSoloRank() {
  const NOW = dateRangeService.dateWithOutTime();
  const { t } = useTranslation();
  // global state
  const result = useRecoilValue(PlayerReportResultAtom);

  // ##state
  // state: 체크박스 관련
  const [summonerNameState, setSummonerNameState] = useState<{ [key: string]: boolean }>();
  const selectedSummonerName: string[] = getTrueValueList(summonerNameState)!;
  const [patchState, setPatchState] = useState({});
  // state: 날짜관련
  const [tab, setTab] = useState(5);
  const [startDate, setStartDate] = useState(dateRangeService.clacDate(-30, NOW));
  const [endDate, setEndDate] = useState(NOW);

  // async request
  const {
    mutate: getPlayerDashboard,
    data: playerDashboardData,
    isLoading: playerDashBoardLoading,
    isError: playerDashboardError,
    reset: dashboardReset,
  } = useMutation(({ champion, oppchampion }: { champion: string; oppchampion: string }) =>
    PlayerService.getPlayerDashboard({
      ...result,
      team: result.team[0],
      player: result.player[0].split('_')[1],
      champion,
      oppchampion,
    }),
  );

  const {
    mutate: getPlayerSoloRankInfo,
    data: solorankInfoData,
    isLoading: solorankInfoLoading,
    isError: solorankInfoError,
    reset: infoReset,
  } = useMutation(
    ({ startdate, enddate }: { startdate: Date; enddate: Date }) =>
      PlayerService.getPlayerSoloRankInfo({
        startdate,
        enddate,
        team: result.team[0],
        player: result.player[0].split('_')[1],
      }),
    {
      onSuccess: (data) => {
        const playerinfo = data.response.players[0];
        const { allSummonerNames } = getSoloRankInfo(playerinfo.soloRankInfo);
        const allSummonerNamesState = initializedObjValue(allSummonerNames, false);
        setSummonerNameState(allSummonerNamesState);
      },
    },
  );

  const {
    mutate: getSelectedSoloRankInfo,
    data: detailData,
    isLoading: detailLoading,
    isError: detailError,
    reset: detailReset,
  } = useMutation(
    ({
      selectedSummonerName,
      startdate,
      enddate,
    }: {
      selectedSummonerName: string[];
      startdate: Date;
      enddate: Date;
    }) =>
      PlayerService.getSelectedSoloRankInfo({
        startdate,
        enddate,
        team: result.team[0],
        player: result.player[0].split('_')[1],
        summonerName: selectedSummonerName,
      }),
    {
      onSuccess: (data) => {
        const e = data.response;
        const patchState = initializedObjValue(Object.keys(e), true);
        setPatchState(patchState);
      },
    },
  );

  // 동작함수
  const requestTotalInfo = () => {
    dashboardReset();
    infoReset();
    detailReset();
    getPlayerSoloRankInfo({
      startdate: startDate,
      enddate: endDate,
    });
    getPlayerDashboard({
      champion: '',
      oppchampion: '',
    });
  };
  const requestDetailInfo = () => {
    detailReset();
    getSelectedSoloRankInfo({ selectedSummonerName, startdate: startDate, enddate: endDate });
  };

  // 이벤트 핸들러
  const handleChangeCheck: (e: React.FormEvent<HTMLInputElement>) => void = (e) => {
    // 리그 시즌 패치
    const { name, value, checked } = e.currentTarget;

    switch (name) {
      case 'summonerNameState':
        setSummonerNameState(cb);
        break;
      case 'patch':
        setPatchState(cb);
        break;
      default:
        break;
    }

    function cb(prev: any) {
      const result = { ...prev };
      if (value === 'all') {
        Object.keys(result).forEach((key) => {
          result[key] = checked;
        });
        return result;
      }
      return { ...result, [value]: checked };
    }
  };

  const handleChangeCalendar: (data: Date, type: string) => void = (date, type) => {
    switch (type) {
      case 'start':
        setStartDate(date);
        break;

      case 'end':
        setEndDate(date);
        break;

      default:
        break;
    }
  };

  const handleClickCalendar = (index: number, calc: number) => {
    // react 18 이라서 자동 배칭
    setStartDate(dateRangeService.clacDate(calc));
    setEndDate(dateRangeService.NOW);
    setTab(index);
  };

  const handleClick = () => requestDetailInfo();

  useEffect(() => {
    if (result.league.includes('LCK') && result.player.length > 0) {
      requestTotalInfo();
    }
  }, [result]);

  // 상단부 로딩 및 에러
  const data = playerDashboardData && solorankInfoData;
  const isLoading = playerDashBoardLoading || solorankInfoLoading;
  const isError = playerDashboardError || solorankInfoError;

  // 조건부 렌더링

  if (result.league.includes('LCK') === false) {
    return (
      <NotFound
        css={spacing.paddingY(12.5)}
        text={
          <Text>
            <h3>{t('solo.soloRank.label.onlyLCK')}</h3>
            <p>{t('solo.soloRank.desc.onlyLCK')}</p>
          </Text>
        }
      />
    );
  }

  return (
    <Wrapper>
      {/* Date Range */}
      <WRAPPER_CALENDAR>
        <CalendarRange startDate={startDate} endDate={endDate} onChange={handleChangeCalendar} />

        <CALENDER_CONTROLLER>
          {dateRangeService.SELECT_DATE_BTN_LIST.map((item, index) => (
            <MUITab
              className='btn-tab'
              variant='outlined'
              style={{ color: 'white' }}
              active={tab === index}
              onClick={() => {
                handleClickCalendar(index, item.calc);
              }}
            >
              {t(item.title)}
            </MUITab>
          ))}
          <Button className='btn-trigger' variant='contained' onClick={() => requestTotalInfo()}>
            검색
          </Button>
        </CALENDER_CONTROLLER>
      </WRAPPER_CALENDAR>

      {/* Player info & solorank info */}
      <WRAPPER_TOP>
        {/* 선수 기본 정보  */}
        {isLoading ? (
          <ProgressContainer css={spacing.paddingY(12.5)}>
            <Progress text={t('solo.playerboard.dataFetching')} />
          </ProgressContainer>
        ) : isError ? (
          <NotFound>
            {t('solo.soloRank.modal.desc.noneData').replace('[name]', result.player[0].split('_')[1])}
          </NotFound>
        ) : (
          data && (
            <>
              <PlayerInfo
                playerInfo={playerDashboardData?.response}
                soloRankInfo={solorankInfoData?.response.players[0].soloRankInfo}
              />

              <SolorankIDList
                summonerNameState={summonerNameState}
                handleChange={handleChangeCheck}
                soloRankInfo={solorankInfoData?.response.players[0].soloRankInfo}
              />

              <SButtonContainer>
                <MUIButton
                  variant='contained'
                  disabled={selectedSummonerName.length === 0}
                  onClick={handleClick}
                >
                  {selectedSummonerName.length === 0 ? '선수아이디를 선택하세요' : t('solo.soloRank.confirm')}
                </MUIButton>
              </SButtonContainer>
            </>
          )
        )}
      </WRAPPER_TOP>

      {/* Player solorank detail */}
      <WRAPPER_CENTER>
        {detailLoading ? (
          <ProgressContainer>
            <Progress text={t('solo.playerboard.dataFetching')} />
          </ProgressContainer>
        ) : detailError ? (
          <NotFound>
            {t('solo.soloRank.modal.desc.noneData').replace('[name]', result.player[0].split('_')[1])}
          </NotFound>
        ) : (
          detailData && (
            <SoloRankTable
              patchState={patchState}
              detailData={detailData.response}
              onChange={handleChangeCheck}
            />
          )
        )}
      </WRAPPER_CENTER>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  ${spacing.paddingB(2.5)}
  border-radius: 0 0 10px 10px;
`;

const WRAPPER_CALENDAR = styled.div`
  display: flex;
  margin: 20px 24px;
`;

const CALENDER_CONTROLLER = styled.div`
  width: 100%;
  display: flex;

  .btn-tab {
    font-size: 14px;
    width: auto;
    height: auto;
    padding: 8px 12px;
    margin-right: 4px;
    border-radius: 10px;
    height: 40px;
  }

  .btn-trigger {
    box-shadow: none;
    height: 40px;
    margin-left: 20px;
    flex: 1;
  }
`;

const WRAPPER_TOP = styled.div`
  background-color: ${colors.bg_light};
  ${spacing.paddingX(6)};
  ${spacing.paddingY(5)};
  ${typoStyle.contents_md};
  border-radius: 0 0 10px 10px;
  margin-bottom: -12px;
`;

const WRAPPER_CENTER = styled(WRAPPER_TOP)`
  ${borderRadiusStyle[10]};
  padding: 0;
`;

const MUIButton = MUIStyled(Button)`
  width: 100%;
  height: 60px;
  color: white !important;
  border-radius: 20px;
`;

const MUITab = MUIStyled(Button)<{ active: boolean }>(
  ({ active }) => `
background-color: ${active ? `rgba(89, 66, 186, 0.2)` : colors.bg_select};
border: ${active ? ' 1px solid #5442b0' : '1px solid transparent'};
`,
);

const SButtonContainer = styled.div`
  padding-top: 20px;
`;

const ProgressContainer = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const Text = styled.div`
  text-align: center;
  h3 {
    ${spacing.marginB(5)}
    ${typoStyle.body}
  }
`;

export default PlayerSoloRank;
