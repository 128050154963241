import React, { useContext } from 'react';
import loadable from '@loadable/component';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ModalsStateContext, ModalsDispatchCointext } from './ModalsContext';

// modal screen

const MyModal = loadable(() => import('./screens/MyModal'));
const ModalAlert = loadable(() => import('./screens/ModalAlert'));
const ModalConfirm = loadable(() => import('./screens/ModalConfirm'));
const ModalSolorankID = loadable(() => import('./screens/ModalAddSolorankID'));
const ModalAddTeamPlayer = loadable(() => import('./screens/ModalAddTeamPlayer'));
const ModalAddFavoritePlayer = loadable(() => import('./screens/ModalAddFavoritePlayer'));
const ModalChampionSelect = loadable(() => import('./components/ModalChampionSelect'));
const RemoveScrimResult = loadable(() => import('./components/RemoveScrimResult'));

export const modalList = {
  myModal: MyModal,
  alert: ModalAlert,
  confirm: ModalConfirm,
  addSolorankID: ModalSolorankID,
  addTeamPlayer: ModalAddTeamPlayer,
  addFavoritePlayer: ModalAddFavoritePlayer,
  addChampion: ModalChampionSelect,
  removeResult: RemoveScrimResult,
};

const Modals: React.FC = () => {
  const openedModals = useContext(ModalsStateContext);
  const { close } = useContext(ModalsDispatchCointext);

  return (
    <TransitionGroup>
      {openedModals.map((modal, index) => {
        const { Component, props = {} } = modal;
        const { onSubmit, onCancel, ...restProps } = props;

        const onClose = () => {
          close(Component);
        };

        const handleCancel = async () => {
          if (typeof onCancel === 'function') {
            await onCancel();
          }
          onClose();
        };

        const handleSubmit = async (e) => {
          if (typeof onSubmit === 'function') {
            await onSubmit(e);
          }
          // 모달 닫기
          onClose();
        };

        return (
          // eslint-disable-next-line react/no-array-index-key
          <CSSTransition key={`Modal${index}`} classNames='item' timeout={200}>
            <Component onClose={onClose} onCancel={handleCancel} onSubmit={handleSubmit} {...restProps} />
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
};

export default Modals;
