import { atom } from 'recoil';
import { FilterData, IFilterSeason } from './filter.type';

interface PathAnalysisParams {
  year: string[];
  league: string[];
  season: string[];
  patch: string[];
  team: string[];
}

const PathAnalysisAtom = {
  years: atom<FilterData>({
    key: 'PathAnalysisYear',
    default: {},
  }),

  leagues: atom<FilterData>({
    key: 'PathAnalysisLeague',
    default: {},
  }),

  seasons: atom<IFilterSeason>({
    key: 'PathAnalysisSeason',
    default: {},
  }),
  teams: atom<FilterData>({
    key: 'PathAnalysisTeam',
    default: {},
  }),
  // patchs: atom<FilterData>({
  //   key: 'PathAnalysisPatch',
  //   default: {},
  // }),
};

export const PathAnalysisResultAtom = atom<PathAnalysisParams | undefined>({
  key: 'PathingAnalysisResult',
  default: undefined,
});

export default PathAnalysisAtom;
