/**
 *
 * @param total 전체경기수
 * @param pick 전체 픽 횟수
 * @param win 전체 승리 횟수
 * @desc 전체게임중에 해당 챔피언이 픽 + 밴 된 확률을 구합니다.
 */
export function getChampionScore(total: any, pick: any, bans: any, win: any) {
  if (
    Number.isNaN(Number(total)) ||
    Number.isNaN(Number(pick)) ||
    Number.isNaN(Number(bans)) ||
    Number.isNaN(Number(win))
  ) {
    console.warn('total, pick, win,bans 타입 확인');
    return 0;
  }
  return (
    ((Number(bans) / Number(total)) * 0.65 +
      (Number(pick) / Number(total)) * 0.45 +
      (Number(win) / Number(pick)) * 0.1) *
    50
  );
}
