import { atom } from 'recoil';
import { FilterData, IFilterSeason } from './filter.type';

interface TeamReportParams {
  league: string[];
  year: string[];
  season: string[];
  patch: string[];
  team: string[];
}

const TeamReportAtom = {
  years: atom<FilterData>({
    key: 'TeamReportYear',
    default: {},
  }),

  leagues: atom<FilterData>({
    key: 'TeamReportLeague',
    default: {},
  }),

  seasons: atom<IFilterSeason>({
    key: 'TeamReportSeason',
    default: {},
  }),

  // patchs: atom<FilterData>({
  //   key: 'TeamReportPatch',
  //   default: {},
  // }),

  teams: atom<FilterData>({
    key: 'TeamReportTeam',
    default: {},
  }),
};

export const TeamReportResultAtom = atom<TeamReportParams>({
  key: 'TeamReportResult',
  default: undefined,
});

export default TeamReportAtom;
