/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React, { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { Link, Element, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import { useRecoilValue } from 'recoil';
import addZero from '../../../lib/addZero';
import DayBox from './SubContainer/DayBox';
import { SetDesc, SetIsOpen } from '../../../redux/modules/modalvalue';
import getMonthDayList from '../../../lib/Calendar/getMonthDayList';
import getFirstDay from '../../../lib/Calendar/getFirstDay';
import getMonthWeeks from '../../../lib/Calendar/getMonthWeeks';
import getLeafYaer from '../../../lib/Calendar/getLeafYear';
import { colors } from '../../../Styles/ui';
import { LeagueReportResultAtom } from '../../../recoil/Filter/leagueReportAtom';
import checkClick from '../../../lib/Calendar/checkClick';
import { userLanguageAtom } from '../../../recoil/Auth/userAtom';
import { RootState } from '../../../redux/modules';
import { ICalendar } from './calendar.type';

const weekDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
const START_DATE = 'START_DATE';
const END_DATE = 'END_DATE';

function CalendarFilter() {
  const result = useRecoilValue(LeagueReportResultAtom);
  const queryClient = useQueryClient();
  const { data: calendar } = useQuery<ICalendar>('calendar');
  const topFilter = useSelector((state: RootState) => state.JungleMapReducer);
  const lang = useRecoilValue(userLanguageAtom);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const year = result?.year.length > 0 ? result.year : topFilter.year;
  const firstDays = getFirstDay(year);
  const leapYear = getLeafYaer(year);
  const monthDays = getMonthDayList(leapYear);

  const [activeLabel, setActiveLabel] = useState(START_DATE);
  const [selectStartIdx, setSelectStartIdx] = useState('');
  const [selectEndIdx, setSelectEndIdx] = useState('');
  const [selectStartValue, setSelectStartValue] = useState('');
  const [selectEndValue, setSelectEndValue] = useState('');
  const [month, setMonth] = useState(new Date().getUTCMonth() + 1);
  const [lock, setLock] = useState(false);

  const getIdx = (idx: number, idx2: number) => {
    return idx * 7 + idx2 - (firstDays.getDay() - 1);
  };

  const getCalendarInfo: (
    idx: number,
    idx2: number,
  ) => { year: number; day: number; month: number; index: number; match: string[] } = (idx, idx2) => {
    let total = 0;
    const index = getIdx(idx, idx2);
    if (calendar) {
      const { info } = calendar!;
      for (let i = 0; monthDays.length; i += 1) {
        if (index > total + monthDays[i] - 1) {
          total += monthDays[i];
        } else {
          return {
            month: i + 1,
            day: index - total,
            year,
            index,
            match: info
              ? info
                  .filter((arr) => arr.index === index)
                  .map((data) => data.matchs)
                  .flat()
              : [],
          };
        }
      }
    }
    return {
      year,
      month: -1,
      day: -1,
      index,
      match: [],
    };
  };

  const autoMoveScroll = (idx: number) => {
    if (idx > 0) {
      scroller.scrollTo(`week-${idx}`, {
        duration: 300,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'calendar-body',
      });
    } else {
      scroll.scrollToTop({
        duration: 300,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'calendar-body',
      });
    }
  };

  const handleSetActive = (to: string) => {
    if (lock) {
      return;
    }
    let currentIdx = (+to.split('-')[1] + 1) * 7 - (firstDays.getDay() - 1);

    for (let i = 0; i < monthDays.length; i += 1) {
      if (currentIdx - monthDays[i] >= 0) {
        currentIdx -= monthDays[i];
      } else {
        setMonth(i);
        return;
        //
      }
    }
  };

  const moveLock = () => {
    setLock(true);
    setTimeout(() => setLock(false), 500);
  };

  useEffect(() => {
    if (!calendar) return;
    if (calendar.isOpen) {
      scrollSpy.update();
      moveLock();

      if (calendar.seasonStartDate) {
        const date = calendar.seasonStartDate.split('-');
        console.log(
          'date',
          getMonthWeeks(+date[1] - 1, monthDays, firstDays, +firstDays.getDay() - 1 + +date[2]),
        );

        setMonth(+date[1] - 1);
        autoMoveScroll(
          getMonthWeeks(+date[1] - 1, monthDays, firstDays, +firstDays.getDay() - 1 + +date[2]) - 1,
        );
      } else {
        autoMoveScroll(getMonthWeeks(month, monthDays, firstDays));
      }
    }
  }, [calendar?.isOpen]);

  const CancelDays = (active: string) => {
    if (active === START_DATE) {
      setSelectStartIdx('');
      setSelectStartValue('');
      setActiveLabel(START_DATE);
    } else if (active === END_DATE) {
      setSelectEndIdx('');
      setSelectEndValue('');
      setActiveLabel(END_DATE);
    }
  };

  const moveMonth = (month: number) => {
    if (!calendar) return;
    if (
      checkClick(
        getIdx(getMonthWeeks(month, monthDays, firstDays), 1),
        year,
        calendar.seasonStartDate,
        calendar.seasonEndDate,
      )
    ) {
      setMonth(month);
      moveLock();
    }
  };

  const toMove = (month: number) => {
    if (!calendar) return 'week-0';
    if (
      checkClick(
        getIdx(getMonthWeeks(month, monthDays, firstDays), 1),
        year,
        calendar!.seasonStartDate,
        calendar!.seasonEndDate,
      )
    ) {
      return `week-${getMonthWeeks(month, monthDays, firstDays)}`;
    }
    return month > 0
      ? `week-${getMonthWeeks(+calendar!.seasonEndDate.split('-')[1] - 1, monthDays, firstDays)}`
      : 'week-0';
  };

  return (
    <SCalendarFilter active={calendar?.isOpen}>
      <SCalendarContainer>
        <div className='header'>
          <div className='date-info'>
            <Link
              activeClass='active'
              className='go-to-pre-month'
              to={toMove(month - 1)}
              smooth
              duration={100}
              containerId='calendar-body'
              onClick={() => {
                moveMonth(month - 1);
              }}
            >
              <img src='/Images/ic-pre.svg' alt='pre' />
            </Link>
            <div className='date-view'>
              {lang === 'ko'
                ? `${year}${t('common.date.year')} ${addZero(month + 1)}${t('common.date.month')}`
                : `${addZero(month + 1)}, ${year}`}
            </div>
            <Link
              activeClass='active'
              className='go-to-next-month'
              to={toMove(month + 1)}
              smooth
              duration={100}
              containerId='calendar-body'
              onClick={() => {
                moveMonth(month + 1);
              }}
            >
              <img src='/Images/ic-next.svg' alt='next' />
            </Link>
            <SCalendarDaysInput
              isActive={activeLabel === START_DATE}
              onClick={() => {
                setActiveLabel(START_DATE);
              }}
            >
              <input
                className='calendar-input'
                type='text'
                placeholder={t('utility.calendarFilter.inputStart')}
                value={selectStartValue}
                readOnly
              />
              <img
                className='calendar-icon'
                src='/Images/ic-cancle.svg'
                alt=''
                onClick={() => {
                  CancelDays(START_DATE);
                }}
                onKeyUp={() => {
                  CancelDays(START_DATE);
                }}
              />
            </SCalendarDaysInput>
            <div className='hyphen'>-</div>
            <SCalendarDaysInput
              isActive={activeLabel === END_DATE}
              onClick={() => {
                setActiveLabel(END_DATE);
              }}
            >
              <input
                className='calendar-input'
                type='text'
                placeholder={t('utility.calendarFilter.inputEnd')}
                value={selectEndValue}
                readOnly
              />
              <img
                className='calendar-icon'
                src='/Images/ic-cancle.svg'
                alt=''
                onClick={() => {
                  CancelDays(END_DATE);
                }}
                onKeyUp={() => {
                  CancelDays(END_DATE);
                }}
              />
            </SCalendarDaysInput>
          </div>

          <img
            className='close-btn'
            src='/Images/ic_close_bk_30.svg'
            alt='close'
            onClick={() => {
              queryClient.setQueryData(['calendar'], {
                ...calendar,
                isOpen: false,
              });
            }}
            onKeyUp={() => {
              queryClient.setQueryData(['calendar'], {
                ...calendar,
                isOpen: false,
              });
            }}
          />
        </div>
        <div className='day-of-the-week'>
          {weekDays.map((day, idx) => {
            return <div className='day'>{t(`common.date.weekDays.${day}`)}</div>;
          })}
        </div>
        <CalendarBody id='calendar-body'>
          {[...Array(Math.floor((365 + (leapYear ? 1 : 0) + firstDays.getDay() - 1) / 7) + 1)].map(
            (week, idx) => {
              return (
                <Element name={`week-${idx}`} className='element'>
                  <Link
                    activeClass='active'
                    className='test1'
                    to={`week-${idx}`}
                    spy
                    containerId='calendar-body'
                    onSetActive={handleSetActive}
                  />
                  <div className='calendar-days'>
                    {[...Array(7)].map((day, idx2) => {
                      return (
                        <DayBox
                          info={getCalendarInfo(idx, idx2)}
                          activeLabel={activeLabel}
                          selectStartIdx={selectStartIdx}
                          selectEndIdx={selectEndIdx}
                          setActiveLabel={setActiveLabel}
                          setSelectStartIdx={setSelectStartIdx}
                          setSelectEndIdx={setSelectEndIdx}
                          setSelectStartValue={setSelectStartValue}
                          setSelectEndValue={setSelectEndValue}
                        />
                      );
                    })}
                  </div>
                </Element>
              );
            },
          )}
        </CalendarBody>
        <SCalendarConfirm
          isSelect={selectStartIdx !== '' && selectEndIdx !== ''}
          onClick={() =>
            selectStartIdx !== '' && selectEndIdx !== ''
              ? queryClient.setQueryData(['calendar'], {
                  ...calendar,
                  startDate: selectStartValue,
                  endDate: selectEndValue,
                  startDayIdx: selectStartIdx,
                  endDayIdx: selectEndIdx,
                  isOpen: false,
                })
              : batch(() => {
                  dispatch(
                    SetDesc(
                      t(
                        `utility.calendarFilter.desc.${
                          activeLabel === START_DATE ? 'noStartIdx' : 'noEndIdx'
                        }`,
                      ),
                    ),
                  );
                  dispatch(SetIsOpen(true));
                })
          }
        >
          <div className='label'>{t('utility.calendarFilter.Confirm')}</div>
        </SCalendarConfirm>
      </SCalendarContainer>
    </SCalendarFilter>
  );
}

export default CalendarFilter;

const SCalendarFilter = styled.div<{ active: boolean | undefined }>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1;
`;

const SCalendarContainer = styled.div`
  width: 1200px;
  height: 90vh;
  padding: 0 0;
  border-radius: 20px;
  background-color: ${colors.bg_select};

  .header {
    width: 100%;
    height: 116px;
    padding: 10px 10px 40px 33px;
    display: flex;
    position: relative;

    .date-info {
      width: 100%;
      height: 36px;
      top: 30px;
      position: absolute;
      display: flex;

      .go-to-next-month {
        margin-right: 20px;
      }

      .hyphen {
        width: 16px;
        height: 40px;
        padding: 13px 5px 13px 6px;

        font-family: SpoqaHanSans;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: ${colors.text};
      }

      .date-view {
        width: 180px;
        height: 31px;
        margin: 2px 0 2px;
        font-family: SpoqaHanSansNeo;
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: ${colors.text};
        white-space: nowrap;

        img {
          width: 36px;
          height: 36px;
          object-fit: contain;
        }
      }
    }
  }

  .day-of-the-week {
    width: 1200px;
    height: 19px;
    margin: 1px 0 0;
    padding: 0 23px;
    display: flex;
    .day {
      width: 145px;
      height: 19px;
      margin: 0 10px;
      font-family: SpoqaHanSansNeo;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: ${colors.text};
    }
  }
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
  }

  .calendar-days {
    margin: 0 23px;
    display: flex;
  }

  .confirm {
  }
`;

const CalendarBody = styled.div`
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #434050;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    margin: 5px;
  }
  width: 100%;
  height: calc(90vh - 244px);
  padding: 4px 0 0;
  overflow-y: scroll;
`;

const SCalendarConfirm = styled.div<{ isSelect: boolean }>`
  width: 1160px;
  height: 60px;
  margin: 20px;
  border-radius: 20px;
  background-color: ${({ isSelect }) => (isSelect ? colors.point : colors.btn_nor)};

  .label {
    line-height: 60px;
  }
  font-family: SpoqaHanSansNeo;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.text};
`;

const SCalendarDaysInput = styled.div<{ isActive: boolean }>`
  width: 150px;
  height: 37px;
  margin: -1px 0px 0 1px;
  border-radius: 20px;
  border: solid 3px ${({ isActive }) => (isActive ? '#7056d9' : 'rgba(0,0,0,0)')};
  background-color: ${colors.bg_box};
  padding: 3px 7px 3px 15px;
  font-family: SpoqaHanSansNeo;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.text};

  display: flex;
  position: relative;

  .calendar-input {
    margin-top: 2px;
    width: 78px;
    cursor: pointer;
    color: ${colors.text};
  }

  .calendar-icon {
    width: 34px;
    height: 34px;
    margin-top: -3px;
    position: absolute;
    right: 7px;
    cursor: pointer;
  }
`;
