import React, { useState, useEffect, useRef } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { useRecoilValue } from 'recoil';
import TabforTop from './TabforTop';
import CalendarFilterNav from '../../../Components/Filter/Calendar/CalendarFilterNav';
import { LeagueReportResultAtom } from '../../../recoil/Filter/leagueReportAtom';
import LeagueServices from '../../../services/LeagueServices';
import Progress from '../../../Components/Ui/Loading/Progress';
import NotFound from '../../../Components/Ui/Error/NotFound';
import { ICalendar } from '../../../Components/Filter/Calendar/calendar.type';

type Position = 'top' | 'jng' | 'mid' | 'bot' | 'sup';
interface Pick {
  position: Position;
  startTime: string;
  endTime: string;
}

const POSITON_TAB: { title: Position }[] = [
  { title: 'top' },
  { title: 'jng' },
  { title: 'mid' },
  { title: 'bot' },
  { title: 'sup' },
];

const LeaguePick = () => {
  const { data: calendar } = useQuery<ICalendar>('calendar');
  const result = useRecoilValue(LeagueReportResultAtom);

  const [queryPosition, setQueryPosition] = useState<Position>('top');
  // 리그 스케줄 받아오기

  const {
    data: leaguePickData,
    mutate,
    isLoading,
    isError,
  } = useMutation(({ position, startTime, endTime }: Pick) =>
    LeagueServices.getPickData({
      ...result,
      position,
      startTime,
      endTime,
    }),
  );

  console.log('leaguePickData', leaguePickData);

  useEffect(() => {
    if (!calendar) return;
    if (!calendar.startDate || !calendar.endDate) return;

    mutate({
      position: queryPosition,
      startTime: calendar.startDate,
      endTime: calendar.endDate,
    });
  }, [calendar, queryPosition]);

  const { t } = useTranslation();

  return (
    <LeaguePickWrapper>
      <CalendarFilterNav result={result} />
      <LeaguePickTabs>
        {POSITON_TAB.map((item) => (
          <TabItem
            key={`pick${item.title}`}
            onClick={() => setQueryPosition(item.title)}
            changeColor={queryPosition === item.title}
          >
            <div>
              <span>{t(`position.${item.title}`)}</span>
            </div>
          </TabItem>
        ))}
      </LeaguePickTabs>
      <TabContents>
        {isLoading ? (
          <ProgressContainer>
            <Progress text={t('solo.playerboard.dataFetching')} />
          </ProgressContainer>
        ) : isError ? (
          <NotFound css={{ height: '100%' }} text={t('solo.playerboard.dataFetchingFailed')} />
        ) : (
          leaguePickData && (
            <TabforTop
              importantPicks={leaguePickData.response.importantPick}
              pickDifference={leaguePickData.response.pickDiff}
              uniquePick={leaguePickData.response.uniquePick}
              tier={leaguePickData.response.championTier}
            />
          )
        )}
      </TabContents>
    </LeaguePickWrapper>
  );
};

export default LeaguePick;

const LeaguePickWrapper = styled.div``;

const LeaguePickTabs = styled.div`
  display: flex;
  height: 62px;
  width: 1110px;
  border-bottom: 1px solid #433f4e;
`;

const TabItem = styled.button<{ changeColor: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  white-space: nowrap;

  div {
    padding: 10px 15px;
  }

  :hover {
    div {
      padding: 10px 15px;
      border-radius: 10px;
      background-color: #26262c;
    }
  }

  span {
    height: 22px;
    font-family: SpoqaHanSansNeo;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 18px;
    border-bottom: solid 1px ${(props) => (props.changeColor ? `#fff` : `none`)};
    color: ${(props) => (props.changeColor ? `#fff` : `#84818e`)};
  }
`;

const TabContents = styled.div`
  width: 1200px;
  height: 836px;
`;
const ProgressContainer = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;
