import { Box, styled, Typography } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { t } from 'i18next';
import React from 'react';
import { useRecoilValue } from 'recoil';
import ChampionImg from '../../../Components/Champion/ChampionImg';
import ChampionString from '../../../Components/Champion/ChampionString';
import { TRANSLATE_SCRIM_TITLE } from '../../../lib/translation/translate_scrim';
import { userLanguageAtom } from '../../../recoil/Auth/userAtom';
import { ChampionListAtom } from '../../../recoil/common/championListAtom';
import { colors, scrollbarStyle, spacing, typoStyle } from '../../../Styles/ui';
import { CustomColumnMenuComponent } from './CustomDataGrid';

type ChampionData = {
  champion: number;
  picks: number;
  teamPicks: number;
  teamWins: number;
  wins: number;
};

type MatchUpData = {
  champion: ChampionData;
  oppChampion: ChampionData;
};

interface ScrimMatchUpProps {
  data: MatchUpData[];
}

type WidthOptions = {
  width?: number;
  flex?: number;
};

type RowDataset = GridValidRowModel[];

const makeCellData = {
  경기수: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'total',
    headerName: t(TRANSLATE_SCRIM_TITLE.경기수),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    disableColumnMenu: true,
    cellClassName: 'is-important',
    ...widthOptions,
  }),

  left챔피언: (championList: any, lang: string, widthOptions?: WidthOptions): GridColDef => ({
    field: 'leftChampion',
    headerName: t(TRANSLATE_SCRIM_TITLE.champion),
    headerAlign: 'right',
    align: 'right',
    disableColumnMenu: true,
    filterable: false,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return lang === 'ko'
        ? championList[params.row.leftChampion].ko
        : championList[params.row.leftChampion].en;
    },
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
          <ChampionString championId={params.row.leftChampion} sx={{ fontSize: 15 }} />
          <ChampionImg
            championId={params.row.leftChampion}
            sx={{
              borderRadius: 0,
              width: 40,
              height: 40,
              marginLeft: 2,
            }}
          />
        </Box>
      );
    },
    ...widthOptions,
  }),
  left승리: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'leftWins',
    headerName: t(TRANSLATE_SCRIM_TITLE.win),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    ...widthOptions,
  }),
  left패배: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'leftLoses',
    headerName: t(TRANSLATE_SCRIM_TITLE.lose),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => params.row.total - params.row.leftWins,
    ...widthOptions,
  }),
  left승률: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'leftWinRate',
    headerName: t(TRANSLATE_SCRIM_TITLE.winRate),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    ...widthOptions,
    valueGetter: (params: GridValueGetterParams) => {
      return (Number(params.row.leftWins / params.row.total) * 100).toFixed(0);
    },
    valueFormatter: (params: GridValueFormatterParams) => `${params.value}%`,
  }),
  left우리팀경기수: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'leftTeamTotal',
    headerName: t(TRANSLATE_SCRIM_TITLE.우리팀경기수),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    disableColumnMenu: true,
    ...widthOptions,
  }),
  left우리팀승리: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'leftTeamWins',
    headerName: t(TRANSLATE_SCRIM_TITLE.우리팀승),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    ...widthOptions,
  }),
  left우리팀패배: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'leftTeamLoses',
    headerName: t(TRANSLATE_SCRIM_TITLE.우리팀패),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => params.row.leftTeamTotal - params.row.leftTeamWins,
    ...widthOptions,
  }),
  left우리팀승률: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'leftTeamWinRate',
    headerName: t(TRANSLATE_SCRIM_TITLE.우리팀승률),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const value = Number(params.row.leftTeamWins / params.row.leftTeamTotal);
      if (Number.isNaN(value)) {
        return '0';
      }

      return (value * 100).toFixed(0);
    },
    valueFormatter: (params: GridValueFormatterParams) =>
      Number.isNaN(params.value) ? '-' : `${params.value}%`,
    ...widthOptions,
  }),

  vs: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'VS',
    headerName: 'VS',
    minWidth: 40,
    headerClassName: 'is-transparent',
    cellClassName: 'is-vs',
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    valueGetter: () => 'VS',
    ...widthOptions,
  }),

  right챔피언: (championList: any, lang: string, widthOptions?: WidthOptions): GridColDef => ({
    field: 'rightChampion',
    headerName: t(TRANSLATE_SCRIM_TITLE.champion),
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params: GridValueGetterParams) => {
      return lang === 'ko'
        ? championList[params.row.rightChampion].ko
        : championList[params.row.rightChampion].en;
    },
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ChampionImg
            championId={params.row.rightChampion}
            sx={{
              borderRadius: 0,
              width: 40,
              height: 40,
              marginRight: 1,
            }}
          />
          <ChampionString championId={params.row.rightChampion} sx={{ fontSize: 15 }} />
        </Box>
      );
    },
    ...widthOptions,
  }),
  right승리: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'rightWins',
    headerName: t(TRANSLATE_SCRIM_TITLE.win),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    ...widthOptions,
  }),
  right패배: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'rightLoses',
    headerName: t(TRANSLATE_SCRIM_TITLE.lose),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => params.row.total - params.row.rightWins,
    ...widthOptions,
  }),
  right승률: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'rightWinRate',
    headerName: t(TRANSLATE_SCRIM_TITLE.winRate),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    ...widthOptions,
    valueGetter: (params: GridValueGetterParams) => {
      return (Number(params.row.rightWins / params.row.total) * 100).toFixed(0);
    },
    valueFormatter: (params: GridValueFormatterParams) => `${params.value}%`,
  }),
  right우리팀경기수: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'rightTeamTotal',
    headerName: t(TRANSLATE_SCRIM_TITLE.우리팀경기수),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    disableColumnMenu: true,
    ...widthOptions,
  }),
  right우리팀승리: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'rightTeamWins',
    headerName: t(TRANSLATE_SCRIM_TITLE.우리팀승),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    ...widthOptions,
  }),
  right우리팀패배: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'rightTeamLoses',
    headerName: t(TRANSLATE_SCRIM_TITLE.우리팀패),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => params.row.rightTeamTotal - params.row.rightTeamWins,
    ...widthOptions,
  }),
  right우리팀승률: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'rightTeamWinRate',
    headerName: t(TRANSLATE_SCRIM_TITLE.우리팀승률),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const value = Number(params.row.rightTeamWins / params.row.rightTeamTotal);
      if (Number.isNaN(value)) {
        return '0';
      }

      return (value * 100).toFixed(0);
    },
    valueFormatter: (params: GridValueFormatterParams) =>
      Number.isNaN(params.value) ? '-' : `${params.value}%`,
    ...widthOptions,
  }),
};

const ScrimMatchUp: React.FC<ScrimMatchUpProps> = ({ data }) => {
  const championList = useRecoilValue(ChampionListAtom);
  const lang = useRecoilValue(userLanguageAtom);

  const ROWS: RowDataset | '' =
    lang &&
    championList &&
    data?.map((item, index) => {
      return {
        id: `${item.champion.champion}${item.oppChampion.champion}${index}`,
        total: item.champion.picks,
        // 왼쪽 챔피언 정보
        leftChampion: item.champion.champion,
        leftWins: item.champion.wins,
        leftTeamTotal: item.champion.teamPicks,
        leftTeamWins: item.champion.teamWins,
        // 오른쪽 챔피언 정보
        rightChampion: item.oppChampion.champion,
        rightWins: item.oppChampion.wins,
        rightTeamTotal: item.oppChampion.teamPicks,
        rightTeamWins: item.oppChampion.teamWins,
      };
    });

  const COLS: GridColDef[] = [
    makeCellData.경기수({ flex: 1.4 }),
    makeCellData.left승리({ flex: 0.7 }),
    makeCellData.left패배({ flex: 0.7 }),
    makeCellData.left승률({ flex: 1 }),
    makeCellData.left우리팀경기수({ flex: 1.3 }),
    makeCellData.left우리팀승리({ flex: 1.3 }),
    makeCellData.left우리팀패배({ flex: 1.3 }),
    makeCellData.left우리팀승률({ flex: 1.3 }),
    makeCellData.left챔피언(championList, lang, { flex: 2.2 }),
    makeCellData.vs({ flex: 0.2 }),
    makeCellData.right챔피언(championList, lang, { flex: 2.2 }),
    makeCellData.right승리({ flex: 0.7 }),
    makeCellData.right패배({ flex: 0.7 }),
    makeCellData.right승률({ flex: 1 }),
    makeCellData.right우리팀경기수({ flex: 1.3 }),
    makeCellData.right우리팀승리({ flex: 1.3 }),
    makeCellData.right우리팀패배({ flex: 1.3 }),
    makeCellData.right우리팀승률({ flex: 1.3 }),
  ];

  return (
    <div>
      <TITLE variant='h3'>{t('scrim.banPick.headToheadStats')}</TITLE>{' '}
      <Box sx={{ height: 253, width: '100%' }}>
        {ROWS && (
          <STYLEDDATAGRID
            sx={{
              border: 'none',
              color: 'white',
            }}
            hideFooter
            disableColumnMenu
            columns={COLS}
            rows={ROWS}
            pageSize={ROWS.length}
            headerHeight={44}
            rowHeight={40}
            disableSelectionOnClick
            components={{
              ColumnMenu: CustomColumnMenuComponent,
            }}
          />
        )}
      </Box>
    </div>
  );
};
const TITLE = styled(Typography)`
  ${typoStyle.percent};
  ${spacing.marginB(2)};
`;
const STYLEDDATAGRID = styled(DataGridPro)`
  min-width: 1200px;
  /* 헤더 */
  .MuiDataGrid-columnHeaders {
    border-bottom: 0;
    background-color: ${colors.배경색[800]};
  }

  .MuiDataGrid-columnHeader {
    height: 100%;
    padding: 0 4px;
    font-size: 13px;
    &:focus {
      outline: none;
    }
    &.is-transparent {
      color: transparent;
    }
  }
  .MuiDataGrid-columnHeader:focus-within {
    outline: none;
  }

  .MuiDataGrid-columnHeaderTitle {
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    line-height: 16px;
    outline: none;
    text-align: center;
  }

  /* 정렬 아이콘 */
  .MuiDataGrid-sortIcon {
    color: white;
    width: 16px;
    height: 16px;
  }

  .MuiDataGrid-filterIcon {
    color: white;
  }

  /* col - 구분선 */
  .MuiDataGrid-columnSeparator {
    display: none;
  }

  /* 전체 스크롤 바디 */
  .MuiDataGrid-virtualScroller {
    ${scrollbarStyle.scroll_4}
  }

  /* row */
  .MuiDataGrid-row {
    border-radius: 4px;
    border: none;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  /* col cell */
  .MuiDataGrid-cell {
    border-bottom: 0;
    padding: 0;
    &.is-vs {
      width: 30px;
      font-size: 13px;
      color: ${colors.배경색[50]};
    }
    &.is-important {
      background-color: rgba(89, 66, 186, 0.2);
    }
    &:focus {
      outline: 0;
    }
  }
`;

export default ScrimMatchUp;
