import React, { memo, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import EachMatch from './EachMatch';
import { ProGameReportResultAtom } from '../../../recoil/Filter/proGameReportAtom';
import GameServices, { GameIndexDataParam, GameInfo } from '../../../services/GameServices';
import { colors } from '../../../Styles/ui';
import Progress from '../../../Components/Ui/Loading/Progress';

const GameReportIndex = () => {
  const result = useRecoilValue(ProGameReportResultAtom);
  const [indexData, setIndexData] = useState<GameInfo[]>();
  const { t } = useTranslation();
  const { mutate, data, isLoading } = useMutation((data: GameIndexDataParam) =>
    GameServices.getGameIndexData(data),
  );

  useEffect(() => {
    mutate(result);
  }, [result]);

  useEffect(() => {
    if (!data) return;
    setIndexData(data.response.game);
  }, [data]);

  if (isLoading) {
    return (
      <ProgressContainer>
        <Progress text={t('solo.playerboard.dataFetching')} />
      </ProgressContainer>
    );
  }

  return (
    <GameReportIndexWrapper>
      {indexData?.map((match, idx) => {
        return <EachMatch matchData={match} team={result.team} key={'match' + idx} />;
      })}
    </GameReportIndexWrapper>
  );
};

export default memo(GameReportIndex);

const GameReportIndexWrapper = styled.main`
  padding-top: 60px;
  font-family: 'SpoqaHanSansNeo';
`;

const ProgressContainer = styled.div`
  width: ${1110}px;
  height: 160px;
  margin: 20px 0 0;
  padding: 0 30px;
  border-radius: 20px;
  background-color: ${colors.bg_select};
  display: flex;
  align-items: center;
  justify-content: center;
`;
