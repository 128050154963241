/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useEffect } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';
import LeagueServices from '../../../services/LeagueServices';
import { barOptions, lineOptions } from '../../../Styles/chart/option';
import { LeagueReportResultAtom } from '../../../recoil/Filter/leagueReportAtom';
import { spacing } from '../../../Styles/ui';
import CustomTooltip from '../../../Components/Ui/Tooltip/CustomTooltip';
import { TRANSLATE_TOOLTIP } from '../../../lib/translation/Tooltip/translate_Tooltip';
import { TotalParams } from '../../../services/types/league_services.type';

const DATASET_STYLE = {
  fill: false,
  lineTension: 0,
  backgroundColor: '#f04545',
  borderColor: '#f04545',
  borderWidth: 2,
  bodySpacing: 3,
};

function LeagueStatistics() {
  // 리그 통합 지수 텝
  const { t } = useTranslation();
  const result = useRecoilValue(LeagueReportResultAtom);
  const { mutate, data } = useMutation((data: TotalParams) => LeagueServices.getLeagueTotal(data));

  useEffect(() => {
    mutate(result);
  }, [result]);

  // 평균 게임시간 그래프 세팅
  const averageGameTime = data?.response.avgGamelength && {
    labels: data.response.avgGamelength.data.map((item) => item.patch),
    datasets: [
      {
        ...DATASET_STYLE,
        data: data.response.avgGamelength.data.map((item) => item.gamelength),
      },
    ],
  };

  // 라인 별 서포팅 시간 그래프 세팅
  const averageSupport = data?.response.avgSupportedTime && {
    labels: data.response.avgSupportedTime.data.map((item) => item.position),
    datasets: [
      {
        ...DATASET_STYLE,
        data: data.response.avgSupportedTime.data.map((item) => item.supported_time),
      },
    ],
  };

  // 평균 교전 횟수 그래프 세팅
  const firstFight = data?.response.avgMatchTotal && {
    labels: data.response.avgMatchTotal.data.map((item) => item.patch),
    datasets: [
      {
        ...DATASET_STYLE,
        data: data.response.avgMatchTotal.data.map((item) => item.match_total),
      },
    ],
  };

  // 평균 첫 갱 시간 그래프 세팅
  const averageGank = data?.response.avgFirstGang && {
    labels: data.response.avgFirstGang.data.map((item) => item.patch),
    datasets: [
      {
        ...DATASET_STYLE,
        data: data.response.avgFirstGang.data.map((item) => item.first_gang_time),
      },
    ],
  };

  return (
    <StatisticsWrapper>
      {/* 위 */}
      <TOP_BOX>
        {/* 평균 게임 시간 */}
        <CHART_CONTEINER>
          <CHART_TITLE>
            <CustomTooltip title={t(TRANSLATE_TOOLTIP.리그보고서.평균게임시간)} placement='top'>
              <h6>
                <span>{t('league.leagueStat.gameLength')}</span>
              </h6>
            </CustomTooltip>
            <div className='Legend'>
              <p className='X'>X {t('league.leagueStat.patch')}</p>
              <p className='Y'>Y {t('league.leagueStat.avgLength')}</p>
            </div>
          </CHART_TITLE>
          <CHART_CONTENT>
            {result.league.includes('LPL') ? (
              <NO_DATA>{t('errorControllerMsg.leagueStatisticsLPLError')}</NO_DATA>
            ) : !data?.response.avgGamelength || //
              !averageGameTime ||
              data.response.avgGamelength.data.some((item) => Number.isNaN(item.gamelength)) ||
              data.response.avgGamelength.max === 0 ? (
              <NO_DATA>{t('league.leagueStat.noData2')}</NO_DATA>
            ) : (
              <Line
                data={averageGameTime}
                options={{
                  ...lineOptions,
                  scales: {
                    ...lineOptions.scales,
                    y: {
                      ...lineOptions.scales.y,
                      min: data?.response.avgGamelength.min,
                      max: data?.response.avgGamelength.max,
                    },
                  },
                }}
              />
            )}
          </CHART_CONTENT>
        </CHART_CONTEINER>

        {/* 라인별 평균 서포팅 시간 */}
        <CHART_CONTEINER>
          <CHART_TITLE>
            <CustomTooltip title={t(TRANSLATE_TOOLTIP.리그보고서.라인별평균서포팅시간)} placement='top'>
              <h6>
                <span>{t('league.leagueStat.pressure')}</span>
              </h6>
            </CustomTooltip>
            <div className='Legend'>
              <p className='X'>X {t('league.leagueStat.lane')}</p>
              <p className='Y'>Y {t('league.leagueStat.yPressure')}</p>
            </div>
          </CHART_TITLE>
          <CHART_CONTENT>
            {result.league.includes('LPL') ? (
              <NO_DATA>{t('errorControllerMsg.leagueStatisticsLPLError')}</NO_DATA>
            ) : !data?.response.avgSupportedTime ||
              !averageSupport ||
              data.response.avgSupportedTime.data.some((item) => Number.isNaN(item.supported_time)) ||
              data.response.avgSupportedTime.max === 0 ? (
              <NO_DATA>{t('league.leagueStat.noData2')}</NO_DATA>
            ) : (
              <Bar
                data={averageSupport}
                options={{
                  ...barOptions,
                  scales: {
                    ...barOptions.scales,
                    y: {
                      ...barOptions.scales.y,
                      min: data?.response.avgSupportedTime.min,
                      max: data?.response.avgSupportedTime.max,
                    },
                  },
                }}
              />
            )}
          </CHART_CONTENT>
        </CHART_CONTEINER>
      </TOP_BOX>

      {/* 아래 */}
      <BOTTOM_BOX>
        {/* 평균 교전 횟수 */}
        <CHART_CONTEINER>
          <CHART_TITLE>
            <CustomTooltip title={t(TRANSLATE_TOOLTIP.리그보고서.평균교전횟수)} placement='top'>
              <h6>
                <span>{t('league.leagueStat.teamFight')}</span>
              </h6>
            </CustomTooltip>
            <div className='Legend'>
              <p className='X'>X {t('league.leagueStat.patch')}</p>
              <p className='Y'>Y {t('league.leagueStat.teamFight')}</p>
            </div>
          </CHART_TITLE>
          {result.league.includes('LPL') ? (
            <NO_DATA>{t('errorControllerMsg.leagueStatisticsLPLError')}</NO_DATA>
          ) : !data?.response.avgMatchTotal ||
            !firstFight ||
            data.response.avgMatchTotal.data.some((item) => Number.isNaN(item.match_total)) ||
            data.response.avgMatchTotal.max === 0 ? (
            <NO_DATA>{t('league.leagueStat.noData2')}</NO_DATA>
          ) : (
            <CHART_CONTENT>
              <Line
                data={firstFight}
                options={{
                  ...lineOptions,
                  scales: {
                    y: {
                      ...lineOptions.scales.y,
                      min: data?.response.avgMatchTotal.min,
                      max: data?.response.avgMatchTotal.max,
                    },
                  },
                }}
              />
            </CHART_CONTENT>
          )}
        </CHART_CONTEINER>

        {/* 평균 첫 갱 시간 */}
        <CHART_CONTEINER>
          <CHART_TITLE>
            <CustomTooltip title={t(TRANSLATE_TOOLTIP.리그보고서.평균첫갱시간)} placement='top'>
              <h6>
                <span>{t('league.leagueStat.gank')}</span>
              </h6>
            </CustomTooltip>
            <div className='Legend'>
              <p className='X'>X {t('league.leagueStat.patch')}</p>
              <p className='Y'>Y {t('league.leagueStat.time')}</p>
            </div>
          </CHART_TITLE>

          <CHART_CONTENT>
            {result.league.includes('LPL') ? (
              <NO_DATA>{t('errorControllerMsg.leagueStatisticsLPLError')}</NO_DATA>
            ) : !data?.response.avgFirstGang ||
              !averageGank ||
              data.response.avgFirstGang.data.some((item) => Number.isNaN(item.first_gang_time)) ||
              data.response.avgFirstGang.max === 0 ? (
              <NO_DATA>{t('league.leagueStat.noData2')}</NO_DATA>
            ) : (
              <Line
                data={averageGank}
                options={{
                  ...lineOptions,
                  scales: {
                    y: {
                      ...lineOptions.scales.y,
                      min: data?.response.avgFirstGang.min,
                      max: data?.response.avgFirstGang.max,
                    },
                  },
                }}
              />
            )}
          </CHART_CONTENT>
        </CHART_CONTEINER>
      </BOTTOM_BOX>
    </StatisticsWrapper>
  );
}

export default LeagueStatistics;

const StatisticsWrapper = styled.div`
  height: calc(100vh - 153px);
`;

const TOP_BOX = styled.div`
  display: flex;
  margin-top: 22.5px;
`;

const CHART_CONTEINER = styled.div`
  display: flex;
  flex-direction: column;
  width: 538px;
  height: 278px;
  border: solid 1px rgb(58, 55, 69);
  background-color: rgb(47, 45, 56);
  border-radius: 20px;
  margin-right: 22px;
`;

const CHART_TITLE = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 51px;
  border-bottom: 1px solid rgb(35, 33, 42);

  h6 {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    margin-left: 20px;
  }

  .Legend {
    display: flex;
    align-items: center;
  }

  .X {
    font-size: 15px;
    color: #84818e;
    margin-right: 15px;
    ::first-letter {
      color: #f14444;
    }
  }
  .Y {
    font-size: 15px;
    text-align: left;
    color: #84818e;
    margin-right: 16px;
    ::first-letter {
      color: #f14444;
    }
  }
`;

const CHART_CONTENT = styled.div`
  flex: 1;
  ${spacing.padding(5)}
`;

const BOTTOM_BOX = styled.div`
  display: flex;
  margin-top: 22px;
`;

const NO_DATA = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: white;
`;
