import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import dayjs from 'dayjs';
import {
  GridColDef,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarExport,
  GridValidRowModel,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { scrollbarStyle, spacing, typoStyle } from '../../../Styles/ui';
import { userAtom, userLanguageAtom } from '../../../recoil/Auth/userAtom';
import ScrimService, { ScrimTeamStatParams } from '../../../services/ScrimServices';
import { getWinRate } from '../../../lib/getWinRate';
import { TRANSLATE_SCRIM_TITLE } from '../../../lib/translation/translate_scrim';
import Calendar from '../../test/Calendar';
import CustomDataGrid from '../components/CustomDataGrid';
import { Avatar, Box } from '@mui/material';

type RowDataset = undefined | GridValidRowModel[];

type WidthOptions = {
  width?: number;
  flex?: number;
};

const 셀번역 = {
  상대전적: TRANSLATE_SCRIM_TITLE.oppRecord,
  리그: TRANSLATE_SCRIM_TITLE.league,
  팀명: TRANSLATE_SCRIM_TITLE.teamName,
  경기수: TRANSLATE_SCRIM_TITLE.games,
  승: TRANSLATE_SCRIM_TITLE.win,
  패: TRANSLATE_SCRIM_TITLE.lose,
  승률: TRANSLATE_SCRIM_TITLE.winRate,
};

const makeCellData = {
  리그: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'league',
    headerName: t(셀번역.리그),
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            sx={{
              width: 26,
              height: 26,
              marginRight: 1,
            }}
            src={
              params.row.league
                ? `/Images/ico-league-${params.row.league?.toLowerCase()}.png`
                : `/Images/team/ico_team_tsb.svg`
            }
          />
          {params.row.league}
        </Box>
      );
    },
    ...widthOptions,
  }),

  팀: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'team',
    headerName: t(셀번역.팀명),
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            sx={{
              width: 26,
              height: 26,
              marginRight: 1,
            }}
            src={
              params.row.team
                ? `/Images/team/ico_team_${params.row.team?.toLowerCase()}.png`
                : `/Images/team/ico_team_tsb.svg`
            }
          />
          {params.row.team}
        </Box>
      );
    },
    ...widthOptions,
  }),

  경기수: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'total',
    headerName: t(셀번역.경기수),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    ...widthOptions,
  }),

  승: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'win',
    headerName: t(셀번역.승),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    ...widthOptions,
  }),

  패: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'lose',
    headerName: t(셀번역.패),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    ...widthOptions,
  }),

  승률: (widthOptions?: WidthOptions): GridColDef => ({
    field: 'winLoseRate',
    headerName: t(셀번역.승률),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    ...widthOptions,
    valueGetter: (params: GridValueGetterParams) =>
      (Number(params.row.win / params.row.total) * 100).toFixed(0),
    valueFormatter: (params) => `${params.value}%`,
  }),
};

function CustomToolbar(title: string) {
  return (
    <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', padding: '12px 16px' }}>
      <TITLE>{title}</TITLE>

      <GridToolbarExport
        sx={{
          padding: '4px 12px',
          background: '#484655',
          color: 'white',
        }}
      />
    </GridToolbarContainer>
  );
}

// 상대전적 - 데이터가 없는 경우, 에러 발생, 필터링 결과 없을 경우 띄워주는 에러메세지
const CustomOverlay = (msg: string) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src='/Images/icon/ic_error_page.png' alt='errorImg' />
      <span style={{ marginTop: '10px' }}>{msg}</span>
    </div>
  );
};

const ScrimTeam = () => {
  const user = useRecoilValue(userAtom);
  const { t } = useTranslation();
  const { mutate, data: teamStatData } = useMutation((data: ScrimTeamStatParams) =>
    ScrimService.getScrimTeamStat(data),
  );

  const columns: GridColDef[] = [
    makeCellData.리그({ flex: 20 }),
    makeCellData.팀({ flex: 42 }),
    makeCellData.경기수({ flex: 12 }),
    makeCellData.승({ flex: 6 }),
    makeCellData.패({ flex: 9 }),
    makeCellData.승률({ flex: 11 }),
  ];

  const rows: RowDataset =
    teamStatData?.response &&
    teamStatData?.response?.relativeRecord.map((item: any) => {
      return {
        id: item.oppteam,
        league: item.league,
        team: item.oppteam,
        total: item.total,
        win: item.win,
        lose: item.total - item.win,
      };
    });

  const handleChange = (date) => {
    if (!user) return;
    const params = {
      team: user?.teamName,
      startDate: date[0] === 0 ? 0 : dayjs(date[0]).unix(),
      endDate: date[1] === 0 ? 0 : dayjs(date[1]).unix(),
    };
    mutate(params);
  };

  return (
    <>
      <Calendar handleChange={handleChange} isSelectDateNeeded />
      {teamStatData && (
        <>
          <TableWrapper>
            <MetaData>
              <InfoBox>
                <Logo
                  src={
                    user?.teamName !== '관리자'
                      ? `/Images/team/ico_team_${
                          user?.teamName ? user.teamName.toLocaleLowerCase() : 'tbd'
                        }.png`
                      : `/Images/team/ico_team_tsb.svg`
                  }
                  alt='teameLogo'
                />
                <TeamName>{user?.teamName === '관리자' ? 'TSB' : user?.teamName}</TeamName>
              </InfoBox>
              <DetailBox>
                <colgroup>
                  <col width='30%' />
                  <col width='50%' />
                  <col width='20%' />
                </colgroup>
                {teamStatData.response.record && (
                  <tbody>
                    <tr>
                      <td className='title'>{t(TRANSLATE_SCRIM_TITLE.scrimRecord)}</td>
                      <td className='gameResult'>{`${teamStatData.response.record?.total?.totals}${t(
                        TRANSLATE_SCRIM_TITLE.game,
                      )} ${teamStatData.response.record?.total?.wins}${t(TRANSLATE_SCRIM_TITLE.win)}
                ${
                  Number(teamStatData.response.record?.total?.totals) -
                  Number(teamStatData.response.record?.total?.wins)
                }${t(TRANSLATE_SCRIM_TITLE.lose)}`}</td>
                      <td className='winRate'>
                        {getWinRate(
                          Number(teamStatData.response.record?.total?.totals),
                          Number(teamStatData.response.record?.total?.wins),
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className='title'>{t(TRANSLATE_SCRIM_TITLE.redSide)}</td>
                      <td className='gameResult'>{`${teamStatData.response.record?.red?.totals}${t(
                        TRANSLATE_SCRIM_TITLE.game,
                      )} ${teamStatData.response.record?.red?.wins}${t(TRANSLATE_SCRIM_TITLE.win)}
                ${
                  Number(teamStatData.response.record?.red?.totals) -
                  Number(teamStatData.response.record?.red?.wins)
                }${t(TRANSLATE_SCRIM_TITLE.lose)}`}</td>
                      <td className='winRate'>
                        {getWinRate(
                          Number(teamStatData.response.record?.red?.totals),
                          Number(teamStatData.response.record?.red?.wins),
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className='title'>{t(TRANSLATE_SCRIM_TITLE.blueSide)}</td>
                      <td className='gameResult'>{`${teamStatData.response.record?.blue?.totals}${t(
                        TRANSLATE_SCRIM_TITLE.game,
                      )} ${teamStatData.response.record?.blue?.wins}${t(TRANSLATE_SCRIM_TITLE.win)}
                ${
                  Number(teamStatData.response.record?.blue?.totals) -
                  Number(teamStatData.response.record?.blue?.wins)
                }${t(TRANSLATE_SCRIM_TITLE.lose)}`}</td>
                      <td className='winRate'>
                        {getWinRate(
                          Number(teamStatData.response.record?.blue?.totals),
                          Number(teamStatData.response.record?.blue?.wins),
                        )}
                      </td>
                    </tr>
                  </tbody>
                )}
              </DetailBox>
            </MetaData>

            {/* 상대전적 */}
            <Group>
              <HomeTable>
                {rows && (
                  <CustomDataGrid
                    columns={columns}
                    rows={rows}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'total', sort: 'desc' }],
                      },
                    }}
                    components={{
                      Toolbar: () => CustomToolbar(t(TRANSLATE_SCRIM_TITLE.oppRecord)),
                      // 필터링 결과가 없는 경우
                      NoResultsOverlay: () => CustomOverlay(t(TRANSLATE_SCRIM_TITLE.noFilteredData)),
                      // 데이터가 빈배열인 경우
                      NoRowsOverlay: () => CustomOverlay(t(TRANSLATE_SCRIM_TITLE.noData)),
                      // 데이터 fetching에 에러 발생 시
                      ErrorOverlay: () => CustomOverlay(t(TRANSLATE_SCRIM_TITLE.errorGenerated)),
                    }}
                  />
                )}
              </HomeTable>
            </Group>
          </TableWrapper>
        </>
      )}
    </>
  );
};

export default ScrimTeam;

const TableWrapper = styled.div`
  display: flex;
  padding: 30px 0;
  width: 1110px;
  color: #ffffff;
  margin-bottom: 20px;
`;

const MetaData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 430px;
  height: 268px;
  margin-right: 10px;
  background-color: #2f2d38;
  border-radius: 12px;
  padding: 44px 48px;
`;

const InfoBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 52px;
  height: 52px;
`;

const TeamName = styled.span`
  font-size: 16px;
  margin-left: 4px;
`;

const DetailBox = styled.table`
  width: 100%;
  table-layout: fixed;
  font-size: 15px;

  tbody {
    width: 316px;
    ${typoStyle.contents};

    td {
      text-align: left;
      vertical-align: middle;
      ${spacing.paddingY(1)};

      &.title {
        color: #817e90;
      }

      &.winRate {
        text-align: right;
        color: #f04545;
      }
    }
  }
`;

const Group = styled.div`
  width: 538px;
  height: 268px;
  margin-right: 10px;
  background-color: #2f2d38;
  border-radius: 12px;
`;

const HomeTable = styled.div`
  height: 100%;
  border-radius: 12px;
`;

const TITLE = styled.div`
  ${typoStyle.body_title}
`;
