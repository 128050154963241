/**
 *
 * @param total 전체경기수
 * @param pick 전체 픽 횟수
 * @param ban 전체 밴 횟수
 * @desc 전체게임중에 해당 챔피언이 픽 + 밴 된 확률을 구합니다.
 */
export function getBanpickRate(total: any, pick: any, ban: any) {
  if (Number.isNaN(Number(total)) || Number.isNaN(Number(pick)) || Number.isNaN(Number(ban))) {
    console.warn('total, pick, ban 타입 확인');
    return 0;
  }
  return ((Number(pick) + Number(ban)) / Number(total)) * 100;
}
