export const TRANSLATE_HOME_TITLE = {
  순위: 'home.rank',
  리그: 'home.league',
  팀명: 'home.teamName',
  승: 'home.win',
  패: 'home.lose',
  승률: 'home.winrate',
  득실: 'home.points',
  스코어: 'home.score',
  데이터없음: 'home.데이터없음',
  데이터에러발생: 'home.데이터에러발생',
  로딩중: 'home.로딩중',
  리그순위표: 'home.리그순위표',
  리그일정: 'home.리그일정',
  알림: 'home.알림',
};
