/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UseMutateFunction } from 'react-query';
import { useTab } from '../../../Hooks';

import { colors, testStyle, transitionStyle, typoStyle } from '../../../Styles/ui';
import IPActive from './IPActive';
import IPInactive from './IPInactive';
import { BookMarkListInfoResponse, IResponseSuccess } from '../../../services/SoloRankServices';

import { RootState } from '../../../redux/modules';

// const S = { table, layout }

const IPConent = ({
  selectedDay,
  setSelectedDay,
  dataset,
  getInfoFunc,
}: {
  selectedDay: string;
  setSelectedDay: React.Dispatch<React.SetStateAction<string>>;
  dataset: BookMarkListInfoResponse;
  getInfoFunc: UseMutateFunction<IResponseSuccess<BookMarkListInfoResponse>, unknown, void, unknown>;
}) => {
  const { t } = useTranslation();
  const { team, patch } = useSelector((state: RootState) => state.JungleMapReducer);
  const selectedPatches = Object.keys(patch).filter((key) => patch[key] === true);

  const tab = [
    {
      title: t('soloRank.InterestedPlayer.tab.ipActive'),
      component: (
        <IPActive
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          playerInfo={dataset.active && dataset.active.players ? dataset.active.players : []}
          getInfoFunc={getInfoFunc}
        />
      ),
    },
    {
      title: t('soloRank.InterestedPlayer.tab.ipInactive'),
      component: (
        <IPInactive
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          playerInfo={dataset.inactive && dataset.inactive.players ? dataset.inactive.players : []}
          getInfoFunc={getInfoFunc}
        />
      ),
    },
  ];
  const { currentIndex, currentTab, setIndex } = useTab(0, tab);
  console.log('selectedPatches', selectedPatches);

  return (
    <SWrapper>
      <STab>
        {tab.map((tab, index) => (
          <STabItem
            isActive={index === currentIndex}
            key={tab.title}
            className={index === currentIndex ? 'is-active' : ''}
            onClick={() => setIndex(index)}
          >
            {tab.title}
          </STabItem>
        ))}
      </STab>
      <SContents>{currentTab.component}</SContents>
      {/* <S.table.AddPlayerPopupButton
        onClick={() => openModal(modalList.addFavoritePlayer)}
      >
        <span>+</span>
        <div>
          <h5>{t("soloRank.InterestedPlayer.label.addPlayer")}</h5>
          <span>{t("soloRank.InterestedPlayer.desc.addPlayer")}</span>
        </div>
      </S.table.AddPlayerPopupButton> */}
    </SWrapper>
  );
};

export default IPConent;

const SWrapper = styled.div`
  width: 1120px;
  height: auto;
  margin: 10px 0 30px;
  //padding: 22px 100px 21px;
  border-radius: 20px;
`;

const SButtonContainer = styled.div`
  margin-top: 40px;
  padding-top: 19px;
  width: 100%;
  border-top: 1px solid ${colors.border_light};
`;

const STab = styled.ul`
  display: flex;
  width: 100%;
  height: 62px;
  border-bottom: 1px solid ${colors.border_light};
  margin-bottom: 20px;
`;

const STabItem = styled.li<{ isActive: boolean }>`
  position: relative;
  width: auto;
  padding: 20px 0;
  margin: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.3;
  border-bottom: ${({ isActive }) => (isActive ? '1px' : '09')} solid ${colors.text};

  ${typoStyle.body}
  ${transitionStyle.opacity};

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 120px;
    height: 1px;
    // border-bottom: 1px solid ${colors.text};
    opacity: 0;
    ${transitionStyle.opacity};
  }

  &.is-active {
    opacity: 1;

    &::after {
      opacity: 1;
    }
  }
`;

const SContents = styled.div`
  display: flex;
  /* ${testStyle.border2} */
`;
