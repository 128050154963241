import React, { useState } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useDispatch } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import GameAtom from '../../../../../recoil/ProGameReport/gameAtom';

const TimeLineValue = ({
  team,
  move,
  monster,
  time,
}: {
  team: number;
  monster?: string;
  move: number;
  time: number;
}) => {
  const setSeekTime = useSetRecoilState(GameAtom.seekTime);

  const getMonsterImg = (monster: string) => {
    const mon = monster.includes('DRAGON') ? monster.split('_')[0] : monster;

    switch (mon) {
      case 'AIR':
        return 'ico-drake-cloud.svg';
      case 'FIRE':
        return 'ico-drake-infernal.svg';
      case 'EARTH':
        return 'ico-drake-mountain.svg';
      case 'WATER':
        return 'ico-drake-ocean.svg';
      case 'ELDER':
        return 'ico-elders.svg';
      case 'CHEMTECH':
        return 'ico-drake-chemtech.svg';
      case 'HEXTECH':
        return 'ico-drake-hextech.svg';
      case 'RIFTHERALD':
        return 'ico-heralds.svg';
      case 'BARON_NASHOR':
        return 'ico-baron.svg';
      default:
        return '';
    }
  };

  const onClick = (time: number) => {
    setSeekTime(time / 2);
  };

  return (
    <TimeLineValueBox team={team} move={move} monster={!!monster} onClick={() => onClick(time)}>
      {monster ? (
        <div className='monster'>
          <img src={`/Images/${getMonsterImg(monster)}`} alt='closeBtn' className='Close' />
        </div>
      ) : (
        <></>
      )}
    </TimeLineValueBox>
  );
};

export default TimeLineValue;

const TimeLineValueBox = styled.div<{ team: number; monster: boolean; move: number }>`
  width: 1px;
  height: 20px;
  cursor: pointer;
  background-color: ${(props) =>
    props.monster ? '' : props.team !== undefined ? (props.team === 1 ? '#0075bf' : '#f04545') : '#fff'};
  margin: 0 0 0 ${(props) => (props.monster ? props.move - 14 : props.move)}px;
  position: absolute;

  .monster {
    width: 14px;
    img {
      width: 14px;
      height: 14px;
    }
    border-bottom: 1px solid ${(props) => (props.team === 1 ? '#0075bf' : '#f04545')};
    margin-top: 1px;
  }
`;
