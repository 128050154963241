import React, { useState } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import TimeLineTeamGoldLabel from './TimeLineTeamGoldLabel';

import { colors } from '../../../../../Styles/ui';
import GameDatasetAtom from '../../../../../recoil/ProGameReport/gameDatasetAtom';
import { useRecoilValue } from 'recoil';

const skipped = (ctx, value) => (ctx.p0.skip || ctx.p1.skip ? value : undefined);

const afterLayout = (chart) => {
  const { ctx, data } = chart;
  ctx.save();
  const yAxis = chart.scales.y;
  const lineGradient = ctx.createLinearGradient(0, yAxis.bottom, 0, yAxis.top);
  lineGradient.addColorStop(0, colors.badge_red);
  lineGradient.addColorStop(0.5, colors.badge_red);
  lineGradient.addColorStop(0.5, colors.badge_blue);
  lineGradient.addColorStop(1, colors.badge_blue);

  const fillGradient = ctx.createLinearGradient(0, yAxis.bottom, 0, yAxis.top);
  fillGradient.addColorStop(0, colors.badge_red);
  fillGradient.addColorStop(0.5, 'black');
  fillGradient.addColorStop(0.5, 'black');
  fillGradient.addColorStop(1, colors.badge_blue);

  chart.data.datasets[0].borderColor = lineGradient;
  chart.data.datasets[0].backgroundColor = fillGradient;

  ctx.restore();
};

const TimeLineTeamGold = () => {
  const timeLineDataset = useRecoilValue(GameDatasetAtom.timeLineDataset);

  const options = {
    hover: {
      animationDuration: 100,
    },
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        intersect: false,
        backgroundColor: '#1d1d1d',
        titleFontSize: 11,
        bodyFontSize: 11,
        displayColors: false,
        boxWidth: 2,
        boxHeight: 2,
        cornerRadius: 10,
        yAlign: 'center',
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          fontColor: '#84818e',
          fontSize: 0.5,
        },
        grid: { color: 'rgba(47, 45, 56,0)' },
        offset: true,
        display: false,
      },

      y: {
        mix: timeLineDataset?.teamGoldMax,
        min: -timeLineDataset.teamGoldMax,
        ticks: {
          display: false,
        },
        grid: {
          color: 'rgba(58, 55, 69,1)',
        },
      },
    },
  };

  const TeamGoldChart = {
    labels: timeLineDataset?.teamGoldX,
    datasets: [
      {
        fill: true,
        lineTension: 0,
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: '#84818e',
        borderWidth: 0.5,
        pointRadius: 0.5,
        data: timeLineDataset?.teamGoldY,
        spanGaps: true,
        segment: {
          borderColor: (ctx) => skipped(ctx, '#84818e'),
          borderDash: (ctx) => skipped(ctx, [2, 3]),
        },
      },
    ],
  };

  return (
    <TimeLineGoldContainer>
      <TimeLineTeamGoldLabel />
      <GoldDataBox>
        <Line
          data={TeamGoldChart}
          options={options}
          plugins={[
            {
              afterLayout,
            },
          ]}
        />
      </GoldDataBox>
    </TimeLineGoldContainer>
  );
};

export default TimeLineTeamGold;

const TimeLineGoldContainer = styled.div`
  width: 694px;
  height: 50px;
  display: flex;
  height: 51px;
  margin: 5px 0 9px 5px;

  .title {
    width: 58px;
    font-family: SpoqaHanSansNeo;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: right;
  }
`;

const GoldDataBox = styled.div`
  width: 623px;
  height: 100%;
  border-radius: 3px;
  margin: 0 0 0 10px;

  .chartjs-render-monitor {
    // position: relative;
    z-index: 9999;
  }
`;
