export const JUNGLE_INIT = 'junglevalue/Jungle_INIT';

export const SET_FILTER_DATA = 'junglevalue/SET_FILTER_DATA';
export const SET_JUNGLE_PLAYER = 'junglevalue/SET_JUNGLE_PLAYER';
export const SET_OPP_JUNGLE_PLAYER = 'junglevalue/SET_OPP_JUNGLE_PLAYER';
export const SET_CHAMP = 'junglevalue/SET_CHAMP';
export const SET_OPP_CHAMP = 'junglevalue/SET_OPP_CHAMP';
export const SET_OBJECT = 'junglevalue/SET_OBJECT';
export const SET_GAME_LIST_FILTER = 'junglevalue/SET_GAME_LIST_FILTER';
export const SET_GAME_LIST = 'junglevalue/SET_GAME_LIST';
export const RESET_SIDE_FILTER = 'junglecvalue/RESET_SIDE_FILTER';
export const RESET_SIDE_FILTER2 = 'junglevalue/RESET_SIDE_FILTER2';
export const RESET_SIDE_FILTER3 = 'junglevalue/RESET_SIDE_FILTER3';

// 정글 오더 API용 챔피언, 상대챔피언
export const SET_JUNGLE_ORDER_CHAMP = 'junglevalue/SET_JUNGLE_ORDER_CHAMP';
export const SET_JUNGLE_ORDER_OPPCHAMP = 'junglevalue/SET_JUNGLE_ORDER_OPPCHAMP';
export const SET_JUNGLING_CAMP_DATA = 'junglevalue/SET_JUNGLING_CAMP_DATA';

/** 리팩토링 추가 action */
export const SET_JUNGLE_YEAR = 'junglevalue/SET_JUNGLE_YEAR';
export const SET_JUNGLE_LEAGUE = 'junglevalue/SET_LEAGUE';
export const SET_JUNGLE_LEAGUES = 'junglevalue/SET_LEAGUES';
export const SET_JUNGLE_SEASON = 'junglevalue/SET_SEASON';
export const SET_JUNGLE_TEAM = 'junglevalue/SET_TEAM';
export const SET_JUNGLE_TEAMS = 'junglevalue/SET_TEAMS';
export const SET_JUNGLE_PATCH = 'junglevalue/SET_PATCH';

export const RESET_JUNGLE_SELECTED_PATCH = 'junglevalue/RESET_JUNGLE_SELECTED_PATCH';
export const RESET_JUNGLE_LEAGUE = 'junglevalue/RESET_LEAGUE';
export const RESET_JUNGLE_LEAGUES = 'junglevalue/RESET_JUNGLE_LEAGUES';
export const RESET_JUNGLE_SEASON = 'junglevalue/RESET_JUNGLE_SEASON';
export const RESET_JUNGLE_TEAM = 'junglevalue/RESET_JUNGLE_TEAM';
export const RESET_JUNGLE_TEAMS = 'junglevalue/RESET_JUNGLE_TEAMS';

export const RESET_JUNGLE_PATCH = 'junglevalue/RESET_JUNGLE_PATCH';
export const RESET_JUNGLE_PLAYER = 'junglevalue/RESET_JUNGLE_PLAYER';
export const SET_IS_JUNGLING_CLICKED = 'junglevalue/SET_IS_JUNGLING_CLICKED';
export const SET_IS_JUNGLE_MAPPING_CLICKED = 'junglevalue/SET_IS_JUNGLE_MAPPING_CLICKED';

export const RESET_DROPDOWN_LEAGUE = 'junglevalue/RESET_DROPDOWN_LEAGUE';
export const RESET_DROPDOWN_TEAM = 'junglevalue/RESET_DROPDOWN_TEAM';
export const RESET_DROPDOWN_PLAYER = 'junglevalue/RESET_DROPDOWN_PLAYER';

export const RESET_DROPDOWN_OPPYEAR = 'junglevalue/RESET_DROPDOWN_OPPYEAR';
export const RESET_DROPDOWN_OPPLEAGUE = 'junglevalue/RESET_DROPDOWN_OPPLEAGUE';
export const RESET_DROPDOWN_OPPTEAM = 'junglevalue/RESET_DROPDOWN_OPPTEAM';
export const RESET_DROPDOWN_OPPPLAYER = 'junglevalue/RESET_DROPDOWN_OPPPLAYER';

export const JungleInit = () => {
  return {
    type: JUNGLE_INIT,
  };
};

// set
export const SetJunlgeYear = (payload) => ({
  type: SET_JUNGLE_YEAR,
  payload,
});
export const SetJungleLeague = (payload) => ({
  type: SET_JUNGLE_LEAGUE,
  payload,
});

export const SetJungleLeagues = (payload) => ({
  type: SET_JUNGLE_LEAGUES,
  payload,
});

export const SetJungleSeason = (payload) => ({
  type: SET_JUNGLE_SEASON,
  payload,
});
export const SetJungleTeam = (payload) => ({
  type: SET_JUNGLE_TEAM,
  payload,
});

export const SetJungleTeams = (payload) => ({
  type: SET_JUNGLE_TEAMS,
  payload,
});
export const SetJunglePatch = (payload) => ({
  type: SET_JUNGLE_PATCH,
  payload,
});

// reset
export const ResetJungleSelectedPatch = () => ({
  type: RESET_JUNGLE_SELECTED_PATCH,
});
export const ResetJungleLeague = (payload) => {
  return {
    type: RESET_JUNGLE_LEAGUE,
    payload,
  };
};

export const ResetJungleSeason = (payload) => {
  return {
    type: RESET_JUNGLE_SEASON,
    payload,
  };
};

export const ResetJungleTeam = () => {
  return {
    type: RESET_JUNGLE_TEAM,
  };
};

export const ResetJunglePlayer = () => {
  return {
    type: RESET_JUNGLE_PLAYER,
  };
};

export const ResetJungleTeams = (payload) => {
  return {
    type: RESET_JUNGLE_TEAMS,
    payload,
  };
};

export const ResetJungleLeagues = (payload) => {
  return {
    type: RESET_JUNGLE_LEAGUES,
    payload,
  };
};
export const ResetJunglePatch = (payload) => {
  return {
    type: RESET_JUNGLE_PATCH,
    payload,
  };
};

export const SetFilterData = (payload) => {
  return {
    type: SET_FILTER_DATA,
    payload,
  };
};

export const SetJunglePlayer = (payload) => {
  return {
    type: SET_JUNGLE_PLAYER,
    payload,
  };
};

export const SetChamp = (payload) => {
  return {
    type: SET_CHAMP,
    payload,
  };
};

export const SetOppChamp = (payload) => {
  return {
    type: SET_OPP_CHAMP,
    payload,
  };
};

export const SetIsJunglingClicked = (payload) => {
  return {
    type: SET_IS_JUNGLING_CLICKED,
    payload,
  };
};

export const SetIsJungleMappingClicked = (payload) => {
  return {
    type: SET_IS_JUNGLE_MAPPING_CLICKED,
    payload,
  };
};

export const SetObject = (payload) => {
  return {
    type: SET_OBJECT,
    payload,
  };
};

export const SetGameListFilter = (payload) => {
  return {
    type: SET_GAME_LIST_FILTER,
    payload,
  };
};

export const SetGameList = (payload) => {
  return {
    type: SET_GAME_LIST,
    payload,
  };
};

export const ResetSideFilter = () => {
  return {
    type: RESET_SIDE_FILTER,
  };
};

export const ResetSideFilter2 = () => {
  return {
    type: RESET_SIDE_FILTER2,
  };
};

export const ResetSideFilter3 = () => {
  return {
    type: RESET_SIDE_FILTER3,
  };
};

export const SetJungleOrderChamp = (payload) => {
  return {
    type: SET_JUNGLE_ORDER_CHAMP,
    payload,
  };
};

export const SetJungleOrderOppchamp = (payload) => {
  return {
    type: SET_JUNGLE_ORDER_OPPCHAMP,
    payload,
  };
};

export const SetJunglingCampData = (payload) => {
  return {
    type: SET_JUNGLING_CAMP_DATA,
    payload,
  };
};

export const ResetDropdownLeague = () => {
  return {
    type: RESET_DROPDOWN_LEAGUE,
  };
};

export const ResetDropdownTeam = () => {
  return {
    type: RESET_DROPDOWN_TEAM,
  };
};

export const ResetDropdownPlayer = () => {
  return {
    type: RESET_DROPDOWN_PLAYER,
  };
};

export const ResetDropdownOppYear = () => {
  return {
    type: RESET_DROPDOWN_OPPYEAR,
  };
};

export const ResetDropdownOppLeague = () => {
  return {
    type: RESET_DROPDOWN_OPPLEAGUE,
  };
};

export const ResetDropdownOppTeam = () => {
  return {
    type: RESET_DROPDOWN_OPPTEAM,
  };
};

export const ResetDropdownOppPlayer = () => {
  return {
    type: RESET_DROPDOWN_OPPPLAYER,
  };
};

const initialState = {
  year: [],
  oppyear: [],
  league: [],
  leagues: {},
  oppleague: {},
  season: {},
  oppseason: {},
  team: [],
  teams: {},
  oppteam: [],
  patch: {},
  player: '',
  oppplayer: '',
  champion: {},
  oppchampion: {},
  isClicked: false,
  isMappingClicked: false,
  object: 'all',
  gameListFilter: [],
  gameList: {},
  jungleOrderChamp: [],
  jungleOrderOppChamp: [],
  gameid: '',
  junglingCampData: null,
};

export default function JungleMapReducer(state = initialState, action) {
  switch (action.type) {
    case JUNGLE_INIT:
      return initialState;

    case SET_FILTER_DATA:
      return action.payload;
    case SET_JUNGLE_PLAYER:
      return {
        ...state,
        player: action.payload,
      };

    // set - 경기 리스트 받아오는 정보
    case SET_JUNGLE_YEAR:
      return {
        ...state,
        year: [...action.payload],
      };
    case SET_JUNGLE_LEAGUE:
      return {
        ...state,
        league: [...action.payload],
      };
    case SET_JUNGLE_LEAGUES:
      return {
        ...state,
        leagues: { ...action.payload },
      };
    case SET_JUNGLE_SEASON:
      return {
        ...state,
        season: { ...action.payload },
      };
    case SET_JUNGLE_TEAM:
      return {
        ...state,
        team: [...action.payload],
      };
    case SET_JUNGLE_TEAMS:
      return {
        ...state,
        teams: { ...action.payload },
      };
    case SET_JUNGLE_PATCH:
      return {
        ...state,
        patch: { ...action.payload },
      };

    // set - 개별 경기정보 선수데이터
    case SET_CHAMP:
      return {
        ...state,
        champion: { ...action.payload },
      };

    case SET_OPP_CHAMP:
      return {
        ...state,
        oppchampion: { ...action.payload },
      };

    // reset - 경기리스트 관련
    case RESET_JUNGLE_SELECTED_PATCH:
      return {
        ...state,
        patch: {},
      };
    case RESET_JUNGLE_LEAGUE:
      return {
        ...state,
        league: [],
        season: {},
        team: [],
        patch: {},
        player: '',
      };

    case RESET_JUNGLE_LEAGUES:
      return {
        ...state,
        league: { ...state.league, [action.payload]: false },
        season: {},
        team: [],
        patch: {},
        player: '',
      };
    case RESET_JUNGLE_SEASON:
      console.log(action.payload);
      return {
        ...state,
        season: { ...state.season, [action.payload]: false },
        team: [],
        patch: {},
        player: '',
      };
    case RESET_JUNGLE_TEAM:
      return {
        ...state,
        team: [],
      };
    case RESET_JUNGLE_TEAMS:
      return {
        ...state,
        teams: { ...state.teams, [action.payload]: false },
      };
    case RESET_JUNGLE_PATCH:
      return {
        ...state,
        patch: { ...state.patch, [action.payload]: false },
      };
    case RESET_JUNGLE_PLAYER:
      return {
        ...state,
        player: '',
      };

    case SET_IS_JUNGLING_CLICKED:
      return {
        ...state,
        isClicked: action.payload,
      };
    case SET_IS_JUNGLE_MAPPING_CLICKED:
      return {
        ...state,
        isMappingClicked: action.payload,
      };

    case SET_OBJECT:
      return {
        ...state,
        object: action.payload,
      };

    case SET_GAME_LIST_FILTER:
      return {
        ...state,
        gameListFilter: action.payload,
      };

    case SET_GAME_LIST:
      return {
        ...state,
        // gameList: {...action.payload}
        gameList: action.payload,
      };

    case RESET_SIDE_FILTER:
      return {
        ...state,
        // player: "",
        champion: {},
        oppchampion: {},
        gameListFilter: [],
        gameList: {},
        object: 'all',
        jungleOrderChamp: [],
        jungleOrderOppChamp: [],
      };
    case RESET_SIDE_FILTER2:
      return {
        ...state,
        player: '',
        champion: {},
        oppchampion: {},
        gameListFilter: [],
        gameList: {},
        object: 'all',
        jungleOrderChamp: [],
        jungleOrderOppChamp: [],
        gameid: '',
      };

    case RESET_SIDE_FILTER3:
      return {
        ...state,
        jungleOrderChamp: [],
        jungleOrderOppChamp: [],
      };

    case RESET_DROPDOWN_LEAGUE:
      return {
        ...state,
        league: {},
        team: [],
        player: '',
        season: {},
        oppseason: {},
        oppyear: [],
        oppleague: {},
        oppteam: [],
        oppplayer: '',
        patch: {},
        champion: {},
        oppchampion: {},
      };

    case RESET_DROPDOWN_TEAM:
      return {
        ...state,
        team: [],
        player: '',
        season: {},
        oppseason: {},
        oppyear: [],
        oppleague: {},
        oppteam: [],
        oppplayer: '',
        patch: {},
        champion: {},
        oppchampion: {},
      };

    case RESET_DROPDOWN_PLAYER:
      return {
        ...state,
        player: '',
        season: {},
        oppseason: {},
        oppyear: [],
        oppleague: {},
        oppteam: [],
        oppplayer: '',
        patch: {},
        champion: {},
        oppchampion: {},
      };

    case RESET_DROPDOWN_OPPYEAR:
      return {
        ...state,
        patch: {},
        oppyear: [],
        oppleague: {},
        oppteam: [],
        oppplayer: '',
        oppseason: {},
        champion: {},
        oppchampion: {},
      };

    case RESET_DROPDOWN_OPPLEAGUE:
      return {
        ...state,
        patch: {},
        oppleague: {},
        oppteam: [],
        oppplayer: '',
        oppseason: {},
        champion: {},
        oppchampion: {},
      };

    case RESET_DROPDOWN_OPPTEAM:
      return {
        ...state,
        patch: {},
        oppteam: [],
        oppplayer: '',
        oppseason: {},
        champion: {},
        oppchampion: {},
      };

    case RESET_DROPDOWN_OPPPLAYER:
      return {
        ...state,
        patch: {},
        oppplayer: '',
        oppseason: {},
        champion: {},
        oppchampion: {},
      };

    case SET_JUNGLE_ORDER_CHAMP:
      return {
        ...state,
        jungleOrderChamp: action.payload,
      };
    case SET_JUNGLE_ORDER_OPPCHAMP:
      return {
        ...state,
        jungleOrderOppChamp: action.payload,
      };

    case SET_JUNGLING_CAMP_DATA:
      return {
        ...state,
        junglingCampData: action.payload,
      };

    default:
      return state;
  }
}
