import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../../Styles/ui';

const Badge = ({ color, index, styling }) => {
  const badgeColor = color === 'red' ? 'badge_red' : 'badge_blue';
  return (
    <Sbadge badgeColor={badgeColor} style={styling}>
      {index}
    </Sbadge>
  );
};

const Sbadge = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
  position: absolute;
  background-color: ${({ badgeColor }) => colors[badgeColor]};
`;

export default Badge;
