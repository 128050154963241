import axios, { AxiosResponse, AxiosError } from 'axios';
import { Status } from './GameServices';
import { API } from '../Pages/config';
import { ApiError } from './axios/axios';
import { IAxiosResponse } from './axios/axios.type';
import { ILoginParam, ILoginSuccess } from './types/auth_services.type';

const authAxios = axios.create({
  baseURL: `${API}/lolapi`,
  method: 'POST',
  headers: { 'content-type': 'application/x-www-form-urlencoded' },
  timeout: 5000,
});

const handleSuccess = (res: AxiosResponse<IAxiosResponse<undefined>>) => {
  // 호출 기본응답
  if (res.status > 299 || res.status < 200) {
    console.error('서버에 호출이 들어가지 않았습니다. url을 확인하세요');
  }

  // 서버에서 호출하는 응답
  if (Number(res.data.status) > 299 || Number(res.data.status) < 200) {
    const { status, message } = res.data;
    const error = new ApiError(status, message);
    return Promise.reject(error);
  }

  return res;
};

const handleError = (error: AxiosError) => {
  return Promise.reject(error);
};

// 응답 인터셉터
authAxios.interceptors.response.use(handleSuccess, handleError);
class AuthServices {
  login({ id, password }: ILoginParam): Promise<IAxiosResponse<ILoginSuccess>> {
    return new Promise((resolve, reject) => {
      authAxios({
        url: '/logins',
        data: `ids=${id}&password=${password}&type=N`,
      })
        .then((res) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  logout(user: any, locale: any) {
    return new Promise((resolve, reject) => {
      authAxios({
        url: `/logout`,
        data: `id=${user.userId}&charge_time=0&lang=${locale}`,
      })
        .then((res: AxiosResponse) => resolve(res))
        .catch((err: AxiosError) => reject(err));
    });
  }

  checkEmail(email: string, authCode: string) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: `${API}/lolapi/authemailcord`,
        data: `email=${email}&type=EC&key=${authCode}`,
      })
        .then((res: AxiosResponse) => {
          const errorCode = Number(res.data.status);

          if (errorCode > 299 || errorCode < 200) {
            throw Error();
          }

          resolve(res.data);
        })
        .catch((err: AxiosError) => reject(err));
    });
  }

  checkCode(authCode: string) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: `${API}/lolapi/authkey`,
        data: `key=${authCode}`,
      })
        .then((res: AxiosResponse) => {
          const errorCode = Number(res.data.status);

          if (errorCode > 299 || errorCode < 200) {
            throw Error();
          }

          resolve(res.data);
        })
        .catch((err: AxiosError) => reject(err));
    });
  }
}

export default new AuthServices();
