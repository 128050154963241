/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import WardSideFilter from './components/WardSideFilter';
import WardObjectMap from './components/WardObjectMap';
import * as S from './components/styled/StyledWardPathingLayout';
import { PathAnalysisResultAtom } from '../../../recoil/Filter/pathAnalysisAtom';

const WardObjectPathing = () => {
  const result = useRecoilValue(PathAnalysisResultAtom);
  const selectedTeam = result.team[0];
  const selectedPatchs = result.patch;

  return (
    <S.WardPathingContainer>
      {/* 사이드 필터와 맵 비디오  */}
      {selectedTeam && selectedPatchs && selectedTeam.length > 0 && selectedPatchs.length > 0 && (
        <S.FlexContainer>
          {/* 사이드 필터 */}
          <S.Sidebar>
            <WardSideFilter />
          </S.Sidebar>
          {/*  맵 */}
          <S.Container>
            {/* 맵 비디오 */}
            <S.VideoContainer>
              <WardObjectMap />
            </S.VideoContainer>
          </S.Container>
        </S.FlexContainer>
      )}
    </S.WardPathingContainer>
  );
};

export default WardObjectPathing;
