import { AxiosError, AxiosResponse } from 'axios';
import { axiosPost } from './axios/axios';
import {
  GameListParam,
  OppPlayerChampionsParam,
  PlayerChampionParam,
  PlayersParam,
} from './types/param_types';
import { MappingPosition } from './WardPathingService';

interface IResponseSuccess<T> {
  response: T;
  status: string;
  message: string;
}

type Position = 'top' | 'jng' | 'mid' | 'bot' | 'sup';

interface JuglePathingParams {
  league: string[];
  year: string[];
  season: string[];
  patch: string[];
  team: string;
  player: string;
  champion: string[];
  oppleague: string[];
  oppyear: string[];
  oppseason: string[];
  oppteam: string;
  oppplayer: string;
  oppchampion: string[];
  compare: string;
}

export type SixTime = { realcount: number };

export type CampRate = {
  side: string;
  monsterids: string;
  sides: string;
  jg_rates: string;
  order: number;
};
export type countRate = {
  counter_jg: number;
  player: string;
};
interface JunglePathingResponse {
  team1: {
    campRate: CampRate[];
    sixTime: SixTime[];
    countRate: countRate[];
  };
  team2: {
    campRate: CampRate[];
    sixTime: SixTime[];
    countRate: countRate[];
  };
}

export type JungleMappingPosition = {
  realCount: number;
  player: {
    gameid: string;
    y: number;
    x: number;
    team: 'red' | 'blue';
    position: Position;
  }[];
};

export type JungleMappingInfo = {
  gameid: string;
  date: string;
  opp_team: string;
  mapping: 1;
  side: 'red' | 'blue';
  champions: string;
  opp_champions: string;
  position: Position;
  team: string;
  win: string;
  player: string;
};

interface MappingResponse {
  position: MappingPosition;
  info: JungleMappingInfo;
}

interface JungleOrder {
  gameid: string;
  participantid: number;
  result: -1 | 0 | 1 | 2;
  side: 'red' | 'blue';
  opp_champ: string;
  monsterid: number;
  team: string;
  champ: string;
  realCount: number;
  player: string;
}

interface JungleMapping {
  position: JungleMappingPosition[];
  info: JungleMappingInfo[];
}

export interface HitMapParams {
  year: string[];
  league: string[];
  team: string;
  season: string[];
  patch: string[];
  player: string;
  champion: string[];
  compare: 'off';
  oppteam: string;
  oppplayer: string;
  oppchampion: string;
  side: 'all';
  firsttime: number;
  secondtime: number;
}

export interface IWardParam {
  year: string[];
  league: string[];
  team: string;
  season: string[];
  patch: string[];
  player: string;
  champion: null | string[];
  side: string;
  firstTime: number;
  secondTime: number;
}

interface IWardResponse {
  warding: IWarding[];
}

export interface IWarding {
  date: string;
  firstward: string;
  side: string;
  firstwardPosition: string; // 문자열로 넘어오는 숫자
  firstwardDestroy: number;
  team: string;
  position: string; // 문자열로 넘어오는 숫자
  firstwardTime: number;
  uniqueid: string;
  champion: string;
  oppteam: string;
  player: string;
}

export interface IOppTeamParam {
  year: string[];
  league: string[];
  team: string;
  season: string[];
  patch: string[];
  player: string;
  champion: null | string[];
}

interface IOppTeamResponse {}

class JunglePathingServices {
  async getPlayer(data: PlayersParam) {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/jungle-passing/userinfo',
        data,
      })
        .then((res: AxiosResponse) => resolve(res))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async getChampion(data: PlayerChampionParam): Promise<IResponseSuccess<any>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/jungle-passing/player-champions',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async getOppChampion(data: OppPlayerChampionsParam) {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/jungle-passing/opp-champions',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async getJunglingCompare(data: JuglePathingParams): Promise<IResponseSuccess<JunglePathingResponse>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/jungle-passing/jungle-passing',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async getGameList(data: GameListParam) {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/jungle-passing/object-game',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async getWard(data: IWardParam): Promise<IResponseSuccess<IWardResponse>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/mapping/ward',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async getOppTeam(data: IOppTeamParam): Promise<IResponseSuccess<IOppTeamResponse>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/mappingfilter/oppteam',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async getHitmap(data: HitMapParams): Promise<IResponseSuccess<MappingResponse>> {
    return new Promise((resolve, reject) => {
      console.log('data', data);
      axiosPost({
        url: '/ward-passing/mapping',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async getJungleOrder(data: any): Promise<IResponseSuccess<JungleOrder[]>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/jungle-passing/jungle-order',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }

  async getJungleMapping(data: any): Promise<IResponseSuccess<JungleMapping>> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/jungle-passing/mapping',
        data,
      })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  }
}

export default new JunglePathingServices();
