const colors = {
  // maincolor
  point: '#5942ba',
  point_hover: '#6a55c1',
  default: '#484655',
  default_hover: '#5b5966',

  // bordercolor
  border_light: '#433f4e',

  // bg
  bg: '#2f2d38',
  bg_gnb: '#16151c',
  bg_light: '#2e2d38',
  bg_box: '#2f2d38',
  bg_box_hover: 'rgba(68,66,76,1)',
  bg_select: '#23212a',
  bg_select_hover: '#393840',
  bg_hover: 'rgba(255,255,255,0.1)',
  bg_checkbox: '#3a3745',
  bg_button: '#3a3745',

  // btn
  btn_nor: '#484655',

  //badge
  badge_red: '#f04545',
  badge_blue: '#0075bf',

  // text
  text: '#ffffff',
  info: '#84818e',
  error: '#ee1717',
  red: '#f04545',
  blue: '#0075bf',
  vs: '#817e90',
  black: '#000000',
  white: 'rgba(255,255,255,1)',

  // hover
  white_hover: 'rgba(255,255,255,0.3)',
  text_hover: 'rgba(255,255,255,0.3)',

  // scrollbar
  bg_scrollbar: '#434050',
  bg_scrollbar_default: 'rgba(255,255,255,0.1)',

  // graph
  graph: '#8069e3',
  보라색: {
    50: '#eae7f4',
    100: '#cbc6e6',
    200: '#a9a1d6',
    300: '#887cc7',
    400: '#6d5fbb',
    500: '#5442b0',
    600: '#4b3daa',
    700: '#4135a1',
    800: '#362e9b',
    900: '#24218c',
  },

  배경색: {
    50: '#e4deff',
    100: '#9a96a8',
    200: '#87859a',
    300: '#817e90',
    400: '#635f71',
    500: '#484655',
    600: '#403f50',
    700: '#383254',
    800: '#2f2e3b',
    900: '#242434',
  },
};

export default colors;
