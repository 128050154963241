/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useTab } from '../../../../Hooks';
import { colors, testStyle, transitionStyle, typoStyle } from '../../../../Styles/ui';
import MyTeam from '../../screens/MyTeam';
import SearchFilter from '../../screens/SearchFilter';
import InterestedPlayer from '../../screens/InterestedPlayer';

const Tabs = [
  { path: '', title: 'soloRank.tab.myTeam' },
  { path: 'searchFilter', title: 'soloRank.tab.filterSearch' },
  { path: 'interestedPlayer', title: 'soloRank.tab.interestedPlayer' },
];

const SoloRankTab = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <SContainer>
      {/* 탭 헤더 */}
      <STab>
        {Tabs.map((tab, i) => {
          const active = i === 0 ? pathname.split('/')[2] === undefined : pathname.split('/')[2] === tab.path;
          return (
            <STabItem key={tab.title} active={active}>
              <SLink to={tab.path}>{t(tab.title)}</SLink>
            </STabItem>
          );
        })}
      </STab>

      {/* 탭 컨텐츠 */}
      <Outlet />
    </SContainer>
  );
};

export default SoloRankTab;

const SContainer = styled.section`
  width: 1120px;
  ${typoStyle.contents}
`;

const STab = styled.ul`
  display: flex;
  width: 100%;
  height: 62px;
  // border-bottom: 1px solid ${colors.border_light};
  //margin-bottom: 20px;
`;

const STabItem = styled.li<{ active: boolean }>`
  position: relative;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: ${({ active }) => (active ? 1 : 0.3)};

  ${typoStyle.body}
  ${transitionStyle.opacity};

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 120px;
    height: 1px;
    /* background: ${colors.text}; */
    opacity: 0;
    ${transitionStyle.opacity};
  }

  &.is-active {
    opacity: 1;
    color: ${colors.text};

    &::after {
      opacity: 1;
    }
  }
`;

const SContents = styled.div`
  // ${testStyle.border2}
  margin-bottom: 80px;
`;
const SLink = styled(Link)`
  font-size: 18px;
`;
