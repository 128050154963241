import React, { useEffect, useState } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { spacing, transitionStyle, colors } from '../../Styles/ui';
import { PathAnalysisResultAtom } from '../../recoil/Filter/pathAnalysisAtom';

const Tabs = [
  { path: '', title: 'video.tab.ward' },
  { path: 'wardPathing', title: 'video.tab.wardPathing' },
  // { path: 'objectPathing', title: 'video.tab.object' },
  { path: 'junglePathing', title: 'video.tab.jungle' },
  { path: 'heatmap', title: 'video.tab.heatmap' },
];

function VideoTabs() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [result, setResult] = useRecoilState(PathAnalysisResultAtom);


  useEffect(() => {
    return () => {
      setResult(undefined);
    };
  }, []);

  return (
    <VideoTabsWrapper>
      {result && (
        <>
          <TabHeader>
            {Tabs.map((tab, i) => {
              const active =
                i === 0 ? pathname.split('/')[2] === undefined : pathname.split('/')[2] === tab.path;
              return (
                <Tab key={tab.title} active={active}>
                  <SLink to={tab.path}>{t(tab.title)}</SLink>
                </Tab>
              );
            })}
          </TabHeader>
          <Outlet />
        </>
      )}
    </VideoTabsWrapper>
  );
}

export default VideoTabs;

const VideoTabsWrapper = styled.div`
  margin: 21px 0 25px 22px;
  width: 1097px;
`;

const TabHeader = styled.ul`
  display: flex;
  ${spacing.marginB(5)};
`;

const Tab = styled.li<{ active: boolean; disabled?: boolean }>`
  ${spacing.paddingX(4)}
  ${spacing.paddingY(3)}
  ${transitionStyle.opacity}
  color: ${colors.text};
  opacity: ${({ active }) => (active ? 1 : 0.3)};
  /* border-bottom: 1px solid transparent; */
  border-color: ${({ active }) => (active ? colors.text : 'transparent')};

  &:hover {
    opacity: 1;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
      user-select: none;
      border-bottom: none;
    `}
`;

const SLink = styled(Link)`
  font-size: 18px;
`;
