/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import TabTop from './Components/TabTop';
import LeagueServices from '../../../services/LeagueServices';
import { LeagueReportResultAtom } from '../../../recoil/Filter/leagueReportAtom';
import Progress from '../../../Components/Ui/Loading/Progress';
import NotFound from '../../../Components/Ui/Error/NotFound';
import { spacing } from '../../../Styles/ui';
import { Position } from '../../../services/types/league_services.type';

const TAB: Position[] = ['top', 'jng', 'mid', 'bot', 'sup'];

function LeaguePlayer() {
  const [positionClicked, setPositionClicked] = useState<Position>('top');
  const { t } = useTranslation();
  const result = useRecoilValue(LeagueReportResultAtom);

  const { mutate, data, isLoading, isError } = useMutation(({ position }: { position: Position }) =>
    LeagueServices.getLeaguePlayer({
      ...result,
      position,
    }),
  );

  useEffect(() => {
    mutate({
      position: positionClicked,
    });
  }, [positionClicked, result]);

  return (
    <LeaguePlayerWrapper>
      {result.league.includes('LPL') ? (
        <ERROR_CONTAINER>
          <NotFound
            css={{ height: '100%' }}
            text={
              <ERROR_TEXT>
                <p>{t('errorControllerMsg.leagueReportPlayerLPLError')}</p>
                <p>{t('errorControllerMsg.sorry')}</p>
              </ERROR_TEXT>
            }
          />
        </ERROR_CONTAINER>
      ) : (
        <>
          <LeaguePlayerTabs>
            {TAB.map((pos) => (
              <TabItem onClick={() => setPositionClicked(pos)} changeColor={positionClicked === pos}>
                <div>
                  <span>{t(`position.${pos}`)}</span>
                </div>
              </TabItem>
            ))}
            <LastMargin />
          </LeaguePlayerTabs>
          <TabContents>
            {isLoading ? (
              <ProgressContainer>
                <Progress text={t('solo.playerboard.dataFetching')} />/
              </ProgressContainer>
            ) : isError ? (
              <NotFound css={{ height: '100%' }} text={t('solo.playerboard.dataFetchingFailed')} />
            ) : data?.response ? (
              <TabTop playerDatas={data?.response} />
            ) : null}
          </TabContents>
        </>
      )}
    </LeaguePlayerWrapper>
  );
}

export default LeaguePlayer;

const LeaguePlayerWrapper = styled.div`
  height: 100%;
`;

const LeaguePlayerTabs = styled.div`
  display: flex;
  height: 62px;
  // margin-top: 21.5px;
`;
const LastMargin = styled.div`
  width: 73%;
  border-bottom: solid 1px #433f4e;
`;

const TabItem = styled.button<{ changeColor: boolean }>`
  display: flex;
  align-items: center;
  width: auto;
  border-bottom: solid 1px #433f4e;
  white-space: nowrap;

  div {
    padding: 10px 15px;
  }

  :hover {
    div {
      padding: 10px 15px;
      border-radius: 10px;
      background-color: #26262c;
    }
  }

  span {
    height: 22px;
    font-family: SpoqaHanSansNeo;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 18px;
    border-bottom: solid 1px ${(props) => (props.changeColor ? `#fff` : `none`)};
    color: ${(props) => (props.changeColor ? `#fff` : `#84818e`)};
  }
`;

const TabContents = styled.div`
  width: 100%;
  height: 100vh;
`;

const ProgressContainer = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const ERROR_CONTAINER = styled.div`
  background-color: #24222c;
  border-radius: 20px;
  ${spacing.paddingY(20)};
`;

const ERROR_TEXT = styled.div`
  text-align: center;
  font-size: 13px;
  p:first-of-type {
    ${spacing.marginB(2)}
  }
`;
