/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { UseMutateFunction } from 'react-query';
import Avatar from '../../../Components/Ui/Avatar';
import IconStar from '../../../Components/Ui/Icons/IconStar';
import { colors } from '../../../Styles/ui';
import SRContent from './Common/SRContent';
import NoneDatabox from '../../../Components/Ui/NoneDatabox';
import { BookMarkListInfoResponse, IResponseSuccess, Players } from '../../../services/SoloRankServices';

const IPActive = ({
  selectedDay,
  setSelectedDay,
  playerInfo,
  getInfoFunc,
}: {
  selectedDay: string;
  setSelectedDay: React.Dispatch<React.SetStateAction<string>>;
  playerInfo: Players[] | [];
  getInfoFunc: UseMutateFunction<IResponseSuccess<BookMarkListInfoResponse>, unknown, void, unknown>;
}) => {
  const { t } = useTranslation();
  return (
    <SWrapper>
      <SAlter>
        <Avatar
          css={css`
            margin-right: 5px;
            margin-top: 2px;
          `}
          src='/Images/ico-notice-gy.png'
          size={14}
        />
        {t('soloRank.InterestedPlayer.desc.activeGuide_1_ko')}
        <IconStar inactiveColor={colors.info} />
        {t('soloRank.InterestedPlayer.desc.activeGuide_2_ko')}
      </SAlter>
      {playerInfo.length > 0 ? (
        <SRContent
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          playerInfo={playerInfo}
          usingBookmark
          usingDeletePlayer={false}
          usingAddPlayer={false}
          usingDeleteSoloId
          usingAddSoloId={false}
          getInfoFunc={getInfoFunc}
          myTeamName=''
          usingDeletePlayerGuide={false}
        />
      ) : (
        <NoneDatabox
          headerText={t('soloRank.InterestedPlayer.desc.noDataHeader')}
          subText={t('soloRank.InterestedPlayer.desc.noDataSub')}
        />
      )}
    </SWrapper>
  );
};

export default IPActive;

const SWrapper = styled.div``;

const SAlter = styled.div`
  display: flex;
  margin-top: 1px;
  margin-bottom: 20px;
  //border-bottom: 1px solid ${colors.border_light};

  font-family: SpoqaHanSansNeo;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.info};
`;
