/**
 * title: 선수 기본 자료
 * 항목 : [ID ,name, 포지션, 나이, 평균퍼포먼스, 최고 솔로랭크 점수,시즌 전체 전적, 선택 기간 전적]
 */
export const playerLabels = [
  'Player ID',
  'Player name',
  'solo.playerboard.position',
  'solo.playerboard.country',
  'solo.playerboard.age',
  'solo.playerboard.avgScore',
  'solo.soloRank.maxSoloRankLP',
  'solo.soloRank.seasonTotalMatchHist',
  'solo.soloRank.selectedDaysMatchHist',
];

/**
 * title: 일반 지표
 * 항목 : [챔피언풀, 선픽률, 솔로킬, dpm, dtpm, 10분cs]
 */
export const commonLabels = [
  'league.playerStat.championPool',
  'league.playerStat.firstPickRate',
  'league.playerStat.solokill',
  'league.playerStat.dpm',
  'league.playerStat.dtpm',
  'league.playerStat.cs',
];

/**
 * tooltip contents : 일반 지표
 * 항목 : [챔피언풀, 선픽률, 솔로킬, dpm, dtpm, 10분cs]
 */
export const commonTooltipContents = [
  'tooltip.선수보고서.챔피언풀',
  'tooltip.선수보고서.선픽률',
  'tooltip.선수보고서.솔로킬',
  'tooltip.선수보고서.DPM',
  'tooltip.선수보고서.DTPM',
  'tooltip.선수보고서.10분CS',
];

/**
 * title: pr 세부지표
 * 항목 : [pr, 데미지, 생존, 킬캐치, 라인전, 초반교전]
 */
export const prLabels = [
  'league.playerStat.pr',
  'league.playerStat.damage',
  'league.playerStat.survival',
  'league.playerStat.killCatch',
  'league.playerStat.lanePhase',
  'league.playerStat.earlyFight',
];

/**
 * tooltip contents : pr 세부 지표
 * 항목 : [pr, 데미지, 생존, 킬캐치, 라인전, 초반교전]
 */
export const detailTooltipContents = [
  'tooltip.선수보고서.PR',
  'tooltip.선수보고서.데미지',
  'tooltip.선수보고서.생존',
  'tooltip.선수보고서.킬캐치',
  'tooltip.선수보고서.라인전',
  'tooltip.선수보고서.초반교전',
];

/**
 * title: soloRank table
 * 항목 : [선수 아이디, 티어, LP, 시즌 전체 전적, 승률, 기간 전체 전적]
 */

export const soloRankLabels = [
  'solo.soloRank.playerId',
  'solo.soloRank.soloRankTier',
  'LP',
  'solo.soloRank.seasonTotalMatchHist',
  'solo.comparison.winrate',
  'solo.soloRank.selectedDaysMatchHist',
];
