/** @jsxImportSource @emotion/react */
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import ReactModal from 'react-modal';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { createStore, compose } from 'redux';
//  recoil
import { RecoilRoot } from 'recoil';
import { Global } from '@emotion/react';
//  MUI
import { ThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
  Tooltip,
  BarElement,
  LineElement,
  RadialLinearScale,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import persistReducer from './redux/modules';
import './i18n';

import MUITheme from './Styles/theme/MUITheme';

// ui
import { GlobalStyles } from './Styles/GlobalStyles';

import App from './App';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Filler,
  RadialLinearScale,
  Legend,
  ChartDataLabels,
);

// production mode일 때 redux devtools 비활성화
const composeEnhancers =
  (process.env.NODE_ENV === 'development' && (window as any)?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

/* 리덕스 & 리덕스사가 & 세션스토리지에 리덕스 상태 보관 */

export const store = createStore(persistReducer, composeEnhancers());
const persistor = persistStore(store);
// export const navigate =  syncHistoryWithStore(createBrowserHistory, store);

/* 모달 렌더링 관련 */
ReactModal.setAppElement('#root');
const container = document.getElementById('root');
const root = createRoot(container!);
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY!);

root.render(
  // <StrictMode>
  <RecoilRoot>
    <Suspense fallback={null}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ThemeProvider theme={MUITheme}>
            <Global styles={GlobalStyles} />
            <App />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  </RecoilRoot>,
  // </StrictMode>,
);
