import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, ChartOptions } from 'chart.js';
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  borderRadiusStyle,
  colors,
  fontsStyle,
  scrollbarStyle,
  spacing,
  transitionStyle,
  typoStyle,
} from '../../../Styles/ui';
import Avatar from '../../../Components/Ui/Avatar';
import DropdownContainer from '../../../Components/Ui/DropDown/DropdownContainer';
import DropdownLabel from '../../../Components/Ui/DropDown/DropdownLabel';
import DropdownList from '../../../Components/Ui/DropDown/DropdownList';
import DropdownItem from '../../../Components/Ui/DropDown/DropdownItem';

import { getWinRate } from '../../../lib/getWinRate';
import Checkbox from '../../../Components/Ui/Checkbox';
import { getTrueValueList } from '../../../lib/getTureValueList';
import { getPositon } from '../../../lib/getPosition';
import { IPlayerDetailResponse } from '../../../services/types/player_services.type';
import { patchsort } from '../../../lib/sort/patchsort';
import theme from '../../../Styles/theme/MUITheme';

ChartJS.register(ArcElement);

function nanReturnZero(value: number) {
  if (Number.isNaN(value)) {
    return 0;
  }
  return value;
}

const options: ChartOptions<'doughnut'> = {
  maintainAspectRatio: false,
  responsive: true,
  cutout: '80%',
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Doughnut Chart',
    },
    datalabels: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

const POSITION = ['top', 'jng', 'mid', 'bot', 'sup'] as const;

const TABLEITEM = [
  {
    value: 'all',
    text: 'position.all',
  },
  {
    value: 'top',
    text: 'position.top',
  },
  {
    value: 'jng',
    text: 'position.jng',
  },
  {
    value: 'mid',
    text: 'position.mid',
  },
  {
    value: 'bot',
    text: 'position.bot',
  },
  {
    value: 'sup',
    text: 'position.sup',
  },
];

const 데이터만들기 = (detailData: IPlayerDetailResponse, selectedPatch: any) => {
  let rowCnt = 0;
  const allMatch = {
    total: 0,
    win: 0,
    lose: 0,
  };

  const allKDA = {
    kill: 0,
    death: 0,
    assist: 0,
    kp: 0,
  };
  const lineGames = {
    top: 0,
    jng: 0,
    mid: 0,
    bot: 0,
    sup: 0,
  };
  const championSet: { [key: string]: any } = {};

  selectedPatch.forEach((patch: keyof typeof detailData) => {
    rowCnt += detailData[patch].length;

    detailData[patch].forEach((data) => {
      allMatch.total += data.total ?? 0;
      allMatch.win += data.win ?? 0;
      allMatch.lose += data.lose ?? 0;
      allKDA.kill += data.kill ?? 0;
      allKDA.death += data.death ?? 0;
      allKDA.assist += data.assist ?? 0;
      allKDA.kp += data.kp ?? 0;
      lineGames[getPositon({ role: data.role })] += data.total ?? 0;

      const key = `${data.champion}_${data.role}`;
      if (Object.keys(championSet).includes(key)) {
        championSet[key].kill += data.kill;
        championSet[key].death += data.death;
        championSet[key].assist += data.assist;
        championSet[key].total += data.total;
        championSet[key].win += data.win;
        championSet[key].lose += data.lose;
      } else {
        championSet[key] = { ...data };
        delete championSet[key].patch;
      }
    });

    allKDA.kp /= rowCnt / 10;
  });

  return {
    championSet,
    allMatch,
    allKDA,
    lineGames,
  };
};

interface P {
  patchState: { [key: string]: boolean };
  detailData: IPlayerDetailResponse;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

//* MUI Select Svg Icon
const useStyles = makeStyles({
  select: {
    '&:before': {
      borderColor: colors.text,
    },
    '&:after': {
      borderColor: colors.text,
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: colors.text,
    },
  },
  icon: {
    marginRight: '10px',
    fill: colors.text,
  },
  root: {
    color: colors.text,
  },
});

const SoloRankTable: React.FC<P> = ({ patchState, detailData, onChange }) => {
  const { t } = useTranslation();
  const [tableValue, setTableValue] = useState('all');
  const selectedPatch = getTrueValueList(patchState);
  const classes = useStyles();

  const { allKDA, allMatch, lineGames, championSet } = 데이터만들기(detailData, selectedPatch);

  const data = allKDA && {
    labels: [t('common.label.lose'), t('common.label.win')],
    datasets: [
      {
        data: [allMatch.lose, allMatch.win],
        backgroundColor: [colors.badge_red, colors.blue],
        borderWidth: 0,
        weight: 3,
      },
    ],
  };

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    if (value.length > 0) setTableValue(value);
  };

  return (
    <Wrapper>
      <PatchContainer>
        <STitle>{t('video.jungle.patch')}</STitle>
        <SCheckboxAll
          name='patch'
          value='all'
          onChange={onChange}
          checked={Object.keys(patchState).length > 0 && !Object.values(patchState).includes(false)}
        >
          {t('video.jungle.selectAll')}
        </SCheckboxAll>
        <SCheckboxWrapper>
          {Object.keys(patchState)
            .sort(patchsort)
            .map((patch) => (
              <Checkbox
                css={{
                  width: 'calc(10% - 22px)',
                  justifyContent: 'flex-start',
                }}
                key={patch}
                name='patch'
                value={patch}
                onChange={onChange}
                checked={patchState[patch]}
              >
                {patch}
              </Checkbox>
            ))}
        </SCheckboxWrapper>
      </PatchContainer>
      {/* 하단내용 시작 */}
      {
        <FlexContainer>
          {/* 그래프영역 */}
          <div>
            {/* 그래프 */}
            <GraphContainer>
              <DoughnutContainer>
                <DoughnutText>
                  <h6>{t('common.label.winrate')}</h6>
                  <p>{nanReturnZero(Math.round((allMatch.win / allMatch.total) * 100))}%</p>
                </DoughnutText>
                <Doughnut data={data} options={options} />
              </DoughnutContainer>

              <GraphText>
                <div>
                  <dt>{t('solo.soloRank.allMatchCnt')}</dt>
                  <dd>{`${allMatch.total}${t('common.label.game')} ${allMatch.win}${t('common.label.win')} ${
                    allMatch.lose
                  }${t('common.label.lose')}`}</dd>
                </div>
                <div>
                  <dt>{t('game.summary.champion.kda')}</dt>
                  <dd>
                    {`${nanReturnZero(Math.round((allKDA.kill * 10) / allMatch.total) / 10)} /
                      ${nanReturnZero(Math.round((allKDA.death * 10) / allMatch.total) / 10)} /
                      ${nanReturnZero(Math.round((allKDA.assist * 10) / allMatch.total) / 10)}`}
                  </dd>
                </div>
                <div>
                  <dt>{t('common.label.kpPercentage')}</dt>
                  <dd>
                    {`${nanReturnZero(
                      Math.round((100 * (allKDA.kill + allKDA.assist)) / allKDA.death) / 100,
                    )} : 1`}
                    <em>{nanReturnZero(Math.round(allKDA.kp))}%</em>
                  </dd>
                </div>
              </GraphText>
            </GraphContainer>

            {/* 포지션 */}
            <PositionContainer>
              {POSITION.map((pos) => (
                <PositionBox key={`position_box${pos}`}>
                  <div>
                    <img src={`/Images/position/ico-position-${pos}.svg`} alt={pos} />
                    <span>{pos}</span>
                  </div>
                  <div>
                    <span>{nanReturnZero(Math.round((lineGames[pos] / allMatch.total) * 100))}%</span>
                    <span>
                      {lineGames[pos]}
                      {t('common.label.game')}
                    </span>
                  </div>
                </PositionBox>
              ))}
            </PositionContainer>
          </div>

          {/* 테이블 영역 */}
          <ColContainer>
            <Title>
              <h4>{t('solo.soloRank.playedChampion')}</h4>
              <div>
                <Avatar
                  css={{ marginRight: 4 }}
                  block={false}
                  src='/Images/ico-notice-gy.png'
                  alt='alarm'
                  size={14}
                />
                <span>{t('solo.soloRank.desc.playedChampion')}</span>
              </div>
            </Title>

            <TableContainer>
              {/* 드롭다운 */}
              <Select
                labelId='player-solorank-position-select-label'
                id='player-solorank-position-select'
                className={classes.select}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                    root: classes.root,
                  },
                  MenuProps: {
                    MenuListProps: {
                      sx: {
                        backgroundColor: colors.bg_box,
                        color: colors.text,
                      },
                    },
                  },
                }}
                value={tableValue}
                label='position'
                onChange={handleChange}
                variant='standard'
                disableUnderline
                sx={{
                  fontWeight: 400,
                  boxShadow: 'none',
                  padding: '20px 16px',
                  '.MuiSelect-select': {
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}
              >
                {TABLEITEM.map((pos) => (
                  <MenuItem
                    value={pos.value}
                    sx={{
                      '&.Mui-selected': {
                        backgroundColor: 'red',
                        color: colors.text,
                        fontWeight: 600,
                      },
                    }}
                  >
                    <Avatar block={false} size={20} src={`/Images/position/ico-position-${pos.value}.svg`} />
                    <Typography
                      color={colors.text}
                      fontFamily='SpoqaHanSansNeo'
                      fontWeight={400}
                      fontSize={16}
                      pl={1}
                    >
                      {t(pos.text)}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
              <TableHeader>
                {/* <DropdownContainer defaultValue={tableValue} onChange={handleChange}>
                  <SDropdownLabel change={false}>
                    <div className='caret'>&#xE000;</div>
                    <PositionLabel>
                      <Avatar
                        block={false}
                        size={20}
                        src={`/Images/position/ico-position-${tableValue}.svg`}
                      />
                      {t(`position.${tableValue}`)}
                    </PositionLabel>
                  </SDropdownLabel>

                  <DropdownList listStyle={ListStyle}>
                    {TABLEITEM.map((pos) => (
                      <SDropdownItem
                        IsActive={pos.value === tableValue}
                        value={pos.value}
                        key={`select${pos.value}`}
                      >
                        <PositionItem>
                         
                        </PositionItem>
                      </SDropdownItem>
                    ))}
                  </DropdownList>
                </DropdownContainer> */}
              </TableHeader>

              {/* 테이블 */}
              <TableContents>
                <table>
                  <thead>
                    <tr>
                      <th>{t('common.label.champion')}</th>
                      <th>{t('common.label.game')}</th>
                      <th>{t('common.label.win')}</th>
                      <th>{t('common.label.lose')}</th>
                      <th>{t('common.label.winrate')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(championSet).map((row: keyof typeof championSet) => {
                      const data = championSet[row];

                      return (
                        (tableValue === 'all' || getPositon(data.role) === tableValue) && (
                          <tr>
                            <td>
                              <ChampionInfo>
                                <Avatar
                                  block={false}
                                  src={`/Images/champion/${data.champion}.png`}
                                  size={26}
                                />
                                {data.champion}
                              </ChampionInfo>
                            </td>
                            <td>{data.total}</td>
                            <td>{data.win}</td>
                            <td>{data.lose}</td>
                            <td>{getWinRate(+data.total, +data.win, 0)}</td>
                          </tr>
                        )
                      );
                    })}
                  </tbody>
                </table>
              </TableContents>
            </TableContainer>
          </ColContainer>
        </FlexContainer>
      }
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${spacing.paddingX(6)};
  ${spacing.paddingY(10)};
`;

const FlexContainer = styled.div`
  display: flex;
  ${spacing.marginX(-2)}

  > div {
    ${spacing.marginX(2)}
    &:first-of-type {
      flex: 1;
    }

    &:last-of-type {
      flex: 1.3;
    }
  }
`;
const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const GraphContainer = styled.div`
  background-color: ${colors.bg_select};
  display: flex;
  align-items: center;
  ${spacing.paddingX(5)}
  ${borderRadiusStyle[20]}
  margin-bottom: 20px;
`;
const DoughnutContainer = styled.div`
  position: relative;
  width: 120px;
  ${spacing.paddingY(8)}
  ${spacing.marginR(4)}
`;
const DoughnutText = styled.div`
  position: absolute;
  left: 50%;
  top: 48%;
  transform: translate(-50%, -50%);
  text-align: center;

  h6 {
    ${fontsStyle.size[13]}
    color: ${colors.vs}
  }
  p {
    ${fontsStyle.size[24]}
    line-height: 28px;
  }
`;
const GraphText = styled.dl`
  > div {
    display: flex;
    dt {
      ${typoStyle.table_head}
      width: 100px;
    }

    dd {
      em {
        color: ${colors.badge_red};
        ${spacing.marginL(3)}
      }
    }
    &:not(:last-of-type) {
      ${spacing.marginB(2)}
    }
  }
`;
const PositionContainer = styled.ul`
  display: flex;
  ${spacing.marginX(-1)}
`;
const PositionBox = styled.li`
  flex: 1 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${colors.bg_select};
  ${borderRadiusStyle[20]}
  ${spacing.marginX(1)}
  ${spacing.paddingT(4)}
  ${spacing.paddingB(6)}

  > div:first-of-type {
    text-align: center;
    ${spacing.marginB(2)};
    img {
      display: block;
      width: 32px;
      height: 32px;
    }
    span {
      text-transform: uppercase;
      font-size: 10px;
      line-height: 0;
    }
  }

  > div:last-of-type {
    span {
      display: block;
      text-align: center;
      ${spacing.marginB(1)}

      &:first-of-type {
        ${typoStyle.body}
        font-weight: bold;
      }
      &:last-of-type {
        ${typoStyle.info}
      }
    }
  }
`;

// 드롭다운
const SDropdownLabel = styled(DropdownLabel)`
  display: flex;
  ${typoStyle.contents_title}
  cursor: pointer;
  .caret {
    transform: rotate(-90deg);
    font-size: 11px;
    ${spacing.marginR(2)}
  }
`;
const SDropdownItem = styled(DropdownItem)`
  ${spacing.marginX(2)}
  ${spacing.marginY(1)}
  ${spacing.paddingX(5)}
  ${borderRadiusStyle.full}
  ${transitionStyle.background}
  background-color: ${({ IsActive }) => (IsActive ? colors.bg_box_hover : 'transparent')};
  &:first-of-type {
    ${spacing.marginT(2)}
  }
  &:last-of-type {
    ${spacing.marginB(2)}
  }
  &:hover {
    background-color: ${colors.bg_box_hover};
  }
`;
// 드롭다운 - 컨텐츠
const PositionLabel = styled.div`
  display: flex;
  align-items: center;
  pointer-events: none;
`;
const PositionItem = styled(PositionLabel)`
  ${spacing.paddingY(2)}
  ${typoStyle.select}
  > div {
    ${spacing.marginR(1)}
  }
`;

// 테이블
const Title = styled.div`
  display: flex;
  align-items: center;
  ${spacing.marginB(3)}

  h4 {
    ${typoStyle.body_title}
    ${spacing.marginR(4)}
  }

  div {
    display: flex;
    align-items: center;
    > span {
      ${typoStyle.info}
    }
  }
`;

const TableContainer = styled.div`
  flex: 1;
  background-color: ${colors.bg_select};
  ${borderRadiusStyle[20]}
  display: flex;
  flex-direction: column;
`;

const TableHeader = styled.div``;

const ChampionInfo = styled.div`
  display: flex;
  align-items: center;
  > div {
    ${spacing.marginR(1)}
  }
`;

const TableContents = styled.div`
  position: relative;
  height: 280px;
  overflow: auto;
  ${scrollbarStyle.scroll_4}

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;

    th,
    td {
      text-align: center;
      vertical-align: middle;
      ${spacing.paddingY(1)}
      &:first-of-type {
        text-align: left;
        ${spacing.paddingL(8)}
      }
    }
    /* 테이블 머리 , 레이아웃 간격 */
    thead {
      position: sticky;
      top: 0;
      background-color: ${colors.bg_checkbox};
      color: ${colors.vs};

      th {
        &:nth-of-type(1) {
          width: 55%;
        }
        &:nth-of-type(2) {
          width: 10%;
        }
        &:nth-of-type(3) {
          width: 8%;
        }
        &:nth-of-type(4) {
          width: 8%;
        }
        &:nth-of-type(5) {
          width: 19%;
        }
      }
    }

    /* 테이블 몸통 */
    tbody {
      tr {
        border-bottom: 1px solid ${colors.bg_checkbox};
      }
    }
  }
`;

const ListStyle = css`
  min-width: 130px;
  background-color: ${colors.bg_box};
  top: calc(100% + 8px);
`;
const PatchContainer = styled.div`
  ${spacing.paddingB(4)}
  ${spacing.marginB(5)}
  border-bottom: 1px solid ${colors.border_light};
  display: flex;
`;

const STitle = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
`;

const SCheckboxAll = styled(Checkbox)`
  opacity: ${(props) =>
    props.name === 'year' ||
    props.name === 'team' ||
    props.name === 'player' ||
    (props.pathname === '/playerReport' && props.name === 'league')
      ? 0.3
      : 1};
  margin-right: 37px;
`;

const SCheckboxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;

  label {
    margin-right: 22px;
    width: auto;
  }
`;

export default SoloRankTable;
