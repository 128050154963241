import React, { useCallback, useMemo } from 'react';

import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Avatar } from '@mui/material';
import { styled as MUIstyled } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { colors } from '../../../../Styles/ui';
// ui
import EventLog from '../../../../Components/Ui/Dialog/EventLog';
// style
import { scrollbarStyle, typoStyle } from '../../../../Styles/ui';
import { COORDINATES, JungleMonsterNumber } from '../../../../lib/mapping/map_jungle_mosters';
import { userLanguageAtom } from '../../../../recoil/Auth/userAtom';
import { applyPostPosition } from '../../../../lib/applyPostposition';
import { jungleSideFilterResultAtom } from '../../../../recoil/JunglePathing/junglePathingAtom';

// 이미지 url용
const JUNGLE_MONSTERS = {
  '-1': 'Top',
  '-2': 'Mid',
  '-3': 'Bot',
  '-4': 'First Return',
  0: 'Blue Sentinel', // [180,327]
  1: 'Gromp', // [106,303]
  2: 'Murk Wolves', // [183,396]
  3: 'Raptors', // [340, 456]
  4: 'Red Brambleback', // [370,520]
  5: 'Krugs', // [405,584] // 바론 앞
  6: 'Scuttler', // [163,179]
  7: 'Blue Sentinel', // [534,374]
  8: 'Gromp', // [608,398]
  9: 'Murk Wolves', // [528, 310]
  10: 'Raptors', // [374, 248]
  11: 'Red Brambleback', // [340,181]
  12: 'Krugs', // [312,120]
  13: 'Scuttler', // [459,432] // 용 앞
  20: 'oceandragon',
  30: 'riftherald',
  40: 'infernaldragon',
  50: 'mountaindragon',
  60: 'baronnashor',
  70: 'clouddragon',
  80: 'hextechdragon',
  90: 'chemtechdragon',
  100: 'elderdragon',
};

const convertMonsterId = (id) => {
  if (Number(id) < 20) {
    return id;
  }
  return parseInt(Number(id) / 10) * 10;
};

const SequenceDialog = ({ ...props }) => {
  const junglevalue = useSelector((state) => state.JungleMapReducer);
  const sideFilterResult = useRecoilValue(jungleSideFilterResultAtom);
  const lang = useRecoilValue(userLanguageAtom);
  const { t } = useTranslation();

  // 임시 주석처리 - 재사용 예정

  // const displayDialog = (item) => {
  //   switch (item.result) {
  //     item.result -> -1 : 몬스터 정글링, 0 : 갱킹 실패, 1: 플래쉬 뺌(갱성공), 2: 킬(갱성공)
  //     case -1:
  //       정글 몬스터 잡은 경우
  //       if (item.monsterid === -4) {
  //         return t(COORDINATES[item.monsterid].name);
  //       }
  //       if (lang === 'ko') {
  //         return ` ${t(COORDINATES[item.monsterid].name)} ${t('video.jungle.junglingSuccess')} ${t(
  //           'video.jungle.junglingSuccess2',
  //         )}`;
  //       }
  //       if (lang === 'en') {
  //         return ` ${t('video.jungle.junglingSuccess')} ${t(COORDINATES[item.monsterid].name)}`;
  //       }
  //       break;
  //     case 0:
  //        갱킹 실패
  //       if (item.monsterid === -1 || item.monsterid === -2 || item.monsterid === -3) {
  //         return `${t(COORDINATES[item.monsterid].name)} ${t('video.jungle.gankingFail')}`;
  //       }
  //       FIRST RETURN
  //       if (item.monsterid === -4) {
  //         return `${item.champ}${t(COORDINATES[item.monsterid].name)}`;
  //       }
  //       break;
  //     case 1:
  //       갱킹 성공 (플래쉬 뺌)
  //       if (item.monsterid === -1 || item.monsterid === -2 || item.monsterid === -3) {
  //         return `${t(COORDINATES[item.monsterid].name)} ${t('video.jungle.gangkingSuccess2')}`;
  //       }
  //       FIRST RETURN
  //       if (item.monsterid === -4) {
  //         return t(COORDINATES[item.monsterid].name);
  //       }
  //       break;
  //     case 2:
  //       갱킹 성공 (킬)
  //       if (item.monsterid === -1 || item.monsterid === -2 || item.monsterid === -3) {
  //         return `${t(COORDINATES[item.monsterid].name)}${t('video.jungle.gangkingSuccess3')}`;
  //       }
  //       FIRST RETURN
  //       if (item.monsterid === -4) {
  //         return t(COORDINATES[item.monsterid].name);
  //       }
  //       break;
  //     default:
  //       break;
  //   }
  // };

  // 시연 용도
  const displayDialog = (item) => {
    if (convertMonsterId(item.monsterid) === -4) {
      return t(COORDINATES[convertMonsterId(item.monsterid) as JungleMonsterNumber].name);
    }

    if (lang === 'ko') {
      return ` ${applyPostPosition(
        t(COORDINATES[convertMonsterId(item.monsterid) as JungleMonsterNumber].name),
        '을',
        '를',
      )} ${t('video.jungle.junglingSuccess')} ${t('video.jungle.junglingSuccess2')}`;
    }

    if (lang === 'en') {
      return ` ${t('video.jungle.junglingSuccess')} ${t(
        COORDINATES[convertMonsterId(item.monsterid) as JungleMonsterNumber].name,
      )}`;
    }
  };

  return (
    <>
      {sideFilterResult && (
        <SContainer {...props}>
          <SDialogContainer color='red'>
            <h4>
              <em aria-hidden />
              RED {t('video.jungle.side')}
            </h4>
            {junglevalue?.junglingCampData?.[0].side === 'blue' ||
            junglevalue?.junglingCampData === 'NODATA' ? (
              <NoData>{`${t('video.jungle.noCorrectSide')}`}</NoData>
            ) : (
              <SEventlogContainer>
                {junglevalue?.junglingCampData?.map((camp, idx) => {
                  const { monsterid } = camp;
                  const covertedMonsterId = convertMonsterId(monsterid);

                  return (
                    camp.side === 'red' && (
                      <EventLog
                        index={idx + 1}
                        time={`${Math.floor(camp.realCount / 60)} ${t('video.jungle.min')} 
                    ${Math.floor(camp.realCount % 60)}  ${t('video.jungle.sec')}`}
                        color={camp.side}
                      >
                        <SMessage>
                          <MUIAvatar
                            color={camp.side}
                            src={`/Images/champion/${camp.champ}.png`}
                            alt='champImg'
                          />
                          {`${
                            lang === 'en'
                              ? camp.champ
                              : applyPostPosition(camp.champ_h.replace(' ', ''), '이', '가')
                          } `}
                          <MUIAvatar
                            color={
                              covertedMonsterId < 7 ? 'red' : covertedMonsterId < 20 ? 'blue' : undefined
                            }
                            src={`/Images/jungleMonster/${
                              JUNGLE_MONSTERS[covertedMonsterId as JungleMonsterNumber]
                            }.png`}
                            alt={`${JUNGLE_MONSTERS[covertedMonsterId as JungleMonsterNumber]}`}
                          />
                          {displayDialog(camp)}
                        </SMessage>
                      </EventLog>
                    )
                  );
                })}
              </SEventlogContainer>
            )}
          </SDialogContainer>

          <SDialogContainer color='blue'>
            <h4>
              <em aria-hidden />
              BLUE {t('video.jungle.side')}
            </h4>
            {junglevalue?.junglingCampData?.[0].side === 'red' ||
            junglevalue?.junglingCampData === 'NODATA' ? (
              <NoData>{`${t('video.jungle.noCorrectSide')}`}</NoData>
            ) : (
              <SEventlogContainer>
                {junglevalue?.junglingCampData?.map((camp, idx) => {
                  const { monsterid } = camp;
                  const covertedMonsterId = convertMonsterId(monsterid);
                  return (
                    camp.side === 'blue' && (
                      <EventLog
                        index={idx + 1}
                        time={`${Math.floor(camp.realCount / 60)} ${t('video.jungle.min')}
              ${Math.floor(camp.realCount % 60)} ${t('video.jungle.sec')}`}
                        color={camp.side}
                      >
                        <SMessage>
                          <MUIAvatar
                            color={camp.side}
                            src={`/Images/champion/${camp.champ}.png`}
                            alt='champImg'
                          />
                          {`${
                            lang === 'en'
                              ? camp.champ
                              : applyPostPosition(camp.champ_h.replace(' ', ''), '이', '가')
                          } `}
                          <MUIAvatar
                            color={
                              covertedMonsterId < 7 ? 'red' : covertedMonsterId < 20 ? 'blue' : undefined
                            }
                            src={`/Images/jungleMonster/${
                              JUNGLE_MONSTERS[covertedMonsterId as JungleMonsterNumber]
                            }.png`}
                            alt={`${JUNGLE_MONSTERS[covertedMonsterId as JungleMonsterNumber]}`}
                          />
                          {displayDialog(camp)}
                        </SMessage>
                      </EventLog>
                    )
                  );
                })}
              </SEventlogContainer>
            )}
          </SDialogContainer>
        </SContainer>
      )}
    </>
  );
};

const SContainer = styled.div`
  display: flex;

  div {
    flex: 1;
  }

  > div:first-of-type {
    margin-right: 20px;
  }
`;

const SDialogContainer = styled.div`
  background-color: ${colors.bg_select};
  border-radius: 20px;

  h4 {
    display: flex;

    ${typoStyle.contents_title}
    padding: 10px 15px;

    em {
      display: inline-block;
      margin-right: 8px;
      width: 3px;
      height: 20px;
      background-color: ${({ color }) => (color === 'red' ? colors.badge_red : colors.badge_blue)};
    }
  }
`;

const SEventlogContainer = styled.div`
  padding: 0 10px;
  margin-bottom: 20px;
  height: 220px;
  overflow-y: overlay;
  ${scrollbarStyle.scroll_4}

  > div:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const SMessage = styled.div`
  ${typoStyle.eventlog}
`;

const NoData = styled.div`
  padding: 100px 50px;
  text-align: center;
`;

const MUIAvatar = MUIstyled(Avatar)<{ color: string | undefined }>(({ color }) => ({
  display: 'inline-block',
  verticalAlign: 'middle',
  width: '16px',
  height: '16px',
  fontSize: 0,
  border: `1px solid ${color === undefined ? '#f39c12' : color === 'red' ? colors.badge_red : colors.blue}`,
}));

export default SequenceDialog;
