import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { useMutation } from 'react-query';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import DetailLog from './DetailLogOption/DetailLog';
import TeamStatus from './TeamStatusOption/TeamStatus';
import TimeLine from './TimeLineOption/TimeLine';
import Summary from './SummaryOption/Summary';
import VideoPlayer from './Video/VideoPlayer.jsx';
import { RootState } from '../../../redux/modules';

import GameServices, {
  ActionLog,
  EventsLog,
  GameDetailDataResponse,
  TimeLineSet,
  Infos,
  Log,
  Player,
  TeamGold,
  Mapping,
  Live,
  Status,
} from '../../../services/GameServices';

import { GameId } from '../../../services/types/param_types';
import secToMS from '../../../lib/secToMS';
import { useModal } from '../../../Hooks';
import { modalList } from '../../../Components/Modals/Modals';

import GameDatasetAtom from '../../../recoil/ProGameReport/gameDatasetAtom';
import { goProGameReport } from '../../../lib/pagePath';
import { colors } from '../../../Styles/ui';
import Progress from '../../../Components/Ui/Loading/Progress';
import GameAtom from '../../../recoil/ProGameReport/gameAtom';
import { useResetProGameReport } from '../../../Hooks/useResetProGameReport';
import VideoAtom from '../../../recoil/ProGameReport/videoAtom';

const GameReportDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const param = useParams();
  const [isDone, setIsDone] = useState<boolean>(false);

  const setFixedDataset = useSetRecoilState(GameDatasetAtom.fixedDataset);
  const setPlayerDataset = useSetRecoilState(GameDatasetAtom.playerDataset);
  const setLogDataset = useSetRecoilState(GameDatasetAtom.logDataset);
  const setStatusLogDataset = useSetRecoilState(GameDatasetAtom.statusLogDataset);
  const setTeamGoldDataset = useSetRecoilState(GameDatasetAtom.teamGoldDataset);
  const setMappingDataset = useSetRecoilState(GameDatasetAtom.mappingDataset);
  const setLiveDataset = useSetRecoilState(GameDatasetAtom.liveDataset);
  const setPlayersStatusDataset = useSetRecoilState(GameDatasetAtom.playersStatusDataset);
  const setTimeLineDataset = useSetRecoilState(GameDatasetAtom.timeLineDataset);

  const videoUrl = useRecoilValue(VideoAtom.vodUrl);
  const gameTime = useRecoilValue(GameAtom.gameTime);

  // 리셋.
  useResetProGameReport();

  const idx = useRecoilValue(GameAtom.playersActiveIdx);

  const onCancel = () => {
    navigate(goProGameReport);
  };

  const {
    mutate: getGameReportDetail,
    data,
    isLoading,
  } = useMutation(
    ({ gameId }: { gameId: GameId }) =>
      GameServices.getGameDetailData({
        gameid: gameId,
      }),
    {
      onSuccess: (data) => {
        const e: GameDetailDataResponse = data.response;

        if (Object.entries(e).filter((a) => a[1].length === 0).length > 0) {
          noDataModal();
        } else if (e?.infos.length === 2) {
          const roming: ActionLog[] = e.actionLog.filter((e) => e.type === 'Roaming');
          const ganking: ActionLog[] = e.actionLog.filter((e) => e.type === 'Ganking');
          const timefight: ActionLog[] = e.actionLog.filter((e) => e.type === 'matchLog');
          const blueKills: EventsLog[] = e.log.events.filter(
            (e) => e.type === 'CHAMPION_KILL' && e.participantid < 6,
          );
          const redKills: EventsLog[] = e.log.events?.filter(
            (e) => e.type === 'CHAMPION_KILL' && e.participantid > 5,
          );
          const buildDestroy: EventsLog[] = e.log.events?.filter((e) => e.type === 'BUILDING_KILL');
          const objectKill: EventsLog[] = e.log.events?.filter(
            (e) => e.type === 'ELITE_MONSTER_KILL' && ['RIFTHERALD', 'BARON_NASHOR'].includes(e.subType),
          );
          const dragonKill: EventsLog[] = e.log.events?.filter(
            (e) => e.type === 'ELITE_MONSTER_KILL' && e.subType.includes('DRAGON'),
          );

          let tgRcIdx = 0;
          const teamGoldX: string[] = [];
          const teamGoldY: (number | undefined)[] = [];
          let teamGoldMax = 0;

          for (let i = 0; i < gameTime; i += 1) {
            teamGoldX.push(secToMS(i));
            if (e.teamGold.length > tgRcIdx && i === e.teamGold[tgRcIdx].realCount) {
              const dataTeamGold = e.teamGold[tgRcIdx];
              const dataTeamGoldGold = dataTeamGold.blueGold - dataTeamGold.redGold;

              teamGoldY.push(dataTeamGoldGold);
              if (teamGoldMax < Math.abs(dataTeamGoldGold)) {
                teamGoldMax = Math.abs(dataTeamGoldGold);
              }
              tgRcIdx += 1;
            } else {
              teamGoldY.push(undefined);
            }
          }

          const timeLineSet: TimeLineSet = {
            roming,
            ganking,
            timefight,
            blueKills,
            redKills,
            buildDestroy,
            objectKill,
            dragonKill,
            teamGoldX,
            teamGoldY,
            teamGoldMax,
          };

          e?.log.events.unshift({
            participantid: 0,
            subType: 'NONE',
            type: 'NONE',
            realCount: 0,
            victimId: 0,
          });

          e?.actionLog.unshift({
            type: 'NONE',
            realCount: 0,
            data: {
              participant: 0,
              dest: 0,
              position: '',
              realCount: 0,
              partis: [0],
            },
          });

          e?.players.forEach((data) => {
            data.currentItem.unshift({
              realCount: 0,
              items: [],
              trinket: 3340,
            });
          });

          setFixedDataset(e?.infos);
          setPlayerDataset(e?.players);
          setLogDataset(e?.log);
          setMappingDataset(e?.mapping);
          setLiveDataset(e?.live);
          setTeamGoldDataset(e?.teamGold);
          setStatusLogDataset(e?.actionLog);
          setPlayersStatusDataset(e?.status);
          setTimeLineDataset(timeLineSet);
          setIsDone(true);

          console.log('e', e);
          console.log('idx', idx);
        } else {
          noDataModal();
        }
      },
    },
  );

  useEffect(() => {
    if (typeof param.id === 'string' && videoUrl) {
      getGameReportDetail({ gameId: param.id });
    } else {
      navigate(goProGameReport);
    }
  }, []);

  function noDataModal() {
    const title = 'No Data';
    const text = t('game.eachMatch.noneData');
    const subtext = undefined;
    openModal(modalList.alert, { title, text, subtext, onCancel });
  }

  if (document.title !== `${t('sidebar.part12')} - NUNU.GG`) {
    document.title = `${t('sidebar.part12')} - NUNU.GG`;
  }

  if (!param.id) {
    navigate(goProGameReport);
    return <GameWrapper />;
  }

  if (isLoading || !isDone) {
    return (
      <LoadingWrapper>
        <ProgressContainer>
          <Progress text={t('solo.playerboard.dataFetching')} />
        </ProgressContainer>
      </LoadingWrapper>
    );
  }

  if (!data) {
    return <GameWrapper />;
  }

  return (
    <GameWrapper>
      <GameReportIndexWrapper>
        <GameReportDetailContainer>
          <DetailLog />
          <TeamStatus />
          <BlockContainer>
            <FlexContainer>
              <VideoContainer>
                <VideoPlayer />
              </VideoContainer>
            </FlexContainer>
            <FlexContainer>
              <Summary />
              <TimeLine />
            </FlexContainer>
          </BlockContainer>
        </GameReportDetailContainer>
      </GameReportIndexWrapper>
    </GameWrapper>
  );
};

export default memo(GameReportDetail);

const GameReportIndexWrapper = styled.main`
  padding-top: 0px;
  padding-left: 0px;
  font-family: 'SpoqaHanSansNeo';
`;

const GameReportDetailContainer = styled.div`
  width: 1920px;
  height: 1080px;
  display: flex;
`;

const BlockContainer = styled.div`
  display: block;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const VideoContainer = styled.div`
  width: 1440px;
  height: 800px;
`;

const GameWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  background-color: #000000;
  overflow: hidden;

  .filter-close {
    display: none;
  }

  .filter-open {
  }
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  background-color: #000000;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

const ProgressContainer = styled.div`
  width: ${1110}px;
  height: 160px;
  margin: 20px 0 0;
  padding: 0 30px;
  border-radius: 20px;
  background-color: ${colors.bg_select};
  display: flex;
  align-items: center;
  justify-content: center;
`;
