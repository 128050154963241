import styled from '@emotion/styled';
import { colors } from '../../../Styles/ui';

export const SideFilterContainer = styled.div`
  height: 100vh;
  overflow: auto;
  background-color: ${colors.배경색[800]};
  width: 220px;
  flex: 0 0 220px;
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
